import apiClient from '../../api-client';
import { GET_ME, UPDATE_USER } from '../../enpoint/user';
import MdfApiResponse from '../../../domain/common/generic';
import { CreateUserType } from '../../../services/redux/reducers/update-user/update-user.slice';

const UserService = {
    getMe: async (): Promise<MdfApiResponse<any>> => apiClient(GET_ME, undefined, undefined, 'GET'),
    updateUser: async (userId: number, createUserForm: CreateUserType) => apiClient(UPDATE_USER(userId), createUserForm, undefined, 'PUT'),
};

export default UserService;
