import moment from 'moment';

const DateFormatter = {
    stringTimestampToDateTime: (value: number) => {
        const formatted = moment.unix(value).format('DD/MM/YYYY');
        return formatted;
    },
    dateTimeStampToDateTime: (date: Date | undefined) => {
        const numberDate = Number(date);
        if (isNaN(numberDate)) {
            return '--/--/-- --:--';
        }
        return DateFormatter.stringTimestampToDateTime(numberDate);
    },
};

export default DateFormatter;
