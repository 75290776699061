import React from "react";
import { Button, styled, Box } from '@mui/material';


export type ClearBtnProps = {
    onClick: () => void;
    label: string;
};

const ClearBtn = ({ label, onClick }: ClearBtnProps) => {
    return (
        <Box sx={ { position: 'fixed', left: '45%', bottom: '1%' } }>
            <CustomClearButton color='primary' variant='contained' onClick={ onClick }>{ label }</CustomClearButton>
        </Box>
    );
};

const CustomClearButton = styled(Button)(() => ({
        padding: '9px 15px',
        fontSize: 15,
        textTransform: 'none'
    }
));

export default ClearBtn;