import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { useRefOfInstance, useRoomObject } from "./RoomStudioHooks";
import BasicObject
    from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import {setSelectedObject} from "../../Redux/Reducers/room-studio/RoomStudioReducer";

export type CurrentObjectProps = {
    currentEntity: SceneEntity|undefined
    currentObject: BasicObject|undefined
};


const CurrentObjectSetter = (props: CurrentObjectProps) => {
    const dispatch = useDispatch();

    const refOfInstance = useRefOfInstance(props.currentEntity);
    const currentRoomObject = useRoomObject(refOfInstance);
    
    useEffect(() => {
        if(currentRoomObject === undefined && props.currentObject !== undefined){
            dispatch(setSelectedObject(currentRoomObject));
        }
        else if (currentRoomObject !== undefined && props.currentObject?.RefOfInstance !== currentRoomObject.RefOfInstance) {
            dispatch(setSelectedObject(currentRoomObject));
        }
    }, [currentRoomObject]);

    return <></>;
};

export { CurrentObjectSetter };
