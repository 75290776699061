import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ConfiguredProduct, PresetTree, Product } from '../../../../../domain/domain';
import configuredProductService from './configured-product.service';
import MdfApiResponse from '../../../../../domain/common/generic';

export type ConfiguredProductTreeListState = {
    isLoading: false;
    payload: MdfApiResponse<PresetTree[]>;
};
const initialConfiguredProductTreeListState: ConfiguredProductTreeListState = {
    isLoading: false,
    payload: {
        content: [],
        errors: [],
        warnings: [],
    },
};

export type ConfiguredProductState = {
    isLoading: false;
    payload: MdfApiResponse<ConfiguredProduct>;
};
const initialConfiguredProductState: ConfiguredProductState = {
    isLoading: false,
    payload: {
        content: {
            id: 0,
            createdAt: new Date(),
            updatedAt: new Date(),
            version: 0,
            isDefault: false,
            configuration: [],
            mappingConfiguration: {
                mappingConfiguration: [],
            },
            productReference: '',
            metadata: {},
        },
        errors: [],
        warnings: [],
    },
};

//<editor-fold desc="get configured product tree list">
export const getConfiguredProductTreeList = createAsyncThunk('get/configured-product/list/tree', async (data: { coreProductReference: string }) => {
    const res = await configuredProductService.getConfiguredProductTreeList(data.coreProductReference);
    return res;
});

export const getConfiguredProductTreeListSlice = createSlice({
    name: 'getConfiguredProductTreeListSlice',
    initialState: initialConfiguredProductTreeListState,
    reducers: {
        resetGetConfiguredProductTreeList: (state) => {
            state = initialConfiguredProductTreeListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getConfiguredProductTreeList.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getConfiguredProductTreeList.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getConfiguredProductTreeList.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetConfiguredProductTreeList } = getConfiguredProductTreeListSlice.actions;
//</editor-fold>

//<editor-fold desc="create configured product">
export const createConfiguredProduct = createAsyncThunk('create/configured-product/', async (data: { name: string; coreProductReference: string }) => {
    const res = await configuredProductService.createConfiguredProduct(data.name, data.coreProductReference);
    return res;
});

export const createConfiguredProductSlice = createSlice({
    name: 'createConfiguredProductSlice',
    initialState: initialConfiguredProductState,
    reducers: {
        resetCreateConfiguredProductSlice: (state) => {
            state = initialConfiguredProductState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createConfiguredProduct.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createConfiguredProduct.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createConfiguredProduct.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreateConfiguredProductSlice } = createConfiguredProductSlice.actions;
//</editor-fold>

//<editor-fold desc="get configured product tree">
export const getConfiguredProductTree = createAsyncThunk('get/configured-product/tree', async (data: { productReference: string }) => {
    const res = await configuredProductService.getConfiguredProductTreeByProductReference(data.productReference);
    return res;
});

export const getConfiguredProductTreeSlice = createSlice({
    name: 'getConfiguredProductTreeSlice',
    initialState: initialConfiguredProductState,
    reducers: {
        resetConfiguredProductTreeSlice: (state) => {
            state = initialConfiguredProductState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getConfiguredProductTree.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getConfiguredProductTree.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getConfiguredProductTree.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetConfiguredProductTreeSlice } = getConfiguredProductTreeSlice.actions;
//</editor-fold>

//<editor-fold desc="get configured product tree">
export const addMultipleAtomsToConfiguredProduct = createAsyncThunk('add/configured-product/tree', async (data: { productReference: string; atomReferences: string[] }) => {
    const res = await configuredProductService.addMultipleAtomsToConfiguredProduct(data.productReference, data.atomReferences);
    return res;
});

export const addMultipleAtomsToConfiguredProductSlice = createSlice({
    name: 'addMultipleAtomsToConfiguredProductSlice',
    initialState: initialConfiguredProductState,
    reducers: {
        resetAddMultipleAtomsToConfiguredProductSlice: (state) => {
            state = initialConfiguredProductState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addMultipleAtomsToConfiguredProduct.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(addMultipleAtomsToConfiguredProduct.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(addMultipleAtomsToConfiguredProduct.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetAddMultipleAtomsToConfiguredProductSlice } = addMultipleAtomsToConfiguredProductSlice.actions;
//</editor-fold>

//<editor-fold desc="get configured product tree">
export const uploadThumbnailUrlToConfiguredProduct = createAsyncThunk('upload-thumbnail/configured-product', async (data: { productReference: string; product: Product }) => {
    const res = await configuredProductService.uploadThumbnailToConfiguredProduct(data.productReference, data.product);
    return res;
});

export const uploadThumbnailUrlToConfiguredProductSlice = createSlice({
    name: 'uploadThumbnailUrlToConfiguredProductSlice',
    initialState: initialConfiguredProductState,
    reducers: {
        resetUploadThumbnailUrlToConfiguredProduct: (state) => {
            state = initialConfiguredProductState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(uploadThumbnailUrlToConfiguredProduct.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(uploadThumbnailUrlToConfiguredProduct.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(uploadThumbnailUrlToConfiguredProduct.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetUploadThumbnailUrlToConfiguredProduct } = uploadThumbnailUrlToConfiguredProductSlice.actions;
//</editor-fold>

//<editor-fold desc="delete configured product">
export const deleteConfiguredProduct = createAsyncThunk('delete/configured-product', async (data: { productReference: string }) => {
    const res = await configuredProductService.deleteConfiguredProduct(data.productReference);
    return res;
});

export const deleteConfiguredProductSlice = createSlice({
    name: 'deleteConfiguredProductSlice',
    initialState: initialConfiguredProductState,
    reducers: {
        resetDeleteConfiguredProduct: (state) => {
            state = initialConfiguredProductState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteConfiguredProduct.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteConfiguredProduct.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deleteConfiguredProduct.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDeleteConfiguredProduct } = deleteConfiguredProductSlice.actions;
//</editor-fold>

//<editor-fold desc="get configured product tree list">
export const getConfiguredProductTreeByCoreProduct = createAsyncThunk('get/configured-product/getConfiguredProductTreeByCoreProduct/tree', async (data: { coreProductReference: string }) => {
    const res = await configuredProductService.getConfiguredProductTreeByProductReference(data.coreProductReference);
    return res;
});

export const getConfiguredProductTreeByCoreProductSlice = createSlice({
    name: 'getConfiguredProductTreeByCoreProduct',
    initialState: initialConfiguredProductState,
    reducers: {
        resetGetConfiguredProductTreeByCoreProduct: (state) => {
            state = initialConfiguredProductState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getConfiguredProductTreeByCoreProduct.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getConfiguredProductTreeByCoreProduct.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getConfiguredProductTreeByCoreProduct.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetConfiguredProductTreeByCoreProduct } = getConfiguredProductTreeByCoreProductSlice.actions;
//</editor-fold>
