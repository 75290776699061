import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import hooktable1 from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_TableWithHooks1.json';
import hooktable2 from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_TableWithHooks2.json';
import hooktable3 from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_TableWithHooks3.json';
import hooktable4 from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_TableWithHooks4.json';
import composition2 from '../../Assets/Bdd/Composition/Canape_3modules.json';
import composition3 from '../../Assets/Bdd/Composition/Canape_4modules.json';
import chauffeuse from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_chauffeuse.json';
import composition1 from '../../Assets/Bdd/Composition/SampleComposition1.json';
import hooktable5 from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_TableWithHooks5.json';
import objectInfos from '../../Assets/Bdd/ObjectInfos/SuperCompoConnectors_ObjectInfo.json';
import { Vector2 } from "three";
import mapping from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_PlateWithMapping.json';
import door2v from '../../Assets/Bdd/RoomObjects/Openings/opening_door_2v.json';
import poufD from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_poufD.json';
import chauffeuseD from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_chauffeuseD.json';
import chD from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_chlD.json';
import batardD from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_batardD.json';
import AddFurnitureConsolePanel from "./AddFurnitureConsolePanel";
import testHooks1 from '../../Assets/Bdd/RoomObjects/ProductPresets/SamplePresetWithHooks.json';
import compo2 from '../../Assets/Bdd/Composition/CompositionNewGen2.json';
import testHooks2 from '../../Assets/Bdd/RoomObjects/ProductPresets/SamplePresetWithHooks2.json';
import {RoomStudioMode} from "../../Libraries/Studios/RoomStudio/Domain/Modes/RoomStudioMode";
import {
    ObjectParser
} from "../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/ObjectParser";
import BasicObject
    from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import KeyboardAction from "../../../application3D-common/Components/TestingComponents/KeyboardAction";
import {addObjects, RoomContentState, setRoomToLoad} from "../../Redux/Reducers/room-studio/RoomContentReducer";
import {RootState} from "../../../../services/redux/root-reducers";
import {RoomStudioState, setEditedDatas} from "../../Redux/Reducers/room-studio/RoomStudioReducer";
import ObjectDatas from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ObjectDatas";
import compo1 from "../../Assets/Bdd/Composition/CompositionNewGen.json";
import compo3 from "../../Assets/Bdd/Composition/CompositionNewGen3.json";


export const loadRoom = (p_room: ObjectDatas, dispatch: any): void => {
    dispatch(setRoomToLoad(p_room));
};


const KeyboardActions = () => {
    const dispatch = useDispatch();

    const { roomStudioApp, editedDatas, roomStudioMode, currentEntity } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const { selectedObject } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const { plannerObjects, instances, room } = useSelector<RootState, RoomContentState>((state) => state.roomContent);

    const takeSnapshot = () => {
        console.log('Screen: ', roomStudioApp);
        if (roomStudioApp) {
            roomStudioApp.Services.Screenshot.Take3DSnapshot(new Vector2(1920,1080));
        }
    };

    return (
        <>
            {roomStudioApp && roomStudioMode === RoomStudioMode.Furnishing && <AddFurnitureConsolePanel />}
            
       
            {/* Edit compo1 */}
            <KeyboardAction keycode="Numpad0" action={() => {
                let datas:ObjectDatas = Object.assign(new ObjectDatas(), compo2);
                // todo clean
                datas.SetDatas(datas.Instances3D,datas.BasicObjects);
                dispatch(setEditedDatas(datas));
            }} />

            <KeyboardAction keycode="Numpad1" action={() => {
                let datas:ObjectDatas = Object.assign(new ObjectDatas(), compo1);
                // todo clean
                datas.SetDatas(datas.Instances3D,datas.BasicObjects);
                dispatch(setEditedDatas(datas));
            }} />

            <KeyboardAction keycode="Numpad2" action={() => {
                let datas:ObjectDatas = Object.assign(new ObjectDatas(), compo3);
                // todo clean
                datas.SetDatas(datas.Instances3D,datas.BasicObjects);
                dispatch(setEditedDatas(datas));
            }} />
            
        </>
    );
};

export { KeyboardActions };
