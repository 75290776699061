import React, { useState } from 'react';
import './ConsolePanel.scss';
import { useDispatch } from "react-redux";
import table from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_TableBasse_Ecorce.json';

import st from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_ST_2002.json';
import colonne from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_Chalet_Colonne.json';
import teddy from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_Teddy_Canape.json';
import smoothie from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_Smoothie_Canape.json';
import circus from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_Circus_Etagere.json';
import mirroir from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_Chalet_Mirroir.json';
import vigo2p from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_Vigo_2P.json';
import alpha from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_Alpha_MeubleTV.json';
import testHooks from '../../Assets/Bdd/RoomObjects/ProductPresets/SamplePresetWithHooks.json';
import compo1 from '../../Assets/Bdd/Composition/CompositionNewGen.json';
import enfilade from '../../Assets/Bdd/RoomObjects/ProductPresets/ProductPreset_Enfilade_Ecorce.json';
import { Guid } from "guid-typescript";
import BasicObject from '../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject';
import { ObjectParser } from '../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/ObjectParser';
import { addObjects } from '../../Redux/Reducers/room-studio/RoomContentReducer';
import ObjectDatas from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ObjectDatas";


export type AddFurnitureConsolePanelProps = {
};

const AddFurnitureConsolePanel = (props: AddFurnitureConsolePanelProps) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    
    return (
        <>
            { !isOpen && (<button className='console-openclose-button' onClick={()=> {
                setIsOpen(true);
            }}>{'OPEN add furniture console  ---->'}</button>)}
            { isOpen && (<button className='console-openclose-button' onClick={()=> {
                setIsOpen(false);
            }}>{'CLOSE add furniture console <----'}</button>)}
            { isOpen && (
                <div className='console-panel'>
            
                <button className='console-button' onClick={()=>{
                    let obj = ObjectParser.DeepParseObject(smoothie,BasicObject);
                    obj.RefOfInstance = Guid.create().toString();
                    dispatch(addObjects(obj));
                }}> + [SMOOTHIE]  -- Canapé </button>
                <button className='console-button' onClick={()=> {
                    let obj = ObjectParser.DeepParseObject(teddy, BasicObject);
                    obj.RefOfInstance = Guid.create().toString();
                    dispatch(addObjects(obj));
                }}> + [TEDDY] ------- Canapé </button>

                
                <button className='console-button' onClick={()=> {
                    let obj = ObjectParser.DeepParseObject(vigo2p, BasicObject);
                    obj.RefOfInstance = Guid.create().toString();
                    dispatch(addObjects(obj));
                }}> + [VIGO] -------- Meuble 2-P </button>
                <button className='console-button' onClick={()=>{
                    let obj = ObjectParser.DeepParseObject(alpha, BasicObject);
                    obj.RefOfInstance = Guid.create().toString();
                    dispatch(addObjects(obj));
                }}> + [ALPHA] ------ Meuble TV (Err)</button>
                <button className='console-button' onClick={()=>{
                    let obj = ObjectParser.DeepParseObject(mirroir, BasicObject);
                    obj.RefOfInstance = Guid.create().toString();
                    dispatch(addObjects(obj));
                }}> + [CHALET] ----- Mirroir </button>
                <button className='console-button' onClick={()=> {
                    let obj = ObjectParser.DeepParseObject(circus, BasicObject);
                    obj.RefOfInstance = Guid.create().toString();
                    dispatch(addObjects(obj));
                }}> + [CIRCUS] ------ Etagères </button>
                <button className='console-button' onClick={()=>{
                    let obj = ObjectParser.DeepParseObject(colonne, BasicObject);
                    obj.RefOfInstance = Guid.create().toString();
                    dispatch(addObjects(obj));
                }}> + [CHALET] ----- Etagères </button>
                <button className='console-button' onClick={()=> {
                    let obj = ObjectParser.DeepParseObject(enfilade, BasicObject);
                    obj.RefOfInstance = Guid.create().toString();
                    dispatch(addObjects(obj));
                }}> + [ECORCE] ----- Enfilade </button>
                <button className='console-button' onClick={()=>{
                    let obj = ObjectParser.DeepParseObject(table, BasicObject);
                    obj.RefOfInstance = Guid.create().toString();
                    dispatch(addObjects(obj));
                }}> + [ECORCE] ----- Table basse </button>
                <button className='console-button' onClick={()=>{
                    let obj = ObjectParser.DeepParseObject(st, BasicObject);
                    obj.RefOfInstance = Guid.create().toString();
                    dispatch(addObjects(obj));
                }}> + [ST2002] ----- Chaise (Err) </button>
                <button className='console-button' onClick={()=>{
                    let obj = ObjectParser.DeepParseObject(testHooks, BasicObject);
                    obj.RefOfInstance = Guid.create().toString();
                    dispatch(addObjects(obj));
                }}> + [LS] ----- Test HOOKS </button>
                <button className='console-button' onClick={()=>{
                    let data:ObjectDatas = Object.assign(new ObjectDatas(), compo1);
                    console.log("addObjects", data.BasicObjects);
                    dispatch(addObjects(data.BasicObjects));
                }}> + [LS] ----- Test COMPOSITION </button>
            </div>)}
        </>
    );
};

export default AddFurnitureConsolePanel;
