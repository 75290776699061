const GET_CATALOGS = `v1/catalogs/`;
const GET_CATALOGS_BY_USER = `v1/products-catalogs/connected-user`;
const CREATE_UPDATE_CATALOG = `v1/catalogs/`;
const GET_CATALOG_BY_ID = (catalogId: number) => `v1/catalogs/catalogById/${catalogId}`;
const GET_CATALOG_BY_REF = (catalogRef: string) => `v1/catalogs/reference/${catalogRef}`;
const FIND_CATALOGS_BY_PRODUCT_ID = (productId: number) => `v1/products-catalogs/product/${productId}/catalogs/`;
const DELETE_CATALOG = (catalogId: number) => `v1/catalogs/${catalogId}`;
const TREE_CATALOG = (catalogId: number) => `v1/products-catalogs/${catalogId}/tree-catalog/`;
const LINK_PRODUCT_TO_CATALOG = (catalogId: number, productId: number) => `v1/products-catalogs/${catalogId}/product/${productId}`;
const UNLINK_PRODUCT_TO_CATALOG = (catalogId: number, productId: number) => `v1/products-catalogs/${catalogId}/product/${productId}`;

const GET_ALL_PRODUCTS_CATALOG_SUBSECTION = (catalogId: number, sectionReference: string, subSectionReference: string) =>
    `v1/products-catalogs/${catalogId}/section/${sectionReference}/sub-section/${subSectionReference}/products/`;

const GET_ALL_PRODUCTS_CATALOG_SUBSECTION_TREE = (catalogId: number, sectionReference: string, subSectionReference: string) =>
    `v1/products-catalogs/${catalogId}/section/${sectionReference}/sub-section/${subSectionReference}/products/tree/`;

const FIND_ALL_PRODUCTS_IN_CATALOG_SECTION = (sectionId: number) => `v1/products-catalogs/section/${sectionId}/products/tree`;

const SEARCH_PRODUCTS_IN_CATALOG = (catalogId: number, searchQuery: string) => `v1/products-catalogs/${catalogId}/search?byName=${searchQuery}`;

export {
    GET_CATALOGS,
    GET_CATALOGS_BY_USER,
    SEARCH_PRODUCTS_IN_CATALOG,
    GET_CATALOG_BY_ID,
    FIND_CATALOGS_BY_PRODUCT_ID,
    DELETE_CATALOG,
    CREATE_UPDATE_CATALOG,
    GET_CATALOG_BY_REF,
    TREE_CATALOG,
    LINK_PRODUCT_TO_CATALOG,
    UNLINK_PRODUCT_TO_CATALOG,
    GET_ALL_PRODUCTS_CATALOG_SUBSECTION,
    GET_ALL_PRODUCTS_CATALOG_SUBSECTION_TREE,
    FIND_ALL_PRODUCTS_IN_CATALOG_SECTION,
};
