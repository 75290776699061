import { Intersection, MathUtils, Object3D, Plane, Raycaster, Vector3 } from 'three';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { ASystem } from '@lutithree/build/Modules/Core/Entity/ASystem';
import { SupportComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SupportComponent';
import { BoundToRoomComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundToRoomComponent';
import { SupportType } from '@lutithree/build/Modules/WebGL/Scene/DataModel/SupportType';
import { BoundingBoxComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundingBoxComponent';
import { SnapData } from "@lutithree/build/Modules/WebGL/Scene/DataModel/SnapData";
import { SnappableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SnappableComponent";

export class SnapSystem extends ASystem<SceneEntity> {
    
    public InitialCheckForSnapSupport(p_entity: SceneEntity, p_supports: Array<SupportComponent>, ...p_planeToIgnore: SnapData[]): { plane: Plane | null; support: SupportComponent | null }{
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined in SnapSystem');
        if (p_supports == null) throw new Error('NullReferenceException : p_supports is null or undefined in SnapSystem');
        if (p_planeToIgnore == null) throw new Error('NullReferenceException : p_planeToIgnore is null or undefined in SnapSystem');

        let chosenPlane: Plane | null = null;
        let chosenSupport: SupportComponent | null = null;
        let closestAngle: undefined | number = undefined;
        
        let snapPlane = this.CheckForSnapSupport(p_entity, p_entity.Transform.Forward, p_supports, ...p_planeToIgnore);
            chosenPlane = snapPlane.plane;
            chosenSupport = snapPlane.support;
            if(chosenPlane)closestAngle = p_entity.Transform.Forward.dot(chosenPlane.normal) / chosenPlane.normal.lengthSq();
            p_supports.forEach((support) => {
                let intersectionsFromBBBorder = this.GetIntersectionsOnSupportFromBBBorder(p_entity, support);
                let intersectionsFromBBCenter = this.GetIntersectionsOnSupportFromBBCenter(p_entity, support);

                for(let i=0; i<intersectionsFromBBBorder.length; i++){
                    if(intersectionsFromBBBorder.every(x => x === undefined) && intersectionsFromBBCenter.some(x => x !== undefined)){
                        let intersection = intersectionsFromBBCenter[i];
                        if ( intersection && intersection.face) {
                            let rotationOfObject = intersection.object.matrixWorld.clone();
                            let normal = intersection.face.normal.clone().transformDirection(rotationOfObject).negate();
                            let angle = p_entity.Transform.Forward.dot(normal) / normal.lengthSq();
                            if (!closestAngle || angle < closestAngle)
                                closestAngle = angle;
                            else
                                return;
                            chosenPlane = new Plane(normal, intersection.point.dot(normal.clone().negate()));
                            chosenSupport = support;
                        }
                    }
                }
            });
        return { plane: chosenPlane, support: chosenSupport };
    }
    
    
    public CheckForSnapSupport(p_entity: SceneEntity, p_mainNormal: Vector3, p_supports: Array<SupportComponent>, ...p_planeToIgnore: SnapData[]): { plane: Plane | null; support: SupportComponent | null } {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined in SnapSystem');
        if (p_supports == null) throw new Error('NullReferenceException : p_supports is null or undefined in SnapSystem');
        if (p_planeToIgnore == null) throw new Error('NullReferenceException : p_planeToIgnore is null or undefined in SnapSystem');

        let chosenPlane: Plane | null = null;
        let chosenSupport: SupportComponent | null = null;
        let closestAngle: undefined | number = undefined;

        p_supports.forEach((support) => {
            this.GetIntersectionsOnSupportFromBBBorder(p_entity, support).forEach((intersection) => {
                if (intersection && intersection.face) {
                    let rotationOfObject = intersection.object.matrixWorld.clone();
                    let normal = intersection.face.normal.clone().transformDirection(rotationOfObject).negate();
                    let plane = new Plane(normal, intersection.point.dot(normal.clone().negate()));
                    let ignoreFlag: boolean = false;
                    p_planeToIgnore.forEach((planeIter) => {
                        if (this.PlanesEqual(planeIter.Plane, plane)) ignoreFlag = true;
                    });
                    if (ignoreFlag) return;
                    

                    this.GetClosestPointToPlan(p_entity, plane).forEach((point) => {
                        let rayAlignement = new Raycaster(point, normal.clone());
                        let alignmentTest = rayAlignement.intersectObject(support.SupportObject);
                        if (Math.abs(plane.distanceToPoint(point)) < support.ThreshHold && alignmentTest.length > 0) {
                            let angle = p_mainNormal.dot(normal) / normal.lengthSq();
                            if (!closestAngle || angle < closestAngle)
                                closestAngle = angle;
                            else
                                return;
                            chosenPlane = plane;
                            chosenSupport = support;
                        }
                    });
                }
            });
        });

        return { plane: chosenPlane, support: chosenSupport };
    }

    public CheckForThreshholdCap(p_entity: SceneEntity, p_support: SupportComponent, p_plane: Plane, p_threshHold: number): boolean {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined in SnapSystem');
        if (p_plane == null) throw new Error('NullReferenceException : p_plane is null or undefined in SnapSystem');
        if (p_threshHold == null) throw new Error('NullReferenceException : p_Threshhold is null or undefined in SnapSystem');

        if (p_entity.HasComponentOfType(BoundToRoomComponent) && p_support.SupportType === SupportType.HardWall) {
            return -p_plane.distanceToPoint(this.GetClosestPointToPlan(p_entity, p_plane)[0]) > p_threshHold;
        }
        return Math.abs(p_plane.distanceToPoint(this.GetClosestPointToPlan(p_entity, p_plane)[0])) > p_threshHold;
    }

    public SnapToGround(p_entity: SceneEntity, p_surfaces: Array<SupportComponent>, p_threshHold: number) {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined in SnapSystem');
        if (p_surfaces == null) throw new Error('NullReferenceException : p_surfaces is null or undefined in SnapSystem');
        if (p_threshHold == null) throw new Error('NullReferenceException : p_threshHold is null or undefined in SnapSystem');

        let surfaces3D: Array<Object3D> = [];
        p_surfaces.forEach((surface) => {
            surfaces3D.push(surface.SupportObject);
        });
        let plane: Plane | null = null;
        let ClosestIntersection: Intersection | undefined;
        let ClosestPoint: Vector3;
        if (p_entity.HasComponentOfType(BoundingBoxComponent)) ClosestPoint = p_entity.GetComponentOfType(BoundingBoxComponent).Center;
        else ClosestPoint = p_entity.Transform.GetObject().position;

        if (p_entity.HasComponentOfType(BoundingBoxComponent)) {
            let OBB = p_entity.GetComponentOfType(BoundingBoxComponent);

            let orientedDownBBVector = new Vector3().addVectors(OBB.Center, OBB.GetUpOriented().clone().negate());
            let RaycasterDown = new Raycaster(orientedDownBBVector, OBB.GetUpOriented().clone().negate().normalize());
            ClosestIntersection = RaycasterDown.intersectObjects(surfaces3D)[0];
            ClosestPoint = new Vector3().addVectors(OBB.Center, OBB.GetUpOriented().clone().negate());
        }
        if (ClosestIntersection && ClosestIntersection.face) {
            let rotationOfObject = ClosestIntersection.object.matrixWorld.clone();
            let normal = ClosestIntersection.face.normal.clone().transformDirection(rotationOfObject).negate();
            plane = new Plane(normal, ClosestIntersection.point.dot(normal.clone().negate()));
        }
        if (!plane || -plane.distanceToPoint(ClosestPoint) >= p_threshHold) return;
        let furthestPointOffset = plane.normal.clone().multiplyScalar(plane.distanceToPoint(ClosestPoint)).negate();
        p_entity.Transform.AddToPosition(furthestPointOffset);
    }

    public SnapToPlane(p_entity: SceneEntity, p_plane: Plane): Vector3 {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined in SnapSystem');
        if (p_plane == null) throw new Error('NullReferenceException : p_plane is null or undefined in SnapSystem');

        let ClosestPointPlan: Vector3;
        if (p_entity.HasComponentOfType(BoundingBoxComponent)) ClosestPointPlan = p_entity.GetComponentOfType(BoundingBoxComponent).Center;
        else ClosestPointPlan = p_entity.Transform.GetObject().position;
        if (p_entity.HasComponentOfType(BoundingBoxComponent)) {
            ClosestPointPlan = this.GetClosestPointToPlan(p_entity, p_plane)[0];
        }

        let furthestPointOffset = p_plane.normal.clone().multiplyScalar(p_plane.distanceToPoint(ClosestPointPlan)).negate();
        p_entity.Transform.AddToPosition(furthestPointOffset);
        return furthestPointOffset;
    }

    public SnapOnAxis(p_entity: SceneEntity, p_axisRef: Vector3, p_axisSideRef: Vector3, p_step: number, p_threshHold: number): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined in SnapSystem');
        if (p_axisRef == null) throw new Error('NullReferenceException : p_axisRef is null or undefined in SnapSystem');
        if (p_axisSideRef == null) throw new Error('NullReferenceException : p_axisSideRef is null or undefined in SnapSystem');
        if (p_step == null) throw new Error('NullReferenceException : p_step is null or undefined in SnapSystem');
        if (p_threshHold == null) throw new Error('NullReferenceException : p_threshHold is null or undefined in SnapSystem');

        let angleDegForwardWithRef = Math.round(MathUtils.RAD2DEG * p_entity.Transform.Forward.angleTo(p_axisRef));
        let angleDegForwardWithSideRef = Math.round(MathUtils.RAD2DEG * p_entity.Transform.Forward.angleTo(p_axisSideRef));
        let angleDegRightWithRef = Math.round(MathUtils.RAD2DEG * p_entity.Transform.Right.angleTo(p_axisRef));

        let signForward = -Math.sign(p_entity.Transform.Forward.dot(p_axisSideRef));

        if (angleDegForwardWithRef % p_step <= p_threshHold || angleDegForwardWithSideRef % p_step <= p_threshHold || angleDegRightWithRef % p_step <= p_threshHold) {
            let snapData = p_entity.GetComponentOfType(SnappableComponent).Snapped[0];
            let angleDistForward = signForward * this.GetClosestAngle(angleDegForwardWithRef, p_step);
            p_entity.Transform.SetEulerAngles(new Vector3(0, angleDistForward - (snapData ? snapData.AngleWith : 0), 0));
        }
    }

    public AdjustToSnappedSupport(p_entity: SceneEntity) {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined in SnapSystem');

        let snapComponent = p_entity.GetComponentOfType(SnappableComponent);

        for (let i = 0; i < snapComponent.Snapped.length; i++) {
            if (this.CheckForThreshholdCap(p_entity, snapComponent.Snapped[i].Support, snapComponent.Snapped[i].Plane, 0.3)) {
                snapComponent.Unsnap(snapComponent.Snapped[i]);
                i = i - 1;
            } else this.SnapToPlane(p_entity, snapComponent.Snapped[i].Plane);
        }
    }

    private GetIntersectionsOnSupportFromBBBorder(p_entity: SceneEntity, p_support: SupportComponent): Array<Intersection>{
        let Intersections: Array<Intersection> = [];

        if (p_entity.HasComponentOfType(BoundingBoxComponent)) {
            let OBB = p_entity.GetComponentOfType(BoundingBoxComponent);

            let orientedRightBBVector = new Vector3().addVectors(OBB.Center, OBB.GetRightOriented()).add(OBB.GetForwardOriented());
            let RaycasterRight = new Raycaster(orientedRightBBVector, OBB.GetRightOriented().clone().add(OBB.GetForwardOriented()).normalize());
            
            let orientedLeftBBVector = new Vector3().addVectors(OBB.Center, OBB.GetRightOriented(true).add(OBB.GetForwardOriented()));
            let RaycasterLeft = new Raycaster(orientedLeftBBVector, OBB.GetRightOriented(true).clone().add(OBB.GetForwardOriented()).normalize());

            let orientedForwardBBVector = new Vector3().addVectors(OBB.Center, OBB.GetRightOriented().add(OBB.GetForwardOriented(true)));
            let RaycasterForward = new Raycaster(orientedForwardBBVector, OBB.GetRightOriented().clone().add(OBB.GetForwardOriented(true)).normalize());

            let orientedBackwardBBVector = new Vector3().addVectors(OBB.Center, OBB.GetRightOriented(true).add(OBB.GetForwardOriented(true)));
            let RaycasterBackward = new Raycaster(orientedBackwardBBVector, OBB.GetRightOriented(true).clone().add(OBB.GetForwardOriented(true)).normalize());

            Intersections.push(RaycasterRight.intersectObject(p_support.SupportObject, true)[0]);
            Intersections.push(RaycasterLeft.intersectObject(p_support.SupportObject, true)[0]);
            Intersections.push(RaycasterForward.intersectObject(p_support.SupportObject, true)[0]);
            Intersections.push(RaycasterBackward.intersectObject(p_support.SupportObject, true)[0]);

            Intersections.sort((a, b) => {
                if (a.distance < b.distance) return -1;
                else return 1;
            });
        }
        
        return Intersections;
    }

    public GetIntersectionsOnSupportFromBBCenter(p_entity: SceneEntity, p_support: SupportComponent): Array<Intersection> {
        let Intersections: Array<Intersection> = [];

        if (p_entity.HasComponentOfType(BoundingBoxComponent)) {
            let OBB = p_entity.GetComponentOfType(BoundingBoxComponent);

            let RaycasterRight = new Raycaster(OBB.Center, OBB.GetRightOriented().clone().add(OBB.GetForwardOriented()).normalize());
            let RaycasterLeft = new Raycaster(OBB.Center, OBB.GetRightOriented(true).clone().add(OBB.GetForwardOriented()).normalize());
            let RaycasterForward = new Raycaster(OBB.Center, OBB.GetRightOriented().clone().add(OBB.GetForwardOriented(true)).normalize());
            let RaycasterBackward = new Raycaster(OBB.Center, OBB.GetRightOriented(true).clone().add(OBB.GetForwardOriented(true)).normalize());

            Intersections.push(RaycasterRight.intersectObject(p_support.SupportObject, true)[0]);
            Intersections.push(RaycasterLeft.intersectObject(p_support.SupportObject, true)[0]);
            Intersections.push(RaycasterForward.intersectObject(p_support.SupportObject, true)[0]);
            Intersections.push(RaycasterBackward.intersectObject(p_support.SupportObject, true)[0]);

            Intersections.sort((a, b) => {
                if (a.distance < b.distance) return -1;
                else return 1;
            });
        }
        
        return Intersections;
    }

    private GetClosestPointToPlan(p_entity: SceneEntity, p_plane: Plane): Vector3[] {
        let OrientedPoints: Array<Vector3> = [];
        let center: Vector3;
        if (p_entity.HasComponentOfType(BoundingBoxComponent)) center = p_entity.GetComponentOfType(BoundingBoxComponent).Center;
        else center = p_entity.Transform.GetObject().position;
        let OBB = p_entity.GetComponentOfType(BoundingBoxComponent);
        let vec1 = new Vector3().addVectors(OBB.GetRightOriented(), OBB.GetForwardOriented()).add(center);
        let vec2 = new Vector3().addVectors(OBB.GetRightOriented(true), OBB.GetForwardOriented()).add(center);
        let vec3 = new Vector3().addVectors(OBB.GetRightOriented(), OBB.GetForwardOriented(true)).add(center);
        let vec4 = new Vector3().addVectors(OBB.GetRightOriented(true), OBB.GetForwardOriented(true)).add(center);
        OrientedPoints.push(vec1);
        OrientedPoints.push(vec2);
        OrientedPoints.push(vec3);
        OrientedPoints.push(vec4);

        OrientedPoints.sort((a, b) => {
            if (p_plane.distanceToPoint(a) > p_plane.distanceToPoint(b)) return -1;
            else return 1;
        });
        let test = p_plane.distanceToPoint(OrientedPoints[0]) - p_plane.distanceToPoint(OrientedPoints[1]);
        if (test < 0.01) return [OrientedPoints[0], OrientedPoints[1]];
        return [OrientedPoints[0]];
    }

    private GetClosestAngle(p_angleDeg: number, p_step: number): number {
        let nmbOfAxis = 180 / p_step;

        let Axis: Array<number> = [];

        for (let i: number = 0; i <= Math.floor(nmbOfAxis); i = i + 1) {
            Axis.push(p_step * i);
        }

        Axis.sort((a, b) => {
            if (Math.abs(p_angleDeg - a) < Math.abs(p_angleDeg - b)) return -1;
            else return 1;
        });

        return Axis[0];
    }
    
    private PlanesEqual(p_plane1: Plane, p_plane2: Plane, p_threshold: number = 0.0001): boolean {
        let equalFlag = true;
        if(p_plane1.normal.x - p_plane2.normal.x > p_threshold)
            equalFlag = false;
        if (p_plane1.normal.y - p_plane2.normal.y > p_threshold)
            equalFlag = false;
        if (p_plane1.normal.z - p_plane2.normal.z > p_threshold)
            equalFlag = false;

        if (p_plane1.constant - p_plane2.constant > p_threshold)
            equalFlag = false;
        
        return equalFlag;
    } 
}
