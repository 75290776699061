import { AuthSigninForm, ResetPasswordForm } from '../../../domain/domain';
import apiClient from '../../api-client';
import { CREATE_PASSWORD, FORGOT_PASSWORD, LOGIN } from '../../enpoint/auth';

const AuthService = {
    loginUser: async (form: AuthSigninForm) => apiClient(LOGIN, form, undefined, 'POST'),
    createPassword: async (form: ResetPasswordForm) => apiClient(CREATE_PASSWORD, form, undefined, 'POST'),
    forgotPassword: async (form: AuthSigninForm) => apiClient(FORGOT_PASSWORD, form, undefined, 'POST'),
};

export default AuthService;
