import * as React from 'react';
import LoginForm from '../../business/LoginForm';
import LayoutContainerLog from "../../ui-components/LayoutContainer/LayoutContainerLog";


const Login = () => {
    return (
        <>
            <LayoutContainerLog title='Connexion'>
                <LoginForm />
            </LayoutContainerLog>
        </>
    );
};

export default Login;
