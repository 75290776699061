import { ASystem } from "@lutithree/build/Modules/Core/Entity/ASystem";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { Vector3 } from "three";
import { Button3DComponent } from "../Components/Button3DComponent";
import { RaycastableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/RaycastableComponent";

export default class Button3DSystem extends ASystem<SceneEntity> {
    public Instanciate3DButtonAtPosition(p_name:string, p_position: Vector3, p_spritesUrl: {p_idle: string, p_active: string}, p_onClickCallback: (p_value: boolean)=>void, p_loadCallback: ()=>void) {
        if (!p_name) throw new Error('NullReferenceException : p_name is null or undefined or empty');
        if (p_position == null) throw new Error('NullReferenceException : p_position is null or undefined');
        if (p_spritesUrl == null) throw new Error('NullReferenceException : p_spritesUrl is null or undefined');
        if (p_onClickCallback == null) throw new Error('NullReferenceException : p_onClickCallback is null or undefined');
        if (p_loadCallback == null) throw new Error('NullReferenceException : p_loadCallback is null or undefined');

        let entity = this.m_entityManager.CreateEntity("3DButton");
        entity.AddComponentOfType(Button3DComponent,
                p_name,
                p_position,
                p_spritesUrl.p_idle,
                p_spritesUrl.p_active,
                p_onClickCallback,
                p_loadCallback);
        entity.AddComponentOfType(RaycastableComponent, 5);
    }
    
    public RemoveButtonAtPosition(p_position: Vector3) {
        if (p_position == null) throw new Error('NullReferenceException : p_position is null or undefined');

        let ObjectsToRemove: Button3DComponent[] = [];
        for (let i = 0; i < this.m_entityManager.GetComponents(Button3DComponent).length; i++) {
            if(this.m_entityManager.GetComponents(Button3DComponent)[i].GetObject().position.equals(p_position))
            {
                ObjectsToRemove.push(...this.m_entityManager.GetComponents(Button3DComponent).splice(i,1));
                i = i - 1; 
            }
        }
        ObjectsToRemove.forEach((component)=>{
            this.m_entityManager.RemoveEntityByID(component.EntityID);
        });
    }
    
    public OnClickButton(p_button : Button3DComponent): void {
        this.m_entityManager.GetComponents(Button3DComponent).forEach((component)=>{
            if(component !== p_button) component.IsOn = false;
        });
        p_button.IsOn = !p_button.IsOn;
    }
}
