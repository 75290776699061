import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { fetchAndAddTemplate } from '../../../../utils/fetchAndAddTemplate';
import { useDispatch } from 'react-redux';
import CardMediaWithLabel from '../../CardAction/CardMediaWithLabel';
import ButtonNavigate from '../../../../ui-components/ButtonNavigate';
import doorWindowOne from '../../../../asset/porte-fenetre1v@2x.png';
import doorWindowTwo from '../../../../asset/porte-fenetre2v@2x.png';
import doorWindowThree from '../../../../asset/porte-fenetre3v@2x.png';
import doorWindowFour from '../../../../asset/porte-fenetre4v@2x.png';


type SlidingWindowPanelProps = {
    setSlidingWindowPanel: (SlidingWindowPanel: boolean) => void;
};

type DataSlidingWindowPanelProps = {
    label: string;
    imageUrl: string;
    onClick: () => void;
};


const SlidingWindowPanel = ({ setSlidingWindowPanel }: SlidingWindowPanelProps) => {
    const dispatch = useDispatch();
    const slidingWindow1 = 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/metadata/objects/openings/casement-window-big/opening_casement_window_big_1v.json';
    const slidingWindow2 = 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/metadata/objects/openings/casement-window-big/opening_casement_window_big_2v.json';
    const slidingWindow3 = 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/metadata/objects/openings/casement-window-big/opening_casement_window_big_3v.json';
    const slidingWindow4 = 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/metadata/objects/openings/casement-window-big/opening_casement_window_big_4v.json';

    const dataSlidingWindowPanel: DataSlidingWindowPanelProps[] = [
        {
            label: '1 Vantail',
            imageUrl: doorWindowOne,
            onClick: () => fetchAndAddTemplate(slidingWindow1, dispatch),

        },
        {
            label: '2 Vantaux',
            imageUrl: doorWindowTwo,
            onClick: () => fetchAndAddTemplate(slidingWindow2, dispatch),
        },
        {
            label: '3 Vantaux',
            imageUrl: doorWindowThree,
            onClick: () => fetchAndAddTemplate(slidingWindow3, dispatch),
        },
        {
            label: '4 Vantaux',
            imageUrl: doorWindowFour,
            onClick: () => fetchAndAddTemplate(slidingWindow4, dispatch),
        },
    ];


    return (
        <>
            <ButtonNavigate label='Portes-fenêtres' onClick={ () => setSlidingWindowPanel(false) } />
            <Box sx={ { pt: 3 } }>
                <Grid container spacing={ 2 }>
                    { dataSlidingWindowPanel.map((el: DataSlidingWindowPanelProps, index: number) => {
                        return (
                            <Grid item xs={ 12 } md={ 6 } key={ index + el.label }>
                                <CardMediaWithLabel onClick={ el.onClick } label={ el.label } media={ el.imageUrl } />
                            </Grid>
                        );
                    }) }
                </Grid>
            </Box>
        </>
    );
};
export default SlidingWindowPanel;
