export const isDevConfig = () => process.env.REACT_APP_ENV === 'dev';

export const isRecConfig = () => process.env.REACT_APP_ENV === 'rec';

export const isProdConfig = () => process.env.REACT_APP_ENV === 'prod';
export const isProdUlConfig = () => process.env.REACT_APP_ENV === 'produl';

export const isiPadConfig = () => process.env.REACT_APP_ENV === 'ipad';

export const getConfig = () => {
    const devConfig = {
        environment: 'dev',
        urlHostApi: 'http://localhost:8080/mdf-api',
        bpHostApi: 'http://localhost:8081/mdf-base-produit-api',
        plannerConfigFilePath: 'http://localhost:3000/config/plannerconfig.json',
    };

    const recConfig = {
        environment: 'rec',
        urlHostApi: 'https://ls-mdf-project.ninja/mdf-api',
        bpHostApi: 'https://bp-api.ls-mdf-project.ninja/mdf-base-produit-api',
        plannerConfigFilePath: 'https://seller.ls-mdf-project.ninja/config/plannerconfig.json',
    };
    const prodConfig = {
        environment: 'prod',
        urlHostApi: 'https://prod-api.ls-mdf-project.ninja/mdf-api',
        bpHostApi: 'https://bp-api.ls-mdf-project.ninja/mdf-base-produit-api',
        plannerConfigFilePath: 'https://prod-seller.ls-mdf-project.ninja/config/plannerconfig.json',
    };

    const prodUlConfig = {
        environment: 'prod',
        urlHostApi: 'https://mdf-api-3d.mobilierdefrance.com/mdf-api',
        bpHostApi: 'https://mdf-api-produit.mobilierdefrance.com/mdf-base-produit-api/',
        plannerConfigFilePath: 'http://51.103.63.244/config/plannerconfig.json',
    };

    const ipadConfig = {
        environment: 'dev',
        urlHostApi: `http://${process.env.REACT_APP_LOCAL_API_URL}:8080/mdf-api`,
        bpHostApi: `http://${process.env.REACT_APP_LOCAL_API_URL}:8081/mdf-base-produit-api`,
        plannerConfigFilePath: 'http://localhost:3000/config/plannerconfig.json',
    };

    if (isRecConfig()) {
        return recConfig;
    } else if (isProdConfig()) {
        return prodConfig;
    } else if (isProdUlConfig()) {
        return prodUlConfig;
    } else if (isiPadConfig()) {
        return ipadConfig;
    } else {
        return devConfig;
    }
};

export default getConfig();
