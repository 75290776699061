const CREATE_MODEL_CELL_VALUE = (model3dReference: string) => `v1/model-3d-cell-value/model3d/${ model3dReference }`;
const GET_MODEL_CELL_VALUES_BY_PART_ITEM_REF = 'v1/model-3d-cell-value/get-values';
const GET_CELL_VALUE_TREE = (productRef: string) => `v1/product/${ productRef }/build-tree`;


const BUILD_MAPPING_TREE = (productRef: string) => `v1/product/${ productRef }/build-mapping-response`;
const GET_DEFAULT_CONFIGURATION = (productRef: string) => `v1/product/${ productRef }/default-configuration`;
const CHANGE_ATOM_VISIBILITY = (partReference: string, partItemReference: string, atomVisibility: string) =>
    `/v1/model-3d-cell-thumbnails/${ partReference }/${ partItemReference }/${ atomVisibility }/change-visibility`;
const RENAME_ATOM_LABEL = (atomRef: string, label: string) => `v1/model-3d-cell-value/${ atomRef }/update/${ label }`;


const FIND_MODEL_3D_FOR_COMBO = () => `v1/model-3d-cell-value/configuration/model-url`;

export {
    CREATE_MODEL_CELL_VALUE,
    GET_MODEL_CELL_VALUES_BY_PART_ITEM_REF,
    BUILD_MAPPING_TREE,
    CHANGE_ATOM_VISIBILITY,
    GET_CELL_VALUE_TREE, FIND_MODEL_3D_FOR_COMBO,
    RENAME_ATOM_LABEL,
    GET_DEFAULT_CONFIGURATION,
};