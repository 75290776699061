import { DisableComponent } from '@lutithree/build/Modules/Core/Entity/DisableComponent';
import { BufferGeometry, Material, Object3D, Texture, Vector3, WebGLRenderTarget } from 'three';
import { IDisposableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/IDisposableComponent";
import { StickerBillboard } from "@lutithree/build/Modules/WebGL/Primitives/Graphics/StickerBillboard";
import { IObjectComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/IObjectComponent";
import { LoadingManager } from "@lutithree/build/Modules/WebGL/Resources/Load/LoadingManager";

export class Button3DComponent extends DisableComponent implements IDisposableComponent, IObjectComponent<Object3D> {

    private m_name: string;
    
    private m_button: StickerBillboard;
    
    private m_idleSprite: Texture | undefined;
    
    private m_clickedSprite: Texture | undefined;
    
    private m_onClickCallback: (p_value: boolean)=>void;
    
    private m_isOn: boolean = false;
    
    public constructor(p_name: string, p_position: Vector3, p_idleSpriteUrl: string, p_clickedSpriteUrl: string, p_onClickCallback: (p_value: boolean)=>void, p_onLoadCallback: ()=>void) {
        super();
        if (p_position == null) throw new Error('NullReferenceException : p_position is null or undefined');
        if (p_idleSpriteUrl == null) throw new Error('NullReferenceException : p_idleSpriteUrl is null or undefined');
        if (p_clickedSpriteUrl == null) throw new Error('NullReferenceException : p_clickedSpriteUrl is null or undefined');
        if (p_onClickCallback == null) throw new Error('NullReferenceException : p_onClickCallback is null or undefined');
        
        this.m_name = p_name;
        this.m_button = this.GenerateButton(p_position);
        this.m_button.m_stickerObject.visible = false;
        this.m_onClickCallback = p_onClickCallback;
        
        let promise1 = LoadingManager.Instance.TextureLoader.loadAsync(p_idleSpriteUrl).then((texture)=>{
            this.m_idleSprite = texture;
            this.m_button.UpdateMaterialTexture(texture);
            this.m_button.m_stickerObject.visible = true;
            p_onLoadCallback();
        });
        let promise2 = LoadingManager.Instance.TextureLoader.loadAsync(p_clickedSpriteUrl).then((texture)=>{
            this.m_clickedSprite = texture;
        });
        
        Promise.all([promise1,promise2]).then((result)=>{
            this.ApplySprite(this.m_isOn);
        });
    }
    
    public get Name(): string {
        return this.m_name;
    }

    public get IsOn(): boolean {
        return this.m_isOn;
    }
    
    public set IsOn(p_value: boolean){
        if (p_value == null) throw new Error('NullReferenceException : p_clicked is null or undefined');
        if(p_value === this.IsOn) return;
        
        this.m_isOn = p_value;
        this.m_onClickCallback(this.m_isOn);
        this.ApplySprite(this.m_isOn);
    }
    
    public GetDisposable(): (Material | BufferGeometry | Texture | WebGLRenderTarget | Object3D)[] {
        return [this.m_button.m_stickerObject];
    }
    
    public GetObject() {
        return this.m_button.m_stickerObject;
    }
    
    private ApplySprite(p_isOn: boolean): void{
        if(p_isOn && this.m_clickedSprite) {
            this.m_button.UpdateMaterialTexture(this.m_clickedSprite);
        }
        else if(!p_isOn && this.m_idleSprite) {
            this.m_button.UpdateMaterialTexture(this.m_idleSprite);
        }
        else
            console.warn("Missing sprite: Something went wrong in Button3DComponent");
    }
    
    private GenerateButton(p_position: Vector3): StickerBillboard {
        return new StickerBillboard("+", "rgba(29,39,64,0.7)", p_position, new Vector3(), 0.003);
    }
}
