import React, { useEffect, useState } from 'react';
import NewsPreview from '../NewsPreview';
import { RootState } from '../../../services/redux/root-reducers';
import NewsCard from '../NewsCard';
import { News } from '../../../domain/domain';
import { useDispatch, useSelector } from 'react-redux';
import { markNewsAsRead, NewsReadState } from '../../../services/redux/reducers/news/news.slice';
import Grid from '@mui/material/Grid';


type NewsRowsProps = {
    newsList: News[];
    unreadNewsList: News[];
    preview: News | undefined;
    setPreview: (preview: News | undefined) => void;
    reloadNewsList: () => void;
};

const NewsGridContainer = ({ newsList, preview, setPreview, reloadNewsList, unreadNewsList }: NewsRowsProps) => {
    const dispatch = useDispatch();
    const markNewsAsReadResponse = useSelector<RootState, NewsReadState>((state) => state.markNewsAsRead);
    const [open, setOpen] = useState<boolean>(false);

    const handleClose = () => {
        setOpen(false);
        setPreview(undefined);
    };

    const isRead = (newsId: number) => {
        let foundUnread =
            unreadNewsList &&
            unreadNewsList.find((el) => {
                return el.id === newsId;
            });
        return !foundUnread;
    };

    useEffect(() => {
        if (markNewsAsReadResponse.payload.errors.length > 0) {
        } else if (markNewsAsReadResponse.payload.content !== null) {
            reloadNewsList();
            setTimeout(() => {
                setPreview(undefined);
            }, 800);
        }
    }, [markNewsAsReadResponse]);

    useEffect(() => {
        preview && setOpen(true);
    }, [preview]);

    return (
        <>
            <Grid container rowSpacing={ 3 }>
                { newsList.map((el: News, index: number) => {
                    return (
                        <Grid item xs={ 12 } key={ index }>
                            <NewsCard
                                setPreview={ setPreview }
                                isRead={ isRead }
                                data={ el }
                            />
                        </Grid>
                    );
                }) }
            </Grid>
            { preview &&
                <NewsPreview
                    open={ open }
                    onClose={ handleClose }
                    data={ preview }
                    unreadNewsList={ unreadNewsList }
                    readNews={ (id) => {
                        dispatch(markNewsAsRead(preview.id));
                    } }
                />
            }
        </>
    );
};

export default NewsGridContainer;
