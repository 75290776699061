import * as React from 'react';
import duplicate from '../../../../Assets/Icons/ContextualMenu/dupliquer.svg';
import { Box, styled, Typography } from '@mui/material';
import ButtonToggleRoom from "../../../../../application3D-common/Components/UI/Composites/ButtonToggleRoom";


const DuplicateButton = () => {
    return (
        <StyledBoxButtonGroup>
            <ButtonToggleRoom
                altLogo='revetement floor'
                logo={ duplicate }
                actions={ () => console.log() }
                text={
                    <Typography variant='subtitle2' color='primary'>
                        Dupliquer
                    </Typography>
                }
            />
        </StyledBoxButtonGroup>
    );
};

const StyledBoxButtonGroup = styled(Box)(({ theme }) => ({
    margin: theme.spacing(0.5),
    height: 65,
    minWidth: 80,
    '& .Mui-disabled': {
        border: 0,
    },
    '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
    },
}));

export default DuplicateButton;
