import { DisableComponent } from "@lutithree/build/Modules/Core/Entity/DisableComponent";
import { Vector3 } from "three";

export class DimensionComponent extends DisableComponent {
    private m_dimensions: Vector3;

    constructor(p_dimensions: Vector3) {
        super();
        this.m_dimensions = p_dimensions;
    }
    
    public get Dimensions(): Vector3 {
        return this.m_dimensions;
    }
}
