import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Camera, Vector2, Vector3 } from 'three';
import { TranslatableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/TranslatableComponent';
import { GroupComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/GroupComponent';
import { MeshLinksComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshLinksComponent';
import AService from '../../../Domain/AService';
import { Engine } from '@lutithree/build/Engine';
import { AutoReorientationComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/AutoReorientationComponent";
import BoundingBoxChangedEvent from "../RelativePositioning/Events/BoundingBoxChangedEvent";
import { TranslationSystem } from "@lutithree/build/Modules/WebGL/Scene/Systems/TranslationSystem";
import { GroupSystem } from "@lutithree/build/Modules/WebGL/Scene/Systems/GroupSystem";
import { MeshEditSystem } from "@lutithree/build/Modules/WebGL/Scene/Systems/MeshEditSystem";
import TranslationOnPlaneChangeEvent from "./Events/TranslationOnPlaneChangeEvent";
import EntityTranslatedEvent from "./Events/EntityTranslatedEvent";
import EntityTranslationEndedEvent from "./Events/EntityTranslationEndedEvent";
import ConnectionsTranslatedEvent from "./Events/ConnectionsTranslatedEvent";
import { TransformControlComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Controls/TransformControlComponent";
import { SelectionSystem } from "@lutithree/build/Modules/WebGL/Scene/Systems/SelectionSystem";
import { SnappableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SnappableComponent";

export default class TranslationOnPlaneService extends AService {
    
    private readonly m_groupSystem: GroupSystem;

    private readonly m_meshEditSystem: MeshEditSystem;

    private readonly m_translationSystem: TranslationSystem;
    
    private readonly m_selectionSystem: SelectionSystem;

    public constructor(p_engine: Engine) {
        super(p_engine);

        this.m_groupSystem = new GroupSystem(p_engine.Modules.Scene);
        this.m_translationSystem = new TranslationSystem(p_engine.Modules.Scene);
        this.m_meshEditSystem = new MeshEditSystem(p_engine.Modules.Scene);
        this.m_selectionSystem = new SelectionSystem(p_engine.Modules.Scene);
    }

    public BeginTranslate(p_entity: SceneEntity, p_initialHitPoint: Vector3, p_viewportPosition: Vector2, p_camera: Camera): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_viewportPosition == null) throw new Error('NullReferenceException : p_viewportPosition is null or undefined');
        if (p_initialHitPoint == null) throw new Error('NullReferenceException : p_initialHitPoint is null or undefined');
        if (p_camera == null) throw new Error('NullReferenceException : p_camera is null or undefined');
        
        // Get target to move
        let targetEntities: SceneEntity[] = this.GetTargetToTranslate(p_entity);

        // If there are targets to move
        if (targetEntities.length > 0) this.m_engine.Modules.EventManager.Publish(TranslationOnPlaneChangeEvent, new TranslationOnPlaneChangeEvent(true));

        // Apply begin translate
        targetEntities.forEach((entity) => {
            if (entity.HasComponentOfType(TranslatableComponent)) {
                entity.GetComponentOfType(TranslatableComponent).InitialPosition = entity.Transform.GetObject().position;
            }
            this.Translate(entity, p_initialHitPoint, p_viewportPosition, p_camera);
        });
    }

    public OnTranslate(p_entity: SceneEntity, p_initialHitPoint: Vector3, p_viewportPosition: Vector2, p_camera: Camera): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_viewportPosition == null) throw new Error('NullReferenceException : p_viewportPosition is null or undefined');
        if (p_initialHitPoint == null) throw new Error('NullReferenceException : p_initialHitPoint is null or undefined');
        if (p_camera == null) throw new Error('NullReferenceException : p_camera is null or undefined');

        // Get target to move
        let targetEntities: SceneEntity[] = this.GetTargetToTranslate(p_entity);
        // Apply translate
        targetEntities.forEach((entity) => {
            this.Translate(entity, p_initialHitPoint, p_viewportPosition, p_camera);
        });
    }

    public EndTranslate(p_entity: SceneEntity): void {
        if (p_entity == null)
            throw new Error('NullReferenceException : p_entity is null or undefined');
        
        // Get target to move
        let targetEntities: SceneEntity[] = this.GetTargetToTranslate(p_entity);
        this.m_engine.Modules.Scene.GetComponents(TransformControlComponent, { entity: false, component: false }).forEach((obj)=>{
            let flag = false;
            let foundEntity;
            this.m_selectionSystem.GetSelectedEntities().forEach((entity)=>{
                if(entity.Transform.GetObject() === obj.GetObject().object) {
                    flag = true;
                    foundEntity = entity;
                }
            });
            if(flag && foundEntity) {
                if((foundEntity as any).HasComponentOfType(AutoReorientationComponent) && (foundEntity as any).HasComponentOfType(SnappableComponent) && (foundEntity as any).GetComponentOfType(SnappableComponent).Snapped.length > 0)
                    obj.Enable(false);
                else
                {
                    if(!obj.Locked)
                        obj.Enable(true);
                }
                if(obj.GetObject().mode === "translate")
                {
                    if(!obj.Locked)
                        obj.Enable(true);
                }
            }
        });

        // If there are targets to move
        if (targetEntities.length > 0) this.m_engine.Modules.EventManager.Publish(TranslationOnPlaneChangeEvent, new TranslationOnPlaneChangeEvent(false));
        
        targetEntities.forEach((entity) => {
            let autorotate = entity.HasComponentOfType(AutoReorientationComponent)?entity.GetComponentOfType(AutoReorientationComponent):undefined;
            if (autorotate) autorotate.ResetRotation();
            if (entity.HasComponentOfType(TranslatableComponent)) {
                entity.GetComponentOfType(TranslatableComponent).LockedWorldDirection = undefined;
            }

            this.m_engine.Modules.EventManager.Publish(EntityTranslationEndedEvent, new EntityTranslationEndedEvent(entity));
            this.m_engine.Modules.LoopStrategy.RequestRender(true);
        });
    }

    private Translate(p_entity: SceneEntity, p_hitPoint: Vector3, p_viewportPosition: Vector2, p_camera: Camera): void {
        let offset: Vector3 = new Vector3();
        let worldMoveResult: { NewLocalPosition: Vector3; WorldTranslation: Vector3 };

        // Get offset
        if (p_entity.HasComponentOfType(AutoReorientationComponent) && p_entity.GetComponentOfType(AutoReorientationComponent).Offsets.length > 0) {
            offset = p_entity.GetComponentOfType(AutoReorientationComponent).ResultingOffset;
        }

        // Get movement information
        worldMoveResult = this.m_translationSystem.Translate(p_entity, p_hitPoint, p_viewportPosition, p_camera, offset);

        this.TryMoveEntity(p_entity, worldMoveResult);
        this.m_engine.Modules.EventManager.Publish(EntityTranslatedEvent, new EntityTranslatedEvent(p_entity));
        this.m_engine.Modules.EventManager.Publish(BoundingBoxChangedEvent, new BoundingBoxChangedEvent(p_entity));
        this.m_engine.Modules.LoopStrategy.RequestRender(false);
    }

    private TryMoveEntity(p_entity: SceneEntity, p_worldMoveResult: { NewLocalPosition: Vector3; WorldTranslation: Vector3 }): void {
        if (p_entity.HasComponentOfType(TranslatableComponent)) {
            let translatable = p_entity.GetComponentOfType(TranslatableComponent);

            if (translatable.IsLockedForTranslation(p_worldMoveResult.WorldTranslation)) return;
            else translatable.LockedWorldDirection = undefined;

            // Try move connections
            let meshConnectedEntities = this.FindGroupedMeshEditionEntities(p_entity);
            if (meshConnectedEntities) {
                this.TryMoveConnections(p_entity, meshConnectedEntities, p_worldMoveResult.WorldTranslation);
            }

            // Move entity
            if (translatable.LockedWorldDirection === undefined) p_entity.Transform.SetPosition(p_worldMoveResult.NewLocalPosition);
        }
    }

    private GetTargetToTranslate(p_entity: SceneEntity): SceneEntity[] {
        let targetEntities: SceneEntity[] = [];

        // Get target to translate
        let planeTranslatablesEntities: SceneEntity[] = this.m_groupSystem.GetGroupEntitiesWith(p_entity, TranslatableComponent);
        targetEntities = targetEntities.concat(planeTranslatablesEntities);
        if (p_entity.HasComponentOfType(TranslatableComponent)) {
            targetEntities.push(p_entity);
        }

        return targetEntities;
    }

    private FindGroupedMeshEditionEntities(p_entity: SceneEntity): SceneEntity[] | undefined {
        let meshLinkedEntities: SceneEntity[] | undefined = undefined;
        if (p_entity.HasComponentOfType(GroupComponent)) {
            meshLinkedEntities = this.m_groupSystem.GetGroupEntitiesWith(p_entity, MeshLinksComponent);
        }
        return meshLinkedEntities;
    }

    private TryMoveConnections(p_entity: SceneEntity, p_meshConnectedEntities: SceneEntity[], p_worldTranslation: Vector3): void {
        if (p_entity.HasComponentOfType(TranslatableComponent)) {
            let translationTarget = p_entity.GetComponentOfType(TranslatableComponent).TranslationTarget;
            let translationNormal: Vector3 = translationTarget instanceof Vector3?new Vector3(0, 1, 0):translationTarget.normal;

            let meshLinksComponents = this.m_engine.Modules.Scene.GetComponentsFromEntities(MeshLinksComponent, p_meshConnectedEntities);

            // Move connection
            meshLinksComponents.forEach((connectable) => {
                this.m_meshEditSystem.MoveConnection(connectable.MeshConnections, p_worldTranslation, translationNormal);
            });
            this.m_engine.Modules.EventManager.Publish(ConnectionsTranslatedEvent, new ConnectionsTranslatedEvent(p_meshConnectedEntities, p_worldTranslation));
        }
    }
}
