import AService from "../../../Domain/AService";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { Object3D, Vector3 } from "three";
import EntityScaledEvent from "./Events/EntityScaledEvent";
import { InversibleComponent } from "./Components/InversibleComponent";
import ScaleSystem from "./Systems/ScaleSystem";
import { Engine } from "@lutithree/build/Engine";
import { DynamicScaleComponent } from "./Components/DynamicScaleComponent";

export default class ScaleService extends AService {

    private readonly m_scaleSystem: ScaleSystem;

    public constructor(p_engine: Engine) {
      super(p_engine);

        this.m_scaleSystem = new ScaleSystem(p_engine.Modules.Scene);
    }

    public GetWorldScale(p_entity: SceneEntity): Vector3 {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        return this.m_scaleSystem.GetWorldScale(p_entity);
    }

    public SetWorldScale(p_entity: SceneEntity, p_newScale: Vector3, p_box3Scale: Vector3): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        let invertFlag = false;
        
        if(p_entity.Transform.GetObject().scale.x < 0)
        {
            this.Invert(p_entity);
            invertFlag = true;
        }
        let targetToScale: SceneEntity = p_entity;
        let targets = this.m_scaleSystem.GetTargetToScale(p_entity);
        let isEntityTheScalableTarget = !(targets.length > 0 && targets[0].Id !== p_entity.Id);
        if (!isEntityTheScalableTarget) {
            targetToScale = targets[0];
        }
        this.m_scaleSystem.SetWorldScale(targetToScale, p_newScale, p_box3Scale);
        
        if(invertFlag)
            this.Invert(p_entity);
        this.m_engine.Modules.EventManager.Publish(EntityScaledEvent, new EntityScaledEvent(p_entity, [targetToScale]));
        this.m_engine.Modules.LoopStrategy.RequestRender(true);
    }

    public Invert(p_entity: SceneEntity): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        if (p_entity.HasComponentOfType(InversibleComponent)) {
            let inversible = p_entity.GetComponentOfType(InversibleComponent);
            let newScale = p_entity.Transform.GetObject().scale.clone().multiply(inversible.InversionVector);
            p_entity.Transform.GetObject().scale.copy(newScale);
            let targets = this.m_scaleSystem.GetTargetToScale(p_entity);

            this.m_engine.Modules.EventManager.Publish(EntityScaledEvent, new EntityScaledEvent(p_entity, targets));
            this.m_engine.Modules.LoopStrategy.RequestRender(true);
        }
    }

    public DynamicRescaleObject(p_object: Object3D, p_scale: number, p_initialScale: Vector3) {
        p_object.scale.set(p_initialScale.x * p_scale, p_initialScale.y * p_scale, p_initialScale.z * p_scale);
    };
    
    public DynamicRescaleRefresh(){
        this.m_engine.Modules.Scene.GetComponents(DynamicScaleComponent, { component: true, entity: true }).forEach((obj)=>{
            obj.UpdateCurrentScale();
            obj.Objects.forEach((sticker)=>{
                this.DynamicRescaleObject(sticker.object, sticker.scale, sticker.initialScale);
            });
        });
    }
}