import IRoomStudioNotifier from '../../Domain/Notifier/IRoomStudioNotifier';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import ViewModeChangedTSEvent from './TS_Events/ViewModeChangedTSEvent';
import ObjectInstanciatedTSEvent from './TS_Events/ObjectInstanciatedTSEvent';
import AssetDataChangedTSEvent from './TS_Events/AssetDataChangedTSEvent';
import MaterialChangedOnIDTSEvent from './TS_Events/MaterialChangedOnIDTSEvent';
import PartTransformChangedTSEvent from "./TS_Events/PartTransformChangedTSEvent";
import ObjectTransformChangedTSEvent from "./TS_Events/ObjectTransformChangedTSEvent";
import GroundRaycastedTSEvent from "./TS_Events/NeutralObjectRaycastedTSEvent";
import NBMeasureMarkerChangeTSEvent from "./TS_Events/NBMeasureMarkerChangeTSEvent";
import {
    ViewMode
} from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Cameras/ViewMode";
import TSBasicNotifierService
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Notifier/TSBasicNotifierService";
import Instance3D
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/Instance3D";
import {
    Asset3DData
} from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Asset3DData";
import TransformUpdates
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Features3D/TransformUpdates";

export default class RoomStudioNotifierService extends TSBasicNotifierService implements IRoomStudioNotifier {

    public NotifyObjectInstanciated(p_entity: SceneEntity, p_instance: Instance3D): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_instance == null) throw new Error('NullReferenceException : p_instance is null or undefined');

        dispatchEvent(new CustomEvent<ObjectInstanciatedTSEvent>('application3d-objectInstanciated', { detail: { Instance: p_instance, EntityID: p_entity.Id } }));
    }

    public NotifyViewModeChanged(p_viewMode: ViewMode): void {
        if (p_viewMode == null) throw new Error('NullReferenceException : p_viewMode is null or undefined');

        dispatchEvent(new CustomEvent<ViewModeChangedTSEvent>('application3d-viewModeChanged', { detail: { ViewMode: p_viewMode } }));
    }

    public NotifyAssetDataChanged(p_refOfInstance: string, p_assetType: string, p_assetData: Asset3DData): void {
        if (!p_refOfInstance) throw new Error('NullReferenceException : p_refOfInstance is null or undefined or empty');
        if (!p_assetType) throw new Error('NullReferenceException : p_assetType is null or undefined or empty');
        if (p_assetData == null) throw new Error('NullReferenceException : p_assetData is null or undefined');

        dispatchEvent(new CustomEvent<AssetDataChangedTSEvent>('application3d-onAssetDataChanged', { detail: { RefOfInstance: p_refOfInstance, Type: p_assetType, Data: p_assetData } }));
    }

    public NotifyMaterialChangedOnIds(p_refOfInstance: string, p_refOfPart: string, p_refOfIds: string[], p_assetData: string[]): void {
        if (!p_refOfInstance) throw new Error('NullReferenceException : p_refOfInstance is null or undefined or empty');
        if (!p_refOfPart) throw new Error('NullReferenceException : p_refOfPart is null or undefined or empty');
        if (!p_refOfIds) throw new Error('NullReferenceException : p_refOfIds is null or undefined or empty');
        if (p_assetData == null) throw new Error('NullReferenceException : p_assetData is null or undefined');

        dispatchEvent(
            new CustomEvent<MaterialChangedOnIDTSEvent>('application3d-onMaterialChangedOnId', { detail: { RefOfInstance: p_refOfInstance, RefOfPart: p_refOfPart, Ids: p_refOfIds, Data: p_assetData } })
        );
    }

    public NotifyPartTransformChanged(p_refOfInstance: string, p_refOfPart: string, p_transform: TransformUpdates): void {
        if (!p_refOfInstance) throw new Error('NullReferenceException : p_refOfInstance is null or undefined or empty');
        if (!p_refOfPart) throw new Error('NullReferenceException : p_refOfPart is null or undefined or empty');
        if (p_transform == null) throw new Error('NullReferenceException : p_transform is null or undefined');

        dispatchEvent(
            new CustomEvent<PartTransformChangedTSEvent>('application3d-onPartTransformChanged', { detail: { RefOfInstance: p_refOfInstance, RefOfPart: p_refOfPart, TransformUpdates: p_transform } })
        );
    }

    public NotifyObjectTransformChanged(p_refOfInstance: string, p_transform: TransformUpdates): void {
        if (p_transform == null) throw new Error('NullReferenceException : p_transform is null or undefined');
        if (!p_refOfInstance) throw new Error('NullReferenceException : p_refOfInstance is null or undefined or empty');

        dispatchEvent(
            new CustomEvent<ObjectTransformChangedTSEvent>('application3d-onObjectTransformChanged', { detail: { RefOfInstance: p_refOfInstance, TransformUpdates: p_transform } })
        );
    }

    public NotifyNeutralObjectRaycasted(): void {
        dispatchEvent(new CustomEvent<GroundRaycastedTSEvent>('application3d-onNeutralObjectRaycasted', { detail: { } }));
    }

    public NotifyNBMesureMarkerChanged(p_nb: number): void{
        dispatchEvent(new CustomEvent<NBMeasureMarkerChangeTSEvent>('application3d-nbMeasureMarkerChangeTSEvent', { detail: { NB: p_nb } }));
    }
}
