import { ModelTree, PresetTree, ProductModel3d, ProductSheet, ProductTree } from '../domain/domain';
import mappingService from '../api/service/mapping-zoning/mapping.services';
import cells3dValuesService from '../api/service/3DCellsValue/3dCellValue.service';

const buildPartElementListForModel3D = (productTree: ProductTree, callback: (atomReferenceList: string[]) => void) => {
    const referenceList: string[] = [];
    const partElements: any = productTree.partTreeList.map((partTree) => {
        const firstAtomInFirstCharacteristicModel3D = partTree.characteristics[0].modelTrees[0].model3d[0];
        referenceList.push(partTree.characteristics[0].modelTrees[0].atom.reference);
        const partElement = {
            dataModelVersion: '1.0.0',
            refOfPart: partTree.reference,
            refOfPartItem: partTree.characteristics[0].reference,
            type: 'Model3d',
            datas: {
                urls: [firstAtomInFirstCharacteristicModel3D.modelUrl],
                hooks: firstAtomInFirstCharacteristicModel3D.anchorPoints.length === 0 ? undefined : firstAtomInFirstCharacteristicModel3D.anchorPoints,
            },
        };
        return partElement;
    });

    const materialAssignments: any = [];

    productTree.partTreeList.forEach((partTree) => {
        const firstAtomInFirstCharacteristicModel3D = partTree.characteristics[0].modelTrees[0].model3d[0];
        if (firstAtomInFirstCharacteristicModel3D.mappings.length === 0) {
            return;
        }
        const partElement = {
            dataModelVersion: '1.0.0',
            refOfPart: partTree.reference,
            refOfPartItem: partTree.characteristics[0].reference,
            type: 'MaterialAssignment',
            refOfMapping: firstAtomInFirstCharacteristicModel3D.mappings[0].id,
            datas: firstAtomInFirstCharacteristicModel3D.mappings[0].zoneList.map((zone) => {
                return {
                    refOfZoning: zone.uuid,
                    dataModelVersion: '1.0.0',
                    urls: [zone.defaultColori.modelUrl],
                    refs: zone.availableIds,
                };
            }),
        };
        materialAssignments.push(partElement);
    });
    const result = partElements.concat(materialAssignments);
    return result;
};

const buildRoomObjectFromProductTree = (productTree: ProductTree, callback: (atomReferenceList: string[]) => void) => {
    const result = {
        dataModelVersion: '1.0.0',
        refOfInstance: productTree.reference,
        type: 'Product',
        behaviours: ['Configurable'],
        composition: {
            dataModelVersion: '1.0.0',
            type: 'Asset3D',
            partElements: buildPartElementListForModel3D(productTree, callback),
        },
    };
    return result;
};

const checkExists = (partElements: any[], refOfInstance: string) => {
    const result = partElements.map(async (el) => {
        if (el.type === 'MaterialAssignment') {
            const exists = await mappingService.checkIfExists(el.refOfMapping);
            if (exists && !exists.content) {
                return { ...el, datas: undefined };
            }
        }
        return el;
    });
    return result;
};

const atomIsMapping = (modelTree: ModelTree) => {
    return (
        modelTree.atom.reference.includes('mapping') ||
        modelTree.atom.reference.includes('zone') ||
        modelTree.atom.reference.includes('material') ||
        modelTree.atom.reference.includes('finish') ||
        modelTree.atom.reference.includes('colori')
    );
};
const buildRoomObjectFromExistingPreset = (preset: PresetTree, presetInfo: ProductSheet) => {

    let behaviours: string[] = [];
    if (presetInfo.productBehaviour.includes('PRESET')) behaviours = ['Configurable'];
    if (presetInfo.hasWallConstraint) behaviours = [...behaviours, 'AutoReorientable'];

    let pointOfView = undefined;
    if (preset.metadata.pointOfView) {
        pointOfView = {
            radius: preset.metadata.pointOfView.radius,
            phi: preset.metadata.pointOfView.phi,
            theta: preset.metadata.pointOfView.theta,
        };
    }

    let dimension = undefined;
    if (presetInfo.dimension) {
        dimension = {
            x: presetInfo.dimension.length,
            y: presetInfo.dimension.height,
            z: presetInfo.dimension.depth,
        };
    }

    const result = {
        dataModelVersion: '1.0.0',
        refOfInstance: preset.metadata.refOfInstance,
        refOfProduct: preset.product.parentProductReference,
        informations: {
            name: presetInfo.name,
            type: presetInfo.productType,
            pointOfView: pointOfView,
            isAutoReorientable: presetInfo.hasWallConstraint,
            isEditable: presetInfo.productBehaviour.includes('PRESET'),
            behaviours: behaviours,
            dimensions: dimension,
            spawnHeight: presetInfo.height,
        },
        composition: {
            ...preset.metadata.composition,
            partElements: [...preset.metadata.composition.partElements],
        },
    };
    return result;
};

/*const buildRoomObjectFromExistingPreset = async (preset: PresetTree, callback: (obj: any) => void) => {
    if (!preset.metadata.composition) {
        const configuration = preset.configuration.filter((atom) => !atomIsMapping(atom));

        const dict = {};

        for (let modelTree of configuration) {
            // @ts-ignore
            dict[modelTree.atom.partReference] = [];
        }

        for (let modelTree of configuration) {
            // @ts-ignore
            dict[modelTree.atom.partReference] = [...dict[modelTree.atom.partReference], modelTree];
        }

        const values: ModelTree[][] = Object.values(dict);
        const model3dList: ProductModel3d[] = [];
        for (let combo of values) {
            const model3d = await cells3dValuesService.getModel3dUrlForCombo(combo.map((el) => el.atom.reference));
            model3dList.push(model3d.content[0]);
        }
        const result = {
            dataModelVersion: '1.0.0',
            refOfInstance: preset.product.reference,
            type: 'Product',
            behaviours: ['Configurable'],
            composition: {
                dataModelVersion: '1.0.0',
                type: 'Asset3D',
                partElements: configuration.map((atom) => {
                    const partElement = {
                        dataModelVersion: '1.0.0',
                        refOfPart: atom.atom.partReference,
                        refOfPartItem: atom.atom.partItemReference,
                        type: 'Model3d',
                        datas: {
                            urls: [model3dList.find((el) => el!==undefined && el.partReference === atom.atom.partReference)?.modelUrl],
                            hooks: (atom.model3d.length === 0 || atom.model3d[0].anchorPoints.length === 0) ? undefined : atom.model3d[0].anchorPoints,
                        },
                    };
                    return partElement;
                }),
            },
        };

        callback(result);
    } else {
        const partEls = checkExists([...preset.metadata.composition.partElements], preset.metadata.refOfInstance);
        Promise.all(partEls).then((res) => {
            const result = {
                dataModelVersion: preset.metadata.dataModelVersion,
                refOfInstance: preset.metadata.refOfInstance,
                type: preset.metadata.type,
                behaviours: preset.metadata.behaviours,
                pointOfView: preset.metadata.pointOfView,
                composition: {
                    ...preset.metadata.composition,
                    partElements: res,
                },
            };
            callback(result);
        });
    }
    // return result;
};*/

export { buildRoomObjectFromProductTree, buildRoomObjectFromExistingPreset, atomIsMapping };


