import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Guid } from 'guid-typescript';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import Instance3D
    from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/Instance3D";

export type EntitiesState = {
    entityIds: { refOfInstance: string; entityId: Guid }[];
    currentEntity: SceneEntity | undefined;
};

const initialEntitiesState: EntitiesState = {
    entityIds: [],
    currentEntity: undefined,
};

const entitiesSlice = createSlice({
    name: 'entities',
    initialState: initialEntitiesState,

    //reducers : define how the state can be updated
    reducers: {
        clearCurrentEntity: (state, action: PayloadAction<void>) => {
           state.currentEntity = undefined;
        },
        setCurrentEntity: (state, action: PayloadAction<{ entity: SceneEntity; isSelected: boolean }>) => {
            if (action.payload) {
                // Selection
                if (action.payload.isSelected) {
                    state.currentEntity = action.payload.entity;
                }
                // Unselection
                else if (state.currentEntity === action.payload.entity) {
                    state.currentEntity = undefined;
                }
            }
        },
        addEntityId: (state, action: PayloadAction<{instance:Instance3D, id:Guid}>) =>{
            if (action.payload) {
                state.entityIds.push({refOfInstance:action.payload.instance.Ref,entityId:action.payload.id});
            }
        },
        removeEntityId: (state, action: PayloadAction<Guid>) =>{
            if (action.payload) {
                let index = state.entityIds.findIndex(x => x.entityId === action.payload);
                state.entityIds.splice(index,1);
            }
        },
    },
});

export const { addEntityId, removeEntityId, setCurrentEntity, clearCurrentEntity} = entitiesSlice.actions;
export default entitiesSlice;
