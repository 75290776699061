import { Object3D } from 'three';
import { Engine } from '@lutithree/build/Engine';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { IEngine } from '@lutithree/build/IEngine';
import ProductStudioServices from "./GameLogic/ProductStudioService";
import ProductStudioModesBuilder from "./GameLogic/Modes/ProductStudioModesBuilder";
import { DimensionComponent } from "../Application3D/GameLogic/Features3D/Dimensions/Components/DimensionComponent";
import ProductStudioHandlers from "./GameLogic/ProductStudioHandlers";
import { Studio } from "../Application3D/GameLogic/Studio/Studio";
import {ProductStudio} from "./GameLogic/Studio/ProductStudio";
import ModeManager from "../Application3D/Domain/Modes/ModeManager";


export default class ProductStudioApp {
    private readonly m_engine: Engine;

    private readonly m_studio: Studio;

    private readonly m_services: ProductStudioServices;
    
    private readonly m_modeManager: ModeManager;

    private m_rootEntities: Map<string, SceneEntity>;

    public constructor(p_engine: Engine) {
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');
        if (!p_engine.IsInitialized()) throw new Error('Engine need to be initialized before being usued!');

        // Build Engine
        this.m_engine = p_engine;
        this.m_rootEntities = new Map<string, SceneEntity>();
        
        // Build application Service
        this.m_services = new ProductStudioServices(this.m_engine, this.m_rootEntities);
        
        // Application modes
        let handlers = new ProductStudioHandlers(this.m_engine, this.m_services);
        let modesBuilder: ProductStudioModesBuilder = new ProductStudioModesBuilder(this.m_engine, this.m_services);
        this.m_modeManager = new ModeManager(modesBuilder.Build());

        handlers.InitEventManagerWithHandlers();
        this.m_studio = new ProductStudio(this.m_rootEntities);
        this.m_studio.Build(this.m_engine);
    }

    public get Services(): ProductStudioServices {
        return this.m_services;
    }

    public get EngineService(): IEngine {
        return this.m_engine;
    }
    
    public get ModesService(): ModeManager {
        return this.m_modeManager;
    }

    public EnableProductMeasures(p_productId: string, p_enabled: boolean): void {
        this.m_engine.Modules.Scene.GetComponents(DimensionComponent).forEach((p_dimensionComponent) => {
            try {
                p_dimensionComponent.Enable(p_enabled);
                Engine.TriggerLastFrameRendering();
            } catch (error) {}
        });
    }

    public VisualizeInAR(p_object: Object3D): void {
        this.m_engine.Modules.AR.ExportToUSDZ(p_object);
    }
}
