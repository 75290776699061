import apiClient from '../../api-client';
import { GET_ALL_NEWS, GET_ALL_UNREAD_NEWS, GET_FILTER_NEWS, GET_NEWS_CATEGORIES, MARK_NEWS_AS_READ } from '../../enpoint/news';
import { NewsCriticality, NewsStatus } from '../../../domain/domain';

const NewsService = {
    getAllNews: async (criticality: NewsCriticality | string | undefined, status: NewsStatus | undefined, page: number | undefined, category: string | undefined) =>
        apiClient(GET_ALL_NEWS, undefined, { criticality, status, page, categoryId: category }, 'GET'),
    getAllUnreadNews: async (criticality: NewsCriticality | string | undefined, status: NewsStatus | undefined, page: number | undefined, category: string | undefined) =>
        apiClient(GET_ALL_UNREAD_NEWS, undefined, { criticality, status, page, categoryId: category }, 'GET'),
    getNewsCategories: async () => apiClient(GET_NEWS_CATEGORIES, undefined, undefined, 'GET'),
    getFilterNews: async (searchValue: string) => apiClient(`${GET_FILTER_NEWS}${searchValue}`, undefined, undefined, 'GET'),
    markNewsAsRead: async (newsId: number) => apiClient(`${MARK_NEWS_AS_READ(newsId)}`, undefined, undefined, 'POST'),
};
export default NewsService;
