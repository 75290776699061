import RoomStudioServices from "../RoomStudioServices";
import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import { IEvent } from "@lutithree/build/Modules/Core/Event/IEvent";
import { Engine } from "@lutithree/build/Engine";
import WallComponent from "../Objects/Components/WallComponent";
import { SelectableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SelectableComponent";
import { GroupComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/GroupComponent";
import { RotControlableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/RotControlableComponent";
import { TranslatableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/TranslatableComponent";
import { YControlableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/YControlableComponent";
import { Object3D, Plane, Vector3 } from "three";
import { BoundingBoxComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundingBoxComponent";
import { SnappableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SnappableComponent";
import AContextualBehaviours from "../../../../../../application3D-common/Librairies/FSM/AContextualBehaviours";
import EntityStopMovingEvent from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Events/EntityStopMovingEvent";
import EntityTranslationEndedEvent from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Events/EntityTranslationEndedEvent";
import EntitySelectionStatusDirtyEvent
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Selection/Events/EntitySelectionStatusDirtyEvent";
import SelectionHandler
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Handlers/SelectionHandler";
import {
    TemporaryNodeComponent
} from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Selection/Components/TemporaryNodeComponent";

export default class MultiSelectionBehaviours extends AContextualBehaviours<{ Engine: Engine; Services: RoomStudioServices; Handlers: ReadonlyMap<string, IHandler<IEvent>> }> {
    
    public constructor(p_engine: Engine, p_services: RoomStudioServices, p_handlers: ReadonlyMap<string, IHandler<IEvent>>) {
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');
        if (p_services == null) throw new Error('NullReferenceException : p_services is null or undefined');
        super({ Engine: p_engine, Services: p_services, Handlers:p_handlers });
    }

    public Enter(): void {
        console.log('MultiSelectionBehaviours Enter!');
        
        let node = this.AddMultiSelectionNode();
        this.UpdateSelectedEntities();
       
        
        let selectionHandler = this.m_context.Handlers.get('selectionHandler');
        if(selectionHandler) {
            (selectionHandler as SelectionHandler).AllowMultiSelection = true;
        }
        
        let selectionEffectsHandler = this.m_context.Handlers.get('selectionEffectsHandler');
        if(selectionEffectsHandler){
            this.m_context.Engine.Modules.EventManager.UnSuscribe(EntitySelectionStatusDirtyEvent, selectionEffectsHandler);
        } else console.warn('There is no selectionHandler key in RoomStudioMapHandlers');
        
        let multiSelectionEffectsHandler = this.m_context.Handlers.get('multiSelectionEffectsHandler');
        if(multiSelectionEffectsHandler){
            this.m_context.Engine.Modules.EventManager.Suscribe(EntitySelectionStatusDirtyEvent, multiSelectionEffectsHandler);
        } else console.warn('There is no multiSelectionEffectsHandler key in RoomStudioMapHandlers');

        let multiSelectionEndMoveHandler = this.m_context.Handlers.get('multiSelectionEndMoveHandler');
        if(multiSelectionEndMoveHandler){
            this.m_context.Engine.Modules.EventManager.Suscribe(EntityTranslationEndedEvent, multiSelectionEndMoveHandler);
            this.m_context.Engine.Modules.EventManager.Suscribe(EntityStopMovingEvent, multiSelectionEndMoveHandler);
        } else console.warn('There is no multiSelectionEndMoveHandler key in RoomStudioMapHandlers');

        this.AddSelectedEntities(node);
        this.EnableWallSelectables(false);
        
        this.m_context.Engine.Modules.LoopStrategy.RequestRender(true);
    }

    public Exit(): void {
        console.log('MultiSelectionBehaviours Exit!');

        this.m_context.Services.Selection.UnSelectAll();
        
        let multiSelectionEffectsHandler = this.m_context.Handlers.get('multiSelectionEffectsHandler');
        if(multiSelectionEffectsHandler){
            this.m_context.Engine.Modules.EventManager.UnSuscribe(EntitySelectionStatusDirtyEvent, multiSelectionEffectsHandler);
        } else console.warn('There is no multiSelectionEffectsHandler key in RoomStudioMapHandlers');

        let selectionEffectsHandler = this.m_context.Handlers.get('selectionEffectsHandler');
        if(selectionEffectsHandler){
            this.m_context.Engine.Modules.EventManager.Suscribe(EntitySelectionStatusDirtyEvent, selectionEffectsHandler);
        } else console.warn('There is no selectionHandler key in RoomStudioMapHandlers');

        let multiSelectionEndMoveHandler = this.m_context.Handlers.get('multiSelectionEndMoveHandler');
        if(multiSelectionEndMoveHandler){
            this.m_context.Engine.Modules.EventManager.UnSuscribe(EntityTranslationEndedEvent, multiSelectionEndMoveHandler);
            this.m_context.Engine.Modules.EventManager.UnSuscribe(EntityStopMovingEvent, multiSelectionEndMoveHandler);
        } else console.warn('There is no multiSelectionEndMoveHandler key in RoomStudioMapHandlers');
        
        let selectionHandler = this.m_context.Handlers.get('selectionHandler');
        if(selectionHandler) {
            (selectionHandler as SelectionHandler).AllowMultiSelection = false;
        }
        
        this.EnableWallSelectables(true);
        
        let multiselectionNode = this.FindMultiSelectionNode();
        if(multiselectionNode) multiselectionNode.ClearNode();
        this.RemoveMultiSelectionNode();
        
        this.m_context.Engine.Modules.LoopStrategy.RequestRender(true);
    }
    
    private AddMultiSelectionNode(): TemporaryNodeComponent {
        let entity = this.m_context.Engine.Modules.Scene.CreateEntity('MultiSelectionNode');
        
        entity.AddComponentOfType(GroupComponent, 'feature-multiSelection-move');
        entity.AddComponentOfType(BoundingBoxComponent, entity.Transform.GetObject());
        let node = entity.AddComponentOfType(TemporaryNodeComponent);
        let normal = new Vector3(0, 1, 0);
        let plane = new Plane(normal, entity.Transform.GetObject().position.dot(normal));
        entity.AddComponentOfType(TranslatableComponent, plane);
        entity.AddComponentOfType(YControlableComponent, Object3D.DEFAULT_UP);
        entity.AddComponentOfType(RotControlableComponent);
        entity.AddComponentOfType(SnappableComponent);
        return node;
    }
    
    private RemoveMultiSelectionNode(): void {
        let nodes = this.m_context.Engine.Modules.Scene.GetComponents(TemporaryNodeComponent);
        nodes.forEach((component)=>{
            let entity = this.m_context.Engine.Modules.Scene.GetEntityByID(component.EntityID);
            let groups = entity.GetComponentsOfType(GroupComponent);
            groups.forEach((group)=>{
                if(group.GroupRef === 'feature-multiSelection-move') this.m_context.Engine.Modules.Scene.RemoveEntityByID(component.EntityID);
            });
        });
    }
    
    private AddSelectedEntities(p_node: TemporaryNodeComponent): void {
        let selectedEntities = this.m_context.Services.Selection.GetSelectedEntities();
        selectedEntities.forEach((entity)=>{
            //p_node.AddChild(entity.Transform.GetObject());
            this.m_context.Engine.Modules.EventManager.Publish(EntitySelectionStatusDirtyEvent, new EntitySelectionStatusDirtyEvent(entity, entity.GetComponentOfType(SelectableComponent)));
        });
    }

    private FindMultiSelectionNode(): TemporaryNodeComponent|undefined {
        let node: TemporaryNodeComponent|undefined  = undefined;
        let groupComponents = this.m_context.Engine.Modules.Scene.GetComponents(GroupComponent);
        groupComponents = groupComponents.filter(component => component.GroupRef === 'feature-multiSelection-move');
        let multiSelectionGroupNode = groupComponents.length>0? groupComponents[0] : undefined;
        if(multiSelectionGroupNode){
            let entity = this.m_context.Engine.Modules.Scene.GetEntityByID(multiSelectionGroupNode.EntityID);
            node = entity.HasComponentOfType(TemporaryNodeComponent)?entity.GetComponentOfType(TemporaryNodeComponent):undefined;
        }
        return node;
    }
    
    private EnableWallSelectables(p_value: boolean): void {
        let wallEntities = this.m_context.Engine.Modules.Scene.GetEntitesWithComponents([WallComponent], {entity: false, component: false});
        wallEntities.forEach((wallEntity)=>{
            let selectable = wallEntity.HasComponentOfType(SelectableComponent, false)?wallEntity.GetComponentOfType(SelectableComponent):undefined;
            if(selectable) {
                selectable.Enable(p_value);
            }
        });
    }
    
    private UpdateSelectedEntities(): void {
        let selectedEntities = this.m_context.Services.Selection.GetSelectedEntities();
        selectedEntities.forEach((selectedEntity)=>{
            if(selectedEntity.HasComponentOfType(WallComponent)){
                this.m_context.Services.Selection.UnSelectEntity(selectedEntity);
            }
            else{
                this.m_context.Services.SelectionEffects.EnableYupGizmo(selectedEntity, false);
                this.m_context.Services.SelectionEffects.EnableTranslationOnPlane(selectedEntity, false);
                this.m_context.Services.SelectionEffects.EnableRotationGizmo(selectedEntity, false);
                this.m_context.Services.SelectionEffects.EnableRelativePositioning(selectedEntity, false);
            }
        });
    }
}