import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { Engine } from "@lutithree/build/Engine";
import { DomElementComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Rendering/DomElementComponent";
import { Box, LightComponent, LightTypes } from "@lutithree/build/Modules/WebGL/Scene/Components/Rendering/LightComponent";
import { ColorRepresentation, Vector3 } from "three";
import { DataTextureLoader } from "@lutithree/build/Modules/WebGL/Resources/Load/DataTextureLoader";
import { EnvironmentComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Rendering/EnvironmentComponent";
import { CameraComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Rendering/CameraComponent";
import { Camera3dDecorator } from "./Cameras/EntityDecorators/Camera3dDecorator";
import { BackgroundEnvmapDecorator } from "./Environment/EntityDecorators/BackgroundEnvmapDecorator";
import { InvisibleShadowGroundDecorator } from "./Environment/EntityDecorators/InvisibleShadowGroundDecorator";
import { MeshFilterEnabler } from "../Features3D/Hooks/ComponentEnablers/MeshFilterEnabler";
import { MeshFilterComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent";
import {SeethroughEnvmapDecorator} from "./Environment/EntityDecorators/SeethroughEnvmapDecorator";


export class Studio {
    protected m_rootEntities: Map<string, SceneEntity>;
    
    public constructor(p_rootEntities: Map<string, SceneEntity>) {
        if (p_rootEntities == null) throw new Error('NullReferenceException : p_rootEntities is null or undefined');

        this.m_rootEntities = p_rootEntities;
    }

    public Build(p_engine: Engine, p_pathBackgroundDHR: string | ColorRepresentation = 0x8c8c8c): void {
        let domEntity = p_engine.Modules.Scene.CreateEntity('DomEntity');
        domEntity.AddComponentOfType(DomElementComponent, p_engine.Modules.Rendering.Renderer.domElement);
        
        this.BuildStudioRootEntities(p_engine);
        this.AddComponentEnablers(p_engine);
        this.AddCameras(p_engine);
        this.AddShadows(p_engine);
        this.AddLightning(p_engine);
        this.LoadEnvironnementsAsync(p_engine, 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/envmaps/brown_photostudio_04_1k.hdr', p_pathBackgroundDHR);
    }
    
    protected AddComponentEnablers(p_engine: Engine): void {
        let meshFilterEnabler = new MeshFilterEnabler(p_engine.Modules.EventManager, p_engine.Modules.Scene, p_engine.Modules.Systems);
        p_engine.Modules.Scene.ComponentEnabler.Suscribe(MeshFilterComponent, meshFilterEnabler);
    }
    

    protected BuildStudioRootEntities(p_engine: Engine): void {

        let studioEntity = p_engine.Modules.Scene.CreateEntity('Studio');

        let lightingEntity =  p_engine.Modules.Scene.CreateEntity('Lighting');
        let envEntity =  p_engine.Modules.Scene.CreateEntity('Environement');
        let camerasEntity =  p_engine.Modules.Scene.CreateEntity('Cameras');

        studioEntity.Transform.AddChild(camerasEntity.Transform);
        studioEntity.Transform.AddChild(lightingEntity.Transform);
        studioEntity.Transform.AddChild(envEntity.Transform);

        this.m_rootEntities.set('Lighting', lightingEntity);
        this.m_rootEntities.set('Environement', envEntity);
        this.m_rootEntities.set('Cameras', camerasEntity);
    }

    protected AddLightning(p_engine: Engine): void {
        let environementEntity: SceneEntity | undefined;
        if (this.m_rootEntities.has('Lighting')) environementEntity = this.m_rootEntities.get('Lighting');

        let cameraBox: Box = {
            near: 5,
            far: 25,
            left: -8,
            right: 8,
            top: 8,
            bottom: -8,
            bias: 0.0,
            normalBias: 0.03,
        };

        // Add light
        let lightEntity1 = p_engine.Modules.Scene.CreateEntity('Directional_Light1');
        if (environementEntity) environementEntity.Transform.AddChild(lightEntity1.Transform);
        lightEntity1.AddComponentOfType(LightComponent, LightTypes.Directional, new Vector3(-3.9, 9, 2.8), true, 0.33, cameraBox);

        let lightEntity2 = p_engine.Modules.Scene.CreateEntity('Directional_Light2');
        if (environementEntity) environementEntity.Transform.AddChild(lightEntity2.Transform);
        lightEntity2.AddComponentOfType(LightComponent, LightTypes.Directional, new Vector3(-3.7, 8, 2.8), true, 0.33, cameraBox);

        let lightEntity3 = p_engine.Modules.Scene.CreateEntity('Directional_Light3');
        if (environementEntity) environementEntity.Transform.AddChild(lightEntity3.Transform);
        lightEntity3.AddComponentOfType(LightComponent, LightTypes.Directional, new Vector3(-3.5, 9, 2.8), true, 0.33, cameraBox);
    }

    protected async LoadEnvironnementsAsync(p_engine: Engine, p_pathLightingDHR: string, p_pathBackgroundDHR: string | ColorRepresentation): Promise<void> {
        // Create Environement_Light
        let textureAsset: DataTextureLoader = new DataTextureLoader(p_pathLightingDHR);
        let lightEnvEntity = p_engine.Modules.Scene.CreateEntity('Environement');
        if (this.m_rootEntities.has('Environement')) this.m_rootEntities.get('Environement')!.Transform.AddChild(lightEnvEntity.Transform);
        textureAsset.LoadAsync().then((texture) => {
            lightEnvEntity.AddComponentOfType(EnvironmentComponent, p_engine.Modules.Rendering.Renderer, p_engine.Modules.Scene.ThreeScene, texture);
            p_engine.Modules.LoopStrategy.RequestRender(true);
        });

        // Create Environement_Background
        let bckgdEnvEntity = p_engine.Modules.Scene.CreateEntity('Environement_Background');
        new BackgroundEnvmapDecorator(p_pathBackgroundDHR, p_engine.Modules.Rendering.Renderer).DecorateAsync(bckgdEnvEntity);
        if (this.m_rootEntities.has('Environement')) this.m_rootEntities.get('Environement')!.Transform.AddChild(bckgdEnvEntity.Transform);

        // Create Environement_Background
        let seethroughEnvEntity = p_engine.Modules.Scene.CreateEntity('Seethrough_Background');
        new SeethroughEnvmapDecorator('https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/envmaps/studio_garden_small.jpg', p_engine.Modules.Rendering.Renderer).DecorateAsync(seethroughEnvEntity);
        if (this.m_rootEntities.has('Environement')) this.m_rootEntities.get('Environement')!.Transform.AddChild(seethroughEnvEntity.Transform);
    }

    protected AddCameras(p_engine: Engine): void {
        let dom = p_engine.Modules.Rendering.Renderer.domElement;
        let parentNode = p_engine.Modules.Rendering.RendererParentNode;

        let camera3dEntity: SceneEntity = p_engine.Modules.Scene.CreateEntity('PerspectiveCamera');
        new Camera3dDecorator(dom, () => {
            p_engine.Modules.LoopStrategy.RequestRender(false);
        }).Decorate(camera3dEntity);
        let cameraComponent = camera3dEntity.GetComponentOfType(CameraComponent);
        p_engine.Modules.Rendering.ChangeCamera(cameraComponent.GetObject());

        if (this.m_rootEntities.has('Cameras')) this.m_rootEntities.get('Cameras')!.Transform.AddChild(camera3dEntity.Transform);
    }

    protected AddShadows(p_engine: Engine): void {
        // Add invisible shadow ground
        let invisibleGround = p_engine.Modules.Scene.CreateEntity('Invisible_Shadow_ground');
        new InvisibleShadowGroundDecorator().Decorate(invisibleGround);
        if (this.m_rootEntities.has('Environement')) this.m_rootEntities.get('Environement')!.Transform.AddChild(invisibleGround.Transform);
    }
}