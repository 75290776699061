import { IHandler } from '@lutithree/build/Modules/Core/Event/IHandler';
import ObjectScaledEvent from "../Events/ObjectScaledEvent";
import PartScaledEvent from "../Events/PartScaledEvent";
import ARoomStudioHandler from "../../../ARoomStudioHandler";
import EntityTranslationEndedEvent from '../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Events/EntityTranslationEndedEvent';
import EntityStopMovingEvent from '../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Events/EntityStopMovingEvent';
import TransformUpdates
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Features3D/TransformUpdates";
import ObjectComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";

export class ObjectTransformChangedHandler extends ARoomStudioHandler implements 
    IHandler<EntityTranslationEndedEvent>, 
    IHandler<EntityStopMovingEvent>,
    IHandler<ObjectScaledEvent>,
    IHandler<PartScaledEvent> {
    
    public Handle(p_event: EntityStopMovingEvent|EntityTranslationEndedEvent|ObjectScaledEvent|PartScaledEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');

        if(p_event instanceof EntityStopMovingEvent || p_event instanceof EntityTranslationEndedEvent) 
            this.HandleRoomObjectEndMove(p_event);
        else if(p_event instanceof ObjectScaledEvent /*&& p_event.ObjectComponent instanceof RoomObjectComponent*/) 
            this.HandleRoomObjectScaled(p_event, p_event.ObjectComponent);
        else if(p_event instanceof PartScaledEvent /*&& p_event.ObjectComponent instanceof RoomObjectComponent*/) 
            this.HandleRoomObjectPartScaled(p_event, p_event.ObjectComponent);
    }

    private HandleRoomObjectEndMove(p_event: EntityStopMovingEvent|EntityTranslationEndedEvent): void {
        let objectComponent = p_event.Entity.HasComponentOfType(ObjectComponent)? p_event.Entity.GetComponentOfType(ObjectComponent):undefined;
        if(objectComponent){
            let transfromUpdates: TransformUpdates = new TransformUpdates(p_event.Entity.Transform.GetObject());
            this.m_services.Notifier.NotifyObjectTransformChanged(objectComponent.Ref, transfromUpdates);
        }

        if(this.m_engine.Modules.Systems.SelectionSystem.GetSelectedEntities().includes(p_event.Entity))
            this.m_services.Helper.EnableRelativePositioningOnEntity(p_event.Entity);

        this.m_services.Selection.EnableEntityControlFeature(true);
        this.m_services.Dimemsions.DisableDimensionOnEntity(p_event.Entity);
    }

    private HandleRoomObjectScaled(p_event: ObjectScaledEvent, p_objectComponent: ObjectComponent): void {
        let transfromUpdates = new TransformUpdates({ position: undefined, forward: undefined, scale: p_event.Entity.Transform.GetObject().scale.clone() });
        this.m_services.Notifier.NotifyObjectTransformChanged(p_objectComponent.Ref,transfromUpdates);

    }

    private HandleRoomObjectPartScaled(p_event: PartScaledEvent, p_objectComponent: ObjectComponent): void {
        let transfromUpdates = new TransformUpdates({ position: undefined, forward: undefined, scale: p_event.PartEntity.Transform.GetObject().scale.clone() });
        this.m_services.Notifier.NotifyPartTransformChanged(p_objectComponent.Ref, p_event.RefOfPart,transfromUpdates);
    }
}
