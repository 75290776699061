import React from "react";
import { Button, styled } from "@mui/material";


type SaveCancelButton = {
    onClickCancel: () => void;
    onSubmit: () => void;
    disabled?: boolean;
};

const SaveCancelButton = ({ onClickCancel, onSubmit, disabled }: SaveCancelButton) => {
    return (
        <>
            <CustomBtnCancel onClick={ onClickCancel } sx={ { mr: 1 } } disableElevation>
                Annuler
            </CustomBtnCancel>
            <CustomBtnValid disabled={ disabled } onClick={ onSubmit } color='secondary' variant='contained'
                            disableElevation>
                Valider
            </CustomBtnValid>
        </>
    );
};

const CustomBtnCancel = styled(Button)(({ theme }) => ({
        "&:hover": {
            backgroundColor: theme.palette.grey[300]
        },
        background: theme.palette.grey[100],
        color: theme.palette.primary.main,
        padding: "6px 16px",
        fontSize: "0.875rem",
        textTransform: "capitalize"
    })
);

const CustomBtnValid = styled(Button)(({ theme }) => ({
        padding: "6px 16px",
        fontSize: "0.875rem",
        textTransform: "capitalize",
        "&.Mui-disabled": {
            backgroundColor: "#c9d1eb",
            color: theme.palette.primary.main
        }
    })
);

export default SaveCancelButton;