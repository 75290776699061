/*eslint-disable*/
export enum ObjectAction {
    Paint,
    Move,
    Edit,
    Scale,
    Replace,
    Duplicate,
    Invert,
    Delete,
    GetInformations,
}
