import React from 'react';
import './popup-inconsistent-choice.scss';
import panelForbidden from '../../../../../seller/src/asset/panel-forbiden.png';

type PopupInconsistentChoiceProps = {
    closeOnclick: () => void;
    actionOnclick: () => void;
};

const PopupInconsistentChoice = ({actionOnclick, closeOnclick}: PopupInconsistentChoiceProps) => {
    return (
        <div className='popupInconsistentChoice-main'>
            <div className='popupInconsistentChoice-paper'>
                <div className='popupInconsistentChoice-left'>
                    <span className='popupInconsistentChoice-container-img'>
                        <img className='popupInconsistentChoice-img' alt='panelForbiden' src={panelForbidden} />
                    </span>
                </div>
                <div className='popupInconsistentChoice-center'>
                    <div className='popupInconsistentChoice-main-title'>
                        L'element selectionné n'est pas compatible <br /> avec la configuration courante du produit.
                    </div>
                    <div className='popupInconsistentChoice-content'>
                        Voulez-vous voir une suggestion de configuration <br /> contenant cet élément ?
                    </div>
                    <button onClick={actionOnclick} className='popupInconsistentChoice-button'>
                        Voir une suggestion de configuration
                    </button>
                </div>
                <div className='popupInconsistentChoice-right'>
                    <div onClick={closeOnclick} className='popupInconsistentChoice-close-btn'>✕</div>
                </div>
            </div>
        </div>
    );
};

export default PopupInconsistentChoice;