import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import BasicObject from '../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject';
import AssignmentData from '../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/AssignmentData';
import ConnectorClickedTSEvent from '../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Notifier/TSEvents/ConnectorClickedTSEvent';
import EntitySelectedTSEvent from '../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Notifier/TSEvents/EntitySelectedTSEvent';
import AssetDataChangedTSEvent from '../../Libraries/Studios/RoomStudio/GameLogic/Notifier/TS_Events/AssetDataChangedTSEvent';
import MaterialChangedOnIDTSEvent from '../../Libraries/Studios/RoomStudio/GameLogic/Notifier/TS_Events/MaterialChangedOnIDTSEvent';
import ObjectTransformChangedTSEvent from '../../Libraries/Studios/RoomStudio/GameLogic/Notifier/TS_Events/ObjectTransformChangedTSEvent';
import PartTransformChangedTSEvent from '../../Libraries/Studios/RoomStudio/GameLogic/Notifier/TS_Events/PartTransformChangedTSEvent';
import ViewModeChangedTSEvent from '../../Libraries/Studios/RoomStudio/GameLogic/Notifier/TS_Events/ViewModeChangedTSEvent';
import { useRoomObject } from "./RoomStudioHooks";
import RoomStudioApp from "../../Libraries/Studios/RoomStudio/RoomStudioApp";
import { editSingleMaterialInObject } from '../../Utils/edit-object-materials';
import {
    setCurrentEntity,
    setSelectedConnector,
    updateViewMode
} from "../../Redux/Reducers/room-studio/RoomStudioReducer";
import {
    updateAssetData,
    updateInstance,
    updatePartTransform
} from "../../Redux/Reducers/room-studio/RoomContentReducer";


type PartMaterialIdToEdit = MaterialChangedOnIDTSEvent;

export type RoomStudioListnerProps = {
    app: RoomStudioApp;
};

const RoomStudioListner = (props: RoomStudioListnerProps) => {
    const dispatch = useDispatch();
    
    const [editPartMaterialIDEvent, setEditPartMaterialIDEvent] = useState<PartMaterialIdToEdit | undefined>(undefined);
    const roomObjecOfMaterialIDEditing = useRoomObject(editPartMaterialIDEvent?editPartMaterialIDEvent.RefOfInstance:editPartMaterialIDEvent);

    function isMaterialIdDataUpToDate(p_editPartMaterialIDEvent:PartMaterialIdToEdit, p_object: BasicObject): boolean {
        let asset = p_object.Assets.find(a => a.RefOfPart === p_editPartMaterialIDEvent.RefOfPart && a.Type === 'MaterialAssignment');
        if(asset) {
            let assignementDatas = asset.Datas as AssignmentData[];
            let assignement = assignementDatas.find( x => Array.isArray(x.Refs) &&
                Array.isArray(p_editPartMaterialIDEvent.Ids) &&
                x.Refs.length === p_editPartMaterialIDEvent.Ids.length &&
                x.Refs.every((val, index) => val === p_editPartMaterialIDEvent.Ids[index]));

            if(assignement){
                if(Array.isArray(assignement.Urls) &&
                    Array.isArray(p_editPartMaterialIDEvent.Data) &&
                    assignement.Urls.length === p_editPartMaterialIDEvent.Data.length &&
                    assignement.Urls.every((val, index) => val === p_editPartMaterialIDEvent.Data[index])){

                    return true;
                }
                else return false;
            }
            else return false;
        }
        else return false;
    }

    useEffect(() => {
        function handleEntitySelected(ev: CustomEvent<EntitySelectedTSEvent>) {
            if(props.app.EngineService.Scene.HasEntityWithID(ev.detail.EntityID)){
                let entity = props.app.EngineService.Scene.GetEntityByID(ev.detail.EntityID);
                dispatch(setCurrentEntity({ entity: entity, isSelected: ev.detail.IsSelected }));
            }
        }

        window.addEventListener('application3d-onEntitySelected', handleEntitySelected);

        return () => {
            window.removeEventListener('application3d-onEntitySelected', handleEntitySelected);
        };
    }, []);

    useEffect(() => {
        function handleConnectorSelected(ev: CustomEvent<ConnectorClickedTSEvent>) {
            if(props.app.EngineService.Scene.HasEntityWithID(ev.detail.EntityID)){
                dispatch(setSelectedConnector({ refOfConnector: ev.detail.RefOfConnector, isSelected: ev.detail.IsSelected }));
            }
        }

        window.addEventListener('application3d-onConnectorClicked', handleConnectorSelected);

        return () => {
            window.removeEventListener('application3d-onConnectorClicked', handleConnectorSelected);
        };
    }, []);

    useEffect(() => {
        function handleViewModeChanged(ev: CustomEvent<ViewModeChangedTSEvent>) {
            dispatch(updateViewMode(ev.detail.ViewMode));
        }

        window.addEventListener('application3d-viewModeChanged', handleViewModeChanged);
        return () => {
            window.removeEventListener('application3d-viewModeChanged', handleViewModeChanged);
        };
    }, []);


    useEffect(() => {
        function handleMaterialChangedOnId(ev: CustomEvent<MaterialChangedOnIDTSEvent>) {
            setEditPartMaterialIDEvent(ev.detail);
        }

        window.addEventListener('application3d-onMaterialChangedOnId', handleMaterialChangedOnId);

        return () => {
            window.removeEventListener('application3d-onMaterialChangedOnId', handleMaterialChangedOnId);
        };
    }, []);

    useEffect(() => {
        function handleObjectAssetChanged(ev: CustomEvent<AssetDataChangedTSEvent>) {
            dispatch(updateAssetData({ refOfInstance: ev.detail.RefOfInstance, type: ev.detail.Type, data: ev.detail.Data }));
        }

        window.addEventListener('application3d-onAssetDataChanged', handleObjectAssetChanged);

        return () => {
            window.removeEventListener('application3d-onAssetDataChanged', handleObjectAssetChanged);
        };
    }, []);

    useEffect(() => {
        function handlePartTransformChanged(ev: CustomEvent<PartTransformChangedTSEvent>) {
            dispatch(updatePartTransform(
                { refOfInstance: ev.detail.RefOfInstance, refOfPart:ev.detail.RefOfPart, transformUpdates: ev.detail.TransformUpdates}));
        }

        window.addEventListener('application3d-onPartTransformChanged', handlePartTransformChanged);

        return () => {
            window.removeEventListener('application3d-onPartTransformChanged', handlePartTransformChanged);
        };
    }, []);

    useEffect(() => {
        function handleObjectTransformChanged(ev: CustomEvent<ObjectTransformChangedTSEvent>) {
            dispatch(updateInstance({ refOfInstance: ev.detail.RefOfInstance, transformUpdates: ev.detail.TransformUpdates }));
        }

        window.addEventListener('application3d-onObjectTransformChanged', handleObjectTransformChanged);

        return () => {
            window.removeEventListener('application3d-onObjectTransformChanged', handleObjectTransformChanged);
        };
    }, []);

    useEffect(() => {
        if(editPartMaterialIDEvent && roomObjecOfMaterialIDEditing){
            if (!isMaterialIdDataUpToDate(editPartMaterialIDEvent, roomObjecOfMaterialIDEditing)) {
                let assignementData = new AssignmentData();
                assignementData.LoadData(editPartMaterialIDEvent.Ids, editPartMaterialIDEvent.Data);
                editSingleMaterialInObject(roomObjecOfMaterialIDEditing, editPartMaterialIDEvent.RefOfPart, assignementData , dispatch);
            }
        }
    }, [editPartMaterialIDEvent, roomObjecOfMaterialIDEditing]);

    return <></>;
};

export { RoomStudioListner };
