import {AComponent} from "@lutithree/build/Modules/Core/Entity/AComponent";
import {IComponent} from "@lutithree/build/Modules/Core/Entity/IComponent";

export default class WallComponent extends AComponent implements IComponent {
    private m_shapeSectionIndex: number;

    private m_refOfInstance: string;
    
    public constructor(p_refOfInstance: string) {
        super();
        this.m_shapeSectionIndex = -1;
        this.m_refOfInstance = p_refOfInstance;
    }

    public get RefOfInstance(): string {
        return this.m_refOfInstance;
    }

    public get ShapeSectionIndex(): number {
        return this.m_shapeSectionIndex;
    }

    public set ShapeSectionIndex(p_value: number) {
        if (p_value < 0) throw new Error('NullReferenceException : ShapeSectionIndex cannot be negative '+p_value);

        this.m_shapeSectionIndex = p_value;
    }
}
