import './add-product-panel.scss';
import loupe from '../../../asset/loupe.svg';
import { useDispatch, useSelector } from 'react-redux';
import { OpenAddProductPanel } from '../../../services/redux/reducers/panel/panel.slice';
import close from '../../../asset/CloseWindow.svg';
import { BasketProduct, PresetTree, Product, ProductSheet, RoomResponse, SectionWithSubSections, SubSection, TreeCatalog } from '../../../domain/domain';
import { RootState } from '../../../services/redux/root-reducers';
import { useEffect, useState } from 'react';
import back from '../../../asset/back.svg';
import logoMdf from '../../../asset/mdf.png';
import { buildRoomObjectFromExistingPreset } from '../../../utils/room-object-factory-v2';
import { TreeCatalogState } from '../../../services/redux/reducers/catalog/catalog.slice';
import { generateUUID } from 'three/src/math/MathUtils';
import configuredProductService from '../../../services/redux/reducers/products/configured-product/configured-product.service';
import CatalogServices from '../../../api/service/catalog/catalog.services';
import catalogServices from '../../../api/service/catalog/catalog.services';
import ProductCatalogSearchResults from './product-panel-list/product-catalog-search-results';
import BasicObject from '../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject';
import { ObjectParser } from '../../../application-3d/application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/ObjectParser';
import { addObjects } from '../../../application-3d/application3D-seller/Redux/Reducers/room-studio/RoomContentReducer';
import { RoomStudioState } from '../../../application-3d/application3D-seller/Redux/Reducers/room-studio/RoomStudioReducer';

type AddProductProps = {
    setOpenPanel: (OpenPanel: boolean) => void;
    setRoom: (room: RoomResponse | undefined) => void;
    room: RoomResponse | undefined;
    basketProducts: BasketProduct[];
    setBasketProducts: (basketProducts: BasketProduct[]) => void;
    saveConfigurationFromModel: (roomResponse: RoomResponse) => void;
};

export const initialSubSection: SubSection = {
    id: 0,
    name: '',
    reference: 'all',
    section: {
        reference: '',
        id: 0,
        version: 0,
        name: '',
        logo: '',
        catalogId: 0,
    },
    version: 0,
};

export const initialConfiguredProductSheet: ProductSheet = {
    name: '',
    height: 0,
    productType: '',
    productBehaviour: '',
    pointOfView: { phi: 0, radius: 0, theta: 0 },
    dimension: { length: 0, height: 0, depth: 0 },
    hasWallConstraint: false,
};

const AddProductPanel = (props: AddProductProps) => {
    const dispatch = useDispatch();

    //#region useSelector
    const catalogTree = useSelector<RootState, TreeCatalogState>((state) => state.getTreeCatalog);
    const { roomStudioApp, editedDatas, roomStudioMode } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    //#endregion

    //#region useState
    const [currentSubsection, setFocusSubsection] = useState<SubSection>(initialSubSection);

    const [clickProduct, setClickProduct] = useState<PresetTree | undefined>(undefined);
    const [productObject, setProductObject] = useState<any | undefined>(undefined);

    const [currentCatalog, setCurrentCatalog] = useState<TreeCatalog | undefined>(undefined);
    const [currentSection, setCurrentSection] = useState<SectionWithSubSections | undefined>(undefined);
    //#endregion

    const [sections, setSections] = useState<SectionWithSubSections[]>([]);
    const [products, setProducts] = useState<Product[]>([]);

    const [searchQuery, setSearchQuery] = useState('');

    const [searchResults, setSearchResults] = useState<Product[]>([]);
    const [configuredProductSheet, setConfiguredProductSheet] = useState<ProductSheet>(initialConfiguredProductSheet);

    //#region useEffect
    useEffect(() => {
        const catalogId = localStorage.getItem('selected-catalogId');
        catalogId !== null &&
            CatalogServices.treeCatalog(parseInt(catalogId)).then((res: any) => {
                setSections(res.content.sections);
                setCurrentCatalog(res.content);
            });
    }, []);

    useEffect(() => {
        clickProduct && displayDefaultPreset();
    }, [clickProduct]);

    useEffect(() => {
        if (catalogTree.payload.content.catalog.id !== 0 && catalogTree.payload.errors.length === 0) {
            setCurrentCatalog(catalogTree.payload.content);
        }
    }, [catalogTree]);

    useEffect(() => {
        currentCatalog && currentSection && reloadProducts();
    }, [currentCatalog]);

    useEffect(() => {
        if (currentSection && currentCatalog) {
            reloadProducts();
        }
    }, [currentSection]);

    useEffect(() => {
        currentCatalog && currentSection && reloadProducts();
    }, [currentSubsection]);

    useEffect(() => {
        if (productObject) {
            let roomObject = ObjectParser.DeepParseObject(productObject, BasicObject);
            dispatch(addObjects(roomObject));
            setClickProduct(undefined);
            setProductObject(undefined);
            props.setOpenPanel(false);
            dispatch(OpenAddProductPanel(false));
        }
    }, [productObject]);

    useEffect(() => {
        catalogServices.searchProductsInCatalog(searchQuery, currentCatalog?.catalog.id ?? 0).then((res: any) => {
            setSearchResults(res.content);
        });
    }, [searchQuery]);

    //endregion

    const reloadProducts = () => {
        currentCatalog &&
            currentSection &&
            currentSubsection &&
            CatalogServices.getAllProductsInCatalogSubSectionTree(currentCatalog.catalog.id, currentSection.reference, currentSubsection.reference).then((res: any) => {
                const listeUnique = res.content.filter((objet: Product, index: number, self: any) => index === self.findIndex((o: Product) => o.id === objet.id && o.name === objet.name));
                setProducts(listeUnique);
            });
    };

    const addToBasket = (productToAdd: PresetTree, refOfInstance: string, callback: () => void) => {
        /// UPLOAD SCREEN THEN FAIS LE RESTE
        // roomStudioApp?.Services.Screenshot.TakeScreenshotOfEntity(new Vector2(1920, 1080)).then((obj) => console.log('screenshot', obj));
        const basketProduct: BasketProduct = {
            inBasket: true,
            name: productToAdd.product.name,
            priceInCents: 0,
            ecoTaxInCents: 0,
            productType: productToAdd.product.productType,
            refOfInstance,
            basketProductScreenshot: '',
            productReference: productToAdd.product.reference,
            productContent: productToAdd,
        };
        props.setBasketProducts([...props.basketProducts, basketProduct]);

        callback();
    };

    const displayDefaultPreset = () => {
        if (clickProduct) {
            // @ts-ignore
            const resObj = buildRoomObjectFromExistingPreset(clickProduct, configuredProductSheet);
            // @ts-ignore
            const refOfInstance = generateUUID();
            resObj.refOfInstance = refOfInstance;

            addToBasket(clickProduct, refOfInstance, () => {
                setProductObject(resObj);
            });
        }
    };

    return (
        <div className="add-product-panel-main">
            <div className="add-panel-top">
                <div className="add-panel-title">Ajouter un produit</div>
                <img
                    alt="fermer"
                    src={close}
                    onClick={() => {
                        props.setOpenPanel(false);
                        dispatch(OpenAddProductPanel(false));
                    }}
                />
            </div>
            <div className="add-panel-search-box">
                <div className="add-panel-input">
                    <img alt="loupe" src={loupe} />
                    <input
                        placeholder="Rechercher un produit"
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="add-panel-body">
                {searchQuery.length !== 0 ? (
                    <div>
                        <h1>Search</h1>
                        <ProductCatalogSearchResults
                            products={searchResults}
                            setClickProduct={(preset) => {
                                setClickProduct(preset);
                            }}
                        />
                    </div>
                ) : !currentSection ? (
                    <div className="add-panel-grid">
                        {sections.map((s: SectionWithSubSections) => {
                            return (
                                <div
                                    onClick={() => {
                                        setProducts([]);
                                        setCurrentSection(s);
                                    }}
                                    key={s.id}
                                    className="section-card"
                                >
                                    <img alt="section" src={s.logo !== null ? s.logo : logoMdf} />
                                    <span>{s.name.length > 20 ? ` ${s.name.toUpperCase().substring(0, 28)}... ` : s.name.toUpperCase()}</span>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className="subsection-panel">
                        <div className="subsection-panel-left">
                            <div
                                onClick={() => {
                                    setProducts([]);
                                    setCurrentSection(undefined);
                                    setFocusSubsection(initialSubSection);
                                }}
                                className="subsection-panel-left-title"
                            >
                                <img alt="retour" src={back} />
                                <span>{currentSection.name}</span>
                            </div>
                            <div className="subsections-list">
                                <div
                                    className={currentSubsection.id === 0 ? 'subsection focus' : 'subsection'}
                                    onClick={() => {
                                        setProducts([]);
                                        setFocusSubsection(initialSubSection);
                                    }}
                                >
                                    Tous les produits
                                </div>
                                {currentSection.subSections.map((subSection: SubSection) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                setFocusSubsection(subSection);
                                            }}
                                            className={currentSubsection.reference === subSection.reference ? 'subsection focus' : 'subsection'}
                                            key={subSection.reference}
                                        >
                                            {subSection.name}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="subsection-panel-right">
                            <div className="preset-list">
                                {products.map((product: Product) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                configuredProductService.getConfiguredProductTreeByProductReference(product.reference).then((res: any) => {
                                                    setClickProduct(res.content);
                                                });
                                                configuredProductService.getConfiguredProductSheet(product.reference).then((res: any) => {
                                                    setConfiguredProductSheet(res.content);
                                                });
                                            }}
                                            className="preset"
                                            key={product.id}
                                        >
                                            <img alt="preset" src={product.thumbnailUrl.length === 0 ? logoMdf : product.thumbnailUrl} />
                                            <div>{product.name}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default AddProductPanel;
