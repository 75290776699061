import React, { useState } from 'react';
import realityOff from '../../../../Assets/Icons/ContextualMenu/Planner_realite-off.svg';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import ButtonToggleRoom from "../../../../../application3D-common/Components/UI/Composites/ButtonToggleRoom";


type AugmentedRealityProps = {
    onClick: () => void;
};


const AugmentedReality = ({ onClick }: AugmentedRealityProps): JSX.Element => {
    const [active, setActive] = useState<boolean>(false);

    const handleClick = () => {
        setActive(!active);
        onClick();
    };

    return (
        <StyledBoxButtonGroup
            aria-label='text alignment'
        >
            <ButtonToggleRoom
                text={
                    <Typography variant='subtitle2' color='primary'>
                        Voir en realité <br />augmentée
                    </Typography>
                }
                altLogo='reality' logo={ realityOff }
                actions={ handleClick }
            />
        </StyledBoxButtonGroup>
    );
};

const StyledBoxButtonGroup = styled(Box)(({ theme }) => ({
    margin: theme.spacing(0.5),
    height: 65,
    minWidth: 80,
    '& .Mui-disabled': {
        border: 0,
    },
    '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
    },
}));


export default AugmentedReality;