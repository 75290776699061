import { IHandler } from '@lutithree/build/Modules/Core/Event/IHandler';
import { Engine } from "@lutithree/build/Engine";
import HookSystem from "../../../Features3D/Hooks/Systems/HookSystem";
import EntityHooksChangeEvent from "../../../Features3D/Hooks/Events/EntityHooksChangeEvent";
import { AHandler } from "@lutithree/build/Handlers/AHandler";
import ObjectSystem from "../../Composition/ObjectSystem";
import PartComponent from "../../Components/PartComponent";
import ObjectComponent from "../../Components/ObjectComponent";

export class ObjectHooksHandler extends AHandler implements IHandler<EntityHooksChangeEvent> {
    
    private m_hookSystem: HookSystem;

    private m_objectSystem: ObjectSystem;
    
    public constructor(p_engine: Engine){
        super(p_engine);
        
        this.m_hookSystem = new HookSystem(p_engine.Modules.Scene);
        this.m_objectSystem = new ObjectSystem(p_engine.Modules.Scene);
    } 
    
    public Handle(p_event: EntityHooksChangeEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');
        if(! (p_event.Entity.HasComponentOfType(PartComponent) || p_event.Entity.HasComponentOfType(ObjectComponent)) ) return;

        let targetEntity = p_event.Entity;
        if(p_event.Entity.HasComponentOfType(PartComponent)){
            let parComponent = p_event.Entity.GetComponentOfType(PartComponent);
            let parentObjectEntity = this.m_objectSystem.GetObjectEntity(parComponent.ParentObjectRef);
            if(parentObjectEntity) targetEntity = parentObjectEntity;
        }
        
        let roomObjectComponent = targetEntity.GetComponentOfType(ObjectComponent);
        let entitiesId = [targetEntity!.Id].concat(Array.from(roomObjectComponent.PartIds.values()));
        this.m_hookSystem.InstanciateHookables(entitiesId);
    }
}
