import { Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { ObjectParser } from '../application-3d/application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/ObjectParser';
import BasicObject from '../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject';
import { addObjects } from '../application-3d/application3D-seller/Redux/Reducers/room-studio/RoomContentReducer';


export const fetchAndAddTemplate = (url: string, dispatch: Dispatch) => {
    fetch(url)
        .then((res) => {
            return res.json();
        })
        .then((template) => {
            let addObject = ObjectParser.DeepParseObject(template, BasicObject);
            addObject.RefOfInstance = uuidv4();
            dispatch(addObjects(addObject));
        });
};
