import { DisableComponent } from "@lutithree/build/Modules/Core/Entity/DisableComponent";
import { IDisposableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/IDisposableComponent";
import { Group, Object3D } from "three";
import { HookComponent } from "./HookComponent";

export class HookableComponent extends DisableComponent implements IDisposableComponent {
    
    private readonly m_hookRefs: string[];

    private m_template: Object3D;
    
    private m_instances :  Map<HookComponent, Object3D[]>|undefined;

    private m_onCleanInstances : (p_model: Group|Object3D) => void;
    
    public constructor(p_hookRefs: string[], p_model: Group, p_cleanResourcesCallback: (p_model: Group|Object3D) => void) {
        super();
        if (!p_hookRefs) throw new Error('NullReferenceException : p_hookRefs is null or undefined');
        if (p_model == null) throw new Error('NullReferenceException : p_model is null or undefined');
        if (p_cleanResourcesCallback == null) throw new Error('NullReferenceException : p_cleanResourcesCallback is null or undefined');

        this.m_template = p_model;
        this.m_hookRefs = p_hookRefs;
        this.m_onCleanInstances = p_cleanResourcesCallback;
    }

    public get HookRefs() : string[] {
        return this.m_hookRefs;
    }

    public get Template() : Object3D {
        return this.m_template;
    }

    public set Template(p_template: Object3D) {
        if (p_template == null) throw new Error('NullReferenceException : p_template is null or undefined');
        this.m_template = p_template;
    }
    
    public Clear(): void{
        let instances :Object3D[] = [];
        if(this.m_instances){
            this.m_instances.forEach((value,key)=>{
                instances = instances.concat(value);
                key.SetOnDisposeCallback(undefined);
                key.SetOnHookUpdate(undefined);
            });
        }
        instances.forEach((instance)=>{
            let parent = instance.parent;
            if(parent) parent.remove(instance);
            this.m_onCleanInstances(instance);
        });
        this.m_instances?.clear();
    }
    
    public AddHookInstances(p_hook :HookComponent, p_instances: Object3D[]): void {
        if(this.m_instances === undefined){
            this.m_instances = new Map<HookComponent, Object3D[]>();
            this.RegisterHooksComponent(p_hook);
            this.m_instances.set(p_hook, p_instances);
        }
        else {
            if(!this.m_instances.has(p_hook)){
                this.RegisterHooksComponent(p_hook);
                this.m_instances.set(p_hook, p_instances);
            }
            else console.warn('You are trying to add instances on already instanciated hook!');
        }
    }
    
    public UpdateInstancesTransform(p_hooksComponent: HookComponent) :void{
        // pour toutes les refs de point d'accroche =>
        this.m_hookRefs.forEach((hookRef)=>{
            // mettre à jour les transform des points des instances si la ref de hook correspondante existe dans le hookComponent passé en paramètre 
            let hookObjects = p_hooksComponent.Hooks.has(hookRef)?p_hooksComponent.Hooks.get(hookRef):undefined;
            if(hookObjects && this.m_instances && this.m_instances.has(p_hooksComponent) && hookObjects.length === this.m_instances.get(p_hooksComponent)!.length){
                for(let i:number = 0; i<hookObjects.length; i++){
                    let instances = this.m_instances.get(p_hooksComponent)!;
                    instances[i].position.copy(hookObjects[i].position);
                    instances[i].rotation.copy(hookObjects[i].rotation);
                    instances[i].scale.copy(hookObjects[i].scale);
                    instances[i].name = hookObjects[i].name+'_'+instances[i].name;
                }
            }
        });
    }
    
    private RegisterHooksComponent(p_hooksComponent: HookComponent): void {
        if (p_hooksComponent == null) throw new Error('NullReferenceException : p_hooksComponent is null or undefined');

        p_hooksComponent.SetOnDisposeCallback(()=>this.OnHookRemoved(p_hooksComponent));
        p_hooksComponent.SetOnHookUpdate(()=>this.UpdateInstancesTransform(p_hooksComponent));
    }
    
    private OnHookRemoved(p_hooksComponent: HookComponent): void {
        if(this.m_instances && this.m_instances.has(p_hooksComponent)){
            this.m_instances.get(p_hooksComponent)!.forEach((instance)=>{
                let parent = instance.parent;
                if(parent) parent.remove(instance);
                this.m_onCleanInstances(instance);
            });
            this.m_instances.delete(p_hooksComponent);
        }
        if(this.m_instances && [this.m_instances.keys()].length === 0) this.m_instances = undefined;
    }
    
    public GetDisposable(): (Object3D)[] {
        this.Clear();
        return [this.m_template];
    }
} 