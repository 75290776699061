import React, { useState } from 'react';
import Textfield from '../../ui-components/textfield/textfield';
import PasswordValidation from '../password-validation/password-validation';
import './create-password-form.scss';

type CreatePasswordFormProps = {
    onSubmit: () => void;
    password: string;
    setPassword: (value: string) => void;
    confirmPassword: string;
    setConfirmPassword: (value: string) => void;

    errorList: string[];

    setPasswordError: (passwordError: boolean) => void;
    passwordError: boolean;

    setConfirmPasswordError: (confirmPasswordError: boolean) => void;
    confirmPasswordError: boolean;

    setPasswordErrorMessage: (passworderrorMessage: string) => void;
    passworderrorMessage: string;
    confirmPassworderrorMessage: string;
    setConfirmPasswordErrorMessage: (confirmPassworderrorMessage: string) => void;
};

const CreatePaswordForm = ({
    onSubmit,
    password,
    passwordError,
    setPasswordError,
    setPassword,
    confirmPassword,
    confirmPasswordError,
    setConfirmPasswordError,
    setConfirmPassword,
    errorList,
    passworderrorMessage,
    confirmPassworderrorMessage,
    setConfirmPasswordErrorMessage,
    setPasswordErrorMessage,
}: CreatePasswordFormProps) => {
    const [passwordIsValid, setPasswordIsValid] = useState(false);
    return (
        <div className="master">
            <div className="paper">
                <div>
                    <div className="create-main">
                        <div className="create-title">Réinitialisation de mot de passe</div>
                        <div className="create-input">
                            <Textfield
                                isError={passwordError}
                                errorMessage={passworderrorMessage}
                                name="Password"
                                value={password}
                                type="password"
                                placeholder="Entrez un nouveau mot de passe"
                                onChange={(value) => {
                                    setPasswordError(false);
                                    setPasswordErrorMessage('');
                                    setPassword(value);
                                }}
                            />
                            <Textfield
                                name="confirmPassword"
                                errorMessage={confirmPassworderrorMessage}
                                isError={confirmPasswordError}
                                type="password"
                                value={confirmPassword}
                                placeholder="Confirmer le mot de passe"
                                onChange={(value) => {
                                    setConfirmPasswordErrorMessage('');
                                    setConfirmPasswordError(false);
                                    setConfirmPassword(value);
                                }}
                            />
                            <PasswordValidation password={password} confirm={confirmPassword} setPasswordIsValid={setPasswordIsValid} />
                        </div>
                    </div>

                    <div className="create-button">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                if (passwordIsValid) {
                                    onSubmit();
                                }
                            }}
                        >
                            Envoyer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreatePaswordForm;
