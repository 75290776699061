import React, { useEffect } from "react";
import { Object3D, Spherical, Vector3} from "three";
import { PointOfViewPlacement } from "../../Librairies/Studios/Application3D/Domain/Features3D/PointOfViews";
import CamerasService from "../../Librairies/Studios/Application3D/GameLogic/Studio/Cameras/CamerasService";

export type POVManagerProps = {
    cameraService: CamerasService
    cameraPointOfView?: PointOfViewPlacement
    allPlacements:  {placement: PointOfViewPlacement, position: Spherical}[]
    object?: Object3D
    debugMode?: boolean
    setAllPovs: (p_povs: Array<{ placement: PointOfViewPlacement, position: Spherical }>)=>void
    setCurrentPov: (p_pov: number)=>void
};

const PointOfViewManager = (props: POVManagerProps) => {

    let setPointOfView = (p_pov: number) => {
        if (p_pov == null) return;
        props.setCurrentPov(p_pov);
    };

    useEffect(()=>{
        let top = new Spherical(2, 0, 0).makeSafe();
        let top90 = new Spherical(2, 0, Math.PI/2).makeSafe();
        let quarter = new Spherical().setFromCartesianCoords(1,1,0).makeSafe();
        let quarterOff = new Spherical().setFromCartesianCoords(1, 1, 1).makeSafe();
        let front = new Spherical().setFromCartesianCoords(0,0,2).makeSafe();

        let allPlacement = new Array<{ placement: PointOfViewPlacement, position: Spherical }>();
        allPlacement.push({ placement: PointOfViewPlacement.TOP, position: top});
        allPlacement.push({ placement: PointOfViewPlacement.TOP_90, position: top90});
        allPlacement.push({ placement: PointOfViewPlacement.THREE_QUARTER, position: quarter });
        allPlacement.push({ placement: PointOfViewPlacement.THREE_QUARTER_OFF, position: quarterOff });
        allPlacement.push({ placement: PointOfViewPlacement.FRONT, position: front });
        props.setAllPovs(allPlacement);
        if(props.cameraPointOfView) props.setCurrentPov(props.cameraPointOfView);
    }, []);

    useEffect(()=>{
        props.cameraService.SetCallbackOnInteractionOnCameraStart(()=>{
            if(props.cameraPointOfView !== PointOfViewPlacement.FREE) setPointOfView(PointOfViewPlacement.FREE);
        });
    }, [props.cameraPointOfView, props.cameraService]);

    useEffect(() => {
        if( props.cameraPointOfView != null && props.allPlacements.length>0){
            if(props.cameraPointOfView === PointOfViewPlacement.FREE) return;
            if (props.object){
                props.cameraService.FocusPerspectiveCameraOnObjects([props.object], 1.2);
                let newPlacement = props.allPlacements.find((obj)=>{
                    return obj.placement === props.cameraPointOfView;
                });
                if(newPlacement) props.cameraService.ApplyRelativeSphericalPosFromTarget(newPlacement.position, props.object);
                props.cameraService.FocusPerspectiveCameraOnObjects([props.object], 1.2);
            }
        }
    }, [props.cameraPointOfView, props.allPlacements, props.object]);

    return (<>
        {
            props.debugMode !== undefined && props.debugMode && props.cameraPointOfView != null &&
            <>
                <button onClick={()=>{setPointOfView(PointOfViewPlacement.TOP);}}>TOP</button>
                <button onClick={()=>{setPointOfView(PointOfViewPlacement.TOP_90);}}>TOP_90</button>
                <button onClick={()=>{setPointOfView(PointOfViewPlacement.THREE_QUARTER);}}>THREE_QUARTER</button>
                <button onClick={()=>{setPointOfView(PointOfViewPlacement.THREE_QUARTER_OFF);}}>THREE_QUARTER_OFF</button>
                <button onClick={()=>{setPointOfView(PointOfViewPlacement.FRONT);}}>FRONT</button>
                <br/>
                <label>{PointOfViewPlacement[props.cameraPointOfView].toString()}</label>
            </>
        }
    </>);
};

export default PointOfViewManager;