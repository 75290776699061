import { Engine } from '@lutithree/build/Engine';
import {BackSide, Color, ShaderMaterial} from 'three';
import AService from "../../../Application3D/Domain/AService";
import {GroupComponent} from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/GroupComponent";
import {SceneEntity} from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import {MeshRendererComponent} from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshRendererComponent";

export default class BackgroundModeService extends AService {

    private m_colorsBright = {color1: new Color("#121212"), color2: new Color("white")};
    
    private m_colorsDark = {color1: new Color("#000000"), color2: new Color("grey")};
    
    public constructor(p_engine: Engine) {
        super(p_engine);
    }

    public SwitchColorMode(p_brightMode: boolean) {
        let env = this.m_engine.Modules.Scene.GetComponents(GroupComponent, { component: false, entity: false }).find((group)=>{return group.GroupRef === "Feature_ScreenshotPanier";});
        let envEntity: SceneEntity | undefined;
        if(env) envEntity = this.m_engine.Modules.Scene.GetEntityByID(env.EntityID);
        if(envEntity && envEntity.HasComponentOfType(MeshRendererComponent)) {
            var material;
            if (p_brightMode) {
                material = new ShaderMaterial({
                    side:BackSide,
                    depthWrite: false,
                    uniforms: {
                        color1: {
                            value: this.m_colorsBright.color1},
                        color2: {
                            value: this.m_colorsBright.color2 }
                    },
                    vertexShader: `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
    }
  `,
                    fragmentShader: `
    uniform vec3 color1;
    uniform vec3 color2;
  
    varying vec2 vUv;
    
    void main() {
      
      gl_FragColor = vec4(mix(color1, color2, vUv.y), 1.0);
    }
  `
                });
            }
            else {
                material = new ShaderMaterial({
                    side:BackSide,
                    depthWrite: false,
                    uniforms: {
                        color1: {
                            value: this.m_colorsDark.color1},
                        color2: {
                            value: this.m_colorsDark.color2 }
                    },
                    vertexShader: `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
    }
  `,
                    fragmentShader: `
    uniform vec3 color1;
    uniform vec3 color2;
  
    varying vec2 vUv;
    
    void main() {
      
      gl_FragColor = vec4(mix(color1, color2, vUv.y), 1.0);
    }
  `
                });
            }
            envEntity.GetComponentOfType(MeshRendererComponent).GetDisposable().forEach((mat)=>{
                mat.dispose();
            });
            envEntity.GetComponentOfType(MeshRendererComponent).Material = material;
        }
    }
}
