import { ConnectorsComponent } from "../../../Features3D/Connectors/Components/ConnectorsComponent";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { AEntityEvent } from "@lutithree/build/Modules/Core/Entity/Events/AEntityEvent";

export default class EntityConnectorClickedEvent extends AEntityEvent<SceneEntity> {
    private m_connectorComponent : ConnectorsComponent;

    private m_isOn : boolean;

    private m_refOfConnector : string;
    
    public constructor(p_entity:SceneEntity, p_connectorComponent : ConnectorsComponent, p_refOfConnector: string, p_isOn: boolean) {
        super(p_entity);
        
        this.m_connectorComponent = p_connectorComponent;
        this.m_isOn = p_isOn;
        this.m_refOfConnector = p_refOfConnector;
    }
    
    public get ConnectorComponent(): ConnectorsComponent{
        return this.m_connectorComponent;
    }

    public get IsOn(): boolean {
        return this.m_isOn;
    }

    public get RefOfConnector(): string {
        return this.m_refOfConnector;
    }
}