import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { Engine } from "@lutithree/build/Engine";
import { Vector3 } from "three";
import { Guid } from "guid-typescript";
import ObjectSystem from "../../Objects/Composition/ObjectSystem";
import { ConnectorsComponent } from "../Connectors/Components/ConnectorsComponent";
import { Button3DComponent } from "./Components/Button3DComponent";
import Button3DSystem from "./Systems/Button3DSystem";
import IInteractiveConnectorsService from "../../../Domain/Objects/IInteractiveConnectorsService";
import AService from "../../../Domain/AService";
import EntityConnectorClickedEvent from "../../Objects/AssetAssembly/Events/EntityConnectorClickedEvent";
import ObjectComponent from "../../Objects/Components/ObjectComponent";


export default class InteractiveConnectorsService extends AService implements IInteractiveConnectorsService {
    
    private m_buttonSystem: Button3DSystem;

    private m_objectSystem: ObjectSystem;
    
    private m_idleSprite : string = "https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/textures/Test/Ajoutmodule-idle.png";

    private m_activatedSprite : string = "https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/textures/Test/Ajoutmodule-actif.png";

    constructor(p_engine: Engine) {
        super(p_engine);
        this.m_buttonSystem = new Button3DSystem(this.m_engine.Modules.Scene);
        this.m_objectSystem = new ObjectSystem(this.m_engine.Modules.Scene);
    }

    public InstanciateButtonsOnConnectors(p_entity: SceneEntity) {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        let connectorsComponents: ConnectorsComponent[] = [];
        if (p_entity.HasComponentOfType(ObjectComponent)) {
            p_entity.GetComponentOfType(ObjectComponent).PartIds.forEach((entityID) => {
                let entity = this.m_engine.Modules.Scene.GetEntityByID(entityID);
                if (entity.HasComponentOfType(ConnectorsComponent)) connectorsComponents.push(entity.GetComponentOfType(ConnectorsComponent));
            });
        }
        if (p_entity.HasComponentOfType(ConnectorsComponent)) {
            connectorsComponents.push(p_entity.GetComponentOfType(ConnectorsComponent));
        }

        connectorsComponents.forEach((connectors)=>{
            connectors.LocationsByRole.forEach((connectorObject, role) => {
                let availability = connectors.AvailabilityByRole.get(role);
                if(availability !== undefined && availability){
                    this.m_buttonSystem.Instanciate3DButtonAtPosition(
                        role,
                        connectorObject.getWorldPosition(new Vector3()),
                        {
                            p_idle: this.m_idleSprite,
                            p_active: this.m_activatedSprite
                        },
                        (value) => {
                            this.m_engine.Modules.EventManager.Publish(EntityConnectorClickedEvent, new EntityConnectorClickedEvent(p_entity, connectors, role, value));
                        },
                        ()=>{
                            this.m_engine.Modules.LoopStrategy.RequestRender(true);
                        });
                }
            });
        });
    }
    
    public RemoveAllButtons(): void {
        let toRemoveIds: Guid[] = [];
        for (let i = 0; i < this.m_engine.Modules.Scene.GetComponents(Button3DComponent).length; i++){
            const buttonComponent = this.m_engine.Modules.Scene.GetComponents(Button3DComponent)[i];
            toRemoveIds.push(buttonComponent.EntityID);
        }
        toRemoveIds.forEach((id)=>{
            this.m_engine.Modules.Scene.RemoveEntityByID(id);
        });
    }
    
    public SelectButton(p_refOfConnector: string, p_isSelected: boolean ): void {
        // todo set into button system
        if(p_isSelected){
            this.m_engine.Modules.Scene.GetComponents(Button3DComponent).forEach((component)=>{
                if(component.Name !== p_refOfConnector) component.IsOn = false;
            });
        }
        
        let buttons = this.m_engine.Modules.Scene.GetComponents(Button3DComponent);
        buttons = buttons.filter( x => x.Name === p_refOfConnector);
        buttons.forEach((button)=>{
            button.IsOn = p_isSelected;
        });
    }
}
