import { IHandler } from '@lutithree/build/Modules/Core/Event/IHandler';
import ARoomStudioHandler from "../../../ARoomStudioHandler";
import {Engine} from "@lutithree/build/Engine";
import RoomStudioServices from "../../RoomStudioServices";
import ObjectSystem
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Composition/ObjectSystem";
import EntityTranslationEndedEvent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Events/EntityTranslationEndedEvent";
import ConnectionsTranslatedEvent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Events/ConnectionsTranslatedEvent";
import PartComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/PartComponent";
import InfoComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/InfoComponent";

export class WallMoveHandler extends ARoomStudioHandler implements IHandler<EntityTranslationEndedEvent>, IHandler<ConnectionsTranslatedEvent> {

    private m_objectSystem: ObjectSystem;

    public constructor(p_engine: Engine, p_services: RoomStudioServices) {
        super(p_engine, p_services);
        this.m_objectSystem = new ObjectSystem(p_engine.Modules.Scene);
    }
    
    public Handle(p_event: EntityTranslationEndedEvent|ConnectionsTranslatedEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');

        if (p_event instanceof EntityTranslationEndedEvent) this.HandleEntityEndTranslated(p_event);
        else if (p_event instanceof ConnectionsTranslatedEvent) this.HandleConnectionsTranslated(p_event);
    }
    
    private HandleConnectionsTranslated(p_event: ConnectionsTranslatedEvent): void {
        p_event.ConnectedEntities.forEach((meshLinkedEntity) => {
            if (meshLinkedEntity.HasComponentOfType(PartComponent)) {
                let partComponent = meshLinkedEntity.GetComponentOfType(PartComponent);
                let parentEntity = this.m_objectSystem.GetObjectEntity(partComponent.ParentObjectRef);
                let infoComponent = parentEntity?.HasComponentOfType(InfoComponent) ? parentEntity?.GetComponentOfType(InfoComponent) : undefined;
                
                if (infoComponent && infoComponent.Info.Type === 'Wall') {
                    this.m_services.ObjectBehaviours.Walls.OnWallMove(meshLinkedEntity, p_event.WorldTranslation);
                }
            }
        });
    }
    
    private HandleEntityEndTranslated(p_event: EntityTranslationEndedEvent): void{
        let roomObjectComponent = p_event.Entity.HasComponentOfType(InfoComponent)? p_event.Entity.GetComponentOfType(InfoComponent):undefined;
        if(roomObjectComponent) {
            if (roomObjectComponent.Info.Type === 'Wall') this.m_services.ObjectBehaviours.Walls.OnWallEndMove(p_event.Entity);
        }
    }
}
