import Connector from "./Connector";
import Connection from "./Connection";

export default class Assembly {

    private dataModelVersion: string;
    
    private subObjectsRef: string[];

    private connectors: Connector[];

    private connections: Connection[];

    public constructor() {
        this.dataModelVersion = '';
        this.subObjectsRef = [];
        this.connectors = [];
        this.connections = [];
    }
    
    public get Connections(): Connection[] {
        let result: Connection[] =[];
        if(this.connections){
            for(let i:number = 0; i<this.connections.length; i++) {
                let connection = Object.assign(new Connection(), this.connections[i]);
                result.push(connection) ;
            }
        }
        return result;
    }

    public get Connectors(): Connector[] {
        let result: Connector[] =[];
        if(this.connectors){
            for(let i:number = 0; i<this.connectors.length; i++) {
                let connector = Object.assign(new Connector(), this.connectors[i]);
                result.push(connector) ;
            }
        }
        return result;
    }

    public get SubObjectsRef(): string[] {
        return this.subObjectsRef;
    }
    
    public AddSubObjectsRef(p_value: string) {
        this.subObjectsRef.push(p_value);
    }
    
    public AddConnector(p_value: Connector){
        this.connectors.push(p_value);
    }
    
    public AddConnection(p_value: Connection){
        this.connections.push(p_value);
    }
    
    public EditRefBOfConnection(p_index: number, p_value: string){
        if(this.connections.length > p_index)
            this.connections[p_index].RefOfConnectorB = p_value;
    }
}