import React, { useEffect, useState } from 'react';
import dateFormatter from '../../utils/date-formatter';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRoom, duplicateRoom, DuplicateRoomState, getRoom, GetRoomState, resetDuplicateRoom } from '../../services/redux/reducers/rooms/rooms.slice';
import test from '../../asset/fakeColorIcone.jpeg';
import './room-card.scss';
import DeleteProject from '../../business/DialogContent/DeleteProject';
import { Box, Card, CardMedia, IconButton, styled, Typography } from '@mui/material';
import { PdfGenerator } from '../../utils/pdf-generator';
import upload from '../../asset/upload_86022.png';
import duplicate from '../../asset/dupliquer.svg';
import trash from '../../asset/Poubelle-p.svg';
import { GetUserProjectByCode, getUserProjectByCode } from '../../services/redux/reducers/projects/project.slice';
import { RootState } from '../../services/redux/root-reducers';
import { useNavigate, useParams } from 'react-router-dom';
import { numToMonth } from '../../pages/rooms/monthArray';

type RoomCardProps = {
    name?: string;
    roomUuid?: string;
    updatedDate?: Date;
    code?: string;
    projectId?: number;
    vignetteUrl: string;
};

const RoomCard: React.FC<RoomCardProps> = ({ updatedDate, roomUuid, name, code, projectId, vignetteUrl }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const duplicateRes = useSelector<RootState, DuplicateRoomState>((state) => state.duplicateRoom);

    const dateFormat = dateFormatter.dateTimeStampToDateTime(updatedDate);
    const [openDeleteM, setOpenDeleteM] = useState(false);
    const [description, setDescription] = useState('Pas encore de description a cette pièce');
    const project = useSelector<RootState, GetUserProjectByCode>((state) => state.getUserProjectByCode);
    const { payload } = useSelector<RootState, GetRoomState>((state) => state.getRoom);
    const splitDate = dateFormat.split('/');
    const dayNum = splitDate[0];
    const monthNum = splitDate[1];
    const yearNum = splitDate[2];
    const monthString = numToMonth(monthNum);
    const hashDate = [];
    hashDate.push(dayNum, monthString, yearNum);
    const dueDate = hashDate.join().replaceAll(',', ' ');

    const handleOpenDeleteModal = (event: Event) => {
        event.stopPropagation();
        setOpenDeleteM(!openDeleteM);
    };

    const handlePdfGen = (event: Event) => {
        event.stopPropagation();
        PdfGenerator({
            payload,
            project,
            description,
            basketProducts: [],
        });
    };

    const handleDuplicatedRoom = (event: Event) => {
        event.stopPropagation();
        dispatch(duplicateRoom({ projectId: projectId, roomName: name }));
    };

    useEffect(() => {
        dispatch(getRoom({ projectId: projectId, roomUuid: roomUuid }));
    }, []);

    useEffect(() => {
        dispatch(getUserProjectByCode(code));
        if (payload.content) {
            if (payload.content.room.description !== '' && payload.content.room.description != null) {
                const desc = JSON.parse(payload.content.room.description).description;
                setDescription(desc);
            }
        }
    }, [payload]);

    useEffect(() => {
        if (duplicateRes.payload.content !== undefined && duplicateRes.payload.errors.length === 0) {
            navigate(`/planner/${parseInt(params.projectId as string)}/${params.code}/${duplicateRes.payload.content.roomUuid}`);
            dispatch(resetDuplicateRoom());
        }
    }, [duplicateRes]);

    return (
        <>
            {openDeleteM && (
                <DeleteProject
                    onClose={() => setOpenDeleteM(false)}
                    title="Supprimer la pièce ?"
                    open={openDeleteM}
                    projectName={name}
                    dialogContent={`Si la pièce "${name}" est partagée avec d’autres utilisateurs, ces derniers n’auront plus accès à cette pièce. Pour confirmer, taper le nom de la pièce :`}
                    actionOnclick={() => {
                        projectId && roomUuid && dispatch(deleteRoom({ projectId: projectId, roomUuid: roomUuid }));
                        setOpenDeleteM(false);
                    }}
                />
            )}
            <StyledRoomCard onClick={() => navigate(`/room/${projectId}/${roomUuid}`)}>
                <CardMedia component="img" height="171" image={vignetteUrl !== null ? vignetteUrl : test} alt="capture d'ecran de la piece" />
                <Box sx={{ p: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ fontSize: 18, height: 22 }} color="primary">
                            {name}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 15,
                                fontWeight: 300,
                            }}
                            color="primary"
                        >
                            {dueDate}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ position: 'absolute', top: 1, right: 1, padding: '3px' }}>
                    <StyledIconButton onClick={handlePdfGen}>
                        <img
                            style={{
                                width: '0.8em',
                                height: '0.8em',
                            }}
                            src={upload}
                            alt="logo upload element"
                        />
                    </StyledIconButton>
                    <StyledIconButton onClick={handleDuplicatedRoom}>
                        <img
                            style={{
                                width: '0.8em',
                                height: '0.8em',
                            }}
                            src={duplicate}
                            alt="logo duplicate"
                        />
                    </StyledIconButton>
                    <StyledIconButton onClick={handleOpenDeleteModal}>
                        <img
                            style={{
                                width: '0.9em',
                                height: '0.9em',
                            }}
                            src={trash}
                            alt="logo poubelle"
                        />
                    </StyledIconButton>
                </Box>
            </StyledRoomCard>
        </>
    );
};

const StyledRoomCard = styled(Card)(() => ({
    boxShadow: '0px 2px 4px #00000029',
    borderRadius: 3,
    position: 'relative',
    cursor: 'pointer',
    height: 247,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    padding: '9px',
    '&:hover': {
        background: theme.palette.grey[200],
    },
    background: 'white',
    color: theme.palette.primary.main,
    margin: 5,
}));

export default RoomCard;
