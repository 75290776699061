import { Vector2 } from 'three';
import AService from "../../../Domain/AService";
import { IScreenshotService } from "../../../Domain/Features3D/IScreenshotService";
import { EnableScreenshotContextEvent } from "./Events/EnableScreenshotContextEvent";

export default class ScreenshotService extends AService implements IScreenshotService {
    
    public Take3DSnapshot(p_screenshotDimensions: Vector2, p_beforeSnapCallback: (() => void) | undefined = undefined, p_afterSnapCallback: (() => void) | undefined = undefined): Promise<Blob> {
        return new Promise<Blob>((resolve, reject) => {
            let screenshotDimension = p_screenshotDimensions;
            let beforeCallback = () => {
                this.m_engine.Modules.EventManager.Publish(EnableScreenshotContextEvent, new EnableScreenshotContextEvent(false));
                if (p_beforeSnapCallback) p_beforeSnapCallback();
            };
            let afterCallback = () => {
                this.m_engine.Modules.EventManager.Publish(EnableScreenshotContextEvent, new EnableScreenshotContextEvent(true));
                if (p_afterSnapCallback) p_afterSnapCallback();
            };
            
            this.m_engine.Modules.Snapshot.TakeSnapshot(screenshotDimension, beforeCallback, afterCallback).then((obj) => {
                resolve(obj);
            });
        });
    }
}