import { Object3D, Vector3 } from 'three';

export default class TransformUpdates {
    private m_forward?: Vector3;
    
    private m_position?: Vector3;
    
    private m_scale?: Vector3;
    
    public constructor(data : {position: Vector3|undefined, forward: Vector3|undefined, scale: Vector3|undefined}| Object3D ){
        if (data == null) throw new Error('NullReferenceException : data is null or undefined');
        if(data instanceof Object3D){
            let position = new Vector3();
            position = data.getWorldPosition(position);
            this.m_position = position;

            let forward = new Vector3();
            forward = data.getWorldDirection(forward);
            this.m_forward = forward;

            this.m_scale = data.scale;
        }
        else {
            this.m_position = data.position;
            this.m_forward = data.forward;
            this.m_scale = data.scale;
        }
    }
    
    public get Forward(): Vector3|undefined {
        return this.m_forward;
    }

    public get Position(): Vector3|undefined {
        return this.m_position;
    }

    public get Scale(): Vector3|undefined {
        return this.m_scale;
    }
}