import React, { useState } from 'react';
import isolateOn from '../../../../../application3D-common/Assets/Icons/ContextualMenu/Planner_isolate-on.svg';
import isolateOff from '../../../../../application3D-common/Assets/Icons/ContextualMenu/Planner_isolate-off.svg';
import {SellerMode} from "../../../SellerApp3D/SellerMode";
import ToogleIcon from "../../../../../application3D-common/Components/UI/Basics/ToogleIcon";


type IsolateProductProps = {
    onChangeProductStudioMode: (isActive: boolean) => void
    sellerApp3DMode: SellerMode
};

const IsolateProductToggle = ({ onChangeProductStudioMode, sellerApp3DMode }: IsolateProductProps): JSX.Element => {
    const [active, setActive] = useState<boolean>(false);

    const handleClick = (): void => {
        setActive(!active);
        if (sellerApp3DMode === SellerMode.ProductStudio) {
            onChangeProductStudioMode(false);
        } else if (sellerApp3DMode === SellerMode.RoomStudio) {
            onChangeProductStudioMode(true);
        }
    };

    return <ToogleIcon imgOn={ isolateOn } imgOff={ isolateOff }
                       label='Produit isolé'
                       active={ active } onChange={ handleClick } />;
};

export default IsolateProductToggle;