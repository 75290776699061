import { Engine } from '@lutithree/build/Engine';

export default class AService {
    protected m_engine: Engine;

    public constructor(p_engine: Engine) {
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');

        this.m_engine = p_engine;
    }
}