import { Dispatch } from 'redux';
import { OpenAddElementPanel, OpenAddProductPanel, OpenFloorPaintPanel, OpenOpeningPaintPanel, OpenPrimitivePaintPanel, OpenPrimitivePanel } from '../services/redux/reducers/panel/panel.slice';
import {
    setDuplcateIcon,
    setPaintIcon,
    setRemplaceIcon,
    setScaleIcon
} from "../application-3d/application3D-seller/Redux/Reducers/ContextualMenuReducer";

export const closeAllPanel = (dispatch: Dispatch) => {
    dispatch(OpenFloorPaintPanel(false));
    dispatch(OpenPrimitivePanel(false));
    dispatch(OpenPrimitivePaintPanel(false));
    dispatch(OpenOpeningPaintPanel(false));
    dispatch(OpenAddElementPanel(false));
    dispatch(setScaleIcon(false));
    dispatch(setPaintIcon(false));
    dispatch(setDuplcateIcon(false));
    dispatch(setRemplaceIcon(false));
    dispatch(OpenAddProductPanel(false));
};
