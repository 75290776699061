import apiClient from '../../api-client';
import {
    ADD_SCREENSHOT_TO_ROOM,
    DELETE_SCREENSHOT_FROM_ROOM,
    UPDATE_ROOM_VIGNETTE,
} from '../../enpoint/room/screenshot';

const screenshotService = {
    addScreenshotToRoom: async (projectId: number, roomUuid: string, file: FormData) => apiClient(ADD_SCREENSHOT_TO_ROOM(projectId, roomUuid), file, undefined, 'POST'),
    deleteScreenshotFromRoom: async (projectId: number, roomUuid: string, fileUrl: { fileUrl: string }) => apiClient(DELETE_SCREENSHOT_FROM_ROOM(projectId, roomUuid), fileUrl, undefined, 'DELETE'),

    updateRoomVignette: async (projectId: number, roomUuid: string, vignetteUrl:  string ) => apiClient(UPDATE_ROOM_VIGNETTE(projectId, roomUuid), vignetteUrl, undefined, 'POST','text/plain'),
};
export default screenshotService;