import ResourceErrorData from "./ResourceErrorData";

export default class TextureErrorData extends ResourceErrorData {

    private readonly m_materialPath: string;


    private readonly m_slot: string;

    public constructor(p_textureUrl:string, p_materialUrl:string, p_slotName: string){
        super(p_textureUrl);
        if (!p_textureUrl) throw new Error('NullReferenceException : p_textureUrl is null or undefined or empty');

        this.m_materialPath = p_materialUrl;
        this.m_slot = p_slotName;
    }

    public get MaterialPath() : string {
        return this.m_materialPath;
    }

    public get Slot() : string {
        return this.m_slot;
    }
}