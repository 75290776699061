import React, { useEffect } from 'react';

export type KeyboardActionProps = {
    keycode: string;
    action: () => void;
};

const KeyboardAction = (props: KeyboardActionProps) => {
    const addKeyboardAction = (): void => {
        document.addEventListener(
            'keydown',
            (event) => {
                //console.log(event);
                switch (event.code) {
                    case props.keycode: {
                        props.action();
                        break;
                    }
                    default:
                        break;
                }
            },
            false
        );
    };

    useEffect(() => {
        addKeyboardAction();
    }, []);

    return <></>;
};

export default KeyboardAction;
