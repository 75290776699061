import apiClient from '../../api-client';
import { GET_SUB_SECTION_SECTION_BY_ID, GET_SUB_SECTIONS_BY_SECTION } from '../../enpoint/catalog/sub-section';


const subSectionService = {
    getSubSectionsBySection: async (sectionId: number) => apiClient(GET_SUB_SECTIONS_BY_SECTION(sectionId), undefined, undefined, 'GET'),
    getSubSectionById: async (subSectionId: number) => apiClient(GET_SUB_SECTION_SECTION_BY_ID(subSectionId), undefined, undefined, 'GET'),
};

export default subSectionService;

