import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../services/redux/root-reducers';
import { Vector3 } from 'three';
import { OpenPrimitivePanel } from '../../../services/redux/reducers/panel/panel.slice';
import PanelSliderLayout from '../PanelSliderLayout';
import { styled, Typography, TextField, Box } from '@mui/material';
import { RoomStudioState } from '../../../application-3d/application3D-seller/Redux/Reducers/room-studio/RoomStudioReducer';
import { setScaleIcon } from '../../../application-3d/application3D-seller/Redux/Reducers/ContextualMenuReducer';
import GeometryData from '../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/GeometryData';




type PrimitivePanelProps = {
    openPrimitivePanel?: boolean;
    setOpenPrimitivePanel: (openPrimitivePanel: boolean) => void;
    openPanel: boolean;
    setOpenPanel: (openEditPanel: boolean) => void;
    open: boolean
};

type Inputs = {
    width: number
    cobblestoneHeight: number
    cylinderHeight: number
    depth: number
    diameter: number
    openingHeight: number
    openingWidth: number
};

const PrimitivePanel = (props: PrimitivePanelProps) => {
    const dispatch = useDispatch();
    const {
        roomStudioApp,
        currentEntity,
        selectedObject,
    } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const [form, setForm] = useState(-1);
    const [objectScale, setObjectScale] = useState<Vector3 | undefined>(undefined);

    const handleClose = () => {
        dispatch(OpenPrimitivePanel(false));
        dispatch(setScaleIcon(false));
        props.setOpenPanel(false);
    };

    const handleChange = (e: any) => {
        if (e.target.value === '0') {
            e.target.value = 1;
        } else if (e.target.value >= 301) {
            e.target.value = 300;
        }
    };

    function noNulValue(val: any) {
        if (val === 0) {
            return 1;
        } else if (val >= 301) {
            return 300;
        }
        return val;
    }

    useEffect(() => {
        if (selectedObject && props.open) {
            if (selectedObject.Informations.Type === 'BuildingBlock' && selectedObject.Assets.find((asset) => (asset.Datas as GeometryData).Type === 'Cylinder')) setForm(1);
            if (selectedObject.Informations.Type === 'BuildingBlock' && selectedObject.Assets.find((asset) => (asset.Datas as GeometryData).Type === 'Cube')) setForm(0);
            if (selectedObject.Informations.Type === 'Opening') setForm(2);
        }
    }, [selectedObject, props.open]);

    useEffect(() => {
        if (currentEntity && props.open && roomStudioApp !== undefined) {
            setObjectScale(roomStudioApp?.Services.Scale.GetWorldScale(currentEntity));
        }
    }, [currentEntity, props.open]);

    const {
        register,
        watch,
        setValue,
    } = useForm<Inputs>();

    const width = watch('width');
    const cobblestoneHeight = watch('cobblestoneHeight');
    const cylinderHeight = watch('cylinderHeight');
    const depth = watch('depth');
    const diameter = watch('diameter');
    const openingWidth = watch('openingWidth');
    const openingHeight = watch('openingHeight');

    useEffect(() => {
        if (roomStudioApp !== null && currentEntity !== undefined && objectScale !== undefined) {
            let scale: Vector3;
            if (form === 1) { // cylinder
                scale = new Vector3((noNulValue(diameter)) / 100, (noNulValue(cylinderHeight)) / 100, (noNulValue(diameter)) / 100);
            } else if (form === 0) { // cube
                scale = new Vector3((noNulValue(width)) / 100, (noNulValue(cobblestoneHeight)) / 100, (noNulValue(depth)) / 100);
            } else if (form === 2) { // opening
                scale = new Vector3((noNulValue(openingWidth)) / 100, (noNulValue(openingHeight)) / 100, objectScale.z);
            } else {
                scale = new Vector3((noNulValue(width)) / 100, (noNulValue(cobblestoneHeight)) / 100, (noNulValue(depth)) / 100);
            }
            if (Math.abs(new Vector3().subVectors(scale, objectScale).length()) > 0.0001) {
                roomStudioApp.Services.Scale.SetWorldScale(currentEntity, scale, objectScale);
                setObjectScale(scale);
            }
        }
    }, [width, cobblestoneHeight, cylinderHeight, depth, diameter, openingWidth, openingHeight]);

    useEffect(() => {
        if (objectScale) {
            setValue('width', Math.round(objectScale.x * 100));
            setValue('cobblestoneHeight', Math.round(objectScale.y * 100));
            setValue('cylinderHeight', Math.round(objectScale.y * 100));
            setValue('depth', Math.round(objectScale.z * 100));
            setValue('diameter', Math.round(objectScale.x * 100));
            setValue('openingWidth', Math.round(objectScale.x * 100));
            setValue('openingHeight', Math.round(objectScale.y * 100));
        }
    }, [objectScale]);


    return (
        <PanelSliderLayout
            onClose={ handleClose }
            open={ props.open }
            title="Redimensionner l'élément de construction">
            <Typography variant='subtitle1' color='primary' sx={ { mb: 1 } }>
                DIMENSIONS
            </Typography>
            <Box
                component='form'
                sx={ {
                    display: 'flex',
                    flexDirection: 'column',
                    '& > :not(style)': { mt: 1, mb: 1 },
                } }
                noValidate
                autoComplete='off'
            >
                { form === 0 && <>
                    <Box>
                        <StyledTypography color='primary'>
                            <label htmlFor='width'>Largeur (cm)</label>
                        </StyledTypography>
                        <StyledInput
                            inputProps={ { min: 1, max: 300 } }
                            type='number'
                            placeholder='Largeur'
                            { ...register('width',
                                {
                                    valueAsNumber: true,
                                    // @ts-ignore
                                    pattern: /^([1-9][0-9]{0,2})$/,
                                    onChange: (e) => handleChange(e),
                                }) }
                        />
                    </Box>
                    <Box>
                        <StyledTypography color='primary'>
                            <label htmlFor='cobblestoneHeight'>Hauteur (cm)</label>
                        </StyledTypography>
                        <StyledInput
                            inputProps={ { min: 1, max: 300 } }
                            type='number'
                            placeholder='Hauteur'
                            { ...register('cobblestoneHeight',
                                {
                                    valueAsNumber: true,
                                    // @ts-ignore
                                    pattern: /^([1-9][0-9]{0,2})$/,
                                    onChange: (e) => handleChange(e),
                                }) }
                        />
                    </Box>
                    <Box>
                        <StyledTypography color='primary'>
                            <label htmlFor='depth'>Profondeur (cm)</label>
                        </StyledTypography>
                        <StyledInput
                            inputProps={ { min: 1, max: 300 } }
                            type='number'
                            placeholder='Profondeur'
                            { ...register('depth',
                                {
                                    valueAsNumber: true,
                                    // @ts-ignore
                                    pattern: /^([1-9][0-9]{0,2})$/,
                                    onChange: (e) => handleChange(e),
                                }) }
                        />
                    </Box>
                </> }
                { form === 1 && <>
                    <Box>
                        <StyledTypography color='primary'>
                            <label htmlFor='cylinderHeight'>Hauteur (cm)</label>
                        </StyledTypography>
                        <StyledInput
                            inputProps={ { min: 1, max: 300 } }
                            type='number'
                            placeholder='Hauteur'
                            { ...register('cylinderHeight', {
                                valueAsNumber: true,
                                // @ts-ignore
                                pattern: /^([1-9][0-9]{0,2})$/,
                                onChange: (e) => handleChange(e),
                            }) }
                        />
                    </Box>
                    <Box>
                        <StyledTypography color='primary'>
                            <label htmlFor='diameter'>Diamètre (cm)</label>
                        </StyledTypography>
                        <StyledInput
                            inputProps={ { min: 1, max: 300 } }
                            type='number'
                            placeholder='Diamètre'
                            { ...register('diameter',
                                {
                                    valueAsNumber: true,
                                    // @ts-ignore
                                    pattern: /^([1-9][0-9]{0,2})$/,
                                    onChange: (e) => handleChange(e),
                                }) }
                        />
                    </Box>
                </> }
                { form === 2 && <>
                    <Box>
                        <StyledTypography color='primary'>
                            <label htmlFor='openingHeight'>Hauteur (cm)</label>
                        </StyledTypography>
                        <StyledInput
                            inputProps={ { min: 1, max: 300 } }
                            type='number'
                            placeholder='Hauteur'
                            { ...register('openingHeight', {
                                valueAsNumber: true,
                                // @ts-ignore
                                pattern: /^([1-9][0-9]{0,2})$/,
                                onChange: (e) => handleChange(e),
                            }) }
                        />
                    </Box>
                    <Box>
                        <StyledTypography color='primary'>
                            <label htmlFor='openingWidth'>Largeur (cm)</label>
                        </StyledTypography>
                        <StyledInput
                            inputProps={ { min: 1, max: 300 } }
                            type='number'
                            placeholder='Largeur'
                            { ...register('openingWidth',
                                {
                                    valueAsNumber: true,
                                    // @ts-ignore
                                    pattern: /^([1-9][0-9]{0,2})$/,
                                    onChange: (e) => handleChange(e),
                                }) }
                        />
                    </Box>
                </> }
            </Box>
        </PanelSliderLayout>
    );
};

const StyledInput = styled(TextField)(({ theme }) => ({
    '& ::-webkit-input-placeholder': {
        fontStyle: 'italic',
    },
    '& .MuiInputBase-root': {
        fontWeight: 300,
        '& input': {
            padding: '10px 13px',
        },
    },
    width: '65%',
    border: `${ theme.palette.primary.main }`,
    fontWeight: 300,
}));

const StyledTypography = styled(Typography)(() => ({
    fontWeight: 300,
    marginBottom: 3,
}));

export default PrimitivePanel;
