import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { AutoReorientationComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/AutoReorientationComponent';
import { SupportComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SupportComponent';
import AService from '../../../Domain/AService';
import { Engine } from '@lutithree/build/Engine';
import { RotControlableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/RotControlableComponent';
import { SnappableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SnappableComponent";
import { SnapData } from "@lutithree/build/Modules/WebGL/Scene/DataModel/SnapData";
import { Plane, Vector3 } from "three";
import { BoundingBoxComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundingBoxComponent";
import { SnapSystem } from "./Systems/SnapSystem";
import EntitySnappedEvent from './Events/EntitySnappedEvent';
import EntityUnsnappedEvent from './Events/EntityUnsnappedEvent';


export default class SnapService extends AService {
    
    private m_snapSystem: SnapSystem;

    public constructor(p_engine: Engine) {
        super(p_engine);

        this.m_snapSystem = new SnapSystem(p_engine.Modules.Scene);
    }
    
    public InitSnapDatas(p_entity: SceneEntity): void{
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        
        let snappable: SnappableComponent | undefined = undefined;
        if (p_entity.HasComponentOfType(SnappableComponent)) {
            snappable = p_entity.GetComponentOfType(SnappableComponent);
            let supports = this.m_engine.Modules.Scene.GetComponents(SupportComponent);
            
            // Find new snap from BB border
            let snapPlane = this.m_snapSystem.InitialCheckForSnapSupport(p_entity, supports, ...snappable.Snapped);
            if (snapPlane.plane && snapPlane.support) {
                this.SnapOnNewSupport(p_entity, snapPlane.plane, snapPlane.support);
            }
        }
    }

    public TrySnapEntityToSupports(p_entity: SceneEntity, p_supports: SupportComponent[]|undefined = undefined): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        let snappable: SnappableComponent | undefined = undefined;
        if (p_entity.HasComponentOfType(SnappableComponent)) {
            snappable = p_entity.GetComponentOfType(SnappableComponent);
            let supports: SupportComponent[];
            if(p_supports !== undefined) supports = p_supports;
            else supports = this.m_engine.Modules.Scene.GetComponents(SupportComponent);

            // Continue snap for registered supports + Detect unsnap
            this.UpdateRegisteredSnapData(p_entity, snappable);

            // Detect new snap 
            let snapPlane = this.m_snapSystem.CheckForSnapSupport(p_entity, p_entity.Transform.Forward, supports, ...snappable.Snapped);
            if (snapPlane.plane && snapPlane.support) {
                this.SnapOnNewSupport(p_entity, snapPlane.plane, snapPlane.support);
            }
        }
    }
    

    private UpdateRegisteredSnapData(p_entity: SceneEntity, p_snappable: SnappableComponent) : void {
        for (let i = 0; i < p_snappable.Snapped.length; i++) {
            if (this.m_snapSystem.CheckForThreshholdCap(p_entity, p_snappable.Snapped[i].Support, p_snappable.Snapped[i].Plane, 0.3)) {
                this.Unsnap(p_entity, p_snappable, p_snappable.Snapped[i]);
                i = i - 1;
            } else this.m_snapSystem.SnapToPlane(p_entity, p_snappable.Snapped[i].Plane);
        }
    }
    
    private SnapOnNewSupport(p_entity: SceneEntity, p_plane: Plane, p_support: SupportComponent): void {
        let normalNegate = p_plane.normal.clone().negate();
        let test = Math.sign(new Vector3(-1, 0, 0).dot(normalNegate));
        let newSnapData = new SnapData(p_support, p_plane, new Vector3(0, 0, 1).angleTo(normalNegate) * (test === 0 ? 1 : test));
        let snappableComponent = p_entity.GetComponentOfType(SnappableComponent);

        if(!snappableComponent.Snapped.some(data => data.Support.SupportObject.uuid === p_support.SupportObject.uuid)){
            // 1 - Fill snap datas in component
            snappableComponent.SnapToPlane(newSnapData);
            
            // 2 - Rotate before snap object to p_plane
            let orientateComponent :AutoReorientationComponent|undefined = undefined;
            if (p_entity.HasComponentOfType(AutoReorientationComponent)) {
                orientateComponent = p_entity.GetComponentOfType(AutoReorientationComponent);
                this.OnAutoReorientationSnapped(true, p_entity, orientateComponent, snappableComponent);
            }

            // 3 - Snap object to p_plane
            let offset = this.m_snapSystem.SnapToPlane(p_entity, p_plane);
            if(orientateComponent && offset) orientateComponent.AddOffset(offset.clone());

            this.m_engine.Modules.EventManager.Publish(EntitySnappedEvent, new EntitySnappedEvent(p_entity));
        }
    }
    
    public Unsnap(p_entity: SceneEntity, p_snappable: SnappableComponent, p_snapData: SnapData): void {
        p_snappable.Unsnap(p_snapData);
        
        // Autorotate management
        if (p_entity.HasComponentOfType(AutoReorientationComponent)) {
            let orientateComponent = p_entity.GetComponentOfType(AutoReorientationComponent);
            this.OnAutoReorientationSnapped(false, p_entity, orientateComponent, p_snappable);
        }
        this.m_engine.Modules.EventManager.Publish(EntityUnsnappedEvent, new EntityUnsnappedEvent(p_entity));
    }

    private OnAutoReorientationSnapped( p_isSnapped: boolean, 
                                        p_entity: SceneEntity, 
                                        p_reorientation: AutoReorientationComponent, 
                                        p_snappable: SnappableComponent): void {
        
        if(p_isSnapped && p_snappable.Snapped.length > 0){
            if (p_entity.HasComponentOfType(RotControlableComponent, false)) p_entity.GetComponentOfType(RotControlableComponent).Enable(false);
            if (p_reorientation.InitialRotation === undefined
                && p_snappable.Snapped.length === 1) {
                this.m_engine.Modules.Systems.OrientationSystem.CaptureInitialOrientation(p_entity);
            }
        }
        else if (!p_isSnapped){
            p_reorientation.Offsets.Clear();
            if(p_snappable.Snapped.length === 0){
                //if (p_entity.HasComponentOfType(RotControlableComponent, false)) p_entity.GetComponentOfType(RotControlableComponent).Enable(true);
                if (p_reorientation.InitialRotation !== undefined) {
                    p_entity.Transform.GetObject().setRotationFromQuaternion(p_reorientation.InitialRotation);
                    p_reorientation.ResetRotation();
                }
            }
        }
       
        this.m_engine.Modules.Systems.OrientationSystem.ReorientateOnSnappedSupport(p_entity);
        if (p_entity.HasComponentOfType(BoundingBoxComponent)) {
            let bb = p_entity.GetComponentOfType(BoundingBoxComponent);
            bb.ComputeBoundingBox(p_entity.Transform.GetObject());
        }
    }
}
