import { IEvent } from '@lutithree/build/Modules/Core/Event/IEvent';
import { ViewMode } from '../../../../Domain/Cameras/ViewMode';

export class ViewModeChangeEvent implements IEvent {
    private m_viewMode: ViewMode;

    public constructor(p_viewMode: ViewMode) {
        if (p_viewMode == null) throw new Error('NullReferenceException : p_viewMode is null or undefined');
        this.m_viewMode = p_viewMode;
    }

    public get ViewMode(): ViewMode {
        return this.m_viewMode;
    }
}
