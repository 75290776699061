import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import ObjectScaledEvent from "../Events/ObjectScaledEvent";
import { SupportComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SupportComponent";
import { SnappableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SnappableComponent";
import ARoomStudioHandler from "../../../ARoomStudioHandler";
import ObjectGeometryChangedEvent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/Events/ObjectGeometryChangedEvent";
import EntityTranslationEndedEvent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Events/EntityTranslationEndedEvent";
import EntityStopMovingEvent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Events/EntityStopMovingEvent";
import InfoComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/InfoComponent";

export class SnapSupportHandler extends ARoomStudioHandler implements 
    IHandler<EntityTranslationEndedEvent>, 
    IHandler<EntityStopMovingEvent>,
    IHandler<ObjectScaledEvent>,
    IHandler<ObjectGeometryChangedEvent> {
    
    public Handle(p_event: EntityTranslationEndedEvent|EntityStopMovingEvent|ObjectScaledEvent|ObjectGeometryChangedEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');
        
        let support = p_event.Entity.HasComponentOfType(SupportComponent)? p_event.Entity.GetComponentOfType(SupportComponent): undefined;
        if(support) {

            let snappedEntities = this.m_engine.Modules.Systems.SnapSystem.GetSnappedEntitiesOnSupport(support);
            snappedEntities.forEach((snappedEntity) => {
                let snappable = snappedEntity.GetComponentOfType(SnappableComponent);
                if(snappable) {
                    snappable.Snapped.forEach((snapData)=>{
                        if(snapData.Support.EntityID === support!.EntityID){
                            this.m_services.Snap.Unsnap(snappedEntity, snappable, snapData);
                        }
                    });
                }
            });
            
            let infoComponent = p_event.Entity.HasComponentOfType(InfoComponent) ? p_event.Entity.GetComponentOfType(InfoComponent) : undefined;
            if (infoComponent && infoComponent.Info.Type === 'Wall') {
                snappedEntities.forEach((snappedEntity) => {
                    this.m_services.Snap.InitSnapDatas(snappedEntity);
                });
            } 
        }
    }
}