import MdfApiResponse from '../../../../domain/common/generic';
import { PresetTree, Product, ProductCompletionStatus } from '../../../../domain/domain';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import productService from '../../../../api/service/product/product.services';

//#region states
export type ProductState = {
    isLoading: false;
    payload: MdfApiResponse<Product>;
};
export type PresetState = {
    isLoading: false;
    payload: MdfApiResponse<PresetTree>;
};
export type ProductListState = {
    isLoading: false;
    payload: MdfApiResponse<Product[]>;
};

const initialProductState: ProductState = {
    isLoading: false,
    payload: {
        content: {
            collection: {
                id: 0,
                isSystem: false,
                name: '',
                reference: '',
                version: 0,
                updatedAt: new Date(),
                createdAt: new Date(),
                logo: '',
                supplier: {
                    id: 0,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    version: 0,
                    name: '',
                    reference: '',
                    logo: '',
                },
            },
            id: 0,
            updatedAt: new Date(),
            createdAt: new Date(),
            version: 0,
            creationStep: 0,
            name: '',
            reference: '',
            parentProductReference: '',
            completionStatus: 'CREATING',
            productType: 'CONFIGURABLE',
            deleted: true,
            thumbnailUrl: '',
        },
        errors: [],
        warnings: [],
    },
};

const initialPresetState: PresetState = {
    isLoading: false,
    payload: {
        content: {
            metadata: {},
            mappingConfiguration: [],
            isDefault: false,
            id: 0,
            updatedAt: new Date(),
            subSectionList: [],
            createdAt: new Date(),
            configuration: [],
            product: {
                id: 0,
                version: 0,
                name: '0',
                createdAt: new Date(),
                productType: 'COMPOSITION',
                creationStep: 0,
                completionStatus: 'CREATING',
                updatedAt: new Date(),
                reference: '',
                parentProductReference: '',
                thumbnailUrl: '',
                deleted: false,
                collection: {
                    id: 0,
                    isSystem: false,
                    version: 0,
                    name: '',
                    reference: '',
                    updatedAt: new Date(),
                    createdAt: new Date(),
                    logo: '',
                    supplier: {
                        id: 0,
                        name: '',
                        reference: '',
                        version: 0,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        logo: '',
                    },
                },
            },
            product3DInfo: {
                updatedAt: new Date(),
                createdAt: new Date(),
                version: 0,
                productReference: '',
                id: 0,
                data: {},
            },
            thumbnailUrl: '',
        },
        errors: [],
        warnings: [],
    },
};

const initialProductListState: ProductListState = {
    isLoading: false,
    payload: {
        content: [],
        errors: [],
        warnings: [],
    },
};
//#endregion

//#region get products by statut
export const getProductsByStatut = createAsyncThunk('get/productsByStatut', async (statut: ProductCompletionStatus) => {
    const res = await productService.getProductsByStatut(statut);
    return res;
});

export const getProductsByStatutSlice = createSlice({
    name: 'getProductsByStatut',
    initialState: initialProductListState,
    reducers: {
        resetGetProductsListByStatut: (state) => {
            state = initialProductListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProductsByStatut.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getProductsByStatut.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getProductsByStatut.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetGetProductsListByStatut } = getProductsByStatutSlice.actions;
//#endregion

//#region get products by statut
export const getProductDefaultPreset = createAsyncThunk('get/productDefaultPreset', async (productRef: string) => {
    const res = await productService.getProductDefaultPreset(productRef);
    return res;
});

export const getProductDefaultPresetSlice = createSlice({
    name: 'getProductDefaultPreset',
    initialState: initialPresetState,
    reducers: {
        resetGetProductDefaultPreset: (state) => {
            state = initialPresetState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProductDefaultPreset.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getProductDefaultPreset.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getProductDefaultPreset.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetGetProductDefaultPreset } = getProductDefaultPresetSlice.actions;
//#endregion
