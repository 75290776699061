import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Engine } from '@lutithree/build/Engine';
import { CameraComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Rendering/CameraComponent';
import { ContactShadowsComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Rendering/ContactShadowsComponent';
import { MeshFilterComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent";
import { AxisTranslatableHandler } from "@lutithree/build/Modules/WebGL/Scene/ComponentHandlers/AxisTranslatableHandler";
import { YControlableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/YControlableComponent";
import { RotatableHandler } from "@lutithree/build/Modules/WebGL/Scene/ComponentHandlers/RotatableHandler";
import { RotControlableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/RotControlableComponent";
import { MeshLinksHandler } from "@lutithree/build/Modules/WebGL/Scene/ComponentHandlers/MeshLinksHandler";
import { MeshLinksComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshLinksComponent";
import { Camera2dDecorator } from "./Cameras/EntityDecorators/Camera2dDecorator";
import { Camera3dDecorator } from './Cameras/EntityDecorators/Camera3dDecorator';
import { Camera360Decorator } from "./Cameras/EntityDecorators/Camera360Decorator";
import { ColorRepresentation } from "three";
import {Studio} from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Studio";
import CamerasService
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Cameras/CamerasService";
import {
    ViewModeFilterEnabler
} from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Cameras/ComponentEnablers/ViewModeFilterEnabler";
import {
    ViewModeFilter
} from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Cameras/Components/ViewModeFilter";
import {
    MeshFilterEnabler
} from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Hooks/ComponentEnablers/MeshFilterEnabler";
import {
    ScreenshotEnvDecorator
} from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Environment/EntityDecorators/ScreenshotEnvDecorator";

export class RoomStudio extends Studio {
    
    private m_cameraService: CamerasService;
    
    public constructor(p_rootEntities: Map<string, SceneEntity>, p_cameraService: CamerasService) {
        super(p_rootEntities);
        if (p_cameraService == null) throw new Error('NullReferenceException : p_services is null or undefined');

        this.m_cameraService = p_cameraService;
    }

    protected async LoadEnvironnementsAsync(p_engine: Engine, p_pathLightingDHR: string, p_pathBackgroundDHR: string | ColorRepresentation): Promise<void> {
        // Create Environement_Background
        let screenEnvEntity = p_engine.Modules.Scene.CreateEntity('Screen_Background');
        new ScreenshotEnvDecorator(p_engine.Modules.Rendering.Renderer).Decorate(screenEnvEntity);
        if (this.m_rootEntities.has('Environement')) this.m_rootEntities.get('Environement')!.Transform.AddChild(screenEnvEntity.Transform);
        screenEnvEntity.Enable(false);
        return super.LoadEnvironnementsAsync(p_engine, p_pathLightingDHR, p_pathBackgroundDHR);
    }

    protected AddComponentEnablers(p_engine: Engine): void {
        // AxisTranslatable
        let axisTranslatableHandler = new AxisTranslatableHandler(p_engine.Modules.EventManager, p_engine.Modules.Scene, p_engine.Modules.Systems);
        p_engine.Modules.Scene.ComponentEnabler.Suscribe(YControlableComponent, axisTranslatableHandler);

        // Rotatable
        let rotatableHandler = new RotatableHandler(p_engine.Modules.EventManager, p_engine.Modules.Scene, p_engine.Modules.Systems);
        p_engine.Modules.Scene.ComponentEnabler.Suscribe(RotControlableComponent, rotatableHandler);

        // MeshLinks
        let meshLinksHandler = new MeshLinksHandler(p_engine.Modules.EventManager, p_engine.Modules.Scene, p_engine.Modules.Systems);
        p_engine.Modules.Scene.ComponentEnabler.Suscribe(MeshLinksComponent, meshLinksHandler);

        // RoomStudio
        let viewModeFilterEnabler = new ViewModeFilterEnabler(p_engine.Modules.EventManager, p_engine.Modules.Scene, p_engine.Modules.Systems);
        p_engine.Modules.Scene.ComponentEnabler.Suscribe(ViewModeFilter, viewModeFilterEnabler);

        let meshFilterEnabler = new MeshFilterEnabler(p_engine.Modules.EventManager, p_engine.Modules.Scene, p_engine.Modules.Systems);
        p_engine.Modules.Scene.ComponentEnabler.Suscribe(MeshFilterComponent, meshFilterEnabler);
    }

    protected AddCameras(p_engine: Engine): void {
        let dom = p_engine.Modules.Rendering.Renderer.domElement;
        let parentNode = p_engine.Modules.Rendering.RendererParentNode;

        let camera3dEntity: SceneEntity = p_engine.Modules.Scene.CreateEntity('PerspectiveCamera');
        new Camera3dDecorator(dom, () => {
            this.m_cameraService.OnPerspectiveCameraChange();
        }).Decorate(camera3dEntity);
        let cameraComponent = camera3dEntity.GetComponentOfType(CameraComponent);
        p_engine.Modules.Rendering.ChangeCamera(cameraComponent.GetObject());

        let cameraOrthoEntity: SceneEntity = p_engine.Modules.Scene.CreateEntity('OrthographicCamera');
        new Camera2dDecorator(parentNode, dom, () => {
            this.m_cameraService.OnTopCameraChange();
        }).Decorate(cameraOrthoEntity);

        let camera360Entity: SceneEntity = p_engine.Modules.Scene.CreateEntity('360Camera');
        new Camera360Decorator(dom, () => {
            this.m_cameraService.OnTopCameraChange();
        }).Decorate(camera360Entity);

        if (this.m_rootEntities.has('Cameras')) this.m_rootEntities.get('Cameras')!.Transform.AddChild(camera3dEntity.Transform);
        if (this.m_rootEntities.has('Cameras')) this.m_rootEntities.get('Cameras')!.Transform.AddChild(cameraOrthoEntity.Transform);
        if (this.m_rootEntities.has('Cameras')) this.m_rootEntities.get('Cameras')!.Transform.AddChild(camera360Entity.Transform);
    }

    protected AddShadows(p_engine: Engine): void {
        let contactShadowEntity: SceneEntity = p_engine.Modules.Scene.CreateEntity('ContactShadows');
        contactShadowEntity.Enable(false);
        contactShadowEntity.AddComponentOfType(ContactShadowsComponent, [0, 3]).Enable(false);
        contactShadowEntity.Enable(true);
        if (this.m_rootEntities.has('Lighting')) this.m_rootEntities.get('Lighting')!.Transform.AddChild(contactShadowEntity.Transform);
    }
}
