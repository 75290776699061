import * as React from 'react';
import { useDispatch } from 'react-redux';
import ruler from '../../../../../Assets/Icons/ruler.svg';
import { Box, styled, Typography } from '@mui/material';
import RoomStudioApp from "../../../../../Libraries/Studios/RoomStudio/RoomStudioApp";
import {RoomStudioMode} from "../../../../../Libraries/Studios/RoomStudio/Domain/Modes/RoomStudioMode";
import ButtonToggleRoom from "../../../../../../application3D-common/Components/UI/Composites/ButtonToggleRoom";
import {updateRoomStudioMode} from "../../../../../Redux/Reducers/room-studio/RoomStudioReducer";
import {closeAllPanel} from "../../../../../../../utils/close-all-panel";


export type DrawMeasureProps = {
    roomStudio: RoomStudioApp;
};

const DrawMeasureButton = ({}: DrawMeasureProps) => {
    const dispatch = useDispatch();

    return (
        <StyledBoxButtonGroup>
            <ButtonToggleRoom
                actions={ () => {
                    closeAllPanel(dispatch);
                    dispatch(updateRoomStudioMode(RoomStudioMode.Measuring));
                } }
                logo={ ruler }
                altLogo='ruler logo'
                text={
                    <Typography variant='subtitle2' color='primary'>
                        Mesurer
                    </Typography>
                }
            />
        </StyledBoxButtonGroup>
    );
};

const StyledBoxButtonGroup = styled(Box)(({ theme }) => ({
    margin: theme.spacing(0.5),
    height: 65,
    minWidth: 80,
    '& .Mui-disabled': {
        border: 0,
    },
    '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
    },
}));

export default DrawMeasureButton;
