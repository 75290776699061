import apiClient from '../../api-client';
import {
    ADD_ROOM,
    DELETE_ROOM,
    DUPLICATE_ROOM,
    EDIT_ROOM_DESCRIPTION,
    GET_ROOM,
    GET_ROOM_MODEL,
    UPDATE_ROOM_NAME,
} from '../../enpoint/room/room';
import { Room, RoomDTO } from '../../../domain/domain';


const roomsService = {
    getRoomModels: async () => apiClient(GET_ROOM_MODEL(), undefined, undefined, 'GET'),
    addRoom: async (projectId: number, templateId: number, roomDTO: { name: string, updatedAt: Date }) => apiClient(ADD_ROOM(projectId, templateId), roomDTO, undefined, 'POST'),
    getRoom: async (projectId: number | undefined, roomUuid: string | undefined) => apiClient(GET_ROOM(projectId, roomUuid), undefined, undefined, 'GET'),
    deleteRoom: async (projectId: number, roomUuid: string) => apiClient(DELETE_ROOM(projectId, roomUuid), undefined, undefined, 'DELETE'),
    duplicateRoom: async (projectId: number | undefined, roomName: string | undefined) => apiClient(DUPLICATE_ROOM(projectId, roomName), undefined, undefined, 'POST'),
    updateRoomName: async (projectId: number | undefined, roomId: string | undefined, newName: string | undefined) => apiClient(UPDATE_ROOM_NAME(projectId, roomId, newName), undefined, undefined, 'PUT'),
    editRoomDesc: async (projectId: number | undefined, roomId: string | undefined, data: { description: string }) => apiClient(EDIT_ROOM_DESCRIPTION(projectId, roomId), data, undefined, 'PUT'),
};
export default roomsService;
