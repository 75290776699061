import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../services/redux/root-reducers';
import { NewsCategoryState } from '../../../services/redux/reducers/news/news-cateogory-list.slice';
import { colorScheme } from '../../../lib/helpers/color-scheme';
import { Chip as ChipMui } from '@mui/material';
import { ColorScheme } from '../../../types';


type TagProps = {
    newsCategoryId: number;
};

const NewsChip = ({ newsCategoryId }: TagProps) => {
    const {
        payload: { content },
    } = useSelector<RootState, NewsCategoryState>((state) => state.categoryList);

    const findCombo = (comboName: string) => {
        return colorScheme.find((el: ColorScheme) => el.name === comboName);
    };

    return (
        <>
            { newsCategoryId && (
                content.map((el) => {
                    if (newsCategoryId === el.id) {
                        return (
                            <ChipMui
                                key={ el.id }
                                sx={ {
                                    textTransform: 'capitalize',
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                    backgroundColor: findCombo(el.color)?.backgroundColor,
                                    color: findCombo(el.color)?.labelAssociated,
                                } }
                                label={ el.name }
                            />
                        );
                    }
                })
            ) }
        </>
    );
};

export default NewsChip;
