import * as React from 'react';
import { Avatar, Box, Button, DialogContent, styled, Typography } from '@mui/material';
import DialogAction from '../DialogAction';
import DialogLayout from '../DialogLayout';


type DialogWithIconProps = {
    themeColor?: string;
    onClose: () => void;
    open: boolean;
    title: string;
    subtitle: string;
    icon: JSX.Element;
    btnAction: string;
    onClickAction: () => void;
};

const DialogWithIcon = ({
                            onClose,
                            open,
                            title,
                            subtitle,
                            icon,
                            themeColor = 'bleu',
                            btnAction,
                            onClickAction,
                        }: DialogWithIconProps) => {

    const commonStyles = {
        width: '3.3rem',
        height: '3.3rem',
        mr: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
    };

    return (
        <>
            <DialogLayout
                onClose={ onClose }
                open={ open }
            >
                <DialogContent
                    sx={ { display: 'flex' } }
                >
                    <Box sx={ {
                        ...commonStyles,
                        background: (theme) => themeColor === 'red' ? theme.palette.error.light : theme.palette.secondary.light,
                    } }>
                        <Avatar sx={ {
                            width: 35,
                            height: 35,
                            background: (theme) => themeColor === 'red' ? theme.palette.error.main : theme.palette.secondary.main,
                        } }>
                            { icon }
                        </Avatar>
                    </Box>
                    <Box>
                        <Typography variant='body1' color='primary' gutterBottom>
                            { title }
                        </Typography>
                        <Typography sx={ { color: (theme) => theme.palette.grey[600] } } variant='body2' gutterBottom>
                            { subtitle }
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogAction>
                    <CustomBtn variant='outlined' onClick={ onClose }>annuler</CustomBtn>
                    <CustomBtn color={ themeColor === 'red' ? 'error' : 'secondary' } variant='contained'
                               onClick={ onClickAction }
                               disableElevation>{ btnAction }</CustomBtn>
                </DialogAction>
            </DialogLayout>
        </>
    );
};

const CustomBtn = styled(Button)(() => ({
    width: 131,
    height: 40,
}));

export default DialogWithIcon;