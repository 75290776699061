import { Engine } from '@lutithree/build/Engine';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { MeshFilterComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent";
import { BoundingBoxComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundingBoxComponent";
import EntityHooksChangeEvent from "../../Features3D/Hooks/Events/EntityHooksChangeEvent";
import PartEditionReport from "../../../Domain/Objects/AssetAssembly/PartEditionReport";
import { IObjectDecorator } from "../../../Domain/Objects/AssetAssembly/IObjectDecorator";
import BasicObject from "../../../Domain/Objects/AssetAssembly/BasicObject";
import IObjectLoader from '../../../Domain/Objects/AssetAssembly/IObjectLoader';
import IObjectEditor from '../../../Domain/Objects/AssetAssembly/IObjectEditor';
import ObjectGeometryChangedEvent from "./Events/ObjectGeometryChangedEvent";
import ObjectSystem from "../Composition/ObjectSystem";
import AssetService from "../Composition/AssetService";
import { DefaultAssetDecorator } from "../DefaultAssetDecorator";
import { DefaultObjectDecorator } from "../DefaultObjectDecorator";
import Instance3D from "../../../Domain/Objects/AssetAssembly/Instance3D";
import { IPartService } from "../../../Domain/Objects/Composition/IPartService";
import { Object3DUtils as Seller3DUtils } from "../../../Utils/Object3DUtils";
import { Object3DUtils as LutithreeObject3DUtils } from "@lutithree/build/Modules/WebGL/Utils/Object3DUtils";
import Asset3D from "../../../Domain/Objects/Assets/Asset3D";
import EntityConnectorsChangeEvent from "../../Features3D/Connectors/Events/EntityConnectorsChangeEvent";
import Model3dData from "../../../Domain/Objects/Assets/Model3dData";
import Composition from "../../../Domain/Objects/Composition/Composition";
import AService from "../../../Domain/AService";
import CompositionService from "../../../Domain/Objects/Composition/CompositionService";
import ObjectEditedEvent from "./Events/ObjectEditedEvent";
import ObjectLoadedEvent from "./Events/ObjectLoadedEvent";
import {EditionType} from "../../../Domain/Objects/AssetAssembly/EditionType";
import ObjectComponent from '../Components/ObjectComponent';
import {OptionsComponent} from "../Components/OptionsComponent";
import IObjectDataAccess from "../../../Domain/Objects/IObjectDataAccess";
import Connectivity from "../../../Domain/Objects/Composition/Connectivity";
import ObjectDatas from "../../../Domain/Objects/ObjectDatas";
import {GetPartElementsByRef, IPartElement} from "../../../Domain/Objects/Composition/IPartElement";
import MapComparator from "../../../Utils/MapComparator";

export default class ObjectService extends AService implements IObjectLoader, IObjectEditor, IPartService{
    
    private readonly  m_objectDecorator: IObjectDecorator;

    protected readonly m_comparator: MapComparator<BasicObject>;
    
    private m_objectSystem: ObjectSystem;

    private m_assetComposition: CompositionService;

    private m_objectComposition: CompositionService;

    private readonly  m_dataAccess: IObjectDataAccess;
    
    private m_newObjectDatas: ObjectDatas|undefined;

    private m_lastObjectDatas: ObjectDatas|undefined;
    
    public constructor(p_engine: Engine, p_dataAccess: IObjectDataAccess, p_objectDecorator: IObjectDecorator|undefined = undefined) {
        super(p_engine);
        this.m_objectSystem = new ObjectSystem(p_engine.Modules.Scene);
        
        let assetDecorator = new DefaultAssetDecorator();
        let assetService = new AssetService(p_engine, assetDecorator);
        this.m_assetComposition = new CompositionService(p_engine, assetService);
        this.m_objectComposition = new CompositionService(p_engine, this);
        this.m_comparator = new MapComparator<BasicObject>();
        this.m_dataAccess = p_dataAccess;
        this.m_newObjectDatas = undefined;
        this.m_lastObjectDatas = undefined;

        if(p_objectDecorator != null) this.m_objectDecorator = p_objectDecorator;
        else this.m_objectDecorator = new DefaultObjectDecorator(p_engine);
    }

    public async LoadPartAsync(p_parentEntity:SceneEntity, p_refOfInstance:string, p_refOfPart:string, p_part: BasicObject|BasicObject[], p_instance?: Instance3D): 
        Promise<{ref:string, entity:SceneEntity}[]> {
        if (!p_refOfPart) throw new Error('NullReferenceException : p_refOfPart is null or undefined or empty');
        if (!p_refOfInstance) throw new Error('NullReferenceException : p_refOfInstance is null or undefined or empty');
        if (p_part == null) throw new Error('NullReferenceException : p_part is null or undefined');
        if (Array.isArray(p_part)) throw new Error('Object canot be an array!' );

        let objectDecoration = (p_object: BasicObject, p_partsEntity: Map<string, SceneEntity>, p_entity: SceneEntity) =>
            this.m_objectDecorator.DecorateObject(p_entity, p_object,p_instance);
        return this.LoadObjectAsync(p_refOfPart, p_part, objectDecoration, p_instance, p_parentEntity);
    }

    public async LoadRootObjectAsync(
        p_ref:string,
        p_part: BasicObject,
        p_instance?: Instance3D,
        p_parentEntity?:SceneEntity)
        : Promise<{ref:string, entity:SceneEntity}[]> {

        if (!p_ref) throw new Error('NullReferenceException : p_ref is null or undefined or empty');
        if (p_part == null) throw new Error('NullReferenceException : p_part is null or undefined');
        
        let objectDecoration = (p_object: BasicObject, p_partsEntity: Map<string, SceneEntity>, p_entity: SceneEntity) =>
            this.m_objectDecorator.DecorateRootObject(p_entity, p_object,p_instance);
        let promise = this.LoadObjectAsync(p_ref, p_part, objectDecoration, p_instance, p_parentEntity);
        promise.then((result)=>{
            this.m_engine.Modules.EventManager.Publish(ObjectLoadedEvent, new ObjectLoadedEvent(result[0].entity));
        });
        return promise;
    }

    public async EditRootObjectAsync(p_newObjectDatas: ObjectDatas, p_lastObjectDatas: ObjectDatas): Promise<PartEditionReport[]> {
        this.m_newObjectDatas = p_newObjectDatas;
        this.m_lastObjectDatas = p_lastObjectDatas;

        let initialLow = this.GetObjectBBLowestPOint(p_newObjectDatas.FirstRootObject!);
        let promise = this.EditObjectAsync(p_newObjectDatas.FirstRootObject!, p_lastObjectDatas.FirstRootObject!, initialLow);
        return promise;
    }

    public async EditPartAsync(p_objComponent: ObjectComponent, p_ref: string, p_newPart: BasicObject, p_lastPart: BasicObject): Promise<PartEditionReport[]> {
        if (!p_ref) throw new Error('Empty refOfPart : p_ref is null or undefined or empty');
        if (p_newPart == null) throw new Error('NullReferenceException : p_newPart is null or undefined');
        if (p_lastPart == null) throw new Error('NullReferenceException : p_lastPart is null or undefined');
        if (p_objComponent == null) throw new Error('NullReferenceException : p_objComponent is null or undefined');

        return this.EditObjectAsync(p_newPart, p_lastPart);
    }

    public RemoveObject(p_objectRef: string): void {
        if (!p_objectRef) throw new Error('NullReferenceException : p_objectRef is null or undefined or empty');

        let objectComponents = this.m_engine.Modules.Scene.GetComponents(ObjectComponent, {entity:false, component:false});
        let objectComponent = objectComponents.find(x => x.Ref === p_objectRef);
        if(objectComponent){
            let entity = this.m_engine.Modules.Scene.GetEntityByID(objectComponent.EntityID);
            this.m_engine.Modules.Scene.RemoveEntity(entity);
            this.m_engine.Modules.LoopStrategy.RequestRender(true);
        }
        else{
            throw new Error('BasicObject not found : '+p_objectRef+' does not exist in scene');
        }
    }

    public GetObjectRefs(): string[] {
        return this.m_objectSystem.GetAllObjectRefs();
    }

    public GetObjectNestedRefs(p_refOfInstance: string): string[]{
        if (!p_refOfInstance) throw new Error('NullReferenceException : p_refOfInstance is null or undefined or empty');
        return this.m_objectSystem.GetObjectNestedRefs(p_refOfInstance);
    }

    public ApplyOption(p_refOfObject: string, p_partRef: string, p_optRef: string, p_value: string): void {
        if (!p_refOfObject) throw new Error('NullReferenceException : p_productRef is null or undefined or empty');
        
        // TODO futur : loop sur les toutes les parts du produit pour déclencher l'option visuelle ex: relax des canap
        let objectComponents = this.m_engine.Modules.Scene.GetComponents(ObjectComponent, { entity: false, component: false });
        
        objectComponents.forEach((objComponent) => {
            if (objComponent.Ref === p_refOfObject) {
                let partEntityId = objComponent.GetEntityIdOfPart(p_partRef);
                if (partEntityId) {
                    let partEntity = this.m_engine.Modules.Scene.GetEntityByID(partEntityId);
                    if (partEntity.HasComponentOfType(OptionsComponent)) {
                        let options = partEntity.GetComponentOfType(OptionsComponent);
                        options.Execute(p_optRef, p_value);
                    }
                    //TODO MOVE
                    this.m_engine.Modules.LoopStrategy.RequestRender(true);
                }
            }
        });
    }
    
    public GetRelativeEntity(p_refOfInstance: string): SceneEntity | undefined {
        if (!p_refOfInstance) throw new Error('NullReferenceException : p_refOfInstance is null or undefined or empty');
        
        return this.m_objectSystem.GetObjectEntity(p_refOfInstance);
    }

    private async LoadObjectAsync(
        p_ref:string,
        p_part: BasicObject,
        p_objectDecoration: (p_object: BasicObject, p_partsEntity: Map<string, SceneEntity>, p_entity: SceneEntity)=> void,
        p_instance?: Instance3D,
        p_parentEntity?:SceneEntity)
        : Promise<{ref:string, entity:SceneEntity}[]> {

        if (p_objectDecoration == null) throw new Error('NullReferenceException : p_objectDecoration is null or undefined');

        let compositionEntity = this.m_engine.Modules.Scene.CreateEntity(p_ref);
        if (p_instance) {
            LutithreeObject3DUtils.ApplyTransform(compositionEntity.Transform.GetObject(), p_instance.Transform);
        }
        if (p_parentEntity) p_parentEntity.Transform.GetObject().add(compositionEntity.Transform.GetObject());

        return new Promise<{ref:string, entity:SceneEntity}[]>((resolve) => {
            let loadingPromise = this.GetObjectLoadingPromise(compositionEntity, p_ref, p_part, p_instance, p_parentEntity);
            loadingPromise.then((result)=>{
                p_objectDecoration(p_part, result, compositionEntity!);
                let objectComponent = compositionEntity!.HasComponentOfType(ObjectComponent) ? compositionEntity!.GetComponentOfType(ObjectComponent): undefined;

                result.forEach((partEntity, refOfPart)=>{
                    if (objectComponent) {
                        // Apply parts transforms
                        if(p_part.TransformByParts){
                            let transformData = p_part.TransformByParts.find(x => x.RefOfPart === refOfPart);
                            if(transformData) LutithreeObject3DUtils.ApplyTransform(partEntity.Transform.GetObject(), transformData.Transform);
                        }
                        // Apply object pivot
                        objectComponent.AddPartEntity(refOfPart, partEntity);
                        this.m_objectDecorator.DecoratePart(partEntity, p_part, compositionEntity, refOfPart);

                        if(p_part.Composition.Type === 'Asset3D' || p_part.IsComposed === false){
                            this.SetupAssetPart(compositionEntity!, partEntity);
                            if(this.HasHooksInAssets(p_part.Composition.PartElements as Asset3D[])){
                                this.m_engine.Modules.EventManager.Publish(EntityHooksChangeEvent, new EntityHooksChangeEvent(compositionEntity!));
                            }
                        }
                        else this.m_engine.Modules.EventManager.Publish(ObjectLoadedEvent, new ObjectLoadedEvent(partEntity));
                        
                        /** @deprecated basicObject.assets should not be used*/
                        if(p_part.Assets.length > 0){
                            this.SetupAssetPart(compositionEntity!, partEntity);
                            if(this.HasHooksInAssets(p_part.Assets)){
                                this.m_engine.Modules.EventManager.Publish(EntityHooksChangeEvent, new EntityHooksChangeEvent(compositionEntity!));
                            }
                        }
                    }
                });

                let objectHeight: number;
                if(p_instance)  objectHeight = p_instance.Transform.position.y;
                else objectHeight =  compositionEntity.Transform.GetObject().position.y;
                this.RepositionUndergroundObjects(compositionEntity, objectHeight);

                if(p_part.IsComposed === true){
                    let connectivity = new Connectivity();
                    connectivity.Connections = p_part.Assembly.Connections;
                    connectivity.Connectors = p_part.Assembly.Connectors;
                    this.m_engine.Modules.EventManager.Publish(EntityConnectorsChangeEvent, new EntityConnectorsChangeEvent(compositionEntity!, connectivity));
                }

                this.m_engine.Modules.EventManager.Publish(ObjectGeometryChangedEvent, new ObjectGeometryChangedEvent(compositionEntity));

                resolve([{ ref:p_part.RefOfInstance, entity:compositionEntity }]);
            });
        });
    }
    

    private async EditObjectAsync(p_newObjectState: BasicObject, p_lastObjectState: BasicObject, p_offset?: number | undefined): Promise<PartEditionReport[]> {
        return new Promise<PartEditionReport[]>((resolve) => {
            let entity: SceneEntity | undefined = this.GetRelativeEntity(p_newObjectState.RefOfInstance);
            if (entity) {
                let editPromise = this.GetObjectEditingPromise(p_newObjectState.Ref, p_newObjectState, p_lastObjectState, entity);
                editPromise.then((result) => {
                    let objectComponent = entity!.HasComponentOfType(ObjectComponent) ? entity!.GetComponentOfType(ObjectComponent): undefined;
                    if (objectComponent) {
                        result.newParts.forEach((partEntity, refOfPart) => {
                            objectComponent!.AddPartEntity(refOfPart, partEntity);
                            if(this.m_objectDecorator) this.m_objectDecorator.DecoratePart(partEntity, p_newObjectState, entity!, refOfPart);
                            this.SetupAssetPart(entity!, partEntity);
                            if(p_newObjectState.IsComposed || p_newObjectState.Composition.Type === 'BasicObject' || p_newObjectState.Composition.Type === 'RoomObject') 
                                this.m_engine.Modules.EventManager.Publish(ObjectLoadedEvent, new ObjectLoadedEvent(partEntity));
                        });
                        
                        if(p_newObjectState.Composition.Type === 'Asset3D'|| !p_newObjectState.IsComposed){
                            if(this.HasHooksInAssets(p_newObjectState.Composition.PartElements as Asset3D[])){
                                this.m_engine.Modules.EventManager.Publish(EntityHooksChangeEvent, new EntityHooksChangeEvent(entity!));
                            }
                        }
                    }
                    
                    this.m_engine.Modules.EventManager.Publish(ObjectGeometryChangedEvent, new ObjectGeometryChangedEvent(entity!));
                    if(p_offset != undefined)
                        this.PositionBBLowToHeight(entity!, p_offset);
                    
                    if(p_newObjectState.IsComposed === true){
                        let connectivity = new Connectivity();
                        connectivity.Connections = p_newObjectState.Assembly.Connections;
                        connectivity.Connectors = p_newObjectState.Assembly.Connectors;
                        this.m_engine.Modules.EventManager.Publish(EntityConnectorsChangeEvent, new EntityConnectorsChangeEvent(entity!, connectivity));
                    }

                    if(this.HasGeometryBeenEdited(result.reports)){
                        this.m_engine.Modules.EventManager.Publish(ObjectGeometryChangedEvent, new ObjectGeometryChangedEvent(entity!));
                    }

                    if (objectComponent) this.m_engine.Modules.EventManager.Publish(
                        ObjectEditedEvent, 
                        new ObjectEditedEvent(entity!, objectComponent, result.newParts, result.reports));

                    this.m_engine.Modules.LoopStrategy.RequestRender(true);
                    resolve(result.reports);
                });
            }
        });
    }

    private GetObjectLoadingPromise(p_compositionEntity:SceneEntity, p_ref:string, p_part: BasicObject, p_instance?: Instance3D, p_parentEntity?:SceneEntity): Promise<Map<string, SceneEntity>> {
        if(p_part.Composition.PartElements.length > 0){
            if(p_part.Composition.Type === 'BasicObject'){
                return this.m_objectComposition.LoadCompositionAsync(p_part.RefOfInstance, p_part.Composition, p_compositionEntity);
            }
            else if(p_part.Composition.Type === 'Asset3D'){
                return this.m_assetComposition.LoadCompositionAsync(p_part.RefOfInstance, p_part.Composition, p_compositionEntity);
            }
        }
        else if(p_part.IsComposed){
            let subObjects = this.m_dataAccess.GetBasicObjects(p_part.Assembly.SubObjectsRef);
            return this.LoadVariousObjectAsync(p_part.RefOfInstance, subObjects, p_compositionEntity);
        }
        /** @deprecated basicObject.assets should not be used*/
        else if(p_part.Assets.length > 0){
            let composition = new Composition();
            composition.PartElements = p_part.Assets;
            return this.m_assetComposition.LoadCompositionAsync(p_part.RefOfInstance, composition, p_compositionEntity);
        }

        return new Promise<Map<string, SceneEntity>>(() => {});
    }
    
    private async LoadVariousObjectAsync(p_ref:string, p_subObjects: BasicObject[], p_parentEntity: SceneEntity): Promise<Map<string, SceneEntity>>{
        if (!p_ref) throw new Error('NullReferenceException : p_assets is null or undefined or etmpty');
        if (p_subObjects == null) throw new Error('NullReferenceException : p_subObjects is null or undefined');

        p_parentEntity.Transform.GetObject().visible = false;
        return new Promise<Map<string, SceneEntity>>((resolve, reject) => {
            let promises: Array<Promise<{ref:string, entity:SceneEntity}[]>> = new Array<Promise<{ref:string, entity:SceneEntity}[]>>();
            let partEntities: Map<string, SceneEntity> = new Map<string, SceneEntity>();

            p_subObjects.forEach((basicObject) => {
                let instance3d = this.m_dataAccess.GetInstance(basicObject.RefOfInstance);
                let objectDecoration = (p_object: BasicObject, p_partsEntity: Map<string, SceneEntity>, p_entity: SceneEntity) =>
                    this.m_objectDecorator.DecorateObject(p_entity, p_object,instance3d);
                
                let promise = this.LoadObjectAsync(
                    basicObject.RefOfInstance,
                    basicObject,
                    objectDecoration,
                    instance3d,
                    p_parentEntity);
                promise.then((result)=>{
                    partEntities.set(result[0].ref,result[0].entity);
                });
                promises.push(promise);
            });

            Promise.all(promises).then((result) => {
                p_parentEntity.Transform.GetObject().visible = true;
                resolve(partEntities);
            });
        });
    }

    private GetObjectEditingPromise(p_ref: string, p_newPart: BasicObject, p_lastPart: BasicObject, p_entity: SceneEntity):
        Promise<{newParts:Map<string, SceneEntity>, reports:PartEditionReport[]}> {

        if(p_lastPart.Composition.PartElements.length > 0){
            if(p_lastPart.Composition.Type === 'BasicObject'){
                return this.m_objectComposition.EditCompositionAsync(p_ref, p_newPart.Composition, p_lastPart.Composition, p_entity);
            }
            else if(p_lastPart.Composition.Type === 'Asset3D'){
                return this.m_assetComposition.EditCompositionAsync(p_ref, p_newPart.Composition, p_lastPart.Composition, p_entity);
            }
        }
        else if(p_newPart.IsComposed && this.m_newObjectDatas && this.m_lastObjectDatas){
            let newSubObjects = this.m_newObjectDatas.GetBasicObjects(p_newPart.Assembly.SubObjectsRef);
            let lastSubObjects = this.m_lastObjectDatas.GetBasicObjects(p_lastPart.Assembly.SubObjectsRef);
            return this.EditVariousObjectAsync(p_ref, newSubObjects, lastSubObjects, p_entity);
        }
        /** @deprecated basicObject.assets should not be used*/
        else if(p_lastPart.Assets.length > 0){
            let composition1 = new Composition();
            composition1.PartElements = p_lastPart.Assets;
            let composition2 = new Composition();
            composition2.PartElements = p_newPart.Assets;
            return this.m_assetComposition.EditCompositionAsync(p_ref, composition2, composition1, p_entity);
        }

        return new Promise<{newParts:Map<string, SceneEntity>, reports:PartEditionReport[]}>(() => { });
    }
    
    private EditVariousObjectAsync(p_ref: string, p_newObjects: BasicObject[], p_lastObjects: BasicObject[], p_entity: SceneEntity): 
        Promise<{newParts:Map<string, SceneEntity>, reports:PartEditionReport[]}>{
       
        if (!p_ref) throw new Error('NullReferenceException : p_ref is null or undefined or etmpty');
        if (p_newObjects == null) throw new Error('NullReferenceException : p_newObjects is null or undefined');
        if (p_lastObjects == null) throw new Error('NullReferenceException : p_lastObjects is null or undefined');
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        
        let editionReport :PartEditionReport[] = [];
        let promises: Array<Promise<PartEditionReport[] | {ref: string, entity: SceneEntity}[] | Map<string, SceneEntity>>>
            = new Array<Promise<PartEditionReport[] | Map<string, SceneEntity>>>();

        let newPartEntities: Map<string, SceneEntity> = new Map<string, SceneEntity>();

        if (p_entity.HasComponentOfType(ObjectComponent)) {
            let objComponent: ObjectComponent = p_entity.GetComponentOfType(ObjectComponent);
           

            let elementsByPart1: Map<string, Array<BasicObject>> = GetPartElementsByRef(p_lastObjects);
            let elementsByPart2: Map<string, Array<BasicObject>> = GetPartElementsByRef(p_newObjects);

            // Suppress removed parts
            let removedParts = this.m_comparator.GetRemovedKeys(elementsByPart1, elementsByPart2);
            removedParts.forEach((value, refOfPart) => {
                let entityId = objComponent.GetEntityIdOfPart(refOfPart);
                if (entityId) {
                    this.m_engine.Modules.Scene.RemoveEntityByID(entityId);
                    objComponent.RemovePart(refOfPart);
                    editionReport.push(new PartEditionReport(refOfPart, EditionType.Remove, "BasicObject"));
                }
            });

            // Create added parts
            let addedParts = this.m_comparator.GetAddedKeys(elementsByPart1, elementsByPart2);
            addedParts.forEach((partElements, partRef) => {
                let basicObject = partElements[0];
                let instance3d = this.m_newObjectDatas!.GetInstance(basicObject.RefOfInstance);
                let promise = this.LoadPartAsync(
                    p_entity,
                    p_ref,
                    basicObject.RefOfInstance,
                    basicObject,
                    instance3d);
                promise.then((partEntity) => {
                    newPartEntities.set(partEntity[0].ref, partEntity[0].entity);
                });
                promises.push(promise);
                editionReport.push(new PartEditionReport(partRef, EditionType.Remove, "BasicObject"));
            });

            // In same parts, find edited parts
            let sameParts = this.m_comparator.GetSameKeys(elementsByPart1, elementsByPart2);
            sameParts.forEach((assets, partRef) => {
                let target1 = elementsByPart1.get(partRef)![0];
                let target2 = elementsByPart2.get(partRef)![0];
                let promise = this.EditPartAsync(objComponent, partRef, target2, target1);
                promise.then((result)=>{
                    editionReport = editionReport.concat(result);
                });
                promises.push(promise);
            });
        }

        return new Promise<{newParts:Map<string, SceneEntity>, reports:PartEditionReport[]}>((resolve, reject) => {
            Promise.all(promises).then((result) => {
                resolve({newParts: newPartEntities, reports: editionReport});
            });
        });
    }
    
    private HasHooksInAssets(p_partElements:Asset3D[]): boolean {
        if(p_partElements.some(x=>x.Type === "Model3d" && (x.Datas as Model3dData).Hooks !== undefined)){
            return true;
        }
        else return false; 
    }
    
    private SetupAssetPart(p_objectEntity: SceneEntity, p_partEntity: SceneEntity): void {
        let boundingBox = p_objectEntity.HasComponentOfType(BoundingBoxComponent)?p_objectEntity.GetComponentOfType(BoundingBoxComponent):undefined;
        
        if(boundingBox){
            let meshFilters = p_partEntity.GetComponentsOfType(MeshFilterComponent);
            meshFilters.forEach((meshFilter) => {
                meshFilter.AddOnEnabledCallback('boundingBoxReset-on-enable', (component, value) => {
                    this.m_engine.Modules.EventManager.Publish(ObjectGeometryChangedEvent, new ObjectGeometryChangedEvent(p_objectEntity!));
                });
                meshFilter.AddOnGeometryChangedCallback('boundingBoxReset-on-geometrychange',()=>{
                    this.m_engine.Modules.EventManager.Publish(ObjectGeometryChangedEvent, new ObjectGeometryChangedEvent(p_objectEntity!));
                });
            });
        }
    }
    
    private GetObjectBBLowestPOint(p_object: BasicObject): number{
        let entity = this.m_objectSystem.GetObjectEntity(p_object.RefOfInstance);
        if (entity && entity.HasComponentOfType(BoundingBoxComponent)) {
            let bb = entity.GetComponentOfType(BoundingBoxComponent);
            return bb.Center.y - bb.HalfSize.y;
        }
       else throw new Error("Target object had no BoundingBox"); 
    }
    
    private PositionBBLowToHeight(p_entity: SceneEntity, p_objectHeight: number): void {
        if (p_entity.HasComponentOfType(BoundingBoxComponent)) {
            let bb = p_entity.GetComponentOfType(BoundingBoxComponent);
            let lowestPoint = bb.Center.y - bb.HalfSize.y;
            let deltaY = p_objectHeight - lowestPoint;
            let objectComponent = p_entity.HasComponentOfType(ObjectComponent) ? p_entity.GetComponentOfType(ObjectComponent) : undefined;
            if (objectComponent) {
                this.m_objectSystem.GetNestedPartEntities(objectComponent).forEach((partEntity) => {
                    partEntity.Transform.GetObject().position.y = partEntity.Transform.GetObject().position.y + deltaY;
                });
            }
        }
    }
    
    private RepositionUndergroundObjects(p_entity: SceneEntity, p_objectHeight: number): void{
        if(p_entity.HasComponentOfType(BoundingBoxComponent)){
            let bb = p_entity.GetComponentOfType(BoundingBoxComponent);
            let deltaY = bb.Center.y - bb.HalfSize.y - p_objectHeight;
         
            if(deltaY < 0 ){
                let objectComponent = p_entity.HasComponentOfType(ObjectComponent)?p_entity.GetComponentOfType(ObjectComponent):undefined;
                if(objectComponent){
                    this.m_objectSystem.GetNestedPartEntities(objectComponent).forEach((partEntity)=>{
                        partEntity.Transform.GetObject().position.y = partEntity.Transform.GetObject().position.y - deltaY;
                    });
                }
            }
        }
    }

    private HasGeometryBeenEdited(p_editionReport: PartEditionReport[]) : boolean {

        if(p_editionReport.some(x=>(x.PartType === "Asset3D" || x.PartType === "BasicObject") && (x.EditionType === EditionType.Remove || x.EditionType === EditionType.Add))){
            return true;
        }

        if(p_editionReport.some(x=>x.AssetType === undefined && (x.EditionType === EditionType.Remove || x.EditionType === EditionType.Add))){
            return true;
        }

        if(p_editionReport.some(x=>x.AssetType === 'Model' || x.AssetType === 'Shape' || x.AssetType === 'Geometry' || x.AssetType === 'Model3d' )){
            return true;
        }

        return false;
    }
}
