import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Engine } from '@lutithree/build/Engine';
import { TranslatableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/TranslatableComponent';
import { YControlableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/YControlableComponent';
import { RotControlableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/RotControlableComponent';
import { AutoReorientationComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/AutoReorientationComponent';
import { SnappableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SnappableComponent';
import { TransformControlSystem } from "@lutithree/build/Modules/WebGL/Scene/Systems/TransformControlSystem";
import { SetSceneNodesToRaycast } from "@lutithree/build/Librairies/LSCustomControls/LSTransformControls";
import { DomElementComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Rendering/DomElementComponent";
import { RaycastableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/RaycastableComponent";
import { TransformControlComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Controls/TransformControlComponent";
import {
    IViewModeController
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Cameras/IViewModeController";
import {
    BasicSelectionEffects
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Selection/BasicSelectionEffects";
import IControlsCallbacks
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Features3D/IControlsCallbacks";
import IRelativePositioning
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Features3D/IRelativePositioning";
import InteractiveConnectorsService
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/UI/InteractiveConnectorsService";
import {
    ViewMode
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Cameras/ViewMode";
import InfoComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/InfoComponent";

export class SelectionEffects extends BasicSelectionEffects {

    private m_pointOfView: IViewModeController;

    private m_controlsCallbacks: IControlsCallbacks;
    
    private m_relativePositioning: IRelativePositioning;

    private m_transformControlSystem: TransformControlSystem;
    
    private m_rootEntities: Map<string, SceneEntity>;
    
    public constructor(p_engine: Engine, p_pointOfView: IViewModeController, p_controlCallbacks: IControlsCallbacks, 
                       p_helperService: IRelativePositioning, p_interactiveConnectorsService: InteractiveConnectorsService, p_rootEntities: Map<string, SceneEntity>) {
        super(p_engine, p_interactiveConnectorsService);
        if (p_pointOfView == null) throw new Error('NullReferenceException : p_pointOfView is null or undefined');
        if (p_controlCallbacks == null) throw new Error('NullReferenceException : p_controlCallbacks is null or undefined');
        if (p_helperService == null) throw new Error('NullReferenceException : p_helperService is null or undefined');
        if (p_rootEntities == null) throw new Error('NullReferenceException : p_rootEntities is null or undefined');

        this.m_pointOfView = p_pointOfView;
        this.m_controlsCallbacks = p_controlCallbacks;
        this.m_relativePositioning = p_helperService;
        this.m_interactiveConnectors = p_interactiveConnectorsService;
        this.m_rootEntities = p_rootEntities;

        let createTransformControl = (p_name: string) => {
            let helperEntities = p_rootEntities.has('Helpers')?p_rootEntities.get('Helpers'):undefined;
            
            let camera = this.m_pointOfView.CurrentCamera;
            let entityBuffer = p_engine.Modules.Scene.CreateEntity(p_name);
            let renderDoms = p_engine.Modules.Scene.GetComponents(DomElementComponent, { entity: false, component: false });
            
            entityBuffer.AddComponentOfType(RaycastableComponent);
            SetSceneNodesToRaycast([p_engine.Modules.Scene.ThreeScene]);
            
            let transformControlComponent = entityBuffer.AddComponentOfType(TransformControlComponent, camera.GetObject(), renderDoms[0].DomElement);
            if(helperEntities) {
                helperEntities.Transform.GetObject().attach(entityBuffer.Transform.GetObject());
            }
            return transformControlComponent;
        };
        this.m_transformControlSystem = new TransformControlSystem(p_engine.Modules.Scene, createTransformControl);
    }
    
    public EnableRotationGizmo(p_entity: SceneEntity, p_value: boolean): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_value == null) throw new Error('NullReferenceException : p_value is null or undefined');
        if(p_value) {
            // Enable rotation
            if (p_entity.HasComponentOfType(RotControlableComponent, false)) {
                this.m_transformControlSystem.ApplyRotationControl(
                    p_entity,
                    this.m_controlsCallbacks.DraggingChangeRotCallback,
                    this.m_controlsCallbacks.RotationChangeCallback);
                if (p_entity.HasComponentOfType(AutoReorientationComponent) && p_entity.HasComponentOfType(SnappableComponent) && 
                    p_entity.GetComponentOfType(SnappableComponent).Snapped.length > 0) {
                    p_entity.GetComponentOfType(RotControlableComponent).Enable(false);
                }
                else p_entity.GetComponentOfType(RotControlableComponent).Enable(true);
            }
        }
        else{
            // Disable rotation
            if (p_entity.HasComponentOfType(RotControlableComponent, false)) {
                p_entity.GetComponentOfType(RotControlableComponent).Enable(false);
                this.m_transformControlSystem.ReleaseControls(p_entity, 'rotate');
            }
        }
    }

    public EnableYupGizmo(p_entity: SceneEntity, p_value: boolean): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_value == null) throw new Error('NullReferenceException : p_value is null or undefined');
        
        if(p_value) {
            // Enable axis translatable
            let viewMode: ViewMode | undefined = this.m_pointOfView.CurrentViewMode;
            if (p_entity.HasComponentOfType(YControlableComponent, false)) {
                let axisTranslatable = p_entity.GetComponentOfType(YControlableComponent);
                let enable = viewMode === ViewMode.ThirdPerson;
                this.m_transformControlSystem.ApplyTranslationControl(
                    p_entity,
                    this.m_controlsCallbacks.DraggingChangeTransCallback,
                    this.m_controlsCallbacks.TranslationChangeCallback
                );
                axisTranslatable.Enable(enable);
            }
        }
        else{
            if (p_entity.HasComponentOfType(YControlableComponent, false)) {
                p_entity.GetComponentOfType(YControlableComponent).Enable(false);
                this.m_transformControlSystem.ReleaseControls(p_entity, 'translate');
            }
        }
    }

    public EnableTranslationOnPlane(p_entity: SceneEntity, p_value: boolean): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_value == null) throw new Error('NullReferenceException : p_value is null or undefined');

        if(p_value) {
            let viewMode: ViewMode | undefined = this.m_pointOfView.CurrentViewMode;
            // Enable plane translation
            if (p_entity.HasComponentOfType(TranslatableComponent, false)) {
                let enableTranslation = true;

                if (p_entity.HasComponentOfType(InfoComponent)) {
                    let type = p_entity.GetComponentOfType(InfoComponent).Info.Type;
                    enableTranslation = type === 'Wall' && viewMode === ViewMode.ThirdPerson ? false : true;
                }

                p_entity.GetComponentOfType(TranslatableComponent).Enable(enableTranslation);
            }
        }
        else{
            // Disable plane translation
            if (p_entity.HasComponentOfType(TranslatableComponent, false)) {
                p_entity.GetComponentOfType(TranslatableComponent).Enable(false);
            }
        }
    }

    public EnableRelativePositioning(p_entity: SceneEntity, p_value: boolean): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_value == null) throw new Error('NullReferenceException : p_value is null or undefined');
        
        if(p_value){
            this.m_relativePositioning.EnableRelativePositioningOnEntity(p_entity);
        }else{
            this.m_relativePositioning.DisableRelativePositioningOnEntity(p_entity);
        }
    }
}
