import { AComponent } from '@lutithree/build/Modules/Core/Entity/AComponent';
import { IComponent } from '@lutithree/build/Modules/Core/Entity/IComponent';

export default class PartComponent extends AComponent implements IComponent {

    // TODO after MDD Composition applyed => remove '?'
    protected m_ref?: string;

    // TODO after MDD Composition applyed => remove '?'
    protected m_compositionType?: string;
    
    protected m_getParentObjectRef: () => string;

    // TODO after MDD Composition applyed => remove '?'
    public constructor( p_getParentObjectRef: () => string, p_ref?: string, p_compositionType?: string) {
        super();
        // TODO after MDD Composition applyed => remove '//'
        //if (!p_ref) throw new Error('NullReferenceException : p_ref is null or undefined or empty');
        //if (!p_compositionType) throw new Error('NullReferenceException : p_compositionType is null or undefined or empty');
        if (p_getParentObjectRef == null) throw new Error('NullReferenceException : p_getParentObjectRef is null or undefined');
        
        this.m_getParentObjectRef = p_getParentObjectRef;
        this.m_compositionType = p_compositionType;
        this.m_ref = p_ref;
    }
    
    public get ParentObjectRef(): string {
        return this.m_getParentObjectRef();
    }

    // TODO after MDD Composition applyed => remove '|undefined'
    public get Ref(): string|undefined {
        return this.m_ref;
    }

    // TODO after MDD Composition applyed => remove '|undefined'
    public get CompositionType(): string|undefined {
        return this.m_compositionType;
    }
}
