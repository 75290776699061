const initialState = {
    // partsPaths: ['/models/TableConcept/Plateaux/CONCEPT_TABLE-CARPANCOUPES-140-FERMEE-P140.glb', '/models/TableConcept/Pieds/CONCEPT_PIED01-P135.glb'],
    partsPaths: [],
};

export default function SelectedPresetReducer(state = initialState, action) {
    switch (action.type) {
        case 'PartsUpdate': {
            return {
                ...state,
                partsPaths: action.payload.paths,
            };
        }
        default:
            return state;
    }
}

/*const rootReducer = combineReducers({
    // Define a top-level state field named `todos`, handled by `todosReducer`
})*/

//export default appReducer
