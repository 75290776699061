import { Object3D, Vector3 } from 'three';
import { IHandler } from '@lutithree/build/Modules/Core/Event/IHandler';
import { YControlableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/YControlableComponent';
import { TranslatableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/TranslatableComponent';
import { SelectableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SelectableComponent';
import ARoomStudioHandler
    from "../../../../../../../../application3D-seller/Libraries/Studios/RoomStudio/ARoomStudioHandler";
import {
    DynamicScaleComponent
} from "../../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Components/DynamicScaleComponent";
import {
    ViewModeChangeEvent
} from "../../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Cameras/Events/ViewModeChangeEvent";
import {
    ViewMode
} from "../../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Cameras/ViewMode";
import ObjectComponent
    from "../../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";
import InfoComponent
    from "../../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/InfoComponent";


export default class ViewModeHandler extends ARoomStudioHandler implements IHandler<ViewModeChangeEvent> {
    
    public Handle(p_event: ViewModeChangeEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');
        
        this.m_services.Cameras.EnableEntitiesForViewMode(p_event.ViewMode);

        // Transform control display
        this.UpdateTransformControlAvailability(p_event.ViewMode);
        
        // Camera Target
        let enableCameraTarget = p_event.ViewMode === ViewMode.ThirdPerson;
        this.m_engine.Modules.Systems.CameraSystem.EnableCameraTargets(enableCameraTarget);
        
        // Wall translation availibility
        this.EnableWallTranslatable(p_event.ViewMode === ViewMode.OrtographicTop);
        if(p_event.ViewMode === ViewMode.OrtographicTop) {
            let scalers = this.m_engine.Modules.Scene.GetComponents(DynamicScaleComponent, { entity: false, component: false });
            scalers.forEach((obj) => {
                obj.Enable(true);
                obj.MainCamera = this.m_engine.Modules.Systems.CameraSystem.GetMainCameraDatas().camera;
            });
            this.m_services.Cameras.SetRoomAtCenterOf2DView();
            this.GetAllOpeningsComponent().forEach((entity)=>{
                this.m_services.ObjectBehaviours.Openings.BringOpeningToFront(true, entity.GetComponentOfType(ObjectComponent));
            });
        }
        else {
            let scalers = this.m_engine.Modules.Scene.GetComponents(DynamicScaleComponent, { entity: true, component: true });
            scalers.forEach((obj) => {
                obj.Enable(false);
            });
            let pos = this.m_services.Cameras.Get2DCamPosition()?.clone();
            if(!pos)
                console.warn("Fail to find cam 2D position");
            this.GetAllOpeningsComponent().forEach((entity)=>{
                this.m_services.ObjectBehaviours.Openings.BringOpeningToFront(false, entity.GetComponentOfType(ObjectComponent));
            });
        }

        this.m_services.Notifier.NotifyViewModeChanged(p_event.ViewMode);
    }

    private UpdateTransformControlAvailability(p_viewMode: ViewMode): void {
        let axisTranslatables = this.m_engine.Modules.Scene.GetComponents(YControlableComponent, { entity: false, component: false });
        axisTranslatables.forEach((axisTranslatable) => {
            if (axisTranslatable.Axis.equals(Object3D.DEFAULT_UP)) {
                axisTranslatable.Enable(p_viewMode === ViewMode.ThirdPerson);
            }
        });
    }
    
    private GetAllOpeningsComponent(){
        return this.m_engine.Modules.Scene.GetEntitesWithComponents([ObjectComponent]);
    }
    
    private EnableWallTranslatable(p_value: boolean): void {
        let wallComponents = this.m_engine.Modules.Scene.GetComponents(InfoComponent, { entity: false, component: false }).filter((object) => object.Info.Type === 'Wall');
        wallComponents.forEach((component) => {
            let entity = this.m_engine.Modules.Scene.GetEntityByID(component.EntityID);
            if (entity.HasComponentOfType(TranslatableComponent, false)) {
                if (!p_value) {
                    entity.GetComponentOfType(TranslatableComponent).Enable(p_value);
                } else {
                    // activate only enabled
                    if (entity.HasComponentOfType(SelectableComponent, false) && entity.GetComponentOfType(SelectableComponent).IsSelected) {
                        entity.GetComponentOfType(TranslatableComponent).Enable(p_value);
                    }
                }
            }
        }); 
    }
} 
