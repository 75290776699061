import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { PerspectiveCamera } from 'three';
import {
    DeviceOrientationComponent
} from "@lutithree/build/Modules/WebGL/Scene/Components/Controls/DeviceOrientationComponent";
import { DeviceOrientationControls } from "@lutithree/build/Librairies/LSCustomControls/DeviceOrientationControl";
import {
    ViewModeCameraComponent
} from "../../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Cameras/Components/ViewModeCameraComponent";
import {
    IEntityDecorator
} from "../../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/IEntityDecorator";
import {
    ViewMode
} from "../../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Cameras/ViewMode";

export class Camera360Decorator implements IEntityDecorator<SceneEntity> {
    private m_dom: HTMLCanvasElement;

    private m_cameraChangedCallback: () => void;

    public constructor(p_dom: HTMLCanvasElement, p_callback: () => void) {
        if (p_dom == null) throw new Error('NullReferenceException : p_dom is null or undefined');
        if (p_callback == null) throw new Error('NullReferenceException : p_callback is null or undefined');

        this.m_dom = p_dom;
        this.m_cameraChangedCallback = p_callback;
    }

    public Decorate(p_entity: SceneEntity): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        let aspect = window.innerWidth / window.innerHeight;
        let camera = new PerspectiveCamera(60, aspect, 0.1, 50);
        camera.layers.enableAll();
        camera.layers.disable(1);
        let cameraComponent = p_entity.AddComponentOfType(ViewModeCameraComponent, camera, ViewMode.FirstPerson);

        p_entity.Transform.GetObject().position.set(0,1,0);
        let deviceControl = new DeviceOrientationControls(p_entity.Transform.GetObject());
        let comp = p_entity.AddComponentOfType(DeviceOrientationComponent, deviceControl);
        comp.SetOnChangecallback(() => {
            this.m_cameraChangedCallback();
        });
    }
}
