import IStatisticsService from './IStatisticsService';
import PerformanceStatisticsTool from '../../../PerformanceStatistics';
import React from 'react';
import { WebGLRenderer } from 'three';

export default class PerformanceService implements IStatisticsService {
    m_tool: PerformanceStatisticsTool | null | undefined;

    constructor(p_tool: Promise<React.RefObject<PerformanceStatisticsTool>>) {
        if (p_tool == null) throw new Error('NullReferenceException : p_tool is null or undefined or empty');
        p_tool.then((p_arg) => {
            this.m_tool = p_arg.current;
        });
    }

    Update(p_renderer: WebGLRenderer): void {
        this.m_tool?.Update();
    }
}
