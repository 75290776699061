import { Engine } from "@lutithree/build/Engine";
import RoomStudioServices from "../RoomStudioServices";
import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import { IEvent } from "@lutithree/build/Modules/Core/Event/IEvent";
import { EntityRaycastedEvent } from "@lutithree/build/Modules/WebGL/Scene/Events/EntityRaycastedEvent";
import { Group, Material, Object3D } from "three";
import { SelectableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SelectableComponent";
import { NewFrameEvent } from "@lutithree/build/Modules/Core/GameLoop/Events/NewFrameEvent";
import { LastFrameEvent } from "@lutithree/build/Modules/Core/GameLoop/Events/LastFrameEvent";
import AContextualBehaviours from "../../../../../../application3D-common/Librairies/FSM/AContextualBehaviours";
import {
    ViewMode
} from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Cameras/ViewMode";
import EntitySelectionStatusDirtyEvent
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Selection/Events/EntitySelectionStatusDirtyEvent";

export default class MeasuringBehaviours extends AContextualBehaviours<{ Engine: Engine; Services: RoomStudioServices; Handlers: ReadonlyMap<string, IHandler<IEvent>> }> {

    public constructor(p_engine: Engine, p_services: RoomStudioServices, p_handlers: ReadonlyMap<string, IHandler<IEvent>>) {
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');
        if (p_services == null) throw new Error('NullReferenceException : p_services is null or undefined');
        super({ Engine: p_engine, Services: p_services, Handlers:p_handlers });
    }
    
    public Enter(): void {
        console.log('MeasuringBehaviours Enter!');

        this.EnableSelectionEffectOnSelected(false);

        let raycastedHandler = this.m_context.Handlers.get('selectionHandler');
        if(raycastedHandler) {
            this.m_context.Engine.Modules.EventManager.UnSuscribe(EntityRaycastedEvent, raycastedHandler);
        }

        let optimisedFrameHandler = this.m_context.Handlers.get('optimisedFrameHandler');
        if(optimisedFrameHandler) {
            this.m_context.Engine.Modules.EventManager.UnSuscribe(NewFrameEvent, optimisedFrameHandler);
            this.m_context.Engine.Modules.EventManager.UnSuscribe(LastFrameEvent, optimisedFrameHandler);
        }

        let regressedFrameHandler = this.m_context.Handlers.get('regressedFrameHandler');
        if(regressedFrameHandler) {
            this.m_context.Engine.Modules.EventManager.Suscribe(NewFrameEvent, regressedFrameHandler);
            this.m_context.Engine.Modules.EventManager.Suscribe(LastFrameEvent, regressedFrameHandler);
        }

        let measureHandler = this.m_context.Handlers.get('measureHandler');
        if(measureHandler) {
            this.m_context.Engine.Modules.EventManager.Suscribe(EntityRaycastedEvent, measureHandler);
        }

        let cleanResourcesCallback: (p_resource: Material | Material[] | Group | Object3D) => void = (resource) => {
            if (Array.isArray(resource)) {
                resource.forEach((item) => {
                    this.m_context.Engine.Modules.Resources.Cleaner.Dispose(item);
                });
            } else this.m_context.Engine.Modules.Resources.Cleaner.Dispose(resource);
        };
        
        this.m_context.Services.MeasureDrawer.AddMeasureDrawer(undefined, cleanResourcesCallback);
        this.m_context.Services.Cameras.SetViewMode(ViewMode.OrtographicTop);
        this.m_context.Services.Cameras.SetRoomAtCenterOf2DView();
        
        this.m_context.Engine.Modules.LoopStrategy.RequestRender(true);
    }

    public Exit(): void {
        console.log('MeasuringBehaviours Exit!');

        this.m_context.Engine.Modules.Rendering.OverrideRenderMode(null);
        this.EnableSelectionEffectOnSelected(true);

        let measureHandler = this.m_context.Handlers.get('measureHandler');
        if(measureHandler) {
            this.m_context.Engine.Modules.EventManager.UnSuscribe(EntityRaycastedEvent, measureHandler);
        }
        
        let regressedFrameHandler = this.m_context.Handlers.get('regressedFrameHandler');
        if(regressedFrameHandler) {
            this.m_context.Engine.Modules.EventManager.UnSuscribe(NewFrameEvent, regressedFrameHandler);
            this.m_context.Engine.Modules.EventManager.UnSuscribe(LastFrameEvent, regressedFrameHandler);
        }

        let optimisedFrameHandler = this.m_context.Handlers.get('optimisedFrameHandler');
        if(optimisedFrameHandler) {
            this.m_context.Engine.Modules.EventManager.Suscribe(NewFrameEvent, optimisedFrameHandler);
            this.m_context.Engine.Modules.EventManager.Suscribe(LastFrameEvent, optimisedFrameHandler);
        }


        let raycastedHandler = this.m_context.Handlers.get('selectionHandler');
        if(raycastedHandler) {
            this.m_context.Engine.Modules.EventManager.Suscribe(EntityRaycastedEvent, raycastedHandler);
        }

        this.m_context.Services.Selection.UnSelectAll();
        this.m_context.Services.MeasureDrawer.ClearAllMeasures();
        this.m_context.Services.MeasureDrawer.RemoveMeasureDrawers();

        this.m_context.Services.Cameras.SetViewMode(ViewMode.ThirdPerson);
        
        this.m_context.Engine.Modules.LoopStrategy.RequestRender(true);
    }
    
    private EnableSelectionEffectOnSelected(p_value: boolean): void {
        let selectedEntities = this.m_context.Services.Selection.GetSelectedEntities();
        selectedEntities.forEach((entity) => {
            let selectable = entity.HasComponentOfType(SelectableComponent)?entity.GetComponentOfType(SelectableComponent):undefined;
            if(selectable){
                selectable.IsSelectionVisible = p_value;
                this.m_context.Engine.Modules.EventManager.Publish(
                    EntitySelectionStatusDirtyEvent,
                    new EntitySelectionStatusDirtyEvent(entity,selectable));
            }
        });
    }
}