import { Group, Object3D } from 'three';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { MeshFilterComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent';
import { MeshRendererComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshRendererComponent';
import { Option } from "../../../Domain/Objects/Assets/Option";
import {OptionsComponent} from "../Components/OptionsComponent";

export default class EntityMeshController {
    public AddModel(p_entity: SceneEntity, p_object3D: Object3D): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_object3D == null) throw new Error('NullReferenceException : p_geometry is null or undefined');

        if (p_entity.HasComponentOfType(MeshFilterComponent)) {
            let meshFilter = p_entity.GetComponentOfType(MeshFilterComponent);
            meshFilter.Model = p_object3D;
        } else {
            let filter = p_entity.AddComponentOfType(MeshFilterComponent, p_object3D);
            let meshRenderer = this.GetOrAddMeshRenderer(p_entity);
            meshRenderer.AddMeshFilter(filter);
        }
    }

    public AddMeshFilter(p_entity: SceneEntity, p_model: Group, p_isSelfSufficientInMaterial: boolean): MeshFilterComponent {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_model == null) throw new Error('NullReferenceException : p_model is null or undefined');
        if (p_isSelfSufficientInMaterial == null) throw new Error('NullReferenceException : p_isSelfSufficientInMaterial is null or undefined');

        let meshRenderer: MeshRendererComponent = this.GetOrAddMeshRenderer(p_entity);

        let meshFilter = p_entity.AddComponentOfType(MeshFilterComponent, p_model, p_isSelfSufficientInMaterial);
        meshRenderer.AddMeshFilter(meshFilter);
        return meshFilter;
    }

    public FindMeshFilterWithModel(p_entity: SceneEntity, p_model: Group): MeshFilterComponent | undefined {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_model == null) throw new Error('NullReferenceException : p_model is null or undefined');

        let meshFilterToReturn: MeshFilterComponent | undefined = undefined;
        let meshFilters: MeshFilterComponent[];
        meshFilters = p_entity.GetComponentsOfType(MeshFilterComponent);
        if (meshFilters.length !== 0) {
            meshFilters.forEach((meshFilter) => {
                if (meshFilter.Model.uuid === p_model.uuid) {
                    meshFilterToReturn = meshFilter;
                }
            });
        }
        return meshFilterToReturn;
    }

    public GetOrAddMeshRenderer(p_entity: SceneEntity): MeshRendererComponent {
        let meshRenderer: MeshRendererComponent;
        if (p_entity.HasComponentOfType(MeshRendererComponent)) {
            meshRenderer = p_entity.GetComponentOfType(MeshRendererComponent);
        } else {
            meshRenderer = p_entity.AddComponentOfType(MeshRendererComponent, true, true);
        }
        return meshRenderer;
    }

    public SetOptions(p_entity: SceneEntity, p_options: Option[], p_meshFilter: MeshFilterComponent): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_options == null) throw new Error('NullReferenceException : p_options is null or undefined');

        if (p_options.length > 0) {
            if (!p_entity.HasComponentOfType(OptionsComponent, false)) {
                p_entity.AddComponentOfType(OptionsComponent);
            }
            let optionsComponent = p_entity.GetComponentOfType(OptionsComponent);
            p_options.forEach((option) => {
                optionsComponent.Add(option, p_meshFilter);
            });
        }
    }

    public RemoveModel(p_entity: SceneEntity): void {
        let meshFilters: MeshFilterComponent[] = p_entity.GetComponentsOfType(MeshFilterComponent);
        let meshRenderer: MeshRendererComponent;

        // Disconnect Components
        if (p_entity.HasComponentOfType(MeshRendererComponent)) {
            meshRenderer = p_entity.GetComponentOfType(MeshRendererComponent);
            meshRenderer.RemoveMeshFilter(meshFilters);
        }

        // remove Components from entity
        meshFilters.forEach((component) => {
            p_entity.RemoveComponent(component);
        });

        if (p_entity.HasComponentOfType(OptionsComponent)) {
            let options: OptionsComponent = p_entity.GetComponentOfType(OptionsComponent);
            p_entity.RemoveComponent(options);
        }
    }

    public RemoveMaterial(p_entity: SceneEntity): void {
        // TODO remove material
    }
}
