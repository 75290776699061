import { Engine } from "@lutithree/build/Engine";
import RoomStudioServices from "../RoomStudioServices";
import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import { IEvent } from "@lutithree/build/Modules/Core/Event/IEvent";
import { RaycastableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/RaycastableComponent";
import { SelectableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SelectableComponent";
import AContextualBehaviours from "../../../../../../application3D-common/Librairies/FSM/AContextualBehaviours";
import EntitySelectionStatusDirtyEvent
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Selection/Events/EntitySelectionStatusDirtyEvent";

export default class AccelerometerBehaviours extends AContextualBehaviours<{ Engine: Engine; Services: RoomStudioServices; Handlers: ReadonlyMap<string, IHandler<IEvent>> }> {
    
    public constructor(p_engine: Engine, p_services: RoomStudioServices, p_handlers: ReadonlyMap<string, IHandler<IEvent>>) {
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');
        if (p_services == null) throw new Error('NullReferenceException : p_services is null or undefined');
        super({ Engine: p_engine, Services: p_services, Handlers:p_handlers });
    }

    public Enter(): void {
        console.log('AccelerometerBehaviours Enter!');

        this.m_context.Services.Cameras.SetFPViewToCameraTarget();
        
        let selectedEntities = this.m_context.Services.Selection.GetSelectedEntities();
        selectedEntities.forEach((entity)=>{
            let selectable: SelectableComponent = entity.GetComponentOfType(SelectableComponent);
            selectable.IsSelectionVisible = false;
            this.m_context.Engine.Modules.EventManager.Publish(EntitySelectionStatusDirtyEvent, new EntitySelectionStatusDirtyEvent(entity,selectable));
        });
        
        this.EnableRaycastableFeature(false);

        this.m_context.Engine.Modules.LoopStrategy.RequestRender(true);
    }

    public Exit(): void {
        console.log('AccelerometerBehaviours Exit!');

        this.EnableRaycastableFeature(true);

        let selectedEntities = this.m_context.Services.Selection.GetSelectedEntities();
        selectedEntities.forEach((entity)=>{
            let selectable: SelectableComponent = entity.GetComponentOfType(SelectableComponent);
            selectable.IsSelectionVisible = true;
            this.m_context.Engine.Modules.EventManager.Publish(EntitySelectionStatusDirtyEvent, new EntitySelectionStatusDirtyEvent(entity,selectable));
        });

        this.m_context.Engine.Modules.LoopStrategy.RequestRender(true);
    }

    private EnableRaycastableFeature(p_enable: boolean){
        this.m_context.Engine.Modules.Scene.GetComponents(RaycastableComponent, { entity: false, component: false }).forEach((component)=>{
            component.Enable(p_enable);
        });
    };
}