export class Option {
    private name: string = '';

    private type: string = '';

    private value: OptionValue;

    public constructor(p_option: Option) {
        if (p_option == null) throw new Error('NullReferenceException : p_option is null or undefined');
        this.name = p_option.name;
        this.type = p_option.type;
        this.value = new OptionValue(p_option.value);
    }

    public get Name(): string {
        return this.name;
    }

    public get Type(): string {
        return this.type;
    }

    public get Value(): OptionValue {
        return this.value;
    }
}

export class OptionValue {

    private content: string = '';

    private isDefault: boolean = false;
    
    public constructor(p_optionValue: OptionValue) {
        if (p_optionValue == null) throw new Error('NullReferenceException : p_optionValue is null or undefined');
        this.content = p_optionValue.content;
        this.isDefault = p_optionValue.isDefault;
    }

    public get Content(): string {
        return this.content;
    }

    public get IsDefault(): boolean {
        return this.isDefault;
    }
}
