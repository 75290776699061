import React from 'react';

class LogItem {
    public Text: string;

    public Color: string;

    constructor(p_text: string, p_color: string) {
        this.Text = p_text;
        this.Color = p_color;
    }
}

type ConsoleProps = {
    top?: number;
    left?: number;
    height?: number;
    width?: number;
    fontSize?: number;
};

export default class ConsoleTool extends React.Component<ConsoleProps> {
    private readonly m_positionX: number | undefined;

    private readonly m_positionY: number | undefined;

    private readonly m_dimensionsX: number | undefined;

    private readonly m_dimensionsY: number | undefined;

    private m_fontSize: number | undefined;

    private m_stringList: LogItem[] = [];

    constructor(props: ConsoleProps) {
        super(props);
        this.state = { messageList: [] };
        this.m_positionY = props.top;
        this.m_positionX = props.left;
        this.m_dimensionsX = props.width;
        this.m_dimensionsY = props.height;
        this.m_fontSize = props.fontSize;
    }

    public LogToConsole = (p_text: string, p_color: string = 'black'): void => {
        if (this.m_stringList.length >= 100) this.m_stringList.splice(0, 1);
        this.m_stringList.push(new LogItem(p_text, p_color));
        this.setState({ messageList: this.m_stringList });
    };

    render() {
        return (
            <>
                <div
                    style={{
                        position: 'absolute',
                        top: this.m_positionY ? this.m_positionY : 140,
                        left: this.m_positionX ? this.m_positionX : 40,
                        height: this.m_dimensionsY ? this.m_dimensionsY : 660,
                        width: this.m_dimensionsX ? this.m_dimensionsX : 400,
                        resize: 'both',
                        overflow: 'auto',
                        border: '1px solid',
                        zIndex: '10'
                    }}
                >
                    <button onClick={()=>{this.m_stringList.Clear();}}>Clear</button>
                    <div
                        style={{
                            position: 'inherit',
                            background: '#f0f0f0',
                            width: 'inherit',
                            height: 'inherit',
                            opacity: 0.5,
                        }}
                    />
                    <div
                        style={{
                            position: 'inherit',
                            height: 'inherit',
                            width: 'inherit',
                            overflow: 'auto',
                        }}
                    >
                        {this.m_stringList.map((txt) => (
                            <p
                                key={Math.random()}
                                style={{
                                    height: 'auto',
                                    fontSize: this.m_fontSize ? this.m_fontSize : 15,
                                    marginLeft: 5,
                                    color: txt.Color,
                                }}
                            >
                                {txt.Text}
                            </p>
                        ))}
                    </div>
                </div>
            </>
        );
    }
}
