import React, { useEffect, useState } from 'react';
import { RootState } from '../../services/redux/root-reducers';
import { useDispatch, useSelector } from 'react-redux';
import { News, NewsCategory } from '../../domain/domain';
import { getNewsList, getUnreadNewsList, NewsState } from '../../services/redux/reducers/news/news.slice';
import { criticalityFilterData } from '../../utils/filterDetail';
import { getNewsCategoryList, NewsCategoryState } from '../../services/redux/reducers/news/news-cateogory-list.slice';
import NewsGridContainer from '../../business/News/NewsGridContainer';
import Layout from '../../ui-components/layout/layout';
import DialogWithIcon from '../../ui-components/Dialog/DialogWithIcon';
import { Box, Button, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContainerPaperLayout from '../../ui-components/ContainerPaperLayout';
import BasicSelect from '../../ui-components/BasicSelect';
import useRefresh from '../../utils/hooks/useRefresh';

const NewsPage = () => {
    const dispatch = useDispatch();
    const [refresh, count] = useRefresh();
    const [newsList, setNewsList] = useState<News[]>([]);
    const [categoryDropdownData, setCategoryDropdownData] = useState<NewsCategory[]>([]);
    const [itemId, setItemId] = useState<number>();
    // toutes les news :
    const { payload } = useSelector<RootState, NewsState>((state: RootState) => state.news);
    // les news non lu :
    const unreadNews = useSelector<RootState, NewsState>((state) => state.unreadNews);
    // la liste des categories :
    const categoryList = useSelector<RootState, NewsCategoryState>((state) => state.categoryList);
    const [criticality, setCriticality] = useState<string>('');
    const [category, setCategory] = useState<string>('');
    const [modal, setModal] = useState<boolean>(true);
    const [preview, setPreview] = useState<News | undefined>(undefined);
    const [resetFilter, setResetFilter] = useState<boolean>(false);
    const [unReadNews, setUnReadNews] = useState<number>(0);

    const handleClick = (): void => {
        setCategory('');
        setCriticality('');
        setResetFilter(false);
        refresh();
    };

    const handleCloseModal = () => {
        localStorage.setItem('firstconnection', 'false');
        setModal(false);
    };

    const filteredNewsList = function (c: string): News[] {
        const newsListFiltered: News[] = [];
        if (category !== '') {
            setCategory('');
        }

        payload.content.content.forEach((n) => {
            if (n.criticality === c.toUpperCase()) {
                newsListFiltered.push(n);
            }
        });

        return newsListFiltered;
    };

    const filteredNewsWithCategory = function (id: number) {
        const listFilteredWithCategory: News[] = [];

        if (criticality.length > 0) {
            if (id === itemId) {
                filteredNewsList(criticality).forEach((n) => {
                    if (n.newsCategoryId === id) {
                        listFilteredWithCategory.push(n);
                    }
                });
            } else {
                newsList.forEach((n) => {
                    if (n.newsCategoryId === id) {
                        listFilteredWithCategory.push(n);
                    }
                });
            }
        } else {
            payload.content.content.forEach((n) => {
                if (n.newsCategoryId === id) {
                    listFilteredWithCategory.push(n);
                }
            });
        }

        return listFilteredWithCategory;
    };

    const handleFilterByCriticality = (c: string) => {
        setCriticality(c);
        setNewsList(filteredNewsList(c));
    };

    const handleFilterByCategory = (selectedCategory: string) => {
        setCategory(selectedCategory);
    };

    useEffect(() => {
        dispatch(getNewsList({ status: undefined, criticality: undefined }));
        dispatch(getUnreadNewsList({ status: undefined, criticality: undefined }));
        dispatch(getNewsCategoryList());
    }, []);

    useEffect(() => {
        if (itemId) {
            setNewsList(filteredNewsWithCategory(itemId));
        }
    }, [itemId]);

    useEffect(() => {
        setCategoryDropdownData(categoryList.payload.content);
    }, [categoryList]);

    useEffect(() => {
        setNewsList(payload.content.content);
    }, [payload, count]);

    useEffect(() => {
        if (criticality !== '' || category !== '') {
            setResetFilter(true);
        }
    }, [criticality, category]);

    useEffect(() => {
        if (unreadNews.payload.content.content.length > 0) {
            setModal(true);
            setUnReadNews(unreadNews.payload.content.content.length);
        }
    }, [unreadNews]);

    return (
        <>
            <Layout navbar>
                <ContainerPaperLayout path="/projects" title="Publications">
                    <Box
                        sx={(theme) => ({
                            '& > :not(style)': { mr: 2 },
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            [theme.breakpoints.down('md')]: {
                                display: 'block',
                                '& > :not(style)': {
                                    '&:first-of-type': { mt: 0 },
                                    mr: 0,
                                    mt: 2,
                                },
                            },
                        })}
                    >
                        <Box sx={{ minWidth: 221 }}>
                            <BasicSelect title="Filtrer par criticité" items={criticalityFilterData} selected={criticality} onChange={(s) => handleFilterByCriticality(s)} />
                        </Box>
                        <Box sx={{ minWidth: 221 }}>
                            <BasicSelect
                                title="Filtrer par catégorie"
                                items={categoryDropdownData}
                                selected={category}
                                // @ts-ignore
                                onChange={(c) => handleFilterByCategory(c)}
                                setItemId={setItemId}
                            />
                        </Box>
                        {resetFilter && (
                            <Box
                                sx={(theme) => ({
                                    [theme.breakpoints.down('md')]: {
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    },
                                })}
                            >
                                <StyledButton startIcon={<CloseIcon />} onClick={handleClick} variant="text" color="secondary">
                                    Réinitialiser
                                </StyledButton>
                            </Box>
                        )}
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <NewsGridContainer
                            newsList={newsList}
                            unreadNewsList={unreadNews.payload.content.content}
                            preview={preview}
                            setPreview={setPreview}
                            reloadNewsList={() => {
                                dispatch(
                                    getNewsList({
                                        status: undefined,
                                        criticality,
                                        category: category,
                                    })
                                );
                                dispatch(
                                    getUnreadNewsList({
                                        status: undefined,
                                        criticality,
                                        category: category,
                                    })
                                );
                            }}
                        />
                    </Box>
                </ContainerPaperLayout>
            </Layout>
            {modal && localStorage.getItem('firstconnection') !== 'false' ? (
                <DialogWithIcon
                    onClose={handleCloseModal}
                    open={modal}
                    title={`Vous avez ${unReadNews} publications non lues`}
                    subtitle="Lisez-les avant de continuer"
                    icon={<VisibilityIcon />}
                    btnAction="Continuer"
                    onClickAction={handleCloseModal}
                />
            ) : null}
        </>
    );
};

const StyledButton = styled(Button)(() => ({
    padding: '6px 17px',
    '& .MuiButton-startIcon': {
        marginRight: 8,
    },
}));

export default NewsPage;
