import { useEffect, useState } from 'react';
import './password-validation.scss';
import validationVert from '../../asset/Validation-vert.svg';

const PasswordValidation = ({ password, confirm, setPasswordIsValid }: { password: string; confirm: string; setPasswordIsValid: (isValid: boolean) => void }) => {
    const [rules, setRules] = useState({
        len: { label: 'Au moins 8 caractères', validated: false },
        spec: { label: 'Au moins un caractère spécial', validated: false },
        upper: { label: 'Au moins une lettre majuscule', validated: false },
        digit: { label: 'Au moins un chiffre', validated: false },
        match: { label: 'Les mots de passe doivent correspondre', validated: false },
    });

    const passwordContainsSpecialCharacter = () => {
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        return format.test(password);
    };

    const passwordContainsAtLeastOneUpperCase = () => {
        return /[A-Z]/.test(password);
    };

    const passwordContainsAtLeastOneDigit = () => {
        return /[0-9]/.test(password);
    };

    useEffect(() => {
        const newRules = { ...rules };
        newRules.len.validated = password.length >= 8;
        newRules.spec.validated = passwordContainsSpecialCharacter();
        newRules.upper.validated = passwordContainsAtLeastOneUpperCase();
        newRules.digit.validated = passwordContainsAtLeastOneDigit();
        newRules.match.validated = password === confirm && password.length !== 0;
        setRules(newRules);
    }, [password, confirm]);

    useEffect(() => {
        setPasswordIsValid(rules.upper.validated && rules.digit.validated && rules.len.validated && rules.spec.validated);
    }, [rules]);

    const validationIcon = (validated: boolean) => {
        if (validated) {
            return <img src={validationVert} alt="password-valid" />;
        } else {
            // @ts-ignore
            return (
                <span
                    style={{
                        color: 'darkred',
                        width: '20px',
                        height: '20px',
                    }}
                    className="material-symbols-outlined"
                >
                    error
                </span>
            );
        }
    };

    return (
        <div className="password-validation">
            <div className="password-rule">
                {Object.keys(rules).map(function (key, index) {
                    return (
                        <div key={key} className="password-rule-row">
                            {/*// @ts-ignore*/}
                            {validationIcon(rules[key].validated)}
                            {/*@ts-ignore*/}
                            <div>{rules[key].label}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PasswordValidation;
