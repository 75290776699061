import { Engine } from '@lutithree/build/Engine';
import RoomStudioServices from '../RoomStudioServices';
import IdleBehaviours from './IdleBehaviours';
import ConfiguringBehaviours from './ConfiguringBehaviours';
import FurnishingBehaviours from './FurnishingBehaviours';
import { RoomStudioMode } from "../../Domain/Modes/RoomStudioMode";
import MeasuringBehaviours from "./MeasuringBehaviours";
import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import { IEvent } from "@lutithree/build/Modules/Core/Event/IEvent";
import MultiSelectionBehaviours from "./MultiSelectionBehaviours";
import AccelerometerBehaviours from "./AccelerometerBehaviours";
import AFSMBuilder from "../../../../../../application3D-common/Librairies/FSM/AFSMBuilder";

export default class RoomStudioModesBuilder extends AFSMBuilder {
    private m_engine: Engine;

    private m_services: RoomStudioServices;

    private m_handlers:  ReadonlyMap<string, IHandler<IEvent>>;
    
    public constructor(p_engine: Engine, p_services: RoomStudioServices, p_handlers:  ReadonlyMap<string, IHandler<IEvent>>) {
        super();
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');
        if (p_services == null) throw new Error('NullReferenceException : p_services is null or undefined');
        if (p_handlers == null) throw new Error('NullReferenceException : p_handlers is null or undefined');

        this.m_engine = p_engine;
        this.m_services = p_services;
        this.m_handlers = p_handlers;
    }

    protected AddStatesBehaviours(): void {
        this.AddBehaviours(RoomStudioMode[RoomStudioMode.Idle], new IdleBehaviours());
        this.AddBehaviours(RoomStudioMode[RoomStudioMode.Configuring], new ConfiguringBehaviours(this.m_engine, this.m_services, this.m_handlers));
        this.AddBehaviours(RoomStudioMode[RoomStudioMode.Furnishing], new FurnishingBehaviours(this.m_engine, this.m_services, this.m_handlers));
        this.AddBehaviours(RoomStudioMode[RoomStudioMode.Measuring], new MeasuringBehaviours(this.m_engine, this.m_services, this.m_handlers));
        this.AddBehaviours(RoomStudioMode[RoomStudioMode.MultiSelection], new MultiSelectionBehaviours(this.m_engine, this.m_services, this.m_handlers));
        this.AddBehaviours(RoomStudioMode[RoomStudioMode.Accelerometer], new AccelerometerBehaviours(this.m_engine, this.m_services, this.m_handlers));
    }

    protected CreateTransitions(): void {
        // Idle to =>
        this.CreateSimpleTransition(RoomStudioMode[RoomStudioMode.Idle], RoomStudioMode[RoomStudioMode.Furnishing]);

        // Furnishing 3D to =>
        this.CreateSimpleTransition(RoomStudioMode[RoomStudioMode.Furnishing], RoomStudioMode[RoomStudioMode.Configuring]);
        this.CreateSimpleTransition(RoomStudioMode[RoomStudioMode.Furnishing], RoomStudioMode[RoomStudioMode.Measuring]);
        this.CreateSimpleTransition(RoomStudioMode[RoomStudioMode.Furnishing], RoomStudioMode[RoomStudioMode.MultiSelection]);
        this.CreateSimpleTransition(RoomStudioMode[RoomStudioMode.Furnishing], RoomStudioMode[RoomStudioMode.Accelerometer]);

        // MultiSelection to =>
        this.CreateSimpleTransition(RoomStudioMode[RoomStudioMode.MultiSelection], RoomStudioMode[RoomStudioMode.Furnishing]);

        // Configuring to =>
        this.CreateSimpleTransition(RoomStudioMode[RoomStudioMode.Configuring], RoomStudioMode[RoomStudioMode.Furnishing]);

        // Measuring to =>
        this.CreateSimpleTransition(RoomStudioMode[RoomStudioMode.Measuring], RoomStudioMode[RoomStudioMode.Furnishing]);

        // Accelerometer to =>
        this.CreateSimpleTransition(RoomStudioMode[RoomStudioMode.Accelerometer], RoomStudioMode[RoomStudioMode.Furnishing]);
    }

    protected SetInitialState(): void {
        this.SetInitialStateByName(RoomStudioMode[RoomStudioMode.Idle]);
    }
}
