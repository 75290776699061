import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OpenFloorPaintPanel } from '../../../../services/redux/reducers/panel/panel.slice';
import { RootState } from '../../../../services/redux/root-reducers';
import {
    getFinishAndColorsByTag,
    GetFinishAndColorsByTagState,
} from '../../../../services/redux/reducers/tags/tags.slice';
import PanelSliderLayout from '../../PanelSliderLayout';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import { styled, Typography, CardActionArea, Grid } from '@mui/material';
import CardActionColor from '../../CardAction/CardActionColor';
import CardActionMaterial from '../../CardAction/CardActionMaterial';
import { RoomStudioState, setRoomAssetToEdit } from '../../../../application-3d/application3D-seller/Redux/Reducers/room-studio/RoomStudioReducer';



type PaintFloorPanelProps = {
    open: boolean;
};

const PaintFloorPanel = ({ open }: PaintFloorPanelProps) => {
    const dispatch = useDispatch();
    const floorFinishing = useSelector<RootState, GetFinishAndColorsByTagState>((state) => state.getFinishAndColorsByTags);
    const { roomNewState } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);

    const [focusFinishing, setFocusFinishing] = useState(0);

    const [iniFocus, setIniFocus] = useState(0);
    const [iniColor, setIniColor] = useState(0);

    const [focusColor, setFocusColor] = useState(0);
    const [colorList, setColorList] = useState([]);

    const handleCLose = () => {
        dispatch(OpenFloorPaintPanel(false));
    };

    useEffect(() => {
        if (open) {
            dispatch(getFinishAndColorsByTag('FLOOR'));
        }
    }, [open]);


    const handleChange = (i: number) => {
        if (focusFinishing === iniFocus) {
            if (i === focusFinishing) {
                setFocusFinishing(i);
            } else {
                setFocusColor(-1);
                setFocusFinishing(i);
            }
        } else {
            if (i !== iniFocus) {
                setFocusColor(-1);
                setFocusFinishing(i);
            } else {
                setFocusColor(iniColor);
                setFocusFinishing(i);
            }
        }
    };

    const handleChangeColor = () => {
        setIniFocus(focusFinishing);
    };


    useEffect(() => {
        let FoundColorList = undefined;
        let FoundColor = undefined;
        for (let i = 0; i < floorFinishing.payload.content.length; i++) {
            let finishing = floorFinishing.payload.content[i];
            for (let j = 0; j < finishing.coloriList.length; j++) {
                let materials = roomNewState?.Assets.filter((color: any) => {
                    return color.type === 'Material';
                });
                if (!materials) return;
                for (let y = 0; y < materials.length; y++) {
                    let foundUrl = undefined;
                    if (!(materials[y].Datas as string[]).find) return;
                    foundUrl = (materials[y].Datas as string[]).find((url) => {
                        return url === finishing.coloriList[j].modelUrl;
                    });
                    if (!foundUrl && !FoundColorList && !FoundColor) {
                        setColorList(floorFinishing.payload.content[0].coloriList);
                        setFocusColor(0);
                        setIniColor(0);
                        setFocusFinishing(0);
                        setIniFocus(0);
                    }
                    if (foundUrl) {
                        FoundColorList = finishing.coloriList;
                        FoundColor = j;
                        setFocusFinishing(i);
                        setIniFocus(i);
                    }
                }
            }
        }
        if (FoundColor != undefined && FoundColorList) {
            setColorList(FoundColorList);
            setFocusColor(FoundColor);
            setIniColor(FoundColor);
        }
    }, [floorFinishing]);

    return (
        <PanelSliderLayout onClose={ handleCLose } open={ open } title='Modifier le revêtement du sol'>
            {/*// @ts-ignore*/ }
            <Timeline
                sx={ {
                    padding: '6px 0',
                    [`& .${ timelineItemClasses.root }:before`]: {
                        flex: 0,
                        padding: 0,
                    },
                } }>
                <TimelineItem>
                    <TimelineSeparator>
                        <StyledTimelineDot color='primary' />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant='subtitle1' color='primary' sx={ { mb: 2 } }>
                            MATIÈRE
                        </Typography>
                        <Grid container spacing={ 2 }>
                            { floorFinishing.payload.content.map((finishing: any, i: number) => {
                                return (
                                    <Grid item xs={ 12 } md={ 12 } lg={ 6 } xl={ 4 } key={ i }>
                                        <CardActionArea
                                            onClick={ () => {
                                                setColorList(finishing.coloriList);
                                                handleChange(i);
                                            } }
                                        >
                                            <CardActionMaterial
                                                index={ i }
                                                focus={ focusFinishing }
                                                materialName={ finishing?.name }
                                            />
                                        </CardActionArea>
                                    </Grid>
                                );
                            }) }
                        </Grid>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <StyledTimelineDot color='primary' />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant='subtitle1' color='primary' sx={ { mb: 2 } }>
                            COULEUR
                        </Typography>
                        <Grid container spacing={ 2 }>
                            { colorList.map((color: any, i) => {
                                return (
                                    <Grid item xs={ 12 } md={ 12 } lg={ 6 } xl={ 4 } key={ i }>
                                        <CardActionArea
                                            sx={ { height: '100%' } }
                                            key={ i }
                                            onClick={ () => {
                                                setFocusColor(i);
                                                setIniColor(i);
                                                handleChangeColor();
                                                dispatch(setRoomAssetToEdit({
                                                    type: 'Material',
                                                    data: [color.modelUrl],
                                                    refOfPart: undefined,
                                                }));
                                            } }
                                        >
                                            <CardActionColor
                                                focus={ focusColor }
                                                thumbnailUrl={ color.thumbnailUrl }
                                                colorName={ color.name }
                                                index={ i }
                                            />
                                        </CardActionArea>
                                    </Grid>
                                );
                            }) }
                        </Grid>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </PanelSliderLayout>
    );
};

const StyledTimelineDot = styled(TimelineDot)(() => ({
    margin: '11px 0',
}));

export default PaintFloorPanel;