import { DisableComponent } from "@lutithree/build/Modules/Core/Entity/DisableComponent";
import { Camera, Object3D, OrthographicCamera, PerspectiveCamera, Vector3 } from "three";

export class DynamicScaleComponent extends DisableComponent {
    private mainCam: Camera;
    
    private attachedObjects: Array<{object: Object3D, scale: number, initialScale: Vector3}>;
    
    constructor(p_objects: Object3D[] | {object:Object3D, initialScale: Vector3}[], p_cam: OrthographicCamera | PerspectiveCamera) {
        super();
        this.mainCam = p_cam;
        this.attachedObjects = new Array<{object: Object3D; scale: number; initialScale: Vector3}>();
        p_objects.forEach((objectToAttach)=>{
            if((objectToAttach as { object: Object3D, initialScale: Vector3 }).object) {
                let buffObject = objectToAttach as { object: Object3D, initialScale: Vector3 };
                this.attachedObjects.push({ object: buffObject.object, scale: this.UpdateObjectScale(buffObject.object), initialScale: buffObject.initialScale });
            }else {
                let buffObject = objectToAttach as  Object3D;
                this.attachedObjects.push({ object: buffObject, scale: this.UpdateObjectScale(buffObject), initialScale: buffObject.scale.clone() });
            }
        });
    }
    
    public set InitialScale(p_value: Vector3) {
        this.attachedObjects.forEach((obj)=>{
            obj.initialScale = p_value.clone();
        });
    }
    
    public UpdateCurrentScale(): void {
        this.attachedObjects.forEach((object) => {
            object.scale = this.UpdateObjectScale(object.object);
        });
    }

    public set MainCamera(p_value: Camera) {
        this.mainCam = p_value;
    }

    public ClearObjects(){
        this.attachedObjects = [];
    }
    
    public SetObjects(p_object: Object3D[]) {
        p_object.forEach((objectToAttach) => {
            if(this.attachedObjects.filter((obj)=>{return objectToAttach.name === obj.object.name;}).length > 0) return;
            let buffObject = objectToAttach as Object3D;
            this.attachedObjects.push({ object: buffObject, scale: this.UpdateObjectScale(buffObject), initialScale: buffObject.scale.clone() });
        });
    }
    
    public SetObjectsWithClear(p_object: Object3D[]) {
        p_object.forEach((objectToAttach) => {
                let buffObject = objectToAttach as Object3D;
                this.attachedObjects.push({ object: buffObject, scale: this.UpdateObjectScale(buffObject), initialScale: buffObject.scale.clone() });
        });
    }
    
    public get Objects() {
        return this.attachedObjects;
    }
    
    private UpdateObjectScale(p_object: Object3D): number {
        if(this.mainCam instanceof OrthographicCamera)
            return 10/(this.mainCam as OrthographicCamera).zoom;
        else
            return this.mainCam.getWorldPosition(new Vector3()).sub(p_object.getWorldPosition(new Vector3())).length();   
    }
}
