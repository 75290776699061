import AService from "../../../Domain/AService";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { MarkerComponent } from "./Components/MarkerComponent";
import NBMeasureMarkerChangedEvent from "./Events/NBMeasureMarkerChangedEvent";
import { Group, Object3D, Vector3 } from "three";
import { MeasureDrawerComponent } from "./Components/MeasureDrawerComponent";
import { MarkerDecorator } from "./EntityDecorator/MarkerDecorator";
import { DynamicScaleComponent } from "../TransformControls/Components/DynamicScaleComponent";

export default class MeasureDrawerService extends AService {

    public AddMeasureDrawer(p_nbPointsMax: number|undefined, p_cleanResourcesCallback: (p_model: Group|Object3D) => void): void{
        if (p_nbPointsMax === null) throw new Error('NullReferenceException : p_nbPointsMax is null or undefined');
        if (p_cleanResourcesCallback == null) throw new Error('NullReferenceException : p_cleanResourcesCallback is null or undefined');

        // authorized only one measure drawer per scene
        this.RemoveMeasureDrawers();

        let entity = this.m_engine.Modules.Scene.CreateEntity('MeasureDrawer');
        let measureComponent = entity.AddComponentOfType(MeasureDrawerComponent, p_nbPointsMax, p_cleanResourcesCallback);
        let stickerObjects: Object3D[] = [];
        measureComponent.Stickers.forEach((sticker)=>{
            stickerObjects.push(sticker.m_stickerObject);
        });
        entity.AddComponentOfType(DynamicScaleComponent, stickerObjects, this.m_engine.Modules.Systems.CameraSystem.GetMainCameraDatas().camera);
    }

    public RemoveMeasureDrawers(): void{
        let entities = this.m_engine.Modules.Scene.GetEntitesWithComponents([MeasureDrawerComponent]);
        for(let i: number = 0; i<entities.length; i++){
            this.m_engine.Modules.Scene.RemoveEntity(entities[i]);
        }
    }

    public FindMeasureDrawer(): MeasureDrawerComponent|undefined {
        let measureDrawers = this.m_engine.Modules.Scene.GetComponents(MeasureDrawerComponent);
        if(measureDrawers.length > 0){
            return measureDrawers[0];
        }
        return undefined;
    }

    public AddMarker(p_point: Vector3, p_normal: Vector3): SceneEntity {
        if (p_point == null) throw new Error('NullReferenceException : p_point is null or undefined');

        // Create marker entity & decorate it
        let markerEntity = this.m_engine.Modules.Scene.CreateEntity('Marker');
        new MarkerDecorator(p_point,p_normal).Decorate(markerEntity, this.m_engine.Modules.Systems.CameraSystem.GetMainCameraDatas().camera);
        // Add marker to drawer tool
        let measureDrawer = this.FindMeasureDrawer();
        if(measureDrawer) {
            measureDrawer.AddMarker(markerEntity.Transform.GetObject());
            let measureEntity = this.m_engine.Scene.GetEntityByID(measureDrawer.EntityID);
            let stickerObjects: Object3D[] = [];
            measureDrawer.Stickers.forEach((sticker) => {
                stickerObjects.push(sticker.m_stickerObject);
            });
            if(measureEntity && measureEntity.HasComponentOfType(DynamicScaleComponent))
                measureEntity.GetComponentOfType(DynamicScaleComponent).SetObjects(stickerObjects);
        }

        let nb = this.m_engine.Modules.Scene.GetComponents(MarkerComponent).length;
        this.m_engine.Modules.EventManager.Publish(NBMeasureMarkerChangedEvent, new NBMeasureMarkerChangedEvent(nb));

        return markerEntity;
    }
    
    public UpdateMeasuresRelatedToMarker(p_entity: SceneEntity): void{
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (!p_entity.HasComponentOfType(MarkerComponent)) return;
        let measureDrawer = this.FindMeasureDrawer();
        if(measureDrawer) {
            measureDrawer.UpdateMeasureWithMarker(p_entity.Transform.GetObject());
        }
    }
    
    public ClearAllMeasures(): void {
        this.RemoveAllMarkers();
        let measureDrawer = this.FindMeasureDrawer();
        if(measureDrawer) {
            let measureEntity = this.m_engine.Scene.GetEntityByID(measureDrawer.EntityID);
            if (measureEntity && measureEntity.HasComponentOfType(DynamicScaleComponent))
                measureEntity.GetComponentOfType(DynamicScaleComponent).ClearObjects();
            measureDrawer.Clear();
            this.m_engine.Modules.LoopStrategy.RequestRender(true);
            this.m_engine.Modules.EventManager.Publish(NBMeasureMarkerChangedEvent, new NBMeasureMarkerChangedEvent(0));
        }
    }

    private RemoveAllMarkers(): void {
        let markerEntities = this.m_engine.Modules.Scene.GetEntitesWithComponents([MarkerComponent]);
        markerEntities.forEach((entity)=>{
            this.m_engine.Modules.Scene.RemoveEntity(entity);
        });
    }
}