import * as React from 'react';
import { PropsWithChildren } from "react";
import { Box, styled, Typography } from "@mui/material";


type CardActionMaterialProps = PropsWithChildren<{
    materialName: string;
    index: number;
    focus: number;
}>;

function CardActionMaterial({ materialName, index, focus }: CardActionMaterialProps) {

    return (
        <BoxActionMaterial
            sx={ (theme: { palette: { primary: { main: string } } }) => ({
                border: `${ focus === index ? `solid 3px ${ theme.palette.primary.main }` : `1px solid #CCCCCC` };`
            }) }
        >
            <Typography sx={ { fontSize: 15, lineHeight: 1.1, wordBreak: 'break-word' } } variant='subtitle1'
                        color='primary'>
                { materialName }
            </Typography>
        </BoxActionMaterial>
    );
}

const BoxActionMaterial = styled(Box)(() => ({
    borderRadius: 3,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textTransform: 'capitalize',
    height: 55
}));

export default CardActionMaterial;