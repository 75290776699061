import AService from "../../../Domain/AService";
import { IDimensions } from "../../../Domain/Features3D/IDimensions";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { Dimension, DimensionsDisplayerComponent } from "./Components/DimensionDisplayerComponent";
import { DimensionDisplayerDecorator } from "./EntityDecorator/DimensionDisplayerDecorator";
import DimensionsSystem from "./Systems/DimensionsSystem";
import { Engine } from "@lutithree/build/Engine";
import { DimensionComponent } from "./Components/DimensionComponent";
import DimensionsInfo from "./DimensionsInfo";

export default class DimensionService extends AService implements IDimensions {
    
    private m_dimensionSystem: DimensionsSystem;
    
    public constructor(p_engine: Engine) {
        super(p_engine);
        this.m_dimensionSystem = new DimensionsSystem(p_engine.Modules.Scene);
    }
    
    public EnableDimensionOnEntity(p_entity: SceneEntity): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        let distanceDisplayer: SceneEntity = this.m_engine.Modules.Scene.CreateEntity("DistanceDisplayer");
        new DimensionDisplayerDecorator(p_entity).Decorate(distanceDisplayer);
        this.RefreshDimensions(p_entity);
        this.m_engine.Modules.LoopStrategy.RequestRender(true);
    }
    
    public DisableDimensionOnEntity(p_entity: SceneEntity): void {
        let dimensionDisplayerComponent = this.m_dimensionSystem.GetDimensionDisplayer(p_entity);
        if(dimensionDisplayerComponent) this.m_engine.Modules.Scene.RemoveEntityByID(dimensionDisplayerComponent.EntityID);
        this.m_engine.Modules.LoopStrategy.RequestRender(true);
    }

    public DisableAllDimension(): void {
        let dimensionsDisplayerComponents = this.m_engine.Modules.Scene.GetComponents(DimensionsDisplayerComponent);
        dimensionsDisplayerComponents.forEach((component)=>{
            this.m_engine.Modules.Scene.RemoveEntityByID(component.EntityID);
        });
        
        this.m_engine.Modules.LoopStrategy.RequestRender(true);
    }


    private RefreshDimensions(p_entity: SceneEntity): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        if (!p_entity.HasComponentOfType(DimensionComponent)) {
            console.warn("Entity had no dimensions");
            return;
        }

        let dimensionDisplayerComponent = this.m_dimensionSystem.GetDimensionDisplayer(p_entity);
        let dimensionComponent = p_entity.GetComponentOfType(DimensionComponent);

        if (dimensionDisplayerComponent && dimensionComponent) {
            dimensionDisplayerComponent.UpdateDistancesInfos(dimensionComponent.Dimensions);
            this.RefreshDisplayer(dimensionDisplayerComponent, this.m_dimensionSystem.GetDimensionsOfEntity(p_entity));
        }
    }
    
    private RefreshDisplayer(p_displayerComponent: DimensionsDisplayerComponent, p_dimensionInfo: DimensionsInfo) {
        p_displayerComponent.UpdateDisplayer(Dimension.LENGTH, p_dimensionInfo.Length);
        p_displayerComponent.UpdateDisplayer(Dimension.DEPTH, p_dimensionInfo.Depth);
        p_displayerComponent.UpdateDisplayer(Dimension.HEIGHT, p_dimensionInfo.Height);
    }
}