import * as React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, styled } from '@mui/material';


type ButtonEdit = {
    onClick: () => void;
};

const ButtonEdit = ({ onClick }: ButtonEdit) => {
    return (
        <CustomBtnEdit onClick={ onClick } aria-label="fingerprint" color="primary">
            <EditIcon />
        </CustomBtnEdit>
    );
};

const CustomBtnEdit = styled(IconButton)(() => ({
        background: 'rgba(29,39,64,0.04)',
    })
);

export default ButtonEdit;