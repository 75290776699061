import apiClient from '../../api-client';
import {
    CREATE_SECTION,
    DELETE_SECTION,
    GET_ALL_SECTION,
    GET_ALL_SECTION_BY_CATALOG_ID,
    GET_SECTION_BY_ID,
    UPDATE_SECTION,
} from '../../enpoint/catalog/section';
import { Section } from '../../../domain/domain';


const sectionService = {
    getAllSection: async () => apiClient(GET_ALL_SECTION, undefined, undefined, 'GET'),
    getAllSectionsByCatalogId: async (catalogId: number) => apiClient(GET_ALL_SECTION_BY_CATALOG_ID(catalogId), undefined, undefined, 'GET'),
    getSectionById: async (sectionId: number) => apiClient(GET_SECTION_BY_ID(sectionId), undefined, undefined, 'GET'),
    createSection: async (section: Section, catalogId: number) => apiClient(CREATE_SECTION(catalogId), section, undefined, 'POST'),
    updateSection: async (sectionId: number, section: Section) => apiClient(UPDATE_SECTION(sectionId), section, undefined, 'PUT'),
    deleteSection: async (sectionId: number) => apiClient(DELETE_SECTION(sectionId), undefined, undefined, 'DELETE'),
};
export default sectionService;