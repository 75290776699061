import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { Event, Group, Material, Object3D } from "three";
import { ConnectorsComponent } from "../../Features3D/Connectors/Components/ConnectorsComponent";

export class EntityConnectorsController {

    private readonly m_connectorsParentName = "(CONNECTORS)";
    
    public AddConnectorsComponent(p_entity: SceneEntity, p_uuid: string, p_model: Group, p_lastModel: Group|undefined,
                                   p_levelLoadedCallback: (p_resource: Material | Material[] | Group | Object3D<Event>) => void) : void {

        let locationsMap = this.GetDummiesByRef(p_model, this.m_connectorsParentName, p_levelLoadedCallback);
        
        // TODO convert local to global

        let components = p_entity.GetComponentsOfType(ConnectorsComponent);
        let component = components.filter(x => x.RefOfModel === p_lastModel?.uuid); //récupérer le composant hoo du bon modèle (et pas celui d'une autre opt par ex) 
        if(component.length > 0){
            if(locationsMap){
                component[0].RefOfModel = p_model.uuid;
                component[0].LocationsByRole.forEach((connectorObjects, key)=>{
                    p_levelLoadedCallback(connectorObjects);
                });
                component[0].LocationsByRole = locationsMap;
            }
            else p_entity.RemoveComponent(component[0]);
        }
        else if(locationsMap) {
            p_entity.AddComponentOfType(ConnectorsComponent, p_uuid, locationsMap);
        }
    }

    private GetDummiesByRef(p_model: Group,
                            p_parentRefName:string,
                            p_levelLoadedCallback: (p_resource: Material | Material[] | Group | Object3D<Event>) => void): Map<string, Object3D> | undefined {

        let dummiesRefs: string[] = [];
        let locationByRef: Map<string, Object3D> | undefined = undefined;
        let objectToRemove :{parent:Object3D, child:Object3D }[] =  [];

        p_model.traverse((child)=>{
            if(child.parent && child.parent.name === p_parentRefName) {
                dummiesRefs.push(child.name);
            }
            if(child.parent && dummiesRefs.includes(child.name)) {
                if (child.type === 'Mesh') {

                    if(child.parent) {
                        objectToRemove.push({parent:child.parent,child:child});
                    }
                }
            }
        });

        objectToRemove.forEach((value)=>{
            
            let emptyNode = new Object3D();
            emptyNode.position.copy(value.child.position);
            emptyNode.rotation.copy(value.child.rotation);
            emptyNode.scale.copy(value.child.scale);
            emptyNode.name = value.child.name;
            
            value.parent.remove(value.child);
            p_levelLoadedCallback(value.child as Group);
            value.parent.add(emptyNode);

            if(!locationByRef) locationByRef = new Map<string, Object3D>();
            locationByRef.set(emptyNode.name,emptyNode);
        });

        return locationByRef;
    }
}