import { IHandler } from '@lutithree/build/Modules/Core/Event/IHandler';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { BoundingBoxComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundingBoxComponent';
import { Mesh, Object3D } from 'three';
import { MeshUtils } from '@lutithree/build/Modules/WebGL/Utils/MeshUtils';
import BoundingBoxChangedEvent from "../RelativePositioning/Events/BoundingBoxChangedEvent";
import EntityScaledEvent from "../TransformControls/Events/EntityScaledEvent";
import { AHandler } from "@lutithree/build/Handlers/AHandler";

export class ResizeHandler extends AHandler implements IHandler<EntityScaledEvent> {
    public Handle(p_event: EntityScaledEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');

        this.TryRefreshBoundingBox(p_event.Entity);
        // TODO apply when refresh uv is activated in meshRenderer
        //this.RefreshObjectUV(p_event.Entity.Transform.GetObject());
    }

    private TryRefreshBoundingBox(p_entity: SceneEntity): void {
        if (p_entity.HasComponentOfType(BoundingBoxComponent)) {
            let bb = p_entity.GetComponentOfType(BoundingBoxComponent);
            bb.ComputeBoundingBox(p_entity.Transform.GetObject());
            this.m_engine.Modules.EventManager.Publish(BoundingBoxChangedEvent, new BoundingBoxChangedEvent(p_entity));
        }
    }

    private RefreshObjectUV(p_object: Object3D): void {
        p_object.traverse((child) => {
            if (child.type === 'Mesh') {
                MeshUtils.RefreshUV_world(child as Mesh);
            }
        });
    }
}
