import { Engine } from "@lutithree/build/Engine";
import { Guid } from "guid-typescript";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { SnappableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SnappableComponent";
import { AutoReorientationComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/AutoReorientationComponent";
import {
    INavigationController
} from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Features3D/INavigationController";
import {
    POVComponent
} from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Cameras/Components/POVComponent";
import ObjectComponent
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";
import AObjectBehaviour
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AObjectBehaviour";

export default class RoomObjectBehaviours extends AObjectBehaviour {

    public constructor(p_engine: Engine, p_navigation: INavigationController) {
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');
        if (p_navigation == null) throw new Error('NullReferenceException : p_navigation is null or undefined');

        super(p_engine, p_navigation);
    }

    public OnAwake(p_entity: SceneEntity): void{
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        this.HideAutoRotateOnWallViewedFromBack(p_entity);
    }
    
    public OnSelectionChange(p_entity:SceneEntity, p_isSelected:boolean): void{
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_isSelected == null) throw new Error('NullReferenceException : p_isSelected is null or undefined');
        
        if(!p_isSelected){
            this.HideAutoRotateOnWallViewedFromBack(p_entity);
        }
    }

    public GetObjectEntity(p_guid: Guid): SceneEntity {
        if (p_guid == null) throw new Error('NullReferenceException : p_guid is null or undefined');
        
        let entity = this.m_engine.Modules.Scene.GetEntityByID(p_guid);
        if (!entity.HasComponentOfType(ObjectComponent)) throw new Error('There is no planner object with guid ' + p_guid.toString());
        return entity;
    }

    public HideAutoRotateOnWallViewedFromBack(p_entity: SceneEntity): void {
        let snappable = p_entity.HasComponentOfType(SnappableComponent)? p_entity.GetComponentOfType(SnappableComponent):undefined;
        let autorotate = p_entity.HasComponentOfType(AutoReorientationComponent)? p_entity.GetComponentOfType(AutoReorientationComponent):undefined;
        if(snappable && snappable.Snapped.length>0 && autorotate) {
            let supportEntity = this.m_engine.Modules.Scene.GetEntityByID(snappable.Snapped[0].Support.EntityID);
            let povComponent = supportEntity.HasComponentOfType(POVComponent)? supportEntity.GetComponentOfType(POVComponent):undefined;
            if(povComponent && povComponent.IsViewedFromBack){
                p_entity.Enable(false);
            }
        }
    }

}