import { IEvent } from "@lutithree/build/Modules/Core/Event/IEvent";

export default class TranslationOnPlaneChangeEvent implements IEvent {
    
    private m_isActivated: boolean;
    
    public constructor(p_isActivated: boolean) {
        if (p_isActivated == null) throw new Error('NullReferenceException : p_isActivated is null or undefined');
        
        this.m_isActivated = p_isActivated;
    }
    
    public get IsActivated(): boolean {
        return this.m_isActivated;
    }
}