import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import editOn from '../../../../../application3D-common/Assets/Icons/ContextualMenu/Editer-on.svg';
import editOff from '../../../../../application3D-common/Assets/Icons/ContextualMenu/Editer-off.svg';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import {
    RoomStudioMode
} from "../../../../Libraries/Studios/RoomStudio/Domain/Modes/RoomStudioMode";
import InfoComponent
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/InfoComponent";
import ToogleIcon from "../../../../../application3D-common/Components/UI/Basics/ToogleIcon";
import {updateRoomStudioMode} from "../../../../Redux/Reducers/room-studio/RoomStudioReducer";


export type EditToggleProps = {
    entity: SceneEntity | undefined;
    setOpenEditPanel: (openEditPanel: boolean) => void;
};

const EditToogle = (props: EditToggleProps) => {
    const dispatch = useDispatch();
    const [isOn, setIsOn] = useState(false);

    const onChange = () => {
        setIsOn(!isOn);
    };

    useEffect(() => {
        if (props.entity) {
            if (isOn && props.entity.HasComponentOfType(InfoComponent)) {
                setTimeout(() => {
                    // @ts-ignore
                    let infoComponent = props.entity.GetComponentOfType(InfoComponent);
                    if (infoComponent.Info.Type === 'Product' && infoComponent.Info.HasBehaviour('Configurable')) {
                        dispatch(updateRoomStudioMode(RoomStudioMode.Configuring));
                        props.setOpenEditPanel(isOn);
                    }
                }, 100);
            }
        }
    }, [isOn]);

    return <ToogleIcon imgOn={ editOn } imgOff={ editOff } label='Editer' active={ isOn } onChange={ onChange } />;
};

export default EditToogle;
