import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { BackSide, Color, Mesh, PMREMGenerator, ShaderMaterial, SphereGeometry, WebGLRenderer } from "three";
import { MeshFilterComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent";
import { MeshRendererComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshRendererComponent";
import { GroupComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/GroupComponent";
import {
    IEntityDecorator
} from "../../../../Domain/IEntityDecorator";

export class ScreenshotEnvDecorator implements IEntityDecorator<SceneEntity> {
    private static m_pmremGenerator: PMREMGenerator;

    public constructor(p_viewport: WebGLRenderer) {
        if (p_viewport == null) throw new Error('NullReferenceException : p_viewport is null or undefined ');


        if (ScreenshotEnvDecorator.m_pmremGenerator) ScreenshotEnvDecorator.m_pmremGenerator.dispose();
        ScreenshotEnvDecorator.m_pmremGenerator = new PMREMGenerator(p_viewport);
        ScreenshotEnvDecorator.m_pmremGenerator.compileEquirectangularShader();
    }

    public Decorate(p_entity: SceneEntity) {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        let geometry = new SphereGeometry(30, 16, 16);
        let sphere = new Mesh(geometry);
        let meshFilter = p_entity.AddComponentOfType(MeshFilterComponent, sphere);
        p_entity.AddComponentOfType(GroupComponent, "Feature_ScreenshotPanier");
        
        var material = new ShaderMaterial({ 
            side:BackSide,
            depthWrite: false,
            uniforms: {
                color1: {
                    value: new Color("#121212") }, 
                color2: {
                    value: new Color("white") }
            },
            vertexShader: `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
    }
  `,
            fragmentShader: `
    uniform vec3 color1;
    uniform vec3 color2;
  
    varying vec2 vUv;
    
    void main() {
      
      gl_FragColor = vec4(mix(color1, color2, vUv.y), 1.0);
    }
  `
        });
        let meshRenderer = p_entity.AddComponentOfType(MeshRendererComponent, false, false, material);
        meshRenderer.AddMeshFilter(meshFilter);
        p_entity.Transform.GetObject().traverse((obj)=>{
            obj.layers.set(14);
        });
    }
}