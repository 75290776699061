import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Engine } from '@lutithree/build/Engine';
import { Material, Mesh, Vector3 } from 'three';
import { MeshFilterComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent';
import { MeshUtils } from '@lutithree/build/Modules/WebGL/Utils/MeshUtils';
import { SnappableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SnappableComponent";
import { BoundingBoxComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundingBoxComponent";
import IRoomStudioNotifier from "../../Domain/Notifier/IRoomStudioNotifier";
import TransformUpdates
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Features3D/TransformUpdates";
import BoundingBoxChangedEvent
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/RelativePositioning/Events/BoundingBoxChangedEvent";
import {
    Object3DUtils
} from "../../../../../../application3D-common/Librairies/Studios/Application3D/Utils/Object3DUtils";
import ObjectComponent
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";

export default class OpeningBehaviours {
    private readonly m_windowMeshRenderOrder = 10;

    private readonly m_imprintRefOfPart = 'Imprint';

    private readonly m_baseRefOfPart = 'Base';

    private readonly m_handleRefOfPart = 'Handle';

    private readonly m_glassMaterialID = 'SEETHROUGH';

    private readonly m_windowMaterialID = 'WINDOW';

    private m_transparentMaterialPath: string = 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/materials/Defaults/cloudy.json';

    private m_seethroughMaterialPath: string = 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/materials/Defaults/seethrough.json';

    private readonly m_engine: Engine;

    private m_notifier: IRoomStudioNotifier;

    public constructor(p_engine: Engine, p_notifier: IRoomStudioNotifier) {
        if (p_notifier == null) throw new Error('NullReferenceException : p_notifier is null or undefined');
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');

        this.m_engine = p_engine;
        this.m_notifier = p_notifier;
    }

    public OnAwake(p_entity: SceneEntity, p_objectComponent: ObjectComponent): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_objectComponent == null) throw new Error('NullReferenceException : p_objectComponent is null or undefined');
        
        let snappable = p_entity.HasComponentOfType(SnappableComponent)? p_entity.GetComponentOfType(SnappableComponent): undefined;

        let imprintEntity = this.GetEntity(this.m_imprintRefOfPart, p_objectComponent);
        let baseEntity = this.GetEntity(this.m_baseRefOfPart, p_objectComponent);
        
        if (baseEntity) {
            if(snappable){
                if(snappable.Snapped.length===0){
                    this.ApplyRenderOrderOnMesh(baseEntity, 0);
                }
            }
            if (imprintEntity){
                this.ReplaceImprintOnBase(baseEntity, imprintEntity);
                this.OnRescale(p_entity, p_objectComponent);
            }
        }
    }

    public BringOpeningToFront(p_show: boolean, p_objectComponent: ObjectComponent) {
        let imprintEntity = this.GetEntity(this.m_imprintRefOfPart, p_objectComponent);
        if (imprintEntity)
        {
            imprintEntity.Transform.GetObject().traverse((obj) =>
            {
                if (obj.type === "Mesh")
                    ((obj as Mesh).material as Material).depthTest = !p_show;
            });
        }
    }
    
    public OnRescale(p_entity: SceneEntity, p_objectComponent: ObjectComponent): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_objectComponent == null) throw new Error('NullReferenceException : p_roomObjectComponent is null or undefined');
        
        let parts: SceneEntity[] = [];

        let imprintEntity = this.GetEntity(this.m_imprintRefOfPart, p_objectComponent);
        let baseEntity = this.GetEntity(this.m_baseRefOfPart, p_objectComponent);
        let handleEntity = this.GetEntity(this.m_handleRefOfPart, p_objectComponent);

        if (handleEntity) parts.push(handleEntity);

        if (imprintEntity && baseEntity) {
            parts.push(imprintEntity);
            parts.push(baseEntity);

            this.OnObject3DRescaled(p_entity, baseEntity, imprintEntity, parts);
            
            let transfromUpdates = new TransformUpdates({ position: undefined, forward: undefined, scale: imprintEntity.Transform.GetObject().scale.clone() });
            this.m_notifier.NotifyPartTransformChanged(p_objectComponent.Ref, this.m_imprintRefOfPart, transfromUpdates);
        }
    }

    public OnSnap(p_entity: SceneEntity, p_objectComponent: ObjectComponent, p_onSnapped: boolean): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_objectComponent == null) throw new Error('NullReferenceException : p_objectComponent is null or undefined');
        if (p_onSnapped == null) throw new Error('NullReferenceException : p_onSnapped is null or undefined');
        
        let baseEntity = this.GetEntity(this.m_baseRefOfPart, p_objectComponent);
        if (baseEntity) {
            if (p_onSnapped) {
                this.ApplyRenderOrderOnMesh(baseEntity, -100);
                this.m_notifier.NotifyMaterialChangedOnIds(p_objectComponent.Ref, this.m_baseRefOfPart, [this.m_glassMaterialID], [this.m_seethroughMaterialPath]);
            } else if (!p_onSnapped) {
                this.ApplyRenderOrderOnMesh(baseEntity, 0);
                this.m_notifier.NotifyMaterialChangedOnIds(p_objectComponent.Ref, this.m_baseRefOfPart, [this.m_glassMaterialID], [this.m_transparentMaterialPath]);
            }
        }
    }

    private ApplyRenderOrderOnMesh(p_baseEntity: SceneEntity, p_renderOrder: number): void {
        if (p_baseEntity.HasComponentOfType(MeshFilterComponent)) {
            let meshFilter = p_baseEntity.GetComponentOfType(MeshFilterComponent);
            let meshes = meshFilter.GetMeshes();
            let matIdByMesh = meshFilter.GetMaterialIdsByMesh();
            meshes.forEach((mesh) => {
                if (matIdByMesh.get(mesh.uuid) === this.m_windowMaterialID) {
                    mesh.renderOrder = p_renderOrder + this.m_windowMeshRenderOrder;
                } else {
                    mesh.renderOrder = p_renderOrder;
                }
            });
        } 
    }

    private GetEntity(p_refOfPart: string, p_objectComponent: ObjectComponent): SceneEntity | undefined {
        let entity: SceneEntity | undefined = undefined;
        let guid = p_objectComponent.GetEntityIdOfPart(p_refOfPart);
        if (guid) {
            entity = this.m_engine.Modules.Scene.GetEntityByID(guid);
        }
        return entity;
    }

    private GetBaseThickness(p_baseEntity: SceneEntity): number {
        let thickness: number = 0;
        if (p_baseEntity.HasComponentOfType(MeshFilterComponent)) {
            let meshFilter = p_baseEntity.GetComponentOfType(MeshFilterComponent);
            let meshes = meshFilter.GetMeshes();
            if (meshes.length > 0) {
                let worldScale = MeshUtils.GetGeometryWorldScale(meshes[0]);
                thickness = worldScale.y;
            }
        }
        return thickness;
    }

    private OnObject3DRescaled(p_openingEntity: SceneEntity, 
                               p_baseEntity: SceneEntity, 
                               p_imprintEntity: SceneEntity, 
                               p_parts: SceneEntity[]): void {
        
        let baseScale = p_baseEntity.Transform.GetObject().scale;
        p_imprintEntity.Transform.GetObject().scale.set(baseScale.x, p_imprintEntity.Transform.GetObject().scale.y, baseScale.x);

        //if (p_pivot) this.RefreshPivot(p_openingEntity, p_pivot, p_parts);
        Object3DUtils.SetPivotAtObjectBBCenter(p_openingEntity.GetComponentOfType(BoundingBoxComponent).Center, p_openingEntity.Transform.GetObject());
        this.ReplaceImprintOnBase(p_baseEntity, p_imprintEntity);

        if (p_openingEntity.HasComponentOfType(BoundingBoxComponent)) {
            let bb = p_openingEntity.GetComponentOfType(BoundingBoxComponent);
            bb.ComputeBoundingBox(p_openingEntity.Transform.GetObject());
            this.m_engine.Modules.EventManager.Publish(BoundingBoxChangedEvent, new BoundingBoxChangedEvent(p_openingEntity));
        }
    }

    private ReplaceImprintOnBase(p_baseEntity: SceneEntity, p_imprintEntity: SceneEntity): void {
        p_imprintEntity.Transform.GetObject().position.z = p_baseEntity.Transform.GetObject().position.z + this.GetBaseThickness(p_baseEntity);
    }

    private RefreshPivot(p_entity: SceneEntity, p_pivot: Vector3, p_parts: SceneEntity[]): void {
        let offset = Object3DUtils.GetPivotOffset(p_entity.Transform.GetObject(), p_pivot);
        if(Math.sign(p_entity.Transform.GetObject().scale.x) < 0)
            offset.x = -offset.x;
        p_parts.forEach((value, key) => {
            value.Transform.GetObject().position.copy(offset);
        });
        p_entity.Transform.GetObject().visible = true;
    }
}
 