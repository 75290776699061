import { IHandler } from '@lutithree/build/Modules/Core/Event/IHandler';
import { SnappableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SnappableComponent";
import ARoomStudioHandler from "../../../ARoomStudioHandler";
import EntityUnsnappedEvent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Snap/Events/EntityUnsnappedEvent";
import EntitySnappedEvent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Snap/Events/EntitySnappedEvent";
import InfoComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/InfoComponent";
import ObjectComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";


export class ObjectSnappedHandler extends ARoomStudioHandler implements IHandler<EntitySnappedEvent>, IHandler<EntityUnsnappedEvent> {
    public Handle(p_event: EntitySnappedEvent | EntityUnsnappedEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');

        if (p_event.Entity.HasComponentOfType(ObjectComponent) && p_event.Entity.HasComponentOfType(InfoComponent)) {
            let objectComponent = p_event.Entity.GetComponentOfType(ObjectComponent);
            let infoComponent = p_event.Entity.GetComponentOfType(InfoComponent);
            
            // Openings behaviours
            if (infoComponent.Info.Type === 'Opening') this.HandleOpeningSnapped(p_event, objectComponent);
        }
      
    }

    private HandleOpeningSnapped(p_event: EntitySnappedEvent | EntityUnsnappedEvent, p_objectComponent: ObjectComponent): void {
        let snapComponent = p_event.Entity.GetComponentOfType(SnappableComponent);
        if (p_event instanceof EntitySnappedEvent) {
            if(snapComponent.Snapped.length === 1){
                this.m_services.ObjectBehaviours.Openings.OnSnap(p_event.Entity, p_objectComponent, true);
            }
        } else if (p_event instanceof EntityUnsnappedEvent) {
            if(snapComponent.Snapped.length === 0) {
                this.m_services.ObjectBehaviours.Openings.OnSnap(p_event.Entity, p_objectComponent, false);
            }
        }
    }
}
 