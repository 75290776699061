import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import {Event, Group, Material, MeshLambertMaterial, Object3D} from 'three';
import { MaterialLoader } from '@lutithree/build/Modules/WebGL/Resources/Load/MaterialLoader';
import EntityMeshController from "../../EntityMeshController";
import Asset3D from "../../../../../Domain/Objects/Assets/Asset3D";
import {IAssetDecorator} from "../../../../../Domain/Objects/AssetAssembly/IAssetDecorator";
import {TexturesLoadingError} from "@lutithree/build/Modules/WebGL/Resources/Load/TexturesLoadingError";

export class MaterialDecorator implements IAssetDecorator {
    private m_defaultMaterial: Material;

    private readonly m_entityMeshController: EntityMeshController;
    
    public constructor(p_defaultMaterial: Material) {
        if (p_defaultMaterial == null) throw new Error('NullReferenceException : p_defaultMaterial is null or undefined');

        this.m_defaultMaterial = p_defaultMaterial;
        this.m_entityMeshController = new EntityMeshController();
    }

    public DecorateAsset(p_entity: SceneEntity, p_asset: Asset3D, p_levelLoadedCallback: (p_resource: Material | Material[] | Group | Object3D<Event>) => void): Promise<void> {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_asset == null) throw new Error('NullReferenceException : p_asset is null or undefined');
        if (p_levelLoadedCallback == null) throw new Error('NullReferenceException : p_levelLoadedCallback is null or undefined');

        return new Promise<void>((resolve, reject) => {
            if (p_asset.Datas === 'default') {
                this.m_entityMeshController.GetOrAddMeshRenderer(p_entity).Material = this.m_defaultMaterial;
                resolve();
            }
            else {
                let addNewLevel = (p_material: { material: Material; path: string | undefined }, p_level: number) => this.AddNewLevel(p_entity, p_material, p_level, p_levelLoadedCallback);
                let materialAsset: MaterialLoader = new MaterialLoader(p_asset.Datas as string[]);
                materialAsset.AddOnNewLevelLoadedCallback(addNewLevel);
                materialAsset.LoadAsync().then((material) => {
                    resolve();
                }).catch((reason)=>{
                    if(reason instanceof TexturesLoadingError){
                        let material = reason.Material as MeshLambertMaterial;
                        reason.Textures.forEach((url,slot)=>{
                            // @ts-ignore
                            material[slot]?.dispose();
                            // @ts-ignore
                            material[slot] = null;
                            material.needsUpdate = true;
                        });
                        this.m_entityMeshController.GetOrAddMeshRenderer(p_entity).Material = material;
                    }
                    reject(reason);
                });
            }
        });
    }
    
    private AddNewLevel(p_entity: SceneEntity, 
                        p_material: { material: Material; path: string | undefined }, 
                        p_level: number, 
                        p_levelLoadedCallback: (p_resource: Material | Material[] | Group | Object3D<Event>) => void)
        : void {
        
        let actualMaterial: Material | Material[] | Map<string, Material>| undefined = this.m_entityMeshController.GetOrAddMeshRenderer(p_entity).Material;
        if (actualMaterial !== undefined) {
            if(actualMaterial instanceof Map) p_levelLoadedCallback(Array.from(actualMaterial.values()));
            else p_levelLoadedCallback(actualMaterial);
        }
        this.m_entityMeshController.GetOrAddMeshRenderer(p_entity).Material = p_material.material;
    }
}
