export default class ResourceErrorData {

    protected readonly m_brokenUrl: string;
    
    public constructor(p_url: string){
        if (p_url == null) throw new Error('NullReferenceException : p_url is null or undefined or empty');
        this.m_brokenUrl = p_url;
    }

    public get BrokenUrl() : string {
        return this.m_brokenUrl;
    }
}