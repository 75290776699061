import { ViewModeFilter } from '../Components/ViewModeFilter';
import { ASystem } from '@lutithree/build/Modules/Core/Entity/ASystem';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { ViewModeCameraComponent } from '../Components/ViewModeCameraComponent';
import {ViewMode} from "../../../../Domain/Cameras/ViewMode";

export class ViewModeSystem extends ASystem<SceneEntity> {
    public GetCurrentViewMode(): ViewMode | undefined {
        let cameras = this.m_entityManager.GetComponents(ViewModeCameraComponent);
        let mainCamera = cameras.find((camera) => camera.IsMain);
        if (mainCamera) {
            return mainCamera.ViewMode;
        } else {
            return undefined;
        }
    }

    public GetCamerasByViewMode(p_viewMode: ViewMode): ViewModeCameraComponent[] {
        return this.m_entityManager.GetComponents(ViewModeCameraComponent).filter((camera) => {
            return camera.ViewMode === p_viewMode;
        });
    }

    public EnableEntitesForViewMode(p_viewMode: ViewMode): void {
        let viewModeFilters = this.m_entityManager.GetComponents(ViewModeFilter, { entity: false, component: true });
        viewModeFilters.forEach((filter) => {
            let enable = filter.IsAvailableForViewMode(p_viewMode);
            let entity = this.m_entityManager.GetEntityByID(filter.EntityID);
            if (enable != entity.IsEnabled) entity.Enable(enable);
        });
    }

    public SetViewMode(p_viewMode: ViewMode): ViewModeCameraComponent[] {
        let validCameras = this.m_entityManager.GetComponents(ViewModeCameraComponent).filter((camera) => {
            camera.IsMain = false;
            return camera.ViewMode === p_viewMode;
        });
        if (validCameras.length > 0) {
            validCameras[0].IsMain = true;
        }

        return validCameras;
    }
}