import ConfigurateurVendeur from '../../planner/configurateur/configurateur-vendeur';
import rightArrow from '../../../asset/fleche droite XL bleu.svg';
import leftArrow from '../../../asset/fleche gauche XL bleu.svg';
import {
    Asset,
    BasketProduct,
    Colori,
    ConfigurationItemV2,
    ConfigurationResponseV2,
    MappingDTO,
    MdfSetV2,
    ModelTree,
    PartTree,
    PresetTree,
    ProjectProduct as Product,
    ZoneDTO,
} from '../../../domain/domain';
import './edit-panel.scss';

import { useEffect, useState } from 'react';
import { SuggestionConfigurationV2State } from '../../../services/redux/reducers/configurateur-v2/configurateur-v2.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../services/redux/root-reducers';
import PopupInconsistentChoice from '../../../ui-components/modal/popup-inconsistent-choice/popup-inconsistent-choice';
import configuredProductService from '../../../services/redux/reducers/products/configured-product/configured-product.service';
import { getConfiguredProductTreeList } from '../../../services/redux/reducers/products/configured-product/configured-product.reducers';
import { RoomStudioState } from '../../../application-3d/application3D-seller/Redux/Reducers/room-studio/RoomStudioReducer';
import { setRefOfInstance } from '../../../services/redux/reducers/basket-configuration/basket-configuration.slice';

type EditPanelProps = {
    openEditPanel: boolean;
    setOpenEditPanel: (openEditPanel: boolean) => void;
    openPanel: boolean;
    setOpenPanel: (openEditPanel: boolean) => void;
    handleAssetUpdate: (atom: ModelTree) => void;
    product: Product;
    basketProduct?: BasketProduct;
    setProduct: (product: any | undefined) => void;
    setConfigurationWithAtom: () => void;
    saveConfiguration: () => void;
    onMappingClick: (sectionSelected: PartTree, mapping: MappingDTO) => void;
    onColoriClick: (sectionSelected: PartTree, mapping: MappingDTO, zone: ZoneDTO, C: Colori) => void;
    onZoneClick: (sectionSelected: PartTree, mapping: MappingDTO, zone: ZoneDTO) => void;
};

const EditPanel = (props: EditPanelProps) => {
    const dispatch = useDispatch();
    const [openPopup, setOpenPopup] = useState(false);
    const [possibleConfiguration, setPossibleConfiguration] = useState<MdfSetV2[]>([]);
    const [configuration, setConfiguration] = useState<ConfigurationItemV2[]>([]);
    const [atomRef, setAtomRef] = useState('');
    const [suggestedConfiguration, setSuggestedConfiguration] = useState<ConfigurationResponseV2>();
    const [presetToEdit, setPresetToEdit] = useState<PresetTree | undefined>(undefined);

    const { payload } = useSelector<RootState, SuggestionConfigurationV2State>((state) => state.suggestionConfiguration);
    const { editedDatas } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);

    const closePopup = () => {
        setOpenPopup(false);
    };

    useEffect(() => {
        getPreset();
        editedDatas && dispatch(setRefOfInstance(editedDatas.FirstRootObject?.RefOfInstance));
        // dispatch(getSuggestionConfiguration(atomRef));
    }, []);

    useEffect(() => {
        if (suggestedConfiguration !== undefined) {
            setPossibleConfiguration(suggestedConfiguration.referentialSelectionnableSets);
            setConfiguration(suggestedConfiguration.configuration.items);

            let asset: Asset[] = [
                {
                    urls: [
                        'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/materials/mat_IronGrey_LD.gltf',
                        'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/materials/mat_IronGrey_HD.gltf',
                    ],
                    refOfPart: 'setTableConceptPlateau',
                    section: 'Plateau',
                    type: 'Material',
                    options: [],
                },
                {
                    urls: [
                        'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/materials/mat_IronGrey_LD.gltf',
                        'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/materials/mat_IronGrey_HD.gltf',
                    ],
                    refOfPart: 'setTableConceptPied',
                    section: 'Pied',
                    type: 'Material',
                    options: [],
                },
            ];

            const section = (setRef: string) => {
                if (setRef === 'setTableConceptPlateau') {
                    return 'Plateau';
                }
                if (setRef === 'setTableConceptPied') {
                    return 'Pied';
                }
                if (setRef === 'treeTableConceptMaterial') {
                    return 'Plateau';
                } else return '';
            };
            for (let i = 0; i < suggestedConfiguration.configuration.items.length; i++) {
                let obj: Asset = {
                    refOfPart: '',
                    type: '',
                    section: '',
                    urls: [],
                    options: [],
                };
                if (suggestedConfiguration.configuration.items[i].models[0].type === 'MODEL') {
                    obj.refOfPart = suggestedConfiguration.configuration.items[i].setRef;
                    obj.type = 'Model';
                    obj.section = section(suggestedConfiguration.configuration.items[i].setRef);
                    obj.urls = suggestedConfiguration.configuration.items[i].models[0].urlList;
                    obj.options = suggestedConfiguration.configuration.items[i].models[0].options;
                    asset.push(obj);
                }
            }
        }
    }, [suggestedConfiguration]);

    useEffect(() => {
        if (payload.content !== undefined && payload.errors.length === 0) {
            // TOUT EST BON
            setSuggestedConfiguration(payload.content);
        } else {
            // GESTION D'ERREUR
        }
    }, [payload]);

    const fakeTableImage = 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/1651846340369-table-de-repas-extensible-eclipse.jpeg';

    const configurationSuggestion = () => {
        // dispatch(getSuggestionConfiguration(atomRef));

        closePopup();
    };

    const onQuitConfig = () => {
        // props.setOpenPanel(false);

        props.setOpenEditPanel(false);
    };

    const getPreset = () => {
        const customFocusedObjects = editedDatas?.FirstRootObject as any;
        customFocusedObjects &&
            configuredProductService.getConfiguredProductTreeList(customFocusedObjects.refOfProduct).then((res) => {
                if (res && res.content) {
                    // @ts-ignore
                    setPresetToEdit(res.content[0]);
                    customFocusedObjects && dispatch(getConfiguredProductTreeList({ coreProductReference: customFocusedObjects.refOfProduct }));
                }
            });
    };

    return (
        <div className="edit-panel-main">
            {openPopup && <PopupInconsistentChoice actionOnclick={configurationSuggestion} closeOnclick={closePopup} />}

            <div className="panel-arrow" onClick={onQuitConfig}>
                {props.openPanel ? <img alt="fleche gauche" src={rightArrow} /> : <img alt="fleche droite" src={leftArrow} />}
            </div>

            {props.openPanel && props.basketProduct && (
                <ConfigurateurVendeur
                    setConfigurationWithAtom={() => {
                        setOpenPopup(!openPopup);
                    }}
                    handleAssetUpdate={props.handleAssetUpdate}
                    setAtomRef={setAtomRef}
                    basketProduct={props.basketProduct}
                    openPanel={props.openEditPanel}
                    currentProduct={props.product}
                    saveConfiguration={props.saveConfiguration}
                    setConfiguration={setConfiguration}
                    setPossibleConfiguration={setPossibleConfiguration}
                    configuration={configuration}
                    possibleConfiguration={possibleConfiguration}
                    onColoriClick={props.onColoriClick}
                    onMappingClick={props.onMappingClick}
                    onZoneClick={props.onZoneClick}
                    preset={presetToEdit}
                />
            )}
        </div>
    );
};
export default EditPanel;
