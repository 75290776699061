import AState from './AState';
import { IReaction } from './Reaction';
import FSM from './FSM';
import State from './State';
import IEvent from './IEvent';
import Transition from './Transition';

export default abstract class AFSMBuilder {
    private m_result: FSM;

    private m_behaviors: Map<string, Array<AState>>;

    private m_states: Array<State>;

    protected abstract SetInitialState(): void;
    protected abstract AddStatesBehaviours(): void;
    protected abstract CreateTransitions(): void;

    public constructor() {
        this.m_result = new FSM();
        this.m_states = new Array<State>();
        this.m_behaviors = new Map<string, Array<AState>>();
    }

    public Build(): FSM {
        this.AddStatesBehaviours();
        this.CreateStates();
        this.CreateTransitions();
        this.SetInitialState();
        return this.m_result;
    }

    public Reset(): void {}

    private CreateStates(): void {
        this.m_behaviors.forEach((value, key) => {
            // create enter callback
            let enter: () => void = () => {
                value.forEach((behaviour) => {
                    behaviour.Enter();
                });
            };

            // create exit callback
            let exit: () => void = () => {
                value.forEach((behaviour) => {
                    behaviour.Exit();
                });
            };

            // create reactions
            let reactions = this.CreateReactions(value);

            // create state
            let state: State = new State(key, enter, exit, reactions);
            this.m_states.push(state);
            this.m_result.AddState(state);
        });
    }

    protected CreateReactions(p_stateBehaviours: AState[]): Map<{ new (...args: any[]): IEvent }, Array<IReaction>> {
        let reactionsMap = new Map<{ new (...args: any[]): IEvent }, Array<IReaction>>();

        p_stateBehaviours.forEach((behaviours) => {
            behaviours.ReactionsByEvent().forEach((reactions, eventType) => {
                if (!reactionsMap.has(eventType)) {
                    reactionsMap.set(eventType, new Array<IReaction>());
                }
                reactionsMap.set(eventType, reactionsMap.get(eventType)!.concat(reactions));
            });
        });
        return reactionsMap;
    }

    protected SetInitialStateByName(p_stateName: string): void {
        if (!p_stateName) throw new Error('NullReferenceException : p_stateName is null or undefined or empty');
        let initialState: State | undefined = this.m_states.find((state) => state.Name === p_stateName);
        if (!initialState) throw new Error('FSM Builder Error : p_stateName does not exist in state list ' + p_stateName);
        this.m_result.InitialState = initialState;
    }

    protected AddBehaviours(p_stateName: string, p_behaviours: AState): void {
        if (!p_stateName) throw new Error('NullReferenceException : p_stateName is null or undefined or empty');
        if (p_behaviours == null) throw new Error('NullReferenceException : p_behaviours is null or undefined');

        if (!this.m_behaviors.has(p_stateName)) {
            this.m_behaviors.set(p_stateName, new Array<AState>());
        }

        let behavioursOfState = this.m_behaviors.get(p_stateName)!;
        if (behavioursOfState.includes(p_behaviours)) throw new Error('FSM Builder Error : p_behaviours already in map!');
        behavioursOfState.push(p_behaviours);
    }

    protected CreateSimpleTransition(p_fromName: string, p_toName: string): void {
        if (!p_fromName) throw new Error('NullReferenceException : p_fromName is null or undefined');
        if (!p_toName) throw new Error('NullReferenceException : p_toName is null or undefined');

        let from: State | undefined = this.m_states.find((state) => state.Name === p_fromName);
        let to: State | undefined = this.m_states.find((state) => state.Name === p_toName);

        if (!(from && to)) throw new Error('FSM Builder Error : p_fromName or p_toName does not exist in state list from:' + from + '/to:' + to);
        let transition: Transition = new Transition(from, to);
        this.m_result.AddTransition(transition);
    }
}
