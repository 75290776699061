const DataValidators = {
    validatePassword: (password: string) => {
        let specialCharacters = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~€';
        let CAPSCharacter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let characterArray = specialCharacters.split('');
        let capsChararacterArray = CAPSCharacter.split('');
        let checkSpecialCharacters = characterArray.some((el) => {
            let index = password.indexOf(el);
            return index >= 0;
        });
        let checkCapsCharacters = capsChararacterArray.some((el) => {
            let index = password.indexOf(el);
            return index >= 0;
        });

        return checkSpecialCharacters && checkCapsCharacters && password.length >= 8;
    },

    validateEmail: (email: string) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
};

export default DataValidators;
