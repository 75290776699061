import { Object3D } from 'three';
import { CameraSystem } from '@lutithree/build/Modules/WebGL/Scene/Systems/CameraSystem';
import { Engine } from '@lutithree/build/Engine';
import AService from "../../../../Application3D/Domain/AService";

export default class ProductCameraService extends AService {

    private m_cameraSystem: CameraSystem;
    
    public constructor(p_engine: Engine) {
        super(p_engine);

        this.m_cameraSystem = new CameraSystem(p_engine.Modules.Scene);
    }
    
    public FocusPerspectiveCameraOnObjects(p_objects: Object3D[], p_fitRatio: number = 0.9): void {
        if (p_objects == null) throw new Error('NullReferenceException : p_objects is null or undefined');
        this.m_cameraSystem.FocusMainCameraOnObject(p_objects, p_fitRatio);
    }
}
