import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import { IEvent } from "@lutithree/build/Modules/Core/Event/IEvent";
import { Engine } from "@lutithree/build/Engine";
import { EntityRaycastedEvent } from "@lutithree/build/Modules/WebGL/Scene/Events/EntityRaycastedEvent";
import { EntityInitialRaycastEvent } from "@lutithree/build/Modules/WebGL/Scene/Events/EntityInitialRaycastEvent";
import { EntityRemovedEvent } from "@lutithree/build/Modules/WebGL/Scene/Events/EntityRemovedEvent";
import { NewFrameEvent } from "@lutithree/build/Modules/Core/GameLoop/Events/NewFrameEvent";
import { LastFrameEvent } from "@lutithree/build/Modules/Core/GameLoop/Events/LastFrameEvent";
import { CanvasDomHandler } from "@lutithree/build/Handlers/CanvasDomHandler";
import { FrameHandler } from "@lutithree/build/Handlers/FrameHandler";
import { InteractionUpdateEvent } from "@lutithree/build/Modules/Core/Inputs/Events/InteractionUpdateEvent";
import { BeforeRenderingEvent } from "@lutithree/build/Modules/WebGL/Rendering/Events/BeforeRenderingEvent";
import { CameraHandler } from "@lutithree/build/Handlers/CameraHandler";
import { ResourcesHandler } from "@lutithree/build/Handlers/ResourcesHandler";
import { CameraChangedEvent } from "@lutithree/build/Modules/WebGL/Rendering/Events/CameraChangedEvent";
import { RemoveResouceEvent } from "@lutithree/build/Modules/WebGL/Resources/Events/RemoveResouceEvent";
import { WindowResizeEvent } from "@lutithree/build/Modules/WebGL/Rendering/Events/WindowResizeEvent";
import { WindowResizeHandler } from "@lutithree/build/Handlers/WindowResizeHandler";
import { InteractionEndEvent } from "@lutithree/build/Modules/Core/Inputs/Events/InteractionEndEvent";
import { CanvasDomChangedEvent } from "@lutithree/build/Modules/WebGL/Rendering/Events/CanvasDomChangedEvent";
import { RenderHandler } from "@lutithree/build/Handlers/RenderHandler";
import { ScreenInputHandler } from "@lutithree/build/Handlers/ScreenInputHandler";
import { InteractionStartEvent } from "@lutithree/build/Modules/Core/Inputs/Events/InteractionStartEvent";
import ProductStudioServices from "./ProductStudioService";
import ObjectEditedEvent from "../../Application3D/GameLogic/Objects/AssetAssembly/Events/ObjectEditedEvent";
import ScreenshotHandler from "../../Application3D/GameLogic/Features3D/Handlers/ScreenshotHandler";
import EntityRemovedHandler from "../../Application3D/GameLogic/Features3D/Handlers/EntityRemovedHandler";
import { ObjectEditedHandler } from "../../Application3D/GameLogic/Objects/AssetAssembly/Handlers/ObjectEditedHandler";
import { EnableScreenshotContextEvent } from "../../Application3D/GameLogic/Features3D/Screenshot/Events/EnableScreenshotContextEvent";
import EntityConnectorClickedEvent from "../../Application3D/GameLogic/Objects/AssetAssembly/Events/EntityConnectorClickedEvent";
import SelectionHandler from "../../Application3D/GameLogic/Features3D/Handlers/SelectionHandler";
import { ObjectConnectorsHandler } from "../../Application3D/GameLogic/Objects/AssetAssembly/Handlers/ObjectConnectorsHandler";
import EntityConnectorsChangeEvent from "../../Application3D/GameLogic/Features3D/Connectors/Events/EntityConnectorsChangeEvent";
import EntitySelectionStatusDirtyEvent from "../../Application3D/GameLogic/Features3D/Selection/Events/EntitySelectionStatusDirtyEvent";
import EntityHooksChangeEvent from "../../Application3D/GameLogic/Features3D/Hooks/Events/EntityHooksChangeEvent";
import { ObjectSelectionEffectsHandler } from "./Objects/Handlers/ObjectSelectionEffectsHandler";
import { ObjectHooksHandler } from "../../Application3D/GameLogic/Objects/AssetAssembly/Handlers/ObjectHooksHandler";
import { ConnectorSelectionHandler } from "./Objects/Handlers/ConnectorSelectionHandler";
import { RenderMode } from "@lutithree/build/Modules/WebGL/Rendering/RenderingStrategies/RenderMode";
import {LoadingFailHandler} from "../../Application3D/GameLogic/Objects/AssetAssembly/Handlers/LoadingFailHandler";
import ResourceLoadingFailEvent from "../../Application3D/GameLogic/Objects/AssetAssembly/Events/ResourceLoadingFailEvent";
import ObjectGeometryChangedEvent
    from "../../Application3D/GameLogic/Objects/AssetAssembly/Events/ObjectGeometryChangedEvent";
import {ObjectGeometryChangedHandler} from "./Objects/Handlers/ObjectGeometryChangedHandler";

export default class ProductStudioHandlers {

    private readonly m_engine: Engine;

    private readonly m_services: ProductStudioServices;

    private readonly m_handlers:  Map<string, IHandler<IEvent>>;

    public constructor(p_engine: Engine, p_services: ProductStudioServices) {
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');
        if (p_services == null) throw new Error('NullReferenceException : p_services is null or undefined');

        this.m_engine = p_engine;
        this.m_services = p_services;
        this.m_handlers = new Map<string, IHandler<IEvent>>();
        this.BuildHandlersMap(this.m_engine);
    }

    public get Handlers(): ReadonlyMap<string, IHandler<IEvent>>{
        return this.m_handlers;
    }

    public InitEventManagerWithHandlers(): void {
        let domHandler = this.m_handlers.get('domHandler');
        if(domHandler){
            this.m_engine.Modules.EventManager.Suscribe(CanvasDomChangedEvent, domHandler);
        } else console.warn('There is no domHandler key in RoomStudioMapHandlers');

        let screenInputHandler = this.m_handlers.get('screenInputHandler');
        if(screenInputHandler){
            this.m_engine.Modules.EventManager.Suscribe(InteractionStartEvent, screenInputHandler);
            this.m_engine.Modules.EventManager.Suscribe(InteractionEndEvent, screenInputHandler);
            this.m_engine.Modules.EventManager.Suscribe(InteractionUpdateEvent, screenInputHandler);
        } else console.warn('There is no screenInputHandler key in RoomStudioMapHandlers');

        let cameraHandler = this.m_handlers.get('cameraHandler');
        if(cameraHandler){
            this.m_engine.Modules.EventManager.Suscribe(CameraChangedEvent, cameraHandler);
        } else console.warn('There is no cameraHandler key in RoomStudioMapHandlers');

        let resizeHandler = this.m_handlers.get('resizeHandler');
        if(resizeHandler){
            this.m_engine.Modules.EventManager.Suscribe(WindowResizeEvent, resizeHandler);
        } else console.warn('There is no resizeHandler key in RoomStudioMapHandlers');

        let resourcesRemovedHandler = this.m_handlers.get('resourcesRemovedHandler');
        if(resourcesRemovedHandler){
            this.m_engine.Modules.EventManager.Suscribe(RemoveResouceEvent, resourcesRemovedHandler);
        } else console.warn('There is no resourcesRemovedHandler key in RoomStudioMapHandlers');

        let renderHandler = this.m_handlers.get('renderHandler');
        if(renderHandler){
            this.m_engine.Modules.EventManager.Suscribe(BeforeRenderingEvent, renderHandler);
        } else console.warn('There is no renderHandler key in RoomStudioMapHandlers');

        let frameHandler = this.m_handlers.get('frameHandler');
        if(frameHandler){
            this.m_engine.Modules.EventManager.Suscribe(NewFrameEvent, frameHandler);
            this.m_engine.Modules.EventManager.Suscribe(LastFrameEvent, frameHandler);
        } else console.warn('There is no frameHandler key in RoomStudioMapHandlers');

        // FEATURE3D Handlers --------------------------------------------
        let raycastedHandler = this.m_handlers.get('raycastedHandler');
        if(raycastedHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntityRaycastedEvent, raycastedHandler);
            this.m_engine.Modules.EventManager.Suscribe(EntityInitialRaycastEvent, raycastedHandler);
        } else console.warn('There is no raycastedHandler key in RoomStudioMapHandlers');

        let selectionHandler = this.m_handlers.get('selectionHandler');
        if(selectionHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntitySelectionStatusDirtyEvent, selectionHandler);
        } else console.warn('There is no selectionHandler key in RoomStudioMapHandlers');
        
        let screenshotHandler = this.m_handlers.get('screenshotHandler');
        if(screenshotHandler){
            this.m_engine.Modules.EventManager.Suscribe(EnableScreenshotContextEvent, screenshotHandler);
        } else console.warn('There is no screenshotHandler key in RoomStudioMapHandlers');

        let entityRemovedHandler = this.m_handlers.get('entityRemovedHandler');
        if(entityRemovedHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntityRemovedEvent, entityRemovedHandler);
        } else console.warn('There is no entityRemovedHandler key in RoomStudioMapHandlers');

        let objectgeometryChangedHandler = this.m_handlers.get('objectgeometryChangedHandler');
        if (objectgeometryChangedHandler) {
            this.m_engine.Modules.EventManager.Suscribe(ObjectGeometryChangedEvent, objectgeometryChangedHandler);
        } else console.warn('There is no objectgeometryChangedHandler key in RoomStudioMapHandlers');
        
        // ROOM OBJECTS Handlers -----------------------------------------------
        let hookHandler = this.m_handlers.get('hookHandler');
        if(hookHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntityHooksChangeEvent, hookHandler);
        } else console.warn('There is no hookHandler key in RoomStudioMapHandlers');

        let connectorsHandler = this.m_handlers.get('connectorsHandler');
        if(connectorsHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntityConnectorsChangeEvent, connectorsHandler);
        } else console.warn('There is no connectorsHandler key in RoomStudioMapHandlers');

        let selectedConnectorHandler = this.m_handlers.get('selectedConnectorHandler');
        if(selectedConnectorHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntityConnectorClickedEvent, selectedConnectorHandler);
        } else console.warn('There is no selectedConnectorHandler key in RoomStudioMapHandlers');

        let objectEditedHandler = this.m_handlers.get('objectEditedHandler');
        if(objectEditedHandler){
            this.m_engine.Modules.EventManager.Suscribe(ObjectEditedEvent, objectEditedHandler);
        } else console.warn('There is no objectEditedHandler key in RoomStudioMapHandlers');
        
        let assetLoadingFailHandler = this.m_handlers.get('assetLoadingFailHandler');
        if (assetLoadingFailHandler) {
            this.m_engine.Modules.EventManager.Suscribe(ResourceLoadingFailEvent, assetLoadingFailHandler);
        } else console.warn('There is no assetLoadingFailHandler key in RoomStudioMapHandlers');
    }

    private BuildHandlersMap(p_engine: Engine): void {

        // ENGINE Handlers --------------------------------------------
        let domHandler = new CanvasDomHandler(p_engine);
        this.m_handlers.set('domHandler',domHandler);
        let screenInputHandler = new ScreenInputHandler(p_engine);
        this.m_handlers.set('screenInputHandler',screenInputHandler);
        let cameraHandler = new CameraHandler(p_engine);
        this.m_handlers.set('cameraHandler',cameraHandler);
        let resizeHandler = new WindowResizeHandler(p_engine);
        this.m_handlers.set('resizeHandler',resizeHandler);
        let resourcesRemovedHandler = new ResourcesHandler(p_engine);
        this.m_handlers.set('resourcesRemovedHandler',resourcesRemovedHandler);
        let renderHandler = new RenderHandler(p_engine);
        this.m_handlers.set('renderHandler',renderHandler);
        let frameHandler = new FrameHandler(p_engine, RenderMode.Default, RenderMode.HighQuality);
        this.m_handlers.set('frameHandler',frameHandler);

        // FEATURE3D Handlers --------------------------------------------
        let raycastedHandler = new SelectionHandler(p_engine, this.m_services.Selection);
        this.m_handlers.set('raycastedHandler',raycastedHandler);
        let selectionHandler = new ObjectSelectionEffectsHandler(p_engine, this.m_services);
        this.m_handlers.set('selectionHandler',selectionHandler);
        let screenshotHandler = new ScreenshotHandler(p_engine, this.m_services.Selection);
        this.m_handlers.set('screenshotHandler',screenshotHandler);
        let entityRemovedHandler = new EntityRemovedHandler(p_engine, this.m_services.Selection);
        this.m_handlers.set('entityRemovedHandler',entityRemovedHandler);
        let assetLoadingFailHandler = new LoadingFailHandler(p_engine, this.m_services.Notifier);
        this.m_handlers.set('assetLoadingFailHandler', assetLoadingFailHandler);

        let objectgeometryChangedHandler = new ObjectGeometryChangedHandler(p_engine, this.m_services.SelectionEffects, this.m_services.Behaviour);
        this.m_handlers.set('objectgeometryChangedHandler', objectgeometryChangedHandler);
        
        // ROOM OBJECTS Handlers -----------------------------------------------
        let hookHandler = new ObjectHooksHandler(p_engine);
        this.m_handlers.set('hookHandler',hookHandler);
        let connectorsHandler = new ObjectConnectorsHandler(p_engine);
        this.m_handlers.set('connectorsHandler',connectorsHandler);
        let selectedConnectorHandler = new ConnectorSelectionHandler(p_engine, this.m_services);
        this.m_handlers.set('selectedConnectorHandler',selectedConnectorHandler);
        let objectEditedHandler = new ObjectEditedHandler(p_engine, this.m_services.Selection, this.m_services.Cameras);
        this.m_handlers.set('objectEditedHandler',objectEditedHandler);
    }
}