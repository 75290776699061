import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { AEntityEvent } from "@lutithree/build/Modules/Core/Entity/Events/AEntityEvent";
import ObjectComponent from '../../Components/ObjectComponent';


export default class AObjectEvent extends AEntityEvent<SceneEntity> {
    private m_objectComponent: ObjectComponent;
    
    public constructor(p_entity: SceneEntity, p_objectComponent: ObjectComponent) {
        super(p_entity);
        if (p_objectComponent == null) throw new Error('NullReferenceException : p_objectComponent is null or undefined');

        this.m_objectComponent = p_objectComponent;
    }

    public get ObjectComponent(): ObjectComponent {
        return this.m_objectComponent;
    }
}
