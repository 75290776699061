import { useState } from 'react';
import arrowLeft from '../../../../asset/arrowLeft.svg';

import InformationPanelBody from './information-panel-body';
import './information-panel.scss';

type InfoPanelProps = {
    setOpen: (open: boolean) => void;
    setOpenInfoPanel: (openInfoPanel: boolean) => void;
};

const InformationPanel = ({ setOpen, setOpenInfoPanel }: InfoPanelProps) => {
    const [openPdf, setOpenPdf] = useState<boolean>(false);
    return (
        <div className='info-panel-main'>
            <div className='info-panel-close'>
                <div className='info-panel-title'>Table concept</div>
                <div onClick={() => {
                    setOpenInfoPanel(false);
                    setOpen(false);
                }} className='info-panel-back'>
                    <img alt='retour' src={arrowLeft} />EN SAVOIR PLUS
                </div>
            </div>

            <InformationPanelBody />

            <div className='info-panel-footer'>
                <button onClick={() => setOpenPdf(true)}> Voir la fiche technique</button>
            </div>

        </div>
    );
};
export default InformationPanel;