import IStatisticsService from './IStatisticsService';
import PolygoneStatisticsTool from '../../../PolygoneStatistics';
import PolyStatsTool from '../../../PolygoneStatistics';
import { WebGLRenderer } from 'three';
import React from 'react';

export default class PolygoneService implements IStatisticsService {
    m_tool: PolygoneStatisticsTool | null | undefined;

    constructor(p_tool: Promise<React.RefObject<PolyStatsTool>>) {
        if (p_tool == null) throw new Error('NullReferenceException : p_tool is null or undefined or empty');
        p_tool.then((p_arg) => {
            this.m_tool = p_arg.current;
        });
    }

    Update(p_renderer: WebGLRenderer): void {
        if (!p_renderer) {
            console.warn('m_renderer is null or undefined or empty');
            return;
        }
        this.m_tool?.Update(p_renderer);
    }
}
