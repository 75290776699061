import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, styled, Button, Paper, Container } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';


export type ContainerPaperProps = {
    title?: string;
    children: React.ReactNode;
    backArrow?: boolean;
    path?: string
};

const ContainerPaperLayout = ({ children, title, backArrow, path }: ContainerPaperProps) => {
    const navigate = useNavigate();

    return (
        <StyledContainer>
            <StyledBox>
                { backArrow ? (
                    <>
                        <CustomNavButton onClick={ () => path ? navigate(path) : navigate(-1) }>
                            <KeyboardArrowLeft sx={ { fontSize: 45 } } />
                            <Typography variant='h2' color='primary'>{ title }</Typography>
                        </CustomNavButton>
                    </>
                ) : <Typography variant='h2' color='primary'>{ title }</Typography> }
            </StyledBox>
            <Paper elevation={ 0 }
                   sx={ {
                       p: 4,
                       minHeight: 600,
                       height: 'auto',
                       overflowY: 'auto',
                   } }>
                { children }
            </Paper>
        </StyledContainer>
    );
};

const CustomNavButton = styled(Button)(() => ({
        padding: '1px 10px 1px 0',
        textTransform: 'none',
    }
));

const StyledContainer = styled(Container)(({ theme }) => ({
    width: '90%',
    maxWidth: '1800px',
    marginTop: '130px',
    [theme.breakpoints.down('lg')]: {
        width: '95%',
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
}));

const StyledBox = styled(Box)(({ theme }) => ({
        marginBottom: 24,
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.main,
    }
));

export default ContainerPaperLayout;