import { useSelector } from 'react-redux';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import {getRoomObjectFromList} from "../../../application3D-common/Utils/get-object";
import ObjectComponent
    from "../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";
import {RootState} from "../../../../services/redux/root-reducers";
import {RoomStudioState} from "../../Redux/Reducers/room-studio/RoomStudioReducer";
import {RoomContentState} from "../../Redux/Reducers/room-studio/RoomContentReducer";

export function useRefOfInstance(p_entity: SceneEntity | undefined) {
    let refOfInstance = undefined;
    if (p_entity) {
        if (p_entity.HasComponentOfType(ObjectComponent)) {
            let roomObjectComponent = p_entity.GetComponentOfType(ObjectComponent);
            refOfInstance = roomObjectComponent.Ref;
        }
    }
    return refOfInstance;
}

export function useObjectInfo(p_refOfInstance: string | undefined) {
}

export function useInstance(p_refOfInstance: string | undefined) {
    const { roomStudioApp } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const { instances } = useSelector<RootState, RoomContentState>((state) => state.roomContent);

    let instance = undefined;
    if (roomStudioApp && p_refOfInstance !== undefined) {
        let instancesCopy = [...instances];
        for (let i = 0; i < instancesCopy.length; i++) {
            if (instancesCopy[i].Ref === p_refOfInstance) {
                instance = instancesCopy[i];
            }
        }
    }
    return instance;
}

export function useRoomObject(p_refOfInstance: string | undefined) {
    const { roomStudioApp } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const { plannerObjects } = useSelector<RootState, RoomContentState>((state) => state.roomContent);
    
    let roomObject = undefined;
    if (roomStudioApp && p_refOfInstance !== undefined) {
        let objectsCopy = [...plannerObjects];
        roomObject = getRoomObjectFromList(p_refOfInstance, objectsCopy);
    }
    return roomObject;
}

export function useIsRootObject(p_refOfInstance: string | undefined) {
    const { roomStudioApp } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const { plannerObjects } = useSelector<RootState, RoomContentState>((state) => state.roomContent);
    let result = false;
    if (roomStudioApp && p_refOfInstance !== undefined) {
        let objectsCopy = [...plannerObjects];
        result =  objectsCopy.some(x => x.RefOfInstance === p_refOfInstance);
    }
    return result;
}
