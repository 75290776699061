import Transform from "@lutithree/build/Modules/WebGL/Scene/DataModel/Transform";

export default class PartTransformData {
    protected refOfPart: string;

    protected transform: Transform;

    public constructor() {
        this.refOfPart = '';
        this.transform = new Transform();
    }
    
    public get RefOfPart(): string {
        return this.refOfPart;
    }

    public set RefOfPart(p_refOfPart: string) {
        this.refOfPart = p_refOfPart;
    }

    public get Transform(): Transform {
        return this.transform;
    }

    public set Transform(p_transform: Transform) {
        this.transform = p_transform;
    }
}