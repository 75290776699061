import { IDisposableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/IDisposableComponent";
import { DisableComponent } from "@lutithree/build/Modules/Core/Entity/DisableComponent";
import { BufferGeometry, DoubleSide, Group, Material, Object3D, Texture, Vector3, WebGLRenderTarget } from "three";
import { Line } from "@lutithree/build/Modules/WebGL/Primitives/Graphics/Line";
import { Guid } from "guid-typescript";
import { StickerBillboard } from "@lutithree/build/Modules/WebGL/Primitives/Graphics/StickerBillboard";

export enum Dimension {
    DEPTH,
    HEIGHT,
    LENGTH
}

export class DimensionsDisplayerComponent extends DisableComponent implements IDisposableComponent {
    private m_targetEntityGUID: Guid;

    private readonly m_relativeDisplayerObject: Object3D;

    private m_distanceMap: Map<Dimension, { p_line: Line, p_sticker: StickerBillboard }> = new Map<Dimension, { p_line: Line, p_sticker: StickerBillboard }>();

    private m_color: string = '#1D2740';

    constructor(p_targetEntityGUID: Guid) {
        super();
        if (p_targetEntityGUID == null) throw new Error('NullReferenceException : p_targetEntityGUID is null or undefined');

        this.m_targetEntityGUID = p_targetEntityGUID;

        let depthData   = { p_line: new Line(new Vector3(), new Vector3(), this.m_color), p_sticker: new StickerBillboard("0", this.m_color, new Vector3(), new Vector3()) };
        let lengthData  = { p_line: new Line(new Vector3(), new Vector3(), this.m_color), p_sticker: new StickerBillboard("0", this.m_color, new Vector3(), new Vector3()) };
        let heightData  = { p_line: new Line(new Vector3(), new Vector3(), this.m_color), p_sticker: new StickerBillboard("0", this.m_color, new Vector3(), new Vector3()) };
        
        if(!Array.isArray(depthData.p_line.ThreeLine.material)) depthData.p_line.ThreeLine.material.side = DoubleSide;
        if(!Array.isArray(lengthData.p_line.ThreeLine.material)) lengthData.p_line.ThreeLine.material.side = DoubleSide;
        if(!Array.isArray(heightData.p_line.ThreeLine.material)) heightData.p_line.ThreeLine.material.side = DoubleSide;
        
        this.m_distanceMap.set(Dimension.DEPTH, depthData);
        this.m_distanceMap.set(Dimension.HEIGHT, heightData);
        this.m_distanceMap.set(Dimension.LENGTH, lengthData);

        this.m_relativeDisplayerObject = new Group();
        this.m_relativeDisplayerObject.name = "DimensionsDisplayer_object3D";
        this.m_relativeDisplayerObject.add(heightData.p_line.ThreeLine, heightData.p_sticker.m_stickerObject);
        this.m_relativeDisplayerObject.add(depthData.p_line.ThreeLine, depthData.p_sticker.m_stickerObject);
        this.m_relativeDisplayerObject.add(lengthData.p_line.ThreeLine, lengthData.p_sticker.m_stickerObject);
    }

    public get TargetEntityID(): Guid {
        return this.m_targetEntityGUID;
    }

    public GetObject() {
        return this.m_relativeDisplayerObject;
    }

    public UpdateDistancesInfos(p_dimensions: Vector3) {
        if (p_dimensions == null) throw new Error('NullReferenceException : p_relativeInfos is null or undefined');

        this.m_distanceMap.get(Dimension.HEIGHT)?.p_sticker.UpdateText(p_dimensions.y.toString() + " cm");
        this.m_distanceMap.get(Dimension.DEPTH)?.p_sticker.UpdateText(p_dimensions.z.toString() + " cm");
        this.m_distanceMap.get(Dimension.LENGTH)?.p_sticker.UpdateText(p_dimensions.x.toString() + " cm");
    }

    public UpdateDisplayer(p_dimension: Dimension, p_dimensionLine: {p_from: Vector3, p_to: Vector3}) {
        this.m_distanceMap.get(p_dimension)?.p_sticker.MoveSticker(p_dimensionLine.p_from.clone().sub(p_dimensionLine.p_to).multiplyScalar(0.5).add(p_dimensionLine.p_to), new Vector3());
        (this.m_distanceMap.get(p_dimension)?.p_line.Geometry as any).setPoints([p_dimensionLine.p_from, p_dimensionLine.p_to]);
    }
    
    public GetDisposable(): (Material | BufferGeometry | Texture | WebGLRenderTarget | Object3D)[] {
        return [this.m_relativeDisplayerObject];
    }
}