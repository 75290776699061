import React, { ReactElement, useEffect, useState } from 'react';
import DeleteButton from './DeleteButton';
import EditToogle from './EditToogle';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { useSelector } from 'react-redux';
import ScaleToggle from "../../../../../application3D-seller/Components/UI/Composites/EntityMenu/ScaleToggle";
import PaintToogle from "../../../../../application3D-seller/Components/UI/Composites/EntityMenu/PaintToogle";
import InvertButton from "../../../../../application3D-seller/Components/UI/Composites/EntityMenu/InvertButton";
import MoveToogle from "../../../../../application3D-seller/Components/UI/Composites/EntityMenu/MoveToogle";
import ActionComponent
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ActionComponent";
import {
    ObjectAction
} from "../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ObjectAction";
import ReplaceToogle from "../../../../../application3D-common/Components/UI/Composites/EntityMenu/ReplaceToogle";
import DuplicateButton from "../../../../../application3D-common/Components/UI/Composites/EntityMenu/DuplicateButton";
import InfoToogle from "../../../../../application3D-common/Components/UI/Composites/EntityMenu/InfoToogle";
import ContextualMenu from "../../../../../application3D-common/Components/UI/Basics/ContextualMenu";
import {RootState} from "../../../../../../services/redux/root-reducers";
import {RoomStudioState} from "../../../../Redux/Reducers/room-studio/RoomStudioReducer";


export type EntityMenuProps = {
    setOpenEditPanel: (openEditPanel: boolean) => void;
    setOpenPanel: (openPanel: boolean) => void;
    openPanel: boolean;
    currentEntity: SceneEntity
};

const EntityMenu = (props: EntityMenuProps) => {
    const [menuTitle, setTitle] = useState('');
    const [header, setHeader] = useState<ReactElement>(<></>);
    const [body, setBody] = useState<ReactElement[]>([]);
    const { selectedObject } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);

    useEffect(() => {
        if (props.currentEntity.HasComponentOfType(ActionComponent)) {
            let actionsComponent = props.currentEntity.GetComponentOfType(ActionComponent);

            // Set Header
            if (actionsComponent.HasAction(ObjectAction.Delete)) setHeader(<DeleteButton
                entity={ props.currentEntity } />);
            else setHeader(<></>);

            // Set Body
            let i: number = 0;
            let bodyElements: ReactElement[] = [];

            if (actionsComponent.HasAction(ObjectAction.Scale)) {
                bodyElements.push(
                    <ScaleToggle
                        openPanel={ props.openPanel }
                        setOpenPanel={ props.setOpenPanel }
                        key={ i }
                        entity={ props.currentEntity } />);
                i += 1;
            }
            if (actionsComponent.HasAction(ObjectAction.Paint)) {
                bodyElements.push(<PaintToogle entity={ props.currentEntity } key={ i } />);
                i += 1;
            }
            if (selectedObject && selectedObject.Informations && !selectedObject.Informations.HasBehaviour('Configurable')) {
            } else {
                if (actionsComponent.HasAction(ObjectAction.Edit)) {
                    bodyElements.push(<EditToogle
                        setOpenEditPanel={ props.setOpenEditPanel }
                        key={ i }
                        entity={ props.currentEntity } />);
                    i += 1;
                }
            }
            if (selectedObject && selectedObject.Informations && !selectedObject.Informations.HasBehaviour('Inversible')) {
            } else {
                if (actionsComponent.HasAction(ObjectAction.Invert)) {
                    bodyElements.push(<InvertButton entity={ props.currentEntity } key={ i } />);
                    i += 1;
                }
            }
            if (actionsComponent.HasAction(ObjectAction.Replace)) {
                bodyElements.push(<ReplaceToogle key={ i } />);
                i += 1;
            }
            if (actionsComponent.HasAction(ObjectAction.Duplicate)) {
                bodyElements.push(<DuplicateButton key={ i } />);
                i += 1;
            }
            if (actionsComponent.HasAction(ObjectAction.Move)) {
                bodyElements.push(<MoveToogle key={ i } />);
                i += 1;
            }
            if (actionsComponent.HasAction(ObjectAction.GetInformations)) {
                bodyElements.push(<InfoToogle key={ i } />);
                i += 1;
            }
            setBody(bodyElements);
            let title = actionsComponent.Name;
            setTitle(title);
        }

    }, [props.currentEntity, selectedObject]);

    return <ContextualMenu title={ menuTitle } headerItem={ header } bodyItems={ body } />;
};

export default EntityMenu;
