import { DisableComponent } from '@lutithree/build/Modules/Core/Entity/DisableComponent';
import { ViewMode } from '../../../../Domain/Cameras/ViewMode';

export class ViewModeFilter extends DisableComponent {
    private readonly m_viewModes: ViewMode[];

    public constructor(p_viewModes: ViewMode[]) {
        super();
        if (p_viewModes == null) throw new Error('NullReferenceException : p_viewMode is null or undefined or empty');

        this.m_viewModes = p_viewModes;
    }

    public IsAvailableForViewMode(p_viewMode: ViewMode): boolean {
        return this.m_viewModes.includes(p_viewMode);
    }
}
