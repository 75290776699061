import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import moveOn from '../../../../../application3D-common/Assets/Icons/Common/Planner_selectionmultiple-on.svg';
import moveOff from '../../../../../application3D-common/Assets/Icons/Common/Planner_selectionmultiple-off.svg';
import {ViewMode} from "../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Cameras/ViewMode";
import ToogleIcon from "../../../../../application3D-common/Components/UI/Basics/ToogleIcon";
import {RoomStudioState, updateViewMode} from "../../../../Redux/Reducers/room-studio/RoomStudioReducer";
import {RootState} from "../../../../../../services/redux/root-reducers";


export type MoveToogleProps = {};

const MoveToogle = (props: MoveToogleProps) => {
    const dispatch = useDispatch();
    const { currentViewMode } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const [isOn, setIsOn] = useState<boolean>();

    const onChange = () => {
        setIsOn(!isOn);
    };

    useEffect(() => {
        if (isOn != undefined) {
            if (isOn) dispatch(updateViewMode(ViewMode.OrtographicTop));
            else dispatch(updateViewMode(ViewMode.ThirdPerson));
        }
    }, [isOn]);

    useEffect(() => {
        if (currentViewMode != undefined) {
            if (currentViewMode.toString() === ViewMode.OrtographicTop.toString()) {
                setIsOn(true);
            } else {
                setIsOn(false);
            }
        }
    }, [currentViewMode]);

    return <ToogleIcon imgOn={ moveOn } imgOff={ moveOff } label='Déplacer' active={ isOn }
                       onChange={ onChange } />;
};

export default MoveToogle;
