import TimerTool from './Tools/TimerTool';
import IDebugStrategy from './ServicesManager/IServicesManager';
import {WebGLRenderer} from 'three';
import DebugReactComponent from "../DebugReactComponent";

/* eslint-disable */
export enum LogLevel {
    DEFAULT,
    WARNING,
    ERROR,
    INFO,
}
/* eslint-enable */

export default class Debug {
    static get LocalInstance(): Debug {
        if (this.localInstance == null) {
            this.localInstance = new Debug();
        }
        return this.localInstance;
    }

    private m_timertool = new TimerTool();

    private static m_debugStrategyWarningDispatched: boolean = false;

    private m_debugStrategy: IDebugStrategy | null = null;

    private static localInstance: Debug;

    private static m_debugTool: DebugReactComponent;

    constructor() {
        let oldlog = console.log;
        console.log = (txt) => {
            oldlog(txt);
            Debug.Log(txt, LogLevel.DEFAULT);
        };
        let oldWarnlog = console.log;
        console.log = (txt) => {
            oldWarnlog(txt);
            Debug.Log(txt, LogLevel.DEFAULT);
        };
        let oldErrorLog = console.error;
        console.log = (txt) => {
            oldErrorLog(txt);
            Debug.Log(txt, LogLevel.ERROR);
        };
    }

    static SetDebugComponent(p_component: DebugReactComponent) {
        this.m_debugTool = p_component;
    }

    static SetDebugStrategy(debugStrategy: IDebugStrategy) {
        if (debugStrategy == null) throw new Error('NullReferenceException : debugStrategy is null or undefined or empty');
        Debug.LocalInstance.m_debugStrategy = debugStrategy;
    }

    static EnableToolConsoling() {
        Debug.m_debugTool.setState({
            useConsole: true,
        });
        return Debug.m_debugTool.ConsolePromise;
    }

    static EnablePolyStats() {
        Debug.m_debugTool.setState({
            usePolyStats: true,
        });
        return Debug.m_debugTool.PolyStatPromise;
    }

    static EnablePerfStats() {
        Debug.m_debugTool.setState({
            usePerfStats: true,
        });
        return Debug.m_debugTool.PerfToolPromise;
    }

    static Log(p_logMessage: string, p_level: LogLevel): void {
        if (!this.localInstance.m_debugStrategy) {
            this.DispatchErrorMessage();
            return;
        }
        this.localInstance.m_debugStrategy.Log(p_logMessage, p_level);
    }

    static Update(p_renderer: WebGLRenderer): void {
        if (!this.localInstance) return;
        if (!this.localInstance.m_debugStrategy) {
            this.DispatchErrorMessage();
            return;
        }
        this.localInstance.m_debugStrategy.Update(p_renderer);
    }

    static SpyValue<T>(p_name: string, p_value: T): void {
        if (!this.localInstance.m_debugStrategy) {
            this.DispatchErrorMessage();
            return;
        }
        this.localInstance.m_debugStrategy.SpyValue(p_name, p_value);
    }

    static GetTimerTool() {
        return this.LocalInstance?.m_timertool;
    }

    private static DispatchErrorMessage() {
        if (!this.m_debugStrategyWarningDispatched) {
            console.warn('m_debugStrategy is null or undefined or empty');
            this.m_debugStrategyWarningDispatched = true;
        }
    }
}
