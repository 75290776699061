import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import { IEvent } from "@lutithree/build/Modules/Core/Event/IEvent";
import { Engine } from "@lutithree/build/Engine";
import { EntityRaycastedEvent } from "@lutithree/build/Modules/WebGL/Scene/Events/EntityRaycastedEvent";
import { EntityUpdateDragEvent } from "@lutithree/build/Modules/WebGL/Scene/Events/EntityUpdateDragEvent";
import { EntityBeginDragEvent } from "@lutithree/build/Modules/WebGL/Scene/Events/EntityBeginDragEvent";
import { EntityEndDragEvent } from "@lutithree/build/Modules/WebGL/Scene/Events/EntityEndDragEvent";
import { EntityRemovedEvent } from "@lutithree/build/Modules/WebGL/Scene/Events/EntityRemovedEvent";
import { NewFrameEvent } from "@lutithree/build/Modules/Core/GameLoop/Events/NewFrameEvent";
import { LastFrameEvent } from "@lutithree/build/Modules/Core/GameLoop/Events/LastFrameEvent";
import { CanvasDomHandler } from "@lutithree/build/Handlers/CanvasDomHandler";
import { FrameHandler } from "@lutithree/build/Handlers/FrameHandler";
import { InteractionUpdateEvent } from "@lutithree/build/Modules/Core/Inputs/Events/InteractionUpdateEvent";
import { BeforeRenderingEvent } from "@lutithree/build/Modules/WebGL/Rendering/Events/BeforeRenderingEvent";
import { CameraHandler } from "@lutithree/build/Handlers/CameraHandler";
import { ResourcesHandler } from "@lutithree/build/Handlers/ResourcesHandler";
import { CameraChangedEvent } from "@lutithree/build/Modules/WebGL/Rendering/Events/CameraChangedEvent";
import { RemoveResouceEvent } from "@lutithree/build/Modules/WebGL/Resources/Events/RemoveResouceEvent";
import { WindowResizeEvent } from "@lutithree/build/Modules/WebGL/Rendering/Events/WindowResizeEvent";
import { WindowResizeHandler } from "@lutithree/build/Handlers/WindowResizeHandler";
import { InteractionEndEvent } from "@lutithree/build/Modules/Core/Inputs/Events/InteractionEndEvent";
import { CanvasDomChangedEvent } from "@lutithree/build/Modules/WebGL/Rendering/Events/CanvasDomChangedEvent";
import { RenderHandler } from "@lutithree/build/Handlers/RenderHandler";
import { ScreenInputHandler } from "@lutithree/build/Handlers/ScreenInputHandler";
import { InteractionStartEvent } from "@lutithree/build/Modules/Core/Inputs/Events/InteractionStartEvent";
import { ObjectScaledHandler } from "./Objects/Handlers/ObjectScaledHandler";
import RendererUpdateHandler from "./Studio/Rendering/Handlers/RendererUpdateHandler";
import ObjectAddedToRoomEvent from "./Objects/Events/ObjectAddedToRoomEvent";
import RoomStudioServices from "./RoomStudioServices";
import PartScaledEvent from "./Objects/Events/PartScaledEvent";
import { ObjectAddedToRoomHandler } from "./Objects/Handlers/ObjectAddedToRoomHandler";
import { WallMoveHandler } from "./Objects/Handlers/WallMoveHandler";
import { ObjectLoadedInConfiguringHandler } from "./Objects/Handlers/ObjectLoadedInConfiguringHandler";
import ObjectScaledEvent from "./Objects/Events/ObjectScaledEvent";
import { ObjectBBChangedHandler } from "./Objects/Handlers/ObjectBBChangedHandler";
import StructureInstanciatedEvent from "./Objects/Events/StructureInstanciatedEvent";
import ObjectPOVChangedHandler from "./Objects/Handlers/ObjectPOVChangedHandler";
import { ObjectSnappedHandler } from "./Objects/Handlers/ObjectSnappedHandler";
import { ObjectTransformChangedHandler } from "./Objects/Handlers/ObjectTransformChangedHandler";
import { ObjectLoadedInFurnishingHandler } from "./Objects/Handlers/ObjectLoadedInFurnishingHandler";
import ObjectRaycastedHandler from "./Objects/Handlers/ObjectRaycastedHandler";
import { SnapSupportHandler } from "./Objects/Handlers/SnapSupportHandler";
import { ObjectSelectionEffectsHandler } from "./Objects/Handlers/ObjectSelectionEffectsHandler";
import { ConnectorSelectionHandler } from "./Objects/Handlers/ConnectorSelectionHandler";
import { DoubleTapEvent } from "@lutithree/build/Modules/Core/Inputs/Events/DoubleTapEvent";
import { ObjectMultiselectionEffectsHandler } from "./Objects/Handlers/ObjectMultiselectionEffectsHandler";
import { MultiSelectionEndMoveHandler } from "./Objects/Handlers/MultiSelectionEndMoveHandler";
import { MesureMarkerHandler } from "./Handlers/MesureMarkerHandler";
import { RenderMode } from "@lutithree/build/Modules/WebGL/Rendering/RenderingStrategies/RenderMode";
import { EntityInitialRaycastEvent } from "@lutithree/build/Modules/WebGL/Scene/Events/EntityInitialRaycastEvent";
import EntitySelectionStatusDirtyEvent from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Selection/Events/EntitySelectionStatusDirtyEvent";
import TranslationOnPlaneChangeEvent from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Events/TranslationOnPlaneChangeEvent";
import EntityTranslatedEvent
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Events/EntityTranslatedEvent";
import EntityScaledEvent
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Events/EntityScaledEvent";
import {
    EnableScreenshotContextEvent
} from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Screenshot/Events/EnableScreenshotContextEvent";
import {
    ViewModeChangeEvent
} from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Cameras/Events/ViewModeChangeEvent";
import EntityStopMovingEvent
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Events/EntityStopMovingEvent";
import EntityTranslationEndedEvent
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Events/EntityTranslationEndedEvent";
import ObjectGeometryChangedEvent
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/Events/ObjectGeometryChangedEvent";
import ConnectionsTranslatedEvent
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Events/ConnectionsTranslatedEvent";
import BoundingBoxChangedEvent
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/RelativePositioning/Events/BoundingBoxChangedEvent";
import {
    EntityPOVChangedEvent
} from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Cameras/Events/EntityPOVChangedEvent";
import EntitySnappedEvent
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Snap/Events/EntitySnappedEvent";
import EntityUnsnappedEvent
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Snap/Events/EntityUnsnappedEvent";
import EntityHooksChangeEvent
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Hooks/Events/EntityHooksChangeEvent";
import EntityConnectorsChangeEvent
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Connectors/Events/EntityConnectorsChangeEvent";
import EntityConnectorClickedEvent
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/Events/EntityConnectorClickedEvent";
import ObjectEditedEvent
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/Events/ObjectEditedEvent";
import ObjectLoadedEvent
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/Events/ObjectLoadedEvent";
import NBMeasureMarkerChangedEvent
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Measuring/Events/NBMeasureMarkerChangedEvent";
import ResourceLoadingFailEvent
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/Events/ResourceLoadingFailEvent";
import SelectionHandler
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Handlers/SelectionHandler";
import {
    TranslationHandler
} from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Handlers/TranslationHandler";
import {
    ResizeHandler
} from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Handlers/ResizeHandler";
import ScreenshotHandler
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Handlers/ScreenshotHandler";
import EntityRemovedHandler
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Handlers/EntityRemovedHandler";
import {
    DoubleTapHandler
} from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Handlers/DoubleTapHandler";
import {
    ObjectHooksHandler
} from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/Handlers/ObjectHooksHandler";
import {
    ObjectConnectorsHandler
} from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/Handlers/ObjectConnectorsHandler";
import {
    ObjectEditedHandler
} from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/Handlers/ObjectEditedHandler";
import MeasuresHandler
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Handlers/MeasuresHandler";
import NewFrameHandler
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Handlers/NewFrameHandler";
import {
    LoadingFailHandler
} from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/Handlers/LoadingFailHandler";
import ViewModeHandler from "./Studio/Cameras/Handlers/ViewModeHandler";
import {
    ObjectGeometryChangedHandler
} from "../../../../../application3D-common/Librairies/Studios/ProductStudio/GameLogic/Objects/Handlers/ObjectGeometryChangedHandler";


export default class RoomStudioHandlers {

    private readonly m_engine: Engine;
    
    private readonly m_services: RoomStudioServices;

    private readonly m_handlers:  Map<string, IHandler<IEvent>>;
    
    public constructor(p_engine: Engine, p_services: RoomStudioServices) {
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');
        if (p_services == null) throw new Error('NullReferenceException : p_services is null or undefined');

        this.m_engine = p_engine;
        this.m_services = p_services;
        this.m_handlers = new Map<string, IHandler<IEvent>>();
        this.BuildHandlersMap(this.m_engine);
    }
    
    public get Handlers(): ReadonlyMap<string, IHandler<IEvent>>{
        return this.m_handlers;
    }

    public InitEventManagerWithHandlers(): void {
        let domHandler = this.m_handlers.get('domHandler');
        if(domHandler){
            this.m_engine.Modules.EventManager.Suscribe(CanvasDomChangedEvent, domHandler);
        } else console.warn('There is no domHandler key in RoomStudioMapHandlers');

        let screenInputHandler = this.m_handlers.get('screenInputHandler');
        if(screenInputHandler){
            this.m_engine.Modules.EventManager.Suscribe(InteractionStartEvent, screenInputHandler);
            this.m_engine.Modules.EventManager.Suscribe(InteractionEndEvent, screenInputHandler);
            this.m_engine.Modules.EventManager.Suscribe(InteractionUpdateEvent, screenInputHandler);
        } else console.warn('There is no screenInputHandler key in RoomStudioMapHandlers');

        let entityDoubleTapHandler = this.m_handlers.get('entityDoubleTapHandler');
        if (entityDoubleTapHandler) {
            this.m_engine.Modules.EventManager.Suscribe(DoubleTapEvent, entityDoubleTapHandler);
        } else console.warn('There is no entityDoubleRaycastHandler key in RoomStudioMapHandlers');
        
        let cameraHandler = this.m_handlers.get('cameraHandler');
        if(cameraHandler){
            this.m_engine.Modules.EventManager.Suscribe(CameraChangedEvent, cameraHandler);
        } else console.warn('There is no cameraHandler key in RoomStudioMapHandlers');

        let resizeHandler = this.m_handlers.get('resizeHandler');
        if(resizeHandler){
            this.m_engine.Modules.EventManager.Suscribe(WindowResizeEvent, resizeHandler);
        } else console.warn('There is no resizeHandler key in RoomStudioMapHandlers');

        let resourcesRemovedHandler = this.m_handlers.get('resourcesRemovedHandler');
        if(resourcesRemovedHandler){
            this.m_engine.Modules.EventManager.Suscribe(RemoveResouceEvent, resourcesRemovedHandler);
        } else console.warn('There is no resourcesRemovedHandler key in RoomStudioMapHandlers');

        let renderHandler = this.m_handlers.get('renderHandler');
        if(renderHandler){
            this.m_engine.Modules.EventManager.Suscribe(BeforeRenderingEvent, renderHandler);
        } else console.warn('There is no renderHandler key in RoomStudioMapHandlers');

        let optimisedFrameHandler = this.m_handlers.get('optimisedFrameHandler');
        if(optimisedFrameHandler){
            this.m_engine.Modules.EventManager.Suscribe(NewFrameEvent, optimisedFrameHandler);
            this.m_engine.Modules.EventManager.Suscribe(LastFrameEvent, optimisedFrameHandler);
        } else console.warn('There is no optimisedFrameHandler key in RoomStudioMapHandlers');
        
        // FEATURE3D Handlers --------------------------------------------
        let selectionHandler = this.m_handlers.get('selectionHandler');
        if(selectionHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntityRaycastedEvent, selectionHandler);
        } else console.warn('There is no selectionHandler key in RoomStudioMapHandlers');

        let selectionEffectsHandler = this.m_handlers.get('selectionEffectsHandler');
        if(selectionEffectsHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntitySelectionStatusDirtyEvent, selectionEffectsHandler);
        } else console.warn('There is no selectionHandler key in RoomStudioMapHandlers');

        let translationHandler = this.m_handlers.get('translationHandler');
        if(translationHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntityTranslatedEvent, translationHandler);
            this.m_engine.Modules.EventManager.Suscribe(TranslationOnPlaneChangeEvent, translationHandler);
            this.m_engine.Modules.EventManager.Suscribe(EntityUpdateDragEvent, translationHandler);
            this.m_engine.Modules.EventManager.Suscribe(EntityBeginDragEvent, translationHandler);
            this.m_engine.Modules.EventManager.Suscribe(EntityEndDragEvent, translationHandler);
            this.m_engine.Modules.EventManager.Suscribe(EntityInitialRaycastEvent, translationHandler);
        } else console.warn('There is no dragHandler key in RoomStudioMapHandlers');

        let scaleHandler = this.m_handlers.get('scaleHandler');
        if(scaleHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntityScaledEvent, scaleHandler);
        } else console.warn('There is no scaleHandler key in RoomStudioMapHandlers');

        let screenshotHandler = this.m_handlers.get('screenshotHandler');
        if(screenshotHandler){
            this.m_engine.Modules.EventManager.Suscribe(EnableScreenshotContextEvent, screenshotHandler);
        } else console.warn('There is no screenshotHandler key in RoomStudioMapHandlers');

        let entityRemovedHandler = this.m_handlers.get('entityRemovedHandler');
        if(entityRemovedHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntityRemovedEvent, entityRemovedHandler);
        } else console.warn('There is no entityRemovedHandler key in RoomStudioMapHandlers');

        // STUDIO Handlers --------------------------------------------------
        let viewModeHandler = this.m_handlers.get('viewModeHandler');
        if(viewModeHandler){
            this.m_engine.Modules.EventManager.Suscribe(ViewModeChangeEvent, viewModeHandler);
        } else console.warn('There is no viewModeHandler key in RoomStudioMapHandlers');

        let rendererUpdate = this.m_handlers.get('rendererUpdate');
        if(rendererUpdate){
            this.m_engine.Modules.EventManager.Suscribe(NewFrameEvent, rendererUpdate);
            this.m_engine.Modules.EventManager.Suscribe(LastFrameEvent, rendererUpdate);
        } else console.warn('There is no rendererUpdate key in RoomStudioMapHandlers');

        // ROOM OBJECTS Handlers -----------------------------------------------
        let objectScaledHandler = this.m_handlers.get('objectScaledHandler');
        if(objectScaledHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntityScaledEvent, objectScaledHandler);
        } else console.warn('There is no objectScaledHandler key in RoomStudioMapHandlers');

        let objectRaycastedHandler = this.m_handlers.get('objectRaycastedHandler');
        if(objectRaycastedHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntityRaycastedEvent, objectRaycastedHandler);
        } else console.warn('There is no objectRaycastedHandler key in RoomStudioMapHandlers');

        let objectTransformChangedHandler = this.m_handlers.get('objectTransformChangedHandler');
        if(objectTransformChangedHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntityTranslationEndedEvent, objectTransformChangedHandler);
            this.m_engine.Modules.EventManager.Suscribe(EntityStopMovingEvent, objectTransformChangedHandler);
            this.m_engine.Modules.EventManager.Suscribe(ObjectScaledEvent, objectTransformChangedHandler);
            this.m_engine.Modules.EventManager.Suscribe(PartScaledEvent, objectTransformChangedHandler);
        } else console.warn('There is no objectTransformChangedHandler key in RoomStudioMapHandlers');

        let snapSupportHandler = this.m_handlers.get('snapSupportHandler');
        if(snapSupportHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntityTranslationEndedEvent, snapSupportHandler);
            this.m_engine.Modules.EventManager.Suscribe(EntityStopMovingEvent, snapSupportHandler);
            this.m_engine.Modules.EventManager.Suscribe(ObjectScaledEvent, snapSupportHandler);
            this.m_engine.Modules.EventManager.Suscribe(ObjectGeometryChangedEvent, snapSupportHandler);
        } else console.warn('There is no snapSupportHandler key in RoomStudioMapHandlers');

        let objectPOVChangedHandler = this.m_handlers.get('objectPOVChangedHandler');
        if(objectPOVChangedHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntityPOVChangedEvent, objectPOVChangedHandler);
        } else console.warn('There is no objectPOVChangedHandler key in RoomStudioMapHandlers');

        let objectSnappedHandler = this.m_handlers.get('objectSnappedHandler');
        if(objectSnappedHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntitySnappedEvent, objectSnappedHandler);
            this.m_engine.Modules.EventManager.Suscribe(EntityUnsnappedEvent, objectSnappedHandler);
        } else console.warn('There is no objectSnappedHandler key in RoomStudioMapHandlers');

        let objectAwakedHandler = this.m_handlers.get('objectAwakedHandler');
        if(objectAwakedHandler){
            this.m_engine.Modules.EventManager.Suscribe(ObjectAddedToRoomEvent, objectAwakedHandler);
            this.m_engine.Modules.EventManager.Suscribe(StructureInstanciatedEvent, objectAwakedHandler);
        } else console.warn('There is no objectAwakedHandler key in RoomStudioMapHandlers');

        let objectgeometryChangedHandler = this.m_handlers.get('objectgeometryChangedHandler');
        if(objectgeometryChangedHandler){
            this.m_engine.Modules.EventManager.Suscribe(ObjectGeometryChangedEvent, objectgeometryChangedHandler);
        } else console.warn('There is no objectgeometryChangedHandler key in RoomStudioMapHandlers');

        let objectBBChangedHandler = this.m_handlers.get('objectBBChangedHandler');
        if(objectBBChangedHandler){
            this.m_engine.Modules.EventManager.Suscribe(BoundingBoxChangedEvent, objectBBChangedHandler);
        } else console.warn('There is no objectBBChangedHandler key in RoomStudioMapHandlers');

        let wallMoveHandler = this.m_handlers.get('wallMoveHandler');
        if(wallMoveHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntityTranslationEndedEvent, wallMoveHandler);
            this.m_engine.Modules.EventManager.Suscribe(ConnectionsTranslatedEvent, wallMoveHandler);
        } else console.warn('There is no wallMoveHandler key in RoomStudioMapHandlers');

        let hookHandler = this.m_handlers.get('hookHandler');
        if(hookHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntityHooksChangeEvent, hookHandler);
        } else console.warn('There is no hookHandler key in RoomStudioMapHandlers');

        let connectorsHandler = this.m_handlers.get('connectorsHandler');
        if(connectorsHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntityConnectorsChangeEvent, connectorsHandler);
        } else console.warn('There is no connectorsHandler key in RoomStudioMapHandlers');

        let selectedConnectorHandler = this.m_handlers.get('selectedConnectorHandler');
        if(selectedConnectorHandler){
            this.m_engine.Modules.EventManager.Suscribe(EntityConnectorClickedEvent, selectedConnectorHandler);
        } else console.warn('There is no selectedConnectorHandler key in RoomStudioMapHandlers');

        let objectEditedHandler = this.m_handlers.get('objectEditedHandler');
        if(objectEditedHandler){
            this.m_engine.Modules.EventManager.Suscribe(ObjectEditedEvent, objectEditedHandler);
        } else console.warn('There is no objectEditedHandler key in RoomStudioMapHandlers');

        let objectLoadedInFurnishingHandler = this.m_handlers.get('objectLoadedInFurnishingHandler');
        if(objectLoadedInFurnishingHandler){
            this.m_engine.Modules.EventManager.Suscribe(ObjectLoadedEvent, objectLoadedInFurnishingHandler);
        } else console.warn('There is no objectLoadedInFurnishingHandler key in RoomStudioMapHandlers');

        let mesureMarkerHandler = this.m_handlers.get('mesureMarkerHandler');
        if(mesureMarkerHandler){
            this.m_engine.Modules.EventManager.Suscribe(NBMeasureMarkerChangedEvent, mesureMarkerHandler);
        } else console.warn('There is no mesureMarkerHandler key in RoomStudioMapHandlers');

        let newFrameHandler = this.m_handlers.get('newFrameHandler');
        if (newFrameHandler) {
            this.m_engine.Modules.EventManager.Suscribe(NewFrameEvent, newFrameHandler);
            this.m_engine.Modules.EventManager.Suscribe(LastFrameEvent, newFrameHandler);
        } else console.warn('There is no newFrameHandler key in RoomStudioMapHandlers');

        let assetLoadingFailHandler = this.m_handlers.get('assetLoadingFailHandler');
        if (assetLoadingFailHandler) {
            this.m_engine.Modules.EventManager.Suscribe(ResourceLoadingFailEvent, assetLoadingFailHandler);
        } else console.warn('There is no assetLoadingFailHandler key in RoomStudioMapHandlers');
    }
    
    private BuildHandlersMap(p_engine: Engine): void {

        // ENGINE Handlers --------------------------------------------
        let domHandler = new CanvasDomHandler(p_engine);
        this.m_handlers.set('domHandler',domHandler);
        let screenInputHandler = new ScreenInputHandler(p_engine);
        this.m_handlers.set('screenInputHandler',screenInputHandler);
        let cameraHandler = new CameraHandler(p_engine);
        this.m_handlers.set('cameraHandler',cameraHandler);
        let resizeHandler = new WindowResizeHandler(p_engine);
        this.m_handlers.set('resizeHandler',resizeHandler);
        let resourcesRemovedHandler = new ResourcesHandler(p_engine);
        this.m_handlers.set('resourcesRemovedHandler',resourcesRemovedHandler);
        let renderHandler = new RenderHandler(p_engine);
        this.m_handlers.set('renderHandler',renderHandler);
        let optimisedFrameHandler = new FrameHandler(p_engine, RenderMode.Default, RenderMode.HighQuality);
        this.m_handlers.set('optimisedFrameHandler',optimisedFrameHandler);
        let regressedFrameHandler = new FrameHandler(p_engine, RenderMode.Default, RenderMode.Default);
        this.m_handlers.set('regressedFrameHandler',regressedFrameHandler);
        
        // FEATURE3D Handlers --------------------------------------------
        let selectionHandler = new SelectionHandler(p_engine, this.m_services.Selection);
        this.m_handlers.set('selectionHandler',selectionHandler);
        let multiSelectionEndMoveHandler = new MultiSelectionEndMoveHandler(p_engine, this.m_services);
        this.m_handlers.set('multiSelectionEndMoveHandler',multiSelectionEndMoveHandler);
        let multiSelectionEffectsHandler = new ObjectMultiselectionEffectsHandler(p_engine, this.m_services);
        this.m_handlers.set('multiSelectionEffectsHandler',multiSelectionEffectsHandler);
        let selectionEffectsHandler = new ObjectSelectionEffectsHandler(p_engine, this.m_services);
        this.m_handlers.set('selectionEffectsHandler',selectionEffectsHandler);
        let translationHandler = new TranslationHandler(p_engine, this.m_services.Translation, this.m_services.Snap, this.m_services.Cameras);
        this.m_handlers.set('translationHandler',translationHandler);
        let scaleHandler = new ResizeHandler(p_engine);
        this.m_handlers.set('scaleHandler',scaleHandler);
        let screenshotHandler = new ScreenshotHandler(p_engine, this.m_services.Selection);
        this.m_handlers.set('screenshotHandler',screenshotHandler);
        let entityRemovedHandler = new EntityRemovedHandler(p_engine, this.m_services.Selection);
        this.m_handlers.set('entityRemovedHandler',entityRemovedHandler);
        let entityDoubleTapHandler = new DoubleTapHandler(p_engine, this.m_services.Cameras);
        this.m_handlers.set('entityDoubleTapHandler', entityDoubleTapHandler);

        // STUDIO Handlers --------------------------------------------------
        let viewModeHandler = new ViewModeHandler(p_engine, this.m_services);
        this.m_handlers.set('viewModeHandler',viewModeHandler);
        let rendererUpdate = new RendererUpdateHandler(p_engine, this.m_services);
        this.m_handlers.set('rendererUpdate',rendererUpdate);
        
        // ROOM OBJECTS Handlers -----------------------------------------------
        let objectScaledHandler = new ObjectScaledHandler(p_engine, this.m_services);
        this.m_handlers.set('objectScaledHandler',objectScaledHandler);
        let objectRaycastedHandler = new ObjectRaycastedHandler(p_engine, this.m_services);
        this.m_handlers.set('objectRaycastedHandler',objectRaycastedHandler);
        let objectTransformChangedHandler = new ObjectTransformChangedHandler(p_engine, this.m_services);
        this.m_handlers.set('objectTransformChangedHandler',objectTransformChangedHandler);
        let snapSupportHandler = new SnapSupportHandler(p_engine, this.m_services);
        this.m_handlers.set('snapSupportHandler',snapSupportHandler);
        let objectPOVChangedHandler = new ObjectPOVChangedHandler(p_engine, this.m_services);
        this.m_handlers.set('objectPOVChangedHandler',objectPOVChangedHandler);
        let objectSnappedHandler = new ObjectSnappedHandler(p_engine, this.m_services);
        this.m_handlers.set('objectSnappedHandler',objectSnappedHandler);
        let objectAwakedHandler = new ObjectAddedToRoomHandler(p_engine, this.m_services);
        this.m_handlers.set('objectAwakedHandler',objectAwakedHandler);
        let objectgeometryChangedHandler = new ObjectGeometryChangedHandler(p_engine, this.m_services.SelectionEffects, this.m_services.ObjectBehaviours.BasicObjects);
        this.m_handlers.set('objectgeometryChangedHandler',objectgeometryChangedHandler);
        let objectBBChangedHandler = new ObjectBBChangedHandler(p_engine, this.m_services);
        this.m_handlers.set('objectBBChangedHandler',objectBBChangedHandler);
        let wallMoveHandler = new WallMoveHandler(p_engine, this.m_services);
        this.m_handlers.set('wallMoveHandler',wallMoveHandler);
        let hookHandler = new ObjectHooksHandler(p_engine);
        this.m_handlers.set('hookHandler',hookHandler);
        let connectorsHandler = new ObjectConnectorsHandler(p_engine);
        this.m_handlers.set('connectorsHandler',connectorsHandler);
        let selectedConnectorHandler = new ConnectorSelectionHandler(p_engine, this.m_services);
        this.m_handlers.set('selectedConnectorHandler',selectedConnectorHandler);
        let objectEditedHandler = new ObjectEditedHandler(p_engine, this.m_services.Selection, this.m_services.Cameras);
        this.m_handlers.set('objectEditedHandler',objectEditedHandler);
        let objectLoadedInFurnishingHandler = new ObjectLoadedInFurnishingHandler(p_engine, this.m_services);
        this.m_handlers.set('objectLoadedInFurnishingHandler',objectLoadedInFurnishingHandler);
        let objectLoadedInConfiguringHandler = new ObjectLoadedInConfiguringHandler(p_engine, this.m_services);
        this.m_handlers.set('objectLoadedInConfiguringHandler',objectLoadedInConfiguringHandler);
        let measureHandler = new MeasuresHandler(p_engine, this.m_services.MeasureDrawer);
        this.m_handlers.set('measureHandler',measureHandler);
        let mesureMarkerHandler = new MesureMarkerHandler(p_engine, this.m_services);
        this.m_handlers.set('mesureMarkerHandler',mesureMarkerHandler);
        let newFrameHandler = new NewFrameHandler(p_engine, this.m_services.Scale);
        this.m_handlers.set('newFrameHandler', newFrameHandler);
        let assetLoadingFailHandler = new LoadingFailHandler(p_engine, this.m_services.Notifier);
        this.m_handlers.set('assetLoadingFailHandler', assetLoadingFailHandler);
    }
}