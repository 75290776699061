import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import ARoomStudioHandler from "../../../ARoomStudioHandler";
import EntitySelectionStatusDirtyEvent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Selection/Events/EntitySelectionStatusDirtyEvent";

export class ObjectSelectionEffectsHandler extends ARoomStudioHandler implements IHandler<EntitySelectionStatusDirtyEvent> {
    
    public Handle(p_event: EntitySelectionStatusDirtyEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');
        
        let isSelected: boolean = true;
        if(!p_event.Selectable.IsSelected || !p_event.Selectable.IsEnabled) isSelected = false;

        let enable = p_event.Selectable.IsSelected && p_event.Selectable.IsSelectionVisible;
        if(!p_event.Selectable.IsEnabled){
            enable = false;
        }
        this.m_services.SelectionEffects.EnableOutline(p_event.Entity, enable);
        this.m_services.SelectionEffects.EnableYupGizmo(p_event.Entity, enable);
        this.m_services.SelectionEffects.EnableTranslationOnPlane(p_event.Entity, enable);
        this.m_services.SelectionEffects.EnableRotationGizmo(p_event.Entity, enable);
        this.m_services.SelectionEffects.EnableRelativePositioning(p_event.Entity, enable);
        
        this.HandleSelectionChange(p_event, isSelected);

        this.m_services.Notifier.NotifyEntityClicked(p_event.Entity.Id, isSelected);
    }

    private HandleSelectionChange(p_event: EntitySelectionStatusDirtyEvent, p_isSelected: boolean): void {
        this.m_services.ObjectBehaviours.BasicObjects.OnSelectionChange(p_event.Entity,p_isSelected);
    }
}