import {IEvent} from "@lutithree/build/Modules/Core/Event/IEvent";
import ResourceErrorData from "../../../../Domain/Objects/ResourceErrorData";

export default class ResourceLoadingFailEvent implements IEvent {
    
    private readonly m_resourceErrorData: ResourceErrorData;

    public constructor(p_resourceErrorData: ResourceErrorData){
        if (p_resourceErrorData == null) throw new Error('NullReferenceException : p_resourceErrorData is null or undefined');

        this.m_resourceErrorData = p_resourceErrorData;
    }

    public get ResourceErrorData() : ResourceErrorData {
        return this.m_resourceErrorData;
    }
}