import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { SelectableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SelectableComponent";
import { AObjectLoadedHandler } from "./AObjectLoadedHandler";
import ObjectLoadedEvent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/Events/ObjectLoadedEvent";
import PartComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/PartComponent";

export class ObjectLoadedInFurnishingHandler extends AObjectLoadedHandler implements IHandler<ObjectLoadedEvent> {
    public Handle(p_event: ObjectLoadedEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');
        
        this.LoadObjectAtFurnishing(p_event.Entity);
    }
    
    private LoadObjectAtFurnishing(p_entity:SceneEntity): void{
        this.LoadObjectCommonActions(p_entity);
        let selectable = p_entity.HasComponentOfType(SelectableComponent)?p_entity.GetComponentOfType(SelectableComponent):undefined;
        
        // Part object
        if(p_entity.HasComponentOfType(PartComponent)){
            if(selectable) selectable.Enable(false);
        }
        // Root object
        else{ 

        }
    }
}