import React, { PropsWithChildren } from "react";
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import mdfLogo from "../../../asset/top.svg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InfoBox from "../../InfoBox";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";


type LayoutContainerProps = PropsWithChildren<{
    title: string;
    info?: boolean;
}>;

const LayoutContainerLog = ({ children, title, info }: LayoutContainerProps) => {
    return (
        <StyledContainer maxWidth='sm'>
            <img
                style={ { width: 310, height: 72, marginBottom: '25px' } }
                src={ mdfLogo }
                alt='logo mdf' />
            <StyledBoxPaper>
                <Typography variant='h3' color='primary' sx={ { textAlign: 'center', fontSize: '23px' } }>
                    { title }
                </Typography>
                { info &&
                    <Box sx={ { mt: 3 } }>
                        <InfoBox
                            icon={ <InfoOutlinedIcon sx={ { fontSize: '2.7em', marginRight: '10px' } }
                                                     color='primary' /> }
                            content='Le mot de passe doit contenir au moins 8 caractères, dont un caractère spécial et une majuscule' />
                    </Box>
                }
                { children }
            </StyledBoxPaper>
        </StyledContainer>
    );
};

const StyledContainer = styled(Container)(() => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    left: 0,
    position: 'absolute',
    top: '7%',
    right: 0,
}));

const StyledBoxPaper = styled(Box)(() => ({
    width: '100%',
    background: '#FFFFFF',
    padding: '27px',
    border: '2px solid #E3E7ED',
    borderRadius: '5px',
}));

export default LayoutContainerLog;