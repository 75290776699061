import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
    CreateProjectsState,
    GetUserProjectByCode,
    getUserProjectByCode,
    resetCreateProjects,
    resetUserProjectByCode, UpdateProjectNameState,
} from '../../services/redux/reducers/projects/project.slice';
import {
    DeleteRoomState,
    DuplicateRoomState,
    resetDuplicateRoom,
    resetUpdateRoomName,
} from '../../services/redux/reducers/rooms/rooms.slice';
import { RootState } from '../../services/redux/root-reducers';
import ContainerPaperLayout from '../../ui-components/ContainerPaperLayout';
import { useParams } from 'react-router-dom';
import RoomCard from '../../ui-components/room-card/room-card';
import RoomCardContainer from '../../ui-components/room-card/room-car-container/room-card-container';
import Layout from '../../ui-components/layout/layout';
import AddRoomModal from '../../business/DialogContent/AddRoomModal';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, styled, Grid } from '@mui/material';


const Rooms = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const { payload } = useSelector<RootState, GetUserProjectByCode>((state) => state.getUserProjectByCode);
    const deleteRoom = useSelector<RootState, DeleteRoomState>((state) => state.deleteRoom);
    const duplicateRes = useSelector<RootState, DuplicateRoomState>((state) => state.duplicateRoom);
    const createProject = useSelector<RootState, CreateProjectsState>((state) => state.createProject);
    const updateName = useSelector<RootState, UpdateProjectNameState>(state => state.updateProjectName);
    const [open, setOpen] = useState<boolean>(false);
    const [isMount, setIsMount] = useState(false);

    const handleOpenRoomModal = () => {
        setOpen(!open);
    };

    useEffect(() => {
        if (!isMount) {
            dispatch(getUserProjectByCode(params.code));
            setIsMount(true);
        }
        return () => {
            dispatch(resetUserProjectByCode());
        };
    }, []);
    useEffect(() => {
        if (createProject.payload.content !== undefined && createProject.payload.errors.length === 0) {
            dispatch(getUserProjectByCode(params.code));
            dispatch(resetCreateProjects());
        }
    }, [createProject]);
    useEffect(() => {
        if (duplicateRes.payload.content !== undefined && payload.errors.length === 0) {
            dispatch(getUserProjectByCode(params.code));
            dispatch(resetDuplicateRoom());
        }
    }, [duplicateRes]);
    useEffect(() => {
        deleteRoom.payload.content !== undefined && dispatch(getUserProjectByCode(params.code));
    }, [deleteRoom]);
    useEffect(() => {
        if (updateName.payload.content !== undefined && updateName.payload.errors.length === 0) {
            dispatch(getUserProjectByCode(params.code));
            dispatch(resetUpdateRoomName());
        }
    }, [updateName]);

    return (
        <Layout navbar={ true }>
            <ContainerPaperLayout path='/projects' backArrow title='Projets'>
                <RoomCardContainer
                    projectId={ payload.content && payload.content.id }
                    code={ payload.content?.code }
                    title={ payload.content?.name }
                    updatedDate={ payload.content?.updatedAt }>
                    <Grid container direction='row' spacing={ { xs: 1, md: 2, lg: 3 } }>
                        <Grid item xs={ 6 } md={ 4 } lg={ 3 } xl={ 3 }>
                            <CustomBox>
                                <CustomAddButton onClick={ handleOpenRoomModal } variant='outlined'
                                                 color='secondary'
                                                 startIcon={
                                                     <AddIcon sx={ { fontSize: 40 } } />
                                                 }>
                                    Ajouter une pièce
                                </CustomAddButton>
                            </CustomBox>
                        </Grid>
                        { payload.content &&
                            payload.content.content?.rooms.map((room: any, index) => {
                                return (
                                    <Grid item xs={ 6 } md={ 4 } lg={ 3 } xl={ 3 } key={ index }>
                                        <RoomCard
                                            vignetteUrl={ room.vignetteUrl }
                                            projectId={ payload.content?.id }
                                            code={ params.code }
                                            key={ index }
                                            name={ room.name }
                                            roomUuid={ room.roomUuid }
                                            updatedDate={ payload.content && payload.content.updatedAt }
                                        />
                                    </Grid>
                                );
                            }) }
                    </Grid>
                </RoomCardContainer>
                { open &&
                    <AddRoomModal
                        onClose={ handleOpenRoomModal }
                        open={ open }
                        titleStepOne='Ajouter une pièce'
                        titleStepTwo='Choisir une forme'
                    />
                }
            </ContainerPaperLayout>
        </Layout>
    );
};

const CustomAddButton = styled(Button)(() => ({
        '& .MuiButton-startIcon': { marginRight: 0 },
        '& .MuiSvgIcon-root': { fontSize: 50 },
        textTransform: 'none',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: 247,
    }
));

const CustomBox = styled(Box)(() => ({ height: '100%' }));

export default Rooms;
