import React, { useState } from "react";
import DialogLayout from "../../../ui-components/Dialog/DialogLayout";
import DialogContent from "@mui/material/DialogContent";
import { Typography, styled, Button } from "@mui/material";
import TextFieldUi from "../../../ui-components/TextFieldUi";
import trash from "../../../asset/Poubelle-p-blanc.svg";
import DialogAction from "../../../ui-components/Dialog/DialogAction";


type DeleteProjectProps = {
    onClose: () => void;
    title: string;
    open: boolean;
    dialogContent: string;
    actionOnclick: () => void;
    projectName: string | undefined;
};

const DeleteProject = ({ open, onClose, title, dialogContent, actionOnclick, projectName }: DeleteProjectProps) => {
    const [isRoomErr, setIsRoomErr] = useState(false);
    const [roomErrMsg, setRoomErrMsg] = useState("");
    const [deleteMsg, setDeleteMsg] = useState("");

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setDeleteMsg(e.target.value);
    };

    const checkProjectError = () => {
        if (deleteMsg.length === 0 || deleteMsg !== projectName) {
            setIsRoomErr(true);
            setRoomErrMsg("Le texte ne correspond pas.");
            return false;
        }
        return true;
    };

    const deleteProject = () => {
        checkProjectError() && actionOnclick();
    };

    return (
        <DialogLayout
            title={ title }
            onClose={ onClose }
            open={ open }
        >
            <DialogContent>
                <Typography variant='subtitle1' color='primary'>
                    { dialogContent }
                </Typography>
            </DialogContent>
            <DialogContent>
                <TextFieldUi
                    name='supress-project'
                    id='supress-project'
                    variant='outlined'
                    fullWidth
                    placeholder={ `${ projectName }` }
                    error={ isRoomErr }
                    value={ deleteMsg }
                    onChange={ handleChange }
                    helperText={ roomErrMsg }
                />
            </DialogContent>
            <DialogAction>
                <CustomBtn variant='outlined' onClick={ onClose } size='large'>annuler</CustomBtn>
                <CustomBtn variant='contained' size='large' color='error' onClick={ deleteProject }
                           startIcon={ <img alt='trash logo' src={ trash } /> } disableElevation
                >
                    supprimer
                </CustomBtn>
            </DialogAction>
        </DialogLayout>
    );
};

const CustomBtn = styled(Button)(() => ({
    width: 200,
    height: 50
}));

export default DeleteProject;