import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {SellerMode} from "../../Components/SellerApp3D/SellerMode";

export type SellerApp3DModeState = {
    sellerMode: SellerMode;
};

const initialSellerApp3DModeState: SellerApp3DModeState = {
    sellerMode: SellerMode.RoomStudio,
};

const SellerApp3DModeSlice = createSlice({
    name: 'sellerApp3DMode',
    initialState: initialSellerApp3DModeState,
    //reducers : define how the state can be updated
    reducers: {
        updateSellerApp3DMode: (state, action: PayloadAction<SellerMode>) => {
            if (action.payload !== undefined) {
                state.sellerMode = action.payload;
            }
        },
    },
});

export const { updateSellerApp3DMode } = SellerApp3DModeSlice.actions;
export default SellerApp3DModeSlice;
