import { Vector3 } from 'three';
import { Asset3DData } from './Asset3DData';
import PhysicalAssetData from "./PhysicalAssetData";

export default class GeometryData extends PhysicalAssetData {
    private dataModelVersion: string;

    private type: string;

    private boundingbox_scale?: Vector3;

    public constructor() {
        super();
        this.dataModelVersion = '';
        this.type = '';
        this.boundingbox_scale = new Vector3(1, 1, 1);
    }

    public get Type(): string {
        return this.type;
    }

    public get BoundingboxScale(): Vector3 | undefined {
        return this.boundingbox_scale;
    }

    public set BoundingboxScale(p_scale: Vector3|undefined) {
        this.boundingbox_scale = p_scale;
    }

    public static IsEquals(p_primitive1: GeometryData, p_primitive2: GeometryData): boolean {
        if (p_primitive1 == null) throw new Error('NullReferenceException : p_primitive1 is null or undefined');
        if (p_primitive2 == null) throw new Error('NullReferenceException : p_primitive2 is null or undefined');

        if (p_primitive1.type !== p_primitive2.type) return false;
        if (p_primitive1.boundingbox_scale === undefined && p_primitive2.boundingbox_scale !== undefined) return false;
        if (p_primitive1.boundingbox_scale !== undefined && p_primitive2.boundingbox_scale === undefined) return false;
        if (p_primitive1.boundingbox_scale !== undefined && p_primitive2.boundingbox_scale !== undefined) {
            if (p_primitive1.boundingbox_scale.x !== p_primitive2.boundingbox_scale.x) return false;
            if (p_primitive1.boundingbox_scale.y !== p_primitive2.boundingbox_scale.y) return false;
            if (p_primitive1.boundingbox_scale.z !== p_primitive2.boundingbox_scale.z) return false;
        }

        return true;
    }

    public static IsGeometryData(data: Asset3DData): data is GeometryData {
        let geometryData = data as GeometryData;
        let hasType = geometryData.type !== undefined;
        return hasType;
    }
}
