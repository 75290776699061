import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { BackSide, Mesh, MeshStandardMaterial, PMREMGenerator, SphereGeometry, WebGLRenderer } from "three";
import { MeshFilterComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent";
import { MeshRendererComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshRendererComponent";
import { TextureLoader } from "@lutithree/build/Modules/WebGL/Resources/Load/TextureLoader";
import {
    IAsyncEntityDecorator
} from "../../../../Domain/IAsyncEntityDecorator";

export class SeethroughEnvmapDecorator implements IAsyncEntityDecorator<SceneEntity>
{
    private static m_pmremGenerator: PMREMGenerator;

    private m_envmapContent: string;

    public constructor(p_envmapContent: string, p_viewport: WebGLRenderer)
    {
        if (!p_envmapContent) throw new Error('NullReferenceException : p_orbitControls is null or undefined');
        if (p_viewport == null) throw new Error('NullReferenceException : p_viewport is null or undefined ');

        this.m_envmapContent = p_envmapContent;

        if (SeethroughEnvmapDecorator.m_pmremGenerator) SeethroughEnvmapDecorator.m_pmremGenerator.dispose();
        SeethroughEnvmapDecorator.m_pmremGenerator = new PMREMGenerator(p_viewport);
        SeethroughEnvmapDecorator.m_pmremGenerator.compileEquirectangularShader();
    }

    public DecorateAsync(p_entity: SceneEntity): Promise<void>
    {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        let geometry = new SphereGeometry(30, 16, 16);
        let sphere = new Mesh(geometry);
        sphere.renderOrder = -150;
        let meshFilter = p_entity.AddComponentOfType(MeshFilterComponent, sphere);

        return new Promise<void>((resolve) =>
        {
                new TextureLoader(this.m_envmapContent).LoadAsync().then(
                        function (texture)
                        {
                            const materialSeeThrough = new MeshStandardMaterial({
                                color: 0x000000,
                                emissive: 0xffffff,
                                side: BackSide,
                                depthWrite: false,
                                metalness: 0.0,
                                envMap: null,
                                roughness: 1.0,
                            });
                            materialSeeThrough.emissiveMap = texture;
                            let meshRenderer = p_entity.AddComponentOfType(MeshRendererComponent, false, false, materialSeeThrough);
                            meshRenderer.AddMeshFilter(meshFilter);
                            resolve();
                        }
                );
        });
    }
}