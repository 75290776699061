import React from 'react';
import { PresetTree, Product } from '../../../../domain/domain';
import configuredProductService from '../../../../services/redux/reducers/products/configured-product/configured-product.service';
import logoMdf from '../../../../asset/mdf.png';
import './product-catalog-search-results.scss';

type ProductPanelListProps = {
    products: Product[];
    setClickProduct: (preset: PresetTree | undefined) => void;
};
const ProductCatalogSearchResults = ({ products, setClickProduct }: ProductPanelListProps) => {
    return (
        <div className="product-catalog-search-results-main">
            <div className="subsection-panel-right">
                <div className="preset-list">
                    {products.map((product: Product) => {
                        return (
                            <div
                                onClick={() => {
                                    configuredProductService.getConfiguredProductTreeByProductReference(product.reference).then((res: any) => {
                                        setClickProduct(res.content);
                                    });
                                }}
                                className="preset"
                                key={product.id}
                            >
                                <img alt="preset" src={product.thumbnailUrl.length === 0 ? logoMdf : product.thumbnailUrl} />
                                <div>{product.name}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ProductCatalogSearchResults;
