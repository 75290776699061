import { MappingConstraint, MdfApiResponse } from '../../../../domain/domain';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import mappingConstraintService from '../../../../api/service/mapping-zoning/mapping-constraints.services';

export type ConstraintState = {
    isLoading: boolean;
    payload: MdfApiResponse<MappingConstraint | undefined>;
};

export type ConstraintListState = {
    isLoading: boolean;
    payload: MdfApiResponse<MappingConstraint[] | undefined>;
};

const initialConstraintState: ConstraintState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};

const initialConstraintListState: ConstraintListState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

//<editor-fold desc="create-mapping-constraint">
export const createMappingConstraint = createAsyncThunk('create/mappingConstraint', async (constraint: MappingConstraint) => {
    const res = await mappingConstraintService.createMappingConstraint(constraint);
    return res;
});

export const createMappingConstraintSlice = createSlice({
    name: 'createMappingConstraint',
    initialState: initialConstraintState,
    reducers: {
        resetCreateMappingConstraint: (state) => {
            state = initialConstraintState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createMappingConstraint.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createMappingConstraint.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createMappingConstraint.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetCreateMappingConstraint } = createMappingConstraintSlice.actions;
//</editor-fold>

//<editor-fold desc="get-mapping-constraint">
export const getMappingConstraint = createAsyncThunk('get/mappingConstraint', async (mappingId: number) => {
    const res = await mappingConstraintService.getMappingConstraint(mappingId);
    return res;
});

export const getMappingConstraintSlice = createSlice({
    name: 'getMappingConstraint',
    initialState: initialConstraintListState,
    reducers: {
        resetGetMappingConstraint: (state) => {
            state = initialConstraintListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMappingConstraint.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getMappingConstraint.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getMappingConstraint.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetGetMappingConstraint } = getMappingConstraintSlice.actions;

//</editor-fold>

//<editor-fold desc="delete-mapping-constraint">
export const deleteMappingConstraint = createAsyncThunk('delete/mappingConstraint', async (constraintId: number) => {
    const res = await mappingConstraintService.deleteMappingConstraint(constraintId);
    return res;
});

export const deleteMappingConstraintSlice = createSlice({
    name: 'deleteMappingConstraint',
    initialState: initialConstraintState,
    reducers: {
        resetDeleteMappingConstraint: (state) => {
            state = initialConstraintState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteMappingConstraint.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteMappingConstraint.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deleteMappingConstraint.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetDeleteMappingConstraint } = deleteMappingConstraintSlice.actions;
//</editor-fold>
