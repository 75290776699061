import { NewFrameEvent } from '@lutithree/build/Modules/Core/GameLoop/Events/NewFrameEvent';
import { IHandler } from '@lutithree/build/Modules/Core/Event/IHandler';
import { LastFrameEvent } from '@lutithree/build/Modules/Core/GameLoop/Events/LastFrameEvent';
import { Engine } from "@lutithree/build/Engine";
import RoomStudioServices from "../../../RoomStudioServices";
import { SetOnLoadCallback } from "@lutithree/build/Librairies/LSCustomControls/LSTransformControls";
import ARoomStudioHandler from "../../../../ARoomStudioHandler";

export default class RendererUpdateHandler extends ARoomStudioHandler implements IHandler<NewFrameEvent>, IHandler<LastFrameEvent> {
    constructor(p_engine: Engine, p_services: RoomStudioServices) {
        SetOnLoadCallback(()=>{
            this.m_engine.Modules.LoopStrategy.RequestRender(true);
        });
        super(p_engine, p_services);
    }
    
    public Handle(p_event: NewFrameEvent | LastFrameEvent): void {
        this.m_services.Notifier.NotifyRendererUpdated(this.m_engine.Modules.Rendering.Renderer);
    }
}
