import { Engine } from "@lutithree/build/Engine";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { SupportComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SupportComponent";
import { SelectableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SelectableComponent";
import RoomGeometrySystem from "./Systems/RoomGeometrySystem";
import { Guid } from "guid-typescript";
import { MeshLinksComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshLinksComponent";
import { TranslatableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/TranslatableComponent";
import { Vector3 } from "three";
import WallsInitializerSystem from "./Systems/WallsInitializerSystem";
import WallComponent from "./Components/WallComponent";
import IRoomStudioNotifier from "../../Domain/Notifier/IRoomStudioNotifier";
import ObjectSystem
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Composition/ObjectSystem";
import ShapeData
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/ShapeData";
import ObjectGeometryChangedEvent
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/Events/ObjectGeometryChangedEvent";
import InfoComponent
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/InfoComponent";
import ObjectComponent
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";
import PartComponent
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/PartComponent";

export default class WallBehaviours {

    private readonly m_engine: Engine;

    private m_notifier: IRoomStudioNotifier;
    
    private readonly m_roomGeometry: RoomGeometrySystem;

    private readonly m_wallSystem: WallsInitializerSystem;

    private m_objectSystem: ObjectSystem;

    public constructor(p_engine: Engine, p_notifier: IRoomStudioNotifier) {
        if (p_notifier == null) throw new Error('NullReferenceException : p_notifier is null or undefined');
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');

        this.m_engine = p_engine;
        this.m_notifier = p_notifier;
        this.m_roomGeometry = new RoomGeometrySystem(this.m_engine.Modules.Scene);
        this.m_wallSystem = new WallsInitializerSystem(p_engine.Modules.Scene, this.m_engine.Modules.Systems.CameraSystem);
        this.m_objectSystem = new ObjectSystem(p_engine.Modules.Scene);
    }
    
    public OnAwake(p_entities: SceneEntity[], p_shape: ShapeData): void {
        if (p_entities == null) throw new Error('NullReferenceException : p_entities is null or undefined');
        if (p_shape == null) throw new Error('NullReferenceException : p_shape is null or undefined');

        this.m_wallSystem.SetupWalls(p_entities, p_shape);
    }

    public OnWallViewedFromBehind(p_entity: SceneEntity, p_isViewedFromBehind: boolean): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_isViewedFromBehind == null) throw new Error('NullReferenceException : p_isViewedFromBehind is null or undefined');
        
        let snappedEntities = this.m_engine.Modules.Systems.SnapSystem.GetSnappedEntitiesOnSupport(p_entity.GetComponentOfType(SupportComponent));
        snappedEntities.forEach((snappedEntity) => {
            let selectable = snappedEntity.HasComponentOfType(SelectableComponent, false)?snappedEntity.GetComponentOfType(SelectableComponent): undefined;
            if(!(selectable && selectable.IsSelected) || selectable === undefined){
                snappedEntity.Enable(!p_isViewedFromBehind);
            }
        });
    }
    
    public OnWallMove(p_wallPartEntity: SceneEntity, p_worldTranslation: Vector3 | undefined): void {
        if (p_wallPartEntity == null) throw new Error('NullReferenceException : p_wallPartEntity is null or undefined');
        
       this.TryMoveConnectedWalls(p_wallPartEntity, p_worldTranslation);
    }

    public OnWallEndMove(p_wallEntity: SceneEntity): void {
        // Notify Shape
        let shapeTemplate = this.m_roomGeometry.GetRoomShapeTemplate();
        let groundInfoComponent = this.m_engine.Modules.Scene.GetComponents(InfoComponent, { entity: false, component: false }).find((object) => object.Info.Type === 'Ground');
       
        if (groundInfoComponent) {
            let groundEntity = this.m_engine.Modules.Scene.GetEntityByID(groundInfoComponent.EntityID);
            let groundObjectComponent = groundEntity?.HasComponentOfType(ObjectComponent)? groundEntity?.GetComponentOfType(ObjectComponent):undefined;
            if(groundObjectComponent){
                this.m_notifier.NotifyAssetDataChanged(groundObjectComponent.Ref, 'Shape', shapeTemplate);
            }
        }
        
        // Notify wall geometry change
        let wallComponent = p_wallEntity.HasComponentOfType(WallComponent) ? p_wallEntity.GetComponentOfType(WallComponent): undefined;
        if(wallComponent){
            let wallEntity = this.m_objectSystem.GetObjectEntity(wallComponent.RefOfInstance);
            let wallObjectComponent = wallEntity?.HasComponentOfType(ObjectComponent)?wallEntity.GetComponentOfType(ObjectComponent):undefined;
            wallObjectComponent?.PartIds.forEach((entityPartID)=>{
                let partEntity = this.m_engine.Modules.Scene.GetEntityByID(entityPartID);
                let meshLinkComponent = partEntity.GetComponentOfType(MeshLinksComponent);
                let linkedEntityIds = meshLinkComponent.GetLinkedEntityIds();
                linkedEntityIds.forEach((entityID)=>{
                    let wallPartEntity = this.m_engine.Modules.Scene.GetEntityByID(entityID);
                    let wallPartComponent = wallPartEntity.HasComponentOfType(PartComponent)? wallPartEntity.GetComponentOfType(PartComponent):undefined;
                    if(wallPartComponent){
                        let linkedWallEntity = this.m_objectSystem.GetObjectEntity(wallPartComponent.ParentObjectRef);
                        if(linkedWallEntity) this.m_engine.Modules.EventManager.Publish(ObjectGeometryChangedEvent, new ObjectGeometryChangedEvent(linkedWallEntity));
                    }
                });
            });
        }
    }
    
    /*public GetRelativeWalls(p_wallEntity: SceneEntity): SceneEntity[] {
        if (p_wallEntity == null) throw new Error('NullReferenceException : p_wallEntity is null or undefined');
        
        let relativeWalls: SceneEntity[] = [];
        let wallObject = p_wallEntity.HasComponentOfType(WallComponent) ? p_wallEntity.GetComponentOfType(WallComponent): undefined;
        if(wallObject){
            let objectComponent =
                this.m_objectSystem.
                this.m_entityManager.GetComponents(ObjectComponent, { entity: false, component: false })
                    .filter(x => x.Ref == wallComponent.RefOfInstance);
            wallObject.PartIds.forEach((entityPartID)=>{
                let partEntity = this.m_engine.Modules.Scene.GetEntityByID(entityPartID);
                let meshLinkComponent = partEntity.GetComponentOfType(MeshLinksComponent);
                let linkedEntityIds = meshLinkComponent.GetLinkedEntityIds();
                linkedEntityIds.forEach((entityID) => {
                    let wallPartEntity = this.m_engine.Modules.Scene.GetEntityByID(entityID);
                    let objectPart = wallPartEntity.HasComponentOfType(RoomPartComponent)? wallPartEntity.GetComponentOfType(RoomPartComponent):undefined;
                    if(objectPart){
                        let wallEntity = this.m_objectSystem.GetObjectEntity(objectPart.ParentObjectRef);
                        if(wallEntity){
                            relativeWalls.push(wallEntity);
                            this.m_engine.Modules.EventManager.Publish(ObjectGeometryChangedEvent, new ObjectGeometryChangedEvent(wallEntity));
                        }
                    }
                });
            });
        }
        return relativeWalls;
    }*/
    
    private TryMoveConnectedWalls(p_entity: SceneEntity, p_worldTranslation: Vector3 | undefined): void {
        // Get entities linked by vertices
        let meshLinkComponent = p_entity.GetComponentOfType(MeshLinksComponent);
        let linkedEntityIds = meshLinkComponent.GetLinkedEntityIds();

        let linkedMeshLinks = this.GetRevlativesMeshLinks(linkedEntityIds, 'Wall');
        if (linkedMeshLinks.length > 0) {
            let check = this.m_roomGeometry.IsRoomGeometryCorrect(linkedMeshLinks);
            if (!check) {
                // Revert verices movement
                let connectables: MeshLinksComponent[] = this.m_engine.Modules.Systems.GroupSystem.GetComponentsOnGroup(p_entity, MeshLinksComponent);
                connectables.forEach((connectable) => {
                    this.m_engine.Modules.Systems.MeshEditSystem.RevertToPreviousPosition(connectable.MeshConnections);
                });
                // Lock direction movement
                let translatables: TranslatableComponent[] = this.m_engine.Modules.Systems.GroupSystem.GetComponentsOnGroup(p_entity, TranslatableComponent);
                translatables.forEach((translatable) => {
                    translatable.LockedWorldDirection = p_worldTranslation;
                });
            } else {
                this.GetRevlativesMeshLinks(linkedEntityIds).forEach((connectable) => {
                    connectable.OnGeometryScaled();
                    // TODO send event wall moved
                });
            }
        }
    }

    private GetRevlativesMeshLinks(p_entityIds: Guid[], p_roomObjectType: string | undefined = undefined): MeshLinksComponent[] {
        let wallMeshLinks: MeshLinksComponent[] = [];
        p_entityIds.forEach((id) => {
            let entity = this.m_engine.Modules.Scene.GetEntityByID(id);
            if (p_roomObjectType) {
                if (entity.HasComponentOfType(PartComponent)) {
                    let partComponent = entity.GetComponentOfType(PartComponent);
                    let parentEntity = this.m_objectSystem.GetObjectEntity(partComponent.ParentObjectRef);
                    let infoComponent = parentEntity?.HasComponentOfType(InfoComponent) ? parentEntity?.GetComponentOfType(InfoComponent) : undefined;
                    if (infoComponent && infoComponent.Info.Type === p_roomObjectType) {
                        wallMeshLinks.push(entity.GetComponentOfType(MeshLinksComponent));
                    }
                }
            } else {
                wallMeshLinks.push(entity.GetComponentOfType(MeshLinksComponent));
            }
        });
        return wallMeshLinks;
    }
}