import { IHandler } from '@lutithree/build/Modules/Core/Event/IHandler';
import { Engine } from "@lutithree/build/Engine";
import { AHandler } from "@lutithree/build/Handlers/AHandler";
import ObjectSystem from "../../Composition/ObjectSystem";
import ObjectEditedEvent from "../Events/ObjectEditedEvent";
import { EditionType } from "../../../../Domain/Objects/AssetAssembly/EditionType";
import SelectionService from "../../../Features3D/Selection/SelectionService";
import CamerasService from "../../../Studio/Cameras/CamerasService";
import ObjectComponent from "../../Components/ObjectComponent";

export class ObjectEditedHandler extends AHandler implements IHandler<ObjectEditedEvent> {
    
    private m_objectSystem: ObjectSystem;
    
    private m_selectionService: SelectionService;
    
    private m_cameraService: CamerasService;

    public constructor(p_engine: Engine, p_selectionService: SelectionService, p_cameraService: CamerasService){
        super(p_engine);

        if (p_selectionService == null) throw new Error('NullReferenceException : p_selectionService is null or undefined');
        if (p_cameraService == null) throw new Error('NullReferenceException : p_cameraService is null or undefined');
        
        this.m_objectSystem = new ObjectSystem(p_engine.Modules.Scene);
        this.m_selectionService = p_selectionService;
        this.m_cameraService = p_cameraService;
    }

    public Handle(p_event: ObjectEditedEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');
        
        if(p_event.NewParts.size === 1){
            // @ts-ignore
            const [firstPart] = p_event.NewParts.values();
            if(firstPart.HasComponentOfType(ObjectComponent)) this.m_selectionService.UpdateSelectionStatus(firstPart,false);
            this.m_cameraService.FocusPerspectiveCameraOnObjects([p_event.Entity.Transform.GetObject()], 1.1);
        }

        if(p_event.EditionReports.find(x=>x.EditionType === EditionType.Remove && x.PartType==='BasicObject')){
            this.m_cameraService.FocusPerspectiveCameraOnObjects([p_event.Entity.Transform.GetObject()], 1.1);
        }
    }
}
