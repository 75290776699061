import AddProduct from './AddProduct/AddProduct';
import EditRoom from './EditFloor/EditFloor';
import { Box, styled } from '@mui/material';


type RightBarProps = {
    disable: boolean;
    setOpenShapePanel: (OpenShapePanel: boolean) => void;
    setBuildingElementPanel: (buildingElementPanel: boolean) => void;
    setOpenPanel: (OpenPanel: boolean) => void;
};

const RightBar = (props: RightBarProps) => {
    return (
        <>
            <StyledBoxRightBar>
                <AddProduct
                    setOpenPanel={ props.setOpenPanel }
                    setBuildingElementPanel={ props.setBuildingElementPanel }
                />
                <EditRoom />
            </StyledBoxRightBar>
        </>
    );
};

const StyledBoxRightBar = styled(Box)(() => ({
    position: 'fixed',
    right: '0.5%',
    top: '24%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

export default RightBar;
