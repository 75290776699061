import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import {Event, Group, Material, MeshLambertMaterial, Object3D} from 'three';
import { MaterialLoader } from '@lutithree/build/Modules/WebGL/Resources/Load/MaterialLoader';
import EntityMeshController from "../../EntityMeshController";
import AssignmentData from "../../../../../Domain/Objects/Assets/AssignmentData";
import {IAssetDecorator} from "../../../../../Domain/Objects/AssetAssembly/IAssetDecorator";
import Asset3D from '../../../../../Domain/Objects/Assets/Asset3D';
import {TexturesLoadingError} from "@lutithree/build/Modules/WebGL/Resources/Load/TexturesLoadingError";

export default class MaterialAssignmentDecorator implements IAssetDecorator {
    private m_defaultMaterial: Material;

    private readonly m_entityMeshController: EntityMeshController;
    
    public constructor(p_defaultMaterial: Material) {
        if (p_defaultMaterial == null) throw new Error('NullReferenceException : p_defaultMaterial is null or undefined');

        this.m_defaultMaterial = p_defaultMaterial;
        this.m_entityMeshController = new EntityMeshController();
    }

    public DecorateAsset(p_entity: SceneEntity, p_asset: Asset3D, p_levelLoadedCallback: (p_resource: Material | Material[] | Group | Object3D<Event>) => void): Promise<void> {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_asset == null) throw new Error('NullReferenceException : p_asset is null or undefined');
        if (p_levelLoadedCallback == null) throw new Error('NullReferenceException : p_levelLoadedCallback is null or undefined');
        
        return new Promise<void>((resolve, reject) => {

            let promises: Promise<void>[] = [];
            let datas = p_asset.Datas as AssignmentData[];

            let materials: Map<string, Material> = new Map<string, Material>();
            if(datas !== undefined){
                datas.forEach((assignment) => {
                    let addNewLevel = (p_material: { material: Material; path: string | undefined }, p_level: number) => {
                        this.AddNewLevel(materials, assignment.Refs, p_entity, p_material, p_level, p_levelLoadedCallback);
                    };

                    let promise = new Promise<void>((internalResolve, internalReject) => {
                        let materialAsset: MaterialLoader = new MaterialLoader(assignment.Urls);
                        materialAsset.AddOnNewLevelLoadedCallback(addNewLevel);
                        materialAsset.LoadAsync().then((material) => {
                                internalResolve();
                            },
                            (reason)=>{
                                if(reason instanceof TexturesLoadingError){
                                    let material = reason.Material as MeshLambertMaterial;
                                    reason.Textures.forEach((url,slot)=>{
                                        // @ts-ignore
                                        material[slot]?.dispose();
                                        // @ts-ignore
                                        material[slot] = null;
                                        material.needsUpdate = true;
                                    });

                                    assignment.Refs.forEach((ref) => {
                                        materials.set(ref, reason.Material);
                                    });
                                }
                                internalReject(reason);
                            });
                    });
                    promises.push(promise);
                });
            }
            else{
                let meshRendererComponent = this.m_entityMeshController.GetOrAddMeshRenderer(p_entity);
                if (meshRendererComponent !== undefined) {
                    meshRendererComponent.Material = materials;
                }
            }
            
            Promise.allSettled(promises).then(
                (results) => {
                    this.ApplyMaterials(materials, p_entity);
                    
                    results.forEach((result)=>{
                        if(result.status == "rejected"){
                            reject(result.reason);
                        }
                    });
                    resolve();
                });
        });
    }

    private AddNewLevel(p_materials: Map<string, Material>,
                        p_refs: string[], p_entity: SceneEntity,
                        p_material: { material: Material; path: string | undefined },
                        p_level: number,
                        p_levelLoadedCallback: (p_resource: Material | Material[] | Group | Object3D<Event>) => void)
            : void {

        //console.log('Add levels ', {ref:p_refs, mat:p_material, level:p_level, map:p_materials});
        p_refs.forEach((ref) => {
            p_materials.set(ref, p_material.material);
        });
        let actualMaterial: Material | Material[] | Map<string, Material> | undefined = this.m_entityMeshController.GetOrAddMeshRenderer(p_entity).Material;
        if (actualMaterial !== undefined) {
            if (actualMaterial instanceof Map) p_levelLoadedCallback(Array.from(actualMaterial.values()));
            else p_levelLoadedCallback(actualMaterial);
        }
        if (p_level !== 0) this.ApplyMaterials(p_materials, p_entity);
    }

    private ApplyMaterials(p_materials: Map<string, Material>, p_entity: SceneEntity): void {
        this.m_entityMeshController.GetOrAddMeshRenderer(p_entity).Material = p_materials;
    }
}
