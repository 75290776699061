import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { BoxGeometry, BufferGeometry, CylinderGeometry, Group, Material, Mesh, Object3D, PlaneGeometry, Vector3 } from 'three';
import { Object3DUtils } from '@lutithree/build/Modules/WebGL/Utils/Object3DUtils';
import { MeshUtils } from "@lutithree/build/Modules/WebGL/Utils/MeshUtils";
import Asset3D from "../../../../../Domain/Objects/Assets/Asset3D";
import EntityMeshController from "../../EntityMeshController";
import GeometryData from "../../../../../Domain/Objects/Assets/GeometryData";
import { IAssetDecorator } from "../../../../../Domain/Objects/AssetAssembly/IAssetDecorator";

export class GeometryDecorator implements IAssetDecorator {
    private m_defaultMaterial: Material;

    private readonly m_entityMeshController: EntityMeshController;
    
    public constructor(p_defaultMaterial: Material) {
        if (p_defaultMaterial == null) throw new Error('NullReferenceException : p_defaultMaterial is null or undefined');

        this.m_defaultMaterial = p_defaultMaterial;
        this.m_entityMeshController = new EntityMeshController();
    }
    
    public async DecorateAsset(p_entity: SceneEntity, p_asset: Asset3D, p_levelLoadedCallback: (p_resource: (Material | Material[] | Group | Object3D)) => void): Promise<void> {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_asset == null) throw new Error('NullReferenceException : p_asset is null or undefined');
        if (p_levelLoadedCallback == null) throw new Error('NullReferenceException : p_levelLoadedCallback is null or undefined');

        return new Promise<void>((resolve) => {
            let data = p_asset.Datas as GeometryData;
            let boundingboxScale = new Vector3(1,1,1);
            if(data.BoundingboxScale) boundingboxScale = data.BoundingboxScale;

            let geometry: BufferGeometry;
            switch (data.Type) {
                case 'Cube':
                    geometry = new BoxGeometry(boundingboxScale.x, boundingboxScale.y, boundingboxScale.z);
                    break;
                case 'Cylinder':
                    geometry = new CylinderGeometry(boundingboxScale.x/2, boundingboxScale.x/2, boundingboxScale.y, 100);
                    break;
                case 'Plane': {
                    geometry = new PlaneGeometry(boundingboxScale.x,boundingboxScale.y);
                    break;
                }
                default:
                    console.warn('Selected primitive does not exist');
                    geometry = new BoxGeometry(boundingboxScale.x, boundingboxScale.y, boundingboxScale.z);
                    break;
            }
            let mesh = new Mesh(geometry, this.m_defaultMaterial);
            MeshUtils.RefreshUV_local(mesh);
            if (data.Transform) Object3DUtils.ApplyTransform(mesh, data.Transform);
            if (data.Pivot) Object3DUtils.ApplyPivot(mesh, data.Pivot);

            this.m_entityMeshController.AddModel(p_entity, mesh);
            resolve();
        });
    }
}
