import { AHandler } from "@lutithree/build/Handlers/AHandler";
import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import { Engine } from "@lutithree/build/Engine";
import { NewFrameEvent } from "@lutithree/build/Modules/Core/GameLoop/Events/NewFrameEvent";
import ScaleService from "../TransformControls/ScaleService";
import { DynamicScaleComponent } from "../TransformControls/Components/DynamicScaleComponent";
import { LastFrameEvent } from "@lutithree/build/Modules/Core/GameLoop/Events/LastFrameEvent";

export default class NewFrameHandler extends AHandler implements IHandler<NewFrameEvent>, IHandler<LastFrameEvent> {

    private m_scaleService: ScaleService;
    
    public constructor(p_engine: Engine, p_scaleService: ScaleService) {
        super(p_engine);
        
        this.m_scaleService = p_scaleService;
    }

    public Handle(p_event: NewFrameEvent | LastFrameEvent): void {
        let dynamicComponents = this.m_engine.Modules.Scene.GetComponents(DynamicScaleComponent);
        dynamicComponents.forEach((component)=>{
            component.UpdateCurrentScale();
            component.Objects.forEach((object)=>{
                this.m_scaleService.DynamicRescaleObject(object.object, object.scale, object.initialScale.clone());
            });
        });
    }
}