import { IPartService } from "./IPartService";
import { Engine } from "@lutithree/build/Engine";
import AService from "../../AService";
import MapComparator from "../../../Utils/MapComparator";
import { IPartElement } from "./IPartElement";
import Composition from "./Composition";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import PartEditionReport from "../AssetAssembly/PartEditionReport";
import { EditionType } from "../AssetAssembly/EditionType";
import ObjectComponent from "../../../GameLogic/Objects/Components/ObjectComponent";

export default class CompositionService extends AService{

    protected readonly m_comparator: MapComparator<IPartElement>;
    
    protected m_partService: IPartService;
    
    public constructor(p_engine: Engine, p_partService: IPartService){
        super(p_engine);
        if (p_partService == null) throw new Error('NullReferenceException : p_partService is null or undefined');
        
        this.m_partService = p_partService;
        this.m_comparator = new MapComparator<IPartElement>();
    }

    public async LoadCompositionAsync(p_ref: string, p_composition: Composition, p_compositionEntity: SceneEntity): Promise<Map<string, SceneEntity>> {
        if (!p_ref) throw new Error('NullReferenceException : p_assets is null or undefined or etmpty');
        if (p_composition == null) throw new Error('NullReferenceException : p_composition is null or undefined');

        p_compositionEntity.Transform.GetObject().visible = false;

        let partElementByRef = p_composition.GetPartElementsByRef();

        return new Promise<Map<string, SceneEntity>>((resolve, reject) => {
            let promises: Array<Promise<{ref:string, entity:SceneEntity}[]>> = new Array<Promise<{ref:string, entity:SceneEntity}[]>>();
            let partEntities: Map<string, SceneEntity> = new Map<string, SceneEntity>();

            partElementByRef.forEach((partElements, refOfPart) => {
                let instance3d = p_composition.GetInstance3D(refOfPart);
                let target = (partElements.length > 1) ? partElements : partElements[0];
                let promise = this.m_partService.LoadPartAsync(p_compositionEntity, p_ref, refOfPart, target, instance3d);
                promise.then((result)=>{
                    partEntities.set(result[0].ref,result[0].entity);
                });
                promises.push(promise);
            });

            Promise.all(promises).then((result) => {
                p_compositionEntity.Transform.GetObject().visible = true;
                resolve(partEntities);
            });
        });
    }

    public async EditCompositionAsync(p_ref: string, p_newComposition: Composition, p_lastComposition: Composition, p_compositionEntity: SceneEntity): 
        Promise<{newParts:Map<string, SceneEntity>, reports:PartEditionReport[]}> {
        
        if (!p_ref) throw new Error('NullReferenceException : p_assets is null or undefined or etmpty');
        if (p_lastComposition == null) throw new Error('NullReferenceException : p_lastComposition is null or undefined');
        if (p_newComposition == null) throw new Error('NullReferenceException : p_newComposition is null or undefined');
        if (p_compositionEntity == null) throw new Error('NullReferenceException : p_compositionEntity is null or undefined');

        let editionReport :PartEditionReport[] = [];
        let promises: Array<Promise<PartEditionReport[] | {ref: string, entity: SceneEntity}[] | Map<string, SceneEntity>>>
            = new Array<Promise<PartEditionReport[] | Map<string, SceneEntity>>>();

        let newPartEntities: Map<string, SceneEntity> = new Map<string, SceneEntity>();

        if (p_compositionEntity.HasComponentOfType(ObjectComponent)) {
            let objComponent: ObjectComponent = p_compositionEntity.GetComponentOfType(ObjectComponent);

            let elementsByPart1: Map<string, Array<IPartElement>> = p_lastComposition.GetPartElementsByRef();
            let elementsByPart2: Map<string, Array<IPartElement>> = p_newComposition.GetPartElementsByRef();

            // Suppress removed parts
            let removedParts = this.m_comparator.GetRemovedKeys(elementsByPart1, elementsByPart2);
            removedParts.forEach((value, refOfPart) => {
                let entityId = objComponent.GetEntityIdOfPart(refOfPart);
                if (entityId) {
                    this.m_engine.Modules.Scene.RemoveEntityByID(entityId);
                    objComponent.RemovePart(refOfPart);
                    editionReport.push(new PartEditionReport(refOfPart, EditionType.Remove, p_lastComposition.Type));
                }
            });

            // Create added parts
            let addedParts = this.m_comparator.GetAddedKeys(elementsByPart1, elementsByPart2);
            addedParts.forEach((partElements, partRef) => {
                let target = (partElements.length > 1) ? partElements : partElements[0];
                let promise = this.m_partService.LoadPartAsync(p_compositionEntity, p_ref, partRef, target);
                promise.then((partEntity) => {
                    newPartEntities.set(partEntity[0].ref, partEntity[0].entity);
                });
                promises.push(promise);
                editionReport.push(new PartEditionReport(partRef, EditionType.Remove, p_lastComposition.Type));
            });

            // In same parts, find edited parts
            let sameParts = this.m_comparator.GetSameKeys(elementsByPart1, elementsByPart2);
            sameParts.forEach((assets, partRef) => {
                let target1 = (elementsByPart1.get(partRef)!.length > 1) ? elementsByPart1.get(partRef)! : elementsByPart1.get(partRef)![0];
                let target2 = (elementsByPart2.get(partRef)!.length > 1) ? elementsByPart2.get(partRef)! : elementsByPart2.get(partRef)![0];
                let promise = this.m_partService.EditPartAsync(objComponent, partRef, target2, target1);
                promise.then((result)=>{
                    editionReport = editionReport.concat(result);
                });
                promises.push(promise);
            });
            
        }
        
        return new Promise<{newParts:Map<string, SceneEntity>, reports:PartEditionReport[]}>((resolve, reject) => {
            Promise.all(promises).then((result) => {
                resolve({newParts: newPartEntities, reports: editionReport});
            });
        });
    }
}