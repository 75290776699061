import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetUserProjectByCode, getUserProjectByCode } from '../../../services/redux/reducers/projects/project.slice';
import { RootState } from '../../../services/redux/root-reducers';
import { AddRoomState, duplicateRoom, DuplicateRoomState, getRoom, GetRoomState, resetAddRoom, resetDuplicateRoom } from '../../../services/redux/reducers/rooms/rooms.slice';
import AddRoomModal from '../../DialogContent/AddRoomModal';
import { UpdateRoomVignette } from '../../../services/redux/reducers/rooms/screenshot.slice';
import { vignetteScreenshot } from '../../../utils/vignette-screenshot';
import { saveRoom } from '../../../utils/saveRoom';
import HeaderLayout from '../../../ui-components/HeaderLayout';
import { Box, Divider, Typography, MenuItem, IconButton, Button, Menu, styled } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { RoomContentState } from '../../../application-3d/application3D-seller/Redux/Reducers/room-studio/RoomContentReducer';
import RoomStudioApp from '../../../application-3d/application3D-seller/Libraries/Studios/RoomStudio/RoomStudioApp';
import store from "../../../services/store";

type NavBarPlannerProps = {};

const NavbarPlanner = ({}: NavBarPlannerProps) => {
    let roomStudio: RoomStudioApp | null = store.getState().roomStudio.roomStudioApp;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { projectId, projectCode, uuid } = useParams();
    const [mount, setMount] = useState(false);
    const [openM, setopenM] = useState(false);
    const [backPush, setBackPush] = useState(false);
    const [projectPush, setProjectPush] = useState(false);
    const [imgUrl, setImgUrl] = useState();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openEl = Boolean(anchorEl);
    const isOpenDropdown = openEl ? 'secondary' : 'primary';
    const fontWeightHeader = openEl ? 500 : 'normal';

    const room = useSelector<RootState, GetRoomState>((state) => state.getRoom);
    const project = useSelector<RootState, GetUserProjectByCode>((state) => state.getUserProjectByCode);
    const duplicateRoomRes = useSelector<RootState, DuplicateRoomState>((state) => state.duplicateRoom);
    const addRoomRes = useSelector<RootState, AddRoomState>((state) => state.addRoom);
    const snapshot = useSelector<RootState, RoomContentState>((state) => state.roomContent);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (!mount) {
            projectId &&
                room.payload.content === undefined &&
                dispatch(
                    getRoom({
                        projectId: parseInt(projectId),
                        roomUuid: uuid,
                    })
                );
            projectCode && dispatch(getUserProjectByCode(projectCode));
            setMount(true);
        }
    }, []);
    useEffect(() => {
        if (addRoomRes.payload.content.name.length > 0 && addRoomRes.payload.errors.length < 1) {
            vignetteScreenshot(setImgUrl, roomStudio);
            room.payload.content && uuid && projectId && saveRoom(uuid, projectId, room.payload.content, room.payload.content.room.basketProducts, snapshot, dispatch);
            projectId && navigate(`/planner/${parseInt(projectId)}/${projectCode}/${addRoomRes.payload.content.roomUuid}`);
            projectId &&
                dispatch(
                    getRoom({
                        projectId: parseInt(projectId),
                        roomUuid: addRoomRes.payload.content.roomUuid,
                    })
                );
            projectCode && dispatch(getUserProjectByCode(projectCode));
            dispatch(resetAddRoom());
        }
    }, [addRoomRes]);
    useEffect(() => {
        if (duplicateRoomRes.payload.content !== undefined && duplicateRoomRes.payload.errors.length < 1) {
            projectId && navigate(`/planner/${parseInt(projectId)}/${projectCode}/${duplicateRoomRes.payload.content.roomUuid}`);
            dispatch(resetDuplicateRoom());
        }
    }, [duplicateRoomRes]);
    useEffect(() => {
        projectId && dispatch(getRoom({ projectId: parseInt(projectId), roomUuid: uuid }));
    }, [uuid]);
    useEffect(() => {
        if (imgUrl !== undefined && projectId && uuid) {
            dispatch(
                UpdateRoomVignette({
                    projectId: parseInt(projectId),
                    roomUuid: uuid,
                    fileUrl: imgUrl,
                })
            );
            if (backPush) {
                setImgUrl(undefined);
                setBackPush(false);
                navigate(`/room/${projectId}/${uuid}`);
            }
            if (projectPush) {
                setImgUrl(undefined);
                setBackPush(false);
                setProjectPush(false);
                navigate(`/project/${projectId}/${projectCode}`);
            }
        }
    }, [imgUrl]);

    return (
        <>
            <HeaderLayout>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ArrowBackIosNewIcon
                        onClick={() => {
                            vignetteScreenshot(setImgUrl, roomStudio);
                            setBackPush(true);
                        }}
                        sx={{ fontSize: 32, cursor: 'pointer' }}
                    />
                    <Divider sx={{ margin: '0 1.5em' }} orientation="vertical" flexItem />
                    <Typography
                        onClick={() => {
                            vignetteScreenshot(setImgUrl, roomStudio);
                            setProjectPush(true);
                        }}
                        sx={{
                            fontWeight: 'normal',
                            fontSize: 16,
                            letterSpacing: '1.4px',
                            textTransform: 'uppercase',
                            cursor: 'pointer',
                        }}
                        color="primary"
                    >
                        {room.payload.content?.projectName}
                    </Typography>
                    <Divider sx={{ margin: '0 1.5em' }} orientation="vertical" flexItem />
                    <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={handleClick}>
                        <Typography
                            sx={{
                                fontWeight: fontWeightHeader,
                                fontSize: 16,
                                letterSpacing: '1.4px',
                                textTransform: 'uppercase',
                                cursor: 'pointer',
                                mr: 1,
                            }}
                            color={isOpenDropdown}
                        >
                            {room.payload.content?.room.name}
                        </Typography>
                        {openEl ? (
                            <ExpandLessIcon color={isOpenDropdown} sx={{ fontSize: 31, cursor: 'pointer' }} />
                        ) : (
                            <ExpandMoreIcon color={isOpenDropdown} sx={{ fontSize: 31, cursor: 'pointer' }} />
                        )}
                    </Box>
                </Box>
            </HeaderLayout>
            {openEl && (
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={openEl}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflowY: 'auto',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.7,
                            minWidth: 322,
                            '& .MuiList-root': {
                                paddingTop: '0!important',
                                borderRadius: '5px 5px 0px 0px',
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'tr  anslateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderRadius: '5px 5px 0px 0px',
                            pt: 2,
                            pb: 2,
                            pr: 2,
                            pl: 2,
                            background: '#F9F9F9',
                        }}
                    >
                        <Typography
                            color="secondary"
                            sx={{
                                fontWeight: 500,
                                fontSize: 16,
                                letterSpacing: '1.4px',
                                textTransform: 'uppercase',
                                cursor: 'pointer',
                            }}
                        >
                            {room.payload.content?.room.name}
                        </Typography>
                        <Box>
                            <IconButton
                                sx={{ mr: 1 }}
                                onClick={() => {
                                    navigate(`/room/${projectId}/${uuid}`);
                                }}
                                aria-label="button info"
                                color="primary"
                            >
                                <InfoOutlinedIcon sx={{ fontSize: 31 }} />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    dispatch(
                                        duplicateRoom({
                                            projectId: project.payload.content?.id,
                                            roomName: room.payload.content?.room.name,
                                        })
                                    );
                                }}
                                aria-label="button shared"
                                color="primary"
                            >
                                <ContentCopyIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                        </Box>
                    </Box>
                    <Divider />
                    {project.payload &&
                        project.payload.content?.content.rooms.map((rooms: any, index: number) => {
                            return (
                                <MenuItem
                                    key={index}
                                    color="primary"
                                    onClick={() => {
                                        vignetteScreenshot(setImgUrl, roomStudio);
                                        room.payload.content && uuid && projectId && saveRoom(uuid, projectId, room.payload.content, room.payload.content.room.basketProducts, snapshot, dispatch);
                                        navigate(`/planner/${projectId}/${projectCode}/${rooms.roomUuid}`);
                                    }}
                                    sx={{
                                        borderBottom: '1px rgba(0,0,0,0.12) solid',
                                        fontSize: 16,
                                        fontWeight: 'normal',
                                        letterSpacing: '1.4px',
                                        textTransform: 'uppercase',
                                        cursor: 'pointer',
                                        pt: 2,
                                        pb: 2,
                                    }}
                                >
                                    {rooms.name}
                                </MenuItem>
                            );
                        })}
                    <Box sx={{ pr: 1, pl: 1, pt: 1 }}>
                        <StyledButton
                            onClick={() => {
                                setopenM(true);
                            }}
                            startIcon={<AddIcon />}
                            sx={{ width: '100%' }}
                            variant="contained"
                            color="secondary"
                            size="medium"
                            disableElevation
                        >
                            AJOUTER UNE PIECE
                        </StyledButton>
                    </Box>
                </Menu>
            )}
            {openM && uuid && <AddRoomModal onClose={() => setopenM(false)} open={openM} titleStepOne="Ajouter une nouvelle pièce" titleStepTwo="Choisir une forme" />}
        </>
    );
};

const StyledButton = styled(Button)(() => ({
    width: 200,
    height: 50,
}));

export default NavbarPlanner;
