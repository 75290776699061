import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../services/redux/root-reducers';
import {
    addRoom,
    AddRoomState,
    getRoomModel,
    GetRoomModelState, resetAddRoom,
    resetGetRoomModel,
} from '../../../services/redux/reducers/rooms/rooms.slice';
import { getUserProjectByCode, GetUserProjectByCode } from '../../../services/redux/reducers/projects/project.slice';
import DialogLayout from '../../../ui-components/Dialog/DialogLayout';
import DialogContent from '@mui/material/DialogContent';
import DialogAction from '../../../ui-components/Dialog/DialogAction';
import TextFieldUi from '../../../ui-components/TextFieldUi';
import { styled, Button, Box, CardActionArea, Grid, Typography } from '@mui/material';
import creationforme from '../../../asset/creationforme.svg';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonNavigate from '../../../ui-components/ButtonNavigate';


type AddRoomModalProps = {
    onClose: () => void;
    open: boolean;
    titleStepOne: string
    titleStepTwo: string
};

const AddRoomModal = ({ onClose, open, titleStepOne, titleStepTwo }: AddRoomModalProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const addRoomRes = useSelector<RootState, AddRoomState>((state) => state.addRoom);
    const { payload2 } = useSelector<RootState, GetRoomModelState>((state) => state.getRoomModel);
    const project = useSelector<RootState, GetUserProjectByCode>((state) => state.getUserProjectByCode);
    const [helperText, setHelperText] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [focusStep, setFocusStep] = useState<number>(0);
    const [templateId, setTemplateId] = useState(1);
    const [templateFocus, setTemplateFocus] = useState(0);
    const [step, setStep] = useState({ current: 1 });
    const [roomName, setRoomName] = useState<string>('');
    const titleStepper = step.current === 1 ? titleStepOne : titleStepTwo;

    const createRoomWithTemplate = () => {
        const projectId = params.projectId && parseInt(params.projectId);
        if (projectId) {
            dispatch(addRoom({
                projectId, templateId, roomDTO: {
                    name: roomName,
                    updatedAt: new Date(),
                },
            }));
        }
    };

    const handleChange = (name: string) => {
        setRoomName(name);
        if (name.length === 24) {
            setError(true);
            setHelperText('Le nombre de caractères maximum est atteint.');
        } else {
            setError(false);
            setHelperText('');
        }
    };

    const handleSelectTemplate = (idx: number, elId: number) => {
        setTemplateFocus(idx);
        setTemplateId(elId);
    };

    const handleSubmit = () => {
        if (roomName.length === 0) {
            setError(true);
            setHelperText('Le nom de la pièce doit etre spécifié');
        } else {
            setStep({ current: 2 });
            setError(false);
            setHelperText('');
        }
    };

    useEffect(() => {
        dispatch(resetAddRoom());
        dispatch(getRoomModel());
        dispatch(resetGetRoomModel());
    }, []);

    useEffect(() => {
        if (addRoomRes.payload.content.name.length > 0 && addRoomRes.payload.errors.length < 1) {
           // params.projectId && navigate(`/planner/${ parseInt(params.projectId) }/${ params.code }/${ addRoomRes.payload.content.roomUuid }`);
            dispatch(getUserProjectByCode(params.code));
            onClose();
        }
    }, [addRoomRes]);

    useEffect(() => {
        if (payload2 !== undefined && payload2?.length > 0) {
            setTemplateId(payload2[0].id);
            setTemplateFocus(0);
        }
    }, [payload2]);

    const StyledSelectBox = styled(Box)(({ theme }) => ({
        border: `${ focusStep === 0 ? `solid 3px ${ theme.palette.primary.main }` : `1px solid #CCCCCC;` }`,
        padding: '14px 25px',
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
    }));

    const CustomBox = styled(Box)(() => ({
        padding: '14px 25px',
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));


    return (
        <DialogLayout
            title={ titleStepper }
            onClose={ onClose }
            open={ open }
        >
            { step.current === 1 && (
                <>
                    <DialogContent>
                        <Box sx={ { mb: 1 } }>
                            <TextFieldUi
                                labelContent='Nommez votre pièce'
                                placeholder='Nom de la pièce'
                                name='add-room'
                                id='new-peace'
                                variant='outlined'
                                error={ error }
                                helperText={ helperText }
                                onChange={ (e) => handleChange(e.target.value) }
                                fullWidth
                                value={ roomName }
                                inputProps={ { maxLength: 24 } }
                                maxLength={ 24 }
                                showLength
                            />
                        </Box>
                    </DialogContent>
                    <DialogContent>
                        <Box sx={ { mb: 2 } }>
                            <Label>Comment voulez-vous créer votre pièce ?</Label>
                        </Box>
                        <Box>
                            <CardActionArea>
                                <StyledSelectBox onClick={ () => setFocusStep(0) } key={ 0 }>
                                    <img alt='forme' style={ { height: 36, marginRight: 30 } } src={ creationforme } />
                                    Partir d'une forme
                                </StyledSelectBox>
                            </CardActionArea>
                        </Box>
                    </DialogContent>
                    <DialogAction>
                        <CustomBtn variant='outlined' onClick={ onClose }>ANNULER</CustomBtn>
                        <CustomBtn color='secondary'
                                   variant='contained'
                                   onClick={ handleSubmit }
                                   disableElevation>CONTINUER</CustomBtn>
                    </DialogAction>
                </>
            ) }
            { step.current === 2 && (
                <>
                    <DialogContent>
                        <ButtonNavigate label='Retour' onClick={ () => setStep({ current: 1 }) } />
                        <Box sx={ { mb: 3, mt: 1 } }>
                            <Label>Choisir une forme de pièce pour commencer</Label>
                        </Box>
                        <Grid sx={ { justifyContent: 'center' } } container spacing={ 2 }
                              columns={ { xs: 4, sm: 8, md: 12 } }>
                            { payload2?.map((result, index) => (
                                <Grid item xs={ 3 } key={ index }>
                                    <CardActionArea onClick={ () => handleSelectTemplate(index, result.id) }>
                                        <CustomBox
                                            sx={ (theme: { typography: { body: any; }; palette: { primary: { main: any; }; }; }) => ({
                                                ...theme.typography.body,
                                                border: `${ templateFocus === index ? `solid 3px ${ theme.palette.primary.main }` : `1px solid #EAEAEC` }`,
                                            }) }
                                        >
                                            <img alt='prev'
                                                 src={ result.previewPicture } />
                                        </CustomBox>
                                    </CardActionArea>
                                    <Typography sx={ { textAlign: 'center', mt: 2 } } variant='subtitle1'
                                                color='primary'>
                                        { result.name }
                                    </Typography>
                                </Grid>
                            )) }
                        </Grid>
                    </DialogContent>
                    <DialogAction>
                        <CustomBtn variant='outlined' onClick={ onClose }>ANNULER</CustomBtn>
                        <CustomBtn color='secondary'
                                   variant='contained'
                                   onClick={ createRoomWithTemplate }
                                   disableElevation>Commencer</CustomBtn>
                    </DialogAction>
                </>
            ) }
        </DialogLayout>
    );
};

const CustomBtn = styled(Button)(() => ({
    width: 200,
    height: 50,
}));

const Label = styled('label')(({ theme }) => ({
    color: `${ theme.palette.primary.main }`,
}));

export default AddRoomModal;