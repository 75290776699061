import { createSlice } from '@reduxjs/toolkit';

export type ModalState = {
    modals: string[];
};

const modalState: ModalState = {
    modals: [],
};

const modalSlice = createSlice({
    name: "modal",
    initialState: modalState,
    reducers: {
        addModal: (state, action) => {
            state.modals.push(action.payload);
        },
        removeModal: (state, action) => {
            state.modals = state.modals.filter(modal => modal !== action.payload);
        },
    },
});

export const { addModal, removeModal } = modalSlice.actions;
export default modalSlice.reducer;