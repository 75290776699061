import { WebGLRenderer } from "three";
import IBasicNotifier from "../../Domain/Notifier/IBasicNotifier";
import { Guid } from "guid-typescript";
import { ConnectorsComponent } from "../Features3D/Connectors/Components/ConnectorsComponent";
import RendererUpdatedTSEvent from "./TSEvents/RendererUpdatedTSEvent";
import EntitySelectedTSEvent from "./TSEvents/EntitySelectedTSEvent";
import ConnectorClickedTSEvent from "./TSEvents/ConnectorClickedTSEvent";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import ResourceLoadingFailTSEvent from "./TSEvents/ResourceLoadingFailTSEvent";
import ResourceErrorData from "../../Domain/Objects/ResourceErrorData";
import ObjectComponent from "../Objects/Components/ObjectComponent";

export default class TSBasicNotifierService implements IBasicNotifier {
    
    public NotifyEntityClicked(p_entityID: Guid, p_isSelected: boolean): void {
        if (p_entityID == null) throw new Error('NullReferenceException : p_entityID is null or undefined');
        if (p_isSelected == null) throw new Error('NullReferenceException : p_isSelected is null or undefined');

        dispatchEvent(new CustomEvent<EntitySelectedTSEvent>('application3d-onEntitySelected', { detail: { EntityID: p_entityID, IsSelected: p_isSelected } }));
    }
    
    public NotifyRendererUpdated(p_webGLRenderer: WebGLRenderer): void {
        if (p_webGLRenderer == null) throw new Error('NullReferenceException : p_webGLRenderer is null or undefined');

        dispatchEvent(new CustomEvent<RendererUpdatedTSEvent>('application3d-rendererUpdated', { detail: { Renderer: p_webGLRenderer } }));
    }

    public NotifyConnectorClicked(p_entity: SceneEntity, p_connectorComponent : ConnectorsComponent, p_refOfConnector: string, p_isSelected: boolean): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entityID is null or undefined');
        if (p_isSelected == null) throw new Error('NullReferenceException : p_isSelected is null or undefined');

        let objectComponent = p_entity.HasComponentOfType(ObjectComponent)?p_entity.GetComponentOfType(ObjectComponent):undefined;
        if(objectComponent){
            dispatchEvent(new CustomEvent<ConnectorClickedTSEvent>('application3d-onConnectorClicked',
                { detail: { EntityID: p_entity.Id, RefOfInstance: objectComponent.Ref,RefOfConnector: p_refOfConnector, IsSelected: p_isSelected } }));
        }
    }

    public NotifyResourceLoadingFailed(p_resourceErrorData: ResourceErrorData): void {
        if (p_resourceErrorData == null) throw new Error('NullReferenceException : p_resourceErrorData is null or undefined');

        dispatchEvent(new CustomEvent<ResourceLoadingFailTSEvent>('application3d-resourceLoadingFailed', 
            { detail: { ResourceErrorData: p_resourceErrorData } }));
    }
}
