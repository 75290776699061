import IServicesManager from './IServicesManager';
import ILogService from '../Services/Log/ILogService';
import IStatisticsService from '../Services/Statistics/IStatisticsService';
import { WebGLRenderer } from 'three';
import { LogLevel } from '../Debug';

export default class ServicesManager implements IServicesManager {
    private m_logServices: ILogService[];

    private m_updateServices: IStatisticsService[];

    constructor() {
        this.m_logServices = [];
        this.m_updateServices = [];
    }

    public AddLogService(p_service: ILogService) {
        this.m_logServices.push(p_service);
    }

    public AddStatisticsService(p_service: IStatisticsService) {
        if (p_service == null) throw new Error('NullReferenceException : p_name is null or undefined');
        this.m_updateServices.push(p_service);
    }

    public Log(p_logMessage: string, p_level: LogLevel): void {
        this.m_logServices.forEach((logger) => {
            logger.Log(p_logMessage, p_level);
        });
    }

    public SpyValue<T>(p_name: string, p_value: T): void {}

    public Update(p_renderer: WebGLRenderer): void {
        this.m_updateServices.forEach((service) => {
            service.Update(p_renderer);
        });
    }
}
