import apiClient from '../../api-client';
import { COMPUTE_PRICES, FIND_BASKET_BY_ROOM, UPDATE_BASKET } from '../../enpoint/basket/basket.endpoint';
import { BasketContent, BasketProduct } from '../../../domain/domain';

const BasketServices = {
    findBasketByRoom: async (roomUuid: string) => apiClient(FIND_BASKET_BY_ROOM(roomUuid), undefined, undefined, 'GET'),
    updateBasket: async (content: BasketContent) => apiClient(UPDATE_BASKET, content, undefined, 'PUT'),
    computeBasketPrices: async (products: BasketProduct[]) => apiClient(COMPUTE_PRICES, products, undefined, 'POST'),
};
export default BasketServices;
