import axios from 'axios';
import { Vector2 } from 'three';
import { getConfig } from '../api/config';

export const vignetteScreenshot = (setImgUrl: any, application: any) => {
    application &&
        application.Services.Screenshot.Take3DSnapshot(new Vector2(480, 270)).then((blob: Blob) => {
            const formData = new FormData();
            formData.append('file', blob);
            axios
                .post(`${getConfig().urlHostApi}/v1/documents/upload`, formData, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('mdf-jwt')}` },
                })
                .then((res) => {
                    // @ts-ignore
                    setImgUrl(res.data.content);
                })
                .catch((e) => {
                    console.error('Error :', e);
                });
        });
};
