import AObjectEvent from "./AObjectEvent";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import PartEditionReport from "../../../../Domain/Objects/AssetAssembly/PartEditionReport";
import ObjectComponent from "../../Components/ObjectComponent";

export default class ObjectEditedEvent extends AObjectEvent {
    
    private readonly m_editionReports: PartEditionReport[];
    
    private readonly m_newParts: Map<string, SceneEntity>;
    
    public constructor(p_entity: SceneEntity, p_objectComponent: ObjectComponent, p_newParts: Map<string, SceneEntity>, p_editReports: PartEditionReport[]){
        super(p_entity, p_objectComponent);

        if (p_editReports == null) throw new Error('NullReferenceException : p_editReports is null or undefined');
        if (p_newParts == null) throw new Error('NullReferenceException : p_newParts is null or undefined');
        
        this.m_editionReports = p_editReports;
        this.m_newParts = p_newParts;
    }
    
    public get EditionReports() : PartEditionReport[] {
        return this.m_editionReports;
    }

    public get NewParts() : Map<string, SceneEntity> {
        return this.m_newParts;
    }
}