import { Asset3DData } from "./Asset3DData";
import PhysicalAssetData from "./PhysicalAssetData";

export default class Model3dData extends PhysicalAssetData{
    private dataModelVersion: string;

    private urls: string[];

    private hooks?: string[];

    public constructor() {
        super();
        this.dataModelVersion = '';
        this.urls = [];
    }

    public get Urls(): string[] {
        return this.urls;
    }

    public get Hooks(): string[]|undefined {
        return this.hooks;
    }

    public static IsEquals(p_model3dData1: Model3dData, p_model3dData2: Model3dData): boolean {
        if (p_model3dData1 == null) throw new Error('NullReferenceException : p_model3dData1 is null or undefined');
        if (p_model3dData2 == null) throw new Error('NullReferenceException : p_model3dData2 is null or undefined');

        //compare urls
        if (p_model3dData1.urls.length !== p_model3dData2.urls.length) return false;
        for (let i: number = 0; i < p_model3dData1.urls.length; i++) {
            if (p_model3dData1.urls[i] !== p_model3dData2.urls[i]) return false;
        }

        //compare hooks
        if((p_model3dData1.hooks && !p_model3dData2.hooks) || (!p_model3dData1.hooks && p_model3dData2.hooks)) return false;
        if(p_model3dData1.hooks && p_model3dData2.hooks){
            if (p_model3dData1.hooks.length !== p_model3dData2.hooks.length) return false;
            for (let i: number = 0; i < p_model3dData1.hooks.length; i++) {
                if (p_model3dData1.hooks[i] !== p_model3dData2.hooks[i]) return false;
            }

        }
       
        return true;
    }

    public static IsModel3dData(data: Asset3DData | string): data is Model3dData {
        let assignmentData = data as Model3dData;
        return assignmentData.urls !== undefined;
    }
}
