import * as React from 'react';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { Button, styled } from '@mui/material';


type ButtonNavigateProps = {
    onClick: () => void;
    label: string;
};

const ButtonNavigate = ({ onClick, label }: ButtonNavigateProps) => {
    return (
        <>
            <CustomNavButton size='medium' onClick={ onClick }>
                <KeyboardArrowLeft sx={ { fontSize: 37 } } />
                { label }
            </CustomNavButton>
        </>
    );
};

const CustomNavButton = styled(Button)(() => ({
    padding: '1px 8px 1px 0',
    fontSize: 18,
    textTransform: 'capitalize',
}));

export default ButtonNavigate;