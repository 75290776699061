export interface IPartElement {
    readonly Ref:string; 
}

export const GetPartElementsByRef = <T extends IPartElement>(p_partElements: T[]):Map<string, Array<T>> => {
    let partElementsByPart: Map<string, Array<T>> = new Map<string, Array<T>>();

    // parcours des partElements
    for (let i: number = 0; i < p_partElements.length; i++) {
        // if part already exist in map
        if (partElementsByPart.has(p_partElements[i].Ref)) {
            partElementsByPart.get(p_partElements[i].Ref)!.push(p_partElements[i]);
        } // else add key
        else {
            partElementsByPart.set(p_partElements[i].Ref, [p_partElements[i]]);
        }
    }

    return partElementsByPart;
};