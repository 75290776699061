import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { Mesh, PlaneGeometry, ShadowMaterial } from "three";
import { MeshFilterComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent";
import { IEntityDecorator } from "../../../../../Application3D/Domain/IEntityDecorator";

export class InvisibleShadowGroundDecorator implements IEntityDecorator<SceneEntity> {
    public Decorate(p_entity: SceneEntity): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        let geometry = new PlaneGeometry(2000, 2000, 100, 100);
        geometry.rotateX( - Math.PI / 2 );

        const material = new ShadowMaterial();
        material.opacity = 0.2;
        
        let plane = new Mesh(geometry, material);
        plane.receiveShadow = true;
        p_entity.AddComponentOfType(MeshFilterComponent, plane);
    }
}