import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import AObjectEvent from "./AObjectEvent";
import ObjectComponent from "../../Components/ObjectComponent";

export default class APartEvent extends AObjectEvent {
    private m_refOfPart: string;

    private m_partEntity: SceneEntity;

    public constructor(p_entity: SceneEntity, p_objectComponent: ObjectComponent, p_partEntity:SceneEntity, p_refOfPart: string) {
        super(p_entity, p_objectComponent);
        if (!p_refOfPart) throw new Error('NullReferenceException : p_refOfPart is null or undefined or empty');
        if (p_partEntity == null) throw new Error('NullReferenceException : p_refOfPart is null or undefined');

        this.m_refOfPart = p_refOfPart;
        this.m_partEntity = p_partEntity;
    }

    public get RefOfPart(): string {
        return this.m_refOfPart;
    }

    public get PartEntity(): SceneEntity {
        return this.m_partEntity;
    }
}
