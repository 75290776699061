import {Spherical, Vector3} from "three";

export default class Information {
    private dataModelVersion: string;
    
    private name: string;
    
    private type: string;

    private behaviours: string[];

    private pointOfView?: Spherical;
    
    private isEditable?: boolean;
    
    private isAutoReorientable?: boolean;
    
    private dimensions?: Vector3;

    private spawnHeight?: number;
    
    private isDeletable: boolean;

    public constructor() {
        this.dataModelVersion = '';
        this.name = '';
        this.pointOfView = new Spherical();
        this.type = '';
        this.isEditable = false;
        this.isAutoReorientable = false;
        this.behaviours = [];
        this.isDeletable = false;
    }

    public Clone(): Information {
        let infoClone = new Information();
        infoClone.dataModelVersion = this.dataModelVersion;

        infoClone.name = this.name;
        infoClone.type = this.type;
        infoClone.behaviours = this.behaviours;
        if(this.dimensions) infoClone.dimensions = this.dimensions.clone();
        if (this.spawnHeight) infoClone.spawnHeight = this.spawnHeight;
        infoClone.isDeletable= this.isDeletable;

        return infoClone;
    }

    public get Type(): string {
        return this.type;
    }

    public set Type(p_type: string) {
        this.type = p_type;
    }

    public get Name(): string {
        return this.name;
    }

    public set Name(p_name: string) {
        this.name = p_name;
    }

    public get IsEditable(): boolean {
        return this.isEditable != undefined? this.isEditable:false;
    }

    public get IsAutoReorientable(): boolean {
        return this.isAutoReorientable != undefined? this.isAutoReorientable:false;
    }

    public get PointOfView(): Spherical|undefined {
        return this.pointOfView? Object.assign(new Spherical(), this.pointOfView):undefined;
    }

    public set PointOfView(p_pointOfView: Spherical|undefined) {
        this.pointOfView = p_pointOfView;
    }

    public get Deletable(): boolean {
        return this.isDeletable;
    }

    public set Deletable(p_value: boolean) {
        this.isDeletable = p_value;
    }
    
    public get Dimensions(): Vector3|undefined {
        return this.dimensions? Object.assign(new Vector3(), this.dimensions):undefined;
    }

    public get SpawnHeight(): number | undefined {
        return this.spawnHeight? this.spawnHeight:undefined;
    }

    public HasBehaviour(p_behaviour: string): boolean {
        if (!p_behaviour) throw new Error('NullReferenceException : p_behaviour is null or undefined or empty');
        if (this.behaviours.includes(p_behaviour)) {
            return true;
        }
        return false;
    }
    
    public set Behaviours(p_behaviours: string[]) {
        this.behaviours = p_behaviours;
    }
}
