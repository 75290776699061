import { Basket, BasketContent, MdfApiResponse } from '../../../../domain/domain';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import BasketServices from '../../../../api/service/basket.services/basket.services';

//region state
export type BasketState = {
    isLoading: boolean;
    payload: MdfApiResponse<Basket>;
};
const initialBasketState: BasketState = {
    isLoading: false,
    payload: {
        content: {
            id: 0, version: 0, roomUuid: '', content: { basketProducts: [] },
        }, errors: [], warnings: [],
    },
};

//endregion

//#region Find Basket By Room
export const FindBasketByRoom = createAsyncThunk('find/basket', async (roomUuid: string) => {
    const res = await BasketServices.findBasketByRoom(roomUuid);
    return res;
});

export const FindBasketByRoomSlice = createSlice({
    name: 'findBasket',
    initialState: initialBasketState,
    reducers: {
        resetFindBasket: (state) => {
            state = initialBasketState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(FindBasketByRoom.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(FindBasketByRoom.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false, fullfiled: true };
            return state;
        });
        builder.addCase(FindBasketByRoom.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetFindBasket } = FindBasketByRoomSlice.actions;
//#endregion

//#region Update Basket
export const UpdateBasket = createAsyncThunk('update/basket', async (content: BasketContent) => {
    const res = await BasketServices.updateBasket(content);
    return res;
});

export const UpdateBasketSlice = createSlice({
    name: 'updateBasket',
    initialState: initialBasketState,
    reducers: {
        resetUpdateBasket: (state) => {
            state = initialBasketState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(UpdateBasket.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(UpdateBasket.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false, fullfiled: true };
            return state;
        });
        builder.addCase(UpdateBasket.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetUpdateBasket } = UpdateBasketSlice.actions;
//#endregion