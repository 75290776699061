import MdfApiResponse from '../../../../domain/common/generic';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Room } from '../../../../domain/domain';
import ScreenshotServices from '../../../../api/service/rooms/screenshot.services';

export type ScreenshotState = {
    isLoading: boolean;
    payload: MdfApiResponse<Room | undefined>;
};

const initialScreenshotState: ScreenshotState = {
    isLoading: false,
    payload: { content: undefined, errors: [], warnings: [] },
};

export const addScreenShotToRoom = createAsyncThunk('addScreenshotState', async (data: { projectId: number; roomUuid: string; file: FormData }) => {
    const res = await ScreenshotServices.addScreenshotToRoom(data.projectId, data.roomUuid, data.file);
    return res;
});

export const addScreenShotSlice = createSlice({
    name: 'addScreenShoot',
    initialState: initialScreenshotState,
    reducers: {
        resetAddScreenshot: (state) => {
            state = initialScreenshotState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addScreenShotToRoom.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(addScreenShotToRoom.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(addScreenShotToRoom.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetAddScreenshot } = addScreenShotSlice.actions;

export const deleteScreenShotfromRoom = createAsyncThunk('deleteScreenShotfromRoom', async (data: { projectId: number; roomUuid: string; fileUrl: { fileUrl: string } }) => {
    const res = await ScreenshotServices.deleteScreenshotFromRoom(data.projectId, data.roomUuid, data.fileUrl);
    return res;
});

export const deleteScreenShotFromRoomSlice = createSlice({
    name: 'deleteScreenShotFromRoom',
    initialState: initialScreenshotState,
    reducers: {
        resetDeleteScreenShotFromRoom: (state) => {
            state = initialScreenshotState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteScreenShotfromRoom.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteScreenShotfromRoom.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(deleteScreenShotfromRoom.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetDeleteScreenShotFromRoom } = deleteScreenShotFromRoomSlice.actions;

//------- update room vignette --------//

export const UpdateRoomVignette = createAsyncThunk('update/roomVignette', async (data: { projectId: number; roomUuid: string; fileUrl: string }) => {
    try {
        const res = await ScreenshotServices.updateRoomVignette(data.projectId, data.roomUuid, data.fileUrl);
        return res;
    } catch (e) {
        console.error(e);
    }
});

export const updateRoomVignetteSlice = createSlice({
    name: 'updateRoom',
    initialState: initialScreenshotState,
    reducers: {
        resetUpdateRoomVignette: (state) => {
            state = initialScreenshotState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(UpdateRoomVignette.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(UpdateRoomVignette.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(UpdateRoomVignette.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetUpdateRoomVignette } = updateRoomVignetteSlice.actions;

//------------------//
