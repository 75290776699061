import { IHandler } from '@lutithree/build/Modules/Core/Event/IHandler';
import ObjectAddedToRoomEvent from "../Events/ObjectAddedToRoomEvent";
import { SnappableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SnappableComponent";
import StructureInstanciatedEvent from "../Events/StructureInstanciatedEvent";
import ARoomStudioHandler from "../../../ARoomStudioHandler";
import ObjectComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";
import InfoComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/InfoComponent";

export class ObjectAddedToRoomHandler extends ARoomStudioHandler implements IHandler<ObjectAddedToRoomEvent>, IHandler<StructureInstanciatedEvent> {
    public Handle(p_event: ObjectAddedToRoomEvent|StructureInstanciatedEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');
        
        
       if(p_event instanceof ObjectAddedToRoomEvent) this.HandleRoomObjectInstanciated(p_event);
       else if(p_event instanceof StructureInstanciatedEvent) this.HandleRoomStructureInstanciation(p_event);
    }
    
    private HandleRoomStructureInstanciation(p_event: StructureInstanciatedEvent): void{
        this.m_services.ObjectBehaviours.Walls.OnAwake(p_event.WallEntities,p_event.Shape);
        this.m_services.Cameras.SetRoomAtCenterOf2DView();
        this.m_services.Cameras.SetCameraTargetAtCenter([p_event.GroundEntity.Transform.GetObject()], 0);
        this.m_services.Cameras.UpdatePOVStatus();
    }
    
    private HandleRoomObjectInstanciated(p_event: ObjectAddedToRoomEvent): void{
        this.m_services.Notifier.NotifyObjectInstanciated(p_event.Entity, p_event.Instance3D);

        this.HandleBasicObjectAwake(p_event);
        let infoComponent = p_event.Entity.HasComponentOfType(InfoComponent)? p_event.Entity.GetComponentOfType(InfoComponent):undefined;
        if (infoComponent && infoComponent.Info.Type === 'Opening') this.HandleOpeningAwake(p_event, p_event.ObjectComponent);

        let snappable = p_event.Entity.HasComponentOfType(SnappableComponent)? p_event.Entity.GetComponentOfType(SnappableComponent):undefined;
        if(snappable) this.m_services.Snap.InitSnapDatas(p_event.Entity);
        this.m_services.ObjectBehaviours.BasicObjects.HideAutoRotateOnWallViewedFromBack(p_event.Entity);
    }

    private HandleBasicObjectAwake(p_event: ObjectAddedToRoomEvent): void {
        this.m_services.ObjectBehaviours.BasicObjects.OnAwake(p_event.Entity);
    }

    private HandleOpeningAwake(p_event: ObjectAddedToRoomEvent, p_objectComponent: ObjectComponent): void {
        this.m_services.ObjectBehaviours.Openings.OnAwake(p_event.Entity, p_objectComponent);
    }
}
