import { useEffect, useState } from 'react';
import arrowLeft from '../../../../asset/arrowLeft.svg';
import { useDispatch } from 'react-redux';
import { Asset, ConfigurationItemV2, ConfigurationResponseV2, MdfSetV2 } from '../../../../domain/domain';
import './preset-panel.scss';

type PresetPanelProps = {
    setOpenPresetPanel: (openInfoPanel: boolean) => void;
    setOpenPanel: (openPanel: boolean) => void;
    setPossibleConfiguration: (possibleConfiguration: MdfSetV2[]) => void;
    setConfiguration: (configuration: ConfigurationItemV2[]) => void;
};

const PresetPanel = (props: PresetPanelProps) => {
    const dispatch = useDispatch();
    const [currentPreset, setCurrentPreset] = useState<ConfigurationResponseV2>();
    const fakeTableImage = 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/1651846340369-table-de-repas-extensible-eclipse.jpeg';

    useEffect(() => {
        if (currentPreset !== undefined) {
            props.setPossibleConfiguration(currentPreset.referentialSelectionnableSets);
            props.setConfiguration(currentPreset.configuration.items);

            let asset: Asset[] = [
                {
                    urls: [
                        'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/materials/mat_IronGrey_LD.gltf',
                        'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/materials/mat_IronGrey_HD.gltf',
                    ],
                    refOfPart: 'setTableConceptPlateau',
                    section: 'Plateau',
                    type: 'Material',
                    options: [],
                },
                {
                    urls: [
                        'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/materials/mat_IronGrey_LD.gltf',
                        'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/materials/mat_IronGrey_HD.gltf',
                    ],
                    refOfPart: 'setTableConceptPied',
                    section: 'Pied',
                    type: 'Material',
                    options: [],
                },
            ];

            const section = (setRef: string) => {
                if (setRef === 'setTableConceptPlateau') {
                    return 'Plateau';
                }
                if (setRef === 'setTableConceptPied') {
                    return 'Pied';
                }
                if (setRef === 'treeTableConceptMaterial') {
                    return 'Plateau';
                } else return '';
            };
            for (let i = 0; i < currentPreset.configuration.items.length; i++) {
                let obj: Asset = {
                    refOfPart: '',
                    type: '',
                    section: '',
                    urls: [],
                    options: [],
                };
                if (currentPreset.configuration.items[i].models[0].type === 'MODEL') {
                    obj.refOfPart = currentPreset.configuration.items[i].setRef;
                    obj.type = 'Model';
                    obj.section = section(currentPreset.configuration.items[i].setRef);
                    obj.urls = currentPreset.configuration.items[i].models[0].urlList;
                    obj.options = currentPreset.configuration.items[i].models[0].options;
                    asset.push(obj);
                }
            }
        }
    }, [currentPreset]);

    return (
        <div className="preset-panel-main">
            <div className="preset-panel-close">
                <div className="preset-panel-title">Table concept</div>
                <div
                    onClick={() => {
                        props.setOpenPresetPanel(false);
                        props.setOpenPanel(false);
                    }}
                    className="preset-panel-back"
                >
                    <img alt="retour" src={arrowLeft} />
                    Remplacer par
                </div>
            </div>
            <div className="preset-panel-body">
                {/* <div className="preset-panel-grid">
                    {getTablePresetRes.payload.content !== undefined &&
                        getTablePresetRes.payload.content.map((configuration: ConfigurationResponseV2, i: number) => {
                            return (
                                <div
                                    key={i}
                                    className="preset-panel-card"
                                    onClick={() => {
                                        setCurrentPreset(configuration);
                                    }}
                                >
                                    <img alt="preset" src={fakeTableImage} />
                                    <span>preset Name</span>
                                </div>
                            );
                        })}
                </div>*/}
            </div>
        </div>
    );
};
export default PresetPanel;
