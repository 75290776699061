import { Layers, Object3D } from 'three';
import { Engine } from '@lutithree/build/Engine';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { IEngine } from '@lutithree/build/IEngine';
import RoomStudioServices from './GameLogic/RoomStudioServices';
import RoomStudioModesBuilder from "./GameLogic/Modes/RoomStudioModesBuilder";
import RoomStudioHandlers from "./GameLogic/RoomStudioHandlers";
import { RoomStudio } from "./GameLogic/Studio/RoomStudio";
import {
    DimensionComponent
} from "../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Dimensions/Components/DimensionComponent";
import ModeManager from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Modes/ModeManager";

export default class RoomStudioApp {
    private readonly m_engine: Engine;

    private readonly m_studio: RoomStudio;

    private readonly m_services: RoomStudioServices;
    
    private readonly m_modeManager: ModeManager;

    public constructor(p_engine: Engine) {
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');
        if (!p_engine.IsInitialized()) throw new Error('Engine need to be initialized before being usued!');

        // Build Engine
        this.m_engine = p_engine;
        let rootEntities = new Map<string, SceneEntity>();
        this.BuildObjectsRootEntities(this.m_engine,rootEntities);
        
        // Set application Service
        this.m_services = new RoomStudioServices(this.m_engine, rootEntities);
        
        // Application modes
        let handlers = new RoomStudioHandlers(this.m_engine, this.m_services);
       
        let modesBuilder: RoomStudioModesBuilder = new RoomStudioModesBuilder(this.m_engine, this.m_services, handlers.Handlers);
        this.m_modeManager = new ModeManager(modesBuilder.Build());

        handlers.InitEventManagerWithHandlers();
       
        this.m_studio = new RoomStudio(rootEntities, this.m_services.Cameras);
        this.m_studio.Build(this.m_engine);
        
        let layers = new Layers();
        layers.enableAll();
        layers.disable(31);
        this.m_engine.Modules.Inputs.SetInputLayers(layers);
        
    }

    public get Services(): RoomStudioServices {
        return this.m_services;
    }

    public get EngineService(): IEngine {
        return this.m_engine;
    }
    
    public get ModesService(): ModeManager {
        return this.m_modeManager;
    }

    public EnableProductMeasures(p_productId: string, p_enabled: boolean): void {
        this.m_engine.Modules.Scene.GetComponents(DimensionComponent).forEach((p_dimensionComponent) => {
            try {
                p_dimensionComponent.Enable(p_enabled);
                Engine.TriggerLastFrameRendering();
            } catch (error) {}
        });
    }

    public VisualizeInAR(p_object: Object3D): void {
        this.m_engine.Modules.AR.ExportToUSDZ(p_object);
    }

    private BuildObjectsRootEntities(p_engine: Engine, p_rootEntities:Map<string, SceneEntity>): void {

        let objectsEntity = p_engine.Modules.Scene.CreateEntity('Objects');

        let helpersEntity = p_engine.Modules.Scene.CreateEntity('Helpers');
        let productsEntity =  p_engine.Modules.Scene.CreateEntity('Products');
        let accessoriesEntity =  p_engine.Modules.Scene.CreateEntity('Accessories');
        let buildingblocksEntity =  p_engine.Modules.Scene.CreateEntity('BuildingBlocks');
        let roomEntity =  p_engine.Modules.Scene.CreateEntity('Room');

        objectsEntity.Transform.AddChild(productsEntity.Transform);
        objectsEntity.Transform.AddChild(accessoriesEntity.Transform);
        objectsEntity.Transform.AddChild(buildingblocksEntity.Transform);
        objectsEntity.Transform.AddChild(roomEntity.Transform);
        objectsEntity.Transform.AddChild(helpersEntity.Transform);

        p_rootEntities.set('Products', productsEntity);
        p_rootEntities.set('Accessories', accessoriesEntity);
        p_rootEntities.set('BuildingBlocks', buildingblocksEntity);
        p_rootEntities.set('Room', roomEntity);
        p_rootEntities.set('Helpers', helpersEntity);
    }
}
