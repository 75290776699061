import ResourceErrorData from "../Librairies/Studios/Application3D/Domain/Objects/ResourceErrorData";
import TextureErrorData from "../Librairies/Studios/Application3D/Domain/Objects/TextureErrorData";
import AssetErrorData from "../Librairies/Studios/Application3D/Domain/Objects/AssetErrorData";

export const onLoadingFail: (p_resourceErrorData:ResourceErrorData) => void = function(p_resourceErrorData:ResourceErrorData){
    if(p_resourceErrorData instanceof TextureErrorData){
        // console.log('[3D ERROR]: Texture loading failed.' ,
        //     '\n\t', 'Material url: '+p_resourceErrorData.MaterialPath,
        //     '\n\t', 'Texture url: '+p_resourceErrorData.BrokenUrl,
        //     '\n\t', 'Slot name: '+p_resourceErrorData.Slot);
    }
    else if(p_resourceErrorData instanceof AssetErrorData){
        // console.log('[3D ERROR]: MDF Asset3D loading failed.' ,
        //     '\n\t', 'Broken url: '+p_resourceErrorData.BrokenUrl,
        //     '\n\t', 'Relative refOfInstance: '+p_resourceErrorData.RefOfInstance,
        //     '\n\t', 'Relative refOfPart: '+p_resourceErrorData.RefOfPart,
        //     '\n\t', 'Relative asset3d: ',p_resourceErrorData.Asset);
    }
};