import React, { useEffect, useState } from 'react';
import Header from '../../ui-components/header/header';
import TextField from '../../ui-components/textfield/textfield';
import { Button } from '../../ui-components/button/button';
import DataValidators from '../../utils/data-validators';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword, ForgotPasswordState } from '../../services/redux/reducers/auth/auth.slice';
import { RootState } from '../../services/redux/root-reducers';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordForm = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [mounted, setMounted] = useState(false);
    const dispatch = useDispatch();
    const history = useNavigate();
    const { payload } = useSelector<RootState, ForgotPasswordState>((state) => state.forgotPassword);
    const validateEmail = () => {
        return DataValidators.validateEmail(email);
    };

    const onSubmit = () => {
        // dispatch(resetForgotPassword());
        if (!validateEmail()) {
            setError('Une adresse email valide doit être renseignée');
            return;
        }
        dispatch(forgotPassword({ email, password: '' }));
    };
    useEffect(() => {
        if (mounted) {
            if (payload.errors.length > 0) {
                setError("L'adresse email renseignée ne correspond a aucun compte");
            } else if (payload.content !== null) {
                alert('un mail vous permettant de réinitialiser votre mot de passe vous sera envoyé');
                history('/login');
            }
        } else {
            setMounted(true);
        }
    }, [payload]);

    return (
        <div className="main-login-form">
            <div className="container">
                <Header title="Mot de passe oublié" />
                <TextField
                    label="Adresse email"
                    type="email"
                    value={email}
                    onChange={(v) => {
                        setError('');
                        setEmail(v);
                    }}
                />
                <Button label="Envoyer" primary onClick={onSubmit} />
            </div>
            <div className="errors">
                <h5 className="text-danger">{error}</h5>
            </div>
        </div>
    );
};

export default ForgotPasswordForm;
