import { IHandler } from '@lutithree/build/Modules/Core/Event/IHandler';
import ARoomStudioHandler from "../../../ARoomStudioHandler";
import {
    EntityPOVChangedEvent
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Cameras/Events/EntityPOVChangedEvent";
import InfoComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/InfoComponent";

export default class ObjectPOVChangedHandler extends ARoomStudioHandler implements IHandler<EntityPOVChangedEvent> {
    public Handle(p_event: EntityPOVChangedEvent): void {
        let infoComponent = p_event.Entity.HasComponentOfType(InfoComponent)? p_event.Entity.GetComponentOfType(InfoComponent):undefined;
        if(infoComponent && infoComponent.Info.Type === 'Wall') {
            this.m_services.ObjectBehaviours.Walls.OnWallViewedFromBehind(p_event.Entity, p_event.Component.IsViewedFromBack);
        }
    }
}
