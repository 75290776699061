import React, { useState } from 'react';
import ToogleIcon from '../../Basics/ToogleIcon';
import switchColorOn from '../../../../Assets/Icons/ContextualMenu/blackWhite-on.svg';
import switchColorOff from '../../../../Assets/Icons/ContextualMenu/blackWhite-off.svg';
import { useSelector } from 'react-redux';
import {RootState} from "../../../../../../services/redux/root-reducers";
import {ProductStudioState} from "../../../../Redux/Reducers/product-studio/ProductStudioReducer";


type SwitchLightDarkProps = {};


const SwitchLightDarkToggle = ({}: SwitchLightDarkProps) => {
    const [active, setActive] = useState<boolean>(false);
    const [brightMode, setBrightMode] = useState<boolean>(true);
    const { productStudioApp } = useSelector<RootState, ProductStudioState>((state) => state.productStudio);

    const handleClick = () => {
        setActive(!active);
        productStudioApp?.Services.BackgroundService.SwitchColorMode(!brightMode);
        setBrightMode(!brightMode);
    };

    return <ToogleIcon imgOn={ switchColorOn } imgOff={ switchColorOff }
                       label='Fond foncé' active={ active } onChange={ handleClick } />;
};

export default SwitchLightDarkToggle;