import React, { createRef } from 'react';
import ConsoleTool from './Console';
import PolyStatsTool from './PolygoneStatistics';
import PerfStatsTool from './PerformanceStatistics';
import Debug from './DebugScripts/Debug';

interface DebugProps {}

interface DebugState {
    useConsole?: boolean;
    usePerfStats?: boolean;
    usePolyStats?: boolean;
}

/* eslint-disable */
export enum LogLevel {
    DEFAULT,
    WARNING,
    ERROR,
    INFO,
}
/* eslint-enable */

export default class DebugReactComponent extends React.Component<DebugProps, DebugState> {
    private ConsoleRef = createRef<ConsoleTool>();

    private PerfToolRef = createRef<PerfStatsTool>();

    private PolyToolRef = createRef<PolyStatsTool>();

    private ConsoleResolve: ((value: React.RefObject<ConsoleTool> | PromiseLike<React.RefObject<ConsoleTool>>) => void) | undefined;

    private PerfToolResolve: ((value: React.RefObject<PerfStatsTool> | PromiseLike<React.RefObject<PerfStatsTool>>) => void) | undefined;

    private PolyToolResolve: ((value: React.RefObject<PolyStatsTool> | PromiseLike<React.RefObject<PolyStatsTool>>) => void) | undefined;

    ConsolePromise = new Promise<React.RefObject<ConsoleTool>>((resolve, reject) => {
        this.ConsoleResolve = resolve;
    });

    PerfToolPromise = new Promise<React.RefObject<PerfStatsTool>>((resolve, reject) => {
        this.PerfToolResolve = resolve;
    });

    PolyStatPromise = new Promise<React.RefObject<PolyStatsTool>>((resolve, reject) => {
        this.PolyToolResolve = resolve;
    });

    componentDidMount() {
        if (this.ConsoleResolve) this.ConsoleResolve(this.ConsoleRef);
        if (this.PerfToolResolve) this.PerfToolResolve(this.PerfToolRef);
        if (this.PolyToolResolve) this.PolyToolResolve(this.PolyToolRef);
    }

    constructor(p_props: DebugProps) {
        super(p_props);
        Debug.SetDebugComponent(this);
        this.state = {
            useConsole: false,
            usePerfStats: false,
            usePolyStats: false,
        };
    }

    render() {
        return (
            <>
                {this.state.useConsole && <ConsoleTool ref={this.ConsoleRef} />}
                {this.state.usePolyStats && <PolyStatsTool ref={this.PolyToolRef} />}
                {this.state.usePerfStats && <PerfStatsTool ref={this.PerfToolRef} />}
            </>
        );
    }
}
