import { IHandler } from '@lutithree/build/Modules/Core/Event/IHandler';
import { Engine } from "@lutithree/build/Engine";
import { AHandler } from "@lutithree/build/Handlers/AHandler";
import ObjectSystem from "../../Composition/ObjectSystem";
import EntityConnectorsChangeEvent from "../../../Features3D/Connectors/Events/EntityConnectorsChangeEvent";
import { ConnectorsComponent } from "../../../Features3D/Connectors/Components/ConnectorsComponent";
import ConnectorSystem from "../../../Features3D/Connectors/Systems/ConnectorSystem";
import Connectivity from "../../../../Domain/Objects/Composition/Connectivity";
import Connector from "../../../../Domain/Objects/Composition/Connector";
import ObjectComponent from "../../Components/ObjectComponent";

export class ObjectConnectorsHandler extends AHandler implements IHandler<EntityConnectorsChangeEvent> {

    private m_connectorsSystem: ConnectorSystem;

    private m_objectSystem: ObjectSystem;

    public constructor(p_engine: Engine){
        super(p_engine);

        this.m_connectorsSystem = new ConnectorSystem(p_engine.Modules.Scene);
        this.m_objectSystem = new ObjectSystem(p_engine.Modules.Scene);
    }

    public Handle(p_event: EntityConnectorsChangeEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');

        if(p_event instanceof EntityConnectorsChangeEvent) this.HandleConnectorsChange(p_event);
    }

    private HandleConnectorsChange(p_event: EntityConnectorsChangeEvent): void{
        let objectComponent = p_event.Entity.HasComponentOfType(ObjectComponent)?p_event.Entity.GetComponentOfType(ObjectComponent):undefined;
        if(objectComponent === undefined) return;

        this.RefresfConnectorAvailability(p_event.Connectivity);
        this.ApplyConnections(p_event.Connectivity);
    }
    
    private RefresfConnectorAvailability(p_connectivity: Connectivity): void{
        p_connectivity.Connectors.forEach((connector)=>{
            let entity = this.m_objectSystem.GetObjectEntity(connector.RefOfInstance);
            if(entity) {
                let allConnectorComponents = this.m_objectSystem.GetComponentOnNestedEntities(entity, ConnectorsComponent);
                let connectorComponent = allConnectorComponents.find(x => x.GetConnectorLocation(connector.Role) != undefined);
                if(connectorComponent){
                    connectorComponent.SetAvailability(connector.Role, connector.IsAvailable);
                }
            }
        });
    }
    
    private ApplyConnections(p_connectivity: Connectivity): void{
        if(p_connectivity.Connections){
            p_connectivity.Connections.forEach((connection)=>{
                let connectorA = p_connectivity.GetConnector(connection.RefOfConnectorA);
                let connectorB = p_connectivity.GetConnector(connection.RefOfConnectorB);
                if(connectorA && connectorB) this.Connect(connectorA, connectorB);
            });
        }
    }
    
    private Connect(p_connectorA: Connector, p_connectorB: Connector): void{
        let entityA = this.m_objectSystem.GetObjectEntity(p_connectorA.RefOfInstance);
        let entityB = this.m_objectSystem.GetObjectEntity(p_connectorB.RefOfInstance);

        if(entityA && entityB){
            let connectorsA = this.m_objectSystem.GetComponentOnNestedEntities(entityA,ConnectorsComponent);
            let connectorsB = this.m_objectSystem.GetComponentOnNestedEntities(entityB,ConnectorsComponent);
            this.m_connectorsSystem.ConnectEntities(entityA,p_connectorA, connectorsA, entityB, p_connectorB, connectorsB);
        }
    }
}
