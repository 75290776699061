export default class MapComparator<T> {

    public GetRemovedKeys(p_group1: Map<string, Array<T>>, p_group2: Map<string, Array<T>>): Map<string, Array<T>> {
        let removedItem = new Map<string, Array<T>>();

        p_group1.forEach((value: Array<T>, key: string) => {
            if (!p_group2.has(key)) {
                removedItem.set(key, value);
            }
        });
        return removedItem;
    }

    public GetAddedKeys(p_group1: Map<string, Array<T>>, p_group2: Map<string, Array<T>>): Map<string, Array<T>> {
        let addedItems = new Map<string, Array<T>>();

        p_group2.forEach((value: Array<T>, key: string) => {
            if (!p_group1.has(key)) {
                addedItems.set(key, value);
            }
        });
        return addedItems;
    }

    public GetSameKeys(p_group1: Map<string, Array<T>>, p_group2: Map<string, Array<T>>): Map<string, Array<T>> {
        let sameItems = new Map<string, Array<T>>();

        p_group2.forEach((value: Array<T>, key: string) => {
            if (p_group1.has(key)) {
                sameItems.set(key, value);
            }
        });
        return sameItems;
    }
}
