import { ASystem } from "@lutithree/build/Modules/Core/Entity/ASystem";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { IComponent } from "@lutithree/build/Modules/Core/Entity/IComponent";
import ObjectComponent from "../Components/ObjectComponent";

export default class ObjectSystem extends ASystem<SceneEntity> {

    public GetAllObjectRefs(): string[] {
        let refs : string[] = [];
        let objectComponents = this.m_entityManager.GetComponents(ObjectComponent, { entity: false, component:false });
        objectComponents.forEach((component)=>{
            refs.push(component.Ref);
        });
        return refs;
    }

    public GetObjectEntity(p_refOfInstance: string): SceneEntity | undefined {
        if (!p_refOfInstance) throw new Error('NullReferenceException : p_refOfInstance is null or undefined or empty');
        
        let entity: SceneEntity | undefined = undefined;
        let objectComponents = this.m_entityManager.GetComponents(ObjectComponent, { entity: false, component: false });
        objectComponents.forEach((objComponent) => {
            if (objComponent.Ref === p_refOfInstance) {
                entity = this.m_entityManager.GetEntityByID(objComponent.EntityID);
                return entity;
            }
        });

        return entity;
    }

    public GetObjectNestedRefs(p_refOfInstance: string): string[]{
        if (!p_refOfInstance) throw new Error('NullReferenceException : p_refOfInstance is null or undefined or empty');

        let nestedRef: string[] = [];
        let objectEntity = this.GetObjectEntity(p_refOfInstance);
        if(objectEntity){
            let objectComponents = this.GetComponentOnNestedEntities(objectEntity, ObjectComponent);
            objectComponents.forEach((objectComponent)=>{
                nestedRef.push(objectComponent.Ref);
            });
        } 
        return nestedRef;
    }

    public GetNestedPartEntities(p_objectComponent: ObjectComponent): Array<SceneEntity> {
        if (p_objectComponent == null ) throw new Error('NullReferenceException : p_objectComponent is null or undefined');
        
        let nestedPartEntities = new Array<SceneEntity>();
        p_objectComponent.PartIds.forEach((partId)=>{
            
            let tempEntity = this.m_entityManager.GetEntityByID(partId);
            nestedPartEntities.push(tempEntity);
           
            let objectComponent = tempEntity.HasComponentOfType(ObjectComponent)?tempEntity.GetComponentOfType(ObjectComponent):undefined;
            if(objectComponent)
                this.GetNestedPartEntities(objectComponent).forEach((entity)=>{
                    nestedPartEntities.push(entity);
                });
        });
        
        return nestedPartEntities;
    };

    public GetComponentOnNestedEntities<C extends IComponent>(p_entity: SceneEntity, p_classNames: { new (...args: any[]): C }): Array<C> {
        if (p_entity == null ) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_classNames == null ) throw new Error('NullReferenceException : p_classNames is null or undefined');

        let objectComponent = p_entity.HasComponentOfType(ObjectComponent) ? p_entity.GetComponentOfType(ObjectComponent):undefined;
        if(objectComponent) {
            let nestedPartEntities = this.GetNestedPartEntities(objectComponent);
            return this.m_entityManager.GetComponentsFromEntities(p_classNames, nestedPartEntities);
        }
        return [];
    };
}