import ShapeData from './ShapeData';
import { Option } from './Option';
import GeometryData from './GeometryData';
import { Asset3DData } from './Asset3DData';
import AssignmentData from './AssignmentData';
import Model3dData from './Model3dData';
import { IPartElement } from '../Composition/IPartElement';

export default class Asset3D implements IPartElement {
    private dataModelVersion: string;

    private refOfPart: string;

    type: string;

    private refOfPartItem: string;

    private datas?: Asset3DData;

    private options: Array<Option>;

    public constructor() {
        this.dataModelVersion = '';
        this.refOfPart = '';
        this.refOfPartItem = '';
        this.type = '';
        //this.datas = new Array<string>();
        this.options = new Array<Option>();
    }

    public get Ref(): string {
        return this.refOfPart;
    }

    public get RefOfPartItem(): string {
        return this.refOfPartItem;
    }

    public get RefOfPart(): string {
        return this.refOfPart;
    }

    public get Type(): string {
        return this.type;
    }

    public get Datas(): Asset3DData|undefined {
        return this.datas;
    }

    public set Datas(p_data: Asset3DData|undefined) {
        this.datas = p_data;
    }

    public get Options(): Array<Option> {
        return this.options;
    }

    public set Options(p_options: Array<Option>) {
        this.options = p_options;
    }

    public static IsDataCompatibleWithAssetType(p_data: Asset3DData, p_type: string): boolean {
        if (p_data == null) throw new Error('NullReferenceException : p_data is null or undefined');
        if (!p_type) throw new Error('NullReferenceException : p_data is null or undefined or empty');

        switch (p_type) {
            case 'Model': {
                if (Array.isArray(p_data)) {
                    p_data.forEach((item) => {
                        if (typeof item !== 'string') return false;
                    });
                    return true;
                } else return false;
            }
            case 'Material': {
                if (typeof p_data === 'string') return true;
                else if (Array.isArray(p_data)) {
                    p_data.forEach((item) => {
                        if (typeof item !== 'string') return false;
                    });
                    return true;
                } else return false;
                break;
            }
            case 'Shape': {
                return ShapeData.IsShapeData(p_data);
            }
            case 'Geometry': {
                return GeometryData.IsGeometryData(p_data);
            }
            case 'MaterialAssignment': {
                if (Array.isArray(p_data)) {
                    p_data.forEach((item) => {
                        if (!AssignmentData.IsAssignmentData(item)) return false;
                    });
                    return true;
                } else return false;
            }
            case 'Model3d': {
                return Model3dData.IsModel3dData(p_data);
            }
            default:
                throw new Error('Incompatible type error: asset type (' + p_type + ') & asset data type (' + p_data + ') mismatch : ');
        }
    }

    public static IsEquals(p_asset1: Asset3D, p_asset2: Asset3D): boolean {
        if (p_asset1 == null) throw new Error('NullReferenceException : p_asset1 is null or undefined');
        if (p_asset2 == null) throw new Error('NullReferenceException : p_asset2 is null or undefined');

        if (p_asset1.refOfPart !== p_asset2.refOfPart) return false;
        if (p_asset1.type !== p_asset2.type) return false;

        if (Array.isArray(p_asset1.datas) && Array.isArray(p_asset2.datas)) {
            if (p_asset1.datas.length !== p_asset2.datas.length) return false;

            for (let i: number = 0; i < p_asset1.datas.length; i++) {
                if (p_asset1.datas[i] !== p_asset2.datas[i]) return false;
                if (p_asset1.type === 'MaterialAssignment') {
                    return AssignmentData.IsEquals(p_asset1.datas[i] as AssignmentData, p_asset2.datas[i] as AssignmentData);
                }
            }
        } else if (p_asset1.type === 'Shape') {
            return ShapeData.IsEquals(p_asset1.datas as ShapeData, p_asset2.datas as ShapeData);
        } else if (p_asset1.type === 'Geometry') {
            return GeometryData.IsEquals(p_asset1.datas as GeometryData, p_asset2.datas as GeometryData);
        } else if (p_asset1.type === 'Model3d') {
            return Model3dData.IsEquals(p_asset1.datas as Model3dData, p_asset2.datas as Model3dData);
        } else {
            if (p_asset1.datas !== p_asset2.datas) return false;
        }

        return true;
    }

    public static GetAssetsByType(p_assets: Array<Asset3D>): Map<string, Array<Asset3D>> {
        if (p_assets == null) throw new Error('NullReferenceException : p_assetsDescription is null or undefined');

        let assetsGroups: Map<string, Array<Asset3D>> = new Map<string, Array<Asset3D>>();
        for (let i: number = 0; i < p_assets.length; i++) {
            if (assetsGroups.has(p_assets[i].type)) {
                assetsGroups.get(p_assets[i].type)!.push(p_assets[i]);
            } else {
                assetsGroups.set(p_assets[i].type, [p_assets[i]]);
            }
        }
        return assetsGroups;
    }

    public static IsArrayEqual(p_goup1: Array<Asset3D>, p_group2: Array<Asset3D>): boolean {
        if (p_goup1 == null) throw new Error('NullReferenceException : p_lastConfig is null or undefined');
        if (p_group2 == null) throw new Error('NullReferenceException : p_newConfig is null or undefined');

        if (p_goup1.length !== p_group2.length) return false;
        for (let i = 0; i < p_goup1.length; i++) {
            if (!this.IsEquals(p_goup1[i], p_group2[i])) {
                return false;
            }
        }
        return true;
    }
}
