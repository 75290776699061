import AService from "../../Domain/AService";
import { IObjectDecorator } from "../../Domain/Objects/AssetAssembly/IObjectDecorator";
import BasicObject from "../../Domain/Objects/AssetAssembly/BasicObject";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { GroupComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/GroupComponent";
import ObjectSystem from "./Composition/ObjectSystem";
import { Engine } from "@lutithree/build/Engine";
import Instance3D from "../../Domain/Objects/AssetAssembly/Instance3D";
import ObjectComponent from "./Components/ObjectComponent";
import PartComponent from "./Components/PartComponent";

export class DefaultObjectDecorator extends AService implements IObjectDecorator {
    private m_objectSystem: ObjectSystem;
    
    public constructor(p_engine: Engine) {
        super(p_engine);
        this.m_objectSystem = new ObjectSystem(p_engine.Modules.Scene);
    }
    
    public DecorateRootObject(p_entity: SceneEntity, p_object: BasicObject, p_instance: Instance3D | undefined): void {
        if (p_object == null) throw new Error('NullReferenceException : p_object is null or undefined');
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        let objectComponent = p_entity.AddComponentOfType(ObjectComponent, p_object.RefOfInstance);
    }

    public DecorateObject(p_entity: SceneEntity, p_object: BasicObject, p_instance: Instance3D | undefined): void {
        if (p_object == null) throw new Error('NullReferenceException : p_object is null or undefined');
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        let objectComponent = p_entity.AddComponentOfType(ObjectComponent, p_object.RefOfInstance);
        p_entity.AddComponentOfType(GroupComponent, p_object.RefOfInstance);
    }

    public DecoratePart(p_entity: SceneEntity, p_parentObject: BasicObject, p_parentEntity:SceneEntity, p_refOfPart: string): void {
        if (!p_refOfPart) throw new Error('NullReferenceException : p_refOfPart is null or undefined or empty');
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (!p_parentObject) throw new Error('NullReferenceException : p_parentObject is null or undefined or empty');

        if(p_parentObject.Assets.length > 0) {/** @deprecated basicObject.assets should not be used*/
            p_entity.AddComponentOfType(PartComponent, ()=>{return p_parentObject.Ref;}, p_refOfPart, 'Asset3D');
        }
        else {
            p_entity.AddComponentOfType(PartComponent, ()=>{return p_parentObject.Ref;}, p_refOfPart, p_parentObject.Composition.Type);
        }
       
        if(!p_entity.HasComponentOfType(GroupComponent)) p_entity.AddComponentOfType(GroupComponent, p_parentObject.Ref);
        if (p_entity.HasComponentOfType(ObjectComponent))
            this.m_objectSystem.GetNestedPartEntities(p_entity.GetComponentOfType(ObjectComponent)).forEach((entity) => {
                entity.AddComponentOfType(GroupComponent, p_parentObject.RefOfInstance);
            });
    }
}