import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import ButtonNavigate from '../../../../ui-components/ButtonNavigate';
import CardMediaWithLabel from '../../CardAction/CardMediaWithLabel';
import colonne from './../../../../asset/colonne@2x.png';
import cloison from './../../../../asset/cloison@2x.png';
import { addObjects } from '../../../../application-3d/application3D-seller/Redux/Reducers/room-studio/RoomContentReducer';
import { ObjectParser } from '../../../../application-3d/application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/ObjectParser';
import BasicObject from '../../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject';


type DataProps = {
    name: string;
    url: string;
    modelUrl: string;
    code: number
};

type ConstructPanelProps = {
    setOpenPanel: (openPanel: boolean) => void
    setOpenAddElementPanel: (openAddElementPanel: boolean) => void
    setConstructPanel: (constructPanel: boolean) => void
};

const data: DataProps[] = [
    {
        name: 'Colonne',
        url: colonne,
        modelUrl: 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/construction-elements/BuildingBlock_Cylinder.json',
        code: 11,
    },
    {
        name: 'Cloison',
        url: cloison,
        modelUrl: 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/construction-elements/BuildingBlock_Cube.json',
        code: 111,
    },
];

const ConstructPanel = (props: ConstructPanelProps) => {
    const dispatch = useDispatch();

    const getModelFromUrl = async (url: string) => {
        try {
            let response = await fetch(url);
            return await response.json();
        } catch (error) {
            console.error(error);
            return undefined;
        }
    };

    const displayConstructionElement = async (url: string) => {
        const model = await getModelFromUrl(url);
        model.refOfInstance = uuidv4();
        dispatch(addObjects(ObjectParser.DeepParseObject(model, BasicObject)));
    };

    return (
        <>
            <ButtonNavigate label='Construction' onClick={ () => props.setConstructPanel(false) } />
            <Box sx={ { pt: 3 } }>
                <Grid container spacing={ 2 }>
                    { data.map((el, index: number) => {
                        return (
                            <Grid item xs={ 12 } md={ 6 } key={ el.code + index }>
                                <CardMediaWithLabel
                                    onClick={ () => {
                                        displayConstructionElement(el.modelUrl);
                                        props.setOpenPanel(false);
                                        props.setOpenAddElementPanel(false);
                                    } }
                                    media={ el.url }
                                    label={ el.name }
                                />
                            </Grid>
                        );
                    }) }
                </Grid>
            </Box>
        </>
    );
};

export default ConstructPanel;