import { DisableComponent } from '@lutithree/build/Modules/Core/Entity/DisableComponent';

export class ScalableComponent extends DisableComponent {
    protected readonly m_onObject3DScaledCallbacks: Map<string, () => void>;

    public constructor() {
        super();
        this.m_onObject3DScaledCallbacks = new Map<string, () => void>();
    }

    public OnObject3DScaled(): void {
        this.m_onObject3DScaledCallbacks.forEach((callback, key) => {
            callback();
        });
    }

    public AddOnObject3DScaledCallback(p_key: string, p_callback: () => void): void {
        if (!p_key) throw new Error('NullReferenceException : p_key is null or undefined or empty');
        if (p_callback == null) throw new Error('NullReferenceException : p_callback is null or undefined');

        if (!this.m_onObject3DScaledCallbacks.has(p_key)) this.m_onObject3DScaledCallbacks.set(p_key, p_callback);
    }
}
