import { AHandler } from "@lutithree/build/Handlers/AHandler";
import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import { EntityRaycastedEvent } from "@lutithree/build/Modules/WebGL/Scene/Events/EntityRaycastedEvent";
import { Engine } from "@lutithree/build/Engine";
import { Vector3 } from "three";
import { MarkerComponent } from "../Measuring/Components/MarkerComponent";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import MeasureDrawerService from "../Measuring/MeasureDrawerService";

export default class MeasuresHandler extends AHandler implements IHandler<EntityRaycastedEvent> {
    
    private m_measureService: MeasureDrawerService;

    
    public constructor(p_engine: Engine, p_measureDrawerService: MeasureDrawerService){
        super(p_engine);

        this.m_measureService = p_measureDrawerService;
    }
    
    public Handle(p_event: EntityRaycastedEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');
        
        let maxPoints = undefined;
        let measureDrawer = this.m_measureService.FindMeasureDrawer();
        if(measureDrawer) maxPoints = measureDrawer.NBPointsMax;
        
        let nbMarkers = this.m_engine.Modules.Scene.GetComponents(MarkerComponent).length;
        if((maxPoints && nbMarkers<maxPoints)||maxPoints==undefined){
            // Add marker 
            this.AddMarker(p_event);
        }
        
        this.m_engine.Modules.LoopStrategy.RequestRender(true);
    }
    
    private AddMarker(p_event: EntityRaycastedEvent): SceneEntity {
        let worldPosition = new Vector3();
        let normal = new Vector3();

        p_event.Entity.Transform.GetObject().getWorldPosition(worldPosition);
        if(p_event.IntersectionFace && p_event.IntersectionFace.normal) {
            normal = p_event.IntersectionFace.normal.clone().applyMatrix4(p_event.Entity.Transform.GetObject().matrixWorld).sub(worldPosition.clone());
        }
        return this.m_measureService.AddMarker(p_event.IntersectionPoint, normal);
    }
}