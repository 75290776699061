import React from 'react';
import TakeSnapshotButton from './TakeSnapshot';
import { useSelector } from 'react-redux';
import DrawMeasureButton from './DrawMeasureButton/DrawMeasureButton';
import MultipleSelectionButton from './MultipleSelectionButton';
import { Box, styled } from '@mui/material';
import RoomStudioApp from "../../../../Libraries/Studios/RoomStudio/RoomStudioApp";
import {RoomStudioMode} from "../../../../Libraries/Studios/RoomStudio/Domain/Modes/RoomStudioMode";
import {ViewMode} from "../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Cameras/ViewMode";
import PaperLayout from "../../../../../application3D-common/Components/UI/Composites/PaperLayout";
import {RootState} from "../../../../../../services/redux/root-reducers";
import {RoomStudioState} from "../../../../Redux/Reducers/room-studio/RoomStudioReducer";


export type TopBarProps = {
    roomStudio: RoomStudioApp;
    mode: RoomStudioMode | undefined;
    viewMode: ViewMode | undefined;
};

const TopBar = (props: TopBarProps) => {
    const { currentEntity } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);

    return (
        <StyledBoxTopBar>
            <PaperLayout flexDirection='row' elevation={ 2 }>
                <TakeSnapshotButton roomStudio={ props.roomStudio } />
                { props.mode === RoomStudioMode.Furnishing && (
                    <>
                        <DrawMeasureButton roomStudio={ props.roomStudio } />
                        <MultipleSelectionButton mode={ props.mode } viewMode={ props.viewMode } />
                    </>
                ) }
            </PaperLayout>
        </StyledBoxTopBar>
    );
};

const StyledBoxTopBar = styled(Box)(() => ({
    position: 'fixed',
    left: '1%',
    top: '5em',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
}));

export default TopBar;
