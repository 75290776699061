import { jsPDF } from 'jspdf';
import dateFormatter from './date-formatter';
import moment from 'moment';
import { BasketProduct, PresetTree } from '../domain/domain';

type PdfGeneratorProps = {
    payload: any;
    project: any;
    description: string;
    basketProducts: BasketProduct[];
};

const showBasket = (doc: jsPDF, basketProductList: BasketProduct[], x: number, y: number) => {
    const firstIndent = x + 3;
    const secondIndent = x + 6;
    const thirdIndent = x + 9;
    let currentY = y;
    basketProductList.forEach((bp) => {
        doc.text(`Produit : ${bp.name}`, firstIndent, currentY);
        currentY += 1.5;
        doc.text(`Référence : ${bp.productReference}`, firstIndent, currentY);
        currentY += 1.5;
        doc.text(`Type de produit : ${bp.productType === 'PRESET' ? 'Configurable' : bp.productType}`, firstIndent, currentY);
        currentY += 1.5;
        if (bp.productType === 'PRESET' || bp.productType === 'CONFIGURABLE') {
            const presetTree = bp.productContent as PresetTree;
            doc.text(`Configuration :`, firstIndent, currentY);
            currentY += 1.5;
            presetTree.configuration.forEach((atom) => {
                doc.text(`- ${atom.atom.partName} : ${atom.atom.partItemName}`, secondIndent, currentY);
                currentY += 1.5;
                doc.text(`- ${atom.atom.label}`, thirdIndent, currentY);
                currentY += 1.5;
            });
            if (presetTree.mappingConfiguration.length > 0) {
                doc.text(`Habillages :`, firstIndent, currentY);
                currentY += 1.5;
                presetTree.mappingConfiguration.forEach((atom) => {
                    doc.text(`- ${atom.atom.partName} : ${atom.atom.partItemName}`, secondIndent, currentY);
                    currentY += 1.5;
                    doc.text(`- ${atom.atom.label}`, thirdIndent, currentY);
                    currentY += 1.5;
                });
            }
        }
        doc.text(`Prix : ${(bp.priceInCents / 100).toFixed(2)} €`, firstIndent, currentY);
        currentY += 1.5;
        doc.text('-------------------------------', firstIndent, currentY);
        currentY += 3;
    });
    return currentY;
};

const showTotal = (doc: jsPDF, basketProducts: BasketProduct[], x: number, y: number) => {
    const currentY = y;
    doc.setFontSize(18);
    let totalPrice = 0;
    basketProducts.forEach((el) => {
        totalPrice += el.priceInCents;
    });
    doc.text(`Total : ${(totalPrice / 100).toFixed(2)} €`, x, y);
    return currentY;
};

export const PdfGenerator = ({ payload, project, description, basketProducts }: PdfGeneratorProps) => {
    const doc = new jsPDF({ orientation: 'portrait', unit: 'em', format: 'a4' });

    doc.setFont('Montserrat');
    doc.addFont('', '', '', 800);
    doc.setFontSize(35);
    doc.text('Configuration Projet', 13, 4);

    doc.addFont('', '', '', 400);
    doc.setFontSize(23);
    doc.text(`Récapitulatif de la piéce : `, 5, 10);

    doc.setFontSize(18);
    doc.text(`- Projet : ${payload.content?.projectName}`, 5, 13);
    doc.text(`- Piéce : ${payload.content?.room.name}`, 5, 16);
    doc.text(`- Code : ${payload.content?.projectCode}`, 5, 19);
    doc.text(`- Crée le : ${dateFormatter.dateTimeStampToDateTime(project.payload.content?.createdAt)} `, 5, 22);
    doc.text(`- Modifié le : ${dateFormatter.dateTimeStampToDateTime(project.payload.content?.updatedAt)} `, 5, 25);

    doc.text(`- Déscription : ${description !== undefined ? description : "Cette piéce n'as pas encore de description"}`, 5, 28);
    doc.text(`- Capture d'écran : Aucune capture d'écran `, 5, 31);

    doc.addPage();
    doc.setFontSize(23);
    doc.text(`Panier : `, 5, 10);
    doc.setFontSize(15);
    let currentY = showBasket(doc, basketProducts, 5, 13);
    currentY += 3;
    currentY = showTotal(doc, basketProducts, 5, currentY);
    doc.save(`${moment(Date.now()).format('DD-MM-YY')}/${payload.content?.projectName}/${payload.content?.room.name}.pdf`);
};
