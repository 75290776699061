import { Vector2 } from 'three';
import { Asset3DData } from './Asset3DData';
import PhysicalAssetData from "./PhysicalAssetData";

export default class ShapeData extends PhysicalAssetData {
    private dataModelVersion: string;

    private points: Vector2[];

    private refOfSections: string[];

    public constructor() {
        super();
        this.dataModelVersion = '';
        this.points = new Array<Vector2>();
        this.refOfSections = [];
    }
    
    public get RefOfSections() : string[] {
        return this.refOfSections;
    }

    public set RefOfSections(p_refOfSections: string[]) {
        this.refOfSections = p_refOfSections;
    }

    public get Points() : Vector2[] {
        return this.points;
    }
    
    public set Points(p_points: Vector2[]) {
        this.points = p_points;
    }
    

    public static IsEquals(p_shape1: ShapeData, p_shape2: ShapeData): boolean {
        if (p_shape1 == null) throw new Error('NullReferenceException : p_shape1 is null or undefined');
        if (p_shape2 == null) throw new Error('NullReferenceException : p_shape2 is null or undefined');

        if (p_shape1.points.length !== p_shape2.points.length) return false;

        for (let i: number = 0; i < p_shape1.points.length; i++) {
            if (p_shape1.points[i] !== p_shape1.points[i]) return false;
        }

        return true;
    }

    public static IsShapeData(data: Asset3DData): data is ShapeData {
        let shapeData = data as ShapeData;
        let hasPoints = shapeData.points !== undefined;
        let hasRefOfSection = shapeData.refOfSections !== undefined;
        return hasPoints && hasRefOfSection;
    }
}
