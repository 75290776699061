import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import camera from '../../../../../Assets/Icons/camera.svg';
import { Vector2 } from 'three';
import { useParams } from 'react-router-dom';
import { Box, styled, Typography, Alert, Snackbar } from '@mui/material';
import RoomStudioApp from "../../../../../Libraries/Studios/RoomStudio/RoomStudioApp";
import ButtonToggleRoom from "../../../../../../application3D-common/Components/UI/Composites/ButtonToggleRoom";
import {RoomStudioState} from "../../../../../Redux/Reducers/room-studio/RoomStudioReducer";
import {
    addScreenshot,
    clearScreenshots,
    RoomContentState
} from "../../../../../Redux/Reducers/room-studio/RoomContentReducer";
import {RootState} from "../../../../../../../services/redux/root-reducers";
import {postFile} from "../../../../../../../utils/upload-file-method";
import {ADD_SCREENSHOT_TO_ROOM} from "../../../../../../../api/enpoint/room/screenshot";


export type TakeSnapshotProps = {
    roomStudio: RoomStudioApp;
};

const TakeSnapshotButton = ({ roomStudio }: TakeSnapshotProps) => {
    const dispatch = useDispatch();
    const { projectId, uuid } = useParams();
    const { roomStudioApp } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const snapshot = useSelector<RootState, RoomContentState>((state) => state.roomContent);
    const [openSnackBar, setOpenSnackBar] = useState(false);

    useEffect(() => {
        const snap = snapshot.screenshots[0];
        if (snap && projectId && uuid) {
            const file = new FormData();
            file.append('file', snap);
            postFile(ADD_SCREENSHOT_TO_ROOM(parseInt(projectId), uuid), file).then(res => {
                // @ts-ignore
                if (res && res.content) {
                    setOpenSnackBar(true);
                }
            });
            dispatch(clearScreenshots());
            if (roomStudioApp) roomStudioApp.EngineService.Resize();
        }
    }, [snapshot]);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };


    return (
        <>
            <StyledBoxButtonGroup
                aria-label='text alignment'
            >
                <ButtonToggleRoom
                    text={
                        <Typography variant='subtitle2' color='primary'>
                            Capture <br />d'écran
                        </Typography>
                    }
                    altLogo='appareil photo' logo={ camera }
                    actions={ () => {
                        roomStudio.Services.Screenshot.Take3DSnapshot(new Vector2(1920, 1080)).then((blob) => {
                            dispatch(addScreenshot(blob));
                        });
                    } }
                />
            </StyledBoxButtonGroup>
            <Snackbar anchorOrigin={ { vertical: 'bottom', horizontal: 'right' } } open={ openSnackBar }
                      autoHideDuration={ 5000 } onClose={ handleClose }>
                <Alert onClose={ handleClose } severity='success' sx={ { width: '100%' } }>
                    Capture d'écran sauvegardée
                </Alert>
            </Snackbar>
        </>
    );
};

const StyledBoxButtonGroup = styled(Box)(({ theme }) => ({
    margin: theme.spacing(0.5),
    height: 65,
    minWidth: 80,
    '& .Mui-disabled': {
        border: 0,
    },
    '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
    },
}));

export default TakeSnapshotButton;
