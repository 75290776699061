import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { BufferGeometry, Event, Group, Material, Object3D } from 'three';
import { MeshFilterComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent';
import { ModelLoader } from '@lutithree/build/Modules/WebGL/Resources/Load/ModelLoader';
import Asset3D from "../../../../../Domain/Objects/Assets/Asset3D";
import EntityMeshController from "../../EntityMeshController";
import {IAssetDecorator} from "../../../../../Domain/Objects/AssetAssembly/IAssetDecorator";
import { computeBoundsTree } from 'three-mesh-bvh';

BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;

export class ModelDecorator implements IAssetDecorator {
    private readonly m_entityMeshController: EntityMeshController;

    protected readonly m_errorModelAssetPath: string = "https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/models/Model_ERROR.glb";
    
    protected m_getErrorModelAsset: Promise<Group>;
    
    public constructor() {
        this.m_entityMeshController = new EntityMeshController();

        let modelAsset = new ModelLoader(this.m_errorModelAssetPath);
        this.m_getErrorModelAsset = modelAsset.LoadAsync();
    }

    public DecorateAsset(p_entity: SceneEntity, p_asset: Asset3D, p_levelLoadedCallback: (p_resource: Group | Material | Material[] | Object3D<Event>) => void): Promise<void> {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_asset == null) throw new Error('NullReferenceException : p_asset is null or undefined');
        if (p_levelLoadedCallback == null) throw new Error('NullReferenceException : p_levelLoadedCallback is null or undefined');

        let lastModel: Group | undefined = undefined;
        let asset = p_asset;
        let addNewLevel = (p_model: Group, p_level: number) => {

            // TODO remove model type & replace with model3d
            //if (asset.transform) Object3DUtils.ApplyTransform(p_model, asset.transform);
            let meshFilter: MeshFilterComponent = this.AddOrUpdateModel(p_entity, p_model, lastModel, false, p_levelLoadedCallback);
            
            // options
            if (meshFilter && p_level === 0) {
                if (asset.Options) {
                    this.m_entityMeshController.SetOptions(p_entity, asset.Options, meshFilter);
                }
            }
            lastModel = p_model;
        };

        return new Promise<void>((resolve, reject) => {
            let modelAsset = new ModelLoader(asset.Datas as string[]);
            modelAsset.AddOnNewLevelLoadedCallback(addNewLevel);
            modelAsset.LoadAsync().then((model) => {
                // TODO remove model type & replace with model3d
                //if (this.m_asset.pivot) Object3DUtils.ApplyPivot(model, this.m_asset.pivot); 
                resolve();
            },
                (reason)=>{
                    this.m_getErrorModelAsset.then((model)=>{
                        this.AddOrUpdateModel(p_entity, model.clone(), lastModel, true, p_levelLoadedCallback);
                        lastModel = model;
                    });
                    reject(reason);
                });
        });
    }
    
    
    private AddOrUpdateModel(p_entity: SceneEntity, p_model: Group, p_lastModel: Group|undefined, p_isSelfSufficientInMaterial: boolean, p_levelLoadedCallback: (p_resource: Material | Material[] | Group | Object3D<Event>) => void): MeshFilterComponent{
        let meshFilter: MeshFilterComponent | undefined = undefined;
        if (p_lastModel !== undefined) {
            meshFilter = this.m_entityMeshController.FindMeshFilterWithModel(p_entity, p_lastModel);
            if (meshFilter) {
                meshFilter.IsSelfSufficientInMaterial = p_isSelfSufficientInMaterial;
                meshFilter.Model = p_model;
            }
            p_levelLoadedCallback(p_lastModel);
        } else {
            meshFilter = this.m_entityMeshController.AddMeshFilter(p_entity, p_model, p_isSelfSufficientInMaterial);
        }

        if(meshFilter){
            meshFilter.GetMeshes().forEach((mesh)=>{
                mesh.geometry.computeBoundsTree();
            });
        }

        return meshFilter!;
    }
    
}
