import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import {IAssetDecorator} from "../../Domain/Objects/AssetAssembly/IAssetDecorator";
import Asset3D from "../../Domain/Objects/Assets/Asset3D";
import { FrontSide, Group, Material, MeshPhysicalMaterial, Object3D } from "three";
import { ShapeDecorator } from "./AssetAssembly/EntityDecorators/Assets/ShapeDecorator";
import { GeometryDecorator } from "./AssetAssembly/EntityDecorators/Assets/GeometryDecorator";
import MaterialAssignmentDecorator from "./AssetAssembly/EntityDecorators/Assets/MaterialAssignmentDecorator";
import { MaterialDecorator } from "./AssetAssembly/EntityDecorators/Assets/MaterialDecorator";
import { Model3dDecorator } from "./AssetAssembly/EntityDecorators/Assets/Model3dDecorator";
import { ModelDecorator } from "./AssetAssembly/EntityDecorators/Assets/ModelDecorator";

export class DefaultAssetDecorator implements IAssetDecorator {

    private static readonly m_defaultMaterial: MeshPhysicalMaterial = new MeshPhysicalMaterial({ color: 0xffffff, side: FrontSide });
    
    private m_decoratorByType: Map<string, IAssetDecorator>;
    
    public constructor(){
        this.m_decoratorByType = new Map<string, IAssetDecorator>();
        this.m_decoratorByType.set('Model', new ModelDecorator());
        this.m_decoratorByType.set('Model3d', new Model3dDecorator());
        this.m_decoratorByType.set('Material', new MaterialDecorator(DefaultAssetDecorator.m_defaultMaterial));
        this.m_decoratorByType.set('MaterialAssignment', new MaterialAssignmentDecorator(DefaultAssetDecorator.m_defaultMaterial));
        this.m_decoratorByType.set('Geometry', new GeometryDecorator(DefaultAssetDecorator.m_defaultMaterial));
        this.m_decoratorByType.set('Shape', new ShapeDecorator(DefaultAssetDecorator.m_defaultMaterial));
    }
    
    public DecorateAsset(p_entity: SceneEntity, p_asset: Asset3D, p_levelLoadedCallback: (p_resource: Material | Material[] | Group | Object3D) => void): Promise<void> {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        let decorator = this.m_decoratorByType.get(p_asset.Type);
        if(decorator) return decorator.DecorateAsset(p_entity, p_asset, p_levelLoadedCallback);
        else return new Promise<void>((resolve)=>resolve);
    }
}
