import { MeshFilterComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent';
import { Object3D } from 'three';
import { AComponent } from '@lutithree/build/Modules/Core/Entity/AComponent';
import {Option} from "../../../Domain/Objects/Assets/Option";

export class OptionsComponent extends AComponent {
    private m_options: Map<Option, MeshFilterComponent>;

    public constructor(p_parent: Object3D) {
        super();

        this.m_options = new Map<Option, MeshFilterComponent>();
    }

    public Execute(p_optionRef: string, p_value: string): void {
        if (!p_optionRef) throw new Error('NullReferenceException : p_optionRef is null or undefined or empty');
        if (!p_value) throw new Error('NullReferenceException : p_value is null or undefined or empty');

        let option: Option | undefined = Array.from(this.m_options.keys()).find((opt) => opt.Name === p_optionRef && opt.Value.Content === p_value);
        if (option) {
            if (option.Type === 'Switch') this.ExecuteSwitchOption(option);
        }
    }

    public Add(p_option: Option, p_object: MeshFilterComponent): void {
        if (!p_option) throw new Error('NullReferenceException : p_optionRef is null or undefined or empty');
        if (p_object == null) throw new Error('NullReferenceException : p_object is null or undefined');

        if (!this.AlreadyContainOption(p_option)) {
            this.m_options.set(p_option, p_object);
            p_object.Enable(p_option.Value.IsDefault);
        }
    }

    public Remove(p_optionRef: string): void {
        if (!p_optionRef) throw new Error('NullReferenceException : p_optionRef is null or undefined or empty');

        let option: Option | undefined = Array.from(this.m_options.keys()).find((opt) => opt.Name === p_optionRef);
        if (option) {
            this.m_options.delete(option);
        }
    }

    private DisableAllValues(p_optionRef: string, p_type: string): void {
        if (!p_optionRef) throw new Error('NullReferenceException : p_optionRef is null or undefined or empty');

        let resultingOptions: Option[] = Array.from(this.m_options.keys()).filter((opt) => opt.Name === p_optionRef && opt.Type === p_type);
        resultingOptions.forEach((option) => {
            this.m_options.get(option)!.Enable(false);
        });
    }

    private ExecuteSwitchOption(p_option: Option): void {
        this.DisableAllValues(p_option.Name, p_option.Type);
        this.m_options.get(p_option)!.Enable(true);
    }

    private AlreadyContainOption(p_option: Option): boolean {
        // @ts-ignore
        let result: boolean = [...this.m_options.keys()].some((option) => {
            this.Equals(p_option, option);
        });
        return result;
    }

    private Equals(p_option1: Option, p_option2: Option): boolean {
        if (p_option1 == null) throw new Error('NullReferenceException : p_option1 is null or undefined');
        if (p_option1.Name.match(p_option1.Name) === null && p_option1.Type.match(p_option2.Type) === null && p_option1.Value.Content.match(p_option2.Value.Content) === null) {
            return true;
        } else return false;
    }
}
