import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import editOn from '../../../../../application3D-common/Assets/Icons/ContextualMenu/Planner_dimensions-on.svg';
import editOff from '../../../../../application3D-common/Assets/Icons/ContextualMenu/Planner_dimensions-off.svg';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import ToogleIcon from "../../../../../application3D-common/Components/UI/Basics/ToogleIcon";
import {
    ContextualMenuState, setDuplcateIcon,
    setPaintIcon,
    setRemplaceIcon,
    setScaleIcon
} from "../../../../Redux/Reducers/ContextualMenuReducer";
import {
    OpenPrimitivePaintPanel,
    OpenPrimitivePanel,
    PanelState
} from "../../../../../../services/redux/reducers/panel/panel.slice";
import {RootState} from "../../../../../../services/redux/root-reducers";


export type ScaleToogleProps = {
    entity: SceneEntity | undefined;
    setOpenPanel: (openPanel: boolean) => void;
    openPanel: boolean;
};

const ScaleToggle = (props: ScaleToogleProps) => {
    const dispatch = useDispatch();
    const [isOn, setIsOn] = useState<boolean>(false);
    const primitivePanelRes = useSelector<RootState, PanelState>((state) => state.panel);
    const icon = useSelector<RootState, ContextualMenuState>((state) => state.contextualMenu);

    const onChange = () => {
        setIsOn(!isOn);
        dispatch(setScaleIcon(!icon.scaleIcon));
        dispatch(setPaintIcon(false));
        dispatch(setRemplaceIcon(false));
        dispatch(setDuplcateIcon(false));
        dispatch(OpenPrimitivePaintPanel(false));
    };

    useEffect(() => {
        !icon.scaleIcon && setIsOn(false);
    }, [icon.scaleIcon]);

    useEffect(() => {
        if (isOn != undefined) {
            dispatch(OpenPrimitivePanel(isOn));
            props.setOpenPanel(isOn);
        }
    }, [isOn]);

    return (
        <>
            <ToogleIcon imgOn={ editOn } imgOff={ editOff }
                        label={ `Modifier \n les dimension` }
                        active={ isOn } onChange={ onChange } />
        </>
    );
};
export default ScaleToggle;
