import { Scene } from '@lutithree/build/Modules/WebGL/Scene/Scene';
import { Object3D, Vector2, Vector3 } from 'three';
import { Vector2Utils } from '@lutithree/build/Modules/WebGL/Utils/Vector2Utils';
import Transform from "@lutithree/build/Modules/WebGL/Scene/DataModel/Transform";
import Instance3D
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/Instance3D";
import BasicObject
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import ShapeData
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/ShapeData";
import GeometryData
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/GeometryData";
import Asset3D
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Asset3D";

export default class MetadataPrepService {
    private readonly m_wallHeight: number = 2.5;

    public constructor(p_scene: Scene) {
        if (p_scene == null) throw new Error('NullReferenceException : p_scene is null or undefined');
    }

    public PrepareRoomObjectsDatas(p_instances: Instance3D[], p_objectToInstanciate: BasicObject[]): Instance3D[] {
        if (p_instances == null) throw new Error('NullReferenceException : p_instances is null or undefined');
        if (p_objectToInstanciate == null) throw new Error('NullReferenceException : p_objectToInstanciate is null or undefined');
        
        let walls = p_objectToInstanciate.filter((x) => x.Informations.Type === 'Wall');
        let ground = p_objectToInstanciate.filter((x) => x.Informations.Type === 'Ground');
        let ceiling = p_objectToInstanciate.filter((x) => x.Informations.Type === 'Ceiling');
        if (ground.length > 0 && walls.length > 0) {
            let instances = this.PrepareAllWallsDatas(walls, ground[0]);
            instances.push(this.PrepareGroundDatas(ground[0]));
            if (ceiling.length > 0 ) instances.push(this.PrepareCeilingDatas(ceiling[0], ground[0]));
            return instances;
        }
        return [];
    }
    
    private PrepareCeilingDatas(p_ceiling: BasicObject, p_ground: BasicObject): Instance3D {
        let forward: Vector3;
        let scale: Vector3;

        let hasGeometryObjectAsset = p_ground.Assets.some((asset) => asset.Type === 'Shape' || asset.Type === 'Geometry');
        if(hasGeometryObjectAsset) {
            forward = new Vector3(0, -1, 0);
            scale = new Vector3(1, -1, 1); 
        }
        else {
            forward = new Vector3(0, 0, 0);
            scale = new Vector3(1, -1, 1);
        } 
        
        let physicalObjectAsset = p_ground.Assets.find((asset) => asset.Type === 'Shape' || asset.Type === 'Model3d' || asset.Type === 'Geometry');
        if(physicalObjectAsset) p_ceiling.Assets.push(physicalObjectAsset);
        
        let transform = new Transform();
        transform.forward = forward;
        transform.position = new Vector3(0, this.m_wallHeight, 0);
        transform.scale = scale;

        return new Instance3D(p_ceiling.RefOfInstance, transform, p_ceiling.DataModelVersion);
    }

    private PrepareGroundDatas(p_ground: BasicObject): Instance3D {
        let forward: Vector3;
        
        let hasGeometryObjectAsset = p_ground.Assets.some((asset) => asset.Type === 'Shape' || asset.Type === 'Geometry');
       
        if(hasGeometryObjectAsset) forward = new Vector3(0, 1, 0);
        else  forward = new Vector3(0, 0, 0);

        let transform = new Transform();
        transform.forward = forward;
        transform.position = new Vector3(0, 0, 0);
        transform.scale = new Vector3(1, 1, 1);

        return new Instance3D(p_ground.RefOfInstance, transform, p_ground.DataModelVersion);
    }

    private PrepareAllWallsDatas(p_walls: BasicObject[], p_ground: BasicObject): Instance3D[] {
        let instances: Instance3D[] = [];
        let shapeAsset = p_ground.Assets.find((asset) => asset.Type === 'Shape');
        if (shapeAsset) {
            let points = (shapeAsset.Datas as ShapeData).Points;
            for (let i: number = 0; i < points.length - 1; i++) {
                // TODO remove - strict mode --------------------------------
                let from = new Vector2(points[i].x, points[i].y);
                let to = new Vector2(points[i + 1].x, points[i + 1].y);
                // todo end -------------------------------------------------

                let instance = this.PrepareSingleWallDatas(p_walls[i], from, to, this.m_wallHeight);
                if (instance) instances.push(instance);
            }
        }
        return instances;
    }
    
    private PrepareSingleWallDatas(p_wall: BasicObject, p_from: Vector2, p_to: Vector2, p_height: number): Instance3D | undefined {
        let instance: Instance3D | undefined = undefined;
        let geometryAsset = p_wall.Assets.find((asset) => asset.Type === 'Geometry');
        if (geometryAsset) {
            let scale = this.GetWallScale(geometryAsset, p_from, p_to, p_height);
            let forward = this.GetWallForward(geometryAsset, p_from, p_to);
            let position = this.GetWallPosition(geometryAsset, p_from, p_to);

            let geometryData = geometryAsset.Datas as GeometryData;
            geometryData.BoundingboxScale = scale;

            let transform = new Transform();
            transform.forward = forward;
            transform.position = position;
            transform.scale = new Vector3(1, 1, 1);

            instance = new Instance3D(p_wall.RefOfInstance, transform, p_wall.DataModelVersion);
        }
        return instance; 
    }

    private GetWallForward(p_primitiveAsset: Asset3D, p_from: Vector2, p_to: Vector2): Vector3 {
        let wallTargetDirection2d: Vector2 = p_to.clone().sub(p_from).normalize();
        // NB: Comme le sol a subit cette rotation : mesh.rotation.set(-Math.PI / 2, 0, 0);
        // On convertie les coordonnées 2D à 3D de cette façon v(x,y) => v(x,0,-y)
        let wallTargetDirection3d: Vector3 = new Vector3(wallTargetDirection2d.x, 0, -wallTargetDirection2d.y);
        return new Vector3().crossVectors(Object3D.DEFAULT_UP, wallTargetDirection3d);
    }

    private GetWallScale(p_primitiveAsset: Asset3D, p_from: Vector2, p_to: Vector2, p_height: number): Vector3 {
        const width = p_to.clone().sub(p_from);
        return new Vector3(width.length(), p_height, 1);
    }

    private GetWallPosition(p_primitiveAsset: Asset3D, p_from: Vector2, p_to: Vector2): Vector3 {
        let wallPosition2d = Vector2Utils.GetPointInBetweenByPerc(p_from, p_to, 0.5);
        return new Vector3(wallPosition2d.x, 0, -wallPosition2d.y);
    }
}
