import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MdfApiResponse from '../../../../domain/common/generic';
import { SubSection } from '../../../../domain/domain';
import subSectionService from '../../../../api/service/catalog/sub-section.service';

//#region state
export type SubSectionState = {
    isLoading: boolean;
    success: boolean;
    payload: MdfApiResponse<SubSection>;
};
export type SubSectionListState = {
    isLoading: boolean;
    payload: MdfApiResponse<SubSection[]>;
};

const initialSubSectionState: SubSectionState = {
    isLoading: false,
    success: false,
    payload: {
        content: {
            reference: '',
            name: '',
            id: 0,
            version: 0,
            section: {
                id: 0,
                reference: '',
                version: 0,
                name: '',
                logo: '',
                catalogId: 0,
            },
        },
        errors: [],
        warnings: [],
    },
};
const initialSubSectionListState: SubSectionListState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};
//#endregion

//#region get sub-Section by section
export const getAllSubSections = createAsyncThunk('get/subSections', async (sectionId: number) => {
    const res = await subSectionService.getSubSectionsBySection(sectionId);
    return res;
});

export const getAllSubSectionsSlice = createSlice({
    name: 'getAllSubSections',
    initialState: initialSubSectionListState,
    reducers: {
        resetGetAllSubSection: (state) => {
            state = initialSubSectionListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllSubSections.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getAllSubSections.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getAllSubSections.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetGetAllSubSection } = getAllSubSectionsSlice.actions;
//#endregion

//#region get subsection by ID
export const getSubSectionById = createAsyncThunk('get/subSectionsById', async (subSectionId: number) => {
    const res = await subSectionService.getSubSectionById(subSectionId);
    return res;
});

export const getSubSectionByIdSlice = createSlice({
    name: 'getSubSectionById',
    initialState: initialSubSectionState,
    reducers: {
        resetGetSubSectionByID: (state) => {
            state = initialSubSectionState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSubSectionById.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getSubSectionById.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false, success: true };
            return state;
        });
        builder.addCase(getSubSectionById.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetGetSubSectionByID } = getSubSectionByIdSlice.actions;
//#endregion
