import {Spherical, Vector2, Vector3} from "three";
import { Engine } from "@lutithree/build/Engine";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { RenderMode } from "@lutithree/build/Modules/WebGL/Rendering/RenderingStrategies/RenderMode";
import { GroupComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/GroupComponent";
import CamerasService
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Cameras/CamerasService";
import ScreenshotService
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Screenshot/ScreenshotService";
import {
    EnableScreenshotContextEvent
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Screenshot/Events/EnableScreenshotContextEvent";
import {
    ViewMode
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Cameras/ViewMode";
import ObjectService
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/ObjectService";
import InfoComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/InfoComponent";

export default class RoomScreenshotService extends ScreenshotService {

    private m_cameraService: CamerasService;
    
    private m_objectService: ObjectService;

    public constructor(p_engine: Engine, p_cameraService: CamerasService, p_objectService: ObjectService) {
        super(p_engine);
        this.m_cameraService = p_cameraService;
        this.m_objectService = p_objectService;
    }

    public TakeScreenshotOfInstance(p_screenshotDimensions: Vector2, p_refOfInstance: string) {
        if(!p_refOfInstance){
           throw new Error("No selected entity screenshot bypassed");
        }
        return new Promise<Blob>((resolve, reject) => {
            let screenshotDimension = p_screenshotDimensions;
            let env = this.m_engine.Modules.Scene.GetComponents(GroupComponent, { component: false, entity: false }).find((group)=>{return group.GroupRef === "Feature_ScreenshotPanier";});
            let envEntity: SceneEntity;
            let previousPosition = this.m_cameraService.CurrentCamera.GetObject().position.clone();
            let previoustarget = this.m_cameraService.GetCameraTargetPosition().clone();
            if(env) envEntity = this.m_engine.Modules.Scene.GetEntityByID(env.EntityID);
            
            let entityToScreen = this.m_objectService.GetRelativeEntity(p_refOfInstance);
            let objectScreenshotPosition: Spherical | undefined;
            
            if(!entityToScreen)
            {
                throw new Error("Entity does not exist");
            }
            
            objectScreenshotPosition = entityToScreen.GetComponentOfType(InfoComponent).Info.PointOfView;
            let beforeCallback = () => {
                if (!entityToScreen) {
                    throw new Error("Entity does not exist");
                }
                entityToScreen.Transform.GetObject().traverse((obj)=>{
                        obj.layers.set(14);
                });
                envEntity.Enable(true);
                if(objectScreenshotPosition)
                    this.m_cameraService.ApplyRelativeSphericalPosFromTarget(objectScreenshotPosition, entityToScreen.Transform.GetObject());
                else this.m_cameraService.ApplyRelativeSphericalPosFromTarget(new Spherical(3,1.66,1.66), entityToScreen.Transform.GetObject());
                this.m_engine.Modules.Rendering.OverrideRenderMode(RenderMode.Snapshot);
                this.m_engine.Modules.EventManager.Publish(EnableScreenshotContextEvent, new EnableScreenshotContextEvent(false));
            };
            let afterCallback = () => {
                if (!entityToScreen) {
                    throw new Error("Entity does not exist");
                }
                entityToScreen.Transform.GetObject().traverse((obj) => {
                    obj.layers.set(0);
                });
                envEntity.Enable(false);
                this.m_cameraService.CurrentCamera.GetObject().position.set(previousPosition.x, previousPosition.y, previousPosition.z);
                this.m_cameraService.SetCameraTargetPosition(previoustarget);
                this.m_engine.Modules.Rendering.OverrideRenderMode(null);
                this.m_engine.Modules.EventManager.Publish(EnableScreenshotContextEvent, new EnableScreenshotContextEvent(true));
            };
            this.m_engine.Modules.Snapshot.TakeSnapshot(screenshotDimension, beforeCallback, afterCallback).then((obj) => {
                resolve(obj);
            });
        });
    }
    
    public Take2DSnapshot(): Promise<Blob> {
        let previousMode = this.m_cameraService.CurrentViewMode;
        let mainCamPosition: Vector3 | undefined;
        return this.Take3DSnapshot(new Vector2(1920,1080), () => {
            if (previousMode == undefined)
                return;
            this.m_cameraService.SetViewMode(ViewMode.OrtographicTop);
            mainCamPosition = this.m_cameraService.CurrentCamera?.GetObject().position.clone();
            this.m_cameraService.SetRoomAtCenterOf2DView();
        }, () => {
            if (previousMode == null)
                return;
            if (mainCamPosition) this.m_cameraService.Set2DViewToPosition(mainCamPosition);
            this.m_cameraService.SetViewMode(previousMode);
        });
    }
}