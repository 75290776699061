import { IHandler } from '@lutithree/build/Modules/Core/Event/IHandler';
import { EntityRaycastedEvent } from '@lutithree/build/Modules/WebGL/Scene/Events/EntityRaycastedEvent';
import { GroupComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/GroupComponent';
import { SelectableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SelectableComponent';
import { AHandler } from "@lutithree/build/Handlers/AHandler";
import { Engine } from "@lutithree/build/Engine";
import SelectionService from "../Selection/SelectionService";
import { Button3DComponent } from "../UI/Components/Button3DComponent";
import Button3DSystem from "../UI/Systems/Button3DSystem";

export default class SelectionHandler extends AHandler implements IHandler<EntityRaycastedEvent>{

    protected m_allowMultiSelection: boolean;
    
    protected m_selectionService: SelectionService;

    protected m_button3DSystem: Button3DSystem;

    public constructor(p_engine: Engine, p_selectionService: SelectionService) {
        if (p_selectionService == null) throw new Error('NullReferenceException : p_selectionService is null or undefined');
        super(p_engine);

        this.m_allowMultiSelection = false;
        this.m_selectionService = p_selectionService;
        this.m_button3DSystem = new Button3DSystem(p_engine.Modules.Scene);
    }
    
    public set AllowMultiSelection(p_value: boolean) {
        if (p_value == null) throw new Error('NullReferenceException : p_value is null or undefined');
        this.m_allowMultiSelection = p_value;
    }
    
    public Handle(p_event: EntityRaycastedEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');
        
         this.HandleEntityRaycasted(p_event);
    }
    
    private HandleEntityRaycasted(p_event: EntityRaycastedEvent){
        let entity = p_event.Entity;
        if(!entity)
        {
            console.warn("Entity was nulled");   
            return;
        }
        let button = entity.HasComponentOfType(Button3DComponent)?entity.GetComponentOfType(Button3DComponent):undefined;
        if(button) {
            this.m_button3DSystem.OnClickButton(button);
            this.m_engine.Modules.LoopStrategy.RequestRender(true);
            return;
        }
        
        if (entity.HasComponentOfType(GroupComponent)) {
            let selectableEntities = this.m_engine.Modules.Systems.GroupSystem.GetGroupEntitiesWith(entity, SelectableComponent);
            if (selectableEntities.length > 0) {
                selectableEntities.forEach((groupedEntities) => {
                    this.m_selectionService.UpdateSelectionStatus(groupedEntities,this.m_allowMultiSelection);
                });
            } else {
                this.m_selectionService.UpdateSelectionStatus(entity,this.m_allowMultiSelection);
            }
        } else {
            this.m_selectionService.UpdateSelectionStatus(entity,this.m_allowMultiSelection);
        }
    }
}
