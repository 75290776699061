import shape from '../../../application-3d/application3D-seller/Assets/Icons/Planner_formedelapiece.svg';
import { useState } from 'react';
import close from '../../../asset/CloseWindow.svg';
import './shape-panel.scss';

type ShapePanelProps = {
    setOpenShapePanel: (openShapePanel: boolean) => void;
    setOpenPanel: (OpenPanel: boolean) => void;
};

const FakeShape = [
    { name: 'Rectangulaire', url: shape },
    { name: 'Carré', url: shape },
    { name: 'Pan coupé', url: shape },
    { name: 'Pan coupé', url: shape },
    { name: 'Pan coupé', url: shape },
    { name: 'Pan coupé', url: shape },
    { name: 'Pan coupé', url: shape },
];

const ShapePanel = (props: ShapePanelProps) => {
    const [focus, setFocus] = useState(0);
    return (
        <div className='shape-panel-main'>
            <div className='shape-panel-top'>
                <div className='shape-panel-title'>Modifier la forme de la pièce</div>
                <img alt='fermer' src={close}
                     onClick={() => {
                         props.setOpenPanel(false);
                         props.setOpenShapePanel(false);
                     }}
                />
            </div>
            <div className='shape-panel-body'>
                <div className='shape-panel-grid'>
                    {FakeShape.map((el, i) => {
                        return (
                            <div key={i} onClick={() => setFocus(i)} className='shape-cont'>
                                <div className={focus === i ? 'shape focus' : 'shape'}>
                                    {focus === i && <div className='circle' />}
                                    <img alt='' src={el.url} />
                                </div>
                                <div className='shape-name'>{el.name}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
export default ShapePanel;
