import * as React from 'react';
import { Box, styled, Typography } from '@mui/material';


type InfoBoxProps = {
    icon?: JSX.Element;
    content: string;
    errorBox?: boolean;
};

const InfoBox = ({ content, icon, errorBox }: InfoBoxProps) => {

    const StyledInfoBox = styled(Box)(({ theme }) => ({
        padding: 7,
        display: 'flex',
        alignItems: 'center',
        border: `solid 1px ${ errorBox ? theme.palette.error.main : theme.palette.primary.main }`,
        borderRadius: 5,
        background: `${ errorBox ? theme.palette.error.light : '' }`,
    }));

    return (
        <>
            <StyledInfoBox>
                { icon && icon }
                <Typography variant='body2' color={ errorBox ? 'error' : 'primary' }>
                    { content }
                </Typography>
            </StyledInfoBox>
        </>
    );
};

export default InfoBox;