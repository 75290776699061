import { Vector3 } from "three";
import { DistanceDirection } from "./Components/RelativeDistanceDisplayer";

export default class RelativeDistanceInfo {
    private readonly m_from: Vector3;

    private readonly m_to: Vector3;
    
    private readonly m_length: number;
    
    private readonly m_direction: DistanceDirection;
    
    constructor(p_from: Vector3, p_to: Vector3, p_direction: DistanceDirection) {
        if (p_from == null) throw new Error('NullReferenceException : p_from is null or undefined');
        if (p_to == null) throw new Error('NullReferenceException : p_to is null or undefined');
        if (p_direction == null) throw new Error('NullReferenceException : p_direction is null or undefined');
        
        this.m_from = p_from;
        this.m_to = p_to;
        
        this.m_length = this.m_to.clone().sub(this.m_from).length();
        this.m_direction = p_direction;
    }
    
    public get From(){
        return this.m_from;
    }

    public get To() {
        return this.m_to;
    }

    public get Length() {
        return this.m_length;
    }

    public get Direction() {
        return this.m_direction;
    }
}