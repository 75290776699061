import Transform from "@lutithree/build/Modules/WebGL/Scene/DataModel/Transform";
import { Vector3 } from "three";

export default class PhysicalAssetData {
    protected transform?: Transform;

    protected pivot?: Vector3;
    
    public get Transform(): Transform|undefined{
        return this.transform;
    }

    public set Transform(p_transform: Transform|undefined) {
        this.transform = p_transform;
    }

    public get Pivot(): Vector3|undefined{
        return this.pivot;
    }
}