import { Camera } from 'three';
import { CameraComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Rendering/CameraComponent';
import { ViewMode } from '../../../../Domain/Cameras/ViewMode';

export class ViewModeCameraComponent extends CameraComponent {
    private readonly m_mode: ViewMode;

    public constructor(p_camera: Camera, p_viewMode: ViewMode, p_isMain: boolean = false) {
        super(p_camera, p_isMain);
        if (p_viewMode == null) throw new Error('NullReferenceException : p_viewMode is null or undefined or empty');

        this.m_mode = p_viewMode;
    }

    public get ViewMode(): ViewMode {
        return this.m_mode;
    }
}
