import ARoomStudioHandler from "../../../ARoomStudioHandler";
import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import { Engine } from "@lutithree/build/Engine";
import RoomStudioServices from "../../RoomStudioServices";
import { GroupComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/GroupComponent";
import { SnappableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SnappableComponent";
import { TemporaryNodeComponent } from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Selection/Components/TemporaryNodeComponent";
import EntityStopMovingEvent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Events/EntityStopMovingEvent";
import EntityTranslationEndedEvent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Events/EntityTranslationEndedEvent";
import TransformUpdates
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Features3D/TransformUpdates";
import ObjectComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";

export class MultiSelectionEndMoveHandler extends ARoomStudioHandler implements IHandler<EntityTranslationEndedEvent>, IHandler<EntityStopMovingEvent> {
    
    public constructor(p_engine: Engine, p_services: RoomStudioServices){
        super(p_engine,p_services);
    }

    public Handle(p_event: EntityTranslationEndedEvent|EntityStopMovingEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');

        let groupComponents = this.m_engine.Modules.Scene.GetComponents(GroupComponent);
        groupComponents = groupComponents.filter(component => component.GroupRef === 'feature-multiSelection-move');
        let multiSelectionGroupNode = groupComponents.length>0? groupComponents[0] : undefined;
        if(multiSelectionGroupNode){
            let entity = this.m_engine.Modules.Scene.GetEntityByID(multiSelectionGroupNode.EntityID);
            if(entity.HasComponentOfType(TemporaryNodeComponent)) {
                let selectedEntities = this.m_services.Selection.GetSelectedEntities();
                selectedEntities.forEach((selectedEntity)=>{
                    
                    // Notify transform update
                    let objectComponent = selectedEntity.HasComponentOfType(ObjectComponent)? selectedEntity.GetComponentOfType(ObjectComponent):undefined;
                    if(objectComponent){
                        let transfromUpdates: TransformUpdates = new TransformUpdates(selectedEntity.Transform.GetObject());
                        this.m_services.Notifier.NotifyObjectTransformChanged(objectComponent.Ref, transfromUpdates);
                    }
                    
                    // Unsnap
                    let snappable = selectedEntity.HasComponentOfType(SnappableComponent)? selectedEntity.GetComponentOfType(SnappableComponent):undefined;
                    if(snappable){
                        snappable.Snapped.forEach((snapData)=>{
                            this.m_services.Snap.Unsnap(selectedEntity, snappable!, snapData);
                        });
                    }
                });
            }
                
        }
    }
}