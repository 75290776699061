import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Transform from "@lutithree/build/Modules/WebGL/Scene/DataModel/Transform";
import Instance3D from '../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/Instance3D';
import BasicObject
    from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import { Asset3DData } from '../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Asset3DData';
import TransformUpdates
    from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Features3D/TransformUpdates";
import PhysicalAssetData
    from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/PhysicalAssetData";
import PartTransformData
    from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/PartTransformData";
import Asset3D from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Asset3D";
import ObjectDatas from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ObjectDatas";


let currentDataModelVersion: string = '1.0.0';

function indexOfInstance(p_refOfInstance: string, p_instances: Instance3D[]): number {
    for (let i = 0; i < p_instances.length; i++) {
        if (p_instances[i].Ref === p_refOfInstance) {
            return i;
        }
    }
    return -1;
}

function indexOfObject(p_refOfInstance: string, p_objects: BasicObject[]): number {
    for (let i = 0; i < p_objects.length; i++) {
        if (p_objects[i].RefOfInstance === p_refOfInstance) {
            return i;
        }
    }
    return -1;
}

function getUpdatedRoom(p_instances3D: Instance3D[], p_basicObjects: BasicObject[], p_dataModelVersion: string): ObjectDatas {
    let room = new ObjectDatas();
    room.SetDatas(p_instances3D, p_basicObjects);
    return room;
}

function updateObjectAssetData(p_basicObject: BasicObject, p_type: string, p_data: Asset3DData): void {
    let asset = p_basicObject.Assets.find((x) => x.Type === p_type);
    if (asset) {
        let index = p_basicObject.Assets.indexOf(asset);
        p_basicObject.Assets[index].Datas = p_data;
    }
}

function updateObjectAssetTransform(p_basicObject: BasicObject, p_refOfPart:string, p_transformUpdates: TransformUpdates): void {
    let assets = p_basicObject.Assets.filter((x) => x.RefOfPart === p_refOfPart);
    assets.forEach((asset)=>{
        let index = p_basicObject.Assets.indexOf(asset);
        if(p_basicObject.Assets[index].Datas instanceof PhysicalAssetData){
            let datas = p_basicObject.Assets[index].Datas as PhysicalAssetData;
            let transform = datas.Transform === undefined? new Transform() : datas.Transform;

            if (p_transformUpdates.Position) transform!.position = p_transformUpdates.Position;
            if (p_transformUpdates.Forward) transform!.forward = p_transformUpdates.Forward;
            if (p_transformUpdates.Scale) transform!.scale = p_transformUpdates.Scale;

            datas.Transform = transform;
        }
    });
}

function updateObjectPartTransform(p_basicObject: BasicObject, p_refOfPart:string, p_transformUpdates: TransformUpdates): void {
    if(p_basicObject.TransformByParts == null) p_basicObject.TransformByParts = new Array<PartTransformData>();
    
    let transformDatas = p_basicObject.TransformByParts.filter((x) => x.RefOfPart === p_refOfPart);
    if(transformDatas.length !== 0 ){
        transformDatas.forEach((transformData)=>{
            let index = p_basicObject.TransformByParts!.indexOf(transformData);
            let transform = p_basicObject.TransformByParts![index].Transform === undefined? new Transform() : p_basicObject.TransformByParts![index].Transform;

            if (p_transformUpdates.Position) transform.position = p_transformUpdates.Position;
            if (p_transformUpdates.Forward) transform.forward = p_transformUpdates.Forward;
            if (p_transformUpdates.Scale) transform.scale = p_transformUpdates.Scale;

            p_basicObject.TransformByParts![index].Transform = transform;
        });
    }
    else {
        let transform = new Transform();
        if (p_transformUpdates.Position) transform.position = p_transformUpdates.Position;
        if (p_transformUpdates.Forward) transform.forward = p_transformUpdates.Forward;
        if (p_transformUpdates.Scale) transform.scale = p_transformUpdates.Scale;

        let transformData = new PartTransformData();
        transformData.Transform = transform;
        transformData.RefOfPart = p_refOfPart;

        p_basicObject.TransformByParts.push(transformData);
    }
    
}

function addToInstances(p_instance: Instance3D, p_instances3D: Instance3D[]): void {
    let alreadyExist = p_instances3D.find((x) => x.Ref === (p_instance as Instance3D).Ref);
    if (alreadyExist) {
        let index = p_instances3D.indexOf(alreadyExist);
        p_instances3D.splice(index, 1);
    }
    p_instances3D.push(p_instance);
}

export type RoomContentState = {
    currentDataModelVersion: string;
    plannerObjects: BasicObject[];
    instances: Instance3D[];
    room: ObjectDatas;
    roomToLoad: ObjectDatas | undefined; 
    screenshots: Blob[];
};

const initialRoomState: RoomContentState = {
    currentDataModelVersion: currentDataModelVersion,
    plannerObjects: [],
    instances: [],
    room: new ObjectDatas(),
    roomToLoad: undefined,
    screenshots: [],
};

const roomContentSlice = createSlice({
    name: 'roomContent',
    initialState: initialRoomState,

    //reducers : define how the state can be updated
    reducers: {
        addScreenshot: (state, action: PayloadAction<Blob>) => {
            if (action.payload) state.screenshots.push(action.payload);
        },
        clearScreenshots: (state) => {
            state.screenshots.Clear();
        },
        clearRoom: (state, action: PayloadAction<void>) => {
            state.instances = [];
            state.plannerObjects = [];
            let room = new ObjectDatas();
            // @ts-ignore:next-line
            room.SetDatas([...state.instances], [...state.plannerObjects], currentDataModelVersion);
            state.room = room;
        },
        setRoom: (state, action: PayloadAction<ObjectDatas>) => {
            if (action.payload) {
                state.room = action.payload;
                state.instances = [...state.room.Instances3D];
                state.plannerObjects = [...state.room.BasicObjects];
            }
        },
        setRoomToLoad: (state, action: PayloadAction<ObjectDatas | undefined>) => {
            state.roomToLoad = action.payload;
        },
        addInstances: (state, action: PayloadAction<Instance3D | Instance3D[]>) => {
            if (action.payload) {
                let instancesCopy = [...state.instances];
                if (Array.isArray(action.payload)) {
                    action.payload.forEach((instance) => {
                        // @ts-ignore:next-line
                        addToInstances(instance, instancesCopy);
                    });
                } else {
                    // @ts-ignore:next-line
                    addToInstances(action.payload, instancesCopy);
                }
                // @ts-ignore:next-line
                state.room = getUpdatedRoom([...instancesCopy], [...state.plannerObjects], currentDataModelVersion);
                state.instances = instancesCopy;
            }
        },
        addObjects: (state, action: PayloadAction<BasicObject | BasicObject[]>) => {
            if (action.payload) {
                if (Array.isArray(action.payload)) {
                    state.plannerObjects = state.plannerObjects.concat(action.payload);
                } else {
                    state.plannerObjects.push(action.payload);
                }
                // @ts-ignore:next-line
                state.room = getUpdatedRoom([...state.instances], [...state.plannerObjects], currentDataModelVersion);
                state.plannerObjects = [...state.plannerObjects];
            }
        },
        updateObject: (state, action: PayloadAction<BasicObject[]>) => {
            if (action.payload) {
                let objectsCopy = [...state.plannerObjects];
                action.payload.forEach((basicObject)=>{
                    // @ts-ignore:next-line
                    let i = indexOfObject(basicObject.refOfInstance, objectsCopy);

                    if(i>=0){
                        objectsCopy[i] = basicObject;
                    }
                });
                // @ts-ignore:next-line
                state.room = getUpdatedRoom([...state.instances], [...objectsCopy], currentDataModelVersion);
                state.plannerObjects = [...objectsCopy];
                console.log("after update ----- ",state.plannerObjects);
            }
        },
        updateInstance: (state, action: PayloadAction<{ refOfInstance: string; transformUpdates: TransformUpdates }>) => {
            if (action.payload) {
                let transformUpdates = action.payload.transformUpdates;
                let instancesCopy = [...state.instances];
                // @ts-ignore:next-line
                let i = indexOfInstance(action.payload.refOfInstance, instancesCopy);

                if(i>=0){
                    if(transformUpdates.Forward) instancesCopy[i].Transform.forward = transformUpdates.Forward;
                    if(transformUpdates.Position) instancesCopy[i].Transform.position = transformUpdates.Position;
                    if(transformUpdates.Scale) instancesCopy[i].Transform.scale = transformUpdates.Scale;

                    // @ts-ignore:next-line
                    state.room = getUpdatedRoom([...instancesCopy], [...state.plannerObjects], currentDataModelVersion);
                    state.instances = [...instancesCopy];
                }
            }
        },
        updateAssetData: (state, action: PayloadAction<{ refOfInstance: string; type: string; data: Asset3DData }>) => {
            if (action.payload) {
                if (Asset3D.IsDataCompatibleWithAssetType(action.payload.data, action.payload.type)) {
                    let objectsCopy = [...state.plannerObjects];
                    // @ts-ignore:next-line
                    let i = indexOfObject(action.payload.refOfInstance, objectsCopy);
                    if(i>=0){
                        // @ts-ignore:next-line
                        updateObjectAssetData(objectsCopy[i], action.payload.type, action.payload.data);

                        // @ts-ignore:next-line
                        state.room = getUpdatedRoom([...state.instances], [...objectsCopy], currentDataModelVersion);
                        state.plannerObjects = [...objectsCopy];
                    }
                }
            }
        },
        updateAssetTransform: (state, action: PayloadAction<{ refOfInstance: string; refOfPart:string, transformUpdates: TransformUpdates }>) => {
            if (action.payload) {
                let objectsCopy = [...state.plannerObjects];
                // @ts-ignore:next-line
                let i = indexOfObject(action.payload.refOfInstance, objectsCopy);
                if(i>=0) {
                    // @ts-ignore:next-line
                    updateObjectAssetTransform(objectsCopy[i], action.payload.refOfPart, action.payload.transformUpdates);

                    // @ts-ignore:next-line
                    state.room = getUpdatedRoom([...state.instances], [...objectsCopy], currentDataModelVersion);
                    state.plannerObjects = [...objectsCopy];
                }
            }
        },
        updatePartTransform: (state, action: PayloadAction<{ refOfInstance: string; refOfPart:string, transformUpdates: TransformUpdates }>) => {
            if (action.payload) {
                let objectsCopy = [...state.plannerObjects];
                // @ts-ignore:next-line
                let i = indexOfObject(action.payload.refOfInstance, objectsCopy);
                if(i>=0) {
                    // @ts-ignore:next-line
                    updateObjectPartTransform(objectsCopy[i], action.payload.refOfPart, action.payload.transformUpdates);

                    // @ts-ignore:next-line
                    state.room = getUpdatedRoom([...state.instances], [...objectsCopy], currentDataModelVersion);
                    state.plannerObjects = [...objectsCopy];
                }
            }
        },
        removeObject: (state, action: PayloadAction<string[]>) => {
            if (action.payload ) {
                let instanceIdToRemove: number[] = [];
                let plannerObjIdToRemove: number[] = [];
                let instancesCopy = [...state.instances];
                let objectsCopy = [...state.plannerObjects];

                for (let i = 0; i < instancesCopy.length; i++) {
                    if (action.payload.includes(instancesCopy[i].Ref)) {
                        instanceIdToRemove.push(i);
                    }
                }
                for (let i = 0; i < objectsCopy.length; i++) {
                    if (action.payload.includes(objectsCopy[i].RefOfInstance)) {
                        plannerObjIdToRemove.push(i);
                    }
                }

                instanceIdToRemove.forEach((id)=>{
                    instancesCopy.splice(id, 1);
                });
                plannerObjIdToRemove.forEach((id)=>{
                    objectsCopy.splice(id, 1);
                });

                // @ts-ignore:next-line
                state.room = getUpdatedRoom([...instancesCopy], [...objectsCopy], currentDataModelVersion);
                state.instances = [...instancesCopy];
                state.plannerObjects = [...objectsCopy];
            }
        },
    },
});

export const {
    addScreenshot,
    clearScreenshots,
    clearRoom,
    setRoom,
    setRoomToLoad,
    addInstances,
    addObjects,
    updateObject,
    updateAssetData,
    removeObject,
    updateAssetTransform,
    updatePartTransform,
    updateInstance,
} = roomContentSlice.actions;
export default roomContentSlice;
