import apiClient from '../../../api-client';
import {
    ADD_ATOM_LIST_TO_PRESET, ADD_ATOM_TO_PRESET, CREATE_PRESET, DELETE_PRESET, DUPLICATE_PRESET,
    FIND_ATOM_BY_PRESET_REF,
    GET_ALL_PRESET,
    GET_DEFAULT_PRESET, GET_PRESET_BY_PRODUCT_REF, RENAME_PRESET, UPDATE_DEFAULT_PRESET,
    UPDATE_PRESET_THUMBNAILURL,
} from '../../../enpoint/product/Preset/Preset';


const presetService = {
    duplicatePreset: async (productRef: string, presetRef: string) => apiClient(DUPLICATE_PRESET(productRef, presetRef), undefined, undefined, 'POST'),
    getPresetByProductRef: async (productRef: string) => apiClient(GET_PRESET_BY_PRODUCT_REF(productRef), undefined, undefined, 'GET'),
    createPreset: async (productRef: string, presetName: string) => apiClient(CREATE_PRESET(productRef, presetName), undefined, undefined, 'POST'),
    renamePreset: async (productRef: string, presetRef: string, newName: string) => apiClient(RENAME_PRESET(productRef, presetRef, newName), undefined, undefined, 'PUT'),
    getAllPreset: async () => apiClient(GET_ALL_PRESET(), undefined, undefined, 'GET'),
    deletePreset: async (productRef: string, presetRef: string) => apiClient(DELETE_PRESET(productRef, presetRef), undefined, undefined, 'DELETE'),
    getDefaultPreset: async (productRef: string) => apiClient(GET_DEFAULT_PRESET(productRef), undefined, undefined, 'GET'),
    updateDefaultPreset: async (productRef: string, presetRef: string) => apiClient(UPDATE_DEFAULT_PRESET(productRef, presetRef), undefined, undefined, 'POST'),
    addAtomToPreset: async (productRef: string, presetRef: string, atomRef: string) => apiClient(ADD_ATOM_TO_PRESET(productRef, presetRef, atomRef), undefined, undefined, 'POST'),
    addAtomSetToPreset: async (productRef: string, presetRef: string, atomSet: string[]) => apiClient(ADD_ATOM_LIST_TO_PRESET(productRef, presetRef), atomSet, undefined, 'POST'),
    findAtomByPresetRef: async (presetRef: string) => apiClient(FIND_ATOM_BY_PRESET_REF(presetRef), undefined, undefined, 'GET'),
    updatePresetThumbnailUrl: async (productRef: string, presetRef: string, thumbnailUrl: string) => apiClient(UPDATE_PRESET_THUMBNAILURL(productRef, presetRef), thumbnailUrl, undefined, 'PUT'),

};
export default presetService;
