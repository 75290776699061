import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Engine } from '@lutithree/build/Engine';
import { Studio } from "../../../Application3D/GameLogic/Studio/Studio";
import { ColorRepresentation } from "three";
import {DataTextureLoader} from "@lutithree/build/Modules/WebGL/Resources/Load/DataTextureLoader";
import {EnvironmentComponent} from "@lutithree/build/Modules/WebGL/Scene/Components/Rendering/EnvironmentComponent";
import {
    ScreenshotEnvDecorator
} from "../../../Application3D/GameLogic/Studio/Environment/EntityDecorators/ScreenshotEnvDecorator";


export class ProductStudio extends Studio {

    public constructor(p_rootEntities: Map<string, SceneEntity>) {
        super(p_rootEntities);
    }

    protected async LoadEnvironnementsAsync(p_engine: Engine, p_pathLightingDHR: string, p_pathBackgroundDHR: string | ColorRepresentation): Promise<void> {
        // Create Environement_Background
        // Create Environement_Light
        let textureAsset: DataTextureLoader = new DataTextureLoader(p_pathLightingDHR);
        let lightEnvEntity = p_engine.Modules.Scene.CreateEntity('Environement');
        if (this.m_rootEntities.has('Environement')) this.m_rootEntities.get('Environement')!.Transform.AddChild(lightEnvEntity.Transform);
        textureAsset.LoadAsync().then((texture) => {
            lightEnvEntity.AddComponentOfType(EnvironmentComponent, p_engine.Modules.Rendering.Renderer, p_engine.Modules.Scene.ThreeScene, texture);
            p_engine.Modules.LoopStrategy.RequestRender(true);
        });

        let screenEnvEntity = p_engine.Modules.Scene.CreateEntity('Screen_Background');
        new ScreenshotEnvDecorator(p_engine.Modules.Rendering.Renderer).Decorate(screenEnvEntity);
        if (this.m_rootEntities.has('Environement')) this.m_rootEntities.get('Environement')!.Transform.AddChild(screenEnvEntity.Transform);
    }
}
