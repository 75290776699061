import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Camera, MOUSE, PerspectiveCamera, TOUCH, Vector3 } from 'three';
import { OrbitControlComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Controls/OrbitControlComponent';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { IEntityDecorator } from "../../../../Domain/IEntityDecorator";
import { CameraComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Rendering/CameraComponent";

export class Camera3dDecorator implements IEntityDecorator<SceneEntity> {
    private m_dom: HTMLCanvasElement;

    private m_cameraChangedCallback: ()=>void;

    public constructor(p_dom: HTMLCanvasElement, p_callback: ()=>void) {
        if (p_dom == null) throw new Error('NullReferenceException : p_dom is null or undefined');
        if (p_callback == null) throw new Error('NullReferenceException : p_callback is null or undefined');

        this.m_dom = p_dom;
        this.m_cameraChangedCallback = p_callback;
    }

    public Decorate(p_entity: SceneEntity): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        let aspect = window.innerWidth / window.innerHeight;
        let camera = new PerspectiveCamera(60, aspect, 0.1, 50);
        camera.position.copy(new Vector3(-1.273, 1.1416, 2.916));
        camera.layers.enableAll();
        camera.layers.disable(1);
        let cameraComponent = p_entity.AddComponentOfType(CameraComponent, camera, true);

        let orbitControl = p_entity.AddComponentOfType(OrbitControlComponent, this.CreatePerspectiveOrbitControl(cameraComponent.GetObject(), this.m_dom));
        orbitControl.Enable(true);
        orbitControl.SetOnChangecallback(this.m_cameraChangedCallback);
    }

    private CreatePerspectiveOrbitControl(p_camera: Camera, p_renderDom: HTMLCanvasElement): OrbitControls {
        let control = new OrbitControls(p_camera, p_renderDom);

        control.rotateSpeed = 0;
        control.panSpeed = 0.4;
        control.screenSpacePanning = false;
        control.maxPolarAngle = Math.PI / 2;
        control.maxDistance = 8;
        control.minDistance = 1;
        control.enableDamping = true;
        control.dampingFactor = 0.05;
        control.target.set(0, 0, 0);

        control.mouseButtons = {
            LEFT: MOUSE.ROTATE,
            MIDDLE: MOUSE.PAN,
            RIGHT: MOUSE.PAN,
        };
        control.touches = {
            ONE: TOUCH.ROTATE,
            TWO: TOUCH.DOLLY_ROTATE,
        };

        control.enablePan = false;
        control.enableRotate = true;
        control.enableZoom = true;
        return control;
    }
}
