import { createTheme } from '@mui/material/styles';


const theme = createTheme({
    palette: {
        common: {
            white: '#fff',
        },
        primary: {
            main: '#1D2740',
        },
        secondary: {
            main: '#4362ca',
            light: '#d9e0f4',

        },
        error: {
            main: '#E95D5D',
            light: '#fdefef',
        },
        grey: {
            100: '#f5f5f5',
            200: '#eeeeee',
            300: '#e0e0e0',
            400: '#dbdbdb',
            500: '#9e9e9e',
            600: '#757575',
            700: '#707070',
            800: '#424242'
        },
    },
    typography: {
        fontFamily: [
            'Montserrat',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
        ].join(','),
        h1: {
            fontWeight: 400,
            fontSize: '3rem',
            lineHeight: 1.167,
            letterSpacing: '-0.01562em',
        },
        h2: {
            fontSize: 34,
            fontWeight: 700,
        },
        h3: {
            fontSize: 29,
            fontWeight: 700,
        },
        h4: {
            fontSize: 20,
            fontWeight: 400,
            letterSpacing: 1,
        },
        h5: {
            fontSize: 18,
            fontWeight: 500,
        },
        subtitle1: {
            fontSize: 18,
            fontWeight: 400,
            lineHeight: 1.55,
            letterSpacing: '1.5px',
        },
        subtitle2: {
            fontSize: '0.761rem',
            letterSpacing: '0.00714em',
            textTransform: 'initial',
            lineHeight: 'normal',
        },
        body1: {
            fontSize: 17,
            fontWeight: 500,
        },
        body2: {
            fontSize: 16,
            fontWeight: 400,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: '15px 38px',
                    fontSize: 14,
                },
                startIcon: {
                    marginRight: 16,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                    background: 'transparent',
                    marginTop: 10,
                    fontSize: 14,
                },
            },
        },
    },
});

export default theme;