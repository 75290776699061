import AState from './AState';
import { IReaction, Reaction } from './Reaction';
import IEvent from './IEvent';

export default class State extends AState {
    private m_name: string;

    private m_onEnter: () => void;

    private m_onExit: () => void;

    public constructor(
        p_name: string,
        p_onEnter: () => void = () => {},
        p_onExit: () => void = () => {},
        p_reactions: Map<{ new (...args: any[]): IEvent }, Array<IReaction>> | undefined = undefined
    ) {
        super(p_reactions);
        if (!p_name) throw new Error('NullReferenceException : p_name is null or undefined or empty');

        this.m_name = p_name;
        this.m_onEnter = p_onEnter;
        this.m_onExit = p_onExit;
    }

    public get Name(): string {
        return this.m_name;
    }

    public Enter(): void {
        this.m_onEnter();
    }

    public Exit(): void {
        this.m_onExit();
    }

    public PlayReactions<T extends IEvent>(p_eventType: { new (...args: any[]): T }, p_event: T): void {
        if (p_eventType == null) throw new Error('NullReferenceException : p_eventType is null or undefined');
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');

        if (this.m_reactions.has(p_eventType)) {
            let reactionsOfEvent = this.m_reactions.get(p_eventType)!;
            reactionsOfEvent.forEach((reaction) => {
                (reaction as Reaction<T>).Reaction(p_event);
            });
        }
    }
}
