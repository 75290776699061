import { IPartService } from '../../../Domain/Objects/Composition/IPartService';
import Asset3D from '../../../Domain/Objects/Assets/Asset3D';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Engine } from '@lutithree/build/Engine';
import AService from '../../../Domain/AService';
import { Group, Material, Object3D } from 'three';
import Instance3D from '../../../Domain/Objects/AssetAssembly/Instance3D';
import { Object3DUtils } from '@lutithree/build/Modules/WebGL/Utils/Object3DUtils';
import { IAssetDecorator } from '../../../Domain/Objects/AssetAssembly/IAssetDecorator';
import PartEditionReport from '../../../Domain/Objects/AssetAssembly/PartEditionReport';
import { MeshFilterComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent';
import { MeshRendererComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshRendererComponent';
import { HookableComponent } from '../../Features3D/Hooks/Components/HookableComponent';
import { HookComponent } from '../../Features3D/Hooks/Components/HookComponent';
import { EditionType } from '../../../Domain/Objects/AssetAssembly/EditionType';
import MapComparator from '../../../Utils/MapComparator';
import ResourceLoadingFailEvent from "../AssetAssembly/Events/ResourceLoadingFailEvent";
import ResourceErrorData from "../../../Domain/Objects/ResourceErrorData";
import AssetErrorData from "../../../Domain/Objects/AssetErrorData";
import TextureErrorData from "../../../Domain/Objects/TextureErrorData";
import {ResourceLoadingError} from "@lutithree/build/Modules/WebGL/Resources/Load/ResourceLoadingError";
import {TexturesLoadingError} from "@lutithree/build/Modules/WebGL/Resources/Load/TexturesLoadingError";
import ObjectComponent from "../Components/ObjectComponent";
import {OptionsComponent} from "../Components/OptionsComponent";

export default class AssetService extends AService implements IPartService {
    protected readonly m_comparator: MapComparator<Asset3D>;

    protected m_assetDecorator: IAssetDecorator;
    
    protected m_onResourceLoadingFailed: (p_resourceErrorData: ResourceErrorData)=> void;

    protected m_onClearResource: (p_resource: Material | Material[] | Group | Object3D) => void;

    public constructor(p_engine: Engine, p_assetDecorator: IAssetDecorator) {
        super(p_engine);
        if (p_assetDecorator == null) throw new Error('Empty refOfPart : p_assetDecorator is null or undefined');

        this.m_assetDecorator = p_assetDecorator;
        this.m_comparator = new MapComparator<Asset3D>();

        this.m_onResourceLoadingFailed = (p_resourceErrorData: ResourceErrorData)=>{
            this.m_engine.Modules.EventManager.Publish(ResourceLoadingFailEvent, new ResourceLoadingFailEvent(p_resourceErrorData));
        };

        this.m_onClearResource = (resource) => {
            if (Array.isArray(resource)) {
                resource.forEach((item) => {
                    this.m_engine.Modules.Resources.Cleaner.Dispose(item);
                });
            } else this.m_engine.Modules.Resources.Cleaner.Dispose(resource);
        };
    }

    public async LoadPartAsync(p_parentEntity: SceneEntity, p_refOfInstance: string, p_refOfPart: string, p_part: Asset3D | Asset3D[], p_instance: Instance3D | undefined): 
        Promise<{ ref: string; entity: SceneEntity }[]> {
        if (!p_refOfInstance) throw new Error('Empty p_refOfInstance : p_refOfInstance is null or undefined or empty');
        if (!p_refOfPart) throw new Error('Empty refOfPart : p_refOfPart is null or undefined or empty');
        if (p_part == null) throw new Error('NullReferenceException : p_part is null or undefined');

        return new Promise<{ ref: string; entity: SceneEntity }[]>((resolve, reject) => {
            let promises: Array<Promise<void>> = new Array<Promise<void>>();

            let partEntity = this.m_engine.Modules.Scene.CreateEntity(p_refOfPart);

            if (p_parentEntity) p_parentEntity.Transform.GetObject().add(partEntity.Transform.GetObject());
            if (p_instance) Object3DUtils.ApplyTransform(partEntity.Transform.GetObject(), p_instance.Transform);
            partEntity.Transform.GetObject().visible = false;

            if (Array.isArray(p_part)) {
                p_part.forEach((partElement) => {
                    // Load asset on part
                    promises.push(this.GetDecorationPromise(partEntity, partElement, p_refOfInstance, reject));
                });
            } else promises.push(this.GetDecorationPromise(partEntity, p_part, p_refOfInstance, reject));

            Promise.allSettled(promises).then((result) => {
                partEntity.Transform.GetObject().visible = true;
                resolve([{ ref: p_refOfPart, entity: partEntity }]);
            });
        });
    }

    public async EditPartAsync(p_objComponent: ObjectComponent, p_ref: string, p_newPart: Asset3D[] | Asset3D, p_lastPart: Asset3D[] | Asset3D): Promise<PartEditionReport[]> {
        if (!p_ref) throw new Error('Empty refOfPart : p_ref is null or undefined or empty');
        if (p_newPart == null) throw new Error('NullReferenceException : p_newPart is null or undefined');
        if (p_lastPart == null) throw new Error('NullReferenceException : p_lastPart is null or undefined');
        if (p_objComponent == null) throw new Error('NullReferenceException : p_objComponent is null or undefined');

        let editionReport: PartEditionReport[] = [];
        let promises: Array<Promise<void | Map<string, SceneEntity>>> = new Array<Promise<void | Map<string, SceneEntity>>>();

        let assetByType1: Map<string, Array<Asset3D>>;
        let assetByType2: Map<string, Array<Asset3D>>;
        if (Array.isArray(p_lastPart)) {
            assetByType1 = Asset3D.GetAssetsByType(p_lastPart);
        } else {
            assetByType1 = new Map<string, Array<Asset3D>>();
            assetByType1.set(p_lastPart.Type, [p_lastPart]);
        }

        if (Array.isArray(p_newPart)) {
            assetByType2 = Asset3D.GetAssetsByType(p_newPart);
        } else {
            assetByType2 = new Map<string, Array<Asset3D>>();
            assetByType2.set(p_newPart.Type, [p_newPart]);
        }

        let partEntityId = p_objComponent.GetEntityIdOfPart(p_ref);
        if (partEntityId) {
            return new Promise<PartEditionReport[]>((resolve, reject) => {
                let partEntity = this.m_engine.Modules.Scene.GetEntityByID(partEntityId!);

                // suppress removed type
                let removedTypes = this.m_comparator.GetRemovedKeys(assetByType1, assetByType2);
                removedTypes.forEach((assets, type) => {
                    this.RemoveAssetType(partEntity, type);
                    editionReport.push(new PartEditionReport(p_ref, EditionType.Remove, 'Asset3D', type));
                });

                // Create added types
                let addedTypes = this.m_comparator.GetAddedKeys(assetByType1, assetByType2);
                addedTypes.forEach((assets, type) => {
                    assets.forEach((asset) => {
                        let promise = this.GetDecorationPromise(partEntity, asset, p_objComponent.Ref, reject);
                        promises.push(promise);
                        editionReport.push(new PartEditionReport(p_ref, EditionType.Add, 'Asset3D', type));
                    });
                });

                // Regenerer edited types
                let sameParts = this.m_comparator.GetSameKeys(assetByType1, assetByType2);
                sameParts.forEach((assets, type) => {
                    if (!Asset3D.IsArrayEqual(assetByType1.get(type)!, assetByType2.get(type)!)) {
                        this.RemoveAssetType(partEntity, type);
                        assetByType2.get(type)!.forEach((asset) => {
                            let promise = this.GetDecorationPromise(partEntity, asset, p_objComponent.Ref, reject);
                            promises.push(promise);
                            editionReport.push(new PartEditionReport(p_ref, EditionType.Regen, 'Asset3D', type));
                        });
                    }
                });
                
                Promise.all(promises).then((result) => {
                    resolve(editionReport);
                }, ()=>{
                    resolve(editionReport);
                });
            });
        } else {
            return [];
        }
    }
    
    private GetDecorationPromise(p_entity: SceneEntity, p_asset: Asset3D, p_refOfInstance: string, reject:()=>void): Promise<void> {
        if(p_asset.Datas == undefined) this.m_onResourceLoadingFailed(new AssetErrorData("", p_asset, p_refOfInstance));
        return this.m_assetDecorator.DecorateAsset(p_entity, p_asset, this.m_onClearResource).catch((reason)=>{
            if(reason instanceof TexturesLoadingError) reason.Textures.forEach((url, slot) => {
                this.m_onResourceLoadingFailed(new TextureErrorData(url, reason.Path, slot));
            }); else if(reason instanceof ResourceLoadingError){
                this.m_onResourceLoadingFailed(new AssetErrorData(reason.Path, p_asset, p_refOfInstance));
            }
        });
    }

    private RemoveAssetType(p_entity: SceneEntity, p_assetType: string): void {
        switch (p_assetType) {
            case 'Shape':
            case 'Primitive':
            case 'Model3d':
            case 'Model':
                this.RemoveModel(p_entity);
                break;
            case 'Material':
                break;
            default:
                break;
        }
    }

    private RemoveModel(p_entity: SceneEntity): void {
        let meshFilters: MeshFilterComponent[] = p_entity.GetComponentsOfType(MeshFilterComponent);
        let meshRenderer: MeshRendererComponent;

        // Disconnect Components
        if (p_entity.HasComponentOfType(MeshRendererComponent)) {
            meshRenderer = p_entity.GetComponentOfType(MeshRendererComponent);
            meshRenderer.RemoveMeshFilter(meshFilters);
        }

        // remove meshfilters
        meshFilters.forEach((component) => {
            p_entity.RemoveComponent(component);
        });

        // remove options
        let options = p_entity.HasComponentOfType(OptionsComponent) ? p_entity.GetComponentOfType(OptionsComponent) : undefined;
        if (options) p_entity.RemoveComponent(options);

        // remove template
        let template = p_entity.HasComponentOfType(HookableComponent) ? p_entity.GetComponentOfType(HookableComponent) : undefined;
        if (template) p_entity.RemoveComponent(template);

        // remove hooks
        let hooks = p_entity.GetComponentsOfType(HookComponent);
        hooks.forEach((hook) => {
            p_entity.RemoveComponent(hook);
        });
    }
}
