import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from '../../../../api/api-client';
import { RESET_PASSWORD } from '../../../../api/enpoint/reset-password';


interface ResetPasswordAttributes {
    oldPassword: string;
    newPassword: string;
}

const initialState = {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
    success: false,
    payload: { content: null, errors: [], warnings: [] },
};

export const resetPassword = createAsyncThunk('reset/password',
    async ({ oldPassword, newPassword }: ResetPasswordAttributes,
           { getState, rejectWithValue }) => {
        try {
            const objectDataToSend = { oldPassword, newPassword };
            // @ts-ignore
            const userId = (getState() as RootState).currentUser.response.content.user.id;

            return await apiClient(RESET_PASSWORD(userId), objectDataToSend, undefined, 'PUT');

        } catch (error) {
            return rejectWithValue;
        }
    },
);

const resetPasswordSlice = createSlice({
    name: 'resetPasswordSlice',
    initialState: initialState,
    reducers: {
        resetUserPassword: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetPassword.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            // @ts-ignore
            if (payload.content) {
                state.success = true;
            }
        });
        builder.addCase(resetPassword.rejected, (state, { payload }) => {
            state.isLoading = false;
            // @ts-ignore
            state.error = payload;
        });
    },
});

export const { resetUserPassword } = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;