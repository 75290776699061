import AState from "../../../../../../application3D-common/Librairies/FSM/AState";

export default class IdleBehaviours extends AState {
    
    public Enter(): void {
        //console.log('IdleBehaviours Enter!');
    }

    public Exit(): void {
        //console.log('IdleBehaviours Exit!');
    }
}
