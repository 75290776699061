import * as React from 'react';
import BasicObject from "../../../Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import {RoomStudioState} from "../../../../application3D-seller/Redux/Reducers/room-studio/RoomStudioReducer";
import {ProductStudioState} from "../../../Redux/Reducers/product-studio/ProductStudioReducer";
import ObjectComponent from "../../../Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";
import {SellerApp3DModeState} from "../../../../application3D-seller/Redux/Reducers/SellerApp3DModeReducer";
import {SellerMode} from "../../../../application3D-seller/Components/SellerApp3D/SellerMode";
import store from "../../../../../services/store";
import InfoComponent from "../../../Librairies/Studios/Application3D/GameLogic/Objects/Components/InfoComponent";

export type DeleteDebugProps = {
    deleteCallback: (p_composition: BasicObject | undefined, p_module: BasicObject | undefined)=>void;
};
const DebugDelete = (props: DeleteDebugProps) => {
    let roomStudio: RoomStudioState | undefined;
    let roomModeStudio: SellerApp3DModeState | undefined;
    if((store.getState() as any).roomStudio){
        roomStudio = (store.getState() as any).roomStudio;
        roomModeStudio = (store.getState() as any).sellerApp3DModes;
    }
    
    let productStudio: ProductStudioState;
    if ((store.getState() as any).productStudio)
        productStudio = (store.getState() as any).productStudio;

    function IsRoomStudio(): boolean {
        if(roomStudio && roomModeStudio && roomModeStudio?.sellerMode == SellerMode.RoomStudio) 
            return true;
        return false;
    }

    function ObjectIsConfigurable(): boolean {
        if(IsRoomStudio()) {
            if(roomStudio!.editedDatas && roomStudio!.editedDatas.FirstRootObject && roomStudio!.editedDatas.FirstRootObject.Informations.Behaviours)
                return roomStudio!.editedDatas.FirstRootObject.Informations.Behaviours.includes("Configurable");
            else 
                return false;
        }
        else {
            if(productStudio && productStudio.rootObject && productStudio.rootObject.FirstRootObject && productStudio.rootObject.FirstRootObject.Informations.Behaviours)
                return productStudio.rootObject.FirstRootObject.Informations.Behaviours.includes("Configurable");
            else
                return false;
        }
    }

    function ObjectIsDeletable(): boolean {
        if (IsRoomStudio()) {
            if (roomStudio!.editedDatas && roomStudio!.editedDatas.FirstRootObject && roomStudio!.editedDatas.FirstRootObject.Informations.Behaviours)
                return roomStudio!.editedDatas.FirstRootObject.Informations.Deletable;
        } else {
            if (productStudio && productStudio.selectedEntity && productStudio.selectedEntity.HasComponentOfType(InfoComponent)){
                return productStudio.selectedEntity.GetComponentOfType(InfoComponent).Info.Deletable;
            }
        }
        return false;
    }

    return (<>
            <button hidden={!ObjectIsDeletable()} style={{position: 'absolute', top: 180, left: 16}} onClick={()=>{
                    let returnObjectRoot = IsRoomStudio() ? roomStudio?.editedDatas?.FirstRootObject : productStudio?.rootObject?.FirstRootObject;
                    let returnSelected = IsRoomStudio() ? roomStudio?.selectedObject : productStudio.productStudioApp?.Services.DataAccess.GetBasicObject(productStudio.selectedEntity?.GetComponentOfType(ObjectComponent).Ref!);
                    props.deleteCallback(returnObjectRoot, returnSelected);
                }}>
                    Delete
                </button>
            </>
    );
};

export default DebugDelete;
