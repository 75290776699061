import { EditionType } from "./EditionType";

export default class PartEditionReport {

    protected m_ref: string;

    protected m_editionType: EditionType;
    
    protected m_partType: string;

    protected m_assetType?: string;
    
    public constructor(p_ref: string, p_editionType: EditionType, p_partType: string, p_assetType: string|undefined = undefined) {
        if (!p_ref) throw new Error('NullReferenceException : p_ref is null or undefined or empty');
        if (!p_partType) throw new Error('NullReferenceException : p_partType is null or undefined or empty');
        if (p_editionType == null) throw new Error('NullReferenceException : p_assetTypeEdition is null or undefined');
        
        this.m_assetType = p_assetType;
        this.m_partType = p_partType;
        this.m_editionType = p_editionType;
        this.m_ref = p_ref;
    }
    
    public get Ref() : string {
        return this.m_ref;
    }

    public get PartType() : string {
        return this.m_partType;
    }

    public get AssetType() : string|undefined {
        return this.m_assetType;
    }
    
    public get EditionType() : EditionType {
        return this.m_editionType;
    }
}