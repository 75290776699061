import React from 'react';
import { WebGLRenderer } from 'three';
import { THREEx } from '../../Librairies/ThreeRendererStat/threex.rendererstats';

type PolyStatsProps = {
    bottom?: number;
    left?: number;
};

export default class PolyStatsTool extends React.Component<PolyStatsProps> {
    private readonly m_positionX: number | undefined;

    private readonly m_positionY: number | undefined;

    private readonly m_renderStats: { domElement: HTMLDivElement; update: (webGLRenderer: WebGLRenderer) => void };

    constructor(props: PolyStatsProps) {
        super(props);
        this.m_positionY = props.bottom ? props.bottom : 30;
        this.m_positionX = props.left ? props.left : 30;
        this.m_renderStats = THREEx.RendererStats();

        this.m_renderStats.domElement.style.position = 'absolute';
        this.m_renderStats.domElement.style.left = this.m_positionX + 'px';
        this.m_renderStats.domElement.style.bottom = this.m_positionY + 'px';
    }

    componentDidMount() {
        document.body.appendChild(this.m_renderStats.domElement);
    }

    componentWillUnmount() {
        document.body.removeChild(this.m_renderStats.domElement);
    }

    public Update(p_renderer: WebGLRenderer) {
        this.m_renderStats.update(p_renderer);
    }

    render() {
        return (
            <>
                <div></div>
            </>
        );
    }
}
