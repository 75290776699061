import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Engine } from '@lutithree/build/Engine';
import { EngineViewer } from "../EngineViewer/EngineViewer";
import ProductStudioApp from "../../Librairies/Studios/ProductStudio/ProductStudioApp";
import {ProductStudioState, setProductStudioApp} from "../../Redux/Reducers/product-studio/ProductStudioReducer";
import {RootState} from "../../../../services/redux/root-reducers";

export type ProductStudioViewerProps = {};

const ProductStudioViewer = (props: ProductStudioViewerProps) => {
    const dispatch = useDispatch();
    const { productStudioApp } = useSelector<RootState, ProductStudioState>((state) => state.productStudio);

    const buildApplication3d = (p_dom: HTMLDivElement) => {
        let engine = new Engine(p_dom);
        let appPromise = new Promise<ProductStudioApp>((resolve, reject) => {
            engine.Initialize().then(() => {
                let appliclation3d = new ProductStudioApp(engine);
                engine.Start();
                resolve(appliclation3d);
                dispatch(setProductStudioApp(appliclation3d));
            });
        });
    };
    
    const updateApplicationDomNode = (p_dom: HTMLDivElement) =>{
        if(productStudioApp && p_dom){
            productStudioApp.EngineService.UpdateDom(p_dom);
        }
    };

    return (
        <>
            <EngineViewer 
                engine={productStudioApp? productStudioApp.EngineService : null}
                onDomNodeEnable={(p_dom: HTMLDivElement) => buildApplication3d(p_dom)}
                onDomNodeUpdate={(p_dom: HTMLDivElement) => updateApplicationDomNode(p_dom)}
            />
        </>
    );
};

export { ProductStudioViewer };
