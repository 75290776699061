import { RaycastableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/RaycastableComponent';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { GroupComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/GroupComponent';
import { Vector3 } from 'three';
import { MeshLinksComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshLinksComponent';
import {
    ViewModeFilter
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Cameras/Components/ViewModeFilter";
import {
    IEntityDecorator
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/IEntityDecorator";
import {
    ViewMode
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Cameras/ViewMode";


export class GripDecorator implements IEntityDecorator<SceneEntity> {
    private m_parent: SceneEntity;

    public constructor(p_parent: SceneEntity) {
        if (p_parent == null) throw new Error('NullReferenceException : m_parent is null or undefined');

        this.m_parent = p_parent;
    }

    public Decorate(p_entity: SceneEntity): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        if (this.m_parent.HasComponentOfType(GroupComponent)) {
            let group = this.m_parent.GetComponentOfType(GroupComponent);
            p_entity.AddComponentOfType(GroupComponent, group.GroupRef);

            let wallForward: Vector3 = new Vector3();
            this.m_parent.Transform.UpdateMatrixWorldInChildren();
            this.m_parent.Transform.GetObject().updateMatrix();
            this.m_parent.Transform.GetObject().getWorldDirection(wallForward);

            p_entity.Transform.UpdateMatrixWorldInChildren();
            p_entity.AddComponentOfType(MeshLinksComponent, wallForward.clone().negate());

            p_entity.AddComponentOfType(RaycastableComponent);
            p_entity.AddComponentOfType(ViewModeFilter, [ViewMode.OrtographicTop]);
        }
    }
}
