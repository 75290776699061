import { Engine } from '@lutithree/build/Engine';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import ObjectService from "../../Application3D/GameLogic/Objects/AssetAssembly/ObjectService";
import ScreenshotService from "../../Application3D/GameLogic/Features3D/Screenshot/ScreenshotService";
import ProductCameraService from "./Studio/Cameras/ProductCameraService";
import TSBasicNotifierService from "../../Application3D/GameLogic/Notifier/TSBasicNotifierService";
import IBasicNotifier from "../../Application3D/Domain/Notifier/IBasicNotifier";
import { ObjectDecorator } from "./Objects/ObjectDecorator";
import SelectionService from "../../Application3D/GameLogic/Features3D/Selection/SelectionService";
import InteractiveConnectorsService from "../../Application3D/GameLogic/Features3D/UI/InteractiveConnectorsService";
import { BasicSelectionEffects } from "../../Application3D/GameLogic/Features3D/Selection/BasicSelectionEffects";
import BackgroundModeService from "./Studio/BackgroundModeService";
import CamerasService from "../../Application3D/GameLogic/Studio/Cameras/CamerasService";
import DimensionService from "../../Application3D/GameLogic/Features3D/Dimensions/DimensionService";
import IObjectDataAccess from "../../Application3D/Domain/Objects/IObjectDataAccess";
import AObjectBehaviour from "../../Application3D/GameLogic/Objects/AObjectBehaviour";
import ObjectDatas from "../../Application3D/Domain/Objects/ObjectDatas";

export default class ProductStudioServices {

    private readonly m_datas: ObjectDatas;
    
    private readonly m_selection: SelectionService;

    private readonly m_selectionEffects: BasicSelectionEffects;
    
    private readonly m_objects: ObjectService;

    private readonly m_screenshot: ScreenshotService;

    private readonly m_productCameras: ProductCameraService;
    
    private readonly m_cameras: CamerasService;
    
    private readonly m_notifier: TSBasicNotifierService;

    private readonly m_connectorsService: InteractiveConnectorsService;
    
    private readonly m_backgroundService: BackgroundModeService;

    private readonly m_dimensions: DimensionService;
    
    private readonly m_behaviour: AObjectBehaviour;
    
    
    public constructor(p_engine: Engine, p_rootEntities: Map<string, SceneEntity>) {
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');

        this.m_datas = new ObjectDatas();
        this.m_screenshot = new ScreenshotService(p_engine);
        let objectDecorator = new ObjectDecorator(p_engine);
        this.m_objects = new ObjectService(p_engine, this.m_datas, objectDecorator);
        this.m_cameras = new CamerasService(p_engine);
        this.m_productCameras = new ProductCameraService(p_engine);
        this.m_notifier = new TSBasicNotifierService();
        this.m_connectorsService = new InteractiveConnectorsService(p_engine);
        this.m_selection = new SelectionService(p_engine);
        this.m_selectionEffects = new BasicSelectionEffects(p_engine, this.m_connectorsService);
        this.m_backgroundService = new BackgroundModeService(p_engine);
        this.m_dimensions = new DimensionService(p_engine);
        this.m_behaviour = new AObjectBehaviour(p_engine, this.m_cameras);
    }

    public get DataAccess(): IObjectDataAccess {
        return this.m_datas;
    }


    public get Selection(): SelectionService {
        return this.m_selection;
    }
    
    public get Behaviour(): AObjectBehaviour {
        return this.m_behaviour;
    }

    public get SelectionEffects(): BasicSelectionEffects {
        return this.m_selectionEffects;
    }

    public get Connectors(): InteractiveConnectorsService {
        return this.m_connectorsService;
    }

    public get Notifier(): IBasicNotifier {
        return this.m_notifier;
    }
    
    public get Objects(): ObjectService {
        return this.m_objects;
    }

    public get Screenshot(): ScreenshotService {
        return this.m_screenshot;
    }

    public get Cameras(): CamerasService {
        return this.m_cameras;
    }
    
    public get ProductCameras(): ProductCameraService {
        return this.m_productCameras;
    }
    
    public get BackgroundService(): BackgroundModeService {
        return this.m_backgroundService;
    }

    public get Dimemsions(): DimensionService {
        return this.m_dimensions;
    }
}
