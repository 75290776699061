import React, { useEffect, useState } from 'react';
import DebugReactComponent from '../Debug/DebugReactComponent';
import ServicesManager from './DebugScripts/ServicesManager/ServicesManager';
import DebugBuilder from './DebugScripts/DebugBuilder';
import Debug from './DebugScripts/Debug';
import { ConfigData } from "../../Librairies/Studios/Application3D/Domain/ConfigData";
import RendererUpdatedTSEvent from "../../Librairies/Studios/Application3D/GameLogic/Notifier/TSEvents/RendererUpdatedTSEvent";

export type DebugManagerProps = {
    config: ConfigData;
};

const DebugManager = (props: DebugManagerProps) => {
    const [debugManager, setDebugManager] = useState<ServicesManager | undefined>();

    useEffect(() => {
        setDebugManager(new DebugBuilder(props.config).BuildDebugStrategy());
    }, []);

    useEffect(() => {
        function handleRendererUpdated(ev: CustomEvent<RendererUpdatedTSEvent>) {
            Debug.Update(ev.detail.Renderer);
        }
        
        if (debugManager) {
            Debug.SetDebugStrategy(debugManager);
            window.addEventListener('application3d-rendererUpdated', handleRendererUpdated);
        }
        return () => {
            if (debugManager) {
                window.removeEventListener('application3d-rendererUpdated', handleRendererUpdated);
            }
        };
    }, [debugManager]);

    return <>{props.config.mode == 'Debug' && <DebugReactComponent />}</>;
};

export { DebugManager };
