import { DisableComponent } from '@lutithree/build/Modules/Core/Entity/DisableComponent';
import { Vector3 } from 'three';

export class InversibleComponent extends DisableComponent {
    private m_inversionVector: Vector3;

    public constructor(p_invertOnX: boolean, p_invertOnY: boolean, p_invertOnZ: boolean) {
        super();
        if (p_invertOnX == null) throw new Error('NullReferenceException : p_invertOnX is null or undefined');
        if (p_invertOnY == null) throw new Error('NullReferenceException : p_invertOnY is null or undefined');
        if (p_invertOnZ == null) throw new Error('NullReferenceException : p_invertOnZ is null or undefined');

        let x = 1;
        let y = 1;
        let z = 1;

        if (p_invertOnX) x *= -1;
        if (p_invertOnY) y *= -1;
        if (p_invertOnZ) z *= -1;

        this.m_inversionVector = new Vector3(x, y, z);
    }

    public get InversionVector(): Vector3 {
        return this.m_inversionVector;
    }
}
