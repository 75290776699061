import { createSlice } from '@reduxjs/toolkit';

export type ContextualMenuState = {
    paintIcon: boolean;
    scaleIcon: boolean
    remplaceIcon: boolean
    editIcon: boolean
    duplicateIcon: boolean
};

const initialContextualMenuState: ContextualMenuState = {
    paintIcon: false,
    scaleIcon: false,
    remplaceIcon: false,
    editIcon: false,
    duplicateIcon: false,
};

export const contextualMenuSlice = createSlice({
    name: 'contextualMenu',
    initialState: initialContextualMenuState,
    reducers: {
        setPaintIcon: (state, action) => {
            state.paintIcon = action.payload;
        },
        setScaleIcon: (state, action) => {
            state.scaleIcon = action.payload;
        },
        setRemplaceIcon: (state, action) => {
            state.remplaceIcon = action.payload;
        },
        setEditIcon: (state, action) => {
            state.editIcon = action.payload;
        },
        setDuplcateIcon: (state, action) => {
            state.duplicateIcon = action.payload;
        },
    },
});

export const { setPaintIcon,setRemplaceIcon,setScaleIcon,setEditIcon,setDuplcateIcon } = contextualMenuSlice.actions;
