import React, { useEffect, useState } from 'react';
import './PointOfViewPreset.scss';
import { ProductStudioViewer } from './ProductStudioViewer';
import { useDispatch, useSelector } from 'react-redux';
import SingleObjectLoader from './SingleObjectLoader';
import ObjectEditor from '../ObjectEditor/ObjectEditor';
import BasicObject from '../../Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject';
import { ProductStudioMode } from '../../Librairies/Studios/ProductStudio/Domain/Modes/ProductStudioMode';
import { Euler, Spherical, Vector3 } from 'three';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import ProductStudioListner from './ProductStudioListner';
//import EntityMenu from '../UI/Composites/EntityMenu/EntityMenu';
import { getBasicObjectFromList } from '../../Utils/get-object';
import Information from '../../Librairies/Studios/Application3D/Domain/Objects/Information';
import { PointOfViewPlacement } from '../../Librairies/Studios/Application3D/Domain/Features3D/PointOfViews';
import ResourceErrorData from '../../Librairies/Studios/Application3D/Domain/Objects/ResourceErrorData';
import { ResourceErrorHandler } from '../ResourceErrorHandler/ResourceErrorHandler';
import PointOfViewManager from '../PointOfView/PointOfViewManager';
import ObjectComponent from "../../Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";
import {
    ProductStudioState,
    saveCameraPointOfView, setAllPointOfViewPlacements,
    setPointOfViewPlacement, SetRoot, setSelectedObject
} from '../../Redux/Reducers/product-studio/ProductStudioReducer';
import {RootState} from "../../../../services/redux/root-reducers";
import ObjectDatas from "../../Librairies/Studios/Application3D/Domain/Objects/ObjectDatas";


export type ProductStudioProps = {
    datas: ObjectDatas | undefined
    product: BasicObject
    cameraPointOfView?: Spherical
    objectPosition?: undefined | Vector3
    objectRotation?: undefined | Euler
    onProductLoaded?: () => void
    onObjectEdited?: () => void
    isVisible?: boolean
    isPovIhmVisible?:boolean;
    setOpenPanel?: (openPanel: boolean) => void;
    openPanel?: boolean;
    setOpenEditPanel?: (openEditPanel: boolean) => void;
    onLoadingFail?: (p_resourceErrorData: ResourceErrorData) => void;
};

const ProductStudio = (props: ProductStudioProps) => {
    const dispatch = useDispatch();
    const {
        productStudioApp,
        productStudioMode,
        cameraPointOfViewSaving,
        selectedEntity,
        placements,
        currentPointOfView,
    } = useSelector<RootState, ProductStudioState>((state) => state.productStudio);
    const [productEntity, setProductEntity] = useState<SceneEntity | undefined>(undefined);
    const [currentObjectInfo, setObjectInfo] = useState<Information | undefined>(undefined);
    const [isDataInitialized, setIsDataInitialized] = useState<boolean>(false);

    const GetPdvId = (sherical: Spherical|undefined, allPlacements:  {placement: PointOfViewPlacement, position: Spherical}[]) => {
        if(sherical) {
            let allowedThresholdRad: number = 0.0001;
            let objectPlacement :PointOfViewPlacement|undefined = undefined;
            allPlacements.forEach((placement)=>{
                let phiDelta = Math.abs(sherical!.phi-placement.position.phi);
                let thetaDelta = Math.abs(sherical!.theta-placement.position.theta);
                if(phiDelta < allowedThresholdRad && thetaDelta < allowedThresholdRad) objectPlacement = placement.placement;
            });
            if(objectPlacement == undefined) objectPlacement = PointOfViewPlacement.FREE;
            return objectPlacement;
        }
        return PointOfViewPlacement.THREE_QUARTER_OFF;
    };

    const SaveMainCamPosition = () => {
        if (productStudioApp && productEntity) {
            if (!productStudioApp.Services.Cameras.CurrentCamera) throw new Error('main camera is null');
            let camera = productStudioApp.Services.Cameras.CurrentCamera.GetObject();
            let camRelativePosFromTarget = productStudioApp.Services.Cameras.GetRelativePosFromTarget(camera, productEntity.Transform.GetObject());
            dispatch(saveCameraPointOfView(camRelativePosFromTarget));
        }
    };
    const ApplySavedPositionToMainCam = (p_placements:  {placement: PointOfViewPlacement, position: Spherical}[]) => {
        if (cameraPointOfViewSaving && productEntity){
            productStudioApp?.Services.Cameras.ApplyRelativeSphericalPosFromTarget(cameraPointOfViewSaving, productEntity.Transform.GetObject());
            if(props.product) {
                let placement = GetPdvId(cameraPointOfViewSaving, p_placements);
                dispatch(setPointOfViewPlacement(placement));
            }
        }
        else
            console.warn('There was no saved position');
    };

    useEffect(() => {
        if(!props.product)
        {
            console.warn("Props.product was null");
            return;
        }
        let placement = GetPdvId(props.product.PointOfView, placements);
        if (props.product) dispatch(setPointOfViewPlacement(placement));
    }, [placements]);


    useEffect(() => {
        return () => {
            productStudioApp?.Services.Selection.UnSelectAll();
        };
    }, [productStudioApp]);

    useEffect(() => {
        if (productStudioApp && props.cameraPointOfView && productEntity) {
            productStudioApp.Services.Cameras.ApplyRelativeSphericalPosFromTarget(props.cameraPointOfView, productEntity.Transform.GetObject());
        }
        if (productStudioApp && props.cameraPointOfView === undefined && productEntity) {
            productStudioApp.Services.Cameras.FocusPerspectiveCameraOnObjects([productEntity.Transform.GetObject()], 1.2);
        }

    }, [productStudioApp, props.cameraPointOfView, productEntity]);

    useEffect(() => {
        if (!props.product) {
            console.warn("Props.product was null");
            return;
        }
        if (selectedEntity && selectedEntity.HasComponentOfType(ObjectComponent)) {
            let objectComponent = selectedEntity.GetComponentOfType(ObjectComponent);
            if (props.product.Composition.Type === 'BasicObject' || props.product.Composition.Type === 'RoomObject') {
                let object = getBasicObjectFromList(objectComponent.Ref, props.product.Composition.PartElements as BasicObject[]);
                if (object) dispatch(setSelectedObject(object));
            }
        } else {
            dispatch(setSelectedObject(undefined));
        }
    }, [selectedEntity]);

    useEffect(() => {
        dispatch(SetRoot(props.datas));
        if(props.datas)
            productStudioApp?.Services.DataAccess.SetDatas(props.datas?.Instances3D, props.datas?.BasicObjects);
    }, [props.datas]);
    
    useEffect(() => {
        if (productStudioApp) {
            productStudioApp.ModesService.SetMode(ProductStudioMode[productStudioMode]);
        }
    }, [productStudioMode, productStudioApp]);

    useEffect(() => {
        if (currentPointOfView !== PointOfViewPlacement.TOP
            && currentPointOfView !== PointOfViewPlacement.TOP_90
            && currentPointOfView !== PointOfViewPlacement.THREE_QUARTER
            && currentPointOfView !== PointOfViewPlacement.THREE_QUARTER_OFF
            && currentPointOfView !== PointOfViewPlacement.FRONT) {
            dispatch(setPointOfViewPlacement(PointOfViewPlacement.FREE));
        }
    }, [currentPointOfView]);

    return (
        <>
            {props.isVisible && <div className='st'>
            { productEntity && productStudioApp &&
                <PointOfViewManager
                    cameraService={ productStudioApp.Services.Cameras }
                    cameraPointOfView={ currentPointOfView }
                    allPlacements={ placements }
                    object={ productEntity.Transform.GetObject() }
                    debugMode={ false }
                    setAllPovs={ (p_povs: Array<{ placement: PointOfViewPlacement, position: Spherical }>) => {
                        dispatch(setAllPointOfViewPlacements(p_povs));
                    } }
                    setCurrentPov={ (p_pov: number) => {
                        dispatch(setPointOfViewPlacement(p_pov));
                    } }
                /> }

            { (props.isVisible === undefined || props.isVisible) && (
                <>
                    <ProductStudioViewer />
                    {/* selectedEntity && (
                        <EntityMenu
                            openPanel={ props.openPanel }
                            setOpenPanel={ props.setOpenPanel }
                            setOpenEditPanel={ props.setOpenEditPanel }
                            currentEntity={ selectedEntity }
                        />
                    ) */}

                </>
            ) }

            { productStudioApp && props.datas && props.product && (
                <>
                    <ProductStudioListner app={ productStudioApp } />
                    <ResourceErrorHandler onLoadingFail={ props.onLoadingFail } />
                    <SingleObjectLoader
                        dataAccess={productStudioApp.Services.DataAccess}
                        objectEditor={ productStudioApp.Services.Objects }
                        cameraService={ productStudioApp.Services.Cameras }
                        datas={props.datas}
                        product={ props.product }
                        onProductLoaded={ props.onProductLoaded }
                        cameraPointOfView={props.cameraPointOfView}
                        objectPosition={ props.objectPosition }
                        objectRotation={ props.objectRotation }
                        mainEntity={ productEntity }
                        setMainEntity={ setProductEntity }
                    />
                    
                </>
            ) }
            {productStudioApp && props.datas && props.product && (
                    <ObjectEditor
                            editor={productStudioApp.Services.Objects}
                            editedDatas={props.datas}
                    />  
            )}
            { props.isPovIhmVisible === true && (
                <>
                    <div className='point-view-preset-main'>
                        Point de vue
                        <select value={ currentPointOfView }  onChange={ (v) => {
                            dispatch(setPointOfViewPlacement(parseInt(v.target.value)));
                        } }>
                            <option value={ PointOfViewPlacement.TOP }>TOP</option>
                            <option value={ PointOfViewPlacement.TOP_90 }>TOP_90</option>
                            <option value={ PointOfViewPlacement.THREE_QUARTER }>THREE_QUARTER</option>
                            <option value={ PointOfViewPlacement.THREE_QUARTER_OFF }>THREE_QUARTER_OFF</option>
                            <option value={ PointOfViewPlacement.FRONT }>FRONT</option>
                            <option value={ PointOfViewPlacement.FREE }>FREE</option>
                        </select>
                        <br />
                        <div className='point-view-preset-copy-paste'>
                            <button
                                className='point-view-preset-copy'
                                onClick={ SaveMainCamPosition }>Copier
                            </button>
                            <button
                                className='point-view-preset-paste'
                                onClick={  ()=>ApplySavedPositionToMainCam(placements) }>Coller
                            </button>
                        </div>
                    </div>
                </>
            ) }
        </div>}
        </>
    );
};

export default ProductStudio;
