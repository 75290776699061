import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { SelectableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SelectableComponent";
import { AEntityEvent } from "@lutithree/build/Modules/Core/Entity/Events/AEntityEvent";

export default class EntitySelectionStatusDirtyEvent extends AEntityEvent<SceneEntity> {
    private m_selectable: SelectableComponent;

    public constructor(p_entity: SceneEntity, p_selectable: SelectableComponent) {
        super(p_entity);
        if (p_selectable == null) throw new Error('NullReferenceException : p_selectable is null or undefined');

        this.m_selectable = p_selectable;
    }

    public get Selectable(): SelectableComponent {
        return this.m_selectable;
    }
}