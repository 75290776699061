import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

interface SelectDropdownType {
    items: any;
    onChange: (event: string) => void;
    setItemId?: (id: number) => void;
    selected: string;
    title?: string;
    error?: string;
}

const BasicSelect = ({ items, onChange, selected, title, setItemId }: SelectDropdownType) => {
    const handleSelectId = (id: number): void => {
        if (setItemId) {
            setItemId(id);
        }
    };

    return (
        <>
            <FormControl color="primary" fullWidth>
                <InputLabel id={`simple-select-label-${title}`}>{title}</InputLabel>
                <Select
                    labelId={`simple-select-label-${title}`}
                    id={`simple-select-${title}`}
                    value={selected}
                    label={title}
                    onChange={(e) => {
                        onChange(e.target.value as string);
                    }}
                >
                    {items.map((el: { id: number; name: string }, index: React.Key | null | undefined) => {
                        return (
                            <MenuItem onClick={() => handleSelectId(el.id)} key={index} value={el.name}>
                                {el.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </>
    );
};

export default BasicSelect;
