import './textfield.scss';

export type TextfieldProps = {
    row?: number;
    secure?: boolean;
    type?: string;
    placeholder?: string;
    onChange?: (value: string) => void;
    value?: string | number;
    defaultValue?: string | number;
    name?: string;
    validation?: string;
    className?: string;
    isError?: boolean;
    errorMessage?: string;
    label?: string;
    width?: number;
};

export const Textfield = (
    {
        name,
        label,
        row = 0,
        type,
        placeholder,
        onChange,
        value,
        defaultValue,
        validation,
        className,
        isError = false,
        errorMessage,
        width,
    }: TextfieldProps) => {
    return (
        <div>
            {row === 0 ? (
                <div className={`inputMain ${className}`}>
                    <label>{label}</label>
                    <input
                        style={{ width: `${width}px` }}
                        name={name}
                        type={type}
                        value={value}
                        defaultValue={defaultValue}
                        className={`form-control  ${isError ? 'error' : ''}`}
                        placeholder={placeholder}
                        onChange={(e) => {
                            onChange && onChange(e.target.value);
                        }}
                    />
                    {errorMessage ? <p className='errorMess'> {errorMessage}</p> : <p></p>}
                </div>
            ) : (
                <div className='textAreaMain'>
                    <textarea
                        name={name}
                        value={value}
                        className={`form-control  ${isError ? 'errorMess' : ''}`}
                        placeholder={placeholder}
                        onChange={(e) => {
                            onChange && onChange(e.target.value);
                        }}
                        rows={row}
                    />
                    {errorMessage ? <p className='errorMess'> {errorMessage}</p> : <p></p>}
                </div>
            )}
        </div>
    );
};

export default Textfield;
