import { MappingDTO, MdfApiResponse, Model3DCellValue, Model3dCellValueByPartItemReference, Model3dTree, ModelTree, ProductTree } from '../../../domain/domain';
import apiClient from '../../api-client';
import {
    BUILD_MAPPING_TREE,
    CHANGE_ATOM_VISIBILITY,
    CREATE_MODEL_CELL_VALUE,
    FIND_MODEL_3D_FOR_COMBO,
    GET_CELL_VALUE_TREE,
    GET_DEFAULT_CONFIGURATION,
    GET_MODEL_CELL_VALUES_BY_PART_ITEM_REF,
    RENAME_ATOM_LABEL,
} from '../../enpoint/3DCellsValue/3DCellValue.endpoint';

const cells3dValuesService = {
    create3dCellValue: async (model3dReference: string, model3dCellValue: Model3DCellValue) => apiClient(CREATE_MODEL_CELL_VALUE(model3dReference), model3dCellValue, undefined, 'POST'),
    get3dCellValues: async (data: Model3dCellValueByPartItemReference) => apiClient(GET_MODEL_CELL_VALUES_BY_PART_ITEM_REF, { partItemReferenceList: data.partItemReferenceList }, undefined, 'POST'),
    get3dCellValuesTree: async (productRef: string) => apiClient(GET_CELL_VALUE_TREE(productRef), undefined, undefined, 'GET'),
    buildMappingTree: async (productRef: string, productTree: ProductTree) => apiClient(BUILD_MAPPING_TREE(productRef), productTree, undefined, 'POST'),
    getDefaultConfiguration: async (productRef: string) => apiClient(GET_DEFAULT_CONFIGURATION(productRef), undefined, undefined, 'GET'),
    change3dCellVisibility: async (partReference: string, partItemRefererence: string, visibility: string) =>
        apiClient(CHANGE_ATOM_VISIBILITY(partReference, partItemRefererence, visibility), undefined, undefined, 'POST'),
    rename3dCellValuesLabel: async (atomRef: string, label: string) => apiClient(RENAME_ATOM_LABEL(atomRef, label), undefined, undefined, 'PUT'),
    getModel3dUrlForCombo: async (atomReference: ModelTree[], setMappingList: (mappings: MappingDTO[]) => void) => {
        const response: MdfApiResponse<Model3dTree[]> = await apiClient(FIND_MODEL_3D_FOR_COMBO(), atomReference, undefined, 'POST');
        setMappingList(response.content[0].mappings);
        return response;
    },
};

export default cells3dValuesService;
