import type { ColorScheme } from '../../types';


export const colorScheme: ColorScheme[] = [
    {
        'name': 'Bleu',
        'labelAssociated': '#2252CD',
        'backgroundColor': '#D6E0FF',
    },
    {
        'name': 'orange',
        'labelAssociated': '#F8A12D',
        'backgroundColor': '#FFE8C3',
    },
    {
        'name': 'Vert',
        'labelAssociated': '#34A074',
        'backgroundColor': '#BAF7DE',
    },
    {
        'name': 'Violet',
        'labelAssociated': '#8C62D3',
        'backgroundColor': '#ECE4F9',
    },
    {
        'name': 'Rouge',
        'labelAssociated': '#F32C60',
        'backgroundColor': '#FDD4DF',
    },
    {
        'name': 'Ciel',
        'labelAssociated': '#07A5C5',
        'backgroundColor': '#E5F6F9',
    },
    {
        'name': 'Sapin',
        'labelAssociated': '#009A56',
        'backgroundColor': '#E5F5EE',
    },
    {
        'name': 'Gris',
        'labelAssociated': '#5B7D8C',
        'backgroundColor': '#EEF2F3',
    },
    {
        'name': 'Brun',
        'labelAssociated': '#7C5547',
        'backgroundColor': '#F2EDEC',
    },
    {
        'name': 'Jaune',
        'labelAssociated': '#FFC403',
        'backgroundColor': '#FFF9E5',
    },
];