import { IDisposableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/IDisposableComponent";
import { DisableComponent } from "@lutithree/build/Modules/Core/Entity/DisableComponent";
import { BufferGeometry, DoubleSide, Group, Material, Object3D, Texture, Vector3, WebGLRenderTarget } from "three";
import { Line } from "@lutithree/build/Modules/WebGL/Primitives/Graphics/Line";
import { StickerBillboard } from "@lutithree/build/Modules/WebGL/Primitives/Graphics/StickerBillboard";
import { Guid } from "guid-typescript";
import RelativeDistanceInfo from "../RelativeDistanceInfo";
import { IObjectComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/IObjectComponent";

export enum DistanceDirection {
    FORWARD ,
    BACKWARD,
    RIGHT,
    LEFT,
    UP,
    DOWN
}

export class RelativeDistanceDisplayerComponent extends DisableComponent implements IDisposableComponent, IObjectComponent<Object3D> {
    private m_targetEntityGUID: Guid;

    private readonly m_relativeDisplayerObject: Object3D;

    private m_relativeDistancesInfos: RelativeDistanceInfo[] = [];
    
    public m_distanceMap: Map<DistanceDirection, {p_line: Line, p_sticker: StickerBillboard}> = new Map<DistanceDirection, { p_line: Line, p_sticker: StickerBillboard }>();

    private m_color: string = '#1D2740';
    
    constructor(p_targetEntityGUID: Guid) {
        super();
        if (p_targetEntityGUID == null) throw new Error('NullReferenceException : p_targetEntityGUID is null or undefined');
        
        this.m_targetEntityGUID = p_targetEntityGUID;
        
        let forwardData = { p_line: new Line(new Vector3(), new Vector3(), this.m_color), p_sticker: new StickerBillboard("0", this.m_color, new Vector3(), new Vector3()) };
        let backwardData = { p_line: new Line(new Vector3(), new Vector3(), this.m_color), p_sticker: new StickerBillboard("0", this.m_color, new Vector3(), new Vector3()) };
        let rightData = { p_line: new Line(new Vector3(), new Vector3(), this.m_color), p_sticker: new StickerBillboard("0", this.m_color, new Vector3(), new Vector3()) };
        let leftData = { p_line: new Line(new Vector3(), new Vector3(), this.m_color), p_sticker: new StickerBillboard("0", this.m_color, new Vector3(), new Vector3()) };
        let upData = { p_line: new Line(new Vector3(), new Vector3(), this.m_color), p_sticker: new StickerBillboard("0", this.m_color, new Vector3(), new Vector3()) };
        let downData = { p_line: new Line(new Vector3(), new Vector3(), this.m_color), p_sticker: new StickerBillboard("0", this.m_color, new Vector3(), new Vector3()) };

        if(!Array.isArray(forwardData.p_line.ThreeLine.material)) forwardData.p_line.ThreeLine.material.side = DoubleSide;
        if(!Array.isArray(backwardData.p_line.ThreeLine.material)) backwardData.p_line.ThreeLine.material.side = DoubleSide;
        if(!Array.isArray(rightData.p_line.ThreeLine.material)) rightData.p_line.ThreeLine.material.side = DoubleSide;
        if(!Array.isArray(leftData.p_line.ThreeLine.material)) leftData.p_line.ThreeLine.material.side = DoubleSide;
        if(!Array.isArray(upData.p_line.ThreeLine.material)) upData.p_line.ThreeLine.material.side = DoubleSide;
        if(!Array.isArray(downData.p_line.ThreeLine.material)) downData.p_line.ThreeLine.material.side = DoubleSide;
       
        this.m_distanceMap.set(DistanceDirection.FORWARD, forwardData);
        this.m_distanceMap.set(DistanceDirection.BACKWARD, backwardData);
        this.m_distanceMap.set(DistanceDirection.RIGHT, rightData);
        this.m_distanceMap.set(DistanceDirection.LEFT, leftData);
        this.m_distanceMap.set(DistanceDirection.UP, upData);
        this.m_distanceMap.set(DistanceDirection.DOWN, downData);
        
        this.m_relativeDisplayerObject = new Group();
        this.m_relativeDisplayerObject.name = "RelativePositionner_object3D";
        this.m_relativeDisplayerObject.attach(forwardData.p_line.ThreeLine);
        this.m_relativeDisplayerObject.attach(forwardData.p_sticker.m_stickerObject);
        this.m_relativeDisplayerObject.add(backwardData.p_line.ThreeLine, backwardData.p_sticker.m_stickerObject);
        this.m_relativeDisplayerObject.add(rightData.p_line.ThreeLine, rightData.p_sticker.m_stickerObject);
        this.m_relativeDisplayerObject.add(leftData.p_line.ThreeLine, leftData.p_sticker.m_stickerObject);
        this.m_relativeDisplayerObject.add(upData.p_line.ThreeLine, upData.p_sticker.m_stickerObject);
        this.m_relativeDisplayerObject.add(downData.p_line.ThreeLine, downData.p_sticker.m_stickerObject);
    }
    
    public set TargetEntityID(p_value: Guid ) {
        this.m_targetEntityGUID = p_value;
    }

    public get TargetEntityID(): Guid {
        return this.m_targetEntityGUID;
    }
    
    public GetObject(): Object3D {
        return this.m_relativeDisplayerObject;
    }
    
    public UpdateDistancesInfos(p_relativeInfos: RelativeDistanceInfo[]) {
        if (p_relativeInfos == null) throw new Error('NullReferenceException : p_relativeInfos is null or undefined');
        
        this.m_relativeDistancesInfos = p_relativeInfos;
        this.m_distanceMap.forEach((object)=> {
            this.ResetDistanceInfo(object);
        });
            p_relativeInfos.forEach((info)=> {
            let distanceDisplay = this.m_distanceMap.get(info.Direction);
            if(distanceDisplay){
                //Here lies treshold of the distanceDisplay
                if(Math.floor(info.Length * 100) > 5)
                {
                    (distanceDisplay.p_line.Geometry as any).setPoints([info.From, info.To]);
                    distanceDisplay.p_sticker.MoveSticker(info.To.clone().sub(info.From).normalize().multiplyScalar(info.Length/2).add(info.From), new Vector3());   
                    distanceDisplay.p_sticker.UpdateText(Math.floor(info.Length * 100).toString() + " cm");
                    distanceDisplay.p_sticker.m_stickerObject.visible = true;
                }
                else {
                    this.ResetDistanceInfo(distanceDisplay);
                }
            }
        });
    }
    
    public GetDisposable(): (Material | BufferGeometry | Texture | WebGLRenderTarget | Object3D)[] {
        return [this.m_relativeDisplayerObject];
    }

    private ResetDistanceInfo(p_object: {p_line: Line, p_sticker: StickerBillboard}) {
        (p_object.p_line.Geometry as any).setPoints([]);
        p_object.p_sticker.UpdateText("0");
        p_object.p_sticker.m_stickerObject.visible = false;
    }
}