import BasicObject
    from "../../Domain/Objects/AssetAssembly/BasicObject";
import Instance3D
    from "../../Domain/Objects/AssetAssembly/Instance3D";
import {ObjectParser} from "../../GameLogic/Objects/ObjectParser";
import IObjectDataAccess from "./IObjectDataAccess";

export default class ObjectDatas implements IObjectDataAccess{
    private dataModelVersion: string;

    private plannerObjects: BasicObject[] = [];

    private instances3D: Instance3D[] = [];
    
    private firstRootObject: BasicObject|undefined;

    private rootObjects: BasicObject[];
    
    public constructor() {
        this.dataModelVersion = '';
        this.instances3D = [];
        this.plannerObjects = [];
        this.rootObjects = [];
    }

    public static GetObjectDatasForObject(p_basicObject: BasicObject|undefined): ObjectDatas|undefined {
        let objectDatas = new ObjectDatas();
        if(p_basicObject){
            objectDatas.SetDatas([],[p_basicObject]);
        }
        else{
            return undefined;
        }

        return objectDatas;
    }

    public static GetObjectDatasForObjects(p_basicObjects: BasicObject[]): ObjectDatas {
        let objectDatas = new ObjectDatas();
        objectDatas.SetDatas([],p_basicObjects);
        return objectDatas;
    }

    public SetDatas(p_instances3D: Instance3D[], p_basicObjects: BasicObject[]): void {
        this.dataModelVersion = '1.0.0';
        
        let instances3D: Instance3D[] = [];
        p_instances3D.forEach((instance)=> {
            instances3D.push(Object.assign(new Instance3D(),instance));
        });
        this.instances3D = instances3D;
        
        let basicObjects: BasicObject[] = [];
        p_basicObjects.forEach((object)=> {
            basicObjects.push(ObjectParser.DeepParseObject(object,BasicObject));
        });
        this.plannerObjects = basicObjects;
        
        this.rootObjects = this.GetRootBasicObjects();
        if(this.rootObjects.length>0){
            this.firstRootObject = this.rootObjects[0];
        }
    }
    
    public get Instances3D(): Instance3D[] {
        if(this.instances3D.find(i => typeof i != typeof Instance3D)){
            let instances: Instance3D[] = [];
            this.instances3D.forEach((object)=> {
                instances.push(Object.assign(new Instance3D(),object));
            });
            this.instances3D = instances;
        }
        return this.instances3D;
    }

    public get BasicObjects(): BasicObject[] {
        if(this.plannerObjects.find(o => typeof o != typeof BasicObject)){
            let basicObjects: BasicObject[] = [];
            this.plannerObjects.forEach((object)=> {
                basicObjects.push(ObjectParser.DeepParseObject(object,BasicObject));
            });
            this.plannerObjects = basicObjects;
        }
        return this.plannerObjects;
    }
    
    public get FirstRootObject(): BasicObject|undefined {
        return this.firstRootObject;
    }

    public get RootObjects(): BasicObject[]{
        return this.rootObjects;
    }
    
    public get DataModelVersion(): string {
        return this.dataModelVersion;
    }

    public GetBasicObject(p_ref: string): BasicObject|undefined {
        return this.BasicObjects.find((x) => x.Ref === p_ref);
    }

    public GetBasicObjects(p_refs: string[]): BasicObject[] {
        let objects: BasicObject[]=[];
        this.BasicObjects.forEach(object => {
            if(p_refs.includes(object.RefOfInstance)){
                objects.push(object);
            }
        });
        return objects;
    }

    public GetInstance(p_ref: string): Instance3D|undefined {
        return this.Instances3D.find((x) => x.Ref === p_ref);
    }

    public GetDatas(p_ref: string): ObjectDatas {
        let objects: BasicObject[] = [];
        let instances: Instance3D[] = [];
        let data = new ObjectDatas();

        let basicObject = this.GetBasicObject(p_ref);
        if(basicObject){
            objects.push(basicObject);
            objects = objects.concat(this.GetBasicObjects(basicObject.Assembly.SubObjectsRef));
        }
        objects.forEach((object)=>{
            let instance = this.GetInstance(object.RefOfInstance);
            if(instance) instances.push(instance);
        });
        data.SetDatas(instances, objects);

        return data;
    }

    private GetRootBasicObjects(): BasicObject[]{
        let rootObjects: BasicObject[] = [];
        let filteredObjects = this.BasicObjects.filter(basicObject=>basicObject.IsRootObject===true);
        rootObjects = filteredObjects ? filteredObjects:[];
        return rootObjects;
    }
}
