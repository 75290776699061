import axios, { AxiosError, Method } from 'axios';
import MdfApiResponse from '../domain/common/generic';
import { getConfig } from './config';

const apiClient = (isBp: boolean) => {
    const axiosInstance = axios.create({
        baseURL: isBp ? getConfig().bpHostApi : getConfig().urlHostApi,
        responseType: 'json',
    });

    return axiosInstance;
};

const sendRequest = async (route: string, data: object | undefined | string, params: object | undefined, method: Method, contentType?: string, isBp = false) => {
    const token = localStorage.getItem('mdf-jwt');
    const client = apiClient(isBp);

    try {
        const req = await client({
            url: route,
            method,
            headers: {
                where: 'seller',
                Authorization: `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
                ...(contentType ? { 'Content-Type': contentType } : {}),
            },
            data: data,
            params: params,
        });
        return req.data;
    } catch (apiErr: AxiosError | unknown) {
        console.error('API ERROR', {
            route,
            data,
            params,
            method,
            apiErr,
        });
        let errorResponse: MdfApiResponse<unknown> = {
            warnings: [],
            errors: [{ code: 'G0001', message: 'an unexpected error occurred', detail: `${apiErr}` }],
            content: null,
        };
        if (!axios.isAxiosError(apiErr)) {
            return Promise.reject(apiErr);
        }

        if (apiErr.response) {
            if (apiErr.response.status) {
                if (apiErr.response.status === 401 && !window.location.href.includes('/login')) {
                    localStorage.removeItem('mdf-jwt');
                    window.location.href = '/login';
                }
            }
            if (apiErr.response.data) {
                errorResponse = apiErr.response.data;
            }
        }
        return Promise.reject(apiErr);
    }
};

export default sendRequest;
