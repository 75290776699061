import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SpeedTestService from '../../../../api/service/speed-test/speed-test-service';
import MdfApiResponse from '../../../../domain/common/generic';
import { CreateSpeedTestRecordForm, SpeedTest } from '../../../../domain/domain';

export type SpeedTestState = {
    isLoading: boolean;
    response: MdfApiResponse<SpeedTest | null>;
};

const initialState: SpeedTestState = {
    isLoading: false,
    response: { content: null, errors: [], warnings: [] },
};

export const uploadSpeedTest = createAsyncThunk('speedtest-upload', async (form: CreateSpeedTestRecordForm, thunkAPI) => {
    const response = await SpeedTestService.uploadData(form);
    return response;
});

export const uploadSpeedTestSlice = createSlice({
    name: 'uploadSpeedTestSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(uploadSpeedTest.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(uploadSpeedTest.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { response: payload, isLoading: false };
            return state;
        });
        builder.addCase(uploadSpeedTest.rejected, (state) => {
            state.isLoading = true;
        });
    },
});
