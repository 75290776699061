import DebugStrategy from './ServicesManager/ServicesManager';
import ServicesManager from './ServicesManager/ServicesManager';
import LogToConsoleService from './Services/Log/LogToConsoleService';
import PolygoneService from './Services/Statistics/PolygoneService';
import PerformanceService from './Services/Statistics/PerformanceService';
import Debug from './Debug';
import { ConfigData } from "../../../Librairies/Studios/Application3D/Domain/ConfigData";

export default class DebugBuilder {
    private readonly m_config: ConfigData;

    private m_debugStrategy: DebugStrategy | undefined;

    constructor(p_config: ConfigData) {
        this.m_config = p_config;
    }

    public BuildDebugStrategy(): ServicesManager | undefined {
        if (this.m_config.mode == 'Debug') {
            let generatedStrategy: DebugStrategy = new DebugStrategy();
            if (this.m_config.onScreenConsole) {
                generatedStrategy.AddLogService(new LogToConsoleService(Debug.EnableToolConsoling()));
            }
            if (this.m_config.polyStats) {
                generatedStrategy.AddStatisticsService(new PolygoneService(Debug.EnablePolyStats()));
            }
            if (this.m_config.perfStats) {
                generatedStrategy.AddStatisticsService(new PerformanceService(Debug.EnablePerfStats()));
            }

            this.m_debugStrategy = generatedStrategy;
            return generatedStrategy;
        }

        return undefined;
    }
}
