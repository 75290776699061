import { FrontSide, Group, Mesh, MeshBasicMaterial, Object3D, Quaternion, Shape, ShapeGeometry, Vector2, Vector3 } from 'three';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { MeshFilterComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent';
import { ShapeUtils } from '@lutithree/build/Modules/WebGL/Utils/ShapeUtils';
import { Vector2Utils } from '@lutithree/build/Modules/WebGL/Utils/Vector2Utils';
import { ASystem } from '@lutithree/build/Modules/Core/Entity/ASystem';
import { EntityManager } from '@lutithree/build/Modules/Core/Entity/EntityManager';
import { WallMeasureDecorator } from "../EntityDecorators/WallMeasureDecorator";
import { GripDecorator } from "../EntityDecorators/GripDecorator";
import WallComponent from "../Components/WallComponent";
import { CameraSystem } from "@lutithree/build/Modules/WebGL/Scene/Systems/CameraSystem";
import ShapeData from '../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/ShapeData';
import ObjectComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";

export default class WallsInitializerSystem extends ASystem<SceneEntity> {
    private readonly m_wallMaterial: MeshBasicMaterial = new MeshBasicMaterial({ color: 0x999999, side: FrontSide });

    private readonly m_wallHelperWidth: number = 0.4;
    
    private m_camSystem: CameraSystem;

    public constructor(p_scene: EntityManager<SceneEntity>, p_camSystem: CameraSystem) {
        super(p_scene);
        
        this.m_camSystem = p_camSystem;
    }

    public SetupWalls(p_entities: SceneEntity[], p_shape: ShapeData): void {
        if (p_entities == null) throw new Error('NullReferenceException : p_entities is null or undefined');
        if (p_shape == null) throw new Error('NullReferenceException : p_shape is null or undefined');
        
        let orderedWalls = this.OrderWallsByRefOfSection(p_entities, p_shape.RefOfSections);

        // Add wall Grips
        let grips = this.AddGrips(orderedWalls, p_shape.Points, 2.5);
        this.AddWallMeasures(p_entities, this.GetPartEntities(p_entities));
        for (let i = 0; i < orderedWalls.length; i++) {
            new GripDecorator(orderedWalls[i]).Decorate(grips[i]);
        }
    }

    private AddWallMeasures(p_entities: SceneEntity[], parts: SceneEntity[][]): void {
        for (let i: number = 0; i < p_entities.length; i++) {
            // Create Entity
            let measureEntity = this.m_entityManager.CreateEntity('Measure');
            // Decorate
            new WallMeasureDecorator(p_entities[i], parts[i][0], this.m_wallHelperWidth+0.3, this.m_camSystem.GetMainCameraDatas().camera).Decorate(measureEntity);
        }
    }

    private AddGrips(p_entities: SceneEntity[], p_points: Array<Vector2>, p_height: number): SceneEntity[] {
        let augmentedPoints = ShapeUtils.GetScaledShape(p_points, this.m_wallHelperWidth);
        let grips: SceneEntity[] = [];

        for (let i: number = 0; i < p_points.length - 1; i++) {
            // TODO remove - strict mode --------------------------------
            let from1 = new Vector2(p_points[i].x, p_points[i].y);
            let to1 = new Vector2(p_points[i + 1].x, p_points[i + 1].y);
            let fromAugmented = new Vector2(augmentedPoints[i].x, augmentedPoints[i].y);
            let toAugmented = new Vector2(augmentedPoints[i + 1].x, augmentedPoints[i + 1].y);
            // todo end -------------------------------------------------

            grips.push(this.AddGrip(p_entities[i], from1, to1, fromAugmented, toAugmented));
        }
        return grips;
    }

    private OrderWallsByRefOfSection(p_entities: SceneEntity[], p_refOfSections: string[]): SceneEntity[] {
        let orderedWalls = [...p_entities];
        p_entities.forEach((entity) => {
            let wallComponent = entity.GetComponentOfType(WallComponent);
            let i = p_refOfSections.indexOf(wallComponent.RefOfInstance);
            wallComponent.ShapeSectionIndex = i;
            orderedWalls[i] = entity;
        });
        return orderedWalls;
    }

    private AddGrip(p_entity: SceneEntity, p_from1: Vector2, p_to1: Vector2, p_from2: Vector2, p_to2: Vector2): SceneEntity {
        // re-center points
        let center = Vector2Utils.GetPointInBetweenByPerc(p_from1, p_to1, 0.5);
        let from1 = new Vector2(p_from1.x - center.x, p_from1.y - center.y);
        let to1 = new Vector2(p_to1.x - center.x, p_to1.y - center.y);
        let from2 = new Vector2(p_from2.x - center.x, p_from2.y - center.y);
        let to2 = new Vector2(p_to2.x - center.x, p_to2.y - center.y);

        // re-orientate points
        let wallTargetDirection2d: Vector2 = p_to1.clone().sub(p_from1).normalize();
        let wallTargetDirection3d: Vector3 = new Vector3(wallTargetDirection2d.x, 0, -wallTargetDirection2d.y);
        let forward = new Vector3().crossVectors(Object3D.DEFAULT_UP, wallTargetDirection3d).negate();
        let quaternion1 = new Quaternion().setFromUnitVectors(forward, new Vector3(0, 0, -1));

        let from13d = new Vector3(from1.x, 0, -from1.y).applyQuaternion(quaternion1);
        let to13d = new Vector3(to1.x, 0, -to1.y).applyQuaternion(quaternion1);
        let from23d = new Vector3(from2.x, 0, -from2.y).applyQuaternion(quaternion1);
        let to23d = new Vector3(to2.x, 0, -to2.y).applyQuaternion(quaternion1);

        from1 = new Vector2(from13d.x, -from13d.z);
        to1 = new Vector2(to13d.x, -to13d.z);
        from2 = new Vector2(from23d.x, -from23d.z);
        to2 = new Vector2(to23d.x, -to23d.z);

        // Build shape
        let shapePoints: Array<Vector2> = [from1, to1, to2, from2];
        const shape = new Shape(shapePoints);
        let geometry = new ShapeGeometry(shape);
        let mesh = new Mesh(geometry, this.m_wallMaterial);

        // On fait subire la même rotation que pour le sol
        mesh.rotation.set(-Math.PI / 2, 0, 0);
        mesh.position.set(mesh.position.x, 0.2, mesh.position.z);

        let entity = this.m_entityManager.CreateEntity('Grip2D');
        entity.AddComponentOfType(MeshFilterComponent, mesh);
        let scale = p_entity.Transform.GetObject().scale;
        entity.Transform.GetObject().scale.copy(new Vector3(1 / scale.x, 1 / scale.y, 1 / scale.z));

        p_entity.Transform.GetObject().add(entity.Transform.GetObject());

        return entity;
    }

    private BuildWallGizmo(p_center: Vector2, p_from: Vector2, p_to: Vector2, p_offset: number = 0): SceneEntity {
        let entity = this.m_entityManager.CreateEntity('WallGizmo');
        let arrowPoints = [new Vector2(0, 0), new Vector2(0.75, 0.5), new Vector2(0, 1), new Vector2(0, 0)];
        let arrowDirection = new Vector3(1, 0, 0);
        // Build shape
        const shape = new Shape(arrowPoints);
        let geometry = new ShapeGeometry(shape);
        let mesh = new Mesh(geometry, this.m_wallMaterial);
        mesh.renderOrder = 1;

        // Edit mesh transform
        mesh.scale.copy(new Vector3(this.m_wallHelperWidth, this.m_wallHelperWidth, this.m_wallHelperWidth));
        mesh.rotation.set(-Math.PI / 2, 0, 0);
        mesh.position.copy(new Vector3(0, 0.2, mesh.scale.z / 2));

        let gizmoObject = new Group().add(mesh);
        // Set Group rotation
        let segmentDirection = new Vector2().subVectors(p_from, p_to).normalize();
        let segmentPerpendicular = Vector2Utils.GetPerpendicular(segmentDirection);
        let quaternion1 = new Quaternion().setFromUnitVectors(arrowDirection, new Vector3(segmentPerpendicular.x, 0, -segmentPerpendicular.y));
        gizmoObject.applyQuaternion(quaternion1);
        // Set Group position
        let translation = new Vector2().copy(segmentPerpendicular).multiplyScalar(p_offset);
        let newPos = new Vector2().copy(p_center).add(translation);
        gizmoObject.position.copy(new Vector3(newPos.x, 0, -newPos.y));

        entity.Transform.GetObject().add(gizmoObject);
        return entity;
    }

    private GetPartEntities(p_entities: SceneEntity[]): SceneEntity[][] {
        let parts: SceneEntity[][] = [];

        for (let i: number = 0; i < p_entities.length; i++) {
            if (p_entities[i].HasComponentOfType(ObjectComponent)) {
                parts[i] = [];
                let plannerObjComponent = p_entities[i].GetComponentOfType(ObjectComponent);
                plannerObjComponent.PartIds.forEach((entityId, refOfPart) => {
                    let entity = this.m_entityManager.GetEntityByID(entityId);
                    if (entity) {
                        parts[i].push(entity);
                    }
                });
            }
        }
        return parts;
    }
}
