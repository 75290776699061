import {ASystem} from "@lutithree/build/Modules/Core/Entity/ASystem";
import {SceneEntity} from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import {BoundingBoxComponent} from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundingBoxComponent";
import DimensionsInfo from "../DimensionsInfo";
import {DimensionsDisplayerComponent} from "../Components/DimensionDisplayerComponent";

export default class DimensionsSystem extends ASystem<SceneEntity> {
    public GetDimensionsOfEntity(p_target: SceneEntity) : DimensionsInfo {
        let fromLength;
        let toLength;
        let fromDepth;
        let toDepth;
        let fromHeight;
        let toHeight;
        if(p_target.HasComponentOfType(BoundingBoxComponent)){
            let bbComponent = p_target.GetComponentOfType(BoundingBoxComponent);
            fromLength = bbComponent.Center.clone().add(bbComponent.GetRightOriented().normalize().multiplyScalar(bbComponent.HalfSize.x)).add(bbComponent.GetUpOriented().normalize().multiplyScalar(-bbComponent.HalfSize.y)).add(bbComponent.GetForwardOriented().normalize().multiplyScalar(bbComponent.HalfSize.z));
            toLength = bbComponent.Center.clone().add(bbComponent.GetRightOriented().normalize().multiplyScalar(-bbComponent.HalfSize.x)).add(bbComponent.GetUpOriented().normalize().multiplyScalar(-bbComponent.HalfSize.y)).add(bbComponent.GetForwardOriented().normalize().multiplyScalar(bbComponent.HalfSize.z));
            fromDepth = bbComponent.Center.clone().add(bbComponent.GetForwardOriented().normalize().multiplyScalar(bbComponent.HalfSize.z)).add(bbComponent.GetUpOriented().normalize().multiplyScalar(-bbComponent.HalfSize.y)).add(bbComponent.GetRightOriented().normalize().multiplyScalar(-bbComponent.HalfSize.x));
            toDepth = bbComponent.Center.clone().add(bbComponent.GetForwardOriented().normalize().multiplyScalar(-bbComponent.HalfSize.z)).add(bbComponent.GetUpOriented().normalize().multiplyScalar(-bbComponent.HalfSize.y)).add(bbComponent.GetRightOriented().normalize().multiplyScalar(-bbComponent.HalfSize.x));
            fromHeight = bbComponent.Center.clone().add(bbComponent.GetUpOriented().normalize().multiplyScalar(bbComponent.HalfSize.y)).add(bbComponent.GetRightOriented().normalize().multiplyScalar(-bbComponent.HalfSize.x)).add(bbComponent.GetForwardOriented().normalize().multiplyScalar(-bbComponent.HalfSize.z));
            toHeight = bbComponent.Center.clone().add(bbComponent.GetUpOriented().normalize().multiplyScalar(-bbComponent.HalfSize.y)).add(bbComponent.GetRightOriented().normalize().multiplyScalar(-bbComponent.HalfSize.x)).add(bbComponent.GetForwardOriented().normalize().multiplyScalar(-bbComponent.HalfSize.z));
            
            return new DimensionsInfo({p_from: fromLength, p_to: toLength}, {p_from: fromDepth, p_to: toDepth},{p_from: fromHeight, p_to: toHeight});
        }
        else
            throw new Error("Entity did not have a bounding box");
    }

    public GetDimensionDisplayer(p_entity: SceneEntity): DimensionsDisplayerComponent|undefined {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        let dimensionDisplayerComponent: DimensionsDisplayerComponent|undefined = undefined;
        let dimensionDisplayerComponents = this.m_entityManager.GetComponents(DimensionsDisplayerComponent, { entity: false, component: false });
        dimensionDisplayerComponents.forEach((component) => {
            if (component.TargetEntityID === p_entity.Id) {
                dimensionDisplayerComponent = component;
            }
        });
        return dimensionDisplayerComponent;
    }
}
