import React, { useEffect, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import ButtonToggleRoom from "../../../../../application3D-common/Components/UI/Composites/ButtonToggleRoom";


type ToggleIconProps = {
    imgOn: string;
    imgOff: string;
    label: string;
    active: boolean | undefined;
    onChange: () => void;
    expand?: boolean;
};

const ToogleIcon = ({ imgOff, label, onChange, imgOn, active, expand }: ToggleIconProps) => {
    const [icon, setIcon] = useState('');

    useEffect(() => {
        if (active) {
            setIcon(imgOn);
        } else {
            setIcon(imgOff);
        }
    }, [active]);

    return (
        <>
            <StyledBoxButtonGroup>
                <ButtonToggleRoom
                    expand={ expand }
                    active={ active }
                    text={
                        <Typography sx={ { whiteSpace: 'pre-line' } } variant='subtitle2'
                                    color={ active ? 'secondary' : 'primary' }>
                            { label }
                        </Typography>
                    }
                    actions={ () => onChange() }
                    logo={ icon }
                    altLogo='paint icon'
                />
            </StyledBoxButtonGroup>
        </>
    );
};

const StyledBoxButtonGroup = styled(Box)(({ theme }) => ({
    margin: theme.spacing(0.5),
    height: 65,
    minWidth: 80,
    '& .Mui-disabled': {
        border: 0,
    },
    '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
    },
}));

export default ToogleIcon;
