import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { RoomObjectLoader } from "./RoomObjectLoader";
import { RoomObjectDestroyer } from './RoomObjectDestroyer';
import BasicObject
    from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import RoomStudioApp from "../../../Libraries/Studios/RoomStudio/RoomStudioApp";
import {RoomStudioMode} from "../../../Libraries/Studios/RoomStudio/Domain/Modes/RoomStudioMode";
import {ViewMode} from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Cameras/ViewMode";
import {
    ObjectParser
} from "../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/ObjectParser";
import Instance3D
    from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/Instance3D";
import {RootState} from "../../../../../services/redux/root-reducers";
import {
    RoomStudioState,
    updateRoomStudioMode,
    updateViewMode
} from "../../../Redux/Reducers/room-studio/RoomStudioReducer";
import {
    addInstances,
    addObjects, clearRoom,
    RoomContentState,
    setRoomToLoad
} from "../../../Redux/Reducers/room-studio/RoomContentReducer";
import {EntitiesState} from "../../../Redux/Reducers/room-studio/EntitiesReducer";
import ObjectDatas from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ObjectDatas";

export type RoomContentProps = {
    roomStudio : RoomStudioApp;
};

const RoomContent = (props: RoomContentProps) => {
    const dispatch = useDispatch();
    
    const { roomStudioApp } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const { roomToLoad } = useSelector<RootState, RoomContentState>((state) => state.roomContent);
    const { instances, plannerObjects, room } = useSelector<RootState, RoomContentState>((state) => state.roomContent);
    const { entityIds } = useSelector<RootState, EntitiesState>((state) => state.entities);
    
    const loadRoom = (p_room: ObjectDatas):void =>{
        dispatch(updateRoomStudioMode(RoomStudioMode.Furnishing));
        dispatch(updateViewMode(ViewMode.ThirdPerson));

        let templateObjects = new Array<BasicObject>();
        p_room.BasicObjects.forEach((arrayItem) => {
            templateObjects.push(ObjectParser.DeepParseObject(arrayItem, BasicObject));
        });

        let roomTemplateInstances = new Array<Instance3D>();
        p_room.Instances3D.forEach((arrayItem) => {
            roomTemplateInstances.push(Object.assign(new Instance3D(), arrayItem));
        });
        
        dispatch(addInstances(roomTemplateInstances));
        dispatch(addObjects(templateObjects));
    };

    useEffect(() => {
        if(roomStudioApp){
            roomStudioApp.Services.DataAccess.SetDatas(room.Instances3D, room.BasicObjects);
        }
    }, [roomStudioApp, room]);

    useEffect(() => {
        if(roomToLoad){
            if(entityIds.length === 0){
                loadRoom(roomToLoad);
                dispatch(setRoomToLoad(undefined));
            }
            else{
                dispatch(clearRoom());
            }
        }
    }, [roomToLoad, entityIds, props.roomStudio]);
    
    return (
       <>
           {props.roomStudio && (
               <>
                   <RoomObjectLoader
                       roomStudio={props.roomStudio}
                       instances={instances}
                       basicObjects={plannerObjects}
                       entityIds={entityIds}
                   />
                   <RoomObjectDestroyer
                       roomStudio={props.roomStudio}
                       instances={instances}
                       basicObjects={plannerObjects}
                       entityIds={entityIds}
                   />
               </>
           )}
       </>
    );
};

export { RoomContent };
