import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RoomStudioApp from "../../../Libraries/Studios/RoomStudio/RoomStudioApp";
import BasicObject
    from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import {RootState} from "../../../../../services/redux/root-reducers";
import {
    editObjectAssetData,
    RoomStudioState,
    setEditedDatas
} from "../../../Redux/Reducers/room-studio/RoomStudioReducer";
import {updateObject} from "../../../Redux/Reducers/room-studio/RoomContentReducer";
import ObjectDatas from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ObjectDatas";

export type AssetEditorProps = {
    roomStudio : RoomStudioApp;
};

const AssetEditor = (props: AssetEditorProps) => {
    const dispatch = useDispatch();

    const { objectAssetDataToEdit, selectedObject } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    
    function editRoomObjectRendering(p_newState: ObjectDatas, p_lastState: ObjectDatas): void {
        let editPromise = props.roomStudio.Services.RoomObjects.EditRootObjectAsync(p_newState, p_lastState);
        editPromise?.then(() => {
            dispatch(updateObject(p_newState.BasicObjects));
        });
    }

    useEffect(() => {
        if (objectAssetDataToEdit !== undefined) {
            let stateClone: BasicObject = objectAssetDataToEdit.basicObject.Clone();
            stateClone.EditAssetData(objectAssetDataToEdit.type, objectAssetDataToEdit.refOfPart, objectAssetDataToEdit.data);
            dispatch(editObjectAssetData(undefined));
            if(selectedObject && stateClone.RefOfInstance === selectedObject.RefOfInstance){
                console.log("1 - AssetEditor");
                dispatch(setEditedDatas(ObjectDatas.GetObjectDatasForObject(stateClone)));
            } else{
                console.log("2 - AssetEditor");
                let newState = ObjectDatas.GetObjectDatasForObject(stateClone);
                let lastState = ObjectDatas.GetObjectDatasForObject(objectAssetDataToEdit.basicObject);
                if(newState && lastState){
                    editRoomObjectRendering(newState, lastState);
                }
            }
        }
    }, [objectAssetDataToEdit]);

    return <></>;
};

export { AssetEditor };
