import apiClient from '../../../../../api/api-client';
import {
    ADD_METADATA,
    ADD_MULTIPLE_ATOMS_TO_CONFIGURED_PRODUCT,
    CREATE_CONFIGURED_PRODUCT,
    DELETE_CONFIGURED_PRODUCT,
    GET_CONFIGURED_PRODUCT_SHEET,
    GET_CONFIGURED_PRODUCT_TREE,
    GET_CONFIGURED_PRODUCT_TREE_BY_REFERENCE,
    GET_CONFIGURED_PRODUCT_TREE_LIST,
    RENAME_CONFIGURED_PRODUCT,
    UPLOAD_THUMBNAIL_URL,
} from '../../../../../api/enpoint/configured-product';
import { Product } from '../../../../../domain/domain';

const configuredProductService = {
    createConfiguredProduct: async (name: string, coreProductReference: string) => apiClient(CREATE_CONFIGURED_PRODUCT(coreProductReference, name), undefined, undefined, 'POST'),
    getConfiguredProductTreeList: async (coreProductReference: string) => apiClient(GET_CONFIGURED_PRODUCT_TREE_LIST(coreProductReference), undefined, undefined, 'GET'),
    getConfiguredProductTree: async (coreProductReference: string) => apiClient(GET_CONFIGURED_PRODUCT_TREE(coreProductReference), undefined, undefined, 'GET'),
    addMultipleAtomsToConfiguredProduct: async (productReference: string, atomReferences: string[]) =>
        apiClient(ADD_MULTIPLE_ATOMS_TO_CONFIGURED_PRODUCT(productReference), atomReferences, undefined, 'POST'),
    getConfiguredProductTreeByProductReference: async (productReference: string) => apiClient(GET_CONFIGURED_PRODUCT_TREE_BY_REFERENCE(productReference), undefined, undefined, 'GET'),
    renameConfiguredProduct: async (productReference: string) => apiClient(RENAME_CONFIGURED_PRODUCT(productReference), undefined, undefined, 'PUT'),
    uploadThumbnailToConfiguredProduct: async (productReference: string, product: Product) => apiClient(UPLOAD_THUMBNAIL_URL(productReference), product, undefined, 'POST'),
    deleteConfiguredProduct: async (productReference: string) => apiClient(DELETE_CONFIGURED_PRODUCT(productReference), undefined, undefined, 'DELETE'),
    addMetadataToConfiguredProduct: async (productReference: string, metadata: any) => apiClient(ADD_METADATA(productReference), metadata, undefined, 'POST'),
    getConfiguredProductSheet: async (productReference: string) => apiClient(GET_CONFIGURED_PRODUCT_SHEET(productReference), undefined, undefined, 'GET'),
};
export default configuredProductService;
