export default class Connection {

    private dataModelVersion: string;

    private refOfConnectorA: string;

    private refOfConnectorB: string;
    
    public constructor() {
        this.dataModelVersion = '';
        this.refOfConnectorA = '';
        this.refOfConnectorB = '';
    }
    
    public get DataModelVersion(): string {
        return this.dataModelVersion;
    }
    
    set DataModelVersion(value: string) {
        this.dataModelVersion = value;
    }
    
    public get RefOfConnectorA(): string {
        return this.refOfConnectorA;
    }
    
    set RefOfConnectorA(value: string) {
        this.refOfConnectorA = value;
    }
    
    public get RefOfConnectorB(): string {
        return this.refOfConnectorB;
    }
    
    set RefOfConnectorB(value: string) {
        this.refOfConnectorB = value;
    }
}