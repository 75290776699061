/* eslint-disable */
export enum RoomStudioMode {
    Idle,
    Configuring,
    Furnishing,
    Measuring,
    MultiSelection,
    Accelerometer
}
/* eslint-enable */
