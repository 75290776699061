import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { AEntityEvent } from "@lutithree/build/Modules/Core/Entity/Events/AEntityEvent";

export default class EntityScaledEvent extends AEntityEvent<SceneEntity> {
    private m_scaledTargets: SceneEntity[];
    
    public constructor(p_entity: SceneEntity, p_scaledTargets: SceneEntity[]) {
        super(p_entity);
        if (p_scaledTargets == null) throw new Error('NullReferenceException : p_scaledTargets is null or undefined');
        
        this.m_scaledTargets = p_scaledTargets;
    }
    
    public get ScaledTargets(): SceneEntity[] {
        return this.m_scaledTargets;
    }
}
