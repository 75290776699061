// synchronisation between isolation and configuration: connector 
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { SellerMode } from "./SellerMode";
import { VisualStateData } from "./VisualStateDatas";
import {SellerApp3DModeState} from "../../Redux/Reducers/SellerApp3DModeReducer";
import {RoomStudioState} from "../../Redux/Reducers/room-studio/RoomStudioReducer";
import {ProductStudioState} from "../../../application3D-common/Redux/Reducers/product-studio/ProductStudioReducer";
import {RootState} from "../../../../services/store";


export function useConnectorSynchro() {

    const { sellerMode } = useSelector<RootState, SellerApp3DModeState>((state) => state.sellerApp3DModes);
    const roomStudio = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const productStudio = useSelector<RootState, ProductStudioState>((state) => state.productStudio);

    useEffect(() => {
        if(sellerMode === SellerMode.RoomStudio){
            if(roomStudio.selectedConnector) {
                productStudio.productStudioApp?.Services.Connectors.SelectButton(roomStudio.selectedConnector, true);
            }
            else if(productStudio.selectedConnector){
                productStudio.productStudioApp?.Services.Connectors.SelectButton(productStudio.selectedConnector, false);
            }
        }
        else if(sellerMode === SellerMode.ProductStudio){
            if(productStudio.selectedConnector)
                roomStudio.roomStudioApp?.Services.Connectors.SelectButton(productStudio.selectedConnector, true);
            else if(roomStudio.selectedConnector)
                roomStudio.roomStudioApp?.Services.Connectors.SelectButton(roomStudio.selectedConnector, false);
        }
    }, [roomStudio.selectedConnector, productStudio.selectedConnector]);
}

// synchronisation between isolation and configuration: selected object 
export function useSelectedObjectSynchro() {

    const { sellerMode } = useSelector<RootState, SellerApp3DModeState>((state) => state.sellerApp3DModes);
    const roomStudio = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const productStudio = useSelector<RootState, ProductStudioState>((state) => state.productStudio);

    useEffect(() => {
        if( sellerMode === SellerMode.ProductStudio){
            if(productStudio.selectedPart){
                if( roomStudio.selectedObject === undefined || roomStudio.selectedObject.RefOfInstance !== productStudio.selectedPart.RefOfInstance){
                    roomStudio.roomStudioApp?.Services.Selection.SelectObject(productStudio.selectedPart.RefOfInstance,false);
                }
            }
            else{
                roomStudio.roomStudioApp?.Services.Selection.UnSelectAll();
            }
        }
    }, [productStudio.selectedPart]);
    useEffect(() => {
        if( sellerMode === SellerMode.RoomStudio) {
            if(roomStudio.selectedObject){
                if(productStudio.selectedPart === undefined || productStudio.selectedPart.RefOfInstance !== roomStudio.selectedObject.RefOfInstance){
                    productStudio.productStudioApp?.Services.Selection.SelectObject(roomStudio.selectedObject.RefOfInstance, false);
                }
            }
            else{
                productStudio.productStudioApp?.Services.Selection.UnSelectAll();
            }
        }
    }, [roomStudio.selectedObject]);
}

export function useVisualStatesSynchro(p_visualStateData: Array<VisualStateData>) {

    const roomStudio = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const productStudio = useSelector<RootState, ProductStudioState>((state) => state.productStudio);

    // synchronisation: visual datas
    useEffect(() => {
        if(roomStudio.roomStudioApp) {
            p_visualStateData.forEach((data)=>{
                roomStudio.roomStudioApp?.Services.RoomObjects.ApplyOption(data.refOfObject, data.refOfPart, data.refOfOption, data.value);
            });
        }
    }, [p_visualStateData, roomStudio.roomStudioApp]);
    useEffect(() => {
        if(productStudio.productStudioApp) {
            p_visualStateData.forEach((data)=>{
                productStudio.productStudioApp?.Services.Objects.ApplyOption(data.refOfObject, data.refOfPart, data.refOfOption, data.value);
            });
        }
    }, [p_visualStateData, productStudio.productStudioApp]);
}
    