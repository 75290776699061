import apiClient from '../../api-client';
import {
    CREATE_PRODUCT,
    CREATE_PRODUCT_INFO,
    GET_CHILDREN_PRODUCTS_BY_COLLECTION,
    GET_COMPOSITION_LINKABLE,
    GET_CONFIGURATION_POSSIBLE_FROM_MODEL3D_REFERENCE,
    GET_PRODUCT_DEFAULT_PRESET,
    GET_PRODUCTS,
    GET_PRODUCTS_BY_COLLECTION,
    GET_PRODUCTS_BY_ID,
    GET_PRODUCTS_BY_STATUT,
    GET_TABLE_PRESETS,
    PRODUCT_CATALOG_LINES,
    SAVE_PRODUCT_CHARACTERISTICS,
    SAVE_THUMBNAIL_INFORMATION,
    UPDATE_MODEL_3D_NAME,
} from '../../enpoint/product/product.endpoints';
import { MdfApiResponse, Product, ProductCompletionStatus, ProductCreationForm, ProductInformationForm, ProductModel3dUpdateForm } from '../../../domain/domain';

const productService = {
    createProduct: async (createProductForm: ProductCreationForm) => apiClient(CREATE_PRODUCT(), createProductForm, undefined, 'POST'),
    createProductInfo: async (createProductInfoForm: ProductInformationForm) => apiClient(CREATE_PRODUCT_INFO(), createProductInfoForm, undefined, 'POST'),
    saveProductCara: async (productRef: string) => apiClient(SAVE_PRODUCT_CHARACTERISTICS(productRef), undefined, undefined, 'POST'),
    saveThumbnailInformation: async (productRef: string) => apiClient(SAVE_THUMBNAIL_INFORMATION(productRef), undefined, undefined, 'POST'),
    getProducts: async () => apiClient(GET_PRODUCTS(), undefined, undefined, 'GET'),
    getProductsByCollection: async (collectionReference: string) => apiClient(GET_PRODUCTS_BY_COLLECTION(collectionReference), undefined, undefined, 'GET'),
    getChildrenProductsByCollection: async (collectionReference: string) => apiClient(GET_CHILDREN_PRODUCTS_BY_COLLECTION(collectionReference), undefined, undefined, 'GET'),
    getCompositionLinkableByCollection: async (collectionReference: string): Promise<MdfApiResponse<Product[]>> =>
        // @ts-ignore
        apiClient(GET_COMPOSITION_LINKABLE(collectionReference), undefined, undefined, 'GET'),
    productCatalogLines: async (collectionReference: string, catalogId: number) => apiClient(PRODUCT_CATALOG_LINES(collectionReference, catalogId), undefined, undefined, 'GET'),
    getProductsById: async (productReference: string) => apiClient(GET_PRODUCTS_BY_ID(productReference), undefined, undefined, 'GET'),
    deleteProductsById: async (productReference: string) => apiClient(GET_PRODUCTS_BY_ID(productReference), undefined, undefined, 'DELETE'),
    updateProductModel3DName: async (model3dUpdateForm: ProductModel3dUpdateForm) => apiClient(UPDATE_MODEL_3D_NAME(model3dUpdateForm.reference), model3dUpdateForm, undefined, 'PUT'),
    getTablePresets: async () => apiClient(GET_TABLE_PRESETS, undefined, undefined, 'GET'),
    getConfigurationByModel3dCellReference: async (coreProductReference: string, model3dCellReference: string) =>
        apiClient(GET_CONFIGURATION_POSSIBLE_FROM_MODEL3D_REFERENCE(coreProductReference, model3dCellReference), undefined, undefined, 'GET'),
    getProductsByStatut: async (statut: ProductCompletionStatus) => apiClient(GET_PRODUCTS_BY_STATUT(statut), undefined, undefined, 'GET'),
    getProductDefaultPreset: async (productRef: string) => apiClient(GET_PRODUCT_DEFAULT_PRESET(productRef), undefined, undefined, 'GET'),
};
export default productService;
