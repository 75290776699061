/* eslint-disable */
enum TimerState {
    IDLE,
    RUNNING,
    STOPPED,
}
/* eslint-enable */

class Timer {
    public m_id: string;

    public m_timeStart: number;

    public m_timeStop: number;

    public m_state: TimerState;

    constructor(p_id: string) {
        this.m_id = p_id;
        this.m_timeStart = 0;
        this.m_timeStop = 0;
        this.m_state = TimerState.IDLE;
    }
}

export default class TimerTool {
    private m_timers: Timer[] = [];
    
    public AddTimer(p_id: string) {
        if (this.GetTimer(p_id) !== undefined) console.warn('Timer was already present');
        else this.m_timers.push(new Timer(p_id));
    }

    public StartTimer(p_id: string) {
        let timerToStart = this.GetTimer(p_id);
        if (timerToStart !== undefined) {
            if (timerToStart.m_state === TimerState.RUNNING) return;
            else {
                timerToStart.m_state = TimerState.RUNNING;
                timerToStart.m_timeStart = Date.now();
            }
        } else {
            timerToStart = new Timer(p_id);
            this.m_timers.push(timerToStart);
            timerToStart.m_timeStart = Date.now();
            timerToStart.m_state = TimerState.RUNNING;
        }
    }

    public StopTimer(p_id: string) {
        let timerToStop = this.GetTimer(p_id);
        if (timerToStop === undefined) {
            console.warn("Timer doesn't exist!");
        } else {
            if (timerToStop.m_state === TimerState.RUNNING) {
                timerToStop.m_timeStop = Date.now();
                timerToStop.m_state = TimerState.STOPPED;
            } else console.warn('Timer was already stopped');
            return (timerToStop.m_timeStop - timerToStop.m_timeStart) / 1000;
        }
    }

    public GetTime(p_id: string): number {
        let timer = this.GetTimer(p_id);
        if (timer === undefined) {
            console.warn("Timer doesn't exist!");
            return -1;
        } else {
            if (timer.m_state === TimerState.STOPPED) return (timer.m_timeStop - timer.m_timeStart) / 1000;
            else return (Date.now() - timer.m_timeStart) / 1000;
        }
    }

    private GetTimer(p_id: string): Timer | undefined {
        return this.m_timers.find((timer) => {
            return timer.m_id === p_id;
        });
    }
}
