import React, { useEffect, useState } from 'react';

// @ts-ignore
import { ReactInternetSpeedMeter } from 'react-internet-meter';
import ReactSpeedometer from 'react-d3-speedometer';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { SpeedTestState, uploadSpeedTest } from '../../services/redux/reducers/speed-test/speed-test-reducer';
import { RootState } from '../../services/redux/root-reducers';
import { getCurrentUserInfo, UserState } from '../../services/redux/reducers/user/user-reducer';
import TextField from '../../ui-components/textfield/textfield';
import { Button } from '../../ui-components/button/button';
import { useNavigate } from 'react-router-dom';
import Layout from '../../ui-components/layout/layout';

type SpeedTestHint = {
    msg: String;
    hintType: 'VALID' | 'WARNING' | 'ERROR';
};

const SpeedTestModule = () => {
    const [speed, setSpeed] = useState(0.0);
    const [stopTest, setStopTest] = useState(false);
    const [storeName, setStoreName] = useState('');
    const [sendLabel, setSendLabel] = useState('Envoyer');
    const [hint, setHint] = useState<SpeedTestHint>({
        hintType: 'VALID',
        msg: '',
    });
    const dispatch = useDispatch();
    const user = useSelector<RootState, UserState>((state) => state.currentUser);
    const speedTestResponse = useSelector<RootState, SpeedTestState>((state) => state.speedTest);
    const history = useNavigate();

    useEffect(() => {
        setHint({
            hintType: 'VALID',
            msg: 'Test terminé. Entrez le nom du magasin pour envoyer les résultats',
        });
        dispatch(getCurrentUserInfo());
        setTimeout(() => {
            setStopTest(true);
        }, 10000);
    }, []);

    useEffect(() => {
        console.log(speedTestResponse);
        if (speedTestResponse.response.errors.length > 0) {
            setHint({ msg: 'Une erreur réseau est survenue, merci de réessayer', hintType: 'ERROR' });
        } else if (speedTestResponse.response.content !== null) {
            setSendLabel('Envoyé');
            setTimeout(() => {
                history('/');
            }, 3000);
        }
    }, [speedTestResponse]);

    const handleSubmit = () => {
        if (storeName.length === 0) {
            setHint({ msg: 'Vous devez renseigner le nom du magasin', hintType: 'ERROR' });
        } else {
            if (user.response.content !== null) {
                dispatch(uploadSpeedTest({
                    downloadSpeed: Math.round(speed * 100),
                    userId: user.response.content.user.id,
                    storeName: storeName,
                }));
            }
        }
    };

    // @ts-ignore
    return (
        <Layout navbar={true}>
            <div className='main-speed-test'>
                {!stopTest && (
                    <ReactInternetSpeedMeter
                        txtSubHeading=''
                        outputType='alert'
                        customClassName={null}
                        txtMainHeading=''
                        imageUrl='https://i.stack.imgur.com/6M513.png'
                        pingInterval={500} // milliseconds
                        thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte"
                        threshold={100}
                        downloadSize='1781287' //bytes
                        callbackFunctionOnNetworkDown={(s: any) => console.log(`Internet speed is down ${speed}`)}
                        callbackFunctionOnNetworkTest={(s: any) => {
                            const speedParsed = parseFloat(s);
                            if (speedParsed > 0) {
                                setSpeed(speedParsed);
                            }
                        }}
                    />
                )}
                <div className='speedometer'>
                    <ReactSpeedometer segments={5} value={speed} minValue={0} maxValue={200} fluidWidth />
                </div>
                {stopTest && (
                    <>
                        <div>
                            <p className={hint.hintType !== 'VALID' ? 'hint-error' : 'hint-normal'}>{hint.msg}</p>
                        </div>

                        <div className='textfield'>
                            <TextField
                                type='text'
                                onChange={(s) => {
                                    setStoreName(s);
                                }}
                                value={storeName}
                            />

                            <Button
                                label={sendLabel}
                                primary
                                backgroundColor={sendLabel === 'Envoyer' ? '' : 'green'}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
        </Layout>

    );
};

export default SpeedTestModule;
