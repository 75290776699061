import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MdfApiResponse from '../../../domain/common/generic';
import DialogLayout from '../../../ui-components/Dialog/DialogLayout';
import DialogContent from '@mui/material/DialogContent';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import CloseSaveButtonModal from '../../ButtonAction/CloseSaveButtonModal';
import TextFieldUi from '../../../ui-components/TextFieldUi';
import InfoBox from '../../../ui-components/InfoBox';
import { resetPassword, resetUserPassword } from '../../../services/redux/reducers/reset-password/reset-password';
import Snackbar from '@mui/material/Snackbar';
import { RootState } from '../../../services/redux/root-reducers';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


type UpdateUserProps = {
    onClose: () => void;
    title: string;
    open: boolean;
};

type ResetPassword = {
    payload: MdfApiResponse<any>;
    success: boolean;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props, ref) {
    return <MuiAlert elevation={ 6 } ref={ ref } variant='filled' { ...props } />;
});

const UpdateUser = ({ title, onClose, open }: UpdateUserProps) => {
    const dispatch = useDispatch();
    const {
        success,
        payload,
    } = useSelector<RootState, ResetPassword>((state) => state.resetUserPassword);

    const [openSnackBar, setOpenSnackBar] = useState(false);

    const [initialPassword, setInitialPassword] = useState('');
    const [isInitialPasswordErr, setIsInitialPasswordErr] = useState(false);
    const [helperInitialPassword, setHelperInitialPassword] = useState('');

    const [newPassword, setNewPassword] = useState('');
    const [isNewPasswordErr, setIsNewPasswordErr] = useState(false);
    const [helperNewPassword, setHelperNewPassword] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');
    const [isConfirmPassword, setIsConfirmPassword] = useState(false);
    const [helperConfirmPassword, setHelperConfirmPassword] = useState('');


    const ErrorNoData = () => {
        if (initialPassword.length <= 0 || newPassword.length <= 0 || confirmPassword.length <= 0) {
            if (initialPassword.length <= 0) {
                setIsInitialPasswordErr(true);
                setHelperInitialPassword('Le mot de passe doit être spécifié');
            }
            if (newPassword.length <= 0) {
                setIsNewPasswordErr(true);
                setHelperNewPassword('Le nouveau mot de passe doit être specifié');
            }
            if (confirmPassword.length <= 0) {
                setIsConfirmPassword(true);
                setHelperConfirmPassword('La confirmation du nouveau mot de passe doit être specifiée');
            }
            return false;
        }
        return true;
    };

    const ErrorRegEx = () => {
        const validPassword = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$');
        if (!validPassword.test(newPassword)) {
            setIsNewPasswordErr(true);
            setHelperNewPassword('Le mot de passe doit contenir au moins 8 caractères, dont un caractère spécial et une majuscule');
            return false;
        }
        return true;
    };

    const handleChangeInitialPassword: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setInitialPassword(e.target.value);
        setIsInitialPasswordErr(false);
        setHelperInitialPassword('');
    };

    const handleChangeNewPassword: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setNewPassword(e.target.value);
        setIsNewPasswordErr(false);
        setHelperNewPassword('');
    };

    const handleChangeConfirmPassword: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setConfirmPassword(e.target.value);
        setIsConfirmPassword(false);
        setHelperConfirmPassword('');
        setIsNewPasswordErr(false);
        setHelperNewPassword('');
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (ErrorNoData()) {
            if (ErrorRegEx()) {
                if (newPassword !== confirmPassword) {
                    setIsConfirmPassword(true);
                    setIsNewPasswordErr(true);
                    setHelperConfirmPassword('Les nouveaux mots de passe ne correspondent pas');
                } else {
                    dispatch(
                        resetPassword({
                            oldPassword: initialPassword,
                            newPassword: confirmPassword,
                        }),
                    );
                }
            }
        }
    };

    useEffect(() => {
        if (payload.errors.length > 0) {
            setIsInitialPasswordErr(true);
            setHelperInitialPassword('le mot de passe est invalide');
            dispatch(resetUserPassword());
        }
        if (success) {
            setOpenSnackBar(true);
            onClose();
            dispatch(resetUserPassword());
            setInitialPassword('');
            setNewPassword('');
            setConfirmPassword('');
        }
    }, [payload, success]);

    return (
        <>
            <DialogLayout title={ title } onClose={ onClose } open={ open }>
                <DialogContent>
                    <InfoBox
                        icon={ <InfoOutlinedIcon sx={ { fontSize: '2.7em', marginRight: '10px' } } color='primary' /> }
                        content='Le mot de passe doit contenir au moins 8 caractères, dont un caractère spécial et une majuscule' />
                </DialogContent>
                <form onSubmit={ handleSubmit }>
                    <DialogContent>
                        <TextFieldUi
                            type='password'
                            labelContent='Saisissez le mot de passe actuel'
                            placeholder='Mot de passe actuel'
                            variant='outlined'
                            onChange={ handleChangeInitialPassword }
                            value={ initialPassword }
                            name='initial-password'
                            id='initial-password'
                            error={ isInitialPasswordErr }
                            helperText={ helperInitialPassword }
                            fullWidth
                            inputProps={ { maxLength: 70 } }
                        />
                    </DialogContent>
                    <DialogContent>
                        <TextFieldUi
                            type='password'
                            labelContent='Saisissez le nouveau mot de passe'
                            placeholder='Nouveau mot de passe actuel'
                            variant='outlined'
                            onChange={ handleChangeNewPassword }
                            value={ newPassword }
                            name='new-password'
                            id='new-password'
                            error={ isNewPasswordErr }
                            helperText={ helperNewPassword }
                            fullWidth
                            inputProps={ { maxLength: 70 } }
                        />
                    </DialogContent>
                    <DialogContent>
                        <TextFieldUi
                            type='password'
                            labelContent='Confirmez le nouveau mot de passe'
                            placeholder='Nouveau mot de passe'
                            variant='outlined'
                            onChange={ handleChangeConfirmPassword }
                            value={ confirmPassword }
                            name='confirm-new-password'
                            id='confirm-new-password'
                            error={ isConfirmPassword }
                            helperText={ helperConfirmPassword }
                            fullWidth
                            inputProps={ { maxLength: 70 } }
                        />
                    </DialogContent>
                    <CloseSaveButtonModal color='secondary' onClose={ onClose } />
                </form>
            </DialogLayout>
            <Snackbar anchorOrigin={ { vertical: 'bottom', horizontal: 'right' } } open={ openSnackBar }
                      autoHideDuration={ 5000 } onClose={ handleClose }>
                <Alert onClose={ handleClose } severity='success' sx={ { width: '100%' } }>
                    Votre mot de passe a bien été modifié
                </Alert>
            </Snackbar>
        </>
    );
};

export default UpdateUser;