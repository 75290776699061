import MdfApiResponse from '../../../../domain/common/generic';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TagService from '../../../../api/service/tags/tag.service';

export type GetFinishAndColorsByTagState = {
    isLoading: boolean;
    payload: MdfApiResponse<any>;
};

const initialGetFinishAndColorsByTagState: GetFinishAndColorsByTagState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

export const getFinishAndColorsByTag = createAsyncThunk('getFinishAndColorByTags', async (tagName: string) => {
    const res = await TagService.getFinishAndColorsByTag(tagName);
    return res;
});

export const getFinishAndColorsByTagSlice = createSlice({
    name: 'getFinishAndColorsByTagSlice',
    initialState: initialGetFinishAndColorsByTagState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFinishAndColorsByTag.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getFinishAndColorsByTag.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getFinishAndColorsByTag.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
