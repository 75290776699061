import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProjectCard from '../../ui-components/ProjectCard';
import {
    CreateProjectsState,
    deleteUserProjects,
    DeleteUserProjectsState,
    getUserProjects,
    GetUserProjectsState,
} from '../../services/redux/reducers/projects/project.slice';
import { RootState } from '../../services/redux/root-reducers';
import { Project as ProjectInterface } from '../../domain/domain';
import Layout from '../../ui-components/layout/layout';
import CreateProject from '../../business/DialogContent/CreateProject';
import DeleteProject from '../../business/DialogContent/DeleteProject';
import { Grid, Box, Button, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ContainerPaperLayout from '../../ui-components/ContainerPaperLayout';


const Project = () => {
    const dispatch = useDispatch();
    const { payload } = useSelector<RootState, GetUserProjectsState>((state) => state.getUserProjects);
    const deleteUserProjectResponse = useSelector<RootState, DeleteUserProjectsState>((state) => state.deleteUserProject);
    const createProjectResponse = useSelector<RootState, CreateProjectsState>((state) => state.createProject);
    const [openM, setOPenM] = useState(false);
    const [openDeleteM, setOpenDeleteM] = useState(false);
    const [projects, setProjects] = useState<ProjectInterface[]>([]);
    const [projectId, setProjectId] = useState<number>(0);
    const [projectName, setProjectName] = useState<string>('');

    const user = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        dispatch(getUserProjects(user.id));
    }, []);

    useEffect(() => {
        setProjects(payload.content);
    }, [payload]);

    useEffect(() => {
        if (createProjectResponse.payload.content !== undefined && createProjectResponse.payload.errors.length === 0) {
            dispatch(getUserProjects(user.id));
        }
    }, [createProjectResponse]);

    useEffect(() => {
        if (deleteUserProjectResponse.payload.errors.length > 0 && deleteUserProjectResponse.payload.content === undefined) {
        } else {
            dispatch(getUserProjects(user.id));
        }
    }, [deleteUserProjectResponse]);

    return (
        <Layout navbar>
            { openM && (
                <CreateProject
                    onClose={ () => setOPenM(false) }
                    title='Ajouter un projet'
                    open={ openM }
                />
            ) }
            { openDeleteM && (
                <DeleteProject
                    onClose={ () => setOpenDeleteM(false) }
                    title='Supprimer le projet ?'
                    open={ openDeleteM }
                    projectName={ projectName }
                    dialogContent={ `Si le projet "${ projectName }" est partagé avec d'autres utilisateurs, ces derniers n'auront plus accès au projet. Pour confirmer, taper le nom du projet :` }
                    actionOnclick={ () => {
                        dispatch(deleteUserProjects({ projectId: projectId, userId: user.id }));
                        setOpenDeleteM(false);
                    } }
                />
            ) }
            <ContainerPaperLayout path='/projects' title='Mes Projets'>
                <Grid container direction='row' spacing={ { xs: 1, md: 2, lg: 3 } }>
                    <Grid item xs={ 6 } md={ 4 } lg={ 3 } xl={ 2 }>
                        <CustomBox>
                            <CustomAddButton onClick={ () => setOPenM(true) } variant='outlined' color='secondary'
                                             startIcon={
                                                 <AddIcon sx={ { fontSize: 40 } } />
                                             }>
                                Ajouter un projet
                            </CustomAddButton>
                        </CustomBox>
                    </Grid>
                    { projects.map((el: ProjectInterface, i: number) => {
                        return (
                            <Grid item xs={ 6 } md={ 4 } lg={ 3 } xl={ 2 } key={ i }>
                                <ProjectCard
                                    setProjectName={ setProjectName }
                                    name={ el.name }
                                    projectId={ el.id }
                                    onclick={ () => {
                                        setProjectId(el.id);
                                        setOpenDeleteM(true);
                                    } }
                                    created={ el.createdAt }
                                    title={ el.name }
                                    code={ el.code }
                                />
                            </Grid>
                        );
                    }) }
                </Grid>
            </ContainerPaperLayout>
        </Layout>
    );
};

const CustomAddButton = styled(Button)(() => ({
        '& .MuiButton-startIcon': { marginRight: 0 },
        '& .MuiSvgIcon-root': { fontSize: 50 },
        textTransform: 'none',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: 143,
    }
));

const CustomBox = styled(Box)(() => ({ height: '100%' }));

export default Project;
