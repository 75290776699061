import { createSlice } from '@reduxjs/toolkit';
import { ModelTree } from '../../../../domain/domain';

export type BasketConfigurationState = {
    refOfInstance: string;
    configuration: ModelTree[];
};

const initialBasketConfigurationState: BasketConfigurationState = {
    refOfInstance: '',
    configuration: [],
};
export const basketConfigurationSlice = createSlice({
    name: 'basketConfigurationSlice',
    initialState: initialBasketConfigurationState,
    reducers: {
        setRefOfInstance: (state, action) => {
            state.refOfInstance = action.payload;
        },
        setBasketProductConfiguration: (state, action) => {
            state.configuration = action.payload;
        },
        resetBasketProductConfiguration: (state) => {
            state = {
                refOfInstance: '',
                configuration: [],
            };
            return state;
        },
    },
});

export const { setRefOfInstance, setBasketProductConfiguration, resetBasketProductConfiguration } = basketConfigurationSlice.actions;
