import Asset3D from "./Assets/Asset3D";
import ResourceErrorData from "./ResourceErrorData";

export default class AssetErrorData extends ResourceErrorData {
    private readonly m_asset: Asset3D;

    private readonly m_refOfInstance: string;

    public constructor(p_url: string, p_asset: Asset3D, p_refOfInstance: string){
        super(p_url);
        if (p_asset == null) throw new Error('NullReferenceException : p_asset is null or undefined');

        this.m_refOfInstance = p_refOfInstance;
        this.m_asset = p_asset;
    }

    public get RefOfInstance() : string {
        return this.m_refOfInstance;
    }

    public get RefOfPart() : string {
        return this.m_asset.RefOfPart;
    }

    public get RefOfPartItem() : string {
        return this.m_asset.RefOfPartItem;
    }

    public get Asset() : Asset3D {
        return this.m_asset;
    }
}