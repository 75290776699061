import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ViewModes.scss';
import {
    ToggleButtonGroup,
    ToggleButton,
    Box, styled,
} from '@mui/material';
import modeView360 from '../../../../../../asset/360.svg';
import modeView360Withe from '../../../../../../asset/360White.svg';
import DeviceDetection from '@lutithree/build/Modules/Core/Utils/DeviceDetection';
import {RoomStudioMode} from "../../../../Libraries/Studios/RoomStudio/Domain/Modes/RoomStudioMode";
import CamerasService
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Cameras/CamerasService";
import {ViewMode} from "../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Cameras/ViewMode";
import {
    RoomStudioState,
    updateRoomStudioMode,
    updateViewMode
} from "../../../../Redux/Reducers/room-studio/RoomStudioReducer";
import {RootState} from "../../../../../../services/redux/root-reducers";
import {closeAllPanel} from "../../../../../../utils/close-all-panel";


export type ViewModesProps = {
    cameraService: CamerasService;
    roomStudioMode: RoomStudioMode
};

const ViewModes = (props: ViewModesProps) => {
    const dispatch = useDispatch();
    const { currentViewMode } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const [alignment, setAlignment] = useState('3D');

    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const onClickAction = (viewMode: ViewMode) => {
        if (viewMode !== null && viewMode !== undefined) {
            if (currentViewMode) {
                if (props.roomStudioMode === RoomStudioMode.Accelerometer &&
                    (viewMode.toString() === ViewMode.OrtographicTop.toString() || viewMode.toString() === ViewMode.ThirdPerson.toString())) {
                    dispatch(updateRoomStudioMode(RoomStudioMode.Furnishing));
                }
            }
            dispatch(updateViewMode(viewMode));
        }
    };

    useEffect(() => {
        if (currentViewMode != undefined) {
            props.cameraService.SetViewMode(currentViewMode);
            switch (currentViewMode.toString()) {
                case ViewMode.OrtographicTop.toString(): {
                    setAlignment('2D');
                    break;
                }
                case ViewMode.ThirdPerson.toString(): {
                    setAlignment('3D');
                    break;
                }
                case ViewMode.FirstPerson.toString(): {
                    setAlignment('360');
                    dispatch(updateRoomStudioMode(RoomStudioMode.Accelerometer));
                    break;
                }
                default:
                    break;
            }
        }
    }, [currentViewMode]);

    return (
        <>
            <Box
                onClick={ () => closeAllPanel(dispatch) }
                sx={ {
                    position: 'fixed',
                    left: '1%',
                    top: '50%',
                    background: 'white',
                    borderRadius: 25,
                    boxShadow: 2,
                } }
            >
                <ToggleButtonGroup
                    color='primary'
                    orientation='vertical'
                    value={ alignment }
                    exclusive
                    onChange={ handleAlignment }
                    aria-label='text alignment'
                >
                    <StyledToggleBtn
                        onClick={ () => onClickAction(ViewMode.OrtographicTop) }
                        sx={ { mb: '1px' } } value='2D' aria-label='top 2D'>
                        2D
                    </StyledToggleBtn>
                    { props.roomStudioMode !== RoomStudioMode.Measuring &&
                        <StyledToggleBtn
                            onClick={ () => onClickAction(ViewMode.ThirdPerson) }
                            value='3D' aria-label='middle 3D'
                        >
                            3D
                        </StyledToggleBtn> }
                    { DeviceDetection.IsMobileIOS() && (props.roomStudioMode === RoomStudioMode.Accelerometer || props.roomStudioMode === RoomStudioMode.Furnishing) &&
                        <StyledToggleBtn
                            onClick={ () => {
                                onClickAction(ViewMode.FirstPerson);
                                window.dispatchEvent(new Event('GetAccelRights'));
                            } }
                            sx={ { mt: '1px !important' } } value='360' aria-label='bottom 360'>
                            <img style={ {
                                height: '20px',
                            } } src={ alignment === '360' ? modeView360Withe : modeView360 } alt='logo 360 mode' />
                        </StyledToggleBtn> }
                </ToggleButtonGroup>
            </Box>
        </>
    );
};

const StyledToggleBtn = styled(ToggleButton)(({ theme }) => ({
        fontSize: 14,
        color: theme.palette.primary.main,
        background: 'white',
        border: 'none',
        padding: '16px 9px',
        borderRadius: '24px !important',
        '&:hover': {
            background: 'white',
        },
        '&.Mui-selected': {
            color: 'white',
            background: theme.palette.primary.main,
            '&:hover': {
                background: theme.palette.primary.main,
            },
        },
    }),
);


export default ViewModes;
