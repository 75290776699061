import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import paintOn from '../../../../../application3D-common/Assets/Icons/ContextualMenu/Planner_peindremenucontextuel-on.svg';
import paintOff from '../../../../../application3D-common/Assets/Icons/ContextualMenu/Planner_peindremenucontextuel-off.svg';
import {ViewMode} from "../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Cameras/ViewMode";
import ToogleIcon from "../../../../../application3D-common/Components/UI/Basics/ToogleIcon";
import {RoomStudioState, updateViewMode} from '../../../../Redux/Reducers/room-studio/RoomStudioReducer';
import {
    OpenOpeningPaintPanel,
    OpenPaintPanel,
    OpenPrimitivePaintPanel,
    PanelState
} from "../../../../../../services/redux/reducers/panel/panel.slice";
import {
    ContextualMenuState, setDuplcateIcon,
    setPaintIcon,
    setRemplaceIcon,
    setScaleIcon
} from "../../../../Redux/Reducers/ContextualMenuReducer";
import {RootState} from "../../../../../../services/store";


export type PaintToogleProps = {
    entity: SceneEntity | undefined;
};

const PaintToogle = (props: PaintToogleProps) => {
    const dispatch = useDispatch();
    const {
        paintPanel,
        primitivePaintPanel,
        openingPaintPanel,
    } = useSelector<RootState, PanelState>((state) => state.panel);
    const [isOn, setIsOn] = useState<boolean>(false);
    const { currentViewMode } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const { selectedObject } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const icon = useSelector<RootState, ContextualMenuState>((state) => state.contextualMenu);

    const onChange = () => {
        setIsOn(!isOn);
        dispatch(setPaintIcon(!icon.paintIcon));
        dispatch(setScaleIcon(false));
        dispatch(setRemplaceIcon(false));
        dispatch(setDuplcateIcon(false));
    };

    useEffect(() => {
        if (selectedObject && selectedObject.Informations.Type) {
            if (selectedObject.Informations.Type === 'Wall') {
                setIsOn(paintPanel);
            } else if (selectedObject.Informations.Type === 'BuildingBlock') {
                setIsOn(primitivePaintPanel);
            } else if (selectedObject.Informations.Type === 'Opening') {
                setIsOn(openingPaintPanel);
            }
        }
    }, [paintPanel, primitivePaintPanel, openingPaintPanel]);

    useEffect(() => {
        if (selectedObject && selectedObject.Informations.Type) {
            if (selectedObject.Informations.Type === 'Wall') {
                if (isOn) dispatch(updateViewMode(ViewMode.ThirdPerson));
                dispatch(OpenPaintPanel(isOn));
            }
            if (selectedObject.Informations.Type === 'BuildingBlock') {
                if (isOn) dispatch(updateViewMode(ViewMode.ThirdPerson));
                dispatch(OpenPrimitivePaintPanel(isOn));
            }
            if (selectedObject.Informations.Type === 'Opening') {
                if (isOn) dispatch(updateViewMode(ViewMode.ThirdPerson));
                dispatch(OpenOpeningPaintPanel(isOn));
            }
        }
    }, [isOn]);

    useEffect(() => {
        if (currentViewMode != undefined) {
            if (currentViewMode.toString() === ViewMode.ThirdPerson.toString() && isOn) {
                setIsOn(true);
            } else {
                setIsOn(false);
            }
        }
    }, [currentViewMode]);

    return <ToogleIcon imgOn={ paintOn } imgOff={ paintOff } label='Peindre' active={ isOn }
                       onChange={ onChange } />;
};
export default PaintToogle;
