import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import ShapeData
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/ShapeData";

export default class StructureInstanciatedEvent {
    
    private readonly m_wallEntities: SceneEntity[];

    private readonly m_groundEntity: SceneEntity;
    
    private readonly m_shape: ShapeData;
    
    public constructor(p_wallEntities: SceneEntity[], p_groundEntity: SceneEntity, p_shape: ShapeData){
        if (p_wallEntities == null) throw new Error('NullReferenceException : p_wallEntities is null or undefined');
        if (p_groundEntity == null) throw new Error('NullReferenceException : p_groundEntity is null or undefined');
        if (p_shape == null) throw new Error('NullReferenceException : p_shape is null or undefined');
        
        this.m_wallEntities = p_wallEntities;
        this.m_groundEntity = p_groundEntity;
        this.m_shape = p_shape;
    }
    
    public get WallEntities(): SceneEntity[] {
        return this.m_wallEntities;
    }

    public get GroundEntity(): SceneEntity {
        return this.m_groundEntity;
    }

    public get Shape(): ShapeData {
        return this.m_shape;
    }
}
