import {AComponent} from "@lutithree/build/Modules/Core/Entity/AComponent";
import {IComponent} from "@lutithree/build/Modules/Core/Entity/IComponent";
import Information
    from "../../../Domain/Objects/Information";

export default class InfoComponent extends AComponent implements IComponent {
    private m_objectInfo: Information;

    public constructor(p_objectInfo: Information) {
        if (p_objectInfo == null) throw new Error('NullReferenceException : p_object is null or undefined');
        super();

        this.m_objectInfo = p_objectInfo;
    }

    public get Info(): Information {
        return this.m_objectInfo;
    }
}
 