import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MdfApiResponse from '../../../../domain/common/generic';
import { MappingDTO } from '../../../../domain/domain';
import mappingMzService from '../../../../api/service/mapping-zoning/mappingMz.service';

export type MappingMzState = {
    isLoading: boolean;
    payload: MdfApiResponse<MappingDTO[]>;
};

const initialMappingMzState: MappingMzState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

//<editor-fold desc="get-group">
export const getMappingMz = createAsyncThunk('get/mappingMz', async (refOfPart: string) => {
    const res = await mappingMzService.createMapping(refOfPart);
    return res;
});

export const getMappingMzSlice = createSlice({
    name: 'getMappingMz',
    initialState: initialMappingMzState,
    reducers: {
        resetGetMappingMz: (state) => {
            state = initialMappingMzState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMappingMz.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getMappingMz.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state.payload = payload;
            state.isLoading = false;
        });
        builder.addCase(getMappingMz.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetMappingMz } = getMappingMzSlice.actions;
//</editor-fold>
