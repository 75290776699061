import { createSlice } from '@reduxjs/toolkit';

export type PanelState = {
    panel: boolean;
    openAddElementPanel: boolean;
    primitivePanel: boolean;
    primitivePaintPanel: boolean;
    openingPaintPanel: boolean;
    paintPanel: boolean;
    floorPanel: boolean;
    addProductPanel: boolean;
};

const initialPanelState: PanelState = {
    panel: false,
    openAddElementPanel: false,
    primitivePanel: false,
    primitivePaintPanel: false,
    openingPaintPanel: false,
    paintPanel: false,
    floorPanel: false,
    addProductPanel: false,
};

export const panelSlice = createSlice({
    name: 'panel',
    initialState: initialPanelState,
    reducers: {
        Panel: (state, action) => {
            state.panel = action.payload;
        },
        OpenPrimitivePanel: (state, action) => {
            state.primitivePanel = action.payload;
        },
        OpenAddElementPanel: (state, action) => {
            state.openAddElementPanel = action.payload;
        },
        OpenPrimitivePaintPanel: (state, action) => {
            state.primitivePaintPanel = action.payload;
        },
        OpenOpeningPaintPanel: (state, action) => {
            state.openingPaintPanel = action.payload;
        },
        OpenPaintPanel: (state, action) => {
            state.paintPanel = action.payload;
        },
        OpenFloorPaintPanel: (state, action) => {
            state.floorPanel = action.payload;
        },
        OpenAddProductPanel: (state, action) => {
            state.addProductPanel = action.payload;
        },
    },
});

export const { OpenPrimitivePanel, OpenPaintPanel, Panel, OpenFloorPaintPanel, OpenPrimitivePaintPanel, OpenOpeningPaintPanel, OpenAddElementPanel, OpenAddProductPanel } = panelSlice.actions;
