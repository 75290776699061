import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { BoundingBoxComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundingBoxComponent';
import { SelectableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SelectableComponent';
import { RotControlableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/RotControlableComponent';
import { YControlableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/YControlableComponent';
import { Plane, Vector3 } from 'three';
import { TranslatableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/TranslatableComponent';
import { SnappableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SnappableComponent';
import { AutoReorientationComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/AutoReorientationComponent';
import { BoundToRoomComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundToRoomComponent';
import { RaycastableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/RaycastableComponent";
import BasicObject
    from "../../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import {
    IObjectDecorator
} from "../../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/IObjectDecorator";
import Instance3D
    from "../../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/Instance3D";
import {
    InversibleComponent
} from "../../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Components/InversibleComponent";
import {
    RelativePositionableComponents
} from "../../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/RelativePositioning/Components/RelativePositionableComponents";
import {
    ScalableComponent
} from "../../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Components/ScalableComponent";
import ActionComponent
    from "../../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ActionComponent";
import { ObjectAction } from '../../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ObjectAction';

export class OpeningDecorator implements IObjectDecorator {

    public DecorateRootObject(p_entity: SceneEntity, p_object: BasicObject, p_instance: Instance3D | undefined): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_object == null) throw new Error('NullReferenceException : p_object is null or undefined');

        p_entity.AddComponentOfType(BoundingBoxComponent, p_entity.Transform.GetObject());
        p_entity.AddComponentOfType(AutoReorientationComponent);
        p_entity.AddComponentOfType(BoundToRoomComponent);
        let normal = new Vector3(0, 1, 0);
        let plane = new Plane(normal, 0);
        p_entity.AddComponentOfType(TranslatableComponent, plane);
        p_entity.AddComponentOfType(InversibleComponent, true, false, false);
        p_entity.AddComponentOfType(SelectableComponent);
        p_entity.AddComponentOfType(RelativePositionableComponents);
        p_entity.AddComponentOfType(YControlableComponent, new Vector3(0, 1, 0));
        p_entity.AddComponentOfType(RotControlableComponent);
        p_entity.AddComponentOfType(ActionComponent, p_object.Informations.Name, [
            ObjectAction.Scale,
            ObjectAction.Paint,
            ObjectAction.Invert,
            ObjectAction.Replace,
            ObjectAction.Duplicate,
            ObjectAction.Delete,
        ]);
        p_entity.AddComponentOfType(SnappableComponent);
    }

    public DecorateObject(p_entity: SceneEntity, p_object: BasicObject, p_instance: Instance3D | undefined): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_object == null) throw new Error('NullReferenceException : p_object is null or undefined');

        p_entity.AddComponentOfType(BoundingBoxComponent, p_entity.Transform.GetObject());
        p_entity.AddComponentOfType(InversibleComponent, true, false, false);
        p_entity.AddComponentOfType(SelectableComponent);
        p_entity.AddComponentOfType(ActionComponent, p_object.Informations.Name, [
            ObjectAction.Scale,
            ObjectAction.Paint,
            ObjectAction.Invert,
            ObjectAction.Replace,
            ObjectAction.Duplicate,
            ObjectAction.Delete,
        ]);
    }

    public DecoratePart(p_entity: SceneEntity, p_parentObject: BasicObject, p_parentEntity:SceneEntity, p_refOfPart: string): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_parentObject == null) throw new Error('NullReferenceException : p_parentObject is null or undefined');
        if (p_parentEntity == null) throw new Error('NullReferenceException : p_parentEntity is null or undefined');
        if (!p_refOfPart) throw new Error('NullReferenceException : p_refOfPart is null or undefined');

        if((p_parentObject.Composition.Type === 'Asset3D') || p_parentObject.Assets.length>0) {
            p_entity.AddComponentOfType(RaycastableComponent);
            if (p_refOfPart === 'Base') {
                p_entity.AddComponentOfType(ScalableComponent);
            }
        }
    }
}
