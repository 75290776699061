import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { RelativeDistanceDisplayerComponent } from "../Components/RelativeDistanceDisplayer";
import { Guid } from "guid-typescript";
import { IEntityDecorator } from "../../../../Domain/IEntityDecorator";
import { Camera, Object3D, PerspectiveCamera } from "three";
import { DynamicScaleComponent } from "../../TransformControls/Components/DynamicScaleComponent";

export class DistanceDisplayerDecorator implements IEntityDecorator<SceneEntity> {
    
    private readonly m_targetGuid: Guid;
    
    private m_mainCam: Camera;
    
    public constructor(p_target: SceneEntity, p_mainCam: Camera) {
        if (p_target == null) throw new Error('NullReferenceException : p_target is null or undefined');
        
        this.m_mainCam = p_mainCam;
        this.m_targetGuid = p_target.Id;
    }
    
    public Decorate(p_entity: SceneEntity): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        
        let reltiveComponent = p_entity.AddComponentOfType(RelativeDistanceDisplayerComponent, this.m_targetGuid);
        let objects: Object3D[] = []; 
        reltiveComponent.m_distanceMap.forEach((obj)=>{
            objects.push(obj.p_sticker.m_stickerObject);
        });
        let dynamicScale = p_entity.AddComponentOfType(DynamicScaleComponent, objects, this.m_mainCam);
        if(this.m_mainCam instanceof PerspectiveCamera)
            dynamicScale.Enable(false);
        p_entity.Transform.GetObject().add(reltiveComponent.GetObject());
    }
}