import {Box3, Camera, Matrix4, Object3D, PerspectiveCamera, Quaternion, Spherical, Vector3} from 'three';
import { OBB } from 'three/examples/jsm/math/OBB';
import { CameraChangedEvent } from '@lutithree/build/Modules/WebGL/Rendering/Events/CameraChangedEvent';
import { SupportComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SupportComponent';
import { OrbitControlComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Controls/OrbitControlComponent';
import { ViewModeChangeEvent } from './Events/ViewModeChangeEvent';
import { CameraSystem } from '@lutithree/build/Modules/WebGL/Scene/Systems/CameraSystem';
import { ViewModeSystem } from './Systems/ViewModeSystem';
import { Engine } from '@lutithree/build/Engine';
import { ViewModeCameraComponent } from './Components/ViewModeCameraComponent';
import { POVSystem } from './Systems/POVSystem';
import { CameraComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Rendering/CameraComponent';
import { IViewModeController } from "../../../Domain/Cameras/IViewModeController";
import { EntityPOVChangedEvent } from "./Events/EntityPOVChangedEvent";
import AService from "../../../../Application3D/Domain/AService";
import { INavigationController } from "../../../Domain/Features3D/INavigationController";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import {ViewMode} from "../../../Domain/Cameras/ViewMode";

export default class CamerasService extends AService implements INavigationController, IViewModeController {

    private m_cameraSystem: CameraSystem;

    private m_viewModeSystem: ViewModeSystem;

    private m_povSystem: POVSystem;

    public constructor(p_engine: Engine) {
        super(p_engine);

        this.m_cameraSystem = new CameraSystem(p_engine.Modules.Scene);
        this.m_viewModeSystem = new ViewModeSystem(p_engine.Modules.Scene);
        this.m_povSystem = new POVSystem(p_engine.Modules.Scene, this.m_cameraSystem);
    }

    public get CurrentViewMode(): ViewMode | undefined {
        return this.m_viewModeSystem.GetCurrentViewMode();
    }

    public get CurrentCamera(): CameraComponent {
        let cameraComponent = this.m_cameraSystem.GetMainCameraComponent();
        if(cameraComponent) return cameraComponent;
        else throw new Error('NullReferenceException : there is no CurrentCamera Component in scene');
    }

    public SetViewMode(p_viewMode: ViewMode): void {
        if (p_viewMode == null) throw new Error('NullReferenceException : p_viewMode is null or undefined');

        let mainCamera = this.m_cameraSystem.GetMainCameraComponent();
        if (mainCamera instanceof ViewModeCameraComponent && mainCamera.ViewMode !== p_viewMode) {
            let result = this.m_viewModeSystem.GetCamerasByViewMode(p_viewMode);

            if (result.length > 0) {
                result[0].IsMain = true;
                mainCamera.IsMain = false;
                this.m_engine.Modules.EventManager.Publish(ViewModeChangeEvent, new ViewModeChangeEvent(result[0].ViewMode));
                this.m_engine.Modules.EventManager.Publish(CameraChangedEvent, new CameraChangedEvent(result[0]));

                this.UpdatePOVStatus();
            }
        }
    }

    public ApplyRelativeSphericalPosFromTarget(p_spherical: Spherical, p_target?: Object3D) {
        if (p_spherical == null)
            throw new Error("NullReference Exception: p_spherical is null or undefined");

        let camDatas = this.m_engine.Modules.Systems.CameraSystem.GetMainCameraDatas();

        let cameraPosition = new Vector3();
        cameraPosition.setFromSpherical(p_spherical.makeSafe());

        let targetPosition: Vector3 = new Vector3();
        if(p_target){
            const boundingbox = new Box3();
            boundingbox.expandByObject(p_target);
            targetPosition = boundingbox.getCenter(new Vector3());

            let targetWorldQuaternion = new Quaternion();
            p_target.getWorldQuaternion(targetWorldQuaternion);
            cameraPosition.applyQuaternion(targetWorldQuaternion);
        }
        else{
            if(camDatas.control) targetPosition = camDatas.control.target;
        }

        cameraPosition = new Vector3().addVectors(cameraPosition, targetPosition);
        camDatas.camera.position.copy(cameraPosition);
        camDatas.control?.target.set(targetPosition.x, targetPosition.y, targetPosition.z);

        if(camDatas.control) camDatas.control.update();

        this.m_engine.Modules.LoopStrategy.RequestRender(false);
    }

    public ApplyRelativeCarthesianPosFromTarget(p_carthesian: Vector3, p_target?: Object3D|undefined) {
        if (p_carthesian == null)
            throw new Error("NullReference Exception: p_carthesian is null or undefined");

        let camDatas = this.m_engine.Modules.Systems.CameraSystem.GetMainCameraDatas();

        let targetPosition: Vector3 = new Vector3();
        if(p_target){
            const boundingbox = new Box3();
            boundingbox.expandByObject(p_target);
            targetPosition = boundingbox.getCenter(new Vector3());
        }
        else{
            if(camDatas.control) targetPosition = camDatas.control.target;
        }

        camDatas.camera.position.copy(new Vector3().addVectors(p_carthesian, targetPosition));
        camDatas.camera.lookAt(targetPosition);
        if(camDatas.control) camDatas.control.update();

        this.m_engine.Modules.LoopStrategy.RequestRender(false);
    }

    public GetRelativePosFromTarget(p_camera: Camera, p_target?: Object3D) : Spherical {
        let orbitControl = this.GetOrbitControl(p_camera);

        let  spherical = new Spherical();
        if(orbitControl){
            spherical.radius = orbitControl.GetControl().getDistance();
            spherical.phi = orbitControl.GetControl().getPolarAngle();
            spherical.theta = orbitControl.GetControl().getAzimuthalAngle();
            spherical = spherical.makeSafe();
            let position = new Vector3();
            position.setFromSpherical(spherical);

            if(p_target){
                let targetInvertedQuaternion = new Quaternion().copy(p_target.quaternion).invert();
                position.applyQuaternion(targetInvertedQuaternion);
            }

            spherical.setFromCartesianCoords(position.x, position.y, position.z);
        }
        spherical = spherical.makeSafe();

        return spherical;
    }
    
    public Get2DCamPosition(): Vector3 | undefined {
        let cam = this.m_engine.Modules.Scene.GetComponents(ViewModeCameraComponent).find((obj)=>{
            return obj.ViewMode === ViewMode.OrtographicTop;
        });
        if(cam)
            return cam.GetObject().position;
        else
            return undefined;
    }

    public SetFPViewToCameraTarget(): void {
        let mainThirdCam = this.m_viewModeSystem.GetCamerasByViewMode(ViewMode.ThirdPerson)[0];
        let mainFPCam = this.m_viewModeSystem.GetCamerasByViewMode(ViewMode.FirstPerson)[0];
        let targetPos = this.m_cameraSystem.GetControlForCamera(mainThirdCam)?.Target;
        
        let camEntity = this.m_engine.Modules.Scene.GetEntityByID(mainFPCam.EntityID);
        if (camEntity.HasComponentOfType(ViewModeCameraComponent) && camEntity.GetComponentOfType(ViewModeCameraComponent).ViewMode === ViewMode.FirstPerson) {
            if (targetPos)
                camEntity.Transform.GetObject().position.set(targetPos.x, 1.6, targetPos.z);
        } else {
            console.warn("Cannot Set 2D view on main cam perspective");
        }
    }
    
    public Move360CameraForward(p_distance: number = 0.65) {
        let mainFPCam = this.m_viewModeSystem.GetCamerasByViewMode(ViewMode.FirstPerson)[0];

        let camEntity = this.m_engine.Modules.Scene.GetEntityByID(mainFPCam.EntityID);
        if (camEntity.HasComponentOfType(ViewModeCameraComponent) && camEntity.GetComponentOfType(ViewModeCameraComponent).ViewMode === ViewMode.FirstPerson) {
            let offset = camEntity.Transform.Forward.clone();
            offset.setY(0);
            offset.normalize().multiplyScalar(-p_distance);
            camEntity.Transform.AddToPosition(offset);
            this.m_engine.Modules.LoopStrategy.RequestRender(true);
        } else {
            console.warn("Cannot Set 2D view on main cam perspective");
        }
    }
    
    public Set2DViewToPosition(p_position: Vector3): void {
       let mainCam = this.m_cameraSystem.GetMainCameraComponent();
       let camEntity = this.m_engine.Modules.Scene.GetEntityByID(mainCam.EntityID);
       let camControl = this.m_cameraSystem.GetControlForCamera(mainCam);
       if(camEntity.HasComponentOfType(ViewModeCameraComponent) && camEntity.GetComponentOfType(ViewModeCameraComponent).ViewMode === ViewMode.OrtographicTop) {
           mainCam.GetObject().position.set(p_position.x, p_position.y, p_position.z); 
           if(camControl) camControl.Target = new Vector3(p_position.x, 0, p_position.z);
       }
       else {
           console.warn("Cannot Set 2D view on main cam perspective");
       }
    }
    
    //TODO Rename to match nomenclature
    public SetRoomAtCenterOf2DView(): void {
        let viewModeSaving = this.CurrentViewMode;
        this.EnableEntitiesForViewMode(ViewMode.OrtographicTop);
        let supportObjects: Object3D[] = [];
        this.m_engine.Modules.Scene.GetComponents(SupportComponent).forEach((object) => {
            supportObjects.push(object.SupportObject);
        });
        this.m_engine.Modules.Scene.GetComponents(ViewModeCameraComponent).forEach((camera) => {
            if (camera.ViewMode !== ViewMode.OrtographicTop) return;
            let camControl = this.m_cameraSystem.GetControlForCamera(camera);

            if (camControl) this.m_cameraSystem.FocusCameraOnObject(camera.GetObject(), camControl.GetControl(), supportObjects);
        });
        if(viewModeSaving) this.EnableEntitiesForViewMode(viewModeSaving);
    }

    public SetCameraTargetAtCenter(p_objects: Object3D[] | undefined = undefined, p_forceY: undefined | number = undefined): void {
        let position: Vector3 = new Vector3(0, 0, 0);
        if (p_objects) {
            const AABB = new Box3();
            for (const object of p_objects) AABB.expandByObject(object);
            let boundingBox: OBB = new OBB().fromBox3(AABB);
            if(p_forceY == undefined)
                position = new Vector3(boundingBox.center.x, boundingBox.center.y, boundingBox.center.z);
            else 
                position = new Vector3(boundingBox.center.x, p_forceY, boundingBox.center.z);
        }
        let cameraDatas = this.m_cameraSystem.GetMainCameraDatas();
        if (cameraDatas.control ) cameraDatas.control.target = position;
        else 
            console.warn("Camera controls found were : ", cameraDatas);
    }

    public SetCameraTargetPosition(p_position : Vector3) {
        let cameraControl;
        if(this.CurrentCamera)
            cameraControl = this.m_cameraSystem.GetControlForCamera(this.CurrentCamera);
        if(cameraControl){
            cameraControl.Target.set(p_position.x, p_position.y, p_position.z);
            cameraControl.Update();
        }
        else throw new Error("Failed to find target");
    }
    
    public GetCameraTargetPosition(): Vector3 {
        let cameraControl;
        if(this.CurrentCamera)
            cameraControl = this.m_cameraSystem.GetControlForCamera(this.CurrentCamera);
        if(cameraControl)
            return cameraControl.Target;
        else throw new Error("Failed to find target");
        //return cameraTarget.length > 0 ? cameraTarget[0].Transform.GetObject().position.clone() : new Vector3();
    }

    public EnableNavigation(p_enable: boolean): void {
        // Freeze orbit controls
        this.m_engine.Modules.Scene.GetComponents(OrbitControlComponent, { entity: false, component: false }).forEach((control) => {
            control.Enable(p_enable);
        });
    }

    public EnablePan(p_enable: boolean): void {
        // Freeze orbit controls
        this.m_engine.Modules.Scene.GetComponents(OrbitControlComponent, { entity: false, component: false }).forEach((control) => {
            control.GetControl().enablePan = p_enable;
        });
    }

    public EnableEntitiesForViewMode(p_viewMode: ViewMode): void {
        if (p_viewMode == null) throw new Error('NullReferenceException : p_viewMode is null or undefined');

        this.m_viewModeSystem.EnableEntitesForViewMode(p_viewMode);
    }

    public FocusPerspectiveCameraOnObjects(p_objects: Object3D[], p_fitRatio: number = 0.9): void {
        if (p_objects == null) throw new Error('NullReferenceException : p_objects is null or undefined');
        this.m_cameraSystem.FocusMainCameraOnObject(p_objects, p_fitRatio);
    }

    public OnPerspectiveCameraChange(): void {
        this.UpdatePOVStatus();
        this.m_engine.Modules.LoopStrategy.RequestRender(false);
    }

    public OnTopCameraChange(): void {
        this.m_engine.Modules.LoopStrategy.RequestRender(false);
    }
    
    public UpdatePOVStatus(p_entity?: SceneEntity): void{
        let povUpdated;
        if(p_entity) {
            povUpdated = this.m_povSystem.UpdatePOVStatus(p_entity);
        }
        povUpdated = this.m_povSystem.UpdateAllEntitiesPOVStatus();
        povUpdated.forEach((povData)=>{
            this.m_engine.Modules.EventManager.Publish(EntityPOVChangedEvent, new EntityPOVChangedEvent(povData.entity, povData.pov));
        });
    }

    public SetCallbackOnInteractionOnCameraStart(p_callback: ()=>void): void {
        if(p_callback == null) throw new Error("NullReference Exception: p_callback is null or undefined");

        let orbitControls = this.m_engine.Modules.Scene.GetComponents(OrbitControlComponent);
        orbitControls.forEach((controlComponent)=>{
            controlComponent.GetControl().addEventListener( 'start', ()=>{
                p_callback();
            });
        });
    }
    
    private GetOrbitControl(p_camera: Camera) : OrbitControlComponent|undefined {
        let control = this.m_engine.Modules.Scene.GetComponents(OrbitControlComponent).filter((controlComponent)=>{
            return controlComponent.GetControl().object === p_camera;
        });
        if(control.length > 0) return control[0];
        else return undefined;
    }
}
