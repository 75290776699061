import * as React from 'react';
import replaceOff from '../../../../../../asset/change-moving.svg';
import { useSelector } from 'react-redux';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Box, styled, Typography } from '@mui/material';
import ButtonToggleRoom from "../../../../../application3D-common/Components/UI/Composites/ButtonToggleRoom";
import {RoomStudioState} from "../../../../Redux/Reducers/room-studio/RoomStudioReducer";
import {RootState} from "../../../../../../services/redux/root-reducers";


export type InvertButtonProps = {
    entity: SceneEntity | undefined;
};

const InvertButton = (props: InvertButtonProps) => {
    const { roomStudioApp } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);

    const invertEntity = (p_entity: SceneEntity) => {
        if (roomStudioApp) {
            roomStudioApp.Services.Scale.Invert(p_entity);
        }
    };

    const handleClick = () => {
        if (props.entity) {
            invertEntity(props.entity);
        }
    };

    return (
        <StyledBoxButtonGroup>
            <ButtonToggleRoom
                altLogo='Moove'
                logo={ replaceOff }
                actions={ handleClick }
                text={
                    <Typography variant='subtitle2' color='primary'>
                        Changer le sens
                    </Typography>
                }
            />
        </StyledBoxButtonGroup>
    );
};

const StyledBoxButtonGroup = styled(Box)(({ theme }) => ({
    margin: theme.spacing(0.5),
    height: 65,
    minWidth: 80,
    '& .Mui-disabled': {
        border: 0,
    },
    '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
    },
}));

export default InvertButton;
