import { MeshLinksComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshLinksComponent';
import { Mesh, Quaternion, Vector2, Vector3 } from 'three';
import { MeshUtils } from '@lutithree/build/Modules/WebGL/Utils/MeshUtils';
import { ASystem } from '@lutithree/build/Modules/Core/Entity/ASystem';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import WallComponent from "../Components/WallComponent";
import { MeshFilterComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent";
import ShapeData
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/ShapeData";
import ObjectComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";

export default class RoomGeometrySystem extends ASystem<SceneEntity> {
    private m_minWallWidth: number = 1;
    
    public IsRoomGeometryCorrect(p_connectables: MeshLinksComponent[]): boolean {
        if (p_connectables == null) throw new Error('NullReferenceException : p_connectables is null or undefined');
        for (let i: number = 0; i < p_connectables.length; i++) {
            let connections = p_connectables[i].MeshConnections;
            for (let j: number = 0; j < connections.length; j++) {
                let scale1 = MeshUtils.GetGeometryWorldScale(connections[j].Key.Mesh);
                if (scale1 !== undefined && scale1.x < this.m_minWallWidth) return false;
            }
        }
        return true;
    }

    public GetRoomShapeTemplate(): ShapeData {
        // get walls
        let wallObjectComponents = this.m_entityManager.GetComponents(WallComponent, { entity: false, component: false });
        let shape = new Array<Vector2>(wallObjectComponents.length + 1);
        let refOfSections = new Array<string>(wallObjectComponents.length);

        wallObjectComponents.forEach((wallComponent) => {
            // get wall mesh
            let objectComponent = 
                this.m_entityManager.GetComponents(ObjectComponent, { entity: false, component: false })
                    .filter(x => x.Ref == wallComponent.RefOfInstance);
            let mesh: Mesh | undefined;
            if(objectComponent && objectComponent.length>0) mesh = this.GetMeshes(objectComponent[0]);
            if (mesh) {
                let position = this.GetSectionInitialPoint(mesh);

                // Build shape template
                shape[wallComponent.ShapeSectionIndex] = position;
                refOfSections[wallComponent.ShapeSectionIndex] = wallComponent.RefOfInstance;

                if (wallComponent.ShapeSectionIndex === 0) {
                    shape[wallObjectComponents.length] = position;
                }
            }
        });
        let shapeTemplate = new ShapeData();
        shapeTemplate.RefOfSections = refOfSections;
        shapeTemplate.Points = shape;

        return shapeTemplate;
    }

    private GetMeshes(p_objectComponent:  ObjectComponent): Mesh | undefined {
        if (p_objectComponent.PartIds.size > 0) {
            // @ts-ignore
            let [entityID] = p_objectComponent.PartIds.values();
            let entity = this.m_entityManager.GetEntityByID(entityID);
            if (entity.HasComponentOfType(MeshFilterComponent)) {
                let meshFilter = entity.GetComponentOfType(MeshFilterComponent);
                let meshes = meshFilter.GetMeshes();
                if (meshes.length > 0) return meshes[0];
            }
        }
        return undefined;
    }

    private GetSectionInitialPoint(p_mesh: Mesh): Vector2 {
        p_mesh.updateMatrixWorld();

        // Get first wall point
        let boundingbox = p_mesh.geometry.boundingBox;

        let worldPosition = new Vector3();
        p_mesh.getWorldPosition(worldPosition);

        let max = boundingbox!.max.clone().applyMatrix4(p_mesh.matrixWorld);
        let quaternion1 = new Quaternion().setFromUnitVectors(new Vector3(0, 1, 0), new Vector3(0, 0, 1));
        max.applyQuaternion(quaternion1);

        // Compute position
        let x = parseFloat((Math.round(max.x * 100) / 100).toFixed(2));
        let y = parseFloat((Math.round(max.y * 100) / 100).toFixed(2));

        return new Vector2(x, y);
    }
}
