import * as React from 'react';
import { PropsWithChildren } from 'react';
import { styled } from '@mui/material';


type HeaderLayoutProps = PropsWithChildren<{}>;

const HeaderLayout = ({ children }: HeaderLayoutProps) => {
    return (
        <Wrapper>
            <Nav>
                { children }
            </Nav>
        </Wrapper>
    );
};

const Wrapper = styled('header')(() => ({
    width: '100%',
    height: 68,
    padding: '0.8em 1.8em',
    borderBottom: `1px solid #e3e3e3`,
    backgroundColor: '#fdfdfd',
    boxShadow: '0px 2px 6px #44444429',
    position: 'fixed',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    zIndex: 1000,
}));

const Nav = styled('nav')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
}));

export default HeaderLayout;