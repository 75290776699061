import { Box3, Matrix4, Object3D, Vector3 } from 'three';
import { OBB } from 'three/examples/jsm/math/OBB';

export class Object3DUtils {
    public static SetPivotAtObjectBBCenter(p_OBBCenter: Vector3,p_object: Object3D) {

        let objectWorldPosition = new Vector3();
        p_object.getWorldPosition(objectWorldPosition);
        
        p_object.children.forEach((childObject) => {
            
            let childWorldPosition = objectWorldPosition.clone().add(childObject.position);
            let offset = childWorldPosition.clone().sub(p_OBBCenter);
            
            let resultingPosition = new Vector3(offset.x, childObject.position.y, offset.z);
            
            childObject.position.copy(resultingPosition);
        });
        
    }
    
    public static ApplyPivot(p_object3D: Object3D, p_pivot: Vector3): void {
        if (p_object3D == null) throw new Error('NullReferenceException : p_object3D is null or undefined');
        if (p_pivot == null) throw new Error('NullReferenceException : p_pivot is null or undefined');

        let pivot = new Vector3(p_pivot.x, p_pivot.y, p_pivot.z);
        let AABB = new Box3().setFromObject(p_object3D);
        let boundingBox: OBB = new OBB().fromBox3(AABB);

        let objectSize = boundingBox.halfSize.clone().multiplyScalar(2);
        let offset: Vector3 = pivot.clone().multiply(objectSize);
        p_object3D.position.copy(offset);
    }

    public static GetPivotOffset(p_object3D: Object3D, p_pivot: Vector3): Vector3 {
        if (p_object3D == null) throw new Error('NullReferenceException : p_object3D is null or undefined');
        if (p_pivot == null) throw new Error('NullReferenceException : p_pivot is null or undefined');

        let initialRotation = p_object3D.rotation.clone();
        let initialPosition = p_object3D.position.clone();
        p_object3D.rotation.set(0, 0, 0);
        p_object3D.position.set(0, 0, 0);

        let pivot = new Vector3(p_pivot.x, p_pivot.y, p_pivot.z);
        let AABB = new Box3().setFromObject(p_object3D);
        let boundingBox: OBB = new OBB().fromBox3(AABB);

        p_object3D.position.set(initialPosition.x, initialPosition.y, initialPosition.z);
        p_object3D.rotation.set(initialRotation.x, initialRotation.y, initialRotation.z);
        boundingBox.applyMatrix4(new Matrix4().makeRotationFromEuler(p_object3D.rotation));

        let size = p_object3D.worldToLocal(boundingBox.halfSize.clone().multiplyScalar(2));
        let offset = pivot.clone().multiply(size);

        return offset;
    }
}