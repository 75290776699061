import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import ARoomStudioHandler from "../../../ARoomStudioHandler";
import { Object3D } from "three";
import BoundingBoxChangedEvent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/RelativePositioning/Events/BoundingBoxChangedEvent";
import {
    RelativeDistanceDisplayerComponent
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/RelativePositioning/Components/RelativeDistanceDisplayer";
import {
    DynamicScaleComponent
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Components/DynamicScaleComponent";
import {
    MarkerComponent
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Measuring/Components/MarkerComponent";

export class ObjectBBChangedHandler extends ARoomStudioHandler implements IHandler<BoundingBoxChangedEvent>{
    Handle(p_event: BoundingBoxChangedEvent): void {
        this.m_services.Helper.RefreshRelativePositioning(p_event.Entity);
        this.m_services.Dimemsions.DisableDimensionOnEntity(p_event.Entity);
        let relativeComponents = this.m_engine.Modules.Scene.GetComponents(RelativeDistanceDisplayerComponent);
        let objects: Object3D[] = [];
        relativeComponents.forEach((component)=>{
            component?.m_distanceMap.forEach((obj) => {
                objects.push(obj.p_sticker.m_stickerObject);
            });
            if(component) {
                let dynamicComponent = this.m_engine.Modules.Scene.GetEntityByID(component.EntityID).GetComponentOfType(DynamicScaleComponent);
                dynamicComponent.SetObjectsWithClear(objects);
            }
        });
        
        if(p_event.Entity.HasComponentOfType(MarkerComponent)) this.m_services.MeasureDrawer.UpdateMeasuresRelatedToMarker(p_event.Entity);
        
        this.m_services.Scale.DynamicRescaleRefresh();
    }
}
