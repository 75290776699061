import React, { PropsWithChildren } from 'react';
import Paper from '@mui/material/Paper';


type PaperLayoutProps = PropsWithChildren<{
    elevation: number;
    mt?: number | undefined;
    flexDirection: 'row' | 'column';
}>;

const PaperLayout = ({ children, elevation, mt, flexDirection }: PaperLayoutProps) => {
    const marginTop = mt && mt > 0 ? mt : 0;
    return (
        <Paper
            elevation={ elevation }
            sx={ {
                border: (theme) => `1px solid ${ theme.palette.divider }`,
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: flexDirection,
                mt: marginTop,
            } }
        >
            { children }
        </Paper>
    );
};

export default PaperLayout;