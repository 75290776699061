import * as React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, styled, Typography, CardMedia, CardActionArea, Grid } from '@mui/material';
import building from './../../../../asset/construction.png';
import door from './../../../../asset/porte1v@2x.png';
import window from './../../../../asset/fenetre2v@2x.png';
import slidingWindow from './../../../../asset/porte-fenetre2v@2.png';


type ElementPanelProps = {
    setOpenPanel: (openPanel: boolean) => void;
    setConstructPanel: (constructPanel: boolean) => void;
    setWindowPanel: (windowPanel: boolean) => void;
    setSlidingWindowPanel: (slidWindowPanel: boolean) => void;
    setOpenAddElementPanel: (openAddElementPanel: boolean) => void;
    setDoorPanel: (doorPanel: boolean) => void;
    setTitle: (setTitlePanel: string) => void;
};

type DataCard = {
    title: string;
    img: string;
    onClick: () => void
};

const ElementsPanel = (props: ElementPanelProps) => {

    const cardsData: DataCard[] = [
        {
            title: 'Construction',
            img: building,
            onClick: () => {
                props.setConstructPanel(true);
                props.setTitle('Elément de construction / Construction');
            },
        },
        {
            title: 'Portes',
            img: door,
            onClick: () => {
                props.setDoorPanel(true);
                props.setTitle('Elément de construction / Portes');
            },
        },
        {
            title: 'Fenêtres',
            img: window,
            onClick: () => {
                props.setWindowPanel(true);
                props.setTitle('Elément de construction / Fenêtres');
            },
        },
        {
            title: 'Portes-fenêtres',
            img: slidingWindow,
            onClick: () => {
                props.setSlidingWindowPanel(true);
                props.setTitle('Elément de construction / Portes-fenêtres');
            },
        },
    ];

    return (
        <>
            <StyledBoxInfo>
                <InfoOutlinedIcon sx={ {
                    fontSize: '2.7em',
                    marginRight: '10px',
                } } color='primary' />
                <StyledTypo variant='body2' color='primary'>
                    Les éléments de construction vous permettent de représenter des contraintes d'espace dans votre
                    pièce: escalier, cloisons, muret, portes et fenêtre...
                </StyledTypo>
            </StyledBoxInfo>
            <Box sx={ { pt: 3 } }>
                <Grid container spacing={ 2 }>
                    { cardsData.map((card, index: number) => {
                        return (
                            <Grid item xs={ 12 } md={ 6 } key={ index + card.title }>
                                <Box
                                    onClick={ card.onClick }
                                    sx={ {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    } }>
                                    <CardActionArea sx={ {
                                        borderRadius: '5px',
                                    } }>
                                        <CardMedia
                                            sx={ {
                                                borderRadius: '5px',
                                            } }
                                            component='img'
                                            alt={ card.title }
                                            src={ card.img }
                                        />
                                    </CardActionArea>
                                    <Typography sx={ { fontSize: 17, mt: 2, textAlign: 'center' } } variant='subtitle1'
                                                color='primary'>{ card.title }</Typography>
                                </Box>
                            </Grid>
                        );
                    }) }
                </Grid>
            </Box>
        </>
    );
};

const StyledBoxInfo = styled(Box)(({ theme }) => ({
    padding: 7,
    display: 'flex',
    alignItems: 'center',
    border: `solid 1px ${ theme.palette.primary.main }`,
    borderRadius: 5,
    [theme.breakpoints.down('lg')]: {
        padding: 5,
        flexDirection: 'column',
        fontSize: 14,
        textAlign: 'center',
    },
}));

const StyledTypo = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        fontSize: 14,
    },
}));

export default ElementsPanel;
