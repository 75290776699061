import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import AObjectEvent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/Events/AObjectEvent";
import Instance3D from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/Instance3D";
import ObjectComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";

export default class ObjectAddedToRoomEvent extends AObjectEvent {
    private m_instance3D: Instance3D;

    public constructor(p_entity: SceneEntity, p_objectComponent: ObjectComponent, p_instance: Instance3D) {
        super(p_entity,p_objectComponent);
        if (p_instance == null) throw new Error('NullReferenceException : p_instance is null or undefined');

        this.m_instance3D = p_instance;
    }
    
    public get Instance3D(): Instance3D {
        return this.m_instance3D;
    }
}
