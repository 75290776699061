import React, { useEffect, useState } from 'react';
import MainPaper from '../../ui-components/ContainerPaperLayout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/redux/root-reducers';
import { getRoom, GetRoomState, UpdateRoomNameState } from '../../services/redux/reducers/rooms/rooms.slice';
import { useParams } from 'react-router-dom';
import Layout from '../../ui-components/layout/layout';
import close from '../../asset/CloseWindow.svg';
import RoomDetailBody from '../../components/room-detail/room-detail.body';
import '../../components/room-detail/room-detail-body.scss';


const RoomDetail = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const { payload } = useSelector<RootState, GetRoomState>((state) => state.getRoom);
    const projectId = parseInt(params.projectId as string);
    const updateRoomNameRes = useSelector<RootState, UpdateRoomNameState>((state) => state.updateRoomName);
    const [openPreviewScreenshot, setOpenPreviewScreenshot] = useState(false);
    const [screen, setScreen] = useState('');

    useEffect(() => {
        if (params.name !== undefined) {
            dispatch(getRoom({ projectId: projectId, roomUuid: params.name }));
        }
    }, [updateRoomNameRes]);

    return (
        <>
            <Layout navbar={ true }>
                <MainPaper path={ `/project/${ projectId }/${ payload.content?.projectCode }` } backArrow
                           title={ payload.content?.projectName }>
                    <RoomDetailBody setScreenPrev={ setScreen } setOpenPreviewScreenshot={ setOpenPreviewScreenshot } />
                </MainPaper>
            </Layout>
            { openPreviewScreenshot &&
                <div className='screen-prev-main'>
                    <div className='screen-prev-bloc'>
                        <div className='close' onClick={ () => {
                            setOpenPreviewScreenshot(false);
                        } }>
                            <img alt='fermer' src={ close } />
                        </div>
                        <img className='screen' alt='preview' src={ screen } />
                    </div>
                </div>
            }
        </>
    );
};
export default RoomDetail;