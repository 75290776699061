import MdfApiResponse from '../../../../domain/common/generic';
import { NewsCategory } from '../../../../domain/domain';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NewsService from '../../../../api/service/news-service/news-service';

export type NewsCategoryState = {
    isLoading: boolean;
    payload: MdfApiResponse<NewsCategory[]>;
};

const initialState: NewsCategoryState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

export const getNewsCategoryList = createAsyncThunk('news/category', async (_, thunkApi) => {
    const response = await NewsService.getNewsCategories();
    return response;
});

const getNewsCategorySlice = createSlice({
    name: 'getNewsCategorySlice',
    initialState,
    reducers: {
        resetCreateNews: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getNewsCategoryList.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getNewsCategoryList.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getNewsCategoryList.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetCreateNews } = getNewsCategorySlice.actions;
export default getNewsCategorySlice.reducer;