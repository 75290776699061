import './information-panel-body.scss';

const InformationPanelBody = () => {
    const img = 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/1641995256282-buffet-2-portes-eclipse.jpeg';
    return (
        <div className="info-panel-body-main">
            <img alt="info" src={img} />
            <div className="info-panel-body-text">
                <strong>Lorem Ipsum is simply dummy</strong> text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
            </div>
            <iframe src="https://www.youtube.com/embed/pJmsX2CPwQI" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen title="video" />
            <iframe src="https://www.youtube.com/embed/H2_Tuw_CC8Q" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen title="video" />
        </div>
    );
};
export default InformationPanelBody;