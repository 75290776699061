import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getRoom, GetRoomState, resetAddRoom } from '../../../services/redux/reducers/rooms/rooms.slice';
import { updateProjectRoom, UpdateProjectRoomState } from '../../../services/redux/reducers/projects/project.slice';
import { RootState } from '../../../services/redux/root-reducers';
import { BasketProduct, Colori, MappingDTO, ModelTree, PartTree, RoomResponse, ZoneDTO } from '../../../domain/domain';
import Layout from '../../../ui-components/layout/layout';
import EditPanel from '../../panel/edit-panel/edit-panel';
import ShapePanel from '../../panel/shape-panel/shape-panel';
import AddElementPanel from '../../panel/AddElementPanel';
import AddProductPanel from '../../panel/add-product-panel/add-product-panel';
import { OpenAddElementPanel, OpenPaintPanel, PanelState } from '../../../services/redux/reducers/panel/panel.slice';
import PaintWallPanel from '../../panel/PaintPanel/WallPanel';
import PaintFloorPanel from '../../panel/PaintPanel/FloorPanel';
import PaintOpeningPanel from '../../panel/PaintPanel/PaintOpeningPanel';
import PrimitivePaintPanel from '../../panel/PaintPanel/PaintBuildingElement';
import PrimitivePanel from '../../panel/PrimitvePanel';
import validate from '../../../asset/Validation-vert.svg';
import './home-planner.scss';
import roomsService from '../../../api/service/rooms/rooms.services';
import MdfApiResponse from '../../../domain/common/generic';
import { saveBasket, saveRoom } from '../../../utils/saveRoom';
import leftArrow from '../../../asset/fleche gauche XL bleu.svg';
import { RoomStudioState, setEditedDatas } from '../../../application-3d/application3D-seller/Redux/Reducers/room-studio/RoomStudioReducer';
import { RoomContentState, setRoomToLoad } from '../../../application-3d/application3D-seller/Redux/Reducers/room-studio/RoomContentReducer';
import { editPanelClassName } from '../../../application-3d/application3D-seller/Utils/edit-panel-class';
import { ObjectParser } from '../../../application-3d/application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/ObjectParser';
import BasicObject from '../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject';
import SellerApp3D from '../../../application-3d/application3D-seller/Components/SellerApp3D/SellerApp3D';
import { onLoadingFail } from '../../../application-3d/application3D-common/Utils/loading-fail';
import ObjectDatas from '../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/ObjectDatas';
import { BasketConfigurationState, resetBasketProductConfiguration, setBasketProductConfiguration } from '../../../services/redux/reducers/basket-configuration/basket-configuration.slice';

const HomePlannerCode = () => {
    const dispatch = useDispatch();
    const { projectId, uuid } = useParams();
    const [room, setRoom] = useState<RoomResponse | undefined>(undefined);
    const [product, setProduct] = useState<any>([]);

    const [dynamicClass, setDynamicClass] = useState('HomePlanner-close');

    const [openPanel, setOpenPanel] = useState(true);
    const [primitivePanel, setPrimitivePanel] = useState(false);
    const [primitivePaintPanel, setPrimitivePaintPanel] = useState(false);
    const [openingPaintPanel, setOpeningPaintPanel] = useState(false);
    const [openPaintPanel, setOpenPaintPanel] = useState(false);
    const [openFloorPaintPanel, setOpenFloorPaintPanel] = useState(false);
    const [openEditPanel, setOpenEditPanel] = useState(false);
    const [openShapePanel, setOpenShapePanel] = useState(false);
    const [buildingElementPanel, setBuildingElementPanel] = useState(false);
    const [addProductPanel, setAddProductPanel] = useState(false);
    const [basketProducts, setBasketProducts] = useState<BasketProduct[]>([]);

    const roomResponse = useSelector<RootState, GetRoomState>((state) => state.getRoom);
    const panel = useSelector<RootState, PanelState>((state) => state.panel);
    const [toastSnap] = useState<boolean>(false);
    const saveRoomResponse = useSelector<RootState, UpdateProjectRoomState>((state) => state.updateProjectRoom);
    const { editedDatas, roomStudioApp, selectedConnector, selectedObject } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const snapshot = useSelector<RootState, RoomContentState>((state) => state.roomContent);
    const basketConfigurationState = useSelector<RootState, BasketConfigurationState>((state) => state.basketConfiguration);

    const {
        payload: { content, errors },
    } = roomResponse;

    const saveConfigurationFromModel = (roomToSave: RoomResponse) => {
        if (uuid && projectId) {
            dispatch(
                updateProjectRoom({
                    projectId: parseInt(projectId),
                    roomUuid: uuid,
                    room: { ...roomToSave.room, roomUuid: uuid },
                })
            );
        }
    };
    const saveConfiguration = () => {
        if (room) {
            if (uuid && projectId) {
                dispatch(
                    updateProjectRoom({
                        projectId: parseInt(projectId),
                        roomUuid: uuid,
                        room: { ...room.room, roomUuid: uuid },
                    })
                );
            }
        }
    };

    useEffect(() => {
        if (room && !openPanel) {
            const currentBasketBasketProducts = room.room.basketProducts;
            let found = currentBasketBasketProducts.find((el) => el.refOfInstance === basketConfigurationState.refOfInstance);
            const filteredBasketProducts = currentBasketBasketProducts.filter((el) => el.refOfInstance !== basketConfigurationState.refOfInstance);

            if (found && projectId) {
                found = {
                    ...found,
                    productContent: {
                        ...found.productContent,
                        configuration: basketConfigurationState.configuration,
                    },
                };
                // found.productContent.configuration = basketConfigurationState.configuration;
                filteredBasketProducts.push(found);
                roomStudioApp && saveBasket(room.room, parseInt(projectId), filteredBasketProducts, dispatch, basketConfigurationState.refOfInstance, roomStudioApp);
                dispatch(resetBasketProductConfiguration());
            }
        }
    }, [openPanel]);

    useEffect(() => {
        if (roomResponse && roomResponse.payload && roomResponse.payload.content && roomResponse.payload.content.room) {
            setRoom(roomResponse.payload.content);
            // setBasketProducts(roomResponse.payload.content.room.basketProducts);
        }
    }, [roomResponse]);

    useEffect(() => {
        if (basketConfigurationState.refOfInstance.length > 0) {
            if (room) {
                room.room.basketProducts.forEach((basketProduct) => {
                    if (basketProduct.refOfInstance === basketConfigurationState.refOfInstance) {
                        dispatch(setBasketProductConfiguration(basketProduct.productContent.configuration));
                    }
                });
            }
        }
    }, [basketConfigurationState.refOfInstance]);

    // TODO remove => Pour Joza:  l'ajout de module à une composition ----------------------------------------
    useEffect(() => {
        if (editedDatas && editedDatas.FirstRootObject) {
            console.log('L objet en cours d édition est ', editedDatas.FirstRootObject);
        }
    }, [editedDatas]);

    useEffect(() => {
        if (selectedObject) {
            console.log('L objet sélectionné est ', selectedObject);
        }
    }, [selectedObject]);

    useEffect(() => {
        if (selectedConnector) {
            console.log('Le connecteur sélectionné est ', selectedConnector);
        }
    }, [selectedConnector]);
    // END TODO remove => Pour Joza:  l'ajout de module à une composition ----------------------------------------

    useEffect(() => {
        if (uuid !== undefined && projectId) {
            roomsService.getRoom(parseInt(projectId), uuid).then((res: MdfApiResponse<any>) => {
                setBasketProducts(res.content.room.basketProducts);
                let room2 = Object.assign(new ObjectDatas(), res.content.room.metadata.data);
                dispatch(setRoomToLoad(room2));
                dispatch(resetAddRoom());
            });
        }
    }, [uuid]);
    useEffect(() => {
        uuid && projectId && dispatch(getRoom({ projectId: parseInt(projectId), roomUuid: uuid }));
    }, [saveRoomResponse]);

    useEffect(() => {
        !saveRoomResponse.isLoading && uuid && projectId && saveRoom(uuid, projectId, roomResponse.payload.content, basketProducts, snapshot, dispatch);
    }, [basketProducts]);

    useEffect(() => {
        !saveRoomResponse.isLoading && uuid && projectId && saveRoom(uuid, projectId, roomResponse.payload.content, basketProducts, snapshot, dispatch);
    }, [snapshot]);
    useEffect(() => {
        panel.openAddElementPanel ? setBuildingElementPanel(true) : setBuildingElementPanel(false);
        setPrimitivePanel(panel.primitivePanel);
        setOpenPaintPanel(panel.paintPanel);
        setOpenFloorPaintPanel(panel.floorPanel);
        setPrimitivePaintPanel(panel.primitivePaintPanel);
        setOpeningPaintPanel(panel.openingPaintPanel);
        setBuildingElementPanel(panel.openAddElementPanel);
        setAddProductPanel(panel.addProductPanel);
    }, [panel]);

    useEffect(() => {
        buildingElementPanel ? dispatch(OpenAddElementPanel(true)) : dispatch(OpenAddElementPanel(false));
        !primitivePanel && setOpenPanel(false);
        !openPaintPanel && setOpenPaintPanel(false);
        !openEditPanel && setOpenPanel(false);
        closeAllPanel();
        editPanelClassName({
            setDynamicClass: setDynamicClass,
            openFloorPanel: openFloorPaintPanel,
            openPaintPanel: openPaintPanel,
            primitivePaintPanel: primitivePaintPanel,
            openingPaintPanel: openingPaintPanel,
            primitivePanel: primitivePanel,
            openPanel: openPanel,
            openEditPanel: openEditPanel,
            openShapePanel: openShapePanel,
            buildingElementPanel: buildingElementPanel,
            addProductPanel: addProductPanel,
        });
    }, [openPanel, openEditPanel, primitivePanel, primitivePaintPanel, buildingElementPanel, addProductPanel, editedDatas, openPaintPanel, openFloorPaintPanel, openingPaintPanel]);

    const handleAssetUpdate = (atom: ModelTree) => {
        console.log('atomToAdd2', atom);
        if (editedDatas !== undefined && editedDatas.FirstRootObject !== undefined && content !== undefined) {
            const productUuid = editedDatas.FirstRootObject.RefOfInstance;

            content.room.metadata.data.plannerObjects.map((plannerObject: any) => {
                if (plannerObject.refOfInstance === productUuid) {
                    const partElements = plannerObject.composition.partElements.map((asset: any) => {
                        if (atom.atom.partReference === asset.refOfPart && asset.type === 'Model3d') {
                            asset = {
                                dataModelVersion: asset.dataModelVersion,
                                datas: {
                                    urls: atom.model3d.map((el) => el.modelUrl),
                                    hooks: atom.model3d[0].anchorPoints.length === 0 ? undefined : atom.model3d[0].anchorPoints,
                                },
                                options: asset.options,
                                refOfPartItem: atom.atom.partItemReference,
                                refOfPart: asset.refOfPart,
                                type: asset.type,
                            };
                        }
                        return asset;
                    });
                    let table = ObjectParser.DeepParseObject(
                        {
                            ...plannerObject,
                            composition: { ...plannerObject.composition, partElements },
                        },
                        BasicObject
                    );
                    dispatch(setEditedDatas(ObjectDatas.GetObjectDatasForObject(table)));

                    return { ...plannerObject, composition: { ...plannerObject.composition, partElements } };
                }
                return plannerObject;
            });
        }
    };

    const onMappingClick = (sectionSelected: PartTree, mappingSelected: MappingDTO) => {
        if (editedDatas !== undefined && editedDatas.FirstRootObject !== undefined && content !== undefined) {
            const productUuid = editedDatas.FirstRootObject.RefOfInstance;
            content.room.metadata.data.plannerObjects.map((plannerObject: any) => {
                if (plannerObject.refOfInstance === productUuid) {
                    let partElements;
                    if (plannerObject.composition.partElements.find((asset: any) => asset.type === 'MaterialAssignment' && asset.refOfPart === sectionSelected.reference) === undefined) {
                        partElements = [
                            ...plannerObject.composition.partElements,
                            {
                                dataModelVersion: '1.0.0',
                                datas: mappingSelected.zoneList.map((el) => {
                                    const zone = {
                                        dataModelVersion: '1.0.0',
                                        refOfZoning: el.uuid,
                                        urls: [el.defaultColori !== null && el.defaultColori.modelUrl],
                                        refs: [...el.availableIds],
                                    };
                                    return zone;
                                }),
                                refOfMapping: mappingSelected.id,
                                options: [],
                                refOfPart: sectionSelected.reference,
                                type: 'MaterialAssignment',
                            },
                        ];
                    } else {
                        console.log('mappingSelected', mappingSelected);
                        partElements = plannerObject.composition.partElements.map((asset: any) => {
                            if (asset.refOfPart === sectionSelected.reference && asset.type === 'MaterialAssignment') {
                                asset = {
                                    dataModelVersion: asset.dataModelVersion,
                                    datas: mappingSelected.zoneList.map((el) => {
                                        const zone = {
                                            dataModelVersion: '1.0.0',
                                            refOfZoning: el.uuid,
                                            urls: [el.defaultColori !== null && el.defaultColori.modelUrl],
                                            refs: [...el.availableIds],
                                        };
                                        return zone;
                                    }),
                                    refOfMapping: mappingSelected.id,
                                    options: asset.options,
                                    refOfPart: asset.refOfPart,
                                    type: asset.type,
                                };
                            }
                            return asset;
                        });
                    }
                    let table = ObjectParser.DeepParseObject(
                        {
                            ...plannerObject,
                            composition: { ...plannerObject.composition, partElements },
                        },
                        BasicObject
                    );
                    console.log('table ==>', table);
                    dispatch(setEditedDatas(ObjectDatas.GetObjectDatasForObject(table)));
                    return { ...plannerObject, composition: { ...plannerObject.composition, partElements } };
                }
                return plannerObject;
            });
        }
    };

    const onZoneClick = (sectionSelected: PartTree, m: MappingDTO, z: ZoneDTO) => {
        if (editedDatas !== undefined && editedDatas.FirstRootObject !== undefined && content !== undefined) {
            const productUuid = editedDatas.FirstRootObject.RefOfInstance;
            content.room.metadata.data.plannerObjects.map((plannerObject: any) => {
                if (plannerObject.refOfInstance === productUuid) {
                    const partElements = plannerObject.composition.partElements.map((asset: any) => {
                        if (asset.refOfPart === sectionSelected.reference && asset.type === 'MaterialAssignment' && asset.refOfMapping === m.id) {
                            const zoningFound = asset.datas.find((el: any) => el.refOfZoning === z.uuid);
                            if (!zoningFound) {
                                asset = {
                                    dataModelVersion: asset.dataModelVersion,
                                    datas: [
                                        ...asset.datas,
                                        {
                                            dataModelVersion: '1.0.0',
                                            refOfZoning: z.uuid,
                                            urls: [z.defaultColori.modelUrl],
                                            refs: [...z.availableIds],
                                        },
                                    ],
                                    refOfMapping: asset.refOfMapping,
                                    options: asset.options,
                                    refOfPart: asset.refOfPart,
                                    type: asset.type,
                                };
                            } else {
                                asset = {
                                    dataModelVersion: asset.dataModelVersion,
                                    datas: asset.datas.map((zone: any) => {
                                        if (zone.refOfZoning === z.uuid) {
                                            return {
                                                ...zone,
                                                urls: [z.defaultColori.modelUrl],
                                            };
                                        }
                                        return zone;
                                    }),
                                    refOfMapping: asset.refOfMapping,
                                    options: asset.options,
                                    refOfPart: asset.refOfPart,
                                    type: asset.type,
                                };
                            }
                        }
                        return asset;
                    });
                    let table = ObjectParser.DeepParseObject(
                        {
                            ...plannerObject,
                            composition: { ...plannerObject.composition, partElements },
                        },
                        BasicObject
                    );
                    dispatch(setEditedDatas(ObjectDatas.GetObjectDatasForObject(table)));
                    return { ...plannerObject, composition: { ...plannerObject.composition, partElements } };
                }
                return plannerObject;
            });
        }
    };

    const onColoriClick = (sectionSelected: PartTree, m: MappingDTO, z: ZoneDTO, c: Colori) => {
        if (editedDatas !== undefined && editedDatas.FirstRootObject !== undefined && content !== undefined) {
            const productUuid = editedDatas.FirstRootObject.RefOfInstance;
            content.room.metadata.data.plannerObjects.map((plannerObject: any) => {
                if (plannerObject.refOfInstance === productUuid) {
                    const partElements = plannerObject.composition.partElements.map((asset: any) => {
                        if (asset.refOfPart === sectionSelected.reference && asset.type === 'MaterialAssignment' && asset.refOfMapping === m.id) {
                            asset = {
                                dataModelVersion: asset.dataModelVersion,
                                datas: asset.datas.map((zone: any) => {
                                    if (zone.refOfZoning === z.uuid) {
                                        return {
                                            ...zone,
                                            urls: [c.modelUrl],
                                        };
                                    }
                                    return zone;
                                }),
                                refOfMapping: asset.refOfMapping,
                                options: asset.options,
                                refOfPart: asset.refOfPart,
                                type: asset.type,
                            };
                        }
                        return asset;
                    });
                    let table = ObjectParser.DeepParseObject(
                        {
                            ...plannerObject,
                            composition: { ...plannerObject.composition, partElements },
                        },
                        BasicObject
                    );
                    dispatch(setEditedDatas(ObjectDatas.GetObjectDatasForObject(table)));
                    return { ...plannerObject, composition: { ...plannerObject.composition, partElements } };
                }
                return plannerObject;
            });
        }
    };

    const closeAllPanel = () => {
        if (primitivePanel) {
            setOpenPanel(true);
            setOpenEditPanel(false);
            setOpenShapePanel(false);
            setBuildingElementPanel(false);
            setAddProductPanel(false);
            setPrimitivePaintPanel(false);
        }
        if (addProductPanel) {
            setOpenPanel(true);
            setOpenEditPanel(false);
            setOpenShapePanel(false);
            setBuildingElementPanel(false);
            setPrimitivePanel(false);
            setOpenPaintPanel(false);
            setPrimitivePaintPanel(false);
        }
        if (openShapePanel) {
            setOpenPanel(true);
            setOpenEditPanel(false);
            setAddProductPanel(false);
            setBuildingElementPanel(false);
            setPrimitivePanel(false);
            setOpenPaintPanel(false);
        }
        if (openEditPanel) {
            setOpenPanel(true);
            setAddProductPanel(false);
            setOpenShapePanel(false);
            setBuildingElementPanel(false);
            setAddProductPanel(false);
            setOpenPaintPanel(false);
            setPrimitivePaintPanel(false);
        }
        if (buildingElementPanel) {
            setOpenPanel(true);
            setOpenEditPanel(false);
            setOpenShapePanel(false);
            setOpenEditPanel(false);
            setAddProductPanel(false);
            setOpenPaintPanel(false);
        }
        if (openPaintPanel) {
            setOpenPanel(true);
            setOpenEditPanel(false);
            setOpenShapePanel(false);
            setOpenEditPanel(false);
            setAddProductPanel(false);
            setBuildingElementPanel(false);
            setPrimitivePaintPanel(false);
        }
        if (primitivePaintPanel) {
            setPrimitivePaintPanel(true);
            setOpenPanel(false);
            setOpenEditPanel(false);
            setOpenShapePanel(false);
            setOpenEditPanel(false);
            setAddProductPanel(false);
            setBuildingElementPanel(false);
        }
        if (openingPaintPanel) {
            setOpeningPaintPanel(true);
            setPrimitivePaintPanel(false);
            setOpenPanel(false);
            setOpenEditPanel(false);
            setOpenShapePanel(false);
            setOpenEditPanel(false);
            setAddProductPanel(false);
            setBuildingElementPanel(false);
        }
        if (editedDatas === undefined && primitivePanel) {
            setOpenPanel(false);
            setOpenEditPanel(false);
            setOpenShapePanel(false);
            setPrimitivePanel(false);
            setAddProductPanel(false);
            setBuildingElementPanel(false);
            setPrimitivePaintPanel(false);
            setOpeningPaintPanel(false);
        }
        if (editedDatas === undefined) {
            /*TODO HOTFIX IS ON */
            dispatch(OpenPaintPanel(false));
            /*ENDOF HOTFIX*/
            setOpenPaintPanel(false);
        }
    };
    const rightBarDisable = () => {
        if (!openPanel && openEditPanel) {
            return false;
        }
        if (addProductPanel) {
            return false;
        }
        if (openEditPanel) {
            return false;
        }
        if (buildingElementPanel) {
            return false;
        }
        if (primitivePanel) {
            return false;
        }
        if (openShapePanel) {
            return false;
        }
        if (openPaintPanel) {
            return false;
        }
        if (openFloorPaintPanel) {
            return false;
        }
        if (primitivePaintPanel) {
            return false;
        }
        if (openingPaintPanel) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <Layout navbar={true}>
            {toastSnap && (
                <div className="toast-snap">
                    <img alt="validation" src={validate} />
                    <span>Screenshot sauvegardé</span>
                </div>
            )}

            <div className={dynamicClass}>
                <SellerApp3D
                    openPanel={openPanel}
                    openPrimitivePanel={primitivePanel}
                    setOpenPrimitivePanel={setPrimitivePanel}
                    setAddProductPanel={setAddProductPanel}
                    setBuildingElementPanel={setBuildingElementPanel}
                    setOpenPanel={setOpenPanel}
                    setOpenEditPanel={setOpenEditPanel}
                    setOpenShapePanel={setOpenShapePanel}
                    disable={rightBarDisable()}
                    sizeStateOf3DView={dynamicClass}
                    onLoadingFail={onLoadingFail}
                />

                <PrimitivePaintPanel open={primitivePaintPanel} />

                <PaintWallPanel open={openPaintPanel} />

                <PaintFloorPanel open={openFloorPaintPanel} />

                <PaintOpeningPanel open={openingPaintPanel} />

                <PrimitivePanel open={primitivePanel} setOpenPrimitivePanel={setPrimitivePanel} openPanel={openPanel} setOpenPanel={setOpenPanel} />

                {buildingElementPanel && <AddElementPanel open={buildingElementPanel} setOpenPanel={setOpenPanel} setOpenAddElementPanel={setBuildingElementPanel} />}

                {openShapePanel && <ShapePanel setOpenPanel={setOpenPanel} setOpenShapePanel={setOpenShapePanel} />}

                {/*{ primitivePanel &&*/}
                {/*    <PrimitivePanel openPrimitivePanel={ primitivePanel } setOpenPrimitivePanel={ setPrimitivePanel }*/}
                {/*                    openPanel={ openPanel } setOpenPanel={ setOpenPanel } /> }*/}

                {openEditPanel && editedDatas && editedDatas.FirstRootObject && product && room && basketProducts.length > 0 && (
                    <EditPanel
                        basketProduct={room.room.basketProducts.find((el) => el.refOfInstance === editedDatas.FirstRootObject!.RefOfInstance)}
                        setConfigurationWithAtom={() => {}}
                        setProduct={setProduct}
                        openPanel={openPanel}
                        setOpenPanel={setOpenPanel}
                        openEditPanel={openEditPanel}
                        setOpenEditPanel={setOpenEditPanel}
                        handleAssetUpdate={handleAssetUpdate}
                        product={product}
                        saveConfiguration={saveConfiguration}
                        onColoriClick={(sectionSelected, m, z, C) => {
                            onColoriClick(sectionSelected, m, z, C);
                        }}
                        onMappingClick={onMappingClick}
                        onZoneClick={onZoneClick}
                    />
                )}

                {editedDatas && !openEditPanel ? (
                    <div
                        className="panel-arrow-2"
                        onClick={() => {
                            setOpenEditPanel(true);
                        }}
                    >
                        {!openEditPanel && <img alt="fleche gauche" src={leftArrow} />}
                    </div>
                ) : (
                    <div></div>
                )}

                {panel.addProductPanel && (
                    <AddProductPanel
                        setOpenPanel={setOpenPanel}
                        room={room}
                        setRoom={setRoom}
                        basketProducts={basketProducts}
                        setBasketProducts={setBasketProducts}
                        saveConfigurationFromModel={saveConfigurationFromModel}
                    />
                )}
            </div>
        </Layout>
    );
};

export default HomePlannerCode;
