import { IEvent } from "@lutithree/build/Modules/Core/Event/IEvent";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { Vector3 } from "three";

export default class ConnectionsTranslatedEvent implements IEvent {

    private readonly m_entities: SceneEntity[];

    private readonly m_worldTranslation: Vector3;

    public constructor(p_entities:SceneEntity[], p_worldTranslation: Vector3) {
        if (p_entities == null) throw new Error('NullReferenceException : p_entities is null or undefined');
        if (p_worldTranslation == null) throw new Error('NullReferenceException : p_worldTranslation is null or undefined');
        
        this.m_entities = p_entities;
        this.m_worldTranslation = p_worldTranslation;
    }

    public get ConnectedEntities(): SceneEntity[] {
        return this.m_entities;
    }

    public get WorldTranslation(): Vector3 {
        return this.m_worldTranslation;
    }
}