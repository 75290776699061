import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MdfApiResponse from '../../../../domain/common/generic';
import { Section } from '../../../../domain/domain';
import sectionService from '../../../../api/service/catalog/section.service';

//#region state
export type SectionState = {
    isLoading: boolean;
    success: boolean;
    payload: MdfApiResponse<Section>;
};
export type SectionListState = {
    isLoading: boolean;
    payload: MdfApiResponse<Section[]>;
};
const initialSectionState: SectionState = {
    isLoading: false,
    success: false,
    payload: {
        content: {
            reference: '',
            name: '',
            logo: '',
            id: 0,
            version: 0,
            catalogId: 0,
        },
        errors: [],
        warnings: [],
    },
};
const initialSectionListState: SectionListState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};
//#endregion

//#region get All Section
export const getAllSections = createAsyncThunk('get/sections', async () => {
    const res = await sectionService.getAllSection();
    return res;
});

export const getAllSectionsSlice = createSlice({
    name: 'getAllSections',
    initialState: initialSectionListState,
    reducers: {
        resetGetAllSection: (state) => {
            state = initialSectionListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllSections.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getAllSections.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getAllSections.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetGetAllSection } = getAllSectionsSlice.actions;
//#endregion

//#region get All Section by catalogId
export const getAllSectionsByCatalogId = createAsyncThunk('get/sectionsByCatalogId', async (catalogId: number) => {
    const res = await sectionService.getAllSectionsByCatalogId(catalogId);
    return res;
});

export const getAllSectionsByCatalogIdSlice = createSlice({
    name: 'getAllSectionsByCatalogId',
    initialState: initialSectionListState,
    reducers: {
        resetGetAllSectionByCatalogId: (state) => {
            state = initialSectionListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllSectionsByCatalogId.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getAllSectionsByCatalogId.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getAllSectionsByCatalogId.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetGetAllSectionByCatalogId } = getAllSectionsByCatalogIdSlice.actions;
//#endregion

//#region get Section By ID
export const getSectionById = createAsyncThunk('get/section/byId', async (sectionId: number) => {
    const res = await sectionService.getSectionById(sectionId);
    return res;
});

export const getSectionByIdSlice = createSlice({
    name: 'getSectionById',
    initialState: initialSectionState,
    reducers: {
        resetGetSectionById: (state) => {
            state = initialSectionState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSectionById.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getSectionById.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false, success: true };
            return state;
        });
        builder.addCase(getSectionById.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetSectionById } = getSectionByIdSlice.actions;
//#endregion

//#region Create Section
export const createSection = createAsyncThunk('create/section', async (data: { section: Section; catalogId: number }) => {
    const res = await sectionService.createSection(data.section, data.catalogId);
    return res;
});

export const createSectionSlice = createSlice({
    name: 'createSection',
    initialState: initialSectionState,
    reducers: {
        resetCreateSection: (state) => {
            state = initialSectionState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createSection.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createSection.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false, success: true };
            return state;
        });
        builder.addCase(createSection.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetCreateSection } = createSectionSlice.actions;
//#endregion

//#region update Section
export const updateSection = createAsyncThunk('update/section', async (data: { sectionId: number; section: Section }) => {
    const res = await sectionService.updateSection(data.sectionId, data.section);
    return res;
});

export const updateSectionsSlice = createSlice({
    name: 'updateSection',
    initialState: initialSectionState,
    reducers: {
        resetUpdateSection: (state) => {
            state = initialSectionState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateSection.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateSection.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false, success: true };
            return state;
        });
        builder.addCase(updateSection.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetUpdateSection } = updateSectionsSlice.actions;

//#endregion

//#region delete Section
export const deleteSections = createAsyncThunk('delete/section', async (sectionId: number) => {
    const res = await sectionService.deleteSection(sectionId);
    return res;
});

export const deleteSectionSlice = createSlice({
    name: 'deleteSections',
    initialState: initialSectionState,
    reducers: {
        resetDeleteSection: (state) => {
            state = initialSectionState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteSections.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteSections.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false, success: true };
            return state;
        });
        builder.addCase(deleteSections.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDeleteSection } = deleteSectionSlice.actions;

//#endregion
