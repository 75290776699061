export default class AssignmentData {
    private dataModelVersion?: string;

    private urls: string[];

    private refs: string[];

    public constructor() {
        this.dataModelVersion = '';
        this.refs = [];
        this.urls = [];
    }
    
    public get Urls(): string[] {
        return this.urls;
    }

    public get Refs(): string[] {
        return this.refs;
    }

    public LoadData(ref: string[], urls: string[]): AssignmentData {
        this.dataModelVersion = ''; // TODO: fill this field
        this.refs = ref;
        this.urls = urls;
        return this;
    }

    public set Refs(p_refs: string[]) {
        this.refs = p_refs;
    }
    
    public set Urls(p_urls: string[]) {
        this.urls = p_urls;
    }

    public static IsEquals(p_assignmentData1: AssignmentData, p_assignmentData2: AssignmentData): boolean {
        if (p_assignmentData1 == null) throw new Error('NullReferenceException : p_assignmentData1 is null or undefined');
        if (p_assignmentData2 == null) throw new Error('NullReferenceException : p_assignmentData2 is null or undefined');

        //compare urls
        if (p_assignmentData1.urls.length !== p_assignmentData2.urls.length) return false;
        for (let i: number = 0; i < p_assignmentData1.urls.length; i++) {
            if (p_assignmentData1.urls[i] !== p_assignmentData2.urls[i]) return false;
        }

        //compare refs
        if (p_assignmentData1.refs.length !== p_assignmentData2.refs.length) return false;
        for (let i: number = 0; i < p_assignmentData1.refs.length; i++) {
            if (p_assignmentData1.refs[i] !== p_assignmentData2.refs[i]) return false;
        }

        return true;
    }

    public static IsAssignmentData(data: AssignmentData | string): data is AssignmentData {
        let assignmentData = data as AssignmentData;
        let hasUrl = assignmentData.urls !== undefined;
        let hasRef = assignmentData.refs !== undefined;

        return hasUrl && hasRef;
    }
}
