import * as React from 'react';
import coting from '../../../../../Assets/Icons/Planner_revetementdusol.svg';
import { useDispatch } from 'react-redux';
import { styled, Box, Typography } from '@mui/material';
import PaperLayout from "../../../../../../application3D-common/Components/UI/Composites/PaperLayout";
import ButtonToggleRoom from "../../../../../../application3D-common/Components/UI/Composites/ButtonToggleRoom";
import {OpenFloorPaintPanel} from "../../../../../../../services/redux/reducers/panel/panel.slice";


const EditFloor = () => {
    const dispatch = useDispatch();

    return (
        <>
            <PaperLayout flexDirection='row' elevation={ 2 } mt={ 1 }>
                <StyledBoxButtonGroup
                    aria-label='text alignment'
                >
                    <ButtonToggleRoom
                        altLogo='revetement floor'
                        logo={ coting }
                        actions={ () => dispatch(OpenFloorPaintPanel(true)) }
                        text={
                            <Typography variant='subtitle2' color='primary'>
                                Revêtement<br />du sol
                            </Typography>
                        }
                    />
                </StyledBoxButtonGroup>
            </PaperLayout>
        </>
    );
};

const StyledBoxButtonGroup = styled(Box)(({ theme }) => ({
    margin: theme.spacing(0.5),
    height: 80,
    minWidth: 80,
    '& .Mui-disabled': {
        border: 0,
    },
    '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
    },
}));

export default EditFloor;
