type EditPanelArg = {
    openPanel: boolean
    openEditPanel: boolean
    openShapePanel: boolean
    buildingElementPanel: boolean
    addProductPanel: boolean
    primitivePanel: boolean
    openPaintPanel: boolean
    openFloorPanel: boolean
    primitivePaintPanel: boolean
    openingPaintPanel: boolean
    setDynamicClass: (dynamicClass: string) => void
};
export const editPanelClassName = (props: EditPanelArg) => {
    if (props.openEditPanel) {
        props.setDynamicClass('HomePlanner');
    }
    if (!props.openPanel && props.openEditPanel) {
        props.setDynamicClass('HomePlanner-edit-close');
    }
    if (props.openShapePanel) {
        props.setDynamicClass('HomePlanner-shape');
    }
    if (props.buildingElementPanel) {
        props.setDynamicClass('HomePlanner-element');
    }
    if (props.addProductPanel) {
        props.setDynamicClass('HomePlanner-addProduct');
    }
    if (props.primitivePanel) {
        props.setDynamicClass('HomePlanner-primitive');
    }
    if (props.openPaintPanel || props.primitivePaintPanel || props.openingPaintPanel) {
        props.setDynamicClass('HomePlanner-paint');
    }
    if (props.openFloorPanel) {
        props.setDynamicClass('HomePlanner-floorPaint');
    }
    if (!props.openPanel
        && !props.openEditPanel
        && !props.openShapePanel
        && !props.buildingElementPanel
        && !props.addProductPanel
        && !props.primitivePanel
        && !props.openPaintPanel
        && !props.openFloorPanel
        && !props.primitivePaintPanel
        && !props.openingPaintPanel
    ) {
        props.setDynamicClass('HomePlanner-close');
    }
};