import { ModelTree } from '../domain/domain';

type ZoneColoris = { zoneName: string; defaultColoris: string };

export type Conf = {
    partName: string;
    partReference: string;
    itemAtom: { partItemName: string; atomName: string }[];
    zoneColorisList: ZoneColoris[];
    mappingName: string;
};
export const buildPresetConf = (modelTreeList: ModelTree[]): Conf[] => {
    const result: Conf[] = [];
    modelTreeList.forEach((modelTree, i) => {
        const isAlreadyInResultIndex = result.findIndex(el => el.partReference === modelTree.atom.partReference);
        if (isAlreadyInResultIndex < 0) {
            result.push({
                partName: modelTree.atom.partName,
                partReference: modelTree.atom.partReference,
                itemAtom: [
                    {
                        partItemName: modelTree.atom.partItemName,
                        atomName: modelTree.atom.label,
                    },
                ],
                mappingName: modelTree.model3d.length > 0 && modelTree.model3d[0].mappings.length > 0 ? modelTree.model3d[0].mappings[0].name : 'Pas d\'habillage',
                zoneColorisList: modelTree.model3d.length === 0 || modelTree.model3d[0].mappings.length === 0
                    ? [{ zoneName: 'Pas de zone', defaultColoris: 'Pas de coloris' }]
                    : modelTree.model3d[0].mappings[0].zoneList.map((z) => {
                        return {
                            zoneName: z.name,
                            defaultColoris: z.defaultColori ? z.defaultColori.name : 'Aucun',
                        };
                    }),
            });
        } else {
            result[isAlreadyInResultIndex].itemAtom.push({
                partItemName: modelTree.atom.partItemName,
                atomName: modelTree.atom.label,
            });
        }
    });
    return result;
};