import { useState } from 'react';

import infoOn from '../../../../Assets/Icons/ContextualMenu/Planner_detailsmenucontextuel-on.svg';
import infoOff from '../../../../Assets/Icons/ContextualMenu/Planner_detailsmenucontextuel-off.svg';
import ToogleIcon from '../../Basics/ToogleIcon/ToogleIcon';


const InfoToogle = () => {
    const [isOn, setIsOn] = useState(false);

    const onChange = () => {
        setIsOn(!isOn);
    };

    return <ToogleIcon
        imgOn={ infoOn }
        imgOff={ infoOff }
        label='Info'
        active={ isOn }
        onChange={ onChange } />;
};

export default InfoToogle;
