const CREATE_PROJECT = '/v1/projects/create/';
const IMPORT_PROJECT = (userId: number, code: string) => `/v1/projects/import-from-code/u/${userId}/c/${code}`;

const GET_PROJECTS = '/v1/projects/';
const GET_USER_PROJECTS = (userId: number) => `/v1/projects/${userId}`;
const GET_PROJECT_BY_CODE = (code: string | undefined) => `/v1/projects/code/${code}`;

const UPDATE_USER_PROJECTS = (projectId: number) => `/v1/projects/${projectId}/configuration`;
const UPDATE_PROJECT_NAME = (projectId: number | undefined, projectName: string) => `/v1/projects/${projectId}/name/${projectName}`;
const UPDATE_PROJECT_ROOM = (projectId: number, roomUuid: string) => `/v1/projects/${projectId}/r/${roomUuid}/save-product`;
const UPDATE_PROJECT_METADATA = (projectId: number, roomUuid: string) => `/v1/projects/${projectId}/r/${roomUuid}/save-metadata`;

const DELETE_PROJECT = (projectId: number, userId: number) => `/v1/projects/${projectId}/u/${userId}/delete`;

export {
    CREATE_PROJECT,
    IMPORT_PROJECT,
    GET_PROJECTS,
    GET_USER_PROJECTS,
    GET_PROJECT_BY_CODE,
    UPDATE_USER_PROJECTS,
    UPDATE_PROJECT_NAME,
    DELETE_PROJECT,
    UPDATE_PROJECT_ROOM,
    UPDATE_PROJECT_METADATA,
};
