import { DisableComponent } from "@lutithree/build/Modules/Core/Entity/DisableComponent";
import { IDisposableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/IDisposableComponent";
import { DoubleSide, Group, Object3D, Vector3 } from "three";
import { Line } from "@lutithree/build/Modules/WebGL/Primitives/Graphics/Line";
import { StickerBillboard } from "@lutithree/build/Modules/WebGL/Primitives/Graphics/StickerBillboard";
import { IObjectComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/IObjectComponent";

export class MeasureDrawerComponent extends DisableComponent implements IDisposableComponent, IObjectComponent<Object3D> {

    private readonly m_measureDrawerObject: Object3D;
    
    private m_nbPointsMax: number|undefined;
    
    private m_markerObjects: Object3D[];

    private m_lines: Line[];

    private m_stickers: StickerBillboard[];
    
    private m_cleanResources : (p_model: Group|Object3D) => void;
    
    private m_color: string = '#1D2740';
    
    public constructor(p_nbPointsMax: number|undefined, p_cleanResourcesCallback: (p_model: Group|Object3D) => void) {
        super();
        if (p_cleanResourcesCallback == null) throw new Error('NullReferenceException : p_cleanResourcesCallback is null or undefined');
        if (p_nbPointsMax === null) throw new Error('NullReferenceException : p_nbPointsMax is null or undefined');
        
        this.m_measureDrawerObject = new Group();
        this.m_measureDrawerObject.layers.set(4);
        this.m_nbPointsMax = p_nbPointsMax;
        this.m_markerObjects = [];
        this.m_lines = [];
        this.m_stickers = [];

        this.m_cleanResources = p_cleanResourcesCallback;
    }
    
    public get NBPointsMax(): number|undefined {
        return this.m_nbPointsMax;
    } 
    
    public get Stickers(): StickerBillboard[]{
        return this.m_stickers;
    }
    
    public AddMarker(p_object: Object3D): void {
        if (p_object == null) throw new Error('NullReferenceException : p_object is null or undefined');
        
        if(this.m_nbPointsMax && this.m_markerObjects.length+1>this.m_nbPointsMax) this.Clear();
       
        // add marker
        this.m_markerObjects.push(p_object);

        let size = this.m_markerObjects.length;
        p_object.name = p_object+'_'+(size-1);
        if(size>1){

            let from = this.m_markerObjects[size-2].position;
            let to = this.m_markerObjects[size-1].position;
            
            // add line
            let newLine = new Line(from, to, this.m_color);
            newLine.ThreeLine.name = 'Line_'+(size-1)+'_to_'+size;
            newLine.ThreeLine.layers.set(4);
            if(!Array.isArray(newLine.ThreeLine.material)) newLine.ThreeLine.material.side = DoubleSide;
            this.m_lines.push(newLine);

            // add sticker
            let sticker = new StickerBillboard("0", this.m_color, from, to);
            let lenght = from.distanceTo(to);
            sticker.UpdateText(Math.floor(lenght * 100).toString() + " cm");
            sticker.m_stickerObject.name = 'Sticker_'+(size-1)+'_to_'+size;
            sticker.MoveSticker(
                to.clone().sub(from).normalize().multiplyScalar(lenght/2).add(from), 
                new Vector3()
            );
            sticker.m_stickerObject.visible = true;
            this.m_stickers.push(sticker);

            this.m_measureDrawerObject.add(newLine.ThreeLine);
            this.m_measureDrawerObject.add(sticker.m_stickerObject);
        }
    }
    
    public UpdateMeasureWithMarker(p_markerObject: Object3D): void {
        if (p_markerObject == null) throw new Error('NullReferenceException : p_object is null or undefined');
        
        let markerIndex = this.m_markerObjects.indexOf(p_markerObject);
        
        if(markerIndex>=0){
            if(markerIndex-1 >= 0){
                let from = this.m_markerObjects[markerIndex-1].position;
                let to = p_markerObject.position;
                let lenght = from.distanceTo(to);
                this.m_lines[markerIndex-1].Geometry.setPoints([from, to], null);
                this.m_stickers[markerIndex-1].MoveSticker(to.clone().sub(from).normalize().multiplyScalar(lenght/2).add(from), new Vector3());
                this.m_stickers[markerIndex-1].UpdateText(Math.floor(lenght * 100).toString() + " cm");
            }
            if(markerIndex+1 < this.m_markerObjects.length){
                let from = p_markerObject.position;
                let to = this.m_markerObjects[markerIndex+1].position;
                let lenght = from.distanceTo(to);
                this.m_lines[markerIndex].Geometry.setPoints([from, to], null);
                this.m_stickers[markerIndex].MoveSticker(to.clone().sub(from).normalize().multiplyScalar(lenght/2).add(from), new Vector3());
                this.m_stickers[markerIndex].UpdateText(Math.floor(lenght * 100).toString() + " cm");
            }
        }
        else{
            throw new Error('p_markerObject is not referenced into MeasureDrawerComponent.');
        }
    }

    public Clear(): void {
        let itemToRemove: Object3D[] = [];
        this.m_measureDrawerObject.children.forEach((child)=>{
            itemToRemove.push(child);
        });

        itemToRemove.forEach((item)=>{
            this.m_measureDrawerObject.remove(item);
            this.m_cleanResources(item);
        });
        
        this.m_markerObjects.Clear();
        this.m_stickers.Clear();
        this.m_lines.Clear();
    }
    
    public GetDisposable(): Object3D[] {
        return [this.m_measureDrawerObject];
    }

    public GetObject() : Object3D {
        return this.m_measureDrawerObject;
    }
}