import * as React from 'react';
import { useDispatch } from 'react-redux';
import element from '../../../../../Assets/Icons/Planner_ajoutelementdeconstruction.svg';
import addProduct from '../../../../../Assets/Icons/Planner_ajoutproduitmdf.svg';
import accessory from '../../../../../Assets/Icons/Planner_ajoutaccessoires-disabled.svg';
import { Typography, styled, Box } from '@mui/material';
import PaperLayout from "../../../../../../application3D-common/Components/UI/Composites/PaperLayout";
import ButtonToggleRoom from '../../../../../../application3D-common/Components/UI/Composites/ButtonToggleRoom';
import {
    OpenAddElementPanel,
    OpenAddProductPanel,
    OpenFloorPaintPanel
} from "../../../../../../../services/redux/reducers/panel/panel.slice";


type AddProductProps = {
    setBuildingElementPanel: (buildingElementPanel: boolean) => void;
    setOpenPanel: (OpenPanel: boolean) => void;
};

const AddProduct = (props: AddProductProps) => {
    const dispatch = useDispatch();

    return (
        <>
            <PaperLayout flexDirection='column' elevation={ 2 }>
                <StyledTriangle />
                <StyledBoxButtonGroup
                    aria-label='text alignment'
                >
                    <ButtonToggleRoom
                        text={
                            <Typography variant='subtitle2' color='primary'>
                                Produits<br />Mobilier de<br />France
                            </Typography> }
                        actions={ () => {
                            dispatch(OpenAddProductPanel(true));
                            props.setOpenPanel(true);
                        } } logo={ addProduct } altLogo='produit mdf'
                    />
                </StyledBoxButtonGroup>
                <StyledBoxButtonGroup
                    aria-label='text alignment'
                >
                    <ButtonToggleRoom
                        disabled
                        text={
                            <Typography variant='subtitle2'>
                                Accessoires
                            </Typography> }
                        actions={ () => dispatch(OpenFloorPaintPanel(true)) }
                        logo={ accessory }
                        altLogo='accessory logo'
                    />
                </StyledBoxButtonGroup>
                <StyledBoxButtonGroup
                    aria-label='text alignment'
                >
                    <ButtonToggleRoom
                        text={
                            <Typography variant='subtitle2' color='primary'>
                                Elements de<br />construction
                            </Typography> }
                        actions={ () => {
                            dispatch(OpenAddElementPanel(true));
                            props.setBuildingElementPanel(true);
                            props.setOpenPanel(true);
                        } }
                        logo={ element }
                        altLogo='constructor mdf'
                    />
                </StyledBoxButtonGroup>
            </PaperLayout>
        </>
    );
};

const StyledBoxButtonGroup = styled(Box)(({ theme }) => ({
    margin: theme.spacing(0.5),
    height: 80,
    minWidth: 80,
    '& .Mui-disabled': {
        border: 0,
    },
    '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
    },
}));

const StyledTriangle = styled(Box)(({ theme }) => ({
    width: 0,
    height: 0,
    borderTop: `20px solid ${ theme.palette.primary.main }`,
    borderRight: '20px solid white',
    borderTopLeftRadius: 3,
    position: 'absolute',
}));

export default AddProduct;
