import React, { useEffect, useState } from 'react';
import RoomStudioApp from "../../../../../application3D-seller/Libraries/Studios/RoomStudio/RoomStudioApp";
import ProgressBar from "../../../../../application3D-common/Components/UI/Basics/ProgressBar/ProgressBar";

export type LoadingBarProps = {
   roomStudio: RoomStudioApp
};

const LoadingBar = (props: LoadingBarProps) => {

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [loadingProgress, setLoadingProgress] = useState<number>(-1);
    const [loadingFile, setLoadingFile] = useState<string>('');
    const [loadingStep, setLoadingStep] = useState<string>('');

    const onLoadingProgress = (p_commandName: string, p_url: string, p_progress: number): void => {
        setLoadingStep(p_commandName);
        setLoadingProgress(p_progress);
        setLoadingFile(p_url);
    };

    useEffect(() => {
        props.roomStudio.Services.Room.SetOnInstanciateCallback(onLoadingProgress);
    }, []);

    useEffect(() => {
        if (loadingProgress >= 0) {
            setIsVisible(true);
        }
        if (loadingProgress >= 100) {
            setTimeout(() => setIsVisible(false), 1000);
        }
    }, [loadingProgress]);

    if (isVisible) {
        return <ProgressBar title={loadingStep} subTitle={loadingFile} completed={loadingProgress} />;
    } else {
        return <></>;
    }
};

export default LoadingBar;
