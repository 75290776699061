import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../services/redux/root-reducers';
import ElementsPanel from './ElementsPanel';
import ConstructPanel from './ConstructPanel';
import WindowPanel from './WindowPanel';
import SlidingWindowPanel from './SlidingWindowPanel';
import PanelSliderLayout from '../PanelSliderLayout';
import DoorPanel from './DoorPanel';
import {
    OpenAddElementPanel,
    PanelState,
} from '../../../services/redux/reducers/panel/panel.slice';


type AddElementProps = {
    setOpenAddElementPanel: (openAddElementPanel: boolean) => void;
    setOpenPanel: (OpenPanel: boolean) => void;
    open: boolean;
};

const AddElementPanel = ({ setOpenAddElementPanel, setOpenPanel, open }: AddElementProps) => {
    const dispatch = useDispatch();
    const [constructPanel, setConstructPanel] = useState(false);
    const [windowsPanel, setWindowsPanel] = useState(false);
    const [slidingWindowsPanel, setSlidingWindowsPanel] = useState(false);
    const [doorPanel, setDoorPanel] = useState(false);
    const [titlePanel, setTitlePanel] = useState('');
    const addElementPanel = useSelector<RootState, PanelState>((state) => state.panel);

    const handleClose = () => {
        dispatch(OpenAddElementPanel(false));
        setWindowsPanel(false);
        setSlidingWindowsPanel(false);
        setConstructPanel(false);
        setDoorPanel(false);
    };

    useEffect(() => {
        addElementPanel.openAddElementPanel ? setOpenAddElementPanel(true) : setOpenAddElementPanel(false);
    }, [addElementPanel]);

    useEffect(() => {
        if (addElementPanel.openAddElementPanel && !doorPanel && !constructPanel && !windowsPanel && !slidingWindowsPanel) {
            setTitlePanel('Elément de construction');
        }
    });

    function ChoosePanel() {
        switch (addElementPanel.openAddElementPanel) {
            case constructPanel:
                return (
                    <ConstructPanel
                        setConstructPanel={ setConstructPanel }
                        setOpenPanel={ setOpenPanel }
                        setOpenAddElementPanel={ setOpenAddElementPanel }
                    />);
            case windowsPanel:
                return (
                    <WindowPanel setWindowPanel={ setWindowsPanel } />);
            case slidingWindowsPanel:
                return (
                    <SlidingWindowPanel setSlidingWindowPanel={ setSlidingWindowsPanel } />);
            case doorPanel:
                return (
                    <DoorPanel setDoorPanel={ setDoorPanel } />);
            default:
                return (
                    <ElementsPanel
                        setDoorPanel={ setDoorPanel }
                        setWindowPanel={ setWindowsPanel }
                        setSlidingWindowPanel={ setSlidingWindowsPanel }
                        setOpenPanel={ setOpenPanel }
                        setConstructPanel={ setConstructPanel }
                        setOpenAddElementPanel={ setOpenAddElementPanel }
                        setTitle={ setTitlePanel }
                    />);
        }
    }

    return (
        <PanelSliderLayout onClose={ handleClose } open={ open } title={ titlePanel }>
            <ChoosePanel />
        </PanelSliderLayout>
    );
};

export default AddElementPanel;
