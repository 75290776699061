import * as React from 'react';
import { components } from 'react-select';
import './header.scss';

type HeaderProps = { title: string };

const Option = (props: any) => (
    <components.Option {...props} className="country-option">
        <img src={props.data.icon} alt="logo" className="country-logo" />
        {props.data.label}
    </components.Option>
);

const Header = ({ title }: HeaderProps) => {
    return (
        <header className="main-header">
            <div />
            <h3>{title}</h3>
        </header>
    );
};
export default Header;
