import React, { useRef } from 'react';
import { PropsWithChildren } from 'react';
import {
    Paper,
    styled,
    Slide,
    DialogTitle,
    IconButton,
    Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


type TitleCLoseActionPanelProps = {
    onClose: () => void;
    title: string;
};

type PanelSliderLayoutProps = PropsWithChildren<{
    open: boolean;
    title: string;
    onClose: () => void;
}>;

function TitleCLoseActionPanel({ onClose, title, ...other }: TitleCLoseActionPanelProps) {
    return (
        <DialogTitle color='primary'
                     sx={ {
                         padding: 0,
                         m: 0,
                         fontSize: 24,
                         fontWeight: 500,
                     } }
                     { ...other }>
            { title }
            <IconButton
                aria-label='close'
                onClick={ onClose }
                sx={ {
                    position: 'absolute',
                    right: 10,
                    top: 78,
                    color: (theme) => theme.palette.grey[500],
                } }
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    );
}


const PanelSliderLayout = ({ children, open, title, onClose }: PanelSliderLayoutProps) => {
    const containerRef = useRef(null);

    return (
        <>
            <Slide direction='left' in={ open } container={ containerRef.current }>
                <StyledPaper elevation={ 3 }>
                    <TitleCLoseActionPanel onClose={ onClose } title={ title } />
                    <Box sx={ { mt: 5 } }>
                        { children }
                    </Box>
                </StyledPaper>
            </Slide>
        </>
    );
};

const StyledPaper = styled(Paper)(() => ({
    position: 'fixed',
    right: '0%',
    bottom: '-1%',
    top: '0%',
    height: '100%',
    width: '28%',
    padding: '30px 22px',
    overflowY: 'auto',
    maxWidth: 550,
    paddingTop: 102,
}));

export default PanelSliderLayout;