import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MdfApiResponse from '../../../../domain/common/generic';
import { ConfigurationClassV2, ConfigurationResponseV2, ConfigurationV2, MdfSetV2 } from '../../../../domain/domain';
import ConfigurateurService from '../../../../api/service/configurateur-v2-service/configurateur-v2-service';

export const getConfigurationSectionList = createAsyncThunk('tableConceptV2/sectionlist', async (thunkAPI) => {
    const response = await ConfigurateurService.getConfigurateurCollection();
    return response;
});

export type ConfigurateurV2State = {
    isLoading: boolean;
    payload: MdfApiResponse<ConfigurationClassV2>;
};
const initialState: ConfigurateurV2State = {
    isLoading: false,
    payload: {
        content: { sections: [], referentialSets: [] },
        errors: [],
        warnings: [],
    },
};
export const getConfigurateurV2Slice = createSlice({
    name: 'getConfigurateurV2Slice',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getConfigurationSectionList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getConfigurationSectionList.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload: payload, isLoading: false };
            return state;
        });
        builder.addCase(getConfigurationSectionList.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

export const getReferentialSet = createAsyncThunk('tableConceptV2/referentialSet', async (thunkAPI) => {
    const response = await ConfigurateurService.getReferentielSet();
    return response;
});

export type ReferentialSetV2State = {
    isLoading: boolean;
    referentialSet: MdfApiResponse<MdfSetV2[] | null>;
};
const initialGetReferentialSetState: ReferentialSetV2State = {
    isLoading: false,
    referentialSet: {
        /*{ref: '', name:'', setType:"SET_WITH_IMG", items:[]}*/ content: null,
        errors: [],
        warnings: [],
    },
};
export const getReferentialSetV2Slice = createSlice({
    name: 'getReferentialSetV2Slice',
    initialState: initialGetReferentialSetState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getReferentialSet.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getReferentialSet.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { referentialSet: payload, isLoading: false };
            return state;
        });
        builder.addCase(getReferentialSet.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

//===========
type GetReferentialSetByConfigurationFormV2 = {
    form: ConfigurationV2;
};

export const getReferentialSetByConfiguration = createAsyncThunk('tableConceptV2/referentialSetByConfiguration', async (data: GetReferentialSetByConfigurationFormV2, thunkAPI) => {
    const response = await ConfigurateurService.getReferentialSetByConfiguration(data.form);
    return response;
});

export type ReferentialSetByConfigurationState = {
    isLoading: boolean;
    referentialSetByConfiguration: MdfApiResponse<MdfSetV2[] | null>;
};
const initialGetReferentialSetByConfigurationState: ReferentialSetByConfigurationState = {
    isLoading: false,
    referentialSetByConfiguration: {
        content: null,
        errors: [],
        warnings: [],
    },
};
export const getReferentialSetByConfigurationV2Slice = createSlice({
    name: 'getReferentialSetByConfigurationSlice',
    initialState: initialGetReferentialSetByConfigurationState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getReferentialSetByConfiguration.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getReferentialSetByConfiguration.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { referentialSetByConfiguration: payload, isLoading: false };
            return state;
        });
        builder.addCase(getReferentialSetByConfiguration.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

// DEFAULT CONFIGURATION

export type DefaultConfigurationV2State = {
    isLoading: boolean;
    payload: MdfApiResponse<ConfigurationResponseV2>;
};

const defaultConfigurationInitialState: DefaultConfigurationV2State = {
    isLoading: false,
    payload: {
        content: { configuration: { items: [], version: 0, id: 0 }, referentialSelectionnableSets: [] },
        errors: [],
        warnings: [],
    },
};

export const getDefaultConfiguration = createAsyncThunk('tableConceptV2/defaultConfiguration', async (thunkAPI) => {
    const response = await ConfigurateurService.getDefaultConfiguration();
    return response;
});

export const getDefaultConfigurationV2Slice = createSlice({
    name: 'getDefaultConfigurationV2',
    initialState: defaultConfigurationInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDefaultConfiguration.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getDefaultConfiguration.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload: payload, isLoading: false };
            return state;
        });
        builder.addCase(getDefaultConfiguration.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

// END DEFAULT CONFIGURATION

// SUGGESTION CONFIGURATION

export type SuggestionConfigurationV2State = {
    isLoading: boolean;
    payload: MdfApiResponse<ConfigurationResponseV2 | undefined>;
};

const suggestionConfigurationInitialState: SuggestionConfigurationV2State = {
    isLoading: false,
    payload: {
        content: undefined,
        errors: [],
        warnings: [],
    },
};

export const getSuggestionConfiguration = createAsyncThunk('tableConceptV2/suggestionConfiguration', async (ref : string, thunkAPI) => {
    const response = await ConfigurateurService.getConfigurationSuggestion({ ref });
    return response;
});

export const getSuggestionConfigurationV2Slice = createSlice({
    name: 'getSuggestionConfiguration',
    initialState: suggestionConfigurationInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSuggestionConfiguration.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getSuggestionConfiguration.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload: payload, isLoading: false };
            return state;
        });
        builder.addCase(getSuggestionConfiguration.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

// END SUGGESTION CONFIGURATION
