import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MdfApiResponse from '../../../../../domain/common/generic';
import { Model3DCellValue, Preset } from '../../../../../domain/domain';
import presetService from '../../../../../api/service/product/preset/preset.service';

export type PresetState = {
    isLoading: false;
    payload: MdfApiResponse<Preset>;
};
const initialPresetState: PresetState = {
    isLoading: false,
    payload: {
        content: {
            id: 0,
            configuration: [],
            name: '',
            reference: '',
            productReference: '',
            thumbnailUrl: '',
            duplicateNumber: 0,
            originalName: '',
            version: 0,
            createdAt: new Date(),
            updatedAt: new Date(),
        },
        errors: [],
        warnings: [],
    },
};

export type PresetConfigurationState = {
    isLoading: false;
    payload: MdfApiResponse<Model3DCellValue[]>;
};

const initialConfigurationPresetState: PresetConfigurationState = {
    isLoading: false,
    payload: {
        content: [],
        errors: [],
        warnings: [],
    },
};

export type PresetListState = {
    isLoading: false;
    payload: MdfApiResponse<Preset[]>;
};

const initialPresetListState: PresetListState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

//<editor-fold desc="duplicate-preset">
export const duplicatePreset = createAsyncThunk('post/preset/duplicate', async (data: { productRef: string; presetRef: string }) => {
    const res = await presetService.duplicatePreset(data.productRef, data.presetRef);
    return res;
});

export const duplicatePresetSlice = createSlice({
    name: 'duplicatePreset',
    initialState: initialPresetState,
    reducers: {
        resetDuplicatePreset: (state) => {
            state = initialPresetState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(duplicatePreset.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(duplicatePreset.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(duplicatePreset.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDuplicatePreset } = duplicatePresetSlice.actions;
//</editor-fold>

//<editor-fold desc="get-preset-by-product-ref">
export const getPresetByProductRef = createAsyncThunk('get/preset/productByRef', async (productRef: string) => {
    const res = await presetService.getPresetByProductRef(productRef);
    return res;
});

export const getPresetByProductRefSlice = createSlice({
    name: 'getPresetByProductRef',
    initialState: initialPresetListState,
    reducers: {
        resetGetPresetByProductRef: (state) => {
            state = initialPresetListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPresetByProductRef.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getPresetByProductRef.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getPresetByProductRef.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetPresetByProductRef } = getPresetByProductRefSlice.actions;
//</editor-fold>

//<editor-fold desc="get-preset-by-product-ref">
export const createPreset = createAsyncThunk('post/preset/create', async (data: { productRef: string; presetName: string }) => {
    const res = await presetService.createPreset(data.productRef, data.presetName);
    return res;
});

export const createPresetSlice = createSlice({
    name: 'createPresetSlice',
    initialState: initialPresetState,
    reducers: {
        resetCreatePreset: (state) => {
            state = initialPresetState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createPreset.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createPreset.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createPreset.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreatePreset } = createPresetSlice.actions;
//</editor-fold>

//<editor-fold desc="rename-preset">
export const renamePreset = createAsyncThunk('post/preset/rename', async (data: { productReference: string; presetRef: string; newName: string }) => {
    const res = await presetService.renamePreset(data.productReference, data.presetRef, data.newName);
    return res;
});

export const renamePresetSlice = createSlice({
    name: 'RenamePreset',
    initialState: initialPresetState,
    reducers: {
        resetRenamePreset: (state) => {
            state = initialPresetState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(renamePreset.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(renamePreset.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(renamePreset.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetRenamePreset } = renamePresetSlice.actions;
//</editor-fold>

//<editor-fold desc="get-all-preset">
export const getAllpreset = createAsyncThunk('get/preset/all', async () => {
    const res = await presetService.getAllPreset();
    return res;
});

export const getAllpresetSlice = createSlice({
    name: 'getAllpreset',
    initialState: initialPresetListState,
    reducers: {
        resetGetAllpreset: (state) => {
            state = initialPresetListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllpreset.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getAllpreset.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getAllpreset.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetAllpreset } = getAllpresetSlice.actions;
//</editor-fold>

//<editor-fold desc="delete-preset">
export const deletePreset = createAsyncThunk('delete/preset', async (data: { productRef: string; presetRef: string }) => {
    const res = await presetService.deletePreset(data.productRef, data.presetRef);
    return res;
});

export const deletePresetSlice = createSlice({
    name: 'deletePreset',
    initialState: initialPresetState,
    reducers: {
        resetDeletePreset: (state) => {
            state = initialPresetState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deletePreset.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deletePreset.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deletePreset.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDeletePreset } = deletePresetSlice.actions;
//</editor-fold>

//<editor-fold desc="post-add-atom">
export const addAtomToPreset = createAsyncThunk('post/addAtomPreset', async (data: { productRef: string; presetRef: string; atomRef: string }) => {
    const res = await presetService.addAtomToPreset(data.productRef, data.presetRef, data.atomRef);
    return res;
});

export const addAtomToPresetSlice = createSlice({
    name: 'addAtomPreset',
    initialState: initialPresetState,
    reducers: {
        resetAddAtomToPreset: (state) => {
            state = initialPresetState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addAtomToPreset.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(addAtomToPreset.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(addAtomToPreset.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetAddAtomToPreset } = addAtomToPresetSlice.actions;
//</editor-fold>

//<editor-fold desc="get-atom-presetRef">
export const findAtomByPresetRef = createAsyncThunk('get/findAtomByPresetRef', async (presetRef: string) => {
    const res = await presetService.findAtomByPresetRef(presetRef);
    return res;
});

export const findAtomByPresetRefSlice = createSlice({
    name: 'findAtomByPresetRefSlice',
    initialState: initialConfigurationPresetState,
    reducers: {
        resetFindAtomByPresetRef: (state) => {
            state = initialConfigurationPresetState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(findAtomByPresetRef.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(findAtomByPresetRef.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(findAtomByPresetRef.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetFindAtomByPresetRef } = findAtomByPresetRefSlice.actions;
//</editor-fold>

//<editor-fold desc="updatePresetThumbnailUrl">
export const updatePresetThumbnailUrl = createAsyncThunk('put/preset/updatePresetThumbnailUrl', async (data: { productReference: string; presetRef: string; thumbnailUrl: string }) => {
    const res = await presetService.updatePresetThumbnailUrl(data.productReference, data.presetRef, data.thumbnailUrl);
    return res;
});

export const updatePresetThumbnailUrlSlice = createSlice({
    name: 'updatePresetThumbnailUrl',
    initialState: initialPresetState,
    reducers: {
        resetUpdatePresetThumbnailUrl: (state) => {
            state = initialPresetState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updatePresetThumbnailUrl.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updatePresetThumbnailUrl.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(updatePresetThumbnailUrl.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetUpdatePresetThumbnailUrl } = updatePresetThumbnailUrlSlice.actions;
//</editor-fold>
