import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Guid } from 'guid-typescript';
import Instance3D
    from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/Instance3D";
import RoomStudioApp from "../../../Libraries/Studios/RoomStudio/RoomStudioApp";
import BasicObject
    from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import {RootState} from "../../../../../services/redux/root-reducers";
import {clearCurrentEntity, RoomStudioState} from "../../../Redux/Reducers/room-studio/RoomStudioReducer";
import {removeEntityId} from "../../../Redux/Reducers/room-studio/EntitiesReducer";


export type RoomObjectDestroyerProps = {
    roomStudio : RoomStudioApp;
    instances:  Instance3D[]
    basicObjects: BasicObject[]
    entityIds: {refOfInstance: string, entityId: Guid}[];
};

const RoomObjectDestroyer = (props: RoomObjectDestroyerProps) => {
    const dispatch = useDispatch();

    const { currentEntity } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    
    const [objectRefToRemove, setobjectRefToRemove] = useState(new Array<{ refOfInstance: string; entityId: Guid }>());

    useEffect(() => {
        setobjectRefToRemove([]);
        
        let refWithoutEntity: { refOfInstance: string; entityId: Guid }[] = [];
        props.entityIds.forEach((instanceEntityId) => {
            if (!props.basicObjects.some((x) => x.RefOfInstance === instanceEntityId.refOfInstance)) {
                refWithoutEntity.push(instanceEntityId);
                dispatch(removeEntityId(instanceEntityId.entityId));
            }
        });
        setobjectRefToRemove(refWithoutEntity);
        
    }, [ props.basicObjects,  props.roomStudio]);

    useEffect(() => {
        objectRefToRemove.forEach((instanceEntityId) => {
            let entity = props.roomStudio.Services.ObjectBehaviours.BasicObjects.GetObjectEntity(instanceEntityId.entityId);
            if (entity) {
                if (currentEntity && entity.Id === currentEntity.Id) {
                    dispatch(clearCurrentEntity());
                }
                props.roomStudio.Services.Room.RemoveObject(entity);
            }
        });
        
    }, [objectRefToRemove]);

    return <></>;
};

export { RoomObjectDestroyer };
