import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { Object3D, Raycaster, Vector3 } from "three";
import { ASystem } from "@lutithree/build/Modules/Core/Entity/ASystem";
import { BoundingBoxComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundingBoxComponent";
import { DistanceDirection, RelativeDistanceDisplayerComponent } from "../Components/RelativeDistanceDisplayer";
import RelativeDistanceInfo from "../RelativeDistanceInfo";
import { GroupComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/GroupComponent";
import InfoComponent from "../../../Objects/Components/InfoComponent";


export default class RelativePositioningSystem extends ASystem<SceneEntity> {
    
    public GetRelativeDistanceInfos(p_entity: SceneEntity, p_offset: Vector3 | undefined = undefined): RelativeDistanceInfo[] {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        
        let relativesPositions: RelativeDistanceInfo[] = [];
        let entities: SceneEntity[] = [];
        
        let groupRefToIngnore = this.GetGroupRefs(p_entity);
        
        this.m_entityManager.GetEntitesWithComponents([BoundingBoxComponent]).forEach((entityWithBB)=>{
            let isIgnored: boolean = false; 
            let groups = entityWithBB.GetComponentsOfType(GroupComponent);
            groups.forEach((group)=>{
                if(groupRefToIngnore.includes(group.GroupRef)) isIgnored = true;
            });
            if(!isIgnored) entities.push(entityWithBB);
        });
        
        this.m_entityManager.GetEntitesWithComponents([InfoComponent]).forEach((roomObjectEntity)=>{
            let infoComponent = roomObjectEntity.GetComponentOfType(InfoComponent);
            let roles = ['Ceiling','Ground','Wall'];
            if(roles.includes(infoComponent.Info.Type)){
                entities.push(roomObjectEntity);
            }
        });
        
        if (p_entity.HasComponentOfType(BoundingBoxComponent)) {
            let bb = p_entity.GetComponentOfType(BoundingBoxComponent);
            
            let rightOriented = bb.GetRightOriented();
            let leftOriented = bb.GetRightOriented(true);
            let forwardOriented = bb.GetForwardOriented();
            let backwardOriented = bb.GetForwardOriented(true);
            let upOriented = bb.GetUpOriented();
            let downOriented = bb.GetUpOriented(true);

            let rightInfo = this.GetRelativeDistanceForDirection(p_entity, rightOriented, DistanceDirection.RIGHT, entities, p_offset);
            if(rightInfo)
                relativesPositions.push(rightInfo);
            
            let leftInfo = this.GetRelativeDistanceForDirection(p_entity, leftOriented, DistanceDirection.LEFT, entities, p_offset);
            if (leftInfo)
                relativesPositions.push(leftInfo);
            
            let forwardInfo = this.GetRelativeDistanceForDirection(p_entity, forwardOriented, DistanceDirection.FORWARD, entities, p_offset);
            if (forwardInfo)
                relativesPositions.push(forwardInfo);
            
            let backwardInfo = this.GetRelativeDistanceForDirection(p_entity, backwardOriented, DistanceDirection.BACKWARD, entities, p_offset);
            if (backwardInfo)
                relativesPositions.push(backwardInfo);

            let upInfo = this.GetRelativeDistanceForDirection(p_entity, upOriented, DistanceDirection.UP, entities, p_offset);
            if (upInfo)
                relativesPositions.push(upInfo);

            let downInfo = this.GetRelativeDistanceForDirection(p_entity, downOriented, DistanceDirection.DOWN, entities, p_offset);
            if (downInfo)
                relativesPositions.push(downInfo);
        }
        return relativesPositions;
    }

    public GetRelativePositioningDisplayer(p_entity: SceneEntity): RelativeDistanceDisplayerComponent|undefined {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        
        let positioningDisplayerComponent: RelativeDistanceDisplayerComponent|undefined = undefined;
        let positioningDisplayerComponents = this.m_entityManager.GetComponents(RelativeDistanceDisplayerComponent, { entity: false, component: false });
        positioningDisplayerComponents.forEach((component) => {
            if (component.TargetEntityID.toString() === p_entity.Id.toString()) {
                positioningDisplayerComponent = component;
            }
        });
        return positioningDisplayerComponent;
    }
    
    private GetRelativeDistanceForDirection(p_target: SceneEntity, p_direction: Vector3, p_distanceDirection: DistanceDirection, 
                                            p_entitiesToTest: SceneEntity[], p_offset: Vector3 | undefined = undefined) : RelativeDistanceInfo | undefined{
        let objectsWithoutBB: Object3D[] = [];
        let result;
        
        // 1 - Setup raycaster
        let targetBB = p_target.HasComponentOfType(BoundingBoxComponent)?p_target.GetComponentOfType(BoundingBoxComponent):undefined;
        let from = p_target.Transform.GetObject().position.clone().add(p_direction);
        if(targetBB) from = targetBB.Center.clone().add(p_direction);
        if(p_offset)
            from.add(p_offset);
        let raycaster = new Raycaster(from, p_direction.clone().normalize());
        raycaster.layers.set(0);

        // 2 - Get scene bb to test + test raycaster
        p_entitiesToTest.forEach((entity)=>{
            let bb = entity.HasComponentOfType(BoundingBoxComponent)?entity.GetComponentOfType(BoundingBoxComponent):undefined;
            if(bb){
                if(bb.EntityID !== p_target.Id) {
                    let intersection = new Vector3();
                    let retValue = bb.OBB.intersectRay(raycaster.ray, intersection);
                    if(retValue != null) {
                        result = intersection;
                        return;
                    }
                }
            }
            else{
                objectsWithoutBB.push(entity.Transform.GetObject());
            }
        });
        if(result) return new RelativeDistanceInfo(from, result, p_distanceDirection);
        
        // 3 - Compute intersection with object without BB
        let intersections = raycaster.intersectObjects(objectsWithoutBB, true);
        if (intersections.length > 0){
            return new RelativeDistanceInfo(from, intersections[0].point, p_distanceDirection);
        }
    }

    private GetGroupRefs(p_entity: SceneEntity) : string[] {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        let entityGroupRefs: string[] = [];
        let entityGroups = p_entity.GetComponentsOfType(GroupComponent);

        entityGroups.forEach((group)=>{
            entityGroupRefs.push(group.GroupRef);
        });

        return entityGroupRefs;
    }
}
