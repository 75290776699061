import * as React from "react";
import DialogAction from "../../../ui-components/Dialog/DialogAction";
import { Button, styled } from "@mui/material";


type CloseSaveButtonModalProps = {
    onClose: () => void;
    color?: "primary" | "secondary";
    type?: "button" | "submit";
};

const CloseSaveButtonModal = ({ onClose, color, type }: CloseSaveButtonModalProps) => {
    return (
        <>
            <DialogAction>
                <StyledButton type={ type } variant='outlined' onClick={ onClose } size='large'>
                    Annuler
                </StyledButton>
                <StyledButton type='submit' color={ color } variant='contained' size='large'
                              disableElevation>
                    Valider
                </StyledButton>
            </DialogAction>
        </>
    );
};

const StyledButton = styled(Button)(() => ({
    width: 200,
    height: 50
}));

export default CloseSaveButtonModal;