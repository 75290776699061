import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { ProductDecorator } from "./RoomObjects/ProductDecorator";
import { AccessoryDecorator } from "./RoomObjects/AccessoryDecorator";
import { BuildingBlockDecorator } from "./RoomObjects/BuildingBlockDecorator";
import { OpeningDecorator } from "./RoomObjects/OpeningDecorator";
import { GroundDecorator } from "./RoomObjects/GroundDecorator";
import { CeilingDecorator } from "./RoomObjects/CeilingDecorator";
import { WallDecorator } from "./RoomObjects/WallDecorator";
import { MeshRendererComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshRendererComponent";
import { AutoReorientationComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/AutoReorientationComponent";
import { Engine } from "@lutithree/build/Engine";
import { GroupComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/GroupComponent";
import {BoundingBoxComponent} from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundingBoxComponent";
import {Vector3} from "three";
import ObjectSystem from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Composition/ObjectSystem";
import {
    IObjectDecorator
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/IObjectDecorator";
import BasicObject
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import Instance3D
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/Instance3D";
import Information
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Information";
import {
    DimensionComponent
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Dimensions/Components/DimensionComponent";
import ObjectComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";
import InfoComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/InfoComponent";
import PartComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/PartComponent";


export class ObjectDecorator implements IObjectDecorator {
    
    private m_decoratorByType: Map<string, IObjectDecorator>;
    
    private m_objectSystem: ObjectSystem;
    
    private m_engine: Engine;
    
    public constructor(p_engine: Engine){
        this.m_decoratorByType = new Map<string, IObjectDecorator>();
        this.m_decoratorByType.set('Product', new ProductDecorator());
        this.m_decoratorByType.set('Accessory', new AccessoryDecorator());
        this.m_decoratorByType.set('BuildingBlock', new BuildingBlockDecorator());
        this.m_decoratorByType.set('Opening', new OpeningDecorator());
        this.m_decoratorByType.set('Ground', new GroundDecorator());
        this.m_decoratorByType.set('Ceiling', new CeilingDecorator());
        this.m_decoratorByType.set('Wall', new WallDecorator());
        
        this.m_objectSystem = new ObjectSystem(p_engine.Modules.Scene);
        this.m_engine = p_engine;
    }
    
    public DecorateRootObject(p_entity: SceneEntity, p_object: BasicObject, p_instance: Instance3D | undefined): void {
        if (p_object == null) throw new Error('NullReferenceException : p_object is null or undefined');
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        
        // Object decoration
        p_entity.AddComponentOfType(ObjectComponent, p_object.RefOfInstance);
        p_entity.AddComponentOfType(GroupComponent, p_object.RefOfInstance);
        
        // Info decoration
        if (p_object.Informations) {

            if (p_object.Informations.Type != '') {
                let decorator = this.m_decoratorByType.get(p_object.Informations.Type);
                if (decorator) decorator.DecorateRootObject(p_entity, p_object, p_instance);
                p_entity.AddComponentOfType(InfoComponent, p_object.Informations);
            }

            if (p_object.Informations.HasBehaviour("AutoReorientable")) {
                p_entity.AddComponentOfType(AutoReorientationComponent);
            }
            
            this.AddDimensionComponent(p_entity, p_object.Informations);
            if (!p_instance) this.ApplySpawnHeight(p_entity, p_object.Informations, this.GetRoomMaxHeight());
        }
    }

    public DecorateObject(p_entity: SceneEntity, p_object: BasicObject, p_instance: Instance3D|undefined): void {
        if (p_object == null) throw new Error('NullReferenceException : p_object is null or undefined');
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        // Object decoration
        p_entity.AddComponentOfType(ObjectComponent, p_object.RefOfInstance);
        p_entity.AddComponentOfType(GroupComponent, p_object.RefOfInstance);

        // Info decoration
        if (p_object.Informations) {
            if (p_object.Informations.Type != '') {
                let decorator = this.m_decoratorByType.get(p_object.Informations.Type);
                if (decorator) decorator.DecorateObject(p_entity, p_object, p_instance);
                p_entity.AddComponentOfType(InfoComponent, p_object.Informations);
            }
            this.AddDimensionComponent(p_entity, p_object.Informations);
            if (!p_instance) this.ApplySpawnHeight(p_entity, p_object.Informations, this.GetRoomMaxHeight());
        }
    }

    public DecoratePart(p_entity: SceneEntity, p_parentObject: BasicObject, p_parentEntity:SceneEntity, p_refOfPart: string): void {
        if (!p_refOfPart) throw new Error('NullReferenceException : p_refOfPart is null or undefined or empty');
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        // Object decoration
        if((p_parentObject.Composition.Type === 'Asset3D') || p_parentObject.Assets.length>0) {
            p_entity.AddComponentOfType(GroupComponent, p_parentObject.Ref);
        }
        if (p_entity.HasComponentOfType(ObjectComponent)) {
            this.m_objectSystem.GetNestedPartEntities(p_entity.GetComponentOfType(ObjectComponent)).forEach((entity) => {
                entity.AddComponentOfType(GroupComponent, p_parentObject.RefOfInstance);
            });
            if (p_entity.HasComponentOfType(GroupComponent))
                p_entity.AddComponentOfType(GroupComponent, p_parentObject.RefOfInstance);
        }
        let assetType = p_parentObject.Composition.Type !== ''? p_parentObject.Composition.Type :'Asset3D';
        p_entity.AddComponentOfType(PartComponent, ()=>{return p_parentObject.Ref;}, p_refOfPart, assetType);

        // Info decoration
        let decorator = this.m_decoratorByType.get(p_parentObject.Informations.Type);
        if (decorator) decorator.DecoratePart(p_entity, p_parentObject, p_parentEntity, p_refOfPart);
        if (p_parentEntity.HasComponentOfType(AutoReorientationComponent)) {
            this.CutOffCastShadow(p_entity);
        }
    }

    private CutOffCastShadow(p_entity: SceneEntity): void {
        if (p_entity.HasComponentOfType(MeshRendererComponent)) {
            let meshRenderer = p_entity.GetComponentOfType(MeshRendererComponent);
            meshRenderer.CastShadows = false;
        }
    }

    private AddDimensionComponent(p_entity: SceneEntity, p_objectInfo: Information): void {
        p_entity?.AddComponentOfType(DimensionComponent, p_objectInfo.Dimensions);
    }
    
    private GetRoomMaxHeight(): number | undefined {
        let ceilings = this.m_engine.Modules.Scene.GetComponents(ObjectComponent, {
            entity: false,
            component: false
        }).filter((objectComponent) => {
            return objectComponent.Ref === "Ceiling";
        });

        let maxHeight: number | undefined = undefined;
        if (ceilings.length > 0) {
            let ceiling = this.m_engine.Modules.Scene.GetEntityByID(ceilings[0].EntityID);
            if (ceiling) maxHeight = ceiling.Transform.GetObject().position.y;
        }
        return maxHeight;
    }

    private ApplySpawnHeight(p_entity: SceneEntity, p_objectInfo:Information, p_maxHeight: number | undefined): void {
        if (p_objectInfo.SpawnHeight) {
            let spawnYInMeter = p_objectInfo.SpawnHeight/100;
            if (p_maxHeight) {
                if (p_entity.HasComponentOfType(BoundingBoxComponent, false)) {
                    let bb = p_entity.GetComponentOfType(BoundingBoxComponent);
                    if ((spawnYInMeter + bb.Center.y + bb.HalfSize.y) > p_maxHeight)
                        spawnYInMeter = p_maxHeight - bb.Center.y - bb.HalfSize.y;
                }
            }
            p_entity?.Transform.AddToPosition(new Vector3(0, spawnYInMeter, 0));
        }
    }
}