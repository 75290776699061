import axios from 'axios';
import { getConfig } from '../api/config';

export const Upload = async (data: unknown, setImageUrl: (urls: string[]) => void) => {
    await axios
        .post(`${process.env.REACT_APP_API_URL || 'http://localhost:8080/mdf-api'}/v1/documents/upload/list`, data, {
            headers: { Authorization: `Bearer ${localStorage.getItem('mdf-jwt')}` },
        })
        .then((res) => {
            // @ts-ignore
            setImageUrl(res.data.content);
        })
        .catch((e) => {
            console.log('erreur', e);
        });
};

export const postFile = async (url: string, form: FormData) => {
    try {
        const { data } = await axios.post(`${getConfig().urlHostApi}/${url}`, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('mdf-jwt')}`,
            },
        });
        return data;
    } catch (error) {
        console.error(error);
    }
};

export const postFileWithCallback = async (url: string, form: FormData, onSuccess: () => void, setResult?: (result: any) => void, setIsLoading?: (loading: boolean) => void, list?: string[]) => {
    setIsLoading && setIsLoading(true);
    await axios
        .post(`${getConfig().urlHostApi}/${url}`, form, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('mdf-jwt')}`,
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((res) => {
            //   setIsLoading && setIsLoading(false);
            setResult && setResult(res);
            onSuccess();
            list && list.push(res.data.content);
        })
        .catch((e) => {
            console.log('erreur', e);
        });
};
