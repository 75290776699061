import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { resetAuth } from '../../../services/redux/reducers/auth/auth.slice';
import { useDispatch, useSelector } from 'react-redux';
import HeaderLayout from '../../../ui-components/HeaderLayout';
import {
    selectCatalog,
    TreeCatalogListState,
} from '../../../services/redux/reducers/visibility/visibility.slice';
import { RootState } from '../../../services/redux/root-reducers';
import { TreeCatalog } from '../../../domain/domain';
import mdfLogo from '../../../asset/top.svg';
import { Divider, Typography, Box, IconButton, MenuItem, Menu, ListItemIcon } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Logout from '@mui/icons-material/Logout';


const NavbarApp = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const isPublication = location.pathname === '/' ? 'secondary' : 'primary';
    const isProject = location.pathname === '/projects' ? 'secondary' : 'primary';
    const isProfile = location.pathname === '/profile' ? 'secondary' : 'primary';
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openEl = Boolean(anchorEl);
    const [catalogs, setCatalogs] = useState<TreeCatalog[]>([]);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [selectedCatalogId, setSelectedCatalogId] = useState(0);
    const catalogByUser = useSelector<RootState, TreeCatalogListState>(state => state.getCatalogsByUser);
    const [isMount, setIsMount] = useState<boolean>(false);
    const catalogId = localStorage.getItem('selected-catalogId');

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        catalogId !== null && setSelectedCatalogId(parseInt(catalogId));
        return () => setIsMount(true);
    }, []);
    useEffect(() => {
        //const err = catalogByAdmin.payload.errors && catalogByAdmin.payload.errors.some((e) => e.code === 'BP-A0001');
        //err ? setIsAdmin(false) : setIsAdmin(true);
        if (catalogByUser.payload.content && catalogByUser.payload.errors.length === 0) {
            setCatalogs(catalogByUser.payload.content);
            if (catalogByUser.payload.content[0]) {
                !catalogId && setSelectedCatalogId(catalogByUser.payload.content[0].catalog.id);
            }
            return () => setIsMount(true);
        }
    }, [catalogByUser]);

    useEffect(() => {
        const catalog = catalogByUser.payload.content && catalogByUser.payload.content.find((c) => c.catalog.id === selectedCatalogId);
        dispatch(selectCatalog(catalog));
        catalog !== undefined && localStorage.setItem('selected-catalogId', JSON.stringify(catalog.catalog.id));
    }, [selectedCatalogId]);

    return (
        <>
            <HeaderLayout>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        style={{ width: 194, height: 45 }}
                        src={mdfLogo}
                        alt='logo mdf' />

                    <Divider sx={{ margin: '0 1.5em' }} orientation='vertical' flexItem />
                    <Typography
                        onClick={() => navigate('/')}
                        sx={{
                            fontSize: 14,
                            letterSpacing: '1.4px',
                            textTransform: 'uppercase',
                            cursor: 'pointer',
                            fontWeight: 'normal',
                        }}
                        color={isPublication}
                    >Publications</Typography>
                    <Divider sx={{ margin: '0 1.5em' }} orientation='vertical' flexItem />
                    <Typography
                        onClick={() => navigate('/projects')}
                        sx={{
                            fontSize: 14,
                            letterSpacing: '1.4px',
                            textTransform: 'uppercase',
                            cursor: 'pointer',
                            fontWeight: 'normal',
                        }}
                        color={isProject}
                    >Projets</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                                <span style={{
                                    color: '#1D2740',
                                    fontSize: '0.8em',
                                    marginRight: '1em',
                                }}>Catalogue :</span>
                            <select
                                onChange={(e) => setSelectedCatalogId(parseInt(e.target.value))}
                                name='catalog'
                                id='catalog'
                                value={selectedCatalogId}>
                                <option>Catalogues</option>
                                {catalogs.map((c: TreeCatalog) => {
                                    return (
                                        <option
                                            key={c.catalog.id}
                                            value={c.catalog.id}>
                                            {c.catalog.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    <Divider sx={{ margin: '0 1.5em' }} orientation='vertical' flexItem />
                    <IconButton
                        color={isProfile}
                        onClick={handleClick}
                        size='small'
                        aria-controls={anchorEl ? 'account-menu' : undefined}
                        aria-haspopup='true'
                        aria-expanded={anchorEl ? 'true' : undefined}
                    >
                        <AccountCircleOutlinedIcon sx={{ fontSize: 35 }} />
                    </IconButton>
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    id='account-menu'
                    open={openEl}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={() => {
                        handleClose();
                        navigate('/profile');
                    }}>
                        <AccountCircleOutlinedIcon
                            sx={{ fontSize: 35, mr: 1 }}
                        />
                        Profil
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => {
                        handleClose();
                        dispatch(resetAuth());
                        localStorage.removeItem('mdf-jwt');
                        localStorage.removeItem('user');
                        localStorage.removeItem('firstconnection');
                        localStorage.removeItem('selected-catalogId');
                        navigate('/login');
                    }}>
                        <ListItemIcon>
                            <Logout
                                sx={(theme: { palette: { error: { main: string; }; }; }) => ({
                                    fontSize: 25, mr: 1,
                                    color: theme.palette.error.main,
                                })}
                            />
                        </ListItemIcon>
                        Se Déconnecter
                    </MenuItem>
                </Menu>
            </HeaderLayout>
        </>
    );
};


export default NavbarApp;