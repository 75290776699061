import React, { useEffect, useState } from 'react';
import './tabNav.scss';

type TabNavProps = {
    tabs: string[];
    selected: string;
    setSelected: (tab: string) => void;
};

const TabNav: React.FC<TabNavProps> = ({ children, tabs, setSelected }) => {
    const [isMount, setisMount] = useState(false);
    const [activeSection, setActiveSection] = useState<string>('');
    useEffect(() => {
        !isMount && setActiveSection(tabs[0]);
        !isMount && setSelected(tabs[0]);
        return (() => setisMount(true));
    }, []);
    return (
        <div className='tabNav-main'>
            <div className='tabNav_nav'>
                {tabs.map((tab, index) => {
                    return (
                        <div className='tabNav-item-bloc' key={index}>
                            <button
                                className={activeSection === tab ? 'tabNav_nav-active' : 'tabNav_nav-btn'}
                                key={index}
                                type='button'
                                onClick={() => {
                                    setActiveSection(tab);
                                    setSelected(tab);
                                }}
                            >
                                {tab}
                            </button>
                        </div>
                    );
                })}
            </div>
            {children}
        </div>
    );
};

export default TabNav;
