import React, { ChangeEvent } from 'react';
import { Typography, Box, styled, TextField } from '@mui/material';


interface TextFieldUiProps {
    helperText?: string;
    id?: string;
    variant: 'outlined';
    fullWidth: boolean;
    placeholder?: string;
    error?: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    value: string;
    name: string;
    labelContent?: string;
    inputProps?: object;
    showLength?: boolean;
    maxLength?: number;
    /**
     * Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types).
     */
    type?: React.InputHTMLAttributes<unknown>['type'];
    /**
     * If `true`, the component is disabled.
     * @default false
     */
    disabled?: boolean;
}

const TextFieldUi = ({
                         name,
                         helperText,
                         id,
                         variant,
                         fullWidth = true,
                         placeholder,
                         error,
                         value,
                         onChange,
                         labelContent,
                         inputProps,
                         showLength,
                         maxLength,
                         type,
                         disabled,
                     }: TextFieldUiProps) => {

    const colorLabel = disabled ? 'grey' : 'primary';

    function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
        onChange && onChange(event);
    }

    return (
        <Box
            sx={ {
                position: 'relative',
                height: showLength ? 117 : 'auto',
            } }
        >
            <Box
                color={ colorLabel }
                component='label'
                htmlFor={ name }
            >
                { labelContent }
            </Box>
            <CustomTextField
                sx={ labelContent !== undefined ? ({ marginTop: 1 }) : null }
                color='primary'
                id={ id }
                name={ name }
                variant={ variant }
                fullWidth={ fullWidth }
                disabled={ disabled }
                placeholder={ placeholder }
                error={ error }
                value={ value }
                onChange={ handleOnChange }
                helperText={ helperText }
                inputProps={ inputProps }
                type={ type }
            />
            { showLength && maxLength &&
                <StyledTypography
                    variant='overline' display='block'>
                    { value.length }/{ maxLength }
                </StyledTypography>
            }
        </Box>
    );
};

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root.Mui-error': {
        backgroundColor: `${ theme.palette.error.light }`,
    },
}));

const StyledTypography = styled(Typography)({
    color: '#dbdbdb',
    fontSize: '0.90rem',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute', bottom: 0, right: 0,
});


export default TextFieldUi;