import { DisableComponent } from "@lutithree/build/Modules/Core/Entity/DisableComponent";

export class POVComponent extends DisableComponent {
    private isViewedFromBehind: boolean = false;

    public constructor() {
        super();
    }
    
    public set IsViewedFromBack(p_value: boolean) {
        this.isViewedFromBehind = p_value;
    }

    public get IsViewedFromBack() : boolean{
        return this.isViewedFromBehind;
    }
}
