import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { fetchAndAddTemplate } from '../../../../utils/fetchAndAddTemplate';
import ButtonNavigate from '../../../../ui-components/ButtonNavigate';
import CardMediaWithLabel from '../../CardAction/CardMediaWithLabel';
import doorOne from './../../../../asset/porte1v@2x.png';
import doorTwo from './../../../../asset/porte2v@2x.png';


type DoorPanelProps = {
    setDoorPanel: (setDoorPanel: boolean) => void;
};

type DataDoorPanelProps = {
    label: string;
    imageUrl: string;
    onClick: () => void;
};

const DoorPanel = ({ setDoorPanel }: DoorPanelProps) => {
    const dispatch = useDispatch();
    const openingDoor: string = 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/metadata/objects/openings/door/opening_door.json';
    const openingDoor2v: string = 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/metadata/objects/openings/door/opening_door_2v.json';

    const dataDoorPanel: DataDoorPanelProps[] = [
        {
            label: '1 Vantail',
            imageUrl: doorOne,
            onClick: () => fetchAndAddTemplate(openingDoor, dispatch),
        },
        {
            label: '2 Vantaux',
            imageUrl: doorTwo,
            onClick: () => fetchAndAddTemplate(openingDoor2v, dispatch),
        },
    ];

    return (
        <>
            <ButtonNavigate label='Portes' onClick={ () => setDoorPanel(false) } />
            <Box sx={ { pt: 3 } }>
                <Grid container spacing={ 2 }>
                    { dataDoorPanel.map((el, index: number) => {
                        return (
                            <Grid item xs={ 12 } md={ 6 } key={ index + el.label }>
                                <CardMediaWithLabel onClick={ el.onClick } label={ el.label } media={ el.imageUrl } />
                            </Grid>
                        );
                    }) }
                </Grid>
            </Box>
        </>
    );
};

export default DoorPanel;