import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NewsService from '../../../../api/service/news-service/news-service';
import MdfApiResponse from '../../../../domain/common/generic';
import { News, NewsCriticality, NewsRead, NewsStatus } from '../../../../domain/domain';
import { Pageable } from '../../../../domain/pageable/pageable';


type GetNewsListParamters = {
    status?: NewsStatus;
    criticality?: NewsCriticality | string;
    page?: number;
    category?: string;
};

export const getNewsList = createAsyncThunk('news/list', async (data: GetNewsListParamters, thunkAPI) => {
    const response = await NewsService.getAllNews(data.criticality, data.status, data.page, data.category);
    return response;
});

export type NewsState = {
    isLoading: boolean;
    payload: MdfApiResponse<Pageable<News>>;
};
const initialState: NewsState = {
    isLoading: false,
    payload: {
        content: { content: [], number: 0, totalPages: 0 },
        errors: [],
        warnings: [],
    },
};
export const getNewsSlice = createSlice({
    name: 'getNewsSlice',
    initialState: initialState,
    reducers: {
        resetNewsList: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getNewsList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getNewsList.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload: payload, isLoading: false };
            return state;
        });
        builder.addCase(getNewsList.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

// GET ALL UNREAD NEWS

export const getUnreadNewsList = createAsyncThunk('news/unread/list', async (data: GetNewsListParamters, thunkAPI) => {
    const response = await NewsService.getAllUnreadNews(data.criticality, data.status, data.page, data.category);
    return response;
});

export const getAllUnreadNewsSlice = createSlice({
    name: 'getAllUnreadNewsSlice',
    initialState: initialState,
    reducers: {
        resetUnreadNewsList: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUnreadNewsList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getUnreadNewsList.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload: payload, isLoading: false };
            return state;
        });
        builder.addCase(getUnreadNewsList.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

// SEARCH

export const getSearchNewsList = createAsyncThunk('searchNews/list', async (search: string, thunkAPI) => {
    const response = await NewsService.getFilterNews(search);
    return response;
});

export type SearchNewsState = {
    isLoading: boolean;
    payload: MdfApiResponse<Pageable<News>>;
};
const initialSearchNewsState: SearchNewsState = {
    isLoading: false,
    payload: {
        content: { content: [], number: 0, totalPages: 0 },
        errors: [],
        warnings: [],
    },
};

export const getSearchNewsSlice = createSlice({
    name: 'getSearchNewsSlice',
    initialState: initialSearchNewsState,
    reducers: {
        resetSearchNewsList: (state) => {
            state = initialSearchNewsState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSearchNewsList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getSearchNewsList.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload: payload, isLoading: false };
            return state;
        });
        builder.addCase(getSearchNewsList.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});
// MARK NEWS AS READ

export const markNewsAsRead = createAsyncThunk('news/markAsRead', async (newsId: number, thunkAPI) => {
    const response = await NewsService.markNewsAsRead(newsId);

    return response;
});

export type NewsReadState = {
    isLoading: boolean;
    payload: MdfApiResponse<NewsRead | null>;
};

const initialNewsReadState: NewsReadState = {
    isLoading: false,
    payload: {
        content: null,
        errors: [],
        warnings: [],
    },
};

export const markNewsAsReadSlice = createSlice({
    name: 'markNewsAsReadSlice',
    initialState: initialNewsReadState,
    reducers: {
        resetMarkNewsAsRead: (state) => {
            state = initialNewsReadState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(markNewsAsRead.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(markNewsAsRead.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload: payload, isLoading: false };
            return state;
        });
        builder.addCase(markNewsAsRead.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { resetUnreadNewsList } = getAllUnreadNewsSlice.actions;
export const { resetNewsList } = getNewsSlice.actions;
