import AState from "../../../../FSM/AState";


export default class IdleBehaviours extends AState {
    public Enter(): void {
    }

    public Exit(): void {
    }
}
