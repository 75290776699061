import React, { useEffect, useState } from 'react';
import TopBar from '../UI/Composites/TopBar';
import RightBar from '../UI/Composites/RightBar';
import { useDispatch, useSelector } from 'react-redux';
import { RoomStudioViewer } from './RoomStudioViewer';
import { RoomStudioListner } from './RoomStudioListner';
import { CurrentObjectSetter } from './CurrentObjectSetter';
import { GroundEditor } from './Editors/GroundEditor';
import { AssetEditor } from './Editors/AssetEditor';
import { RoomContent } from './Room/Room';
import ClearBtn from '../UI/Composites/ClearBtn/ClearBtn';
import ViewModes from '../UI/Composites/ViewModes/ViewModes';
import { Spherical } from 'three';
import QuitModeBtn from '../UI/Composites/QuitModeBtn';
import BasicObject
    from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import ResourceErrorData from '../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ResourceErrorData';
import NBMeasureMarkerChangeTSEvent from '../../Libraries/Studios/RoomStudio/GameLogic/Notifier/TS_Events/NBMeasureMarkerChangeTSEvent';
import { RoomStudioMode } from '../../Libraries/Studios/RoomStudio/Domain/Modes/RoomStudioMode';
import { ResourceErrorHandler } from '../../../application3D-common/Components/ResourceErrorHandler/ResourceErrorHandler';
import DialogHelpMode from '../../../application3D-common/Components/Dialog';
import { ViewMode } from '../../../application3D-common/Librairies/Studios/Application3D/Domain/Cameras/ViewMode';
import ObjectEditor from '../../../application3D-common/Components/ObjectEditor/ObjectEditor';
import LoadingBar from "../UI/Composites/LoadingBar/LoadingBar";
import EntityMenu from "../UI/Composites/EntityMenu/EntityMenu";
import {RoomStudioState, updateRoomStudioMode} from "../../Redux/Reducers/room-studio/RoomStudioReducer";
import {closeAllPanel} from "../../../../utils/close-all-panel";
import {
    OpenFloorPaintPanel,
    OpenPaintPanel,
    OpenPrimitivePanel
} from "../../../../services/redux/reducers/panel/panel.slice";
import {addObjects, removeObject, updateObject} from "../../Redux/Reducers/room-studio/RoomContentReducer";
import {RootState} from "../../../../services/redux/root-reducers";
import ObjectDatas from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ObjectDatas";


export type RoomStudioProps = {
    setOpenPanel: (openPanel: boolean) => void;
    openPanel: boolean;
    setOpenEditPanel: (openEditPanel: boolean) => void;
    setOpenShapePanel: (OpenShapePanel: boolean) => void;
    openPrimitivePanel: boolean;
    setOpenPrimitivePanel: (openPrimitivePanel: boolean) => void;
    setBuildingElementPanel: (buildingElementPanel: boolean) => void;
    setAddProductPanel: (addProductPanel: boolean) => void;
    disable: boolean;
    currentObject: BasicObject | undefined;
    editedDatas: ObjectDatas | undefined;
    isVisible: boolean;
    cameraCoordinates?: Spherical;
    onLoadingFail?: (p_resourceErrorData: ResourceErrorData) => void;
};

const RoomStudio = (props: RoomStudioProps) => {
    const dispatch = useDispatch();
    const {
        roomStudioApp,
        roomStudioMode,
        currentEntity,
        currentViewMode,
    } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const [isClearSelectionVisible, setIsClearSelectionVisible] = useState<boolean>(false);
    const [isClearMeasureVisible, setIsClearMeasureVisible] = useState<boolean>(false);
    const [helpModal, setHelpModal] = useState<boolean>(true);
    const [closeModal, setCloseModal] = useState(false);

    const handleCloseHelpModal = () => {
        setHelpModal(!helpModal);
    };

    const handleClose360Modal = () => {
        setCloseModal(!closeModal);
    };

    useEffect(() => {
        if (currentViewMode === 2) {
            setCloseModal(true);
        }
    }, [currentViewMode]);

    useEffect(() => {
        closeAllPanel(dispatch);
    }, [currentEntity]);

    useEffect(() => {
        function handleEntitySelected() {
            if (roomStudioApp && roomStudioApp.Services.Selection.GetSelectedEntities().length > 1) {
                setIsClearSelectionVisible(!isClearSelectionVisible);
            }
        }

        window.addEventListener('application3d-onEntitySelected', handleEntitySelected);
        return () => {
            window.removeEventListener('application3d-onEntitySelected', handleEntitySelected);
        };
    }, [roomStudioApp]);

    useEffect(() => {
        function handleNBMeasureMarkerChanged(ev: CustomEvent<NBMeasureMarkerChangeTSEvent>) {
            if (ev.detail.NB === 0) {
                setIsClearMeasureVisible(false);
            } else {
                setIsClearMeasureVisible(true);
            }
        }

        window.addEventListener('application3d-nbMeasureMarkerChangeTSEvent', handleNBMeasureMarkerChanged);
        return () => {
            window.removeEventListener('application3d-nbMeasureMarkerChangeTSEvent', handleNBMeasureMarkerChanged);
        };
    }, [roomStudioApp]);

    useEffect(() => {
        if (currentEntity === undefined) {
            dispatch(OpenPrimitivePanel(false));
            dispatch(OpenFloorPaintPanel(false));
            dispatch(OpenPaintPanel(false));
        }
    }, [currentEntity]);

    useEffect(() => {
        if (roomStudioApp && roomStudioMode) {
            roomStudioApp?.ModesService.SetMode(RoomStudioMode[roomStudioMode]);
        }
    }, [roomStudioMode, roomStudioApp]);

    useEffect(() => {
        if (!props.cameraCoordinates) return;
        if (roomStudioApp && props.editedDatas && props.editedDatas.FirstRootObject) {
            let editedEntity = roomStudioApp.Services.RoomObjects.GetRelativeEntity(props.editedDatas.FirstRootObject.RefOfInstance);
            if (editedEntity) roomStudioApp.Services.Cameras.ApplyRelativeSphericalPosFromTarget(props.cameraCoordinates, editedEntity.Transform.GetObject());
            roomStudioApp.EngineService.Resize();
        }
    }, [props.cameraCoordinates]);

    const onQuitMeasuring = () => {
        dispatch(updateRoomStudioMode(RoomStudioMode.Furnishing));
        if (!helpModal) {
            setHelpModal(true);
        }
    };

    const handleQuitMultiSelectionMode = () => {
        dispatch(updateRoomStudioMode(RoomStudioMode.Furnishing));
        if (!helpModal) {
            setHelpModal(true);
        }
    };

    const handleMultiUnselectedAll = () => {
        if (roomStudioApp && roomStudioMode == RoomStudioMode.MultiSelection) {
            roomStudioApp.Services.Selection.UnSelectAll();
            setIsClearSelectionVisible(!isClearSelectionVisible);
        }
    };

    return (
        <>
            { roomStudioApp && (<RoomStudioListner app={ roomStudioApp } />) }
            { roomStudioApp && (<ResourceErrorHandler onLoadingFail={ props.onLoadingFail } />) }
            { props.isVisible && (
                <>
                    <RoomStudioViewer />
                    <CurrentObjectSetter
                        currentEntity={ currentEntity }
                        currentObject={ props.currentObject }
                    />

                    { roomStudioApp && roomStudioMode == RoomStudioMode.Measuring && (
                        <>
                            <QuitModeBtn label='QUITTER LE MODE MESURE' onClick={ onQuitMeasuring } />
                            { isClearMeasureVisible && (<ClearBtn label='Recommencer' onClick={ () => {
                                roomStudioApp.Services.MeasureDrawer.ClearAllMeasures();
                            } } />) }
                            <DialogHelpMode showingMode='measure' open={ helpModal }
                                            onClose={ handleCloseHelpModal } />
                        </>
                    ) }

                    { roomStudioApp && roomStudioMode == RoomStudioMode.MultiSelection && (
                        <>
                            <QuitModeBtn label='QUITTER LE MODE DEPLACER' onClick={ handleQuitMultiSelectionMode } />
                            { isClearSelectionVisible && (<ClearBtn label='Tout Désélectionner'
                                                                    onClick={ handleMultiUnselectedAll } />) }
                            <DialogHelpMode showingMode='selected'
                                            open={ helpModal }
                                            onClose={ handleCloseHelpModal } />
                        </>
                    ) }

                    { roomStudioApp && (
                        <>
                            <LoadingBar roomStudio={ roomStudioApp } />
                            { roomStudioMode && (roomStudioMode === RoomStudioMode.Furnishing || roomStudioMode === RoomStudioMode.Configuring) && currentViewMode !== ViewMode.FirstPerson && (
                                <TopBar roomStudio={ roomStudioApp } mode={ roomStudioMode }
                                        viewMode={ currentViewMode } />) }
                            { roomStudioMode && roomStudioMode !== RoomStudioMode.Configuring && (
                                <ViewModes
                                    cameraService={ roomStudioApp.Services.Cameras }
                                    roomStudioMode={ roomStudioMode } />
                            ) }
                        </>
                    ) }

                    { currentEntity && roomStudioMode && (roomStudioMode == RoomStudioMode.Furnishing || roomStudioMode == RoomStudioMode.Configuring)
                        && currentViewMode !== ViewMode.FirstPerson && (
                            <EntityMenu
                                openPanel={ props.openPanel }
                                setOpenPanel={ props.setOpenPanel }
                                setOpenEditPanel={ props.setOpenEditPanel }
                                currentEntity={ currentEntity }
                            />
                        ) }

                    { roomStudioMode && roomStudioMode === RoomStudioMode.Accelerometer &&
                        (
                            <DialogHelpMode showingMode='tablet'
                                            open={ closeModal }
                                            onClose={ handleClose360Modal } />
                        )
                    }

                    { roomStudioMode && roomStudioMode === RoomStudioMode.Furnishing && currentViewMode !== ViewMode.FirstPerson && (
                        <RightBar
                            setBuildingElementPanel={ props.setBuildingElementPanel }
                            setOpenPanel={ props.setOpenPanel }
                            setOpenShapePanel={ props.setOpenShapePanel }
                            disable={ props.disable }
                        />
                    ) }
                </>
            ) }


            {/* *** ROOM CONTENT **** */ }
            { roomStudioApp && (<RoomContent roomStudio={ roomStudioApp } />) }

            {/* *** EDITORS **** */ }
            { roomStudioApp && (<GroundEditor roomStudio={ roomStudioApp } />) }
            { roomStudioApp && (<AssetEditor roomStudio={ roomStudioApp } />) }
            { roomStudioApp && props.editedDatas && (
                <ObjectEditor
                    editor={ roomStudioApp.Services.RoomObjects }
                    editedDatas={ props.editedDatas }
                    onObjectEdited={ (p_lastDatas:ObjectDatas, p_newDatas:ObjectDatas) => {
                        if(p_lastDatas.FirstRootObject && p_lastDatas.FirstRootObject.Assembly && p_newDatas.FirstRootObject && p_newDatas.FirstRootObject.Assembly){
                            
                            let addedRef = p_newDatas.FirstRootObject.Assembly.SubObjectsRef.filter(x=>
                                !p_lastDatas.FirstRootObject?.Assembly.SubObjectsRef.includes(x));
                            let removedRef = p_lastDatas.FirstRootObject.Assembly.SubObjectsRef.filter(x=>
                                !p_newDatas.FirstRootObject?.Assembly.SubObjectsRef.includes(x));
                            let editedRef = p_lastDatas.FirstRootObject.Assembly.SubObjectsRef.filter(x=>
                                p_newDatas.FirstRootObject?.Assembly.SubObjectsRef.includes(x));
                            editedRef.push(p_newDatas.FirstRootObject.Ref);
                            
                            dispatch(updateObject(p_newDatas.BasicObjects.filter(x=>editedRef.includes(x.Ref))));
                            dispatch(addObjects(p_newDatas.GetBasicObjects(addedRef)));
                            dispatch(removeObject(removedRef));
                           
                        }
                    } }
                />
            ) }
        </>
    );
};

export default RoomStudio;
