const CREATE_CONFIGURED_PRODUCT = (coreProductReference: string, name: string) => `/v1/configured-products/${ coreProductReference }/${ name }/create`;


const GET_CONFIGURED_PRODUCT_TREE_LIST = (coreProductReference: string) => `/v1/configured-products/core-product/${ coreProductReference }/tree`;

const GET_CONFIGURED_PRODUCT_TREE = (coreProductReference: string) => `/v1/configured-products/core-product/${ coreProductReference }`;


const ADD_MULTIPLE_ATOMS_TO_CONFIGURED_PRODUCT = (productReference: string) => `/v1/configured-products/product-reference/${ productReference }/add-multiple-atoms`;

const GET_CONFIGURED_PRODUCT_TREE_BY_REFERENCE = (productReference: string) => `/v1/configured-products/product/${ productReference }/tree`;

const RENAME_CONFIGURED_PRODUCT = (productReference: string) => `/v1/configured-products/product-reference/${ productReference }/rename/{newName}`;

const UPLOAD_THUMBNAIL_URL = (productReference: string) => `/v1/configured-products/product-reference/${ productReference }/save-thumbnail`;

const DELETE_CONFIGURED_PRODUCT = (productReference: string) => `/v1/configured-products/product/${ productReference }`;

const ADD_METADATA = (productReference: string) => `/v1/configured-products/product-reference/${ productReference }/add-metadata`;
const GET_CONFIGURED_PRODUCT_SHEET = (productReference: string) => `/v1/configured-products/product/${ productReference }/configured-product-sheet`;

export {
    CREATE_CONFIGURED_PRODUCT,
    GET_CONFIGURED_PRODUCT_TREE_LIST,
    ADD_MULTIPLE_ATOMS_TO_CONFIGURED_PRODUCT,
    GET_CONFIGURED_PRODUCT_TREE_BY_REFERENCE,
    RENAME_CONFIGURED_PRODUCT,
    UPLOAD_THUMBNAIL_URL,
    ADD_METADATA,
    DELETE_CONFIGURED_PRODUCT,
    GET_CONFIGURED_PRODUCT_TREE,
    GET_CONFIGURED_PRODUCT_SHEET
};
