import * as React from 'react';
import { Button, styled, Box } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';


export type QuitModeBtnProps = {
    onClick: () => void;
    label: string;
};

const QuitModeBtn = ({ onClick, label }: QuitModeBtnProps) => {
    return (
        <>
            <Box sx={ {
                position: 'absolute', top: '5em', left: '45%',
            } }>
                <CustomQuitButton
                    variant='contained' color='secondary' onClick={ onClick }
                    startIcon={ <KeyboardArrowLeft /> }
                >{ label }</CustomQuitButton>
            </Box>
        </>
    );
};

const CustomQuitButton = styled(Button)(() => ({
        padding: '8px 20px',
        '& .MuiSvgIcon-root': {
            fontSize: 35,
        },
        '& .MuiButton-startIcon': {
            marginRight: '4px',
        },
    }
));

export default QuitModeBtn;