import IState from './IState';
import { IReaction, Reaction } from './Reaction';
import IEvent from './IEvent';

export default abstract class AState implements IState {
    protected m_reactions: Map<{ new (...args: any[]): IEvent }, Array<IReaction>>;

    public abstract Enter(): void;
    public abstract Exit(): void;

    public constructor(p_reactions: Map<{ new (...args: any[]): IEvent }, Array<IReaction>> | undefined = undefined) {
        if (p_reactions) this.m_reactions = p_reactions;
        else this.m_reactions = new Map<{ new (...args: any[]): IEvent }, Array<IReaction>>();
    }

    public ReactionsByEvent(): Readonly<Map<{ new (...args: any[]): IEvent }, Array<IReaction>>> {
        return this.m_reactions;
    }

    public AddReaction<T extends IEvent>(p_eventType: { new (...args: any[]): T }, p_reaction: Reaction<T>): void {
        if (p_eventType == null) throw new Error('NullReferenceException : p_eventType is null or undefined');
        if (p_reaction == null) throw new Error('NullReferenceException : p_reaction is null or undefined');

        if (!this.m_reactions.has(p_eventType)) {
            this.m_reactions.set(p_eventType, new Array<IReaction>());
        }
        this.m_reactions.get(p_eventType)!.push(p_reaction);
    }
}
