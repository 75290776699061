import State from './State';

export default class Transition {
    private m_from: State;

    private m_to: State;

    private m_behaviour: () => void;

    private m_condition: () => boolean;

    public constructor(
        p_from: State,
        p_to: State,
        p_behaviour: () => void = () => {},
        p_condition: () => boolean = () => {
            return true;
        }
    ) {
        if (p_from == null) throw new Error('NullReferenceException : p_from is null or undefined');
        if (p_to == null) throw new Error('NullReferenceException : p_from is null or undefined');

        this.m_from = p_from;
        this.m_to = p_to;
        this.m_behaviour = p_behaviour;
        this.m_condition = p_condition;
    }

    public get From(): State {
        return this.m_from;
    }

    public get To(): State {
        return this.m_to;
    }

    public Execute(): void {
        this.m_behaviour();
    }

    public IsConditionValid(): boolean {
        return this.m_condition();
    }
}
