import { updateProjectRoom } from '../services/redux/reducers/projects/project.slice';
import { BasketProduct, Room } from '../domain/domain';
import RoomStudioApp from '../application-3d/application3D-seller/Libraries/Studios/RoomStudio/RoomStudioApp';
import { Vector2 } from 'three';
import { postFileWithCallback } from './upload-file-method';

export const saveRoom = (uuid: string, projectId: string, content: any, basketProducts: BasketProduct[], snapshot: any, dispatch: any) => {
    if (uuid && projectId && content) {
        console.log('snapshot', snapshot);
        const productsInRoom = snapshot.plannerObjects.filter((el: any) => el.informations.type === 'Product');
        const diff = basketProducts.filter((basketProduct) => productsInRoom.some((field: any) => basketProduct.refOfInstance === field.refOfInstance));
        dispatch(
            updateProjectRoom({
                projectId: parseInt(projectId),
                roomUuid: uuid,
                room: {
                    ...content?.room,
                    roomUuid: uuid,
                    basketProducts: diff,
                    metadata: {
                        ...content.room.metadata,
                        data: { instances3D: snapshot.instances, plannerObjects: snapshot.plannerObjects },
                    },
                },
            })
        );
    }
};

const postUpdate = (room: Room, projectId: number, basketProducts: BasketProduct[], dispatch: any) => {
    dispatch(
        updateProjectRoom({
            projectId,
            roomUuid: room.roomUuid,
            room: {
                ...room,
                basketProducts,
            },
        })
    );
};

export const saveBasket = (room: Room, projectId: number, basketProducts: BasketProduct[], dispatch: any, refOfInstance: string, roomStudioApp?: RoomStudioApp) => {
    console.log('roomstudioapp', roomStudioApp);
    console.log('refOfInstance', refOfInstance);

    if (roomStudioApp && refOfInstance.length > 0) {
        roomStudioApp.Services.Screenshot.TakeScreenshotOfInstance(new Vector2(800, 450), refOfInstance).then((obj) => {
            const formData = new FormData();
            formData.append('file', obj);
            postFileWithCallback(
                'v1/documents/upload',
                formData,
                () => {},
                (res) => {
                    const filtered = basketProducts.filter((el) => el.refOfInstance !== refOfInstance);
                    let found = basketProducts.find((el) => el.refOfInstance === refOfInstance);
                    if (found) {
                        found = { ...found, basketProductScreenshot: res.data.content };
                        postUpdate(room, projectId, [...filtered, found], dispatch);
                        console.log(res);
                    }
                },
                undefined,
                undefined
            );
            console.log('obj', obj);
        });
    } else {
        postUpdate(room, projectId, basketProducts, dispatch);
    }
};
