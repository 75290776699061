import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import {Spherical} from "three";
import { ProductStudioMode } from '../../../Librairies/Studios/ProductStudio/Domain/Modes/ProductStudioMode';
import {PointOfViewPlacement} from "../../../Librairies/Studios/Application3D/Domain/Features3D/PointOfViews";
import ProductStudioApp from "../../../Librairies/Studios/ProductStudio/ProductStudioApp";
import BasicObject from "../../../Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import ObjectDatas from "../../../Librairies/Studios/Application3D/Domain/Objects/ObjectDatas";


export type ProductStudioState = {
    productStudioApp: ProductStudioApp | null;
    productStudioMode: ProductStudioMode;
    cameraPointOfViewSaving: Spherical | undefined
    currentPointOfView: PointOfViewPlacement | undefined,
    placements: Array<{ placement: PointOfViewPlacement, position: Spherical }>,
    selectedEntity: SceneEntity | undefined;
    selectedPart: BasicObject | undefined;
    selectedConnector: string|undefined;
    rootObject:ObjectDatas | undefined;
};

const initialProductStudioAppState: ProductStudioState = {
    productStudioApp: null,
    productStudioMode: ProductStudioMode.Configuring,
    cameraPointOfViewSaving: undefined,
    selectedEntity: undefined,
    selectedPart: undefined,
    selectedConnector: undefined,
    currentPointOfView: PointOfViewPlacement.THREE_QUARTER_OFF,
    placements: new Array<{ placement: PointOfViewPlacement, position: Spherical }>(),
    rootObject: undefined
};

const productStudioSlice = createSlice({
    name: 'productStudio',
    initialState: initialProductStudioAppState,
    //reducers : define how the state can be updated
    reducers: {
        setProductStudioApp: (state, action: PayloadAction<ProductStudioApp>) => {
            if (action.payload != null && state.productStudioApp == null) {
                state.productStudioApp = action.payload;
            }
        },
        updateProductStudioMode: (state, action: PayloadAction<ProductStudioMode>) => {
            if (action.payload) {
                state.productStudioMode = action.payload;
            }
        },
        saveCameraPointOfView:(state, action: PayloadAction<Spherical>) => {
            if (action.payload) {
                state.cameraPointOfViewSaving = action.payload;
            }
        },
        setSelectedEntity: (state, action: PayloadAction<{ entity: SceneEntity; isSelected: boolean }>) => {
            if (action.payload) {
                // Selection
                if (action.payload.isSelected) {
                    state.selectedEntity = action.payload.entity;
                }
                // Unselection
                else if (state.selectedEntity === action.payload.entity) {
                    state.selectedEntity = undefined;
                }
            }
        },
        setSelectedConnector: (state, action: PayloadAction<{ refOfConnector: string, isSelected: boolean }>) => {
            if (action.payload) {
                // Selection
                if (action.payload.isSelected) {
                    state.selectedConnector = action.payload.refOfConnector;
                }
                // Unselection
                else if (state.selectedConnector === action.payload.refOfConnector) {
                    state.selectedConnector = undefined;
                }
            }
        },
        setSelectedObject: (state, action: PayloadAction<BasicObject | undefined>) => {
            state.selectedPart = action.payload;
            state.selectedConnector = undefined;
        },
        SetRoot: (state, action: PayloadAction<ObjectDatas | undefined>) => {
            state.rootObject = action.payload;
        },
        clearPlacements: (state) => {
            state.placements.Clear();
        },
        setAllPointOfViewPlacements: (state, action: PayloadAction<{ placement: PointOfViewPlacement, position: Spherical }[]>) => {
            if (action.payload !== null) {
                state.placements = action.payload;
            }
        },
        setPointOfViewPlacement: (state, action: PayloadAction<PointOfViewPlacement>) => {
            if (action.payload !== null) {
                state.currentPointOfView = action.payload;
            }
        },
    },
});

export const { setProductStudioApp, setPointOfViewPlacement, SetRoot, setAllPointOfViewPlacements, clearPlacements, updateProductStudioMode, saveCameraPointOfView, setSelectedEntity,setSelectedConnector, setSelectedObject } = productStudioSlice.actions;
export default productStudioSlice;
