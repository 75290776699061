import './EngineViewer.scss';
import React, { useEffect, useState } from 'react';
import { IEngine } from "@lutithree/build/IEngine";

export type EngineViewerProps = {
    onDomNodeEnable : (p_dom: HTMLDivElement)=>void;
    onDomNodeUpdate : (p_dom: HTMLDivElement)=>void;
    engine: IEngine | null;
};

const EngineViewer = (props: EngineViewerProps) => {
    
    const [domNode, setDomNode] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        if(domNode !== null){
            if (props.engine !== null) {
                props.onDomNodeUpdate(domNode!);
            }
            else {
                props.onDomNodeEnable(domNode);
            }
            return () => {
                if (domNode && props.engine) {
                    domNode.removeChild(props.engine.GetCanvas());
                }
            };
        }
    }, [domNode, props.engine]);

    return (
        <>
            <div id="webplanner" ref={(ref) => setDomNode(ref)} />
        </>
    );
};

export { EngineViewer };
