import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../services/redux/root-reducers';
import { ReferentialSetByConfigurationState } from '../../../services/redux/reducers/configurateur-v2/configurateur-v2.slice';
import { BasketProduct, Colori, ConfigurationItemV2, MappingDTO, MdfSetV2, ModelTree, PartTree, PresetTree, ProductModel3d, ProjectProduct as Product, ZoneDTO } from '../../../domain/domain';
import InformationPanel from '../../panel/edit-panel/information-panel/information-panel';

import PresetPanel from '../../panel/edit-panel/preset-panel/preset-panel';
import './configurateur-vendeur.scss';
import ProductConfigurator from './configurator/product-configurator';
import { getCellValuesTree, getDefaultProductConfiguration, ProductCellValueTreeState } from '../../../services/redux/reducers/product/3DCellValues.reducers';
import ConfigurateurDetail from '../configurateur-detail/configurateur-detail';
import { executeRules } from '../../../services/redux/reducers/rules/rules.reducers';
import PopupInconsistentChoice from '../../../ui-components/modal/popup-inconsistent-choice/popup-inconsistent-choice';
import productService from '../../../api/service/product/product.services';
import cells3dValuesService from '../../../api/service/3DCellsValue/3dCellValue.service';
import { RoomStudioState } from '../../../application-3d/application3D-seller/Redux/Reducers/room-studio/RoomStudioReducer';
import Asset3D from '../../../application-3d/application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Asset3D';
import { setBasketProductConfiguration } from '../../../services/redux/reducers/basket-configuration/basket-configuration.slice';

type ConfigurateurVendeurProps = {
    setAtomRef: (atomRef: string) => void;
    openPanel: boolean;
    currentProduct: Product;
    setConfigurationWithAtom: () => void;
    basketProduct?: BasketProduct;
    saveConfiguration: () => void;
    setPossibleConfiguration: (possibleConfiguration: MdfSetV2[]) => void;
    setConfiguration: any;
    possibleConfiguration: MdfSetV2[];
    configuration: ConfigurationItemV2[];
    handleAssetUpdate: (atom: ModelTree) => void;
    preset: PresetTree | undefined;
    onMappingClick: (sectionSelected: PartTree, mapping: MappingDTO) => void;
    onColoriClick: (sectionSelected: PartTree, mapping: MappingDTO, zone: ZoneDTO, C: Colori) => void;
    onZoneClick: (sectionSelected: PartTree, mapping: MappingDTO, zone: ZoneDTO) => void;
};

export type CurrentMaterial = {
    part: {
        partRef: string;
        mappingId: number;
        zones: {
            zoneRef: string;
            defaultColorisUrl: string;
        }[];
    };
};
type Conf = { partItemRef: string; atomRef: string };
export type IClickAtom = {
    PTRef: string;
    atom: ModelTree;
};

const ConfigurateurVendeur = (props: ConfigurateurVendeurProps) => {
    //region api responses
    const referentialByConfig = useSelector<RootState, ReferentialSetByConfigurationState>((state) => state.referentialSetByConfigurationV2);
    const { editedDatas } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const getCellValuesTreeRes = useSelector<RootState, ProductCellValueTreeState>((state) => state.getCellValueTree);
    // endregion api responses

    const dispatch = useDispatch();

    //region states
    const [onclickModifed, setOnclickModified] = useState(false);
    const [, setOpenInfoPanel] = useState<boolean>(false);
    const [openPresetPanel, setOpenPresetPanel] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [, setPresetRef] = useState('');
    const [isMount, setIsMount] = useState(false);
    const [focusRefList, setFocusRefList] = useState<{ partRef: string; atomRef: string }[]>([]);
    const [currentAtom] = useState<ModelTree | undefined>(undefined);
    const [currentConfiguration, setCurrentConfiguration] = useState<ModelTree[]>([]);
    const [currentMaterial, setCurrentMaterial] = useState<CurrentMaterial[]>([]);

    const [selectedPart, setSelectedPart] = useState<PartTree>({
        name: '',
        characteristics: [],
        options: [],
        reference: '',
    });
    const [currentZoneConfiguration, setCurrentZoneConfiguration] = useState<ModelTree[]>([]);
    const [currentMaterialConfiguration, setCurrentMaterialConfiguration] = useState<ModelTree[]>([]);
    const [currentFinishConfiguration, setCurrentFinishConfiguration] = useState<ModelTree[]>([]);
    const [currentColoriConfiguration, setCurrentColoriConfiguration] = useState<ModelTree[]>([]);
    const [currentMappingConfiguration, setCurrentMappingConfiguration] = useState<ModelTree[]>([]);
    const [confList, setConfList] = useState<Conf[]>([]);
    const [tempConfList, setTempConfList] = useState<Conf[]>([]);
    const [confAtomRefList, setConfAtomRefList] = useState<string[]>([]);
    const [clickAtom, setClickAtom] = useState<IClickAtom | undefined>(undefined);
    const [atomAvailable, setAtomAvailable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [productObject, setProductObject] = useState<any | undefined>(undefined);
    const [, setModel3dObject] = useState<Asset3D | undefined>(undefined);
    const [partConfiguration, setPartConfiguration] = useState<ModelTree[]>([]);
    const [mappingTree, setMappingTree] = useState<MappingDTO[]>([]);

    //endregion states

    //region useEffect

    useEffect(() => {
        const customFocusedObject = editedDatas?.FirstRootObject as any;
        customFocusedObject && dispatch(getCellValuesTree(customFocusedObject.refOfProduct));

        if (props.preset) {
            dispatch(getCellValuesTree(props.preset.product.parentProductReference));
            dispatch(getDefaultProductConfiguration(props.preset.product.parentProductReference));
            if (props.preset.configuration.length !== 0) {
                const config = props.preset.configuration.filter((atom) => !atom.atom.reference.includes('>'));
                const mappingConfig = props.preset.mappingConfiguration.filter((atom) => atom.atom.reference.includes('mapping'));
                const zoneConfig = props.preset.mappingConfiguration.filter((atom) => atom.atom.reference.includes('zone'));
                const materialConfig = props.preset.mappingConfiguration.filter((atom) => atom.atom.reference.includes('material'));
                const finishConfig = props.preset.mappingConfiguration.filter((atom) => atom.atom.reference.includes('finish'));
                const coloriConfig = props.preset.mappingConfiguration.filter((atom) => atom.atom.reference.includes('colori'));
                setCurrentMappingConfiguration(mappingConfig);
                setCurrentConfiguration(config);
            }
        }
    }, []);

    useEffect(() => {
        const buildCurrentMaterial: CurrentMaterial[] = [];
        console.log('----------------- configurateur vendeur ', editedDatas?.FirstRootObject);
        editedDatas && editedDatas.FirstRootObject &&
        // @ts-ignore
        editedDatas.FirstRootObject.composition.partElements.map((PE: any, i: number) => {
            const findPart = buildCurrentMaterial.find((p) => p.part.partRef === PE.refOfPart && PE.type === 'MaterialAssignment');
            if (findPart === undefined) {
                PE.refOfMapping &&
                buildCurrentMaterial.push({
                    part: {
                        partRef: PE.refOfPart,
                        mappingId: PE.refOfMapping,
                        zones:
                            PE.type === 'MaterialAssignment' &&
                            PE.datas.map((z: any) => {
                                return { zoneRef: z.refOfZoning, defaultColorisUrl: z.urls[0] };
                            }),
                    },
                });
            }
        });
        setCurrentMaterial(buildCurrentMaterial);
    }, [editedDatas]);

    useEffect(() => {
        props.basketProduct && !isMount && setCurrentConfiguration(props.basketProduct.productContent.configuration);
    }, [props.basketProduct]);

    useEffect(() => {
        if (getCellValuesTreeRes.payload.content) {
            const config: ModelTree[] = [];
            getCellValuesTreeRes.payload.content.partTreeList.forEach((partTree) => {
                partTree.characteristics.forEach((partItemTree) => {
                    config.push(partItemTree.modelTrees[0]);
                });
            });
            if (props.preset?.configuration.length === 0) {
                setCurrentConfiguration(config);
            }
        }
    }, [getCellValuesTreeRes]);

    useEffect(() => {
        if (referentialByConfig.referentialSetByConfiguration.content !== null) {
            props.setPossibleConfiguration(referentialByConfig.referentialSetByConfiguration.content);
        }
    }, [referentialByConfig]);

    useEffect(() => {
        setPresetRef(props.currentProduct.ref);
    }, [props.currentProduct]);

    useEffect(() => {
        dispatch(setBasketProductConfiguration(currentConfiguration));
    }, [currentConfiguration]);

    // useEffect(() => {
    //     currentAtom && onAtomClick(currentAtom);
    // }, [currentAtom]);

    useEffect(() => {
        dispatch(
            executeRules({
                productReference: props.preset?.product.parentProductReference ?? '',
                configuration: [
                    ...currentConfiguration.map((el) => el.atom.reference),
                    ...currentMappingConfiguration.map((el) => el.atom.reference),
                    ...currentZoneConfiguration.map((el) => el.atom.reference),
                    ...currentMaterialConfiguration.map((el) => el.atom.reference),
                    ...currentFinishConfiguration.map((el) => el.atom.reference),
                    ...currentColoriConfiguration.map((el) => el.atom.reference),
                ],
            }),
        );

        let buildConfList: Conf[] = [];
        currentConfiguration.forEach((M: ModelTree) => {
            const f = buildConfList.find((c) => c.partItemRef === M.atom.partItemReference);
            if (!f) {
                buildConfList.push({
                    partItemRef: M.atom.partItemReference,
                    atomRef: M.atom.reference,
                });
            }
        });
        setConfList(buildConfList);
    }, [currentConfiguration, currentMappingConfiguration]);

    useEffect(() => {
        isMount && setTempConfList(confList);
        return () => setIsMount(true);
    }, [confList]);

    useEffect(() => {
        let t: string[] = [];
        tempConfList.forEach((te) => t.push(te.atomRef));
        setConfAtomRefList(t);
    }, [tempConfList]);

    //endregion

    //region methods
    const addToCurrentConfiguration = (value: ModelTree) => {
        const config = currentConfiguration;
        const newConfig = config.map((el) => {
            if (el.atom.partItemReference === value.atom.partItemReference) {
                return value;
            }
            return el;
        });
        setCurrentConfiguration(newConfig);
    };

    const onAtomClick = async (value: ModelTree): Promise<ModelTree> => {
        try {
            //addToCurrentConfiguration(value);
            const newConfig = currentConfiguration.map((el) => (el.atom.partItemReference === value.atom.partItemReference ? value : el));
            const partialConf = newConfig.filter((m) => m.atom.partReference === value.atom.partReference);

            let conf = partConfiguration;
            let T: Conf[] = [...tempConfList];

            const foundIndex = conf.findIndex((el) => value.atom.partItemReference === el.atom.partItemReference);

            if (foundIndex === -1) {
                conf.push(value);
            } else {
                const newarr = conf.map((item) => {
                    return item.atom.partItemReference === value.atom.partItemReference ? value : item;
                });
                conf = newarr;
            }
            setPartConfiguration(conf);

            await cells3dValuesService.getModel3dUrlForCombo(partialConf, setMappingTree).then((modelUrl) => {
                // const urlList = modelUrl.content.map((model: any) => model.modelUrl);
                const atomToAdd: ModelTree = {
                    ...value,
                    model3d: value.model3d.filter((m) => m.reference === modelUrl.content[0].reference),
                };
                props.handleAssetUpdate(atomToAdd);

                addToCurrentConfiguration(value);
            });
            T.forEach((e) => {
                if (e.partItemRef === value.atom.partItemReference) {
                    e.atomRef = value.atom.reference;
                }
            });
            setTempConfList(T);
        } catch (error) {
            console.error(error);
        }
        return value;
    };
    const onClickModifier = (Part: PartTree) => {
        setOnclickModified(!onclickModifed);
        setSelectedPart(Part);
    };
    const closePopup = () => {
        setAtomAvailable(false);
    };
    const updateConfigurations = (newConfigurations: ModelTree[]) => {
        const newConfig = currentConfiguration.map((el) => {
            const updatedConfig = newConfigurations.find((conf) => conf.atom.partItemReference === el.atom.partItemReference);
            return updatedConfig || el;
        });
        setCurrentConfiguration(newConfig);
    };
    const configurationSuggestion = async () => {
        if (clickAtom && props.preset) {
            setLoading(true);

            const res = await productService.getConfigurationByModel3dCellReference(props.preset.product.parentProductReference, clickAtom.atom.atom.reference);
            // @ts-ignore
            let newConfig: ModelTree[] = res.content.filter((atom: ModelTree) => !atom.atom.reference.includes('>'));

            let newList: ModelTree[][] = newConfig.reduce((acc: ModelTree[][], curr: ModelTree) => {
                // on cherche une liste existante qui a la même partReference que l'élément courant
                const matchingList = acc.find((list) => list[0].atom.partReference === curr.atom.partReference);
                if (matchingList) {
                    // si on trouve une liste existante, on ajoute l'élément courant à cette liste
                    matchingList.push(curr);
                } else {
                    // sinon, on crée une nouvelle liste avec l'élément courant et on l'ajoute à la liste de liste
                    acc.push([curr]);
                }
                return acc;
            }, []);
            const model3dPromise = newList.map(async (partialConf) => {
                const result = await cells3dValuesService.getModel3dUrlForCombo(partialConf, setMappingTree);
                return result;
            });

            Promise.all(model3dPromise).then((promiseAll) => {
                // @ts-ignore
                const productModel3dList: ProductModel3d[] = promiseAll.map((response) => response.content[0]);

                setProductObject((prevState: any) => {
                    if (prevState === undefined) {
                        alert('UNDEFINED');
                    }

                    const partElements = prevState.composition.partElements.filter((asset: any) => asset.type !== 'Model3d');
                    const assets = productModel3dList.map((model: ProductModel3d) => {
                        return {
                            dataModelVersion: '1.0.0',
                            datas: {
                                dataModelVersion: '1.0.0',
                                urls: [model.modelUrl],
                                hooks: clickAtom.atom.model3d[0].anchorPoints.length === 0 ? undefined : clickAtom.atom.model3d[0].anchorPoints,
                            },
                            options: [],
                            refOfPartItem: '',
                            refOfPart: model.partReference,
                            type: 'Model3d',
                        };
                    });

                    const state = {
                        ...prevState,
                        composition: {
                            ...prevState.composition,
                            partElements: [...partElements, ...assets],
                        },
                    };
                    return state;
                });
            });
            updateConfigurations(newConfig);
            let T: Conf[] = newConfig.map((m) => {
                return {
                    partItemRef: m.atom.partItemReference,
                    atomRef: m.atom.reference,
                };
            });
            setTempConfList(T);
        }
        closePopup();
        setLoading(false);
    };
    //endregion

    if (!onclickModifed) {
        return (
            <div className={props.openPanel ? 'ConfigurateurVendeur' : 'config-vendeur-close'}>
                {!open ? (
                    <>{currentConfiguration && <ProductConfigurator preset={props.preset && props.preset} onClickModifier={onClickModifier} selectedConfiguration={currentConfiguration} />}</>
                ) : !openPresetPanel ? (
                    <InformationPanel setOpenInfoPanel={setOpenInfoPanel} setOpen={setOpen} />
                ) : (
                    <PresetPanel setPossibleConfiguration={props.setPossibleConfiguration} setConfiguration={props.setConfiguration} setOpenPanel={setOpen} setOpenPresetPanel={setOpenPresetPanel} />
                )}
            </div>
        );
    } else {
        return (
            <>
                {atomAvailable && <PopupInconsistentChoice actionOnclick={configurationSuggestion} closeOnclick={closePopup} />}
                <ConfigurateurDetail
                    setMappingTree={setMappingTree}
                    mappingTree={mappingTree}
                    setIsMount={setIsMount}
                    isMount={isMount}
                    onAtomClick={onAtomClick}
                    onclickModifed={onclickModifed}
                    preset={props.preset}
                    sectionSelected={selectedPart}
                    setOnclickModified={setOnclickModified}
                    onColoriClick={props.onColoriClick}
                    onMappingClick={props.onMappingClick}
                    currentConfiguration={currentConfiguration}
                    getCellValuesTreeRes={getCellValuesTreeRes}
                    currentMaterial={currentMaterial}
                    confAtomRefList={confAtomRefList}
                    clickAtom={clickAtom}
                    setClickAtom={setClickAtom}
                    atomAvailable={atomAvailable}
                    setAtomAvailable={setAtomAvailable}
                />
            </>
        );
    }
};
export default ConfigurateurVendeur;
