import InstancesBuilder from './InstancesBuilder';
import { Sequence } from '@lutithree/build/Modules/Core/Sequence/Sequence';
import { ActionCmd } from '@lutithree/build/Modules/Core/Sequence/ActionCmd';
import { LoadingCmd } from '@lutithree/build/Modules/WebGL/Resources/Load/LoadingCmd';
import { Engine } from '@lutithree/build/Engine';
import BasicObject
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import Instance3D
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/Instance3D";
export default class RoomLoader {
    private m_engine: Engine;

    private m_roomContent: InstancesBuilder;

    private m_progressCallback: (p_title: string, p_url: string, p_progress: number) => void;

    public constructor(p_engine: Engine, p_instancesBuilder: InstancesBuilder) {
        if (p_instancesBuilder == null) throw new Error('NullReferenceException : p_instancesBuilder is null or undefined');
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');

        this.m_engine = p_engine;

        this.m_roomContent = p_instancesBuilder;
        this.m_progressCallback = (p_title: string, p_url: string, p_progress: number) => {};
    }

    public SetOnInstanciateCallback(p_callBack: (p_title: string, p_url: string, p_progress: number) => void): void {
        this.m_progressCallback = p_callBack;
    }

    public LaunchInstanciationSequence(p_basicObjects: BasicObject[], p_instances: Instance3D[]): Promise<void> {
        if (p_basicObjects == null) throw new Error('NullReferenceException : p_basicObjects is null or undefined');
        if (p_instances == null) throw new Error('NullReferenceException : p_instances is null or undefined');
        
        // Build sequence
        let loadingSequence = new Sequence();
        loadingSequence.OnProgressCallback = this.m_progressCallback;
        loadingSequence.AddCommand(new ActionCmd('Stop Loop', () => this.m_engine.Modules.LoopStrategy.Stop()), 0);

        // Instanciate room
        let groundObject = this.GetGround(p_basicObjects);
        let ceilingObject = this.GetCeiling(p_basicObjects);
        let wallObjects = this.GetWalls(p_basicObjects);
        if (groundObject && wallObjects) {
            loadingSequence.AddCommand(new LoadingCmd('Room', () => this.m_roomContent.InstanciateRoom(groundObject!, ceilingObject!, wallObjects, p_instances)), 0.2);
        }

        loadingSequence.AddCommand(new ActionCmd('Start Loop', () => this.m_engine.Start()), 0);

        // Instanciate product
        loadingSequence.AddCommand(new LoadingCmd('Products', () => this.m_roomContent.InstanciateRoomObjectType('Product', p_basicObjects, p_instances)), 0.2);

        // Instanciate BuildingBlocks
        loadingSequence.AddCommand(new LoadingCmd('BuildingBlocks', 
            () => this.m_roomContent.InstanciateRoomObjectType('BuildingBlock', p_basicObjects, p_instances)), 0.1);
        
        // Instanciate Openings
        loadingSequence.AddCommand(new LoadingCmd('Openings',
            () => this.m_roomContent.InstanciateRoomObjectType('Opening', p_basicObjects, p_instances)), 0.1);

        // Instanciate Accessories
        loadingSequence.AddCommand(new LoadingCmd('Accessories', () => this.m_roomContent.InstanciateRoomObjectType('Accessory', p_basicObjects, p_instances)), 0.2);

        // Render HQ
        loadingSequence.AddCommand(new ActionCmd('Render High Quality', () => Engine.TriggerLastFrameRendering()), 0.2);

        // Launch sequence
        return loadingSequence.LaunchSequenceAsync();
    }

    private GetGround(p_basicObjects: BasicObject[]): BasicObject | undefined {
        let result = p_basicObjects.filter((object) => object.Informations.Type === 'Ground');
        if (result.length > 0) return result[0];
        else return undefined;
    }

    private GetCeiling(p_basicObjects: BasicObject[]): BasicObject | undefined {
        let result = p_basicObjects.filter((object) => object.Informations.Type === 'Ceiling');
        if (result.length > 0) return result[0];
        else return undefined;
    }

    private GetWalls(p_basicObjects: BasicObject[]): BasicObject[] {
        return p_basicObjects.filter((object) => object.Informations.Type === 'Wall');
    }
}