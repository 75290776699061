import Connector from "./Connector";
import Connection from "./Connection";

export default class Connectivity {
    private dataModelVersion: string;
    
    private connectors: Connector[];

    private connections?: Connection[];
    
    public constructor() {
        this.dataModelVersion = '';
        this.connectors = [];
    }

    public get Connections(): Connection[]|undefined {
        return this.connections;
    }

    public set Connections(p_connections: Connection[]|undefined) {
        this.connections = p_connections;
    }

    public get Connectors(): Connector[] {
        return this.connectors;
    }

    public set Connectors(p_connectors: Connector[]) {
        this.connectors = p_connectors;
    }

    public Clone(): Connectivity {
        let connectivityCloned = new Connectivity();
        connectivityCloned.dataModelVersion = this.dataModelVersion;

        connectivityCloned.dataModelVersion = this.dataModelVersion;
        connectivityCloned.connectors = JSON.parse(JSON.stringify(this.connectors));
        if(this.connections) connectivityCloned.connections = JSON.parse(JSON.stringify(this.connections));

        return connectivityCloned;
    }
    
    public GetConnector(p_refOfConnector: string): Connector|undefined {
        if (!p_refOfConnector) throw new Error('NullReferenceException : p_refOfConnector is null or undefined or empty');
        
        for(let i=0; i<this.connectors.length; i++){
            if(this.connectors[i].RefOfConnector === p_refOfConnector) return this.connectors[i];
        }
        return undefined;
    }
    
    public GetConnectedConnector(p_refOfConnector: string): string|undefined {
        if (!p_refOfConnector) throw new Error('NullReferenceException : p_refOfConnector is null or undefined or empty');
        if(!this.connections) return undefined;

        for(let i=0; i<this.connections.length; i++){
            if(this.connections[i].RefOfConnectorA === p_refOfConnector) return this.connections[i].RefOfConnectorB;
            else if(this.connections[i].RefOfConnectorB === p_refOfConnector) return this.connections[i].RefOfConnectorA;
        }
        return undefined;
    }
}