import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Camera, Vector3 } from 'three';
import { MeshFilterComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent';
import { MeshLinksComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshLinksComponent';
import { MeshUtils } from '@lutithree/build/Modules/WebGL/Utils/MeshUtils';
import { LengthComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Helpers/LengthComponent';
import {
    ViewMode
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Cameras/ViewMode";
import {
    IEntityDecorator
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/IEntityDecorator";
import {
    DynamicScaleComponent
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Components/DynamicScaleComponent";
import {
    ViewModeFilter
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Cameras/Components/ViewModeFilter";


export class WallMeasureDecorator implements IEntityDecorator<SceneEntity> {
    private m_targetEntity: SceneEntity;

    private m_parentEntity: SceneEntity;
    
    private m_wallPaddining: number;
    
    private m_mainCam: Camera;

    public constructor(p_parentEntity: SceneEntity, p_targetEntity: SceneEntity, p_wallPaddining: number, p_cam: Camera) {
        if (p_targetEntity == null) throw new Error('NullReferenceException : p_targetEntity is null or undefined');
        if (p_parentEntity == null) throw new Error('NullReferenceException : p_parentEntity is null or undefined');

        this.m_targetEntity = p_targetEntity;
        this.m_parentEntity = p_parentEntity;
        this.m_wallPaddining = p_wallPaddining;
        this.m_mainCam = p_cam;
    }

    public Decorate(p_entity: SceneEntity): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        this.m_parentEntity.Transform.GetObject().add(p_entity.Transform.GetObject());

        // Adjust scale
        let worldScale = new Vector3();
        worldScale = p_entity.Transform.GetObject().getWorldScale(worldScale);
        
        p_entity.Transform.GetObject().scale.copy(new Vector3(1 / worldScale.x, 1 / worldScale.y, 1 / worldScale.z));

        // Behaviours
        let lengthComponent = p_entity.AddComponentOfType(LengthComponent, 1, 'cm', 0, new Vector3(0, 0.5, -this.m_wallPaddining));
        let scaleComponent = p_entity.AddComponentOfType(DynamicScaleComponent, lengthComponent.Stickers, this.m_mainCam);
        //lengthComponent.GetObject().scale.copy(lengthComponent.GetObject().worldToLocal(new Vector3(1, 1, 1)));

        let onGeometryScaledCallback = () => {};

        if (this.m_targetEntity.HasComponentOfType(MeshFilterComponent)) {
            let meshFilter = this.m_targetEntity.GetComponentOfType(MeshFilterComponent);
            let meshes = meshFilter.GetMeshes();
            let geometryWorldScale = MeshUtils.GetGeometryWorldScale(meshes[0]);
            lengthComponent.ApplyLenght(geometryWorldScale.x);
            lengthComponent.ApplyLabel((geometryWorldScale.x * 100).toFixed(0) + ' cm');
            scaleComponent.InitialScale = lengthComponent.Stickers[0].scale.clone();

            onGeometryScaledCallback = () => {
                let transformResult = MeshUtils.GetGeometryWorldTransform(meshes[0]);
                lengthComponent.ApplyLenght(transformResult.scale.x);
                lengthComponent.ApplyLabel((transformResult.scale.x * 100).toFixed(0) + ' cm');
                lengthComponent.ApplyPosition(transformResult.center);
                scaleComponent.InitialScale = lengthComponent.Stickers[0].scale.clone();
            };
        }

        if (this.m_targetEntity.HasComponentOfType(MeshLinksComponent)) {
            let meshLink = this.m_targetEntity.GetComponentOfType(MeshLinksComponent);
            meshLink.AddOnGeometryScaledCallback('measureFeature', onGeometryScaledCallback);
        }

        p_entity.AddComponentOfType(ViewModeFilter, [ViewMode.OrtographicTop]);
    }
}
