import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import { EnableScreenshotContextEvent } from "../Screenshot/Events/EnableScreenshotContextEvent";
import { AHandler } from "@lutithree/build/Handlers/AHandler";
import { Engine } from "@lutithree/build/Engine";
import { YControlableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/YControlableComponent";
import { Object3D } from "three";
import { RotControlableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/RotControlableComponent";
import SelectionService from "../Selection/SelectionService";
import { DimensionComponent } from "../Dimensions/Components/DimensionComponent";
import EntitySelectionStatusDirtyEvent from "../Selection/Events/EntitySelectionStatusDirtyEvent";
import { SelectableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SelectableComponent";

export default class ScreenshotHandler extends AHandler implements IHandler<EnableScreenshotContextEvent> {

    private m_selectionService: SelectionService|undefined;

    public constructor(p_engine: Engine, p_selectionService: SelectionService|undefined = undefined) {
        super(p_engine);

        this.m_selectionService = p_selectionService;
    }
    
    public Handle(p_event: EnableScreenshotContextEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');
            p_event.Enabled? this.ToggleScreenShotEffects(true, true) : this.ToggleScreenShotEffects(false, false);
    }

    private ToggleScreenShotEffects(p_activate: boolean, p_activateDimensions: boolean) {
        let axisTranslatables = this.m_engine.Modules.Scene.GetComponents(YControlableComponent, { entity: false, component: false });
        axisTranslatables.forEach((axisTranslatable) => {
            if (axisTranslatable.Axis.equals(Object3D.DEFAULT_UP)) {
                axisTranslatable.Enable(p_activate);
            }
        });

        let rotTranslatables = this.m_engine.Modules.Scene.GetComponents(RotControlableComponent, { entity: false, component: false });
        rotTranslatables.forEach((rotTranslatable) => {
            rotTranslatable.Enable(p_activate);
        });

        this.m_engine.Modules.Systems.CameraSystem.EnableCameraTargets(p_activate);

        if(this.m_selectionService){
            this.m_engine.Modules.Systems.SelectionSystem.GetSelectedEntities().forEach((selectedEntity) => {
                let selectable = selectedEntity.HasComponentOfType(SelectableComponent)?selectedEntity.GetComponentOfType(SelectableComponent):undefined;
                if(selectable){
                    selectable.IsSelectionVisible = p_activate;
                    this.m_engine.Modules.EventManager.Publish(
                        EntitySelectionStatusDirtyEvent,
                        new EntitySelectionStatusDirtyEvent(selectedEntity,selectedEntity.GetComponentOfType(SelectableComponent)));
                }
            });
        }
        
        let dimensionComponents = this.m_engine.Modules.Scene.GetComponents(DimensionComponent, { entity: false, component: false });
        dimensionComponents.forEach((dimensionComponent) => {
            dimensionComponent.Enable(p_activateDimensions);
        });
    }
}
 