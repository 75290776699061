import {useEffect} from "react";
import ResourceLoadingFailTSEvent
    from "../../Librairies/Studios/Application3D/GameLogic/Notifier/TSEvents/ResourceLoadingFailTSEvent";
import ResourceErrorData from "../../Librairies/Studios/Application3D/Domain/Objects/ResourceErrorData";

export type ResourceErrorHandlerProps = {
    onLoadingFail?: (p_resourceErrorData: ResourceErrorData)=>void;
};

const ResourceErrorHandler = (props: ResourceErrorHandlerProps) => {

    useEffect(() => {
        function handleAssetLoadingFailed(ev: CustomEvent<ResourceLoadingFailTSEvent>) {
            if(props.onLoadingFail) props.onLoadingFail(ev.detail.ResourceErrorData);
        }

        window.addEventListener('application3d-resourceLoadingFailed', handleAssetLoadingFailed);

        return () => {
            window.removeEventListener('application3d-resourceLoadingFailed', handleAssetLoadingFailed);
        };
    }, []);
    
    return (<></>);
};

export { ResourceErrorHandler };