import AState from './AState';

export default abstract class AContextualBehaviours<T> extends AState {
    protected m_context: T;

    public constructor(p_context: T) {
        super();
        if (p_context == null) throw new Error('NullReferenceException : p_context is null or undefined');

        this.m_context = p_context;
    }
}
