export default class Connector {
    
    private refOfInstance: string;
    
    private role: string;

    private isAvailable: boolean;

    public constructor() {
        this.refOfInstance = '';
        this.role = 'LEFT';
        this.isAvailable = true;
    }

    public set RefOfInstance(p_value: string){
        this.refOfInstance = p_value;
    }
    
    public get RefOfInstance(): string {
        return this.refOfInstance;
    }

    public get RefOfConnector(): string {
        return this.refOfInstance+'_'+this.role;
    }

    public get Role(): string {
        return this.role;
    }
    
    set Role(value: string) {
        this.role = value;
    }

    public get IsAvailable(): boolean {
        return this.isAvailable;
    }
    
    set IsAvailable(value: boolean) {
        this.isAvailable = value;
    }
} 