import { Engine } from '@lutithree/build/Engine';
import {Object3D, Vector3} from 'three';
import RoomStudioServices from '../RoomStudioServices';
import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import { IEvent } from "@lutithree/build/Modules/Core/Event/IEvent";
import { ViewMode } from '../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Cameras/ViewMode';
import AContextualBehaviours from "../../../../../../application3D-common/Librairies/FSM/AContextualBehaviours";
import ObjectLoadedEvent
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/Events/ObjectLoadedEvent";
import InfoComponent
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/InfoComponent";

export default class ConfiguringBehaviours extends AContextualBehaviours<{ Engine: Engine; Services: RoomStudioServices; Handlers: ReadonlyMap<string, IHandler<IEvent>> }> {
    private m_cameraPositionWhenEnter: Vector3;
    
    private m_cameraTargetPositionWhenEnter: Vector3;

    private m_camera3dMinPolarAngle: number|undefined;
    
    public constructor(p_engine: Engine, p_services: RoomStudioServices, p_handlers: ReadonlyMap<string, IHandler<IEvent>>) {
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');
        if (p_services == null) throw new Error('NullReferenceException : p_services is null or undefined');
        super({ Engine: p_engine, Services: p_services, Handlers:p_handlers });

        this.m_cameraPositionWhenEnter = new Vector3(0, 0, 0);
        this.m_cameraTargetPositionWhenEnter = new Vector3(0, 0, 0);
        this.m_camera3dMinPolarAngle = undefined;
    }

    public Enter(): void {
        console.log('ConfiguringBehaviours Enter!');
        this.m_context.Services.Cameras.SetViewMode(ViewMode.ThirdPerson);

        let mainCam = this.m_context.Engine.Modules.Systems.CameraSystem.GetMainCameraComponent();
        let cameraControl = this.m_context.Engine.Modules.Systems.CameraSystem.GetControlForCamera(mainCam);
        let target = cameraControl?.Target; 
        this.m_cameraPositionWhenEnter.copy(mainCam.GetObject().position);
        if(target) this.m_cameraTargetPositionWhenEnter.copy(target);
        if(cameraControl){
            this.m_camera3dMinPolarAngle = cameraControl.GetControl().minPolarAngle;
            cameraControl.GetControl().minPolarAngle = 0;
        }
        
        this.m_context.Services.Selection.EnableDeepSelectionFeature(true);
        
        this.m_context.Services.Cameras.EnablePan(false);
        this.FocusCameraOnSelection();
        
        let entities = this.m_context.Services.Selection.GetSelectedEntities();

        let objectLoadedInFurnishingHandler = this.m_context.Handlers.get('objectLoadedInFurnishingHandler');
        if(objectLoadedInFurnishingHandler){
            this.m_context.Engine.Modules.EventManager.UnSuscribe(ObjectLoadedEvent, objectLoadedInFurnishingHandler);
        } 

        let objectLoadedInConfiguringHandler = this.m_context.Handlers.get('objectLoadedInConfiguringHandler');
        if(objectLoadedInConfiguringHandler){
            this.m_context.Engine.Modules.EventManager.Suscribe(ObjectLoadedEvent, objectLoadedInConfiguringHandler);
        }

        entities.forEach( entity =>{
            let infoComponent = entity.HasComponentOfType(InfoComponent)?entity.GetComponentOfType(InfoComponent):undefined;
            if(infoComponent && infoComponent.Info.PointOfView){
                this.m_context.Services.Cameras.ApplyRelativeSphericalPosFromTarget(infoComponent.Info.PointOfView, entity.Transform.GetObject());
            }
        });

        this.m_context.Engine.Modules.LoopStrategy.RequestRender(true);
    }

    public Exit(): void {
        console.log('ConfiguringBehaviours Exit!');
        this.m_context.Services.Selection.EnableDeepSelectionFeature(false);
        
        this.m_context.Services.Cameras.EnablePan(true);
        let mainCam = this.m_context.Engine.Modules.Systems.CameraSystem.GetMainCameraComponent();
        let cameraControl = this.m_context.Engine.Modules.Systems.CameraSystem.GetControlForCamera(mainCam);
        mainCam.GetObject().position.copy(this.m_cameraPositionWhenEnter);
        this.m_context.Engine.Modules.Systems.CameraSystem.GetControlForCamera(mainCam)?.Target.copy(this.m_cameraTargetPositionWhenEnter);
        if(cameraControl && this.m_camera3dMinPolarAngle) cameraControl.GetControl().minPolarAngle = this.m_camera3dMinPolarAngle;

        let objectLoadedInConfiguringHandler = this.m_context.Handlers.get('objectLoadedInConfiguringHandler');
        if(objectLoadedInConfiguringHandler){
            this.m_context.Engine.Modules.EventManager.UnSuscribe(ObjectLoadedEvent, objectLoadedInConfiguringHandler);
        }

        let objectLoadedInFurnishingHandler = this.m_context.Handlers.get('objectLoadedInFurnishingHandler');
        if(objectLoadedInFurnishingHandler){
            this.m_context.Engine.Modules.EventManager.Suscribe(ObjectLoadedEvent, objectLoadedInFurnishingHandler);
        }
        
        this.m_context.Engine.Modules.LoopStrategy.RequestRender(true);
    }

    private FocusCameraOnSelection(): void {
        let selectedEntities = this.m_context.Engine.Modules.Systems.SelectionSystem.GetSelectedEntities();
        let objects: Object3D[] = [];
        selectedEntities.forEach((entity) => {
            objects.push(entity.Transform.GetObject());
        });
        this.m_context.Services.Cameras.FocusPerspectiveCameraOnObjects(objects, 1.1);
    }
}
