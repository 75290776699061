import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Box3, Quaternion, Vector3 } from 'three';
import { GroupSystem } from '@lutithree/build/Modules/WebGL/Scene/Systems/GroupSystem';
import { ASystem } from '@lutithree/build/Modules/Core/Entity/ASystem';
import { EntityManager } from '@lutithree/build/Modules/Core/Entity/EntityManager';
import { ScalableComponent } from '../Components/ScalableComponent';

export default class ScaleSystem extends ASystem<SceneEntity> {
    private m_groupSystem: GroupSystem;

    constructor(p_scene: EntityManager<SceneEntity>) {
        super(p_scene);

        this.m_groupSystem = new GroupSystem(p_scene);
    }

    public SetWorldScale(p_entity: SceneEntity, p_newScale: Vector3, p_box3Scale: Vector3): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_newScale == null) throw new Error('NullReferenceException : p_newScale is null or undefined');
        if (p_box3Scale == null) throw new Error('NullReferenceException : p_box3Scale is null or undefined');
        if (!p_entity.HasComponentOfType(ScalableComponent)) throw new Error('Entity is not scalable');
        
        let entityScale = new Vector3();
        p_entity.Transform.GetObject().getWorldScale(entityScale);
        if (entityScale.x < 0) entityScale.x = -entityScale.x;
        if (entityScale.y < 0) entityScale.y = -entityScale.y;
        if (entityScale.z < 0) entityScale.z = -entityScale.z;

        let scale = new Vector3().multiplyVectors(p_newScale.clone(), entityScale.clone()).divide(p_box3Scale.clone());

        p_entity.Transform.GetObject().scale.set(scale.x, scale.y, scale.z);
        p_entity.GetComponentOfType(ScalableComponent).OnObject3DScaled();
    }

    public GetWorldScale(p_entity: SceneEntity): Vector3 {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        let targetToScale = p_entity;
        let targets = this.GetTargetToScale(p_entity);
        //TODO only first target is scaled
        if (targets.length > 0) targetToScale = targets[0];

        let worldPosition = new Vector3();
        targetToScale.Transform.GetObject().getWorldPosition(worldPosition);

        let localQuaternion;
        localQuaternion = targetToScale.Transform.GetObject().quaternion.clone();

        var invertedFlag = Math.sign(p_entity.Transform.GetObject().scale.x) < 0;
        let scale = p_entity.Transform.GetObject().scale.clone();
        
        let worldQuaternion = new Quaternion();
        targetToScale.Transform.GetObject().getWorldQuaternion(worldQuaternion);

        if(invertedFlag) p_entity.Transform.GetObject().scale.set(-scale.x, scale.y, scale.z);
        targetToScale.Transform.GetObject().applyQuaternion(invertedFlag ? worldQuaternion.clone() : worldQuaternion.clone().invert());
        let bbox = new Box3().setFromObject(targetToScale.Transform.GetObject());
        if(invertedFlag) p_entity.Transform.GetObject().scale.set(scale.x, scale.y, scale.z);
        targetToScale.Transform.GetObject().setRotationFromQuaternion(localQuaternion);

        let min = bbox!.min.clone().sub(worldPosition.clone());
        let max = bbox!.max.clone().sub(worldPosition.clone());

        
        let width = max.x - min.x;
        let height = max.y - min.y;
        let depth = max.z - min.z;
        
        return new Vector3(Math.abs(width), Math.abs(height), Math.abs(depth));
    }

    public GetTargetToScale(p_entity: SceneEntity): SceneEntity[] {
        let targetEntities: SceneEntity[] = [];

        // Get target to scale
        let scalablesEntities: SceneEntity[] = this.m_groupSystem.GetGroupEntitiesWith(p_entity, ScalableComponent);
        targetEntities = targetEntities.concat(scalablesEntities);
        if (p_entity.HasComponentOfType(ScalableComponent)) {
            targetEntities.push(p_entity);
        }

        return targetEntities;
    }
}