import Transform from '@lutithree/build/Modules/WebGL/Scene/DataModel/Transform';
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { Object3DUtils } from "@lutithree/build/Modules/WebGL/Utils/Object3DUtils";

export default class Instance3D {
    private dataModelVersion: string = '';

    private ref: string = '';

    private transform: Transform;

    public constructor(p_ref?: string, p_transform?: Transform, p_dataModelVersion?: string){
        if (!p_ref) this.ref = '';
        else this.ref = p_ref;
        
        if (p_transform == null) this.transform = new Transform();
        else this.transform = p_transform;
        
        if (!p_dataModelVersion) this.dataModelVersion = '1.0.0';
        else this.dataModelVersion = p_dataModelVersion;
    }
    
    public get Ref(): string {
        return this.ref;
    }

    public get Transform(): Transform {
        return this.transform;
    }

    public static GenerateInstance3D(p_entity: SceneEntity, p_ref: string, p_dataModelVersion: string): Instance3D {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (!p_ref) throw new Error('NullReferenceException : p_ref is null or undefined or empty');
        if (!p_dataModelVersion) throw new Error('NullReferenceException : p_dataModelVersion is null or undefined or empty');

        return new Instance3D(p_ref,Object3DUtils.GetTransform(p_entity.Transform.GetObject()), p_dataModelVersion);
    }
}
