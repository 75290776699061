import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import trash from '../../../../../application3D-common/Assets/Icons/ContextualMenu/red-trash.svg';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import IconButton from '@mui/material/IconButton';
import ObjectComponent
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";
import {RoomStudioState} from "../../../../Redux/Reducers/room-studio/RoomStudioReducer";
import {removeObject} from "../../../../Redux/Reducers/room-studio/RoomContentReducer";
import {RootState} from "../../../../../../services/redux/root-reducers";


export type DeleteButtonProps = {
    entity: SceneEntity | undefined;
};

const DeleteButton = (props: DeleteButtonProps) => {
    const dispatch = useDispatch();

    const { roomStudioApp } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);

    const removeEntity = (p_entity: SceneEntity) => {
        if (roomStudioApp) {
            roomStudioApp.Services.Room.RemoveObject(p_entity);
        }
    };

    const handleClick = () => {
        if (props.entity) {
            if (props.entity.HasComponentOfType(ObjectComponent)) {
                let roomObjectComponent = props.entity.GetComponentOfType(ObjectComponent);
                dispatch(removeObject([roomObjectComponent.Ref]));
            } else {
                removeEntity(props.entity);
            }
        }
    };

    return (
        <>
            <IconButton color='primary' aria-label='delete-icone'
                        onClick={ handleClick }
                        size='small'>
                <img src={ trash } alt='trash' />
            </IconButton>
        </>
    );
};

export default DeleteButton;
