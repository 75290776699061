import { AComponentHandler } from '@lutithree/build/Modules/WebGL/Scene/ComponentHandlers/AComponentHandler';
import { IComponentHandler } from '@lutithree/build/Modules/Core/Entity/IComponentHandler';
import { ViewModeFilter } from '../Components/ViewModeFilter';
import { ViewModeCameraComponent } from '../Components/ViewModeCameraComponent';

export class ViewModeFilterEnabler extends AComponentHandler implements IComponentHandler<ViewModeFilter> {
    public Handle(p_component: ViewModeFilter): void {
        if (p_component.IsEnabled) {
            let mainCameraComponent = this.m_systems.CameraSystem.GetMainCameraComponent();
            if (mainCameraComponent && mainCameraComponent instanceof ViewModeCameraComponent) {
                let enable = p_component.IsAvailableForViewMode(mainCameraComponent.ViewMode);
                let entity = this.m_scene.GetEntityByID(p_component.EntityID);
                if (entity.IsEnabled != enable) {
                    entity.Enable(enable);
                }
            }
        }
    }
}
