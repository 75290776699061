import React, { useEffect, useState } from 'react';
import ObjectService from "../../Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/ObjectService";
import BasicObject from "../../Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import {Euler, Spherical, Vector3} from "three";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import Information from "../../Librairies/Studios/Application3D/Domain/Objects/Information";
import { DimensionComponent } from "../../Librairies/Studios/Application3D/GameLogic/Features3D/Dimensions/Components/DimensionComponent";
import { BoundingBoxComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundingBoxComponent";
import { Object3DUtils as Seller3DUtils } from "../../Librairies/Studios/Application3D/Utils/Object3DUtils";
import CamerasService from "../../Librairies/Studios/Application3D/GameLogic/Studio/Cameras/CamerasService";
import ObjectDatas from "../../Librairies/Studios/Application3D/Domain/Objects/ObjectDatas";
import IObjectDataAccess from "../../Librairies/Studios/Application3D/Domain/Objects/IObjectDataAccess";

export type SingleObjectLoaderProps = {
    dataAccess: IObjectDataAccess;
    objectEditor : ObjectService;
    cameraService: CamerasService;
    datas: ObjectDatas;
    product: BasicObject;
    onProductLoaded?: ()=>void;
    cameraPointOfView?: Spherical
    objectPosition?: Vector3;
    objectRotation?: Euler;
    mainEntity: SceneEntity | undefined;
    setMainEntity: (p_entity: SceneEntity | undefined)=>void;
};


const SingleObjectLoader = (props: SingleObjectLoaderProps) => {

    const [object, setObject] = useState<BasicObject>(props.product);
    
    const onEntityOfObjectLoaded = (p_object: BasicObject, p_entity: SceneEntity) =>{
        let entity = props.objectEditor.GetRelativeEntity(p_object.RefOfInstance);
        props.setMainEntity(entity);
        let boundingBoxComponent = p_entity.HasComponentOfType(BoundingBoxComponent)?p_entity.GetComponentOfType(BoundingBoxComponent):undefined;
        if (boundingBoxComponent) {
            boundingBoxComponent.ComputeBoundingBox(p_entity.Transform.GetObject());
            Seller3DUtils.SetPivotAtObjectBBCenter(boundingBoxComponent.Center, p_entity.Transform.GetObject());
        }
        if(entity) props.cameraService.FocusPerspectiveCameraOnObjects([entity.Transform.GetObject()], 1.1);
        if (props.onProductLoaded) props.onProductLoaded();
    };
    
    const initSingleObjectLoader = (p_object: BasicObject) => {

        // clear unused objects
        let objectRefs = props.objectEditor.GetObjectRefs();
        let currentObjectRefs = props.objectEditor.GetObjectNestedRefs(p_object.RefOfInstance);
        currentObjectRefs.push(p_object.RefOfInstance);
        objectRefs.forEach((ref)=>{
            if(!currentObjectRefs.includes(ref)) props.objectEditor.RemoveObject(ref);
        });
        
        // load object
        let entity = props.objectEditor.GetRelativeEntity(p_object.RefOfInstance);
        if(!entity){
            let promise = props.objectEditor.LoadRootObjectAsync(p_object.Ref,p_object);
            promise.then((result)=>{
                onEntityOfObjectLoaded(p_object, result[0].entity);
                if(props.objectRotation) result[0].entity.Transform.SetRotation(props.objectRotation);
                if(props.objectPosition) result[0].entity.Transform.SetPosition(props.objectPosition);
                if(props.objectPosition) props.cameraService.SetCameraTargetAtCenter([result[0].entity.Transform.GetObject()]);
                if(props.cameraPointOfView) props.cameraService.ApplyRelativeSphericalPosFromTarget(props.cameraPointOfView, result[0].entity.Transform.GetObject());
                else if(props.product.PointOfView) props.cameraService.ApplyRelativeSphericalPosFromTarget(props.product.PointOfView, result[0].entity.Transform.GetObject());
            });
        }
        else{
            props.cameraService.FocusPerspectiveCameraOnObjects([entity.Transform.GetObject()],1.1);
        }
    };

    const updateObject = (p_object: BasicObject) => {
        if (p_object.Ref !== object.RefOfInstance){
            props.objectEditor.RemoveObject(object.RefOfInstance);
            let promise = props.objectEditor.LoadRootObjectAsync(p_object.Ref, p_object);
            promise.then((result) => {
                onEntityOfObjectLoaded(p_object, result[0].entity);
            });
            setObject(p_object);
        }
    };

    useEffect(()=>{
        if (props.objectPosition == null || props.mainEntity == null) return;
        props.mainEntity.Transform.SetPosition(props.objectPosition);
        props.cameraService.SetCameraTargetAtCenter([props.mainEntity.Transform.GetObject()]);
    }, [props.objectPosition, props.mainEntity]);
    
    useEffect(()=>{
        if (props.objectRotation == null || props.mainEntity == null) return;
        props.mainEntity.Transform.SetRotation(props.objectRotation);
    }, [props.objectRotation, props.mainEntity]);
    
    useEffect(() => {
        props.dataAccess.SetDatas(props.datas.Instances3D, props.datas.BasicObjects);
        initSingleObjectLoader(props.product);
        return () => {
            // clear entities relatives to current product
            let currentObjectRefs = props.objectEditor.GetObjectNestedRefs(props.product.RefOfInstance);
            currentObjectRefs.push(props.product.RefOfInstance);
            currentObjectRefs.forEach((ref)=>{
                props.objectEditor.RemoveObject(ref);
            });
        };
    }, []);

    useEffect(() => {
        updateObject(props.product);
    }, [props.product]);

    return (
        <></>
    );
};

export default SingleObjectLoader;
