import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import ARoomStudioHandler from "../../ARoomStudioHandler";
import NBMeasureMarkerChangedEvent
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Measuring/Events/NBMeasureMarkerChangedEvent";

export class MesureMarkerHandler extends ARoomStudioHandler implements IHandler<NBMeasureMarkerChangedEvent> {

    public Handle(p_event: NBMeasureMarkerChangedEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');

        this.m_services.Notifier.NotifyNBMesureMarkerChanged(p_event.NBMeasureMarkers);
    }
}