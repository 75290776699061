import BasicObject from "../Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";

export const getBasicObjectFromList: (p_refOfInstance: string, p_list: BasicObject[]) => BasicObject| undefined = function (p_refOfInstance: string, p_list: BasicObject[]) {
    for (let i = 0; i < p_list.length; i++) {
        if (p_list[i].RefOfInstance === p_refOfInstance) {
            return p_list[i];
        }
        else if((p_list[i].Composition.Type === 'BasicObject' || p_list[i].Composition.Type === 'RoomObject') && p_list[i].Composition.PartElements.length > 0){
            let result =  getBasicObjectFromList(p_refOfInstance, p_list[i].Composition.PartElements as BasicObject[]);
            if(result) return result;
        }
    }
    return undefined;
};

export const getRoomObjectFromList: (p_refOfInstance: string, p_list: BasicObject[]) => BasicObject| undefined = function (p_refOfInstance: string, p_list: BasicObject[]) {
    for (let i = 0; i < p_list.length; i++) {
        if (p_list[i].RefOfInstance === p_refOfInstance) {
            return p_list[i];
        }
        else if((p_list[i].Composition.Type === 'BasicObject' || p_list[i].Composition.Type === 'RoomObject') && p_list[i].Composition.PartElements.length > 0){
            let result =  getRoomObjectFromList(p_refOfInstance, p_list[i].Composition.PartElements as BasicObject[]);
            if(result) return result;
        }
    }
    return undefined;
};