import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MdfApiResponse from '../../../../domain/common/generic';
import { Configuration, ConfigurationClass, ConfigurationResponse, MdfSet } from '../../../../domain/domain';
import ConfigurateurService from '../../../../api/service/configurateur-service/configurateur-service';

export const getConfigurationSectionList = createAsyncThunk('tableConcept/sectionlist', async (thunkAPI) => {
    const response = await ConfigurateurService.getConfigurateurCollection();
    return response;
});

export type ConfigurateurState = {
    isLoading: boolean;
    payload: MdfApiResponse<ConfigurationClass>;
};
const initialState: ConfigurateurState = {
    isLoading: false,
    payload: {
        content: { sections: [], referentialSets: [] },
        errors: [],
        warnings: [],
    },
};
export const getConfigurateurSlice = createSlice({
    name: 'getConfigurateurSlice',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getConfigurationSectionList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getConfigurationSectionList.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload: payload, isLoading: false };
            return state;
        });
        builder.addCase(getConfigurationSectionList.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

export const getReferentialSet = createAsyncThunk('tableConcept/referentialSet', async (thunkAPI) => {
    const response = await ConfigurateurService.getReferentielSet();
    return response;
});

export type ReferentialSetState = {
    isLoading: boolean;
    referentialSet: MdfApiResponse<MdfSet[] | null>;
};
const initialGetReferentialSetState: ReferentialSetState = {
    isLoading: false,
    referentialSet: {
        /*{ref: '', name:'', setType:"SET_WITH_IMG", items:[]}*/ content: null,
        errors: [],
        warnings: [],
    },
};
export const getReferentialSetSlice = createSlice({
    name: 'getReferentialSetSlice',
    initialState: initialGetReferentialSetState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getReferentialSet.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getReferentialSet.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { referentialSet: payload, isLoading: false };
            return state;
        });
        builder.addCase(getReferentialSet.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

//===========
type GetReferentialSetByConfigurationForm = {
    form: Configuration;
};

export const getReferentialSetByConfiguration = createAsyncThunk('tableConcept/referentialSetByConfiguration', async (data: GetReferentialSetByConfigurationForm, thunkAPI) => {
    const response = await ConfigurateurService.getReferentialSetByConfiguration(data.form);
    return response;
});

export type ReferentialSetByConfigurationState = {
    isLoading: boolean;
    referentialSetByConfiguration: MdfApiResponse<MdfSet[] | null>;
};
const initialGetReferentialSetByConfigurationState: ReferentialSetByConfigurationState = {
    isLoading: false,
    referentialSetByConfiguration: {
        content: null,
        errors: [],
        warnings: [],
    },
};
export const getReferentialSetByConfigurationSlice = createSlice({
    name: 'getReferentialSetByConfigurationSlice',
    initialState: initialGetReferentialSetByConfigurationState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getReferentialSetByConfiguration.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getReferentialSetByConfiguration.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { referentialSetByConfiguration: payload, isLoading: false };
            return state;
        });
        builder.addCase(getReferentialSetByConfiguration.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

// DEFAULT CONFIGURATION

export type DefaultConfigurationState = {
    isLoading: boolean;
    payload: MdfApiResponse<ConfigurationResponse>;
};

const defaultConfigurationInitialState: DefaultConfigurationState = {
    isLoading: false,
    payload: {
        content: { configuration: { items: [], version: 0, id: 0 }, referentialSelectionnableSets: [] },
        errors: [],
        warnings: [],
    },
};

export const getDefaultConfiguration = createAsyncThunk('tableConcept/defaultConfiguration', async (thunkAPI) => {
    const response = await ConfigurateurService.getDefaultConfiguration();
    return response;
});

export const getDefaultConfigurationSlice = createSlice({
    name: 'getDefaultConfiguration',
    initialState: defaultConfigurationInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDefaultConfiguration.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getDefaultConfiguration.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload: payload, isLoading: false };
            return state;
        });
        builder.addCase(getDefaultConfiguration.rejected, (state, errors) => {
            state.isLoading = false;
        });
    },
});

// END DEFAULT CONFIGURATION
