import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Vector3 } from 'three';
import { Engine } from '@lutithree/build/Engine';
import StructureInstanciatedEvent from "../Objects/Events/StructureInstanciatedEvent";
import ObjectAddedToRoomEvent from "../Objects/Events/ObjectAddedToRoomEvent";
import IObjectLoader
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/IObjectLoader";
import BasicObject
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import Instance3D
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/Instance3D";
import ShapeData
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/ShapeData";
import ObjectComponent
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";


export default class InstancesBuilder {
    private m_engine: Engine;

    private m_roomObjectLoader: IObjectLoader;
    
    private m_rootEntities: Map<string, SceneEntity>;

    private m_getCenterOfView: ()=>Vector3;

    public constructor(p_engine: Engine, p_rootEntities: Map<string, SceneEntity>, p_objectBuilder: IObjectLoader, p_getCenterOfView: ()=>Vector3) {
        if (p_objectBuilder == null) throw new Error('NullReferenceException : p_objectBuilder is null or undefined');
        if (p_getCenterOfView == null) throw new Error('NullReferenceException : p_getCenterOfView is null or undefined');
        if (p_rootEntities == null) throw new Error('NullReferenceException : p_rootEntities is null or undefined');
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');

        this.m_engine = p_engine;

        this.m_rootEntities = p_rootEntities;
        this.m_getCenterOfView = p_getCenterOfView;
        this.m_roomObjectLoader = p_objectBuilder;
    }

    public FindInstance3D(p_basicObject: BasicObject, p_instances: Instance3D[]): Instance3D | undefined {
        if (p_basicObject == null) throw new Error('NullReferenceException : p_basicObject is null or undefined');
        if (p_instances == null) throw new Error('NullReferenceException : p_instances is null or undefined');

        return p_instances.find((x) => x.Ref === p_basicObject.RefOfInstance);
    }

    public InstanciateRoomObject(p_basicObject: BasicObject, p_instance: Instance3D | undefined): Promise<{ entity: SceneEntity; instance: Instance3D }> {
        if (p_basicObject == null) throw new Error('NullReferenceException : p_basicObject is null or undefined');
        
        return new Promise<{ entity: SceneEntity; instance: Instance3D }>((resolve) => {
            let promise = this.m_roomObjectLoader.LoadRootObjectAsync(p_basicObject.Ref, p_basicObject, p_instance, this.GetRootEntity(p_basicObject));
            promise.then((result) => {

                if (!p_instance) this.ApplyDefaultPosition(result[0].entity);
                let  instance = Instance3D.GenerateInstance3D(result[0].entity, p_basicObject.RefOfInstance, p_basicObject.DataModelVersion);
                
                if (result[0].entity.HasComponentOfType(ObjectComponent)) {
                    let objectComponent = result[0].entity.GetComponentOfType(ObjectComponent);
                    this.m_engine.Modules.EventManager.Publish(ObjectAddedToRoomEvent, new ObjectAddedToRoomEvent(result[0].entity, objectComponent, instance));
                }
                
                resolve({ entity: result[0].entity, instance: instance });
            });
        });
    }

    public InstanciateRoomObjectType(p_type: string, p_basicObjects: BasicObject[], p_instances: Instance3D[]): Promise<void> {
        if (!p_type) throw new Error('NullReferenceException : p_type is null or undefined or empty');
        if (p_basicObjects == null) throw new Error('NullReferenceException : p_basicObjects is null or undefined');
        if (p_instances == null) throw new Error('NullReferenceException : p_instances is null or undefined');
        
        return new Promise<void>((resolve, reject) => {
            let promises: Promise<{ entity: SceneEntity; instance: Instance3D }>[] = [];
            let objects = p_basicObjects.filter((object) => object.Informations.Type === p_type);
            objects.forEach((object) => {
                let instance = this.FindInstance3D(object, p_instances);
                let promise = this.InstanciateRoomObject(object, instance);
                promises.push(promise);
            });
            Promise.all(promises).then((result) => {
                resolve();
            });
        });
    }

    public InstanciateRoom(p_groundObject: BasicObject, p_ceilingObject: BasicObject|undefined, p_wallObjects: BasicObject[], p_instances: Instance3D[]): Promise<void> {
        if (p_groundObject == null) throw new Error('NullReferenceException : p_groundObject is null or undefined');
        if (p_wallObjects == null) throw new Error('NullReferenceException : p_wallObjects is null or undefined');
        if (p_instances == null) throw new Error('NullReferenceException : p_instances is null or undefined');
        
        let instanciationResults: Promise<{ entity: SceneEntity; instance: Instance3D }>[] = [];

        return new Promise<void>((resolve, reject) => {
            // Instanciate ground
            let groundEntity: SceneEntity;
            let groundInstance = this.FindInstance3D(p_groundObject, p_instances);
            let groundInstanciation = this.InstanciateRoomObject(p_groundObject, groundInstance);
            instanciationResults.push(groundInstanciation);
            groundInstanciation.then((result) => {
                groundEntity = result.entity;
            });

            // Instanciate ceiling
            if(p_ceilingObject){
                let ceilingInstance = this.FindInstance3D(p_ceilingObject, p_instances);
                let ceilingInstanciation = this.InstanciateRoomObject(p_ceilingObject, ceilingInstance);
                instanciationResults.push(ceilingInstanciation);
            }

            // Instanciate Walls
            let wallEntities: SceneEntity[] = [];
            p_wallObjects.forEach((object) => {
                let wallInstance = this.FindInstance3D(object, p_instances);
                let wallInstanciation = this.InstanciateRoomObject(object, wallInstance);

                wallInstanciation.then((result) => {
                    wallEntities.push(result.entity);
                });
                instanciationResults.push(wallInstanciation);
            });
            Promise.all(instanciationResults).then((result) => {
                let shapeAsset = p_groundObject!.Assets.find((asset) => asset.Type === 'Shape');
                if (shapeAsset) {
                    let shape = shapeAsset.Datas as ShapeData;
                    this.m_engine.Modules.EventManager.Publish(StructureInstanciatedEvent, new StructureInstanciatedEvent(wallEntities, groundEntity, shape));
                    resolve();
                }
                else resolve();
            });
        });
    }

    private ApplyDefaultPosition(entity: SceneEntity): void {
        let targetPosition: Vector3 = this.m_getCenterOfView();
        targetPosition.y = entity.Transform.GetObject().position.y;
        entity.Transform.SetPosition(targetPosition);
    }

    private GetRootEntity(p_object: BasicObject): SceneEntity|undefined {
        switch (p_object.Informations.Type) { 
            case 'Product':
                if (this.m_rootEntities.has('Products')) {
                   return this.m_rootEntities.get('Products')!;
                }
                break; 
            case 'Opening':
            case 'BuildingBlock':
                if (this.m_rootEntities.has('BuildingBlocks')) {
                    return this.m_rootEntities.get('BuildingBlocks')!;
                }
                break;
            case 'Accessory':
                if (this.m_rootEntities.has('Accessories')) {
                    return this.m_rootEntities.get('Accessories')!;
                }
                break;
            case 'Wall':
            case 'Ceiling':
            case 'Ground':
                if (this.m_rootEntities.has('Room')) {
                    return this.m_rootEntities.get('Room')!;
                }
                break;
            default:
                return undefined;
                break;
        }
    }
}
