import { DisableComponent } from "@lutithree/build/Modules/Core/Entity/DisableComponent";
import { Object3D } from "three";
import { IDisableComponent } from "@lutithree/build/Modules/Core/Entity/IDisableComponent";
import { IDisposableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/IDisposableComponent";

export class HookComponent extends DisableComponent implements IDisableComponent, IDisposableComponent {
    private m_hook: Map<string, Object3D[]>;
    
    private m_ref: string;
    
    private m_onHookUpdate? : ()=>void;

    private m_onDispose? : ()=>void;

    public constructor(p_ref:string, p_hook: Map<string, Object3D[]>) {
        super(true);
        if (p_hook == null) throw new Error('NullReferenceException : p_hook is null or undefined');
        if (!p_ref) throw new Error('NullReferenceException : p_ref is null or undefined or empty');
        
        this.m_hook = p_hook;
        this.m_ref = p_ref;
    }

    public get Ref(): string {
        return this.m_ref;
    }

    public set Ref(p_ref:string) {
        if (!p_ref) throw new Error('NullReferenceException : p_ref is null or undefined or empty');
        this.m_ref = p_ref;
    }
    
    public get Hooks(): Map<string, Object3D[]> {
        return this.m_hook;
    }

    public set Hooks(p_hook: Map<string, Object3D[]>) {
        if (p_hook == null) throw new Error('NullReferenceException : p_hook is null or undefined');

        this.m_hook = p_hook;
        if(this.m_onHookUpdate) this.m_onHookUpdate();
    }
    
    public HasName(p_name: string): boolean {
        if (!p_name) throw new Error('NullReferenceException : p_name is null or undefined');
        
        return this.Hooks.has(p_name);
    }
    
    public SetOnDisposeCallback(p_onDispose? : ()=>void): void {
        if (p_onDispose === null) throw new Error('NullReferenceException : p_onDispose is null');

        this.m_onDispose = p_onDispose;
    }

    public SetOnHookUpdate(p_onHookUpdate? : ()=>void): void {
        if (p_onHookUpdate === null) throw new Error('NullReferenceException : p_getHooksParentValues is null');

        this.m_onHookUpdate = p_onHookUpdate;
    }

    public GetDisposable(): (Object3D)[] {
        if(this.m_onDispose) this.m_onDispose();
        return [];
    }
}
