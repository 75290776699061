import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import RelativePositioningSystem from "./Systems/RelativePositioningSystem";
import { Engine } from "@lutithree/build/Engine";
import { DistanceDisplayerDecorator } from "./EntityDecorators/DistanceDisplayerDecorator";
import AService from "../../../Domain/AService";
import IRelativePositioning from "../../../Domain/Features3D/IRelativePositioning";
import {DistanceDirection, RelativeDistanceDisplayerComponent} from "./Components/RelativeDistanceDisplayer";
import { AutoReorientationComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/AutoReorientationComponent";
import { SnappableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SnappableComponent";
import { BoundingBoxComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundingBoxComponent";
import {Object3D, Vector3} from "three";
import RelativeDistanceInfo from "./RelativeDistanceInfo";
import { RelativePositionableComponents } from "./Components/RelativePositionableComponents";
import {DynamicScaleComponent} from "../TransformControls/Components/DynamicScaleComponent";

export default class RelativePositioningService extends AService implements IRelativePositioning {
    private readonly m_measureSystem: RelativePositioningSystem;
    
    public constructor(p_engine: Engine) {
        super(p_engine);
        this.m_measureSystem = new RelativePositioningSystem(this.m_engine.Modules.Scene);
    }
    
    public EnableRelativePositioningOnEntity(p_entity: SceneEntity) : void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if(!p_entity.HasComponentOfType(RelativePositionableComponents) || this.m_measureSystem.GetRelativePositioningDisplayer(p_entity)) return;
        let distanceDisplayer: SceneEntity = this.m_engine.Modules.Scene.CreateEntity("DistanceDisplayer");
        new DistanceDisplayerDecorator(p_entity, this.m_engine.Modules.Systems.CameraSystem.GetMainCameraDatas().camera).Decorate(distanceDisplayer);
        this.RefreshRelativePositioning(p_entity);
        let relativeComponents = this.m_engine.Modules.Scene.GetComponents(RelativeDistanceDisplayerComponent);
        let objects: Object3D[] = [];
        relativeComponents.forEach((component)=>{
            component?.m_distanceMap.forEach((obj) => {
                objects.push(obj.p_sticker.m_stickerObject);
            });
            if(component) {
                let dynamicComponent = this.m_engine.Modules.Scene.GetEntityByID(component.EntityID).GetComponentOfType(DynamicScaleComponent);
                dynamicComponent.SetObjectsWithClear(objects);
            }
        });
    }

    public DisableRelativePositioningOnEntity(p_entity: SceneEntity) : void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        
        let positioningDisplayerComponent = this.m_measureSystem.GetRelativePositioningDisplayer(p_entity);
        if(positioningDisplayerComponent){
            this.m_engine.Modules.Scene.RemoveEntityByID(positioningDisplayerComponent.EntityID);
        }
    }

    public RefreshRelativePositioning(p_entity: SceneEntity): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        
        let positioningDisplayerComponent = this.m_measureSystem.GetRelativePositioningDisplayer(p_entity);
        let directions: DistanceDirection[] = this.GetDirectionToDisplay(p_entity);
        let offset: Vector3 = this.GetAxisOffset(p_entity);
        
        if(positioningDisplayerComponent){
            let relativeInfos: RelativeDistanceInfo[] = this.GetRelativeDistanceInfos(p_entity, directions, offset);
            positioningDisplayerComponent.UpdateDistancesInfos(relativeInfos);
        }
        this.m_engine.Modules.LoopStrategy.RequestRender(true);
    }

    private GetRelativeDistanceInfos(p_entity: SceneEntity, p_directions:DistanceDirection[], p_offset:Vector3): RelativeDistanceInfo[]{
        let relativeDistanceInfos: RelativeDistanceInfo[] = this.m_measureSystem.GetRelativeDistanceInfos(p_entity, p_offset);
        for(let i = 0; i < relativeDistanceInfos.length; i++){
            let test = p_directions.find((obj)=>{
                return relativeDistanceInfos[i].Direction === obj;
            });
            if( test == null) {
                relativeDistanceInfos.splice(i, 1);
                i = i - 1;
            }
        }
        
        return relativeDistanceInfos;
    }
    
    private GetAxisOffset(p_entity: SceneEntity): Vector3 {
        let offset: Vector3 = new Vector3();
        let bb = p_entity.HasComponentOfType(BoundingBoxComponent)?p_entity.GetComponentOfType(BoundingBoxComponent):undefined;
        if(p_entity.HasComponentOfType(AutoReorientationComponent) && p_entity.HasComponentOfType(SnappableComponent) && p_entity.GetComponentOfType(SnappableComponent).Snapped.length > 0) {
            if (bb)
                offset = bb.GetForwardOriented(true).clone().multiplyScalar(0.99);
        }
        if(bb && Math.abs(bb.Center.y-bb.HalfSize.y) < 0.001){
            offset = new Vector3(offset.x,(bb.Center.y-bb.HalfSize.y)-bb.Center.y+0.02, offset.z);
        }
        return offset;
    }
    
    private GetDirectionToDisplay(p_entity: SceneEntity): DistanceDirection[]{
        let directions: DistanceDirection[];
        if(p_entity.HasComponentOfType(AutoReorientationComponent) && p_entity.HasComponentOfType(SnappableComponent) && p_entity.GetComponentOfType(SnappableComponent).Snapped.length > 0) {

            if(this.m_engine.Modules.Systems.CameraSystem.GetMainCameraDatas().camera.type === "PerspectiveCamera")
                directions = [DistanceDirection.UP, DistanceDirection.LEFT, DistanceDirection.RIGHT, DistanceDirection.DOWN];
            else
                directions = [DistanceDirection.LEFT, DistanceDirection.RIGHT];
        }
        else
            directions = [DistanceDirection.BACKWARD, DistanceDirection.LEFT, DistanceDirection.RIGHT, DistanceDirection.FORWARD];
        
        return directions;
    }
}