import { Engine } from '@lutithree/build/Engine';
import IdleBehaviours from './IdleBehaviours';
import ConfiguringBehaviours from './ConfiguringBehaviours';
import AFSMBuilder from "../../../../FSM/AFSMBuilder";
import ProductStudioServices from "../ProductStudioService";
import { ProductStudioMode } from "../../Domain/Modes/ProductStudioMode";
import ScreenshotBehaviours from "./ScreenshotBehaviours";

export default class ProductStudioModesBuilder extends AFSMBuilder {
    private m_engine: Engine;

    private m_services: ProductStudioServices;

    public constructor(p_engine: Engine, p_services: ProductStudioServices) {
        super();
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');
        if (p_services == null) throw new Error('NullReferenceException : p_services is null or undefined');

        this.m_engine = p_engine;
        this.m_services = p_services;
    }

    protected AddStatesBehaviours(): void {
        this.AddBehaviours(ProductStudioMode[ProductStudioMode.Idle], new IdleBehaviours());
        this.AddBehaviours(ProductStudioMode[ProductStudioMode.Configuring], new ConfiguringBehaviours());
        this.AddBehaviours(ProductStudioMode[ProductStudioMode.Screenshot], new ScreenshotBehaviours());
    }

    protected CreateTransitions(): void {
        // Idle to =>
        this.CreateSimpleTransition(ProductStudioMode[ProductStudioMode.Idle], ProductStudioMode[ProductStudioMode.Configuring]);
        this.CreateSimpleTransition(ProductStudioMode[ProductStudioMode.Idle], ProductStudioMode[ProductStudioMode.Screenshot]);

        // Configuring to =>
        this.CreateSimpleTransition(ProductStudioMode[ProductStudioMode.Configuring], ProductStudioMode[ProductStudioMode.Screenshot]);

        // Screenshot 3D to =>
        this.CreateSimpleTransition(ProductStudioMode[ProductStudioMode.Screenshot], ProductStudioMode[ProductStudioMode.Configuring]);
    }

    protected SetInitialState(): void {
        this.SetInitialStateByName(ProductStudioMode[ProductStudioMode.Idle]);
    }
}
