import { Engine } from '@lutithree/build/Engine';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { RoomService } from './Room/RoomService';
import IRoomService from '../Domain/Room/IRoomService';
import Behaviours from "./Objects/Behaviours";
import { ObjectDecorator } from "./Objects/EntityDecorators/ObjectDecorator";
import ControlsCallbacks from "./Features3D/TransformControls/ControlsCallbacks";
import RoomScreenshotService from "./Features3D/Screenshot/RoomScreenshotService";
import IServices from "./IServices";
import RoomStudioNotifierService from "./Notifier/RoomStudioNotifierService";
import { SelectionEffects } from './Features3D/Selection/SelectionEffects';
import SelectionService
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Selection/SelectionService";
import CamerasService
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Studio/Cameras/CamerasService";
import ObjectService
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/ObjectService";
import SnapService
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Snap/SnapService";
import TranslationOnPlaneService
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/TranslationOnPlaneService";
import ScaleService
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/ScaleService";
import IRelativePositioning
    from "../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Features3D/IRelativePositioning";
import DimensionService
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Dimensions/DimensionService";
import MeasureDrawerService
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Measuring/MeasureDrawerService";
import InteractiveConnectorsService
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/UI/InteractiveConnectorsService";
import RelativePositioningService
    from "../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/RelativePositioning/RelativePositioningService";
import IObjectDataAccess
    from "../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/IObjectDataAccess";
import ObjectDatas
    from "../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ObjectDatas";

export default class RoomStudioServices implements IServices{
    private readonly m_selection: SelectionService;

    private readonly m_datas: ObjectDatas;
    
    private readonly m_selectionEffects: SelectionEffects;

    private readonly m_cameras: CamerasService;

    private readonly m_room: RoomService;

    private readonly m_roomObjects: ObjectService;
    
    private readonly m_behaviours: Behaviours;
    
    private readonly m_snap: SnapService;

    private readonly m_translation: TranslationOnPlaneService;

    private readonly m_scale: ScaleService;
    
    private readonly m_screenshot: RoomScreenshotService;

    private readonly m_notifier: RoomStudioNotifierService;
    
    private readonly m_helper: IRelativePositioning;
    
    private readonly m_dimensions: DimensionService;

    private readonly m_measureDrawer: MeasureDrawerService;

    private readonly m_connectorsService: InteractiveConnectorsService;

    public constructor(p_engine: Engine, p_rootEntities: Map<string, SceneEntity>) {
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');

        this.m_notifier = new RoomStudioNotifierService();

        this.m_datas = new ObjectDatas();

        this.m_cameras = new CamerasService(p_engine);
       
        this.m_snap = new SnapService(p_engine);
        this.m_translation = new TranslationOnPlaneService(p_engine);
        this.m_scale = new ScaleService(p_engine);
        this.m_helper = new RelativePositioningService(p_engine);

        let controlsCallbacks = new ControlsCallbacks(p_engine, this.m_cameras, this.m_cameras);
        this.m_connectorsService = new InteractiveConnectorsService(p_engine);
        this.m_selectionEffects = new SelectionEffects(p_engine, this.m_cameras, controlsCallbacks, this.m_helper, this.m_connectorsService,p_rootEntities);
        this.m_selection = new SelectionService(p_engine);

        let objectDecorator = new ObjectDecorator(p_engine);
        this.m_roomObjects = new ObjectService(p_engine, this.m_datas, objectDecorator);
        this.m_screenshot = new RoomScreenshotService(p_engine, this.m_cameras, this.m_roomObjects);
        
        let getCenterOfView = ()=>{
            return this.Cameras.GetCameraTargetPosition();
        };
        this.m_room = new RoomService(p_engine, this.m_datas, this.m_roomObjects, this.m_selection, p_rootEntities, getCenterOfView);
        this.m_behaviours = new Behaviours(p_engine, this.m_notifier, this.m_cameras);
        this.m_dimensions = new DimensionService(p_engine);
        this.m_measureDrawer = new MeasureDrawerService(p_engine);
    }

    public get DataAccess(): IObjectDataAccess {
        return this.m_datas;
    }

    public get Notifier(): RoomStudioNotifierService {
        return this.m_notifier;
    }

    public get Connectors(): InteractiveConnectorsService {
        return this.m_connectorsService;
    }

    public get Dimemsions(): DimensionService {
        return this.m_dimensions;
    }

    public get MeasureDrawer(): MeasureDrawerService {
        return this.m_measureDrawer;
    }

    public get Selection(): SelectionService {
        return this.m_selection;
    }

    public get SelectionEffects(): SelectionEffects {
        return this.m_selectionEffects;
    }

    public get Cameras(): CamerasService {
        return this.m_cameras;
    }

    public get Room(): IRoomService {
        return this.m_room;
    }

    public get RoomObjects(): ObjectService {
        return this.m_roomObjects;
    }

    public get ObjectBehaviours(): Behaviours {
        return this.m_behaviours;
    }

    public get Snap(): SnapService {
        return this.m_snap;
    }

    public get Translation(): TranslationOnPlaneService {
        return this.m_translation;
    }

    public get Scale(): ScaleService {
        return this.m_scale;
    }
    
    public get Screenshot(): RoomScreenshotService {
        return this.m_screenshot;
    }

    public get Helper(): IRelativePositioning {
        return this.m_helper;
    }
}
