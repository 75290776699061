import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { BoundingBoxComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundingBoxComponent';
import { SelectableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SelectableComponent';
import { RotControlableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/RotControlableComponent';
import { YControlableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/YControlableComponent';
import { Mesh, Object3D, Plane, Vector3 } from 'three';
import { TranslatableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/TranslatableComponent';
import { SnappableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SnappableComponent';
import { SupportComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SupportComponent';
import { SupportType } from '@lutithree/build/Modules/WebGL/Scene/DataModel/SupportType';
import { MeshRendererComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshRendererComponent';
import { MeshUtils } from "@lutithree/build/Modules/WebGL/Utils/MeshUtils";
import { RaycastableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/RaycastableComponent";
import BasicObject from '../../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject';
import Instance3D from '../../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/Instance3D';
import { IObjectDecorator } from '../../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/IObjectDecorator';
import { RelativePositionableComponents } from '../../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/RelativePositioning/Components/RelativePositionableComponents';
import { ScalableComponent } from '../../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Components/ScalableComponent';
import Asset3D from '../../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Asset3D';
import ActionComponent
    from "../../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ActionComponent";
import { ObjectAction } from '../../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ObjectAction';


export class BuildingBlockDecorator implements IObjectDecorator {

    public DecorateRootObject(p_entity: SceneEntity, p_object: BasicObject, p_instance: Instance3D | undefined): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_object == null) throw new Error('NullReferenceException : p_object is null or undefined');

        p_entity.AddComponentOfType(BoundingBoxComponent, p_entity.Transform.GetObject());
        p_entity.AddComponentOfType(SelectableComponent);
        p_entity.AddComponentOfType(RelativePositionableComponents);
        let normal = new Vector3(0, 1, 0);
        let plane = new Plane(normal, 0);
        p_entity.AddComponentOfType(TranslatableComponent, plane);
        p_entity.AddComponentOfType(YControlableComponent, new Vector3(0, 1, 0));
        p_entity.AddComponentOfType(RotControlableComponent);
        p_entity.AddComponentOfType(ActionComponent, p_object.Informations.Name, [ObjectAction.Scale, ObjectAction.Paint, ObjectAction.Replace, ObjectAction.Duplicate, ObjectAction.Delete]);
        let scalable = p_entity.AddComponentOfType(ScalableComponent);
        scalable.AddOnObject3DScaledCallback('rescaleOpeningFeature', () => {
            this.RefreshObjectUV(p_entity.Transform.GetObject());
        });
        if(p_object.Assets.length > 0){/** @deprecated basicObject.assets should not be used*/
            if (!p_object.Assets.find((asset) => asset.Datas === 'Cylinder')) {
                p_entity.AddComponentOfType(SupportComponent, p_entity.Transform.GetObject(), SupportType.SoftWall);
            }
        }
        else {
            if (p_object.Composition.Type === 'Asset3D' && !p_object.Composition.PartElements.find((asset) => (asset as Asset3D).Datas === 'Cylinder')) {
                p_entity.AddComponentOfType(SupportComponent, p_entity.Transform.GetObject(), SupportType.SoftWall);
            }
        }
      
        p_entity.AddComponentOfType(SnappableComponent);
    }

    public DecorateObject(p_entity: SceneEntity, p_object: BasicObject, p_instance: Instance3D | undefined): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_object == null) throw new Error('NullReferenceException : p_object is null or undefined');

        p_entity.AddComponentOfType(BoundingBoxComponent, p_entity.Transform.GetObject());
        p_entity.AddComponentOfType(SelectableComponent);
        p_entity.AddComponentOfType(ActionComponent, p_entity.Name, [ObjectAction.Scale, ObjectAction.Paint, ObjectAction.Replace, ObjectAction.Duplicate, ObjectAction.Delete]);
        let scalable = p_entity.AddComponentOfType(ScalableComponent);
        scalable.AddOnObject3DScaledCallback('rescaleOpeningFeature', () => {
            this.RefreshObjectUV(p_entity.Transform.GetObject());
        });
    }

    public DecoratePart(p_entity: SceneEntity, p_parentObject: BasicObject, p_parentEntity:SceneEntity, p_refOfPart: string): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_parentObject == null) throw new Error('NullReferenceException : p_parentObject is null or undefined');
        if (p_parentEntity == null) throw new Error('NullReferenceException : p_parentEntity is null or undefined');
        if (!p_refOfPart) throw new Error('NullReferenceException : p_refOfPart is null or undefined');

        if((p_parentObject.Composition.Type === 'Asset3D') || p_parentObject.Assets.length > 0) {
            if (p_entity.HasComponentOfType(MeshRendererComponent)) {
                let meshRenderer = p_entity.GetComponentOfType(MeshRendererComponent);
                meshRenderer.CastShadows = false;
            }

            p_entity.AddComponentOfType(RaycastableComponent);
        }
    }

    private RefreshObjectUV(p_object: Object3D): void {
        p_object.traverse((child) => {
            if (child.type === 'Mesh') {
                MeshUtils.RefreshUV_world(child as Mesh);
            }
        });
    }
}
