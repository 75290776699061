import React from 'react';
import './ProgressBar.scss';

export type ProgressBarProps = {
    title: string;
    subTitle: string;
    completed: number;
};

const ProgressBar = (props: ProgressBarProps) => {
    const progressStyle: React.CSSProperties = {
        width: `${props.completed}%`,
    };

    return (
        <div className="ProgressBarContainer">
            <span className="Label">{`${props.completed}%`}</span>
            <br />
            <span className="Label">{props.title}</span>
            <br />
            <div className="Background">
                <div className="Progress" style={progressStyle} />
            </div>
            <span className="Label">{props.subTitle}</span>
        </div>
    );
};

export default ProgressBar;
