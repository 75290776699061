import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { POVComponent } from "../Components/POVComponent";
import { AEntityEvent } from "@lutithree/build/Modules/Core/Entity/Events/AEntityEvent";

export class EntityPOVChangedEvent extends AEntityEvent<SceneEntity> {
    private m_component: POVComponent;

    public constructor(p_entity: SceneEntity, p_component: POVComponent) {
        super(p_entity);
        if (p_component == null) throw new Error('NullReferenceException : p_component is null or undefined');

        this.m_component = p_component;
        this.m_entity = p_entity;
    }

    public get Component() {
        return this.m_component;
    }
}