import { createSlice } from '@reduxjs/toolkit';

export type PlannerState = {
    platePath: string;
    plateMatPath: string;
    basePath: string;
    baseMatPath: string;
};

const initialState: PlannerState = {
    platePath: 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/models/TableConcept/Plateaux/CONCEPT_TABLE-CARPANCOUPES-140-FERMEE-P140.glb',
    plateMatPath: 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/materials/mat_IronGrey.json',
    basePath: 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/models/TableConcept/Pieds/CONCEPT_PIED01-P135.glb',
    baseMatPath: 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/materials/mat_laque_metal.json',
};

export const plannerSlice = createSlice({
    name: 'plannerSlice',
    initialState,
    reducers: {
        setPlatePath: (state = initialState, { payload }) => {
            state.platePath = payload.url;
        },
        setPlateMatPath: (state = initialState, { payload }) => {
            state.plateMatPath = payload.url;
        },
        setBasePath: (state = initialState, { payload }) => {
            state.basePath = payload.url;
        },
        setBaseMatPath: (state = initialState, { payload }) => {
            state.baseMatPath = payload.url;
        },
    },
});

export const { setPlatePath, setPlateMatPath, setBasePath, setBaseMatPath } = plannerSlice.actions;
export default plannerSlice.reducer;
