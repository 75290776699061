import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Group, Material, Mesh, Object3D, Shape, ShapeGeometry } from 'three';
import { Object3DUtils } from '@lutithree/build/Modules/WebGL/Utils/Object3DUtils';
import Asset3D from "../../../../../Domain/Objects/Assets/Asset3D";
import EntityMeshController from "../../EntityMeshController";
import ShapeData from "../../../../../Domain/Objects/Assets/ShapeData";
import { IAssetDecorator } from "../../../../../Domain/Objects/AssetAssembly/IAssetDecorator";

export class ShapeDecorator implements IAssetDecorator {
    private m_defaultMaterial: Material;

    private readonly m_entityMeshController: EntityMeshController;
    
    public constructor(p_defaultMaterial: Material) {
        if (p_defaultMaterial == null) throw new Error('NullReferenceException : p_defaultMaterial is null or undefined');
        
        this.m_defaultMaterial = p_defaultMaterial;
        this.m_entityMeshController = new EntityMeshController();
    }

    public DecorateAsset(p_entity: SceneEntity, p_asset: Asset3D, p_levelLoadedCallback: (p_resource: (Material | Material[] | Group | Object3D)) => void):  Promise<void> {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_asset == null) throw new Error('NullReferenceException : p_asset is null or undefined');
        if (p_levelLoadedCallback == null) throw new Error('NullReferenceException : p_levelLoadedCallback is null or undefined');

        return new Promise<void>((resolve) => {
            let shapeDatas = p_asset.Datas as ShapeData;

            const shape = new Shape([...shapeDatas.Points]);
            let geometry = new ShapeGeometry(shape);
            let mesh = new Mesh(geometry, this.m_defaultMaterial);
            if (shapeDatas.Transform) Object3DUtils.ApplyTransform(mesh, shapeDatas.Transform);

            this.m_entityMeshController.AddModel(p_entity, mesh); 
            resolve();
        });
    }
}
