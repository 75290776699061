import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import EntitySelectionStatusDirtyEvent from "../../../../Application3D/GameLogic/Features3D/Selection/Events/EntitySelectionStatusDirtyEvent";
import AProductStudioHandler from "../../../AProductStudioHandler";

export class ObjectSelectionEffectsHandler extends AProductStudioHandler implements IHandler<EntitySelectionStatusDirtyEvent> {
    
    public Handle(p_event: EntitySelectionStatusDirtyEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');

        let isSelected: boolean = true;
        if(!p_event.Selectable.IsSelected || !p_event.Selectable.IsEnabled) isSelected = false;

        let enable = p_event.Selectable.IsSelected && p_event.Selectable.IsSelectionVisible;
        if(!p_event.Selectable.IsEnabled){
            enable = false;
        }
        this.m_services.SelectionEffects.EnableOutline(p_event.Entity, enable);
        
        this.m_services.Notifier.NotifyEntityClicked(p_event.Entity.Id, isSelected);
    }
}