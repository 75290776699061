import State from './State';
import Transition from './Transition';
import IEvent from './IEvent';

export default class FSM {
    private m_initialState: State | undefined;

    private m_currentState: State | undefined;

    private m_lastState: State | undefined;

    private m_states: Array<State>;

    private m_transitions: Array<Transition>;

    private m_isRunning: boolean;

    public constructor() {
        this.m_states = new Array<State>();
        this.m_transitions = new Array<Transition>();
        this.m_isRunning = false;
    }

    public get CurrentState() {
        return this.m_currentState;
    }

    public set InitialState(p_state: State) {
        if (p_state == null) throw new Error('NullReferenceException : p_state is null or undefined');
        if (!this.m_states.includes(p_state)) throw new Error('FSM Error : FSM does not contain this initial state' + p_state.Name);

        this.m_initialState = p_state;
    }

    public set InitialStateName(p_stateName: string) {
        if (!p_stateName) throw new Error('NullReferenceException : p_stateName is null or undefined');

        let initialState: State | undefined = this.m_states.find((state) => state.Name == p_stateName);
        if (!initialState) throw new Error('FSM Error : FSM does not contain this initial state' + p_stateName);

        this.m_initialState = initialState;
    }

    public AddState(p_state: State): void {
        if (p_state == null) throw new Error('NullReferenceException : p_state is null or undefined');
        if (this.m_states.includes(p_state)) throw new Error('FSM Error : State Already exist in array' + p_state.Name);

        this.m_states.push(p_state);
    }

    public AddTransition(p_transition: Transition): void {
        if (p_transition == null) throw new Error('NullReferenceException : p_transition is null or undefined');
        if (this.m_transitions.includes(p_transition)) throw new Error('FSM Error : Transition Already exist in array' + p_transition);
        if (!this.m_states.includes(p_transition.From)) throw new Error('FSM Error : FSM does not contain from state of transition' + p_transition);
        if (!this.m_states.includes(p_transition.To)) throw new Error('FSM Error : FSM does not contain from state of transition' + p_transition);

        this.m_transitions.push(p_transition);
    }

    public Start(): void {
        this.m_isRunning = true;
        this.m_currentState = this.m_initialState;
        this.m_currentState?.Enter();
    }

    public Stop(): void {
        this.m_isRunning = false;
        this.m_currentState = undefined;
    }

    public GoToState(p_stateToName: string): void {
        if (this.m_isRunning && p_stateToName !== this.CurrentState!.Name) {
            try {
                let transition: Transition = this.GetEligibleTransition(p_stateToName);
                this.m_lastState = this.m_currentState;
                this.m_currentState?.Exit();
                transition.Execute();
                this.m_currentState = transition.To;
                this.m_currentState.Enter();
            } catch (e) {
                console.error(e);
            }
        }
    }

    public PlayReactions<T extends IEvent>(p_eventType: { new (...args: any[]): T }, p_event: T): void {
        if (this.m_isRunning) {
            this.m_currentState!.PlayReactions(p_eventType, p_event);
        }
    }

    private GetEligibleTransition(p_stateToName: string): Transition {
        if (!p_stateToName) throw new Error('NullReferenceException : p_stateToName is null or undefined or empty');

        let transitions: Array<Transition> = this.m_transitions.filter((transition) => {
            return transition.From === this.m_currentState && transition.To.Name === p_stateToName;
        });

        let transitionToReturn: Transition | undefined = undefined;
        transitions.forEach((transition) => {
            if (transition.IsConditionValid()) transitionToReturn = transition;
        });

        if (transitionToReturn) return transitionToReturn;
        else throw new Error('FSM Error : Invalid next state ' + p_stateToName + '.');
    }
}
