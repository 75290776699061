import React, { useEffect, useState } from 'react';
import BasicObject from "../../Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import IObjectEditor from "../../Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/IObjectEditor";
import ObjectDatas from "../../Librairies/Studios/Application3D/Domain/Objects/ObjectDatas";

export type ObjectEditorProps = {
    editor : IObjectEditor;
    editedDatas: ObjectDatas
    onObjectEdited?: (p_lastDatas:ObjectDatas, p_newDatas:ObjectDatas)=>void
};

const ObjectEditor = (props: ObjectEditorProps) => {
    
    const [objectDatas, setObjectDatas] = useState<ObjectDatas>(props.editedDatas);
    
    useEffect(() => {
        
        if(props.editedDatas.FirstRootObject && objectDatas.FirstRootObject && objectDatas.FirstRootObject !== props.editedDatas.FirstRootObject){
            // Edit Object
            if( props.editedDatas.FirstRootObject.RefOfInstance === objectDatas.FirstRootObject.RefOfInstance){
                let promise = props.editor.EditRootObjectAsync(props.editedDatas,objectDatas);
                
                promise.then(() => {
                    if (props.onObjectEdited) props.onObjectEdited(objectDatas, props.editedDatas);
                    setObjectDatas(props.editedDatas);
                });
            }
            // Change Objet
            else{
                setObjectDatas(props.editedDatas);
            }
        }
    }, [props.editedDatas]);
    

    return (
        <></>
    );
};

export default ObjectEditor;