import { AHandler } from "@lutithree/build/Handlers/AHandler";
import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import SelectionService from "../Selection/SelectionService";
import { Engine } from "@lutithree/build/Engine";
import { SelectableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SelectableComponent";
import { EntityRemovedEvent } from "@lutithree/build/Modules/WebGL/Scene/Events/EntityRemovedEvent";

export default class EntityRemovedHandler extends AHandler implements IHandler<EntityRemovedEvent> {
    
    private m_selectionService: SelectionService;
    
    public constructor(p_engine: Engine, p_selectionService: SelectionService){
        super(p_engine);
        if (p_selectionService == null) throw new Error('Empty refOfPart : p_selectionService is null or undefined');
        
        this.m_selectionService = p_selectionService;
    }
    
    public Handle(p_event: EntityRemovedEvent): void {
        let selectable = p_event.Entity.HasComponentOfType(SelectableComponent)?p_event.Entity.GetComponentOfType(SelectableComponent):undefined;
        if(selectable && selectable.IsSelected){
            this.m_selectionService.UnSelectEntity(p_event.Entity);
        }
    }
}