import { IEvent } from "@lutithree/build/Modules/Core/Event/IEvent";


export default class NBMeasureMarkerChangedEvent implements IEvent {

    private m_nbMarkers: number;

    public constructor (p_nbMarkers: number){
        if (p_nbMarkers === null) throw new Error('NullReferenceException : p_nbMarkers is null');

        this.m_nbMarkers = p_nbMarkers;
    }

    public get NBMeasureMarkers(): number {
        return this.m_nbMarkers;
    }
}
