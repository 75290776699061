import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import RoomStudioApp from '../../../Libraries/Studios/RoomStudio/RoomStudioApp';
import {RoomStudioMode} from "../../../Libraries/Studios/RoomStudio/Domain/Modes/RoomStudioMode";
import {ViewMode} from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Cameras/ViewMode";
import BasicObject
    from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import {
    Asset3DData
} from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/Asset3DData";
import ShapeData
    from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/ShapeData";
import ObjectDatas from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ObjectDatas";

export type RoomStudioState = {
    roomStudioApp: RoomStudioApp | null;
    roomStudioMode: RoomStudioMode | undefined;
    currentViewMode: ViewMode | undefined;
    currentEntity: SceneEntity | undefined;
    selectedObject: BasicObject | undefined;
    editedDatas: ObjectDatas | undefined;
    selectedConnector: string|undefined;
    objectAssetDataToEdit: { basicObject: BasicObject; type: string; data: Asset3DData; refOfPart: string | undefined } | undefined;
    roomLastState: BasicObject | undefined;
    roomNewState: BasicObject | undefined;
    roomAssetToEdit: { type: string, data: Array<string> | ShapeData | string, refOfPart: string | undefined } | undefined;
};

const initialRoomStudioState: RoomStudioState = {
    roomStudioApp: null,
    roomStudioMode: undefined,
    currentViewMode: undefined,
    currentEntity: undefined,
    selectedObject: undefined,
    editedDatas: undefined,
    selectedConnector: undefined,
    objectAssetDataToEdit: undefined,
    roomLastState: undefined,
    roomNewState: undefined,
    roomAssetToEdit: undefined,
};

const roomStudioSlice = createSlice({
    name: 'roomStudio',
    initialState: initialRoomStudioState,
    //reducers : define how the state can be updated
    reducers: {
        setRoomStudioApp: (state, action: PayloadAction<RoomStudioApp>) => {
            if (action.payload != null && state.roomStudioApp == null) {
                state.roomStudioApp = action.payload;
            }
        },
        updateRoomStudioMode: (state, action: PayloadAction<RoomStudioMode>) => {
            if (action.payload) {
                if(action.payload === RoomStudioMode.Furnishing){
                    state.editedDatas = undefined;
                }
                else if(action.payload === RoomStudioMode.Configuring){
                    if(state.roomStudioApp && state.selectedObject){
                        state.editedDatas = state.roomStudioApp.Services.DataAccess.GetDatas(state.selectedObject.Ref);
                    }
                    else{
                        state.editedDatas = undefined;
                    }
                }
                state.roomStudioMode = action.payload;
            }
        },
        updateViewMode: (state, action: PayloadAction<ViewMode>) => {
            if (action.payload !== null && state.currentViewMode != action.payload) {
                state.currentViewMode = action.payload;
            }
        },
        clearCurrentEntity: (state, action: PayloadAction<void>) => {
            state.currentEntity = undefined;
        },
        setCurrentEntity: (state, action: PayloadAction<{ entity: SceneEntity; isSelected: boolean }>) => {
            if (action.payload) {
                // Selection
                if (action.payload.isSelected) {
                    state.currentEntity = action.payload.entity;
                }
                // Unselection
                else if (state.currentEntity === action.payload.entity) {
                    state.currentEntity = undefined;
                }
            }
        },
        setSelectedObject: (state, action: PayloadAction<BasicObject | undefined>) => {
            state.selectedObject = action.payload;
            state.selectedConnector = undefined;
        },
        setSelectedConnector: (state, action: PayloadAction<{ refOfConnector: string, isSelected: boolean }>) => {
            if (action.payload) {
                // Selection
                if (action.payload.isSelected) {
                    state.selectedConnector = action.payload.refOfConnector;
                }
                // Unselection
                else if (state.selectedConnector === action.payload.refOfConnector) {
                    state.selectedConnector = undefined;
                }
            }
        },
        editObjectAssetData: (state, action: PayloadAction<{ basicObject: BasicObject, type: string; data: Asset3DData; refOfPart: string | undefined } | undefined>) => {
            state.objectAssetDataToEdit = action.payload;
        },
        setEditedDatas: (state, action: PayloadAction<ObjectDatas | undefined>) => {
            if (action.payload) {
                state.editedDatas = action.payload;
            } else {
                state.editedDatas = undefined;
                state.objectAssetDataToEdit = undefined;
            }
        },
        setRoomAssetToEdit: (state, action: PayloadAction<{ type: string, data: Array<string> | ShapeData | string, refOfPart: string | undefined }>) => {
            if(action.payload) state.roomAssetToEdit = action.payload;
        },
        setRoomNewState: (state, action: PayloadAction<BasicObject|undefined>) =>{
            if (action.payload) {
                if(state.roomNewState !== undefined){
                    let tempLast = state.roomNewState;
                    let tempNew = action.payload;
                    
                    state.roomNewState = tempNew;
                    state.roomLastState = tempLast.Clone();
                }
                else{
                    state.roomNewState = action.payload;
                }
            }
            else{
                state.roomNewState = undefined;
                state.roomLastState = undefined;
            }
        },
    },
});

export const { setRoomStudioApp, updateRoomStudioMode, updateViewMode, clearCurrentEntity, setCurrentEntity, setSelectedObject, setEditedDatas, setSelectedConnector, editObjectAssetData, setRoomNewState, setRoomAssetToEdit } = roomStudioSlice.actions;
export default roomStudioSlice;
