import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import Connectivity from "../../../../Domain/Objects/Composition/Connectivity";
import { AEntityEvent } from "@lutithree/build/Modules/Core/Entity/Events/AEntityEvent";

export default class EntityConnectorsChangeEvent extends AEntityEvent<SceneEntity> {
    
    private m_connectivity: Connectivity;
    
    public constructor (p_entity: SceneEntity, p_connectivity: Connectivity){
        super(p_entity);
        if (p_connectivity === null) throw new Error('NullReferenceException : p_connectivity is null');
        
        this.m_connectivity = p_connectivity;
    }
    
    public get Connectivity(): Connectivity {
        return this.m_connectivity;
    }
}
