import {Spherical, Vector3} from 'three';
import Transform from "@lutithree/build/Modules/WebGL/Scene/DataModel/Transform";
import PhysicalAssetData from "../Assets/PhysicalAssetData";
import PartTransformData from "../Assets/PartTransformData";
import Asset3D from "../Assets/Asset3D";
import { Asset3DData } from "../Assets/Asset3DData";
import Composition from "../Composition/Composition";
import { ObjectParser } from "../../../GameLogic/Objects/ObjectParser";
import Information from "../Information";
import Assembly from '../Composition/Assembly';

export default class BasicObject {
    protected dataModelVersion: string;

    protected refOfInstance: string;

    protected composition: Composition;

    protected informations?: Information;
    
    protected isRootObject?: boolean;
    
    protected assembly: Assembly;

    /** @deprecated The attribute should not be used*/
    protected assets: Array<Asset3D>;

    /** @deprecated The attribute should not be used*/
    protected transformByParts?: Array<PartTransformData>;
    
    
    

    /** @deprecated The attribute should not be used*/
    protected behaviours: string[] = [];

    /** @deprecated The attribute should not be used*/
    protected pointOfView?: Spherical;

    public constructor() {
        this.dataModelVersion = '';
        this.refOfInstance = '';
        this.behaviours = [];
        this.pointOfView = undefined;
        this.assets = new Array<Asset3D>();
        this.composition = new Composition();
        this.informations = new Information();
        this.assembly = new Assembly();
        this.isRootObject = true;
    }

    public get Ref(): string {
        return this.refOfInstance;
    }

    public get IsComposed(): boolean{
        return this.assembly.SubObjectsRef.length>0;
    }

    public get IsRootObject(): boolean {
        return this.isRootObject!==undefined?this.isRootObject:true;
    }

    /** @deprecated The attribute should not be used*/
    public get Assets(): Array<Asset3D> {
        return this.assets;
    }

    /** @deprecated The attribute should not be used*/
    public get PointOfView(): Spherical|undefined {
        return this.pointOfView;
    }

    public get RefOfInstance(): string {
        return this.refOfInstance;
    }

    public get DataModelVersion(): string {
        return this.dataModelVersion;
    }

    public get Informations(): Information {
        let infos: Information;
        if(!this.informations) infos = new Information();
        else infos = this.informations;

        if(infos.Name == ""){
            infos.Behaviours = this.behaviours;
            infos.PointOfView = this.pointOfView;
            infos.Name = this.refOfInstance;
        }

        return infos;
    }

    public set Informations(p_info: Information) {
        this.informations = p_info;
    }

    public get Assembly(): Assembly {
        return this.assembly;
    }

    public set Assembly(p_assembly: Assembly) {
        this.assembly = p_assembly;
    }

    public get Composition(): Composition {
        return this.composition;
    }

    public set Composition(p_composition: Composition) {
        this.composition = p_composition;
    }

    /** @deprecated The attribute should not be used*/
    public get TransformByParts(): Array<PartTransformData>|undefined {
        return this.transformByParts;
    }

    /** @deprecated The attribute should not be used*/
    public set TransformByParts(p_transformByParts :Array<PartTransformData>|undefined) {
        this.transformByParts = p_transformByParts;
    }

    public set RefOfInstance(p_ref: string) {
        if(!p_ref) throw new Error('NullReferenceException : p_ref is null or undefined or empty');
        this.refOfInstance = p_ref;
    }

    public set PointOfView(p_pointOfView: Spherical|undefined){
        if(p_pointOfView === null) throw new Error('NullReferenceException : p_pointOfView is null');
        this.pointOfView = p_pointOfView;
    }

    public Clone(): BasicObject {
        let objectClone = new BasicObject();
        objectClone.dataModelVersion = this.dataModelVersion; 
        objectClone.refOfInstance = this.refOfInstance;
        if(this.pointOfView)
            objectClone.pointOfView = this.PointOfView?.clone();
        for(let i:number = 0; i<this.assets.length; i++) {
            objectClone.assets.push(ObjectParser.DeepParseAsset3D(this.assets[i]));
        }
        objectClone.behaviours = JSON.parse(JSON.stringify(this.behaviours));
        if(this.transformByParts) objectClone.transformByParts = JSON.parse(JSON.stringify(this.transformByParts));
        if(this.composition && this.composition.Type !== "") objectClone.composition = this.composition.Clone();
        if(this.informations) objectClone.informations = this.informations.Clone();
         
        return objectClone;
    }

    public EditAssetData(p_assetType: string, p_refOfPart: string | undefined = undefined, p_data: Asset3DData): void {
        this.assets.forEach((asset) => {
            if (p_refOfPart) {
                if (asset.RefOfPart === p_refOfPart && p_assetType === asset.Type) {
                    asset.Datas = p_data;
                    return;
                }
            } else if (p_assetType === asset.Type) {
                asset.Datas = p_data;
            }
        });
    }

    public EditAssetTransform(p_assetType: string, p_refOfPart: string | undefined = undefined, p_transform: Transform): void {
        this.assets.forEach((asset) => {
            if (p_refOfPart) {
                if (asset.RefOfPart === p_refOfPart && p_assetType === asset.Type && asset.Datas instanceof PhysicalAssetData) {
                    asset.Datas.Transform = p_transform;
                    return;
                }
            } else if (p_assetType === asset.Type && asset.Datas instanceof PhysicalAssetData) {
                asset.Datas.Transform = p_transform;
            }
        });
    }

    public GetAssetsByPart(): Map<string, Array<Asset3D>> {
        let assetsByPart: Map<string, Array<Asset3D>> = new Map<string, Array<Asset3D>>();

        // parcours de assets
        for (let i: number = 0; i < this.assets.length; i++) {
            // if part already exist in map
            if (assetsByPart.has(this.assets[i].RefOfPart)) {
                assetsByPart.get(this.assets[i].RefOfPart)!.push(this.assets[i]);
            } // else add key
            else {
                assetsByPart.set(this.assets[i].RefOfPart, [this.assets[i]]);
            }
        }

        return assetsByPart;
    }
}
