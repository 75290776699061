import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { MarkerComponent } from "../Components/MarkerComponent";
import { BoundingBoxComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundingBoxComponent";
import { RaycastableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/RaycastableComponent";
import { YControlableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/YControlableComponent";
import {Camera, Object3D, Plane, Vector3} from "three";
import { TranslatableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/TranslatableComponent";
import {DynamicScaleComponent} from "../../TransformControls/Components/DynamicScaleComponent";

export class MarkerDecorator {
    
    private readonly m_normal: Vector3;
    
    private readonly m_point: Vector3;
    
    public constructor(p_point: Vector3, p_normal: Vector3) {
        if (p_point == null) throw new Error('NullReferenceException : p_point is null or undefined');
        if (p_normal == null) throw new Error('NullReferenceException : p_normal is null or undefined');
        
        this.m_normal = p_normal;
        this.m_point = p_point;
    }

    public Decorate(p_entity: SceneEntity, p_mainCam: Camera): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        
        let marker = p_entity.AddComponentOfType(MarkerComponent, this.m_point, this.m_normal);
        p_entity.AddComponentOfType(BoundingBoxComponent, marker.GetMarker());
        p_entity.AddComponentOfType(RaycastableComponent, true);
        p_entity.AddComponentOfType(YControlableComponent, Object3D.DEFAULT_UP);
        p_entity.AddComponentOfType(DynamicScaleComponent, [marker.GetObject()], p_mainCam);
        let normal = new Vector3(0, 1, 0);
        let plane = new Plane(normal, p_entity.Transform.GetObject().position.dot(normal));
        p_entity.AddComponentOfType(TranslatableComponent, plane);
        
        // set measure marker at the extremum of sphere
        // let markerPos =  this.m_point.clone().add(this.m_normal.clone().multiplyScalar(marker.Width));
        p_entity.Transform.GetObject().position.copy(this.m_point.clone());
    }
}