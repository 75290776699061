import * as React from 'react';
import { Typography, Box, CardMedia, CardActionArea } from '@mui/material';


type CardMediaWithLabelProps = {
    label: string;
    onClick: () => void;
    media: string;
};

const CardMediaWithLabel = ({ label, onClick, media }: CardMediaWithLabelProps) => {
    return (
        <>
            <Box
                onClick={ onClick }
                sx={ {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                } }
            >
                <CardActionArea sx={ { borderRadius: '5px' } }>
                    <CardMedia
                        sx={ {
                            borderRadius: '5px',
                        } }
                        component='img'
                        alt={ label }
                        src={ media }
                    />
                </CardActionArea>
                <Typography sx={ { fontSize: 17, mt: 2 } } variant='subtitle1' color='primary'
                >
                    { label }
                </Typography>
            </Box>
        </>
    );
};

export default CardMediaWithLabel;