import MdfApiResponse from '../../../../domain/common/generic';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Product, TreeCatalog } from '../../../../domain/domain';
import visibilityServices from '../../../../api/service/visibility/visibility.services';

export type TreeCatalogListState = {
    isLoading: boolean;
    payload: MdfApiResponse<TreeCatalog[] | undefined>;
};

export const initialTreeCatalogListState: TreeCatalogListState = {
    isLoading: false,
    payload: { content: [], errors: [], warnings: [] },
};

export type SelectTreeCatalogState = {
    treeCatalog: TreeCatalog;
};
const initialSelectTreeCatalogState: SelectTreeCatalogState = {
    treeCatalog: {
        catalog: {
            numberOfPreset: 0,
            id: 0,
            version: 0,
            createdAt: new Date(),
            updatedAt: new Date(),
            name: '',
            reference: '',
        },
        sections: [],
    },
};

//#region find Catalog by admin
export const selectCatalogSlice = createSlice({
    name: 'selectCatalogSlice',
    initialState: initialSelectTreeCatalogState,
    reducers: {
        selectCatalog: (state, action) => {
            state.treeCatalog = action.payload;
        },
        resetSelectCatalog: (state) => {
            state = initialSelectTreeCatalogState;
            return state;
        },
    },
});
export const { resetSelectCatalog, selectCatalog } = selectCatalogSlice.actions;
//endregion

//#region find Catalog by admin
export const findCatalogByAdmin = createAsyncThunk('find/catalogByAdmin', async () => {
    const res = await visibilityServices.findCatalogByAdmin();
    return res;
});

export const findCatalogByAdminSlice = createSlice({
    name: 'getAllProductsInCatalogSectionSlice',
    initialState: initialTreeCatalogListState,
    reducers: {
        resetFindCatalogByAdmin: (state) => {
            state = initialTreeCatalogListState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(findCatalogByAdmin.pending, (state) => {
            state.isLoading = false;
            // @ts-ignore
            state.fullfiled = false;
        });
        builder.addCase(findCatalogByAdmin.fulfilled, (state, { payload }) => {
            // @ts-ignore
            state = { payload, isLoading: false, fullfiled: true };
            return state;
        });
        builder.addCase(findCatalogByAdmin.rejected, (state) => {
            // @ts-ignore
            state.fullfiled = false;
            state.isLoading = false;
        });
    },
});

export const { resetFindCatalogByAdmin } = findCatalogByAdminSlice.actions;
//#endregion
