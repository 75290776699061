import { ASystem } from "@lutithree/build/Modules/Core/Entity/ASystem";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { Object3D } from "three";
import { HookableComponent } from "../Components/HookableComponent";
import { HookComponent } from "../Components/HookComponent";
import { Guid } from "guid-typescript";
import { MeshFilterComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent";

export default class HookSystem extends ASystem<SceneEntity> {

    public InstanciateHookables(p_entitiesID: Guid[]|undefined = undefined): void {
        if(p_entitiesID) this.InstanciateHookablesOnEntities(p_entitiesID);
    }
    
    private InstanciateHookablesOnEntities(p_entitiesID: Guid[]): void {
        if (p_entitiesID == null) throw new Error('NullReferenceException : p_entitiesID is null or undefined');

        let hookables = this.m_entityManager.GetComponents(HookableComponent, {entity:false, component:true});
        hookables = hookables.filter( t => p_entitiesID.includes(t.EntityID));
        
        let hooks =  this.m_entityManager.GetComponents(HookComponent, {entity:false, component:true});
        hooks = hooks.filter( t => p_entitiesID.includes(t.EntityID));
        
        hookables.forEach((hookable)=>{
            let correspondingHooks = hooks.filter(x => hookable.HookRefs.some(y => x.HasName(y)));
            let result = this.BuildHookableInstances(hookable,correspondingHooks);
            if(result){
                let entity = this.m_entityManager.GetEntityByID(hookable.EntityID);
                let meshFilter = entity.HasComponentOfType(MeshFilterComponent)?entity.GetComponentOfType(MeshFilterComponent):undefined;
                if(meshFilter){
                    meshFilter.Model = result;
                }
            }
        });
    }

    private BuildHookableInstances(p_hookableComponent :HookableComponent, p_hooksComponents :HookComponent[]): Object3D {
        if (p_hookableComponent == null) throw new Error('NullReferenceException : p_hookableComponent is null or undefined');
        if (p_hooksComponents == null) throw new Error('NullReferenceException : p_hooksComponents is null or undefined');

        p_hookableComponent.Clear();

        let parentsGroup = new Object3D();
        p_hookableComponent.HookRefs.forEach((hookRef)=>{
            let parentInstances = new Object3D();
            parentsGroup.add(parentInstances);
            parentInstances.name = hookRef;
            let instances: Object3D[] = [];

            // Instanciate template 
            p_hooksComponents.forEach((hookComponent)=>{
                if(hookComponent.Hooks.has(hookRef)){
                    hookComponent.Hooks.get(hookRef)!.forEach((hook)=>{
                        let instance = p_hookableComponent.Template.clone();
                        instance.position.copy(hook.position);
                        instance.rotation.copy(hook.rotation);
                        instance.scale.copy(hook.scale);
                        instance.name = hook.name+'_'+instance.name;

                        parentInstances.add(instance);
                        instances.push(instance);
                    });
                    p_hookableComponent.AddHookInstances(hookComponent, instances);
                }
            });
        });
        
        return parentsGroup;
    }
}