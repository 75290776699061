import { Navigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCurrentUserInfo } from '../../services/redux/reducers/user/user-reducer';

type PrivateRouteProps = {
    el: any;
};

const PrivateRouteV6 = (props: PrivateRouteProps) => {
    const token = localStorage.getItem('mdf-jwt');

    const dispatch = useDispatch();

    useEffect(() => {
        if (token) {
            dispatch(getCurrentUserInfo());
        }
    }, [token]);

    return token ? props.el : <Navigate to="/login" />;
};
export default PrivateRouteV6;
