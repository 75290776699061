import React, { ReactElement } from 'react';
import { Paper, styled, Box, Typography } from '@mui/material';


export type ContextualMenuProps = {
    title: string;
    headerItem: ReactElement;
    bodyItems: ReactElement[];
};

const ContextualMenu = ({ title, headerItem, bodyItems }: ContextualMenuProps) => {
    return (
        <>
            <StyledPaperContextualMenu elevation={ 2 }>
                <StyledBox sx={ { p: title === '' ? 0 : 2 } }>
                    <Typography sx={ { mr: 2 } } variant='body2' color='primary'
                    >
                        { title }
                    </Typography
                    >
                    { headerItem }
                </StyledBox>
                <Box display='flex' flexDirection='row' flexWrap='wrap' justifyContent='center'
                >
                    { bodyItems }
                </Box>
            </StyledPaperContextualMenu>
        </>
    );
};

const StyledPaperContextualMenu = styled(Paper)(() => ({
    position: 'fixed',
    bottom: '1em',
    left: '42%',
}));

const StyledBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

export default ContextualMenu;
