import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../services/redux/root-reducers';
import { editRoomDesc, getRoom, GetRoomState, updateRoomName } from '../../services/redux/reducers/rooms/rooms.slice';
import { GetUserProjectByCode, resetUpdateProjectRoom, UpdateProjectRoomState } from '../../services/redux/reducers/projects/project.slice';
import { PdfGenerator } from '../../utils/pdf-generator';
import { deleteScreenShotfromRoom, ScreenshotState } from '../../services/redux/reducers/rooms/screenshot.slice';
import './room-detail-body.scss';
import CancelIcon from '@mui/icons-material/Cancel';
import ChairIcon from '@mui/icons-material/Chair';
import trash from '../../asset/poubelle.svg';
import DialogWithIcon from '../../ui-components/Dialog/DialogWithIcon';
import ButtonEdit from '../../ui-components/ButtonMui/ButtonEdit/ButtonEdit';
import { Box, Button, styled, Tab, TextField as MuiTextField, Typography } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SaveCancelButton from '../../business/ButtonAction/SaveCancelButton';
import dateFormatter from '../../utils/date-formatter';
import { numToMonth } from '../../pages/rooms/monthArray';
import l from '../../asset/CloseWindow.svg';
import arrowDown from '../../asset/Arrow-down.svg';
import { FindBasketByRoom } from '../../services/redux/reducers/basket/basket.slice';
import { BasketProduct, MdfApiResponse, PresetTree } from '../../domain/domain';
import { buildPresetConf, Conf } from '../../utils/build-preset-conf';
import { saveBasket } from '../../utils/saveRoom';
import basketServices from '../../api/service/basket.services/basket.services';

type RoomDetailBodyProps = {
    setOpenPreviewScreenshot: (openPreviewScreenshot: boolean) => void;
    setScreenPrev: (screenPrev: string) => void;
};

export type PresetConf = {
    presetRef: string;
    presetName: string;
    presetConf: Conf[];
};

const RoomDetailBody = (props: RoomDetailBodyProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const project = useSelector<RootState, GetUserProjectByCode>((state) => state.getUserProjectByCode);
    const { payload } = useSelector<RootState, GetRoomState>((state) => state.getRoom);
    const room = useSelector<RootState, GetRoomState>((state) => state.getRoom);
    const deleteScreenShotRes = useSelector<RootState, ScreenshotState>((state) => state.deleteScreenShot);
    const updateProjectRoomRes = useSelector<RootState, UpdateProjectRoomState>((state) => state.updateProjectRoom);
    const [editTitleMode, setEditTitleMode] = useState(false);
    const [newName, setNewName] = useState('');
    const [description, setDescription] = useState('');
    const [initialDescriptionValue, setInitialDescriptionValue] = useState('');
    const [initialRoomName, setInitialRoomName] = useState('');
    const [url, setUrl] = useState<string>('');
    const [openDeleteModal, setOpeneDeleteModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [datePayload, setDatePayload] = useState<Date>();
    const dateFormat = dateFormatter.dateTimeStampToDateTime(datePayload);
    const [tabValue, setTabValue] = React.useState('2');
    const [openDetailRefList, setOpenDetailRefList] = useState<string[]>([]);
    const [isMount, setIsmount] = useState(false);
    const [presetsListInBasket, setPresetsListInBasket] = useState<BasketProduct[]>([]);
    const [conf, setConf] = useState<PresetConf[]>([]);
    const [basket, setBasket] = useState<BasketProduct[]>([]);
    const roomBasket = room.payload.content && room.payload.content.room.basketProducts;

    const split = dateFormat.split('/');
    const monthNum = split[1];
    const dayNum = split[0];
    const yearNum = split[2];
    const monthString = numToMonth(monthNum);
    const array = [];
    array.push(dayNum, monthString, yearNum);
    const dueDate = array.join().replaceAll(',', ' ');
    const maxLengthInputDescription = 250;
    const maxLengthInputRoomTitle = 24;

    useEffect(() => {
        if (!isMount && params.name) {
            dispatch(FindBasketByRoom(params.name));
            dispatch(getRoom({ projectId: parseInt(params.projectId as string), roomUuid: params.name }));
        }
        return () => setIsmount(true);
    }, []);
    useEffect(() => {
        if (payload.content) {
            if (payload.content.room.description !== '' && payload.content.room.description != null) {
                const desc = JSON.parse(payload.content.room.description).description;
                setDescription(desc);
                setInitialDescriptionValue(desc);
            }
            setNewName(payload.content.room.name);
            setInitialRoomName(payload.content.room.name);
            setDatePayload(payload.content.room.updatedAt);
        }
    }, [payload]);
    useEffect(() => {
        if (deleteScreenShotRes.payload.content !== undefined && deleteScreenShotRes.payload.errors.length < 1) {
            dispatch(getRoom({ projectId: parseInt(params.projectId as string), roomUuid: params.name }));
        }
    }, [deleteScreenShotRes]);
    useEffect(() => {
        if (roomBasket) {
            basketServices
                .computeBasketPrices(roomBasket)
                .then((res: MdfApiResponse<BasketProduct[]>) => {
                    console.log(res);
                    setBasket(res.content);
                })
                .catch((err) => {
                    setBasket(roomBasket);
                    console.error(err);
                });
        }
    }, [room]);

    useEffect(() => {
        const PresetListInBasket: BasketProduct[] = basket.filter((pb) => pb.productType !== 'COMPOSITION');
        setPresetsListInBasket(PresetListInBasket);
    }, [basket]);
    useEffect(() => {
        let confList: PresetConf[] = [];
        presetsListInBasket.forEach((bp: BasketProduct, i) => {
            const findProduct = confList.find((p) => p.presetRef === bp.refOfInstance);
            const pc = bp.productContent as PresetTree;
            findProduct
                ? confList.push({
                      presetName: pc.product.name,
                      presetRef: bp.refOfInstance,
                      presetConf: buildPresetConf(pc.configuration),
                  })
                : confList.push({
                      presetName: bp.productContent.product.name,
                      presetRef: bp.refOfInstance,
                      presetConf: buildPresetConf(pc.configuration),
                  });
        });
        setConf(confList);
    }, [presetsListInBasket]);
    useEffect(() => {
        if (updateProjectRoomRes.payload.content && updateProjectRoomRes.payload.errors.length === 0) {
            dispatch(getRoom({ projectId: parseInt(params.projectId as string), roomUuid: params.name }));
            dispatch(resetUpdateProjectRoom());
        }
    }, [updateProjectRoomRes]);
    //#region method
    const onSubmitDesc = () => {
        if (description === initialDescriptionValue) {
            handleChangeEditMod();
        } else {
            dispatch(
                editRoomDesc({
                    projectId: parseInt(params.projectId as string),
                    roomId: params.name,
                    data: { description },
                })
            );
            setInitialDescriptionValue(description);
            handleChangeEditMod();
        }
    };
    const onSubmitRoomTitle = () => {
        if (newName === initialRoomName || newName === '' || newName === null) {
            handleEditTitle();
        } else {
            dispatch(
                updateRoomName({
                    projectId: parseInt(params.projectId as string),
                    roomId: params.name,
                    newName: newName,
                })
            );
            setInitialRoomName(newName);
            handleEditTitle();
        }
    };
    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    };
    const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewName(event.target.value);
    };
    const handleChangeEditMod = () => {
        setEditMode(!editMode);
    };
    const handleEditTitle = () => {
        setEditTitleMode(!editTitleMode);
    };
    const handleNothingChangeTitle = () => {
        setNewName(initialRoomName);
        handleEditTitle();
    };
    const handleNothingChange = () => {
        setDescription(initialDescriptionValue);
        handleChangeEditMod();
    };
    const addAndRemoveId = (ref: string) => {
        openDetailRefList.includes(ref) ? setOpenDetailRefList([]) : setOpenDetailRefList([ref]);
    };
    const addAndRemoveFromBasket = (basketProductRef: string) => {
        const currentRoom = room.payload.content && room.payload.content.room;
        if (currentRoom) {
            let currentRoomBasket: BasketProduct[] = currentRoom.basketProducts;
            const currentProduct = currentRoomBasket.find((el) => el.refOfInstance === basketProductRef);
            if (!currentProduct) return;
            currentRoomBasket = currentRoomBasket.filter((el) => el.refOfInstance !== currentProduct.refOfInstance);
            const temp = { ...currentProduct, inBasket: !currentProduct.inBasket };
            currentRoomBasket.push(temp);
            params.projectId && saveBasket(currentRoom, parseInt(params.projectId), currentRoomBasket, dispatch, '');
        }
    };
    //endregion

    const sortList = (list: BasketProduct[]) => {
        return list;
    };

    const computeTotalPrice = (basketProductList: BasketProduct[]) => {
        let total = 0;

        basketProductList.forEach((el) => {
            if (el.inBasket) {
                total += el.priceInCents;
            }
        });
        return total / 100;
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                }}
            >
                {editTitleMode ? (
                    <>
                        <BoxTextField>
                            <Box>
                                <StyledInput
                                    variant="outlined"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeTextField(e)}
                                    name="edit-room-name"
                                    value={newName}
                                    inputProps={{ maxLength: maxLengthInputRoomTitle }}
                                />
                                <TypoLength variant="overline" display="block" gutterBottom>
                                    {newName.length}/{maxLengthInputRoomTitle}
                                </TypoLength>
                            </Box>
                            <BoxResponsive>
                                <SaveCancelButton disabled={newName.length === 0} onSubmit={onSubmitRoomTitle} onClickCancel={handleNothingChangeTitle} />
                            </BoxResponsive>
                        </BoxTextField>
                    </>
                ) : (
                    <>
                        <BoxTextField>
                            <BoxWidthHead>
                                <Typography variant="h3" color="primary">
                                    {payload.content && payload.content.room.name}
                                </Typography>
                            </BoxWidthHead>
                            <BoxResponsive>
                                <ButtonEdit onClick={handleEditTitle} />
                            </BoxResponsive>
                        </BoxTextField>
                    </>
                )}
                <BoxRes>
                    <StyledExportBtn
                        size="medium"
                        onClick={() =>
                            PdfGenerator({
                                payload,
                                project,
                                description,
                                basketProducts: presetsListInBasket.filter((el) => el.inBasket),
                            })
                        }
                        startIcon={<FileUploadOutlinedIcon />}
                        disableElevation
                    >
                        Exporter en PDF
                    </StyledExportBtn>

                    <StyledSofa
                        variant="contained"
                        color="secondary"
                        size="medium"
                        onClick={() => navigate(`/planner/${parseInt(params.projectId as string)}/${payload.content?.projectCode}/${payload.content && payload.content.room.roomUuid}`)}
                        startIcon={<ChairIcon />}
                        disableElevation
                    >
                        Modifier la piece
                    </StyledSofa>
                </BoxRes>
            </Box>
            {dueDate === '--  -- --:--' ? null : (
                <Typography color="primary" variant="h4">
                    {dueDate}
                </Typography>
            )}
            <Box sx={{ width: '100%', mt: 4 }}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <CustomTabList onChange={handleChangeTab} aria-label="lab API tabs example">
                            <CustomTab label="Panier" value="1" />
                            <CustomTab label="Informations" value="2" />
                        </CustomTabList>
                    </Box>
                    <CustomTabPanel value="1">
                        <Typography color="primary" variant="h5" gutterBottom>
                            {sortList(presetsListInBasket).map((BP: BasketProduct) => {
                                return (
                                    <>
                                        {!BP.inBasket && (
                                            <div className="flout">
                                                <button onClick={() => addAndRemoveFromBasket(BP.refOfInstance)} className="b">
                                                    {BP.inBasket ? 'RETIRER DU PANIER' : 'RAJOUTER AU PANIER'}
                                                </button>
                                            </div>
                                        )}
                                        <div className={BP.inBasket ? 'instance-block' : 'instance-block-flout'} key={BP.refOfInstance}>
                                            <img className="i" alt="v" src={BP.basketProductScreenshot.length === 0 ? BP.productContent.product.thumbnailUrl : BP.basketProductScreenshot} />
                                            <div className="instance-right">
                                                <div className="instance-top">
                                                    <div className="instanceName">{BP.productContent.product.name}</div>
                                                    <div className="instance-r">
                                                        {/*<div
                                                            onClick={() =>
                                                                navigate(
                                                                    `/planner/${parseInt(params.projectId as string)}/${payload.content?.projectCode}/${
                                                                        payload.content && payload.content.room.roomUuid
                                                                    }`,
                                                                )
                                                            }
                                                            className='instance-button'
                                                        >
                                                            <img src={l} />
                                                            <button>MODIFIER</button>
                                                        </div>*/}
                                                        <></>
                                                        <div onClick={() => addAndRemoveFromBasket(BP.refOfInstance)} className="instance-button">
                                                            <img alt={BP.inBasket ? 'RETIRER DU PANIER' : 'AJOUTER DU PANIER'} src={l} />
                                                            <button className="b">{BP.inBasket ? 'RETIRER DU PANIER' : 'AJOUTER DU PANIER'}</button>
                                                        </div>
                                                        <div className="instance-price">
                                                            <span className="f">{(BP.priceInCents / 100).toFixed(2)}</span>
                                                            <span>dont Eco-taxes {(BP.ecoTaxInCents / 100).toFixed(2)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="instance-detail">
                                                    <div className="instance-detail-b" onClick={() => addAndRemoveId(BP.refOfInstance)}>
                                                        <span className="instance-detail-name">Détail</span>
                                                        <img className="instance-detail-a" src={arrowDown} />
                                                    </div>

                                                    {openDetailRefList.includes(BP.refOfInstance) && (
                                                        <div className={openDetailRefList.includes(BP.refOfInstance) ? 'd' : ''}>
                                                            {conf.map((pc: PresetConf, i) => {
                                                                return (
                                                                    <React.Fragment key={pc.presetRef}>
                                                                        {pc.presetRef === BP.refOfInstance &&
                                                                            pc.presetConf.map((c: Conf, ci) => {
                                                                                return (
                                                                                    <div key={c.partReference} className="conf">
                                                                                        <span className="partName">{c.partName}</span>
                                                                                        <div>
                                                                                            <div style={{ textDecorationLine: 'underline' }} className="partName">
                                                                                                Forme
                                                                                            </div>
                                                                                            <div className="item-atom">
                                                                                                {c.itemAtom.map((ia, index) => {
                                                                                                    return <div key={index}>{`${ia.partItemName} / ${ia.atomName}`}</div>;
                                                                                                })}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div style={{ textDecorationLine: 'underline' }} className="partName">
                                                                                                Matière
                                                                                            </div>
                                                                                            <div>
                                                                                                <span className="partName">Habillage:</span> {c.mappingName}
                                                                                            </div>
                                                                                            <div className="item-atomi">
                                                                                                <span className="partName">Zone / Coloris :</span>
                                                                                                {c.zoneColorisList.map((zc, id) => {
                                                                                                    return (
                                                                                                        <div className="item-atom" key={id}>
                                                                                                            <span className="zc">{zc.zoneName}</span>
                                                                                                            {zc.defaultColoris}
                                                                                                        </div>
                                                                                                    );
                                                                                                })}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div style={{ textDecorationLine: 'underline' }} className="partName">
                                                                                                Options
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h5">Total</Typography>
                            <Typography variant="h5">{computeTotalPrice(presetsListInBasket).toFixed(2)} €</Typography>
                        </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value="2">
                        <Typography color="primary" variant="h5" gutterBottom>
                            Notes personnelles
                        </Typography>
                        <Box sx={{ mb: editMode ? 0 : 6 }}>
                            {editMode ? (
                                <>
                                    <BoxTextField style={{ marginRight: 0 }}>
                                        <Box>
                                            <CustomTextField
                                                id="outlined-multiline-static"
                                                multiline
                                                rows={3}
                                                value={description}
                                                onChange={handleChange}
                                                placeholder="Entrez une note personnelle"
                                                inputProps={{ maxLength: maxLengthInputDescription }}
                                            />
                                            <TypoLength variant="overline" display="block" gutterBottom>
                                                {description.length}/{maxLengthInputDescription}
                                            </TypoLength>
                                        </Box>
                                        <BoxResponsive>
                                            <SaveCancelButton onSubmit={onSubmitDesc} onClickCancel={handleNothingChange} />
                                        </BoxResponsive>
                                    </BoxTextField>
                                </>
                            ) : (
                                <>
                                    <BoxTextField>
                                        <Box
                                            sx={{
                                                width: 419,
                                            }}
                                        >
                                            {description === undefined || description === '' ? (
                                                <TypoColor>Entrez une note personnelle</TypoColor>
                                            ) : (
                                                <Typography sx={{ fontWeight: 300, fontSize: 16 }} color="primary">
                                                    {description}
                                                </Typography>
                                            )}
                                        </Box>
                                        <BoxResponsive>
                                            <ButtonEdit onClick={handleChangeEditMod} />
                                        </BoxResponsive>
                                    </BoxTextField>
                                </>
                            )}
                        </Box>
                        <Typography color="primary" variant="h5">
                            Captures d'écran
                        </Typography>
                        <div className="screenShotList">
                            {payload.content &&
                                payload.content.room.screenshotsUrls.map((screenShot: string, i: number) => {
                                    return (
                                        <div key={i} className="screenShot">
                                            <div
                                                onClick={() => {
                                                    setUrl(screenShot);
                                                    setOpeneDeleteModal(true);
                                                }}
                                                className="trash"
                                            >
                                                <img alt="poubelle" src={trash} />
                                            </div>
                                            <img
                                                onClick={() => {
                                                    props.setScreenPrev(screenShot);
                                                    props.setOpenPreviewScreenshot(true);
                                                }}
                                                className="screen"
                                                alt="¬screenShot"
                                                src={screenShot}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    </CustomTabPanel>
                </TabContext>
            </Box>
            {openDeleteModal && (
                <DialogWithIcon
                    onClose={() => setOpeneDeleteModal(false)}
                    open={openDeleteModal}
                    title="Supprimer la capture d'écran ?"
                    subtitle="Cette action est irréversible"
                    icon={<CancelIcon />}
                    btnAction="Supprimer"
                    themeColor="red"
                    onClickAction={() => {
                        if (params.projectId && params.name) {
                            dispatch(
                                deleteScreenShotfromRoom({
                                    projectId: parseInt(params.projectId),
                                    roomUuid: params.name,
                                    fileUrl: { fileUrl: url },
                                })
                            );
                        }
                        setOpeneDeleteModal(false);
                    }}
                />
            )}
        </>
    );
};
const StyledExportBtn = styled(Button)(({ theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.grey[300],
    },
    background: theme.palette.grey[100],
    color: theme.palette.primary.main,
    padding: '14px 24px',
    marginRight: 30,
    [theme.breakpoints.down('lg')]: {
        marginRight: 0,
        marginBottom: 10,
    },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    fontSize: 18,
    '&.Mui-selected': {
        color: 'white',
        background: theme.palette.primary.main,
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
    },
}));

const CustomTabList = styled(TabList)(({ theme }) => ({
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
}));

const CustomTabPanel = styled(TabPanel)(() => ({
    paddingRight: 0,
    paddingLeft: 0,
}));

const StyledSofa = styled(Button)(() => ({
    padding: '14px 24px',
}));

const BoxWidthHead = styled(Box)(({ theme }) => ({
    width: 300,
    [theme.breakpoints.down('md')]: {
        width: 'auto',
    },
}));

const CustomTextField = styled(MuiTextField)(({ theme }) => ({
    '& ::-webkit-input-placeholder': {
        fontStyle: 'italic',
    },
    '& .MuiInputBase-root': {
        fontWeight: 300,
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
    width: 426,
}));

const StyledInput = styled(MuiTextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        fontSize: 22,
        '& input': {
            padding: '7.5px 11px',
        },
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
}));

const BoxTextField = styled(Box)(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('md')]: {
        display: 'block',
        marginRight: 10,
    },
}));

const BoxRes = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

const BoxResponsive = styled(Box)(({ theme }) => ({
    marginLeft: 10,
    [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        marginBottom: 10,
    },
}));

const TypoColor = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[300],
    fontStyle: 'italic',
    fontWeight: 300,
}));

const TypoLength = styled(Typography)({
    color: '#dbdbdb',
    fontSize: '0.90rem',
    display: 'flex',
    justifyContent: 'flex-end',
});

export default RoomDetailBody;
