import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { SelectableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SelectableComponent";
import { GroupComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/GroupComponent";
import { RaycastableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/RaycastableComponent";
import { Engine } from "@lutithree/build/Engine";
import { BoundingBoxComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundingBoxComponent";
import ObjectSystem from "../../../Application3D/GameLogic/Objects/Composition/ObjectSystem";
import AService from "../../../Application3D/Domain/AService";
import { IObjectDecorator } from "../../../Application3D/Domain/Objects/AssetAssembly/IObjectDecorator";
import BasicObject from "../../../Application3D/Domain/Objects/AssetAssembly/BasicObject";
import Information from "../../../Application3D/Domain/Objects/Information";
import {DimensionComponent} from "../../../Application3D/GameLogic/Features3D/Dimensions/Components/DimensionComponent";
import ObjectComponent from "../../../Application3D/GameLogic/Objects/Components/ObjectComponent";
import PartComponent from "../../../Application3D/GameLogic/Objects/Components/PartComponent";
import ActionComponent from "../../../Application3D/GameLogic/Objects/Components/ActionComponent";
import {ObjectAction} from "../../../Application3D/Domain/Objects/ObjectAction";
import InfoComponent from "../../../Application3D/GameLogic/Objects/Components/InfoComponent";
import {Vector3} from "three";

export class ObjectDecorator extends AService implements IObjectDecorator {
    private m_objectSystem: ObjectSystem;
    
    public constructor(p_engine: Engine) {
        super(p_engine);
        this.m_objectSystem = new ObjectSystem(p_engine.Modules.Scene);
    }
    
    public DecorateRootObject(p_entity: SceneEntity, p_object: BasicObject): void {
        if (p_object == null) throw new Error('NullReferenceException : p_object is null or undefined');
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        p_entity.AddComponentOfType(BoundingBoxComponent, p_entity.Transform.GetObject());
        let objectComponent = p_entity.AddComponentOfType(ObjectComponent, p_object.RefOfInstance);
        this.AddDimensionComponent(p_entity, p_object.Informations);
        this.ApplySpawnHeight(p_entity, p_object.Informations, 10);
    }

    public DecorateObject(p_entity: SceneEntity, p_object: BasicObject): void {
        if (p_object == null) throw new Error('NullReferenceException : p_object is null or undefined');
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        p_entity.AddComponentOfType(BoundingBoxComponent, p_entity.Transform.GetObject());
        let objectComponent = p_entity.AddComponentOfType(ObjectComponent, p_object.RefOfInstance);
        p_entity.AddComponentOfType(SelectableComponent);
        p_entity.AddComponentOfType(GroupComponent, p_object.RefOfInstance);
        p_entity.AddComponentOfType(ActionComponent, p_object.RefOfInstance, [
            ObjectAction.Edit,
            ObjectAction.Replace,
            ObjectAction.Duplicate,
            ObjectAction.Delete,
            ObjectAction.GetInformations,
        ]);
        this.AddDimensionComponent(p_entity, p_object.Informations);
        if (p_object.Informations) {
            if (p_object.Informations.Type != '') {
                p_entity.AddComponentOfType(InfoComponent, p_object.Informations);
            }
        }
        this.ApplySpawnHeight(p_entity, p_object.Informations, 10);
    }

    public DecoratePart(p_entity: SceneEntity, p_parentObject: BasicObject, p_parentEntity:SceneEntity, p_refOfPart: string): void {
        if (!p_refOfPart) throw new Error('NullReferenceException : p_refOfPart is null or undefined or empty');
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (!p_parentObject) throw new Error('NullReferenceException : p_parentObject is null or undefined or empty');

        if(p_parentObject.Assets.length > 0) {/** @deprecated basicObject.assets should not be used*/
            p_entity.AddComponentOfType(PartComponent, ()=>{return p_parentObject.Ref;}, p_refOfPart, 'Asset3D');
            p_entity.AddComponentOfType(RaycastableComponent);
        }
        else {
            p_entity.AddComponentOfType(PartComponent, ()=>{return p_parentObject.Ref;}, p_refOfPart, p_parentObject.Composition.Type);
            if(p_parentObject.Composition.Type === 'Asset3D') p_entity.AddComponentOfType(RaycastableComponent, 0);
        }
       
        if(!p_entity.HasComponentOfType(GroupComponent)) p_entity.AddComponentOfType(GroupComponent, p_parentObject.Ref);
        if (p_entity.HasComponentOfType(ObjectComponent))
            this.m_objectSystem.GetNestedPartEntities(p_entity.GetComponentOfType(ObjectComponent)).forEach((entity) => {
                entity.AddComponentOfType(GroupComponent, p_parentObject.RefOfInstance);
            });
    }

    private AddDimensionComponent(p_entity: SceneEntity, p_objectInfo: Information): void {
        p_entity?.AddComponentOfType(DimensionComponent, p_objectInfo.Dimensions);
    }

    private ApplySpawnHeight(p_entity: SceneEntity, p_objectInfo: Information, p_maxHeight: number | undefined): void {
        if (p_objectInfo.SpawnHeight) {
            let spawnYInMeter = p_objectInfo.SpawnHeight / 100;
            if (p_maxHeight) {
                if (p_entity.HasComponentOfType(BoundingBoxComponent, false)) {
                    let bb = p_entity.GetComponentOfType(BoundingBoxComponent);
                    if ((spawnYInMeter + bb.Center.y + bb.HalfSize.y) > p_maxHeight)
                        spawnYInMeter = p_maxHeight - bb.Center.y - bb.HalfSize.y;
                }
            }
            p_entity?.Transform.AddToPosition(new Vector3(0, spawnYInMeter, 0));
        }
    }
}