import { Dispatch } from "redux";
import BasicObject
    from "../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import AssignmentData
    from "../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/AssignmentData";
import {editObjectAssetData} from "../Redux/Reducers/room-studio/RoomStudioReducer";

export const editDoorMaterial = (url: string[], object: BasicObject | undefined, dispatch: Dispatch): void => {
    if (object) {
        dispatch(editObjectAssetData({ basicObject: object, type: 'Material', data: url, refOfPart: 'Base' }));
    }
};

export const editWindowMaterial = (url: string[], object: BasicObject | undefined, dispatch: Dispatch): void => {
    if (object) {
        let stateClone: BasicObject = object.Clone();
        let materialAssignement = stateClone.Assets.find((x) => x.Type === 'MaterialAssignment' && x.RefOfPart === 'Base');
        if (materialAssignement) {
            let datas = materialAssignement.Datas as AssignmentData[];
            datas.forEach((assignment) => {
                if (assignment.Refs.includes('WINDOW')) {
                    assignment.Urls = url;
                }
            });
            dispatch(editObjectAssetData({ basicObject: object, type: 'MaterialAssignment', data: datas, refOfPart: undefined }));
        }
    }
};

export const editAllMaterialsInObject = (object: BasicObject, refOfPart: string, materials: AssignmentData[], dispatch: Dispatch): void => {
    if (object) {
        dispatch(editObjectAssetData({ basicObject: object, type: 'MaterialAssignment', data: materials, refOfPart: refOfPart }));
    }
};

export const editSingleMaterialInObject = (object: BasicObject, refOfPart: string, material: AssignmentData, dispatch: Dispatch): void => {
    if (object) {
        let stateClone: BasicObject = object.Clone();
        
        let materialAssignements = stateClone.Assets.find((x) => x.Type === 'MaterialAssignment' && x.RefOfPart === refOfPart);

        if (materialAssignements) {
            let datas = materialAssignements.Datas as AssignmentData[];
            for (let i = 0; i < datas.length; i++) {
                if (datas[i].Refs.every((matID) => material.Refs.includes(matID)) && material.Refs.length === datas[i].Refs.length) {
                    datas[i].Urls = [...material.Urls];
                }
            }

            dispatch(editObjectAssetData({ basicObject: object, type: 'MaterialAssignment', data: datas, refOfPart: refOfPart }));
        }
    }
};
