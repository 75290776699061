import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import trash from '../../asset/Poubelle-p.svg';
import BackProject from '../../asset/FondProjets.svg';
import IconButton from '@mui/material/IconButton';
import dateFormatter from '../../utils/date-formatter';
import { useDispatch } from 'react-redux';
import {
    resetCreateProjects, resetImportProjects,
} from '../../services/redux/reducers/projects/project.slice';
import { Card, styled, Box, Typography } from '@mui/material';


type ProjectCardProps = {
    title: string;
    created?: Date;
    code?: string;
    projectId?: number;
    setOPenM?: (openM: boolean) => void;
    onclick?: () => void;
    setProjectName: (projectName: string) => void
    name: string
};

const ProjectCard = ({ title, created, projectId, code, onclick, name, setProjectName }: ProjectCardProps) => {
    const dateFormat = dateFormatter.dateTimeStampToDateTime(created);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetCreateProjects());
        dispatch(resetImportProjects());
    }, []);

    return (
        <>
            <CustomProjectCard>
                <Box onClick={ () => navigate(`/project/${ projectId }/${ code }`) }>
                    <img style={ { position: 'absolute', top: 28, right: 16 } } src={ BackProject } alt='logo' />
                    <Box sx={ { position: 'absolute' } }>
                        <Typography sx={ { fontWeight: 300, mb: 2 } } variant='body2' color='primary'>
                            { dateFormat }
                        </Typography>
                        <Box>
                            <Typography sx={ { fontWeight: 600, fontSize: 20 } } variant='body1'
                                        color='primary'>{ title }</Typography>
                            <Typography sx={ { lineHeight: 0.50 } } variant='body2'
                                        color='primary'> { code }</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={ { position: 'absolute', top: 8, right: 16 } }>
                    <IconButton color='primary' aria-label='delete-icone'
                                onClick={ () => {
                                    setProjectName(name);
                                    onclick && onclick();
                                } }
                                size='small'>
                        <img src={ trash } alt='logo poubelle' />
                    </IconButton>
                </Box>
            </CustomProjectCard>
        </>
    );
};

const CustomProjectCard = styled(Card)(({ theme }) => ({
        boxShadow: '0px 2px 4px #00000029',
        borderTop: `8px solid ${ theme.palette.secondary.main }`,
        borderRadius: 3,
        height: '100%',
        padding: '8px 10px',
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        position: 'relative',
        minHeight: 143,
    }
));

export default ProjectCard;
