import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { SelectableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SelectableComponent";
import { GroupComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/GroupComponent";
import { AObjectLoadedHandler } from "./AObjectLoadedHandler";
import ObjectLoadedEvent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/Events/ObjectLoadedEvent";
import PartComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/PartComponent";
import ObjectComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";

export class ObjectLoadedInConfiguringHandler extends AObjectLoadedHandler implements IHandler<ObjectLoadedEvent> {
    public Handle(p_event: ObjectLoadedEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');

        this.LoadObjectAtConfiguring(p_event.Entity);
    }

    private LoadObjectAtConfiguring(p_entity:SceneEntity): void{
        this.LoadObjectCommonActions(p_entity);
        let selectable = p_entity.HasComponentOfType(SelectableComponent)?p_entity.GetComponentOfType(SelectableComponent):undefined;
        let objectComponent = p_entity.HasComponentOfType(ObjectComponent)?p_entity.GetComponentOfType(ObjectComponent):undefined;
        let groups = p_entity.GetComponentsOfType(GroupComponent);

        // Part object
        if(p_entity.HasComponentOfType(PartComponent)){
            if(objectComponent){
                groups.forEach((groupComponent)=>{
                    if(groupComponent.GroupRef !== objectComponent!.Ref) {
                        groupComponent.Enable(false);
                    }
                });
            }
        }
        // Root object
        else{
            if(selectable) selectable.Enable(false);
        }
    }
}