import Instance3D from "../AssetAssembly/Instance3D";
import { IPartElement } from "./IPartElement";
import Connectivity from "./Connectivity";

export default class Composition {
    private dataModelVersion: string;

    private type: string = "";

    private partElements: IPartElement[];

    private instances3D?: Instance3D[];

    private connectivity?: Connectivity;

    public constructor() {
        this.dataModelVersion = '';
        this.type = '';
        this.partElements = [];
    }

    public get DataModelVersion(): string {
        return this.dataModelVersion;
    }

    public get Type(): string {
        return this.type;
    }

    public get PartElements(): IPartElement[] {
        return this.partElements;
    }

    public set PartElements(p_partElement: IPartElement[]) {
        this.partElements = p_partElement;
    }
    
    public get Instances3D(): Instance3D[]|undefined {
        return this.instances3D;
    }

    public get Connectivity(): Connectivity|undefined {
        return this.connectivity;
    }

    public set Connectivity(p_connectivity: Connectivity|undefined) {
        this.connectivity = p_connectivity;
    }
    
    public Clone(): Composition {
        let compositionClone = new Composition();
        compositionClone.dataModelVersion = this.dataModelVersion;

        compositionClone.type = this.type;
        compositionClone.partElements = JSON.parse(JSON.stringify(this.partElements));
        compositionClone.instances3D = JSON.parse(JSON.stringify(this.instances3D));
        compositionClone.connectivity = this.connectivity?.Clone();
        
        return compositionClone;
    }

    public CopyDatas(p_type: string, p_instances3D: Instance3D[], p_partElements: IPartElement[], p_connectorsInfos: Connectivity, p_dataModelVersion: string): void {
        this.dataModelVersion = p_dataModelVersion;
        this.type = p_type;
        this.partElements = p_partElements;
        this.instances3D = p_instances3D;
        this.connectivity = p_connectorsInfos;
    }

    public GetInstance3D(p_partRef: string): Instance3D | undefined {
        if (!p_partRef) throw new Error('NullReferenceException : p_partRef is null or undefined or empty');

        if(this.instances3D) return this.instances3D.find((x) => x.Ref === p_partRef);
        else return undefined;
    }
    
    public GetPartElementsByRef(): Map<string, IPartElement[]> {
        let partElementsByPart: Map<string, Array<IPartElement>> = new Map<string, Array<IPartElement>>();

        // parcours des partElements
        for (let i: number = 0; i < this.partElements.length; i++) {
            // if part already exist in map
            if (partElementsByPart.has(this.partElements[i].Ref)) {
                partElementsByPart.get(this.partElements[i].Ref)!.push(this.partElements[i]);
            } // else add key
            else {
                partElementsByPart.set(this.partElements[i].Ref, [this.partElements[i]]);
            }
        }

        return partElementsByPart;
    }
    
    public AddPartElement(p_newPartElement: IPartElement): void {
        if (p_newPartElement !== null) throw new Error('NullReferenceException : p_newPartElement is null or undefined');
        
        if(typeof p_newPartElement === this.type){
            this.partElements.push(p_newPartElement);
        }
    }
    
    public AddConnectors(): void {
        
    }
    
    public AddConnection(p_refOfPartElement1: string, p_refOfPartElement2: string, p_connectorRole2: string): void {
        if (!p_refOfPartElement1) throw new Error('NullReferenceException : p_refOfPartElement1 is null or undefined or empty');
        if (!p_refOfPartElement2) throw new Error('NullReferenceException : p_refOfPartElement2 is null or undefined or empty');
        if (!p_connectorRole2) throw new Error('NullReferenceException : p_connectorRole2 is null or undefined or empty');
        
    }
}