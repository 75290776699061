import { IHandler } from '@lutithree/build/Modules/Core/Event/IHandler';
import { Engine } from "@lutithree/build/Engine";
import { AHandler } from "@lutithree/build/Handlers/AHandler";
import { DoubleTapEvent } from "@lutithree/build/Modules/Core/Inputs/Events/DoubleTapEvent";
import CamerasService from "../../Studio/Cameras/CamerasService";

export class DoubleTapHandler extends AHandler implements IHandler<DoubleTapEvent> {

    private m_cameraService: CamerasService;
    
    public constructor(p_engine: Engine, p_camera: CamerasService) {
        if (p_camera == null) throw new Error('NullReferenceException : p_translation is null or undefined');
        super(p_engine);
        
        this.m_cameraService = p_camera;
    }

    public Handle(p_event: DoubleTapEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');
        this.m_cameraService.Move360CameraForward();
    }
}
