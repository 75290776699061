import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Engine } from '@lutithree/build/Engine';
import {EngineViewer} from "../../../application3D-common/Components/EngineViewer/EngineViewer";
import RoomStudioApp from "../../Libraries/Studios/RoomStudio/RoomStudioApp";
import {RootState} from "../../../../services/redux/root-reducers";
import {RoomStudioState, setRoomStudioApp} from "../../Redux/Reducers/room-studio/RoomStudioReducer";

export type RoomStudioViewerProps = {};

const RoomStudioViewer = (props: RoomStudioViewerProps) => {
    const dispatch = useDispatch();
    const { roomStudioApp } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);

    const buildApplication3d = (p_dom: HTMLDivElement) => {
        let engine = new Engine(p_dom);
        let appPromise = new Promise<RoomStudioApp>((resolve, reject) => {
            engine.Initialize().then(() => {
                let studioApp = new RoomStudioApp(engine);
                engine.Start();
                resolve(studioApp);
                dispatch(setRoomStudioApp(studioApp));
            });
        });
    };

    const updateApplicationDomNode = (p_dom: HTMLDivElement) =>{
        if(roomStudioApp && p_dom){
            roomStudioApp.EngineService.UpdateDom(p_dom);
        }
    };

    return (
        <>
            <EngineViewer
                engine={roomStudioApp? roomStudioApp.EngineService : null}
                onDomNodeEnable={(p_dom: HTMLDivElement) => buildApplication3d(p_dom)}
                onDomNodeUpdate={(p_dom: HTMLDivElement) => updateApplicationDomNode(p_dom)}
            />
        </>
    );
};

export { RoomStudioViewer };
