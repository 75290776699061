import { RoomDTO } from '../../../domain/domain';


const GET_ROOM_MODEL = () => '/v1/room-model/';
const ADD_ROOM = (projectId: number, templateId: number) => `/v1/projects/${ projectId }/${ templateId }`;
const GET_ROOM = (projectId: number | undefined, roomUuid: string | undefined) => `/v1/projects/${ projectId }/room/${ roomUuid }`;
const DELETE_ROOM = (projectId: number, roomUuid: string) => `/v1/projects/${ projectId }/delete-room/${ roomUuid }`;
const DUPLICATE_ROOM = (projectId: number | undefined, roomName: string | undefined) => `/v1/projects/${ projectId }/room/${ roomName }/duplicate`;
const UPDATE_ROOM_NAME = (projectId: number | undefined, roomId: string | undefined, newName: string | undefined) =>
    `/v1/projects/${ projectId }/room/${ roomId }/rename/${ newName }`;
const EDIT_ROOM_DESCRIPTION = (projectId: number | undefined, roomId: string | undefined) =>
    `/v1/projects/${ projectId }/room/${ roomId }/edit-description`;


export {
    GET_ROOM_MODEL,
    ADD_ROOM,
    GET_ROOM,
    DELETE_ROOM,
    DUPLICATE_ROOM,
    UPDATE_ROOM_NAME,
    EDIT_ROOM_DESCRIPTION,
};
