import * as React from 'react';
import { styled, ToggleButton, Box } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


type ButtonToggleRoomProps = {
    text: JSX.Element;
    actions: () => void;
    logo: string;
    altLogo: string;
    disabled?: boolean;
    active?: boolean;
    expand?: boolean;
};

const ButtonToggleRoom = ({ text, actions, logo, altLogo, disabled, active, expand }: ButtonToggleRoomProps) => {
    return (
        <Box sx={ { position: 'relative', height: '100%', width: '100%' } }>
            <StyledToggleButton
                sx={ {
                    background: () => active ? `rgba(25, 118, 210, 0.08)` : null,
                    '&:hover': {
                        background: () => active ? 'rgba(25, 118, 210, 0.12)' : null,
                    },
                } }
                disabled={ disabled }
                onClick={ actions }
                value='center'
                aria-label='centered'>
                <img alt={ altLogo } src={ logo } />
                { text }
            </StyledToggleButton>
            { expand && (
                active ? (
                    <ExpandMoreIcon color='secondary' sx={ { position: 'absolute', fontSize: 23, top: 0, right: 0 } } />
                ) : (
                    <ExpandLessIcon color='primary' sx={ { position: 'absolute', fontSize: 23, top: 0, right: 0 } } />
                )) }
        </Box>
    );
};

const StyledToggleButton = styled(ToggleButton)(() => ({
    '& .Mui-disabled': {
        border: 0,
    },
    height: '100%',
    width: '100%',
    border: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1px',
    justifyContent: 'space-evenly',
}));

export default ButtonToggleRoom;