import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoomObject } from "../RoomStudioHooks";
import RoomStudioApp from "../../../Libraries/Studios/RoomStudio/RoomStudioApp";
import {RootState} from "../../../../../services/redux/root-reducers";
import {RoomStudioState, setRoomNewState} from "../../../Redux/Reducers/room-studio/RoomStudioReducer";
import {PanelState} from "../../../../../services/redux/reducers/panel/panel.slice";
import {updateObject} from "../../../Redux/Reducers/room-studio/RoomContentReducer";
import ObjectDatas from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ObjectDatas";

export type GroundEditorProps = {
    roomStudio : RoomStudioApp;
};

const GroundEditor = (props: GroundEditorProps) => {
    const dispatch = useDispatch();

    const { roomAssetToEdit, roomNewState, roomLastState } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const { floorPanel } = useSelector<RootState, PanelState>((state) => state.panel);
    const groundObject = useRoomObject("Ground");

    useEffect(() => {
        let newState = ObjectDatas.GetObjectDatasForObject(roomNewState);
        let lastState = ObjectDatas.GetObjectDatasForObject(roomLastState);
        if(newState && lastState){
            let editPromise = props.roomStudio.Services.RoomObjects.EditRootObjectAsync(newState,lastState);
            editPromise?.then(()=>{
                dispatch(updateObject(newState!.BasicObjects));
            });
        }
    }, [roomLastState]);

    useEffect(() => {
        let stateClone = roomNewState?.Clone();
        if(stateClone && roomAssetToEdit)
            stateClone.EditAssetData(roomAssetToEdit.type, roomAssetToEdit.refOfPart, roomAssetToEdit.data);
        dispatch(setRoomNewState(stateClone));
    }, [roomAssetToEdit]);

    useEffect(() => {
        if(floorPanel){
            dispatch(setRoomNewState(groundObject));
        }
        else{
            dispatch(setRoomNewState(undefined));
        }
    }, [floorPanel]);


    return (
        <></>
    );
};

export { GroundEditor };
