import Asset3D from "../../Domain/Objects/Assets/Asset3D";
import { Asset3DData } from "../../Domain/Objects/Assets/Asset3DData";
import Composition from "../../Domain/Objects/Composition/Composition";
import Model3dData from "../../Domain/Objects/Assets/Model3dData";
import AssignmentData from "../../Domain/Objects/Assets/AssignmentData";
import GeometryData from "../../Domain/Objects/Assets/GeometryData";
import ShapeData from "../../Domain/Objects/Assets/ShapeData";
import Instance3D from "../../Domain/Objects/AssetAssembly/Instance3D";
import Connection from "../../Domain/Objects/Composition/Connection";
import BasicObject from "../../Domain/Objects/AssetAssembly/BasicObject";
import { Option } from "../../Domain/Objects/Assets/Option";
import PartTransformData from "../../Domain/Objects/Assets/PartTransformData";
import Transform from "@lutithree/build/Modules/WebGL/Scene/DataModel/Transform";
import Connector from "../../Domain/Objects/Composition/Connector";
import Connectivity from "../../Domain/Objects/Composition/Connectivity";
import {Spherical} from "three";
import Information from "../../Domain/Objects/Information";
import ObjectDatas from "../../Domain/Objects/ObjectDatas";
import Assembly from "../../Domain/Objects/Composition/Assembly";
import {CompositionWrapper} from "../../../../../../../domain/domain";

export class ObjectParser {
    
    public static DeepParseObject<T extends BasicObject>(p_objectToParse : Object, p_objectType: { new (...args: any[]): T }): T {
        if (p_objectToParse == null) throw new Error('NullReferenceException : p_jsonData is null or undefined');
        let partObject = Object.assign(new p_objectType(), p_objectToParse);
        
        if (partObject.PointOfView) {
            partObject.PointOfView = Object.assign(new Spherical(), partObject.PointOfView);
            partObject.PointOfView = partObject.PointOfView.makeSafe();
        } else
            partObject.PointOfView = undefined;


        if(partObject.Composition){
            partObject.Composition = ObjectParser.DeepParseComposition(partObject.Composition, p_objectType);
        }

        /** @deprecated basicObject.assets should not be used*/
        if(partObject.Assets){
            for(let i:number = 0; i<partObject.Assets.length; i++) {
                partObject.Assets[i] = ObjectParser.DeepParseAsset3D(partObject.Assets[i]);
            }
        }

        /** @deprecated basicObject.assets should not be used*/
        if(partObject.TransformByParts){
            for(let i:number = 0; i<partObject.TransformByParts.length; i++) {
                partObject.TransformByParts[i] = ObjectParser.DeepParsePartTransformData(partObject.TransformByParts[i]);
            }
        }

        if (partObject.Informations) {
            partObject.Informations = Object.assign(new Information(), partObject.Informations);
        }

        if (partObject.Assembly) {
            partObject.Assembly = Object.assign(new Assembly(), partObject.Assembly);
        }
        
        return partObject;
    }

    public static RemapData(p_inputData: CompositionWrapper): ObjectDatas {
        let plannerObjects: BasicObject[] = [];
        let CompositionObject = new BasicObject();
        CompositionObject.RefOfInstance = p_inputData.productComposition.reference;
        CompositionObject.Informations.Name = p_inputData.productComposition.name;
        CompositionObject.Informations.Type = p_inputData.productSheet.productType;
        CompositionObject.Informations.Behaviours = [p_inputData.productSheet.productBehaviour];
        plannerObjects.push(CompositionObject);
        for (let i = 0; i < p_inputData.checkedCompositionModules.length; i++){
            const comp = p_inputData.checkedCompositionModules[i];
            
        let isProductSimple = comp.module.product.productType === "SIMPLE";

        let moduleRef = comp.module.moduleRefOfInstance;
        let moduleName = comp.module.presetTree.metadata.refOfInstance;
        let moduleType = comp.module.product.productType;
        let moduleBehaviours = isProductSimple ? [] : [comp.module.presetTree.metadata.behaviours];
        let simpleData = comp.module.presetTree.configuration[0].model3d;

        if(i > 0 && i < p_inputData.checkedCompositionModules.length){
            let link = new Connection();
            link.DataModelVersion = "1.0.0";
            link.RefOfConnectorA = plannerObjects[i].RefOfInstance + "_RIGHT";
            link.RefOfConnectorB = moduleRef + "_LEFT";
            CompositionObject.Assembly.AddConnection(link);
        }
        
        CompositionObject.Assembly.AddSubObjectsRef(moduleRef);
        if (comp.possibleModulesLeft.length > 0) {
            let connection = new Connector();
            connection.IsAvailable = true;
            connection.RefOfInstance = moduleRef;
            connection.Role = 'LEFT';
            CompositionObject.Assembly.AddConnector(connection);
        }
        if (comp.possibleModulesRight.length > 0) {
            let connection = new Connector();
            connection.IsAvailable = true;
            connection.RefOfInstance = moduleRef;
            connection.Role = 'RIGHT';
            CompositionObject.Assembly.AddConnector(connection);
        }
        let AdditionalObject = new BasicObject();
        AdditionalObject.RefOfInstance = moduleRef;
        AdditionalObject.Informations.Name = moduleName;
        AdditionalObject.Informations.Type = moduleType;
        AdditionalObject.Informations.Behaviours = moduleBehaviours;
        AdditionalObject.Informations.Deletable = comp.deletable;
        if (isProductSimple) {
            simpleData.forEach((data) => {
                AdditionalObject.Assets.push(Object.assign(new Asset3D(), {
                    refOfPart: data.partReference,
                    refOfPartItem: data.reference,
                    type: "Model3d",
                    datas: {urls: [data.modelUrl]}
                }));
            });
        } else {
            comp.module.presetTree.metadata.composition.partElements.forEach((p_value: any) => {
                AdditionalObject.Assets.push(Object.assign(new Asset3D(), p_value));
            });
        }
        plannerObjects.push(AdditionalObject);
        }

        let datasToReturn = new ObjectDatas();
        datasToReturn.SetDatas([], plannerObjects);

        return datasToReturn;
    }
    
    private static DeepParseComposition<T extends BasicObject>(p_objectToParse : Object, p_objectType: { new (...args: any[]): T }) : Composition {
        
        let resultingComposition: Composition = Object.assign(new Composition(), p_objectToParse);
        let type = resultingComposition.Type;

        if(resultingComposition.Instances3D){
            for(let i:number = 0; i<resultingComposition.Instances3D.length; i++) {
                resultingComposition.Instances3D[i] = Object.assign(new Instance3D(), resultingComposition.Instances3D[i]);
            }
        }

        if(resultingComposition.Connectivity){
            resultingComposition.Connectivity = Object.assign(new Connectivity(), resultingComposition.Connectivity);
            for(let i:number = 0; i<resultingComposition.Connectivity.Connectors.length; i++) {
                resultingComposition.Connectivity.Connectors[i] = Object.assign(new Connector(), resultingComposition.Connectivity.Connectors[i]);
            }
            if(resultingComposition.Connectivity.Connections){
                for(let i:number = 0; i<resultingComposition.Connectivity.Connections.length; i++) {
                    let connection = Object.assign(new Connection(), resultingComposition.Connectivity.Connections[i]);
                    resultingComposition.Connectivity.Connections[i] = connection;
                }
            }
        }

        if(type === 'Asset3D') {
            for(let i:number = 0; i<resultingComposition.PartElements.length; i++) {
                resultingComposition.PartElements[i] = ObjectParser.DeepParseAsset3D(resultingComposition.PartElements[i]);
            }
        }
        else {
            for(let i:number = 0; i<resultingComposition.PartElements.length; i++) {
                resultingComposition.PartElements[i] = ObjectParser.DeepParseObject(resultingComposition.PartElements[i], p_objectType);
            }
        }
        return resultingComposition;
    }

    public static DeepParsePartTransformData(p_object : Object): PartTransformData {
        let partTransformData = Object.assign(new PartTransformData(), p_object);
        partTransformData.Transform = Object.assign(new Transform(), partTransformData.Transform);
        return partTransformData;
    }
    
    public static DeepParseAsset3D(p_object : Object): Asset3D {
        let asset = Object.assign(new Asset3D(), p_object);
        if(asset.Datas !== undefined) asset.Datas = ObjectParser.ParseAssetData(asset.Datas, asset.Type);
        
        let options = new Array<Option>();
        for(let i:number =0; i<asset.Options.length; i++){
            options.push(new Option(asset.Options[i]));
        }
        asset.Options = options; 
        return asset;
    }

    private static ParseAssetData(p_assetData : Asset3DData, p_assetType: string): Asset3DData {
        if (p_assetData == null) throw new Error('NullReferenceException : p_assetData is null or undefined');
        if (!p_assetType) throw new Error('NullReferenceException : p_assetType is null or undefined or empty');
        
        switch(p_assetType){
            case 'Model':
                return p_assetData;
            case 'Model3d':
                return Object.assign(new Model3dData(), p_assetData);
            case 'Material':
                return p_assetData;
            case 'MaterialAssignment':
                let assignements =  Object.assign(new Array<AssignmentData>(), p_assetData);
                for(let i:number = 0; i<assignements.length; i++){
                    assignements[i] = Object.assign(new AssignmentData(), assignements[i]);
                }
                return assignements;
            case 'Geometry':
                return Object.assign(new GeometryData(), p_assetData);
            case 'Shape':
                return Object.assign(new ShapeData(), p_assetData);
            default:
                return Object.assign(new Model3dData(), p_assetData);
        }
    }
}