import apiClient from '../../api-client';
import { MappingConstraint } from '../../../domain/domain';
import {
    CREATE_MAPPING_CONSTRAINT,
    DELETE_MAPPING_CONSTRAINT,
    EXECUTE_MAPPING_CONSTRAINT,
    GET_MAPPING_CONSTRAINT,
} from '../../enpoint/mapping-zoning/constraint.endpoints';

const mappingConstraintService = {
    createMappingConstraint: async (constraint: MappingConstraint) => apiClient(CREATE_MAPPING_CONSTRAINT(), constraint, undefined, 'POST'),
    getMappingConstraint: async (mappingId: number) => apiClient(GET_MAPPING_CONSTRAINT(mappingId), undefined, undefined, 'GET'),
    deleteMappingConstraint: async (constraintId: number) => apiClient(DELETE_MAPPING_CONSTRAINT(constraintId), undefined, undefined, 'DELETE'),
    executeMappingConstraint: async (mappingId: number, zoneUuid: string, materialName: string, finishId: number, colorisId: number) => apiClient(EXECUTE_MAPPING_CONSTRAINT(mappingId, zoneUuid, materialName, finishId, colorisId), undefined, undefined, 'GET'),
};
export default mappingConstraintService;