import React, { ReactElement, useEffect, useState } from 'react';
import debugConfig from '../../Assets/Configs/debugConfig.json';
import { useDispatch, useSelector } from 'react-redux';
import { SellerMode } from './SellerMode';
import { VisualStateData } from './VisualStateDatas';
import RoomStudio from '../RoomStudio/RoomStudio';
import { KeyboardActions } from '../TestingComponents/KeyboardActions';
import { Camera, Euler, Spherical, Vector3 } from 'three';
import { useConnectorSynchro, useSelectedObjectSynchro, useVisualStatesSynchro } from './IsolationSynchoHooks';
import Box from '@mui/material/Box';
import QuitModeBtn from '../UI/Composites/QuitModeBtn';
import DeviceDetection from '@lutithree/build/Modules/Core/Utils/DeviceDetection';
import ResourceErrorData
    from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ResourceErrorData";
import {ConfigData} from "../../../application3D-common/Librairies/Studios/Application3D/Domain/ConfigData";
import ProductSizeToggle from "../../../application3D-common/Components/UI/Composites/EntityMenu/ProductSizeToggle";
import AugmentedReality from "../../../application3D-common/Components/UI/Composites/EntityMenu/AugmentedRealityToggle";
import SwitchLightDarkToggle
    from "../../../application3D-common/Components/UI/Composites/EntityMenu/SwitchLightDarkToggle";
import {DebugManager} from "../../../application3D-common/Components/Debug/DebugManager";
import ProductStudio from "../../../application3D-common/Components/ProductStudio/ProductStudio";
import {RoomStudioMode} from "../../Libraries/Studios/RoomStudio/Domain/Modes/RoomStudioMode";
import ContextualMenu from "../../../application3D-common/Components/UI/Basics/ContextualMenu";
import IsolateProductToggle from "../UI/Composites/EntityMenu/IsolateProductToggle";
import { RootState } from '../../../../services/redux/root-reducers';
import {SellerApp3DModeState, updateSellerApp3DMode} from "../../Redux/Reducers/SellerApp3DModeReducer";
import {
    RoomStudioState,
    setEditedDatas,
    updateRoomStudioMode
} from "../../Redux/Reducers/room-studio/RoomStudioReducer";
import {ProductStudioState} from "../../../application3D-common/Redux/Reducers/product-studio/ProductStudioReducer";
import ObjectDatas from "../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/ObjectDatas";
import DebugDelete from '../../../application3D-common/Components/UI/Debug/DebugDelete';


export type SellerApp3DProps = {
    setOpenPanel: (openPanel: boolean) => void;
    openPanel: boolean;
    setOpenEditPanel: (openEditPanel: boolean) => void;
    setOpenShapePanel: (OpenShapePanel: boolean) => void;
    openPrimitivePanel: boolean;
    setOpenPrimitivePanel: (openPrimitivePanel: boolean) => void;
    setBuildingElementPanel: (buildingElementPanel: boolean) => void;
    setAddProductPanel: (addProductPanel: boolean) => void;
    disable: boolean;
    sizeStateOf3DView: string;
    onLoadingFail?: (p_resourceErrorData: ResourceErrorData) => void;
};


const SellerApp3D = (props: SellerApp3DProps) => {
    const dispatch = useDispatch();
    const [config] = useState<ConfigData | undefined>(debugConfig);
    const { sellerMode } = useSelector<RootState, SellerApp3DModeState>((state) => state.sellerApp3DModes);
    const roomStudio = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const { roomStudioMode } = useSelector<RootState, RoomStudioState>((state) => state.roomStudio);
    const productStudio = useSelector<RootState, ProductStudioState>((state) => state.productStudio);

    const [camCoordinates, setCameraCoordinates] = useState<Spherical | undefined>(undefined);
    const [objectPosition, setObjectPosition] = useState<Vector3 | undefined>(undefined);
    const [objectRotation, setObjectRotation] = useState<Euler | undefined>(undefined);
    const [visualStatesDatas, setVisualStatesDatas] = useState(new Array<VisualStateData>());
    const [isDimensionVisible, setIsDimensionVisible] = useState<boolean>(false);
    
    let it: number = 0;
    let bodyElements: ReactElement[] = [];

    bodyElements.push(<ProductSizeToggle key={ it } onChange={(p_isOn:boolean)=> setIsDimensionVisible(p_isOn)}/>);

    if (DeviceDetection.IsMobileIOS()) {
        bodyElements.push(
            <AugmentedReality onClick={ () => {
                if (roomStudio.editedDatas && roomStudio.editedDatas.FirstRootObject) {
                    let entity = roomStudio.roomStudioApp?.Services.RoomObjects.GetRelativeEntity(roomStudio.editedDatas.FirstRootObject.RefOfInstance);
                    if (entity) roomStudio.roomStudioApp?.VisualizeInAR(entity.Transform.GetObject());
                }
            } } key={ it + 2 } />);
    }

    bodyElements.push(<IsolateProductToggle
        sellerApp3DMode={ sellerMode }
        onChangeProductStudioMode={ (isActive: boolean) => switchToIsolationMode(isActive) } key={ it + 3 }
    />);

    if (sellerMode === SellerMode.ProductStudio) {
        bodyElements.push(
            <SwitchLightDarkToggle key={ it + 4 } />,
        );
    }

    useSelectedObjectSynchro();
    useConnectorSynchro();
    useVisualStatesSynchro(visualStatesDatas);

    useEffect(() => {
        if (roomStudio.roomStudioApp) roomStudio.roomStudioApp.EngineService.Resize();
        if (productStudio.productStudioApp) productStudio.productStudioApp.EngineService.Resize();
    }, [props.sizeStateOf3DView]);

    useEffect(() => {
        setVisualStatesDatas([]);
        if (roomStudio.selectedObject)
            setObjectPosition(roomStudio.roomStudioApp?.Services.RoomObjects.GetRelativeEntity(roomStudio.selectedObject.RefOfInstance)?.Transform.GetObject().position.clone());
    }, [roomStudio.selectedObject]);

    useEffect(() => {
        EnableDimensionInProductStudio(isDimensionVisible);
        EnableDimensionInRoomStudio(isDimensionVisible);
    }, [isDimensionVisible]);

    const EnableDimensionInRoomStudio = (p_isOn: boolean) => {
        if(roomStudio.roomStudioApp ){
            if (p_isOn) {
                if (roomStudio.editedDatas && roomStudio.editedDatas.FirstRootObject) {
                    let roomStudioEntity = roomStudio.roomStudioApp?.Services.RoomObjects.GetRelativeEntity(roomStudio.editedDatas.FirstRootObject.RefOfInstance);
                    if(!roomStudioEntity) return;
                    roomStudio.roomStudioApp.Services.Dimemsions.EnableDimensionOnEntity(roomStudioEntity);
                }
            } else {
                roomStudio.roomStudioApp.Services.Dimemsions.DisableAllDimension();
            }
        }
    };

    const EnableDimensionInProductStudio = (p_isOn: boolean) => {
        if (productStudio.productStudioApp) {
            if (p_isOn) {
                if (roomStudio.editedDatas && roomStudio.editedDatas.FirstRootObject) {
                    let productStudioEntity = productStudio.productStudioApp.Services.Objects.GetRelativeEntity(roomStudio.editedDatas.FirstRootObject.RefOfInstance);
                    if (!productStudioEntity) return;
                    productStudio.productStudioApp.Services.Dimemsions.EnableDimensionOnEntity(productStudioEntity);
                } 
            }
            else {
                productStudio.productStudioApp.Services.Dimemsions.DisableAllDimension();
            }
        }
    };
    
    const switchToIsolationMode = (p_isOn: boolean) => {
        if (p_isOn) {
            // ENTER CONFIGURING IN ISOLATION (PRODUCT STUDIO)
            props.setOpenEditPanel(true);

            if (roomStudio.roomStudioApp) {
                let camera: Camera = roomStudio.roomStudioApp.Services.Cameras.CurrentCamera.GetObject();

                if (roomStudio.editedDatas && roomStudio.editedDatas.FirstRootObject) {
                    let entity = roomStudio.roomStudioApp.Services.RoomObjects.GetRelativeEntity(roomStudio.editedDatas.FirstRootObject.RefOfInstance);
                    let relativePosFromTarget: Spherical | undefined = roomStudio.roomStudioApp?.Services.Cameras.GetRelativePosFromTarget(camera, entity!.Transform.GetObject());

                    let newPosition = roomStudio.roomStudioApp.Services.RoomObjects.GetRelativeEntity(roomStudio.editedDatas.FirstRootObject.RefOfInstance)?.Transform.GetObject();
                    if (newPosition) {
                        setObjectPosition(newPosition.position.clone());
                        setObjectRotation(newPosition.rotation.clone());
                    }
                    setCameraCoordinates(relativePosFromTarget);
                }
                dispatch(updateSellerApp3DMode(SellerMode.ProductStudio));
            }
            if (productStudio.productStudioApp) productStudio.productStudioApp.EngineService.Resize();
        } else {
            // ENTER CONFIGURING IN ROOM STUDIO
            if (productStudio.productStudioApp && roomStudio.editedDatas && roomStudio.editedDatas.FirstRootObject) {
                let camera: Camera = productStudio.productStudioApp.Services.Cameras.CurrentCamera.GetObject();
                let entity = productStudio.productStudioApp.Services.Objects.GetRelativeEntity(roomStudio.editedDatas.FirstRootObject.RefOfInstance);
                let relativePosFromTarget: Spherical | undefined = productStudio.productStudioApp.Services.Cameras.GetRelativePosFromTarget(camera, entity!.Transform.GetObject());
                setCameraCoordinates(relativePosFromTarget);
                dispatch(updateSellerApp3DMode(SellerMode.RoomStudio));
            }
        }
    };

    const onIsolationProductLoded = () => {
        visualStatesDatas.forEach((data) => {
            productStudio.productStudioApp?.Services.Objects.ApplyOption(data.refOfObject, data.refOfPart, data.refOfOption, data.value);
        });
        if (roomStudio.selectedObject) {
            if (productStudio.selectedPart === undefined || productStudio.selectedPart.RefOfInstance !== roomStudio.selectedObject.RefOfInstance) {
                productStudio.productStudioApp?.Services.Selection.SelectObject(roomStudio.selectedObject.RefOfInstance, false);
            }
        }
        if(isDimensionVisible) EnableDimensionInProductStudio(isDimensionVisible);
        if (roomStudio.selectedConnector) productStudio.productStudioApp?.Services.Connectors.SelectButton(roomStudio.selectedConnector, true);
    };

    const addVisualStateData = (p_data: VisualStateData) => {
        let datasCopy: VisualStateData[] = [...visualStatesDatas];
        let indexToRemove: number | undefined = undefined;
        for (let i: number = 0; i < datasCopy.length; i++) {
            if (datasCopy[i].refOfObject === p_data.refOfObject && datasCopy[i].refOfOption === p_data.refOfOption && datasCopy[i].refOfPart === p_data.refOfPart) {
                indexToRemove = i;
            }
        }
        if (indexToRemove !== undefined) {
            datasCopy.splice(indexToRemove, 1);
        }
        datasCopy.push(p_data);
        setVisualStatesDatas(datasCopy);
    };

    return (
        <>
            <Box
                sx={ {
                    height: '100%', width: '100%', position: 'absolute',
                } }
            >
                { config && <DebugManager config={ config } /> }

                <RoomStudio
                    openPanel={ props.openPanel }
                    setAddProductPanel={ props.setAddProductPanel }
                    setBuildingElementPanel={ props.setBuildingElementPanel }
                    setOpenShapePanel={ props.setOpenShapePanel }
                    disable={ props.disable }
                    setOpenPanel={ props.setOpenPanel }
                    setOpenEditPanel={ props.setOpenEditPanel }
                    openPrimitivePanel={ props.openPrimitivePanel }
                    setOpenPrimitivePanel={ props.setOpenPrimitivePanel }
                    currentObject={ roomStudio.selectedObject }
                    editedDatas={ roomStudio.editedDatas }
                    isVisible={ sellerMode === SellerMode.RoomStudio }
                    cameraCoordinates={ camCoordinates }
                    onLoadingFail={ props.onLoadingFail } />

                { roomStudio.editedDatas && roomStudio.editedDatas.FirstRootObject && (
                    <ProductStudio
                        datas={roomStudio.editedDatas}
                        product={ roomStudio.editedDatas.FirstRootObject }
                        isVisible={ sellerMode === SellerMode.ProductStudio }
                        cameraPointOfView={ camCoordinates }
                        objectPosition={ objectPosition }
                        objectRotation={ objectRotation }
                        setOpenPanel={ props.setOpenPanel }
                        setOpenEditPanel={ props.setOpenEditPanel }
                        openPanel={ props.openPanel }
                        onProductLoaded={ () => {
                            onIsolationProductLoded();
                        } }
                        onLoadingFail={ props.onLoadingFail }
                    />
                )}
                { roomStudioMode === RoomStudioMode.Configuring && (
                    <QuitModeBtn
                        label="QUITER L'EDITION DE PRODUIT"
                        onClick={ () => {
                            setIsDimensionVisible(false);
                            props.setOpenPanel(false);
                            props.setOpenEditPanel(false);
                            dispatch(updateRoomStudioMode(RoomStudioMode.Furnishing));
                            dispatch(updateSellerApp3DMode(SellerMode.RoomStudio));
                        } }
                    />)
                }

                { roomStudioMode === RoomStudioMode.Configuring && (
                        <>
                    <ContextualMenu
                        title='' headerItem={ <></> } bodyItems={ bodyElements }
                    />
                        </>
                ) }
                <DebugDelete
                    deleteCallback={(val1,val2)=>{console.log("Deleteing hard :", val1,val2 );}}
                />
                <KeyboardActions />
            </Box>
        </>
    );
};

export default SellerApp3D;