import { DisableComponent } from "@lutithree/build/Modules/Core/Entity/DisableComponent";
import { IDisposableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/IDisposableComponent";
import { IObjectComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/IObjectComponent";
import { Group, Mesh, MeshBasicMaterial, MeshStandardMaterial, Object3D, SphereGeometry, Vector3 } from "three";

export class MarkerComponent extends DisableComponent implements IDisposableComponent, IObjectComponent<Object3D> {
    
    private readonly m_object: Object3D;

    private readonly m_markerObject: Object3D;
    
    private m_point: Vector3;

    private m_markeWidth: number = 0.008;

    private m_gripWidth: number = 0.02;

    private m_colorMarker: string = '#080b15';

    private m_colorGrip: string = '#1D2740';
    
    public constructor(p_point: Vector3) {
        super();
        if (p_point == null) throw new Error('NullReferenceException : p_point is null or undefined');
        
        this.m_point = p_point;
        this.m_object = new Group();
        
        const geometry = new SphereGeometry( 1, 32, 32 );
        const markerMaterial = new MeshStandardMaterial( { color: this.m_colorMarker } );
        const gripMaterial = new MeshBasicMaterial( { color: this.m_colorGrip, opacity:0.5, transparent:true } );

        let marker = new Mesh( geometry, markerMaterial );
        marker.scale.copy(new Vector3(this.m_markeWidth, this.m_markeWidth, this.m_markeWidth));
        marker.name = 'Marker';
        marker.layers.set(4);
        this.m_markerObject = marker;

        let grip = new Mesh( geometry, gripMaterial);
        grip.scale.copy(new Vector3(this.m_gripWidth, this.m_gripWidth, this.m_gripWidth));
        grip.name = 'Grip';
        grip.layers.set(4);
        
        this.m_object.add(marker);
        this.m_object.add(grip);
    }

    public get Width(): number {
        return this.m_markeWidth;
    }

    public get Point(): Vector3 {
        return this.m_point;
    }

    public GetDisposable(): Object3D[] {
        return [this.m_object];
    }

    public GetMarker() {
        return this.m_markerObject;
    }
    
    public GetObject() {
        return this.m_object;
    }
}