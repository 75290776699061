const DUPLICATE_PRESET = (productRef: string, presetRef: string) => `v1/preset/product-ref/${productRef}/preset-reference/${presetRef}/duplicate`;
const GET_PRESET_BY_PRODUCT_REF = (productRef: string) => `v1/preset/product-ref/${productRef}`;
const CREATE_PRESET = (productRef: string, presetName: string) => `v1/preset/product-ref/${productRef}/preset-name/${presetName}/create`;
const RENAME_PRESET = (productRef: string, presetRef: string, newName: string) => `v1/preset/product-ref/${productRef}/preset-reference/${presetRef}/rename/${newName}`;
const GET_ALL_PRESET = () => `v1/preset/get-all`;
const DELETE_PRESET = (productRef: string, presetRef: string) => `v1/preset/product-ref/${productRef}/preset-reference/${presetRef}/delete`;
const GET_DEFAULT_PRESET = (productRef: string) => `v1/preset/product-ref/${productRef}/default-preset`;
const UPDATE_DEFAULT_PRESET = (productRef: string, presetRef: string) => `v1/preset/product-ref/${productRef}/${presetRef}/default-preset`;
const ADD_ATOM_TO_PRESET = (productRef: string, presetRef: string, atomRef: string) => `v1/preset/product-ref/${productRef}/preset-reference/${presetRef}/add-remove-atom/${atomRef}`;
const ADD_ATOM_LIST_TO_PRESET = (productRef: string, presetRef: string) => `v1/preset/product-ref/${productRef}/preset-reference/${presetRef}/add-multiple-atoms`;
const FIND_ATOM_BY_PRESET_REF = (presetRef: string) => `v1/preset/product-ref/preset-reference/${presetRef}/find-atom-by-preset-reference`;
const UPDATE_PRESET_THUMBNAILURL = (productRef: string, presetRef: string) => `v1/preset/product-ref/${productRef}/preset-reference/${presetRef}/update-thumbnailUrl`;

export {
    DUPLICATE_PRESET,
    GET_PRESET_BY_PRODUCT_REF,
    CREATE_PRESET,
    RENAME_PRESET,
    GET_ALL_PRESET,
    DELETE_PRESET,
    GET_DEFAULT_PRESET,
    ADD_ATOM_LIST_TO_PRESET,
    UPDATE_DEFAULT_PRESET,
    ADD_ATOM_TO_PRESET,
    FIND_ATOM_BY_PRESET_REF,
    UPDATE_PRESET_THUMBNAILURL,
};
