import React, { useEffect, useState } from 'react';
import Layout from '../../ui-components/layout/layout';
import ContainerPaperLayout from '../../ui-components/ContainerPaperLayout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/redux/root-reducers';
import { getCurrentUserInfo, UserState } from '../../services/redux/reducers/user/user-reducer';
import { User } from '../../domain/domain';
import UpdateUser from '../../business/DialogContent/UpdateUser';
import TextFieldUi from '../../ui-components/TextFieldUi';
import { Box, styled, Button, Typography } from '@mui/material';


const Profile = () => {
    const dispatch = useDispatch();
    const { response } = useSelector<RootState, UserState>((state) => state.currentUser);

    // TODO: find and delete all of then ! next time use Redux !
    const userInfo = localStorage.getItem('user');
    const currentUser = userInfo && JSON.parse(userInfo);
    const [open, setOpen] = useState<boolean>(false);
    const [user, setUser] = useState<User>();
    const [email] = useState(currentUser.email);

    const handleClick = (): void => {
        setOpen(true);
    };

    useEffect(() => {
        dispatch(getCurrentUserInfo());
    }, []);

    useEffect(() => {
        setUser(response.content?.user);
    }, [response]);

    const [lastname, setLastname] = useState((user && user.lastname) || currentUser.lastname);
    const [firstname, setFirstname] = useState((user && user.firstname) || currentUser.firstname);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        e.target.id === 'lastname'
            ? setLastname(e.target.value)
            : setFirstname(e.target.value);
    };

    return (
        <Layout navbar={ true }>
            <ContainerPaperLayout title='Profil'>
                <StyledBoxResponsive
                    sx={ { '& > :not(style)': { mb: 2 } } }>
                    <TextFieldUi
                        disabled
                        id='lastname'
                        labelContent='Nom'
                        variant='outlined'
                        onChange={ handleChange }
                        value={ lastname }
                        name='lastname textField'
                        fullWidth
                        type='text'
                    />
                    <TextFieldUi
                        disabled
                        id='firstname'
                        labelContent='Prenom'
                        variant='outlined'
                        onChange={ handleChange }
                        value={ firstname }
                        name='firstname textField'
                        fullWidth
                        type='text'
                    />
                    <TextFieldUi
                        disabled
                        id='email'
                        labelContent='Email'
                        variant='outlined'
                        onChange={ () => {
                        } }
                        value={ email }
                        name='email textField'
                        fullWidth
                        type='email'
                    />
                </StyledBoxResponsive>
                <Box
                    display='flex'
                    justifyContent='row'
                    alignItems='center'
                    sx={ { mt: 4.2 } }
                >
                    <Typography sx={ { mr: 1.5 } } color='primary' variant='body1'>MOT DE PASSE</Typography>
                    <StyledButton onClick={ handleClick } variant='text' color='secondary'>Changer</StyledButton>
                </Box>
                <UpdateUser open={ open } title='Nouveau mot de passe' onClose={ () => setOpen(false) } />
            </ContainerPaperLayout>
        </Layout>
    );
};

const StyledBoxResponsive = styled(Box)(({ theme }) => ({
    maxWidth: '55%',
    [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
    },
    [theme.breakpoints.up('lg')]: {
        maxWidth: '40%',
    },
}));

const StyledButton = styled(Button)(() => ({
    padding: '13px 25px',
}));


export default Profile;
