import { Event, Group, Material, Object3D } from "three";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { HookableComponent } from "../../Features3D/Hooks/Components/HookableComponent";
import { HookComponent } from "../../Features3D/Hooks/Components/HookComponent";

export class EntityHooksController {
    
    private readonly m_hooksParentName = "(HOOKS)";
    
    public get HooksParentName() : string {
        return this.m_hooksParentName;
    }
    
    public AddHookableComponent(p_entity: SceneEntity, p_hooksName: string[], p_model: Group, p_levelLoadedCallback: (p_resource: Material | Material[] | Group | Object3D<Event>) => void) : void {
        let template = p_entity.HasComponentOfType(HookableComponent)?p_entity.GetComponentOfType(HookableComponent):undefined;
        if(template) template.Template = p_model;
        else p_entity.AddComponentOfType(HookableComponent,p_hooksName, p_model, p_levelLoadedCallback);
    }

    public AddHookComponent(p_entity: SceneEntity, p_uuid: string, p_model: Group, p_lastModel: Group|undefined,
                             p_levelLoadedCallback: (p_resource: Material | Material[] | Group | Object3D<Event>) => void): void {
        let hooksMap = this.GetDummyChildrenByRef(p_model, this.m_hooksParentName, p_levelLoadedCallback);
        
        hooksMap?.forEach((hooks, key)=>{
            hooks.forEach((hook)=>{
                hook.position.add(p_model.position.clone());
            });
        });
        
        let components = p_entity.GetComponentsOfType(HookComponent);
        let component = components.filter(x => x.Ref === p_lastModel?.uuid); //récupérer le composant hoo du bon modèle (et pas celui d'une autre opt par ex) 
        if(component.length > 0){
            if(hooksMap){
                component[0].Ref = p_model.uuid;
                component[0].Hooks.forEach((hookObjects,key)=>{
                    hookObjects.forEach((hookObject)=>{
                        p_levelLoadedCallback(hookObject);
                    });
                });
                component[0].Hooks = hooksMap;
            }
            else p_entity.RemoveComponent(component[0]);
        }
        else if(hooksMap) {
            p_entity.AddComponentOfType(HookComponent, p_uuid, hooksMap);
        }
    }

    private GetDummyChildrenByRef(p_model: Group,
                                  p_parentRefName:string,
                                  p_levelLoadedCallback: (p_resource: Material | Material[] | Group | Object3D<Event>) => void): Map<string, Object3D[]> | undefined {

        let parentHookNode: Object3D;
        let dummiesRefs: string[] = [];
        let locationByRef: Map<string, Object3D[]> | undefined = undefined;
        let objectToRemove :{parent:Object3D, child:Object3D }[] =  [];

        p_model.updateMatrixWorld(true);
        p_model.traverse((child)=>{

            // si c'est le noeud parent "(HOOKS)"
            if(child.name === p_parentRefName){
                parentHookNode = child;
            }

            // si c'est une clef/tag de point d'accroche
            if(child.parent && child.parent.name === p_parentRefName) {
                dummiesRefs.push(child.name);
            }

            // si c'est un point d'accroche
            if(child.parent && dummiesRefs.includes(child.parent.name)) {
                if(child.parent) {
                    objectToRemove.push({parent:child.parent,child:child});

                    let emptyNode = new Object3D();
                    emptyNode.applyMatrix4(child.matrixWorld);
                    if(parentHookNode.parent){
                        emptyNode.applyMatrix4(parentHookNode.parent.matrixWorld.clone().invert());
                    }
                    emptyNode.name = child.name;

                    let value;
                    if(!locationByRef) locationByRef = new Map<string, Object3D[]>();
                    if(locationByRef.has(child.parent.name)){
                        value = locationByRef.get(child.parent.name)!;
                        value.push(emptyNode);
                    }
                    else value = [emptyNode];
                    locationByRef.set(child.parent.name,value);
                }
            }
        });

        objectToRemove.forEach((value)=>{
            value.parent.remove(value.child);
            p_levelLoadedCallback(value.child as Group);
        });

        return locationByRef;
    }
}