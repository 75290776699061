import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Engine } from '@lutithree/build/Engine';
import { SelectableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SelectableComponent';
import { MeshFilterComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent';
import { GroupSystem } from "@lutithree/build/Modules/WebGL/Scene/Systems/GroupSystem";
import InteractiveConnectorsService from "../UI/InteractiveConnectorsService";

export class BasicSelectionEffects {
    protected m_engine: Engine;

    protected readonly m_groupSystem: GroupSystem;

    protected m_interactiveConnectors: InteractiveConnectorsService;

    public constructor(p_engine: Engine, p_interactiveConnectorsService: InteractiveConnectorsService) {
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');

        this.m_engine = p_engine;
        this.m_groupSystem = new GroupSystem(p_engine.Modules.Scene);
        this.m_interactiveConnectors = p_interactiveConnectorsService;
    }

    public EnableOutline(p_entity: SceneEntity, p_value: boolean): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_value == null) throw new Error('NullReferenceException : p_value is null or undefined');

        if(p_value){
            let selectable = p_entity.HasComponentOfType(SelectableComponent)? p_entity.GetComponentOfType(SelectableComponent):undefined;
            // Setup MeshFilterComponent of parts
            this.SetupEntityComponentsCallbacs(p_entity,true);

            if(selectable && selectable.IsEnabled){
                // Apply outlines
                this.m_engine.Modules.Rendering.RenderOutlineForObject(p_entity.Transform.GetObject());
                this.m_interactiveConnectors.InstanciateButtonsOnConnectors(p_entity);
            }
        }
        else{
            // Hide outline
            this.m_engine.Modules.Rendering.ClearOutlineForObject(p_entity.Transform.GetObject());
            let meshFiltersEntities: SceneEntity[] = this.m_groupSystem.GetGroupEntitiesWith(p_entity,MeshFilterComponent);
            let meshFilters = this.m_engine.Modules.Scene.GetComponentsFromEntities(MeshFilterComponent,meshFiltersEntities);
            meshFilters.forEach((component)=>{
                if(!component.IsEnabled) this.m_engine.Modules.Rendering.ClearOutlineForObject(component.GetObject());
            });

            this.m_interactiveConnectors.RemoveAllButtons();
            this.SetupEntityComponentsCallbacs(p_entity,false);
        }
    }

    public RefreshSelectionEffects(p_entity: SceneEntity): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        // Setup MeshFilterComponent of parts
        this.SetupEntityComponentsCallbacs(p_entity,true);

        // Refresh outline
        let selectable = p_entity.HasComponentOfType(SelectableComponent)?p_entity.GetComponentOfType(SelectableComponent):undefined;
        if (selectable) {
            this.m_engine.Modules.Rendering.ClearOutlineForObject(p_entity.Transform.GetObject());
            if(selectable.IsEnabled && selectable.IsSelected) {
                this.m_engine.Modules.Rendering.RenderOutlineForObject(p_entity.Transform.GetObject());
            }
        }
    }

    protected SetupEntityComponentsCallbacs(p_entity: SceneEntity, p_addCallbacks: boolean): void {
        let meshRenderers = this.m_engine.Modules.Systems.GroupSystem.GetComponentsOnGroup(p_entity, MeshFilterComponent);
        if(p_addCallbacks){
            meshRenderers.forEach((meshRenderer) => {
                meshRenderer.AddOnGeometryChangedCallback('selectionFeature', () => this.RefreshSelectionEffects(p_entity));
                meshRenderer.AddOnEnabledCallback('selectionFeature', (component, value) => {
                    if (value) this.RefreshSelectionEffects(p_entity);
                });
            });
        }
        else{
            meshRenderers.forEach((meshRenderer) => {
                meshRenderer.RemoveOnGeometryChangedCallback('selectionFeature');
                meshRenderer.RemoveOnEnabledCallback('selectionFeature');
            });
        }
    }
}
