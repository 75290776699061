import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Engine } from '@lutithree/build/Engine';
import AService from '../../../Domain/AService';
import { SelectionSystem } from '@lutithree/build/Modules/WebGL/Scene/Systems/SelectionSystem';
import { SelectableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SelectableComponent';
import ISelectionController from "../../../Domain/Features3D/ISelectionController";
import EntitySelectionStatusDirtyEvent from "./Events/EntitySelectionStatusDirtyEvent";
import { GroupComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/GroupComponent";
import ObjectSystem from "../../Objects/Composition/ObjectSystem";
import { TransformControlComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Controls/TransformControlComponent";
import { AutoReorientationComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/AutoReorientationComponent";
import { SnappableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SnappableComponent";
import PartComponent from "../../Objects/Components/PartComponent";
import ObjectComponent from "../../Objects/Components/ObjectComponent";

export default class SelectionService extends AService implements ISelectionController {
    
    private readonly m_selectionSystem: SelectionSystem;

    private readonly m_objectSystem: ObjectSystem;
    
    public constructor(p_engine: Engine) {
        super(p_engine);

        this.m_selectionSystem = new SelectionSystem(p_engine.Modules.Scene);
        this.m_objectSystem = new ObjectSystem(p_engine.Modules.Scene);
    }

    public GetSelectedEntities(): SceneEntity[] {
        return this.m_selectionSystem.GetSelectedEntities();
    }

    public UpdateSelectionStatus2(p_entity: SceneEntity): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        
        let selectable = p_entity.HasComponentOfType(SelectableComponent)? p_entity.GetComponentOfType(SelectableComponent):undefined;
        if(selectable){
            if(!selectable.IsSelected){
                selectable.SetSelection(true);
            }
            else{
                selectable.SetSelection(false);
            }
            this.m_engine.Modules.EventManager.Publish(EntitySelectionStatusDirtyEvent, new EntitySelectionStatusDirtyEvent(p_entity,p_entity.GetComponentOfType(SelectableComponent)));
        }
        else{
            this.UnSelectAll();
        }
        this.m_engine.Modules.LoopStrategy.RequestRender(true);
    }

    public UnSelectAll(): void {
        let selectedEntities = this.m_selectionSystem.GetSelectedEntities();
        selectedEntities.forEach((entity) => {
            this.UnSelectEntity(entity);
        });
    }

    public EnableEntityControlFeature(p_value: boolean): void {
        this.m_engine.Modules.Scene.GetComponents(TransformControlComponent, { entity: false, component:false }).forEach((obj) => {
            let flag = false;
            let foundEntity;
            this.m_selectionSystem.GetSelectedEntities().forEach((entity) => {
                if (entity.Transform.GetObject() === obj.GetObject().object) {
                    flag = true;
                    foundEntity = entity;
                }
            });
            if (flag && foundEntity) {
                if ((foundEntity as any).HasComponentOfType(AutoReorientationComponent) && (foundEntity as any).HasComponentOfType(SnappableComponent) && (foundEntity as any).GetComponentOfType(SnappableComponent).Snapped.length > 0) {
                    return;
                }
            }
            obj.Enable(p_value);
            obj.Lock(!p_value);
        });
    }
    
    public EnableDeepSelectionFeature(p_value: boolean): void {
        if (p_value == null) throw new Error('NullReferenceException : p_value is null or undefined');
        if(!p_value) this.UnSelectAll();
        
        let selectableEntities = this.m_engine.Modules.Scene.GetEntitesWithComponents([SelectableComponent], { entity: false, component: false });
        selectableEntities.forEach((entity) => {
            if(!entity.HasComponentOfType(PartComponent)){
                let selectable = entity.GetComponentOfType(SelectableComponent);
                let lastEnabledState = selectable.IsEnabled;
                selectable.Enable(!p_value);
                if(lastEnabledState != !p_value) this.m_engine.Modules.EventManager.Publish(EntitySelectionStatusDirtyEvent, new EntitySelectionStatusDirtyEvent(entity,selectable));
                
                if(p_value && selectable.IsSelected){
                    this.EnableDeepSelection(entity, true);
                }
                else if(!p_value && selectable.IsSelected){
                    this.EnableDeepSelection(entity, false);
                    this.m_engine.Modules.EventManager.Publish(
                        EntitySelectionStatusDirtyEvent,
                        new EntitySelectionStatusDirtyEvent(entity,selectable));
                }
            }
        });
    }

    public SelectObject(p_refOfInstance: string, p_allowMultiple: boolean): void {
        if (!p_refOfInstance) throw new Error('NullReferenceException : p_refOfInstance is null or undefined or empty');
        if (p_allowMultiple == null) throw new Error('NullReferenceException : p_allowMultiple is null or undefined');
        
        let objectEntity = this.m_objectSystem.GetObjectEntity(p_refOfInstance);
        if(objectEntity){
            this.UpdateSelectionStatus(objectEntity,p_allowMultiple);
        }
    }
    
    public UpdateSelectionStatus(p_entity: SceneEntity, p_allowMultiple: boolean): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_allowMultiple == null) throw new Error('NullReferenceException : p_allowMultiple is null or undefined');

        let result: { addedToSelection: SceneEntity[]; removedToSelection: SceneEntity[] } = this.m_selectionSystem.UpdateSelectionStatus(p_entity, p_allowMultiple);

        // Fire EntityUnselectedEvent
        result.removedToSelection.forEach((entity) => {
            this.m_engine.Modules.EventManager.Publish(EntitySelectionStatusDirtyEvent, new EntitySelectionStatusDirtyEvent(entity,entity.GetComponentOfType(SelectableComponent)));
        });

        // Fire EntitySelectedEvent
        result.addedToSelection.forEach((entity) => {
            this.m_engine.Modules.EventManager.Publish(EntitySelectionStatusDirtyEvent, new EntitySelectionStatusDirtyEvent(entity,entity.GetComponentOfType(SelectableComponent)));
        });

        this.m_engine.Modules.LoopStrategy.RequestRender(true);
    }

    public UnSelectEntity(p_entity: SceneEntity): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        
        if (p_entity.HasComponentOfType(SelectableComponent)) {
            let selectable = p_entity.GetComponentOfType(SelectableComponent);
            if (selectable.IsSelected) {
                selectable.SetSelection(false);
                this.m_engine.Modules.EventManager.Publish(EntitySelectionStatusDirtyEvent, new EntitySelectionStatusDirtyEvent(p_entity,p_entity.GetComponentOfType(SelectableComponent)));
            }
        }
    }

    private EnableDeepSelection(p_entity: SceneEntity, p_value: boolean): void {
        
        let objectComponent = p_entity.HasComponentOfType(ObjectComponent)?p_entity.GetComponentOfType(ObjectComponent):undefined;
        if (objectComponent) {
            
            let refOfInstance = objectComponent.Ref;
            objectComponent.PartIds.forEach((partId) => {
                
                let partEntity = this.m_engine.Modules.Scene.GetEntityByID(partId);
                let partSelectable = partEntity.HasComponentOfType(SelectableComponent, false)?partEntity.GetComponentOfType(SelectableComponent):undefined;
                if (partSelectable) {
                    partSelectable.Enable(p_value);
                    
                    let groups = partEntity.GetComponentsOfType(GroupComponent);
                    groups.forEach((groupComponent) => {
                        if (groupComponent.GroupRef === refOfInstance) groupComponent.Enable(!p_value);
                    });
                }
            });
        }
    }
}