import { IEvent } from "@lutithree/build/Modules/Core/Event/IEvent";

export class EnableScreenshotContextEvent implements IEvent {
    private m_enabled: boolean;

    public constructor(p_enable: boolean) {
        if (p_enable == null) throw new Error('NullReferenceException : p_enable is null or undefined');

        this.m_enabled = p_enable;
    }
    
    public get Enabled(): boolean {
        return this.m_enabled;
    }
}