import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState, logIn, resetAuth } from '../../services/redux/reducers/auth/auth.slice';
import { RootState } from '../../services/redux/root-reducers';
import TextFieldUi from '../../ui-components/TextFieldUi';
import { Divider, Link } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InfoBox from '../../ui-components/InfoBox';
import ErrorIcon from '@mui/icons-material/Error';
import version from '../../domain/mdf-version.json';
import healthCheckService from '../../api/service/health-check/health-check.service';

type ApiStatus = {
    api3dUp: boolean;
    apiBpUp: boolean;
    appAvailable: boolean;
};
const LoginForm = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const { payload } = useSelector<RootState, AuthState>((state) => state.auth);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState('');
    const [serverError, setServerError] = useState('');
    const [inputError, setInputError] = useState<boolean>(false);
    const [helperTextLogin, setHelperTextLogin] = useState('');
    const [appStatus, setAppStatus] = useState<ApiStatus>({
        api3dUp: true,
        apiBpUp: true,
        appAvailable: true,
    });
    const handleChangeEmail: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value);
        setInputError(false);
        setHelperTextLogin('');
    };

    const handleChangePassword: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPassword(e.target.value);
        setInputError(false);
        setHelperTextLogin('');
    };

    const handleSubmit = () => {
        dispatch(logIn({ email: email.toLowerCase(), password }));
    };

    useEffect(() => {
        resetAuth();
        checkAppAvailability();
    }, []);

    const checkAppAvailability = async () => {
        const status: ApiStatus = {
            api3dUp: true,
            apiBpUp: true,
            appAvailable: true,
        };
        try {
            await healthCheckService.check3dHealth();
            status.api3dUp = true;
        } catch (e) {
            status.api3dUp = false;
            status.appAvailable = false;
        }

        try {
            await healthCheckService.checkBpHealth();
            status.apiBpUp = true;
        } catch (e) {
            status.apiBpUp = false;
            status.appAvailable = false;
        }
        if (status.api3dUp && status.apiBpUp) {
            status.appAvailable = true;
        }
        console.log(status);

        setAppStatus(status);
    };

    useEffect(() => {
        if (payload.errors.length > 0) {
            setServerError(payload.errors[0].message);
            setInputError(true);
            setHelperTextLogin('Identifiants incorrects, veuillez les vérifier');
        } else if (payload.content !== null && payload.errors.length === 0) {
            localStorage.setItem('mdf-jwt', payload.content.jwt);
            localStorage.setItem('user', JSON.stringify(payload.content.user));
            setTimeout(() => {
                history('/');
            }, 250);
        }
    }, [payload]);

    return (
        <>
            {inputError && (
                <Box sx={{ width: '100%', mb: 2 }}>
                    <InfoBox
                        icon={
                            <ErrorIcon
                                sx={{
                                    fontSize: '2.7em',
                                    marginRight: '10px',
                                }}
                                color="error"
                            />
                        }
                        errorBox
                        content={serverError}
                    />
                </Box>
            )}
            <TextFieldUi labelContent="Adresse Email" id="email" variant="outlined" fullWidth onChange={handleChangeEmail} value={email} name="email input" error={inputError} type="email" />
            <Box sx={{ marginTop: '20px' }}>
                <TextFieldUi
                    labelContent="Mot de passe"
                    id="password"
                    variant="outlined"
                    fullWidth
                    onChange={handleChangePassword}
                    value={password}
                    name="password input"
                    error={inputError}
                    type="password"
                    helperText={helperTextLogin}
                />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Button sx={{ padding: '8px', mt: 4 }} color="secondary" variant="contained" onClick={handleSubmit} disableElevation>
                    Continuer
                </Button>
            </Box>
            <Divider sx={{ mt: 4, mb: 4 }} variant="middle" />
            <Box sx={{ textAlign: 'center' }}>
                <Link sx={{ textTransform: 'uppercase', color: '#89A1DF', fontSize: 14 }} href="/forgot-password" underline="hover">
                    mot de passe oublie
                </Link>
            </Box>
            <br />
            <Typography variant="subtitle2" color="primary" sx={{ textAlign: 'center' }}>
                {version.version}
            </Typography>
            <Typography variant="subtitle2" color="primary" sx={{ textAlign: 'center' }}>
                {version.buildTime}
            </Typography>
            <Typography variant="subtitle2" color="primary" sx={{ textAlign: 'center' }}>
                3D API is {appStatus.api3dUp ? 'up' : 'down'}
            </Typography>
            <Typography variant="subtitle2" color="primary" sx={{ textAlign: 'center' }}>
                BP API is {appStatus.apiBpUp ? 'up' : 'down'}
            </Typography>
        </>
    );
};

export default LoginForm;
