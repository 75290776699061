import { AHandler } from '@lutithree/build/Handlers/AHandler';
import { Engine } from '@lutithree/build/Engine';
import ProductStudioServices from "./GameLogic/ProductStudioService";

export default abstract class AProductStudioHandler extends AHandler {
    protected m_services: ProductStudioServices;
    
    public constructor(p_engine: Engine, p_services: ProductStudioServices) {
        if (p_services == null) throw new Error('NullReferenceException : p_services is null or undefined');
        super(p_engine);

        this.m_services = p_services;
    }
}
