import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import { SelectableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SelectableComponent";
import ObjectGeometryChangedEvent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AssetAssembly/Events/ObjectGeometryChangedEvent";
import {AHandler} from "@lutithree/build/Handlers/AHandler";
import {Engine} from "@lutithree/build/Engine";
import AObjectBehaviour
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/AObjectBehaviour";
import {
    BasicSelectionEffects
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Selection/BasicSelectionEffects";

export class ObjectGeometryChangedHandler extends AHandler implements IHandler<ObjectGeometryChangedEvent> {
    
    private m_selection: BasicSelectionEffects;
    
    private m_behaviours: AObjectBehaviour;
    
    constructor(p_engine: Engine, p_selectionEffects: BasicSelectionEffects, p_behaviours: AObjectBehaviour) {
        super(p_engine);
        this.m_selection = p_selectionEffects;
        this.m_behaviours = p_behaviours;
    }
    
    public Handle(p_event: ObjectGeometryChangedEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');

        let selectedComponent = p_event.Entity.HasComponentOfType(SelectableComponent)?p_event.Entity.GetComponentOfType(SelectableComponent):undefined;
        if (selectedComponent && selectedComponent.IsSelected){
            this.m_selection.RefreshSelectionEffects(p_event.Entity);
        }
        this.m_behaviours.OnGeometryChange(p_event.Entity);
    }
}
