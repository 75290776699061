import { ASystem } from "@lutithree/build/Modules/Core/Entity/ASystem";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import {  Object3D, Quaternion, Vector3 } from "three";
import { Object3DUtils } from "@lutithree/build/Modules/WebGL/Utils/Object3DUtils";
import { ConnectorsComponent } from "../Components/ConnectorsComponent";
import Connector from "../../../../Domain/Objects/Composition/Connector";

export default class ConnectorSystem extends ASystem<SceneEntity> {
    
    public ConnectEntities(p_entity1: SceneEntity, p_connector1: Connector, p_connectorsComponent1: ConnectorsComponent[], 
                           p_entity2: SceneEntity, p_connector2: Connector, p_connectorsComponent2: ConnectorsComponent[])
        : void {
        
        if (p_entity1 == null) throw new Error('NullReferenceException : p_entity1 is null or undefined');
        if (p_entity2 == null) throw new Error('NullReferenceException : p_entity2 is null or undefined');
        if (p_connector1 == null) throw new Error('NullReferenceException : p_connector1 is null or undefined');
        if (p_connector2 == null) throw new Error('NullReferenceException : p_connector2 is null or undefined');
        if (p_connectorsComponent1 == null) throw new Error('NullReferenceException : p_connectorsComponent1 is null or undefined');
        if (p_connectorsComponent2 == null) throw new Error('NullReferenceException : p_connector2 is null or undefined');

        let connectors1Component = p_connectorsComponent1.find(x => x.GetConnectorLocation(p_connector1.Role) != undefined);
        let connectors2Component = p_connectorsComponent2.find(x => x.GetConnectorLocation(p_connector2.Role) != undefined);
        
        if(connectors1Component && connectors2Component) {
            let connector1Location = connectors1Component.GetConnectorLocation(p_connector1.Role);
            let connector2Location = connectors2Component.GetConnectorLocation(p_connector2.Role);
            
            if(connector1Location && connector2Location){
                if(p_connector1.Role === 'RIGHT'||p_connector2.Role === 'LEFT'){
                    this.RelocateConnectorROnConnectorL(p_entity1, connector1Location, p_entity2, connector2Location);
                }
                else if(p_connector2.Role === 'RIGHT'||p_connector1.Role === 'LEFT'){
                    this.RelocateConnectorROnConnectorL(p_entity2, connector2Location, p_entity1, connector1Location);
                }
                else{
                    throw new Error('ConnectorRoleException : roles cannot match c1:'+p_connector1.Role+' / c2: '+p_connector2.Role);
                }
            }
        }
    }
    
    private RelocateConnectorROnConnectorL(p_objectEntityR: SceneEntity, p_connector3dR: Object3D, p_objectEntityL: SceneEntity, p_connector3dL: Object3D): void {
        let parent = p_objectEntityR.Transform.GetObject().parent;

        if(parent) {

            let parentWorldQuaternion = new Quaternion();
            parent.getWorldQuaternion(parentWorldQuaternion);
            
            // Apply c1 rotation to m2
            let forwardConnector1 = new Vector3();
            p_connector3dR.getWorldDirection(forwardConnector1);
            Object3DUtils.ApplyForward(p_objectEntityL.Transform.GetObject(),forwardConnector1);

            // Apply c1 position to c2
            let parentWorldPosition = new Vector3();
            parent.getWorldPosition(parentWorldPosition);
            let invertParentWorldQuaternion = parentWorldQuaternion.clone().invert();
            
            let c1WorldPosition = new Vector3();
            p_connector3dR.getWorldPosition(c1WorldPosition);
            let c1CompoPosition = new Vector3().subVectors(c1WorldPosition, parentWorldPosition);
            c1CompoPosition.applyQuaternion(invertParentWorldQuaternion);
            

            let c2WorldPosition = new Vector3();
            p_connector3dL.getWorldPosition(c2WorldPosition);
            let m2WorldPosition = new Vector3();
            p_objectEntityL.Transform.GetObject().getWorldPosition(m2WorldPosition);
            let c2localPosition = new Vector3().subVectors(c2WorldPosition, m2WorldPosition);
            c2localPosition.applyQuaternion(invertParentWorldQuaternion);

            let resultingValue = new Vector3().subVectors(c1CompoPosition,c2localPosition);
            
            p_objectEntityL.Transform.GetObject().position.copy(resultingValue);
        }
    }
}