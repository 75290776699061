import ARoomStudioHandler from "../../../ARoomStudioHandler";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { RotControlableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/RotControlableComponent";
import { YControlableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/YControlableComponent";
import { TranslatableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/TranslatableComponent";

export class AObjectLoadedHandler extends ARoomStudioHandler {

    protected LoadObjectCommonActions(p_entity:SceneEntity): void{
        let rotatetable = p_entity.HasComponentOfType(RotControlableComponent)?p_entity.GetComponentOfType(RotControlableComponent):undefined;
        if(rotatetable) rotatetable.Enable(false);

        let yControlable = p_entity.HasComponentOfType(YControlableComponent)?p_entity.GetComponentOfType(YControlableComponent):undefined;
        if(yControlable) yControlable.Enable(false);

        let translatable = p_entity.HasComponentOfType(TranslatableComponent)?p_entity.GetComponentOfType(TranslatableComponent):undefined;
        if(translatable) translatable.Enable(false);
    }
}