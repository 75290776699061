import ILogService from './ILogService';
import React from 'react';
import ConsoleTool from '../../../Console';
import { LogLevel } from '../../Debug';

export default class LogToConsoleService implements ILogService {
    m_console: ConsoleTool | null | undefined;

    constructor(p_console: Promise<React.RefObject<ConsoleTool>>) {
        p_console.then((p_arg) => {
            this.m_console = p_arg.current;
        });
    }

    Log(p_logMessage: string, p_level: LogLevel): void {
        this.m_console?.LogToConsole(p_logMessage);
    }
}
