import React, {useEffect, useState} from 'react';
import editOn from '../../../../Assets/Icons/ContextualMenu/Planner_dimensions-on.svg';
import editOff from '../../../../Assets/Icons/ContextualMenu/Planner_dimensions-off.svg';
import ToogleIcon from '../../Basics/ToogleIcon/ToogleIcon';


type ProductSizeToggleProps = {
    onChange: (p_isActive: boolean)=>void;
};

const ProductSizeToggle = ({onChange}: ProductSizeToggleProps) => {
    const [active, setActive] = useState<boolean>(false);

    useEffect(() => {
        onChange(active);
    }, [active]);
    
    const handleClick = () => {
        setActive(!active);
    };

    return <ToogleIcon imgOn={ editOn } imgOff={ editOff }
                       label={ `Dimensions \n du produit` }
                       active={ active } onChange={ handleClick } />;
};

export default ProductSizeToggle;