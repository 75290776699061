import { Engine } from "@lutithree/build/Engine";
import OpeningBehaviours from "./OpeningBehaviours";
import WallBehaviours from "./WallBehaviours";
import RoomObjectBehaviours from "./RoomObjectBehaviours";
import IRoomStudioNotifier from "../../Domain/Notifier/IRoomStudioNotifier";
import {
    INavigationController
} from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Features3D/INavigationController";

export default class Behaviours {
    private readonly m_basicObjectBehaviours: RoomObjectBehaviours;
    
    private readonly m_openingBehaviours: OpeningBehaviours;

    private readonly m_wallBehaviours: WallBehaviours;
    
    public constructor(p_engine: Engine, p_notifier: IRoomStudioNotifier, p_navigation: INavigationController) {
        if (p_notifier == null) throw new Error('NullReferenceException : p_notifier is null or undefined');
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');
        if (p_navigation == null) throw new Error('NullReferenceException : p_navigation is null or undefined');
        
        this.m_basicObjectBehaviours = new RoomObjectBehaviours(p_engine, p_navigation);
        this.m_openingBehaviours = new OpeningBehaviours(p_engine, p_notifier);
        this.m_wallBehaviours = new WallBehaviours(p_engine, p_notifier);
    }
    
    public get BasicObjects(): RoomObjectBehaviours {
        return this.m_basicObjectBehaviours;
    }

    public get Openings(): OpeningBehaviours {
        return this.m_openingBehaviours;
    }

    public get Walls(): WallBehaviours {
        return this.m_wallBehaviours;
    }
}