import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from '../../pages/Login';
import PrivateRouteV6 from './privateRouteV6';
import CreatePassword from '../../pages/CreatePassword';
import ForgotPassword from '../../pages/forgot-password/forgot-password';
import HomePlannerCode from '../planner/home-planner/home-planner-code';
import SpeedTest from '../../pages/speed-test/speed-test';
import Profile from '../../pages/Profile/Profile';
import Project from '../../pages/Project';
import Rooms from '../../pages/rooms/rooms';
import RoomDetail from '../../pages/rooms/room-detail';
import News from '../../pages/News/News';


const RouteurV6 = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={ <Login /> } />
                <Route path="/" element={ <PrivateRouteV6 el={ <News /> } /> } />
                <Route path="/create-password/:resetPasswordToken" element={ <CreatePassword /> } />
                <Route path="/news" element={ <PrivateRouteV6 el={ <News /> } /> } />
                <Route path="/forgot-password" element={ <ForgotPassword /> } />
                <Route path="/planner/:projectId/:projectCode/:uuid"
                       element={ <PrivateRouteV6 el={ <HomePlannerCode /> } /> } />
                <Route path="/speed-test" element={ <PrivateRouteV6 el={ <SpeedTest /> } /> } />
                <Route path="/profile" element={ <PrivateRouteV6 el={ <Profile /> } /> } />
                <Route path="/projects" element={ <PrivateRouteV6 el={ <Project /> } /> } />
                <Route path="/project/:projectId/:code" element={ <PrivateRouteV6 el={ <Rooms /> } /> } />
                <Route path="/room/:projectId/:name" element={ <PrivateRouteV6 el={ <RoomDetail /> } /> } />
            </Routes>
        </BrowserRouter>
    );
};

export default RouteurV6;
