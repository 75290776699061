import React, { ReactNode, useEffect, useState } from 'react';
import NavbarPlanner from '../../business/Header/NavbarPlanner';
import NavbarApp from '../../business/Header/NavbarApp';
import './layout.scss';
import { findCatalogByAdmin } from '../../services/redux/reducers/visibility/visibility.slice';
import { useDispatch } from 'react-redux';
import { getCatalogsByUser } from '../../services/redux/reducers/catalog/catalog.slice';


type LayoutProps = {
    children: ReactNode;
    navbar?: boolean;
    title?: string;
};

const Layout = ({ children, navbar, title }: LayoutProps) => {
    const dispatch = useDispatch();
    const [plannerPage, setPlannerPage] = useState(false);
    const [isMount, setIsMount] = useState(false);

    useEffect(() => {
        window.location.pathname.indexOf('planner') === 1 ? setPlannerPage(true) : setPlannerPage(false);
    }, [window.location]);

    useEffect(() => {
        !isMount && dispatch(getCatalogsByUser());
        return () => setIsMount(true);
    }, []);

    return (
        <div className='main-layout'>
            {navbar && plannerPage
                ? <NavbarPlanner />
                : <NavbarApp />
            }
            <div>
                {title}
            </div>
            <div className='pages-layout'>
                {children}
            </div>
        </div>
    );
};

export default Layout;
