import { IEvent } from '@lutithree/build/Modules/Core/Event/IEvent';
import FSM from "../../../../../../application3D-common/Librairies/FSM/FSM";
import State from "../../../../../../application3D-common/Librairies/FSM/State";

export default class ModeManager {
    private m_fsm: FSM;

    public constructor(p_fsm: FSM) {
        if (p_fsm == null) throw new Error('NullReferenceException : p_fsm is null or undefined');
        this.m_fsm = p_fsm;
        this.m_fsm.Start();
    }

    public SetMode(p_stateName: string): void {
        if (!p_stateName) throw new Error('NullReferenceException : p_stateName is null or undefined or empty');
        this.m_fsm.GoToState(p_stateName);
    }

    public PlayReactions<T extends IEvent>(p_eventType: { new (...args: any[]): T }, p_event: T): void {
        if (p_event == null) throw new Error('NullReferenceException : p_stateName is null or undefined or empty');
        if (p_eventType == null) throw new Error('NullReferenceException : p_eventType is null or undefined or empty');
        this.m_fsm.PlayReactions(p_eventType, p_event);
    }
    
    public GetMode(): State | undefined{
        return this.m_fsm.CurrentState;
    }
}
