import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { fetchAndAddTemplate } from '../../../../utils/fetchAndAddTemplate';
import ButtonNavigate from '../../../../ui-components/ButtonNavigate';
import CardMediaWithLabel from '../../CardAction/CardMediaWithLabel';
import windowOne from '../../../../asset/fenetre1v@2x.png';
import windowTwo from '../../../../asset/fenetre2v@2x.png';
import windowThree from '../../../../asset/fenetre3v@2x.png';
import windowFour from '../../../../asset/fenetre4v@2x.png';


type WindowPanelProps = {
    setWindowPanel: (windowPanel: boolean) => void;
};

type DataWindowPanelProps = {
    label: string;
    imageUrl: string;
    onClick: () => void;
};

const WindowPanel = ({ setWindowPanel }: WindowPanelProps) => {
    const dispatch = useDispatch();
    const window1 = 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/metadata/objects/openings/casement-window-small/opening_casement_window_small_1v.json';
    const window2 = 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/metadata/objects/openings/casement-window-small/opening_casement_window_small_2v.json';
    const window3 = 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/metadata/objects/openings/casement-window-small/opening_casement_window_small_3v.json';
    const window4 = 'https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/metadata/objects/openings/casement-window-small/opening_casement_window_small_4v.json';

    const dataWindowPanel: DataWindowPanelProps[] = [
        {
            label: '1 Vantail',
            imageUrl: windowOne,
            onClick: () => fetchAndAddTemplate(window1, dispatch),
        },
        {
            label: '2 Vantaux',
            imageUrl: windowTwo,
            onClick: () => fetchAndAddTemplate(window2, dispatch),
        },
        {
            label: '3 Vantaux',
            imageUrl: windowThree,
            onClick: () => fetchAndAddTemplate(window3, dispatch),
        },
        {
            label: '4 Vantaux',
            imageUrl: windowFour,
            onClick: () => fetchAndAddTemplate(window4, dispatch),
        },
    ];

    return (
        <>
            <ButtonNavigate label='Fenêtres' onClick={ () => setWindowPanel(false) } />
            <Box sx={ { pt: 3 } }>
                <Grid container spacing={ 2 }>
                    { dataWindowPanel.map((el, index: number) => {
                        return (
                            <Grid item xs={ 12 } md={ 6 } key={ index + el.label }>
                                <CardMediaWithLabel onClick={ el.onClick } label={ el.label } media={ el.imageUrl } />
                            </Grid>
                        );
                    }) }

                </Grid>
            </Box>
        </>
    );
};

export default WindowPanel;
