import { ASystem } from '@lutithree/build/Modules/Core/Entity/ASystem';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { POVComponent } from "../Components/POVComponent";
import { CameraSystem } from "@lutithree/build/Modules/WebGL/Scene/Systems/CameraSystem";
import { EntityManager } from "@lutithree/build/Modules/Core/Entity/EntityManager";

export class POVSystem extends ASystem<SceneEntity> {
    private m_cameraSystem: CameraSystem;

    
    public constructor(p_entityManager: EntityManager<SceneEntity>, p_cameraSystem: CameraSystem) {
        super(p_entityManager);
        if(p_cameraSystem == null) throw new Error("p_cameraSystem was null or undefined in POVsystem");
       
        this.m_cameraSystem = p_cameraSystem;
    }
    
    public UpdateAllEntitiesPOVStatus() : {entity: SceneEntity, pov: POVComponent}[] {
        let povUpdated: {entity: SceneEntity, pov: POVComponent}[] = [];
        let povEntities = this.m_entityManager.GetEntitesWithComponents([POVComponent]);
        let povEntitiesViewedFromBack = povEntities.filter((entity)=>{
            return entity.GetComponentOfType(POVComponent).IsViewedFromBack;
        });
        let backEntities = this.m_cameraSystem.FindEntitiesViewedFromBack(povEntities);
        
        povEntities.forEach((entity)=>{
            let povComponent = entity.GetComponentOfType(POVComponent);
            if(backEntities.includes(entity)) {
                povComponent.IsViewedFromBack = true;
                if(!povEntitiesViewedFromBack.includes(entity)) {
                    povUpdated.push({entity: entity, pov: povComponent});
                }
            }
            else {
                povComponent.IsViewedFromBack = false;
                if(povEntitiesViewedFromBack.includes(entity)){
                    povUpdated.push({entity: entity, pov: povComponent});
                }
                    
            }
        });
        return povUpdated;
    }

    public UpdatePOVStatus(p_entity: SceneEntity) : {entity: SceneEntity, pov: POVComponent}[] {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        
        let povUpdated: {entity: SceneEntity, pov: POVComponent}[] = [];
        if(!p_entity.HasComponentOfType(POVComponent)) return povUpdated;
        
        let povEntities = this.m_entityManager.GetEntitesWithComponents([POVComponent]);
        let povEntitiesViewedFromBack = povEntities.filter((entity)=>{
            return entity.GetComponentOfType(POVComponent).IsViewedFromBack;
        });
        let backEntities = this.m_cameraSystem.FindEntitiesViewedFromBack(povEntities);

        let povComponent = p_entity.GetComponentOfType(POVComponent);
        if(backEntities.includes(p_entity)) {
            povComponent.IsViewedFromBack = true;
            if(!povEntitiesViewedFromBack.includes(p_entity)) {
                povUpdated.push({entity: p_entity, pov: povComponent});
            }
        }
        else {
            povComponent.IsViewedFromBack = false;
            if(povEntitiesViewedFromBack.includes(p_entity)){
                povUpdated.push({entity: p_entity, pov: povComponent});
            }

        }

        return povUpdated;
    }
}