import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { BufferGeometry, Event, Group, Material, Object3D } from 'three';
import { Object3DUtils } from '@lutithree/build/Modules/WebGL/Utils/Object3DUtils';
import { MeshFilterComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent';
import { ModelLoader } from '@lutithree/build/Modules/WebGL/Resources/Load/ModelLoader';
import Asset3D from "../../../../../Domain/Objects/Assets/Asset3D";
import EntityMeshController from "../../EntityMeshController";
import Model3dData from "../../../../../Domain/Objects/Assets/Model3dData";
import { EntityHooksController } from "../../EntityHooksController";
import { EntityConnectorsController } from "../../EntityConnectorsController";
import { computeBoundsTree } from 'three-mesh-bvh';
import {IAssetDecorator} from "../../../../../Domain/Objects/AssetAssembly/IAssetDecorator";

BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;

export class Model3dDecorator implements IAssetDecorator {

    private readonly m_entityMeshController: EntityMeshController;

    private readonly m_entityHooksController: EntityHooksController;

    private readonly m_entityConnectorsController: EntityConnectorsController;

    protected readonly m_errorModelAssetPath: string = "https://mdf-s3-dev-documents.s3.eu-west-3.amazonaws.com/models/Model_ERROR.glb";

    protected m_getErrorModelAsset: Promise<Group>;
    
    public constructor() {
        this.m_entityMeshController = new EntityMeshController();
        this.m_entityHooksController = new EntityHooksController();
        this.m_entityConnectorsController = new EntityConnectorsController();

        let modelAsset = new ModelLoader(this.m_errorModelAssetPath);
        this.m_getErrorModelAsset = modelAsset.LoadAsync();
    }

    public DecorateAsset(p_entity: SceneEntity, p_asset: Asset3D, p_levelLoadedCallback: (p_resource: Material | Material[] | Group | Object3D<Event>) => void): Promise<void> {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_asset == null) throw new Error('NullReferenceException : p_asset is null or undefined');
        if (p_levelLoadedCallback == null) throw new Error('NullReferenceException : p_levelLoadedCallback is null or undefined');

        let lastModel: Group | undefined = undefined;
        let asset = p_asset;
        let addNewLevel = (p_model: Group, p_level: number) => {
            let datas = asset.Datas as Model3dData;
            if (datas.Transform) Object3DUtils.ApplyTransform(p_model, datas.Transform);
            if (datas.Pivot) Object3DUtils.ApplyPivot(p_model, datas.Pivot);

            // Add hookable template
            if(datas.Hooks !== undefined) this.m_entityHooksController.AddHookableComponent(p_entity,datas.Hooks, p_model.clone(true), p_levelLoadedCallback);

            // Add or Update model
            let meshFilter: MeshFilterComponent = this.AddOrUpdateModel(p_entity, p_model, lastModel, false, p_levelLoadedCallback);
            
            // Add Hooks
            this.m_entityHooksController.AddHookComponent(p_entity, meshFilter!.Model.uuid, p_model, lastModel, p_levelLoadedCallback);

            // Add Connectors
            this.m_entityConnectorsController.AddConnectorsComponent(p_entity, meshFilter!.Model.uuid, p_model, lastModel, p_levelLoadedCallback);

            // Add options
            if (meshFilter && p_level === 0) {
                if (asset.Options) this.m_entityMeshController.SetOptions(p_entity, asset.Options, meshFilter);
            }

            lastModel = p_model;
        };

        return new Promise<void>((resolve, reject) => {
            let data = asset.Datas as Model3dData;
            let modelAsset = new ModelLoader(data.Urls);
            modelAsset.AddOnNewLevelLoadedCallback(addNewLevel);
            modelAsset.LoadAsync().then((model) => {
                resolve();
            },
                (reason)=>{
                    this.m_getErrorModelAsset.then((model)=>{
                        this.AddOrUpdateModel(p_entity, model, lastModel, true, p_levelLoadedCallback);
                        lastModel = model;
                    });
                    reject(reason);
            });
        });
    }


    private AddOrUpdateModel(p_entity: SceneEntity, p_model: Group, p_lastModel: Group|undefined, p_isSelfSufficientInMaterial: boolean, p_levelLoadedCallback: (p_resource: Material | Material[] | Group | Object3D<Event>) => void): MeshFilterComponent{
        let meshFilter: MeshFilterComponent | undefined = undefined;
        if (p_lastModel !== undefined) {
            meshFilter = this.m_entityMeshController.FindMeshFilterWithModel(p_entity, p_lastModel);
            if (meshFilter) {
                meshFilter.IsSelfSufficientInMaterial = p_isSelfSufficientInMaterial;
                meshFilter.Model = p_model;
            }
            p_levelLoadedCallback(p_lastModel);
        } else {
            meshFilter = this.m_entityMeshController.AddMeshFilter(p_entity, p_model, p_isSelfSufficientInMaterial);
        }

        if(meshFilter){
            meshFilter.GetMeshes().forEach((mesh)=>{
                mesh.geometry.computeBoundsTree();
            });
        }

        return meshFilter!;
    }
    
}
