import { IHandler } from '@lutithree/build/Modules/Core/Event/IHandler';
import { RenderMode } from "@lutithree/build/Modules/WebGL/Rendering/RenderingStrategies/RenderMode";
import { SnappableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SnappableComponent";
import EntityTranslatedEvent from "../TransformControls/Events/EntityTranslatedEvent";
import TranslationOnPlaneChangeEvent from "../TransformControls/Events/TranslationOnPlaneChangeEvent";
import { AHandler } from "@lutithree/build/Handlers/AHandler";
import { Engine } from "@lutithree/build/Engine";
import SnapService from "../Snap/SnapService";
import { INavigationController } from "../../../Domain/Features3D/INavigationController";
import { EntityUpdateDragEvent } from "@lutithree/build/Modules/WebGL/Scene/Events/EntityUpdateDragEvent";
import { EntityBeginDragEvent } from "@lutithree/build/Modules/WebGL/Scene/Events/EntityBeginDragEvent";
import { EntityEndDragEvent } from "@lutithree/build/Modules/WebGL/Scene/Events/EntityEndDragEvent";
import { EntityInitialRaycastEvent } from "@lutithree/build/Modules/WebGL/Scene/Events/EntityInitialRaycastEvent";
import { AEntityDraggedEvent } from "@lutithree/build/Modules/WebGL/Scene/Events/AEntityDraggedEvent";
import TranslationOnPlaneService from "../TransformControls/TranslationOnPlaneService";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { TranslatableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/TranslatableComponent";
import { GroupSystem } from "@lutithree/build/Modules/WebGL/Scene/Systems/GroupSystem";

export class TranslationHandler extends AHandler implements 
    IHandler<EntityTranslatedEvent>,
    IHandler<TranslationOnPlaneChangeEvent>,
    IHandler<EntityUpdateDragEvent>,
    IHandler<EntityBeginDragEvent>,
    IHandler<EntityEndDragEvent>,
    IHandler<EntityInitialRaycastEvent>{

    protected m_cameraService: INavigationController;

    protected m_snapService: SnapService;

    protected m_translationService: TranslationOnPlaneService;
    
    protected m_groupSystem: GroupSystem;

    public constructor(p_engine: Engine, p_translation: TranslationOnPlaneService, p_snapService: SnapService, p_cameraService: INavigationController) {
        if (p_snapService == null) throw new Error('NullReferenceException : p_snapService is null or undefined');
        if (p_cameraService == null) throw new Error('NullReferenceException : p_cameraService is null or undefined');
        if (p_translation == null) throw new Error('NullReferenceException : p_translation is null or undefined');
        super(p_engine);

        this.m_translationService = p_translation;
        this.m_snapService = p_snapService;
        this.m_cameraService = p_cameraService;
        this.m_groupSystem = new GroupSystem(this.m_engine.Modules.Scene);
    }
    
    public Handle(p_event: EntityTranslatedEvent | TranslationOnPlaneChangeEvent | EntityEndDragEvent | AEntityDraggedEvent | EntityInitialRaycastEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');

        if (p_event instanceof EntityTranslatedEvent) this.HandleEntityTranslated(p_event);
        else if (p_event instanceof TranslationOnPlaneChangeEvent) this.HandleEntityTranslationChange(p_event);
        else if (p_event instanceof EntityBeginDragEvent) this.HandleBeginDrag(p_event);
        else if (p_event instanceof EntityUpdateDragEvent) this.HandleDrag(p_event);
        else if (p_event instanceof EntityEndDragEvent) this.HandleEndDrag(p_event);
        else if (p_event instanceof EntityInitialRaycastEvent) this.HandleInitialDrag(p_event);
    }

    private HandleEntityTranslated(p_event: EntityTranslatedEvent): void {
        if (p_event.Entity.HasComponentOfType(SnappableComponent)) this.m_snapService.TrySnapEntityToSupports(p_event.Entity);
    }

    private HandleEntityTranslationChange(p_event: TranslationOnPlaneChangeEvent): void {
        if(p_event.IsActivated){
            this.m_cameraService.EnableNavigation(false);
            this.m_engine.Modules.Rendering.OverrideRenderMode(RenderMode.Default); 
        }
        else{
            this.m_cameraService.EnableNavigation(true);
            this.m_engine.Modules.Rendering.OverrideRenderMode(null);
        }
    }

    private HandleInitialDrag(p_event: EntityInitialRaycastEvent): void {
        let entities: SceneEntity[] = [];
        p_event.RaycastedComponents.forEach((component)=>{
            let entity = this.m_engine.Modules.Scene.GetEntityByID(component.EntityID);
            let groupedEnities = this.m_groupSystem.GetGroupEntities(entity);
            entities = entities.concat(groupedEnities);
        });

        entities.forEach((entity)=>{
            let translatableComponent = entity.HasComponentOfType(TranslatableComponent)?entity.GetComponentOfType(TranslatableComponent):undefined;
            if(translatableComponent && translatableComponent.IsEnabled){
                this.m_cameraService.EnableNavigation(false);
            }
        });
    }

    private HandleBeginDrag(p_event: EntityBeginDragEvent): void {
        let mainCamera = this.m_engine.Modules.Systems.CameraSystem.GetMainCameraComponent().GetObject();
        this.m_translationService.BeginTranslate(p_event.Entity, p_event.InitialHitPoint, p_event.ViewportPosition, mainCamera);
    }

    private HandleDrag(p_event: EntityUpdateDragEvent): void {
        let mainCamera = this.m_engine.Modules.Systems.CameraSystem.GetMainCameraComponent().GetObject();
        this.m_translationService.OnTranslate(p_event.Entity, p_event.InitialHitPoint, p_event.ViewportPosition, mainCamera);
    }

    private HandleEndDrag(p_event: EntityEndDragEvent): void {
        this.m_translationService.EndTranslate(p_event.Entity);
    }
}
