import { Engine } from '@lutithree/build/Engine';
import RoomStudioServices from '../RoomStudioServices';
import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import { IEvent } from "@lutithree/build/Modules/Core/Event/IEvent";
import AContextualBehaviours from "../../../../../../application3D-common/Librairies/FSM/AContextualBehaviours";

export default class FurnishingBehaviours extends AContextualBehaviours<{ Engine: Engine; Services: RoomStudioServices; Handlers: ReadonlyMap<string, IHandler<IEvent>>  }> {
    public constructor(p_engine: Engine, p_services: RoomStudioServices, p_handlers:  ReadonlyMap<string, IHandler<IEvent>>) {
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');
        if (p_services == null) throw new Error('NullReferenceException : p_services is null or undefined');
        super({ Engine: p_engine, Services: p_services, Handlers:p_handlers });

        // TODO fill reactions map
    }

    public Enter(): void {
        //console.log('FurnishingBehaviours Enter!');
    }

    public Exit(): void {
        //console.log('FurnishingBehaviours Exit!');
    }
}
