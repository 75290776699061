import ARoomStudioHandler from "../../../ARoomStudioHandler";
import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import { Engine } from "@lutithree/build/Engine";
import RoomStudioServices from "../../RoomStudioServices";
import { GroupComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/GroupComponent";
import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { GroupSystem } from "@lutithree/build/Modules/WebGL/Scene/Systems/GroupSystem";
import { RaycastableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/RaycastableComponent";
import { BoundingBoxComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundingBoxComponent";
import { TransformControlComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Controls/TransformControlComponent";
import LSTransformControls from "@lutithree/build/Librairies/LSCustomControls/LSTransformControls";
import { Quaternion, Vector3 } from "three";
import EntitySelectionStatusDirtyEvent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Selection/Events/EntitySelectionStatusDirtyEvent";
import {
    TemporaryNodeComponent
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Selection/Components/TemporaryNodeComponent";

export class ObjectMultiselectionEffectsHandler extends ARoomStudioHandler implements IHandler<EntitySelectionStatusDirtyEvent> {
    
    private m_groupSystem: GroupSystem;

    public constructor(p_engine: Engine, p_services: RoomStudioServices){
        super(p_engine,p_services);
        
        this.m_groupSystem = new GroupSystem(this.m_engine.Modules.Scene);
    }
    
    public Handle(p_event: EntitySelectionStatusDirtyEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');
        
        if(p_event.Selectable.IsEnabled){
            let enable = p_event.Selectable.IsSelected && p_event.Selectable.IsSelectionVisible;
            let multiSelectionNode = this.FindMultiSelectionNode();
            if(multiSelectionNode){
                let entityNode = this.m_engine.Scene.GetEntityByID(multiSelectionNode.EntityID);
                this.UpdateSelection(enable, p_event.Entity, {Entity: entityNode, Component:multiSelectionNode});
            }
        }

        let isSelected: boolean = true;
        if(!p_event.Selectable.IsSelected || !p_event.Selectable.IsEnabled) isSelected = false;
        this.m_services.ObjectBehaviours.BasicObjects.OnSelectionChange(p_event.Entity,isSelected);

        this.m_services.Notifier.NotifyEntityClicked(p_event.Entity.Id, isSelected);
    }
    
    private FindMultiSelectionNode(): TemporaryNodeComponent|undefined {
        let node: TemporaryNodeComponent|undefined  = undefined;
        let groupComponents = this.m_engine.Modules.Scene.GetComponents(GroupComponent);
        groupComponents = groupComponents.filter(component => component.GroupRef === 'feature-multiSelection-move');
        let multiSelectionGroupNode = groupComponents.length>0? groupComponents[0] : undefined;
        if(multiSelectionGroupNode){
            let entity = this.m_engine.Modules.Scene.GetEntityByID(multiSelectionGroupNode.EntityID);
            node = entity.HasComponentOfType(TemporaryNodeComponent)?entity.GetComponentOfType(TemporaryNodeComponent):undefined;
        }
        return node;
    }
    
    private UpdateSelection(p_value: boolean, p_entity: SceneEntity, p_node : {Entity: SceneEntity, Component:TemporaryNodeComponent}): void {
        if(p_value){
            this.AddObjectToSelection(p_entity,p_node);
        }
        else{
            this.RemoveObjectToSelection(p_entity,p_node);
        }
    }
    
    private AddObjectToSelection(p_entity: SceneEntity, p_node: {Entity: SceneEntity, Component:TemporaryNodeComponent}){
        if(p_node.Component.HasChild(p_entity.Transform.GetObject())) return;
            
        p_node.Component.AttachChildren2Parents();
        p_node.Entity.Transform.GetObject().position.copy(new Vector3());
        p_node.Entity.Transform.GetObject().quaternion.copy(new Quaternion());
        p_node.Component.AttachChildren2Node();
        
        p_node.Component.AddChild(p_entity.Transform.GetObject());

        // set node position
        let bb = p_node.Entity.HasComponentOfType(BoundingBoxComponent)?p_node.Entity.GetComponentOfType(BoundingBoxComponent):undefined;
        if(bb){
            console.log('bb.Center ',bb.Center);
            
            bb.ComputeBoundingBox(p_node.Entity.Transform.GetObject());
            p_node.Component.AttachChildren2Parents();
            let newPos = bb.Center.clone().sub(new Vector3(0,bb.HalfSize.y,0));
            p_node.Entity.Transform.GetObject().position.copy(p_node.Entity.Transform.GetObject().worldToLocal(newPos));
            p_node.Component.AttachChildren2Node();
            bb.ComputeBoundingBox(p_node.Entity.Transform.GetObject());
        }

        // groups
        let groupedEntities = this.m_groupSystem.GetGroupEntitiesWith(p_entity, RaycastableComponent);
        groupedEntities.forEach((entity)=>{
            entity.AddComponentOfType(GroupComponent, 'feature-multiSelection-move');
        });
        
        // effects
        this.m_services.SelectionEffects.EnableOutline(p_entity, true);
        if(p_node.Component.GetChildNumber()===1) {
            this.m_services.SelectionEffects.EnableYupGizmo(p_node.Entity, true);
            this.m_services.SelectionEffects.EnableTranslationOnPlane(p_node.Entity, true);
            this.m_services.SelectionEffects.EnableRotationGizmo(p_node.Entity, true);
        }
        this.m_engine.Modules.Scene.GetComponents(TransformControlComponent).forEach((obj) => {
            if (obj.GetObject() instanceof LSTransformControls)
                (obj.GetObject() as LSTransformControls).updateGyzmosSize();
        });
    }

    private RemoveObjectToSelection(p_entity: SceneEntity, p_node: {Entity: SceneEntity, Component:TemporaryNodeComponent}){
        
        p_node.Component.AttachChildren2Parents();
        p_node.Entity.Transform.GetObject().position.copy(new Vector3());
        p_node.Entity.Transform.GetObject().quaternion.copy(new Quaternion());
        p_node.Component.AttachChildren2Node();
        p_node.Component.RemoveChild(p_entity.Transform.GetObject());
        
        // set node position
        let bb = p_node.Entity.HasComponentOfType(BoundingBoxComponent)?p_node.Entity.GetComponentOfType(BoundingBoxComponent):undefined;
        if(bb){
            bb.ComputeBoundingBox(p_node.Entity.Transform.GetObject());
            p_node.Component.AttachChildren2Parents();
            let newPos = bb.Center.clone().sub(new Vector3(0,bb.HalfSize.y,0));
            p_node.Entity.Transform.GetObject().position.copy(p_node.Entity.Transform.GetObject().worldToLocal(newPos));
            p_node.Component.AttachChildren2Node();
            bb.ComputeBoundingBox(p_node.Entity.Transform.GetObject());
        }
        
        // effects
        this.m_services.SelectionEffects.EnableOutline(p_entity, false);
        if(p_node.Component.GetChildNumber()===0) {
            p_node.Entity.Transform.GetObject().position.copy(new Vector3());
            p_node.Entity.Transform.GetObject().quaternion.copy(new Quaternion());
            this.m_services.SelectionEffects.EnableYupGizmo(p_node.Entity, false);
            this.m_services.SelectionEffects.EnableTranslationOnPlane(p_node.Entity, false);
            this.m_services.SelectionEffects.EnableRotationGizmo(p_node.Entity, false);
        }

        // groups
        let groupedEntities = this.m_groupSystem.GetGroupEntitiesWith(p_entity, RaycastableComponent);
        groupedEntities.forEach((entity)=>{
            if(!entity.HasComponentOfType(TemporaryNodeComponent)){
                let groupComponents = entity.GetComponentsOfType(GroupComponent);
                groupComponents = groupComponents.filter(component => component.GroupRef === 'feature-multiSelection-move');
                groupComponents.forEach((component)=>{
                    entity.RemoveComponent(component);
                });
            }
        });

        this.m_engine.Modules.Scene.GetComponents(TransformControlComponent).forEach((obj) => {
            if (obj.GetObject() instanceof LSTransformControls)
                (obj.GetObject() as LSTransformControls).updateGyzmosSize();
        });
        
        // compute objet bb
        let entityBb = p_entity.HasComponentOfType(BoundingBoxComponent)?p_entity.GetComponentOfType(BoundingBoxComponent):undefined;
        if(entityBb){
            entityBb.ComputeBoundingBox(p_entity.Transform.GetObject());
        }
    }
}