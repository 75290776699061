import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Guid } from "guid-typescript";
import BasicObject
    from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import RoomStudioApp from "../../../Libraries/Studios/RoomStudio/RoomStudioApp";
import Instance3D
    from "../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/Instance3D";
import ObjectInstanciatedTSEvent
    from "../../../Libraries/Studios/RoomStudio/GameLogic/Notifier/TS_Events/ObjectInstanciatedTSEvent";
import {addInstances} from "../../../Redux/Reducers/room-studio/RoomContentReducer";
import {addEntityId} from "../../../Redux/Reducers/room-studio/EntitiesReducer";


export type RoomObjectLoaderProps = {
    roomStudio : RoomStudioApp;
    instances:  Instance3D[]
    basicObjects: BasicObject[]
    entityIds: {refOfInstance: string, entityId: Guid}[];
};

const RoomObjectLoader = (props: RoomObjectLoaderProps) => {
    const dispatch = useDispatch();
    
    const [objectToInstanciate, setObjectToInstanciate] = useState(new Array<BasicObject>());

    useEffect(() => {
        function handleObjectInstanciated(ev: CustomEvent<ObjectInstanciatedTSEvent>) {
            dispatch(addInstances(ev.detail.Instance));
            dispatch(addEntityId({ instance: ev.detail.Instance, id: ev.detail.EntityID }));
        }
        window.addEventListener('application3d-objectInstanciated', handleObjectInstanciated);

        return () => {
            window.removeEventListener('application3d-objectInstanciated', handleObjectInstanciated);
        };
    }, []);


    useEffect(() => {
        if (props.basicObjects) {
            let objectWithoutEntity: BasicObject[] = [];
            props.basicObjects.forEach((basicObject) => {
                if (!props.entityIds.some((x) => x.refOfInstance === basicObject.RefOfInstance)) {
                    objectWithoutEntity.push(basicObject);
                }
            });

            let newObjectToInstanciate: BasicObject[] = [];
            objectWithoutEntity.forEach((basicObject) => {
                if (!objectToInstanciate.some(x => x.RefOfInstance === basicObject.RefOfInstance)) {
                    newObjectToInstanciate.push(basicObject);
                }
            });
            if (newObjectToInstanciate.length > 0) {
                setObjectToInstanciate(newObjectToInstanciate);
            }
        }
    }, [props.basicObjects]);

    useEffect(() => {
        props.roomStudio.Services.Room.InstanciateObjectsAsync(objectToInstanciate).then(()=>{
            setObjectToInstanciate([]);
        });
    }, [objectToInstanciate]);

    /*useEffect(() => {
        console.log('///////////// objectToInstanciate ', objectToInstanciate);
    }, [objectToInstanciate]);
    
    useEffect(() => {
        console.log('///////////// instances ',props.instances);
    }, [props.instances]);

    useEffect(() => {
        console.log('/////////////  basicObjects ',props.basicObjects);
    }, [props.basicObjects]);*/

    return <></>;
};

export { RoomObjectLoader };
