import {Engine} from "@lutithree/build/Engine";
import {SceneEntity} from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import {BoundingBoxComponent} from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundingBoxComponent";
import {Vector3} from "three";
import {
    INavigationController
} from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Features3D/INavigationController";
import BoundingBoxChangedEvent
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/RelativePositioning/Events/BoundingBoxChangedEvent";
import {
    Object3DUtils as Seller3DUtils
} from "../../../../../../application3D-common/Librairies/Studios/Application3D/Utils/Object3DUtils";

export default class AObjectBehaviour {

    protected readonly m_engine: Engine;

    protected readonly m_navigation: INavigationController;

    public constructor(p_engine: Engine, p_navigation: INavigationController) {
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');
        if (p_navigation == null) throw new Error('NullReferenceException : p_navigation is null or undefined');

        this.m_engine = p_engine;
        this.m_navigation = p_navigation;
    }

    public OnAwake(p_entity: SceneEntity): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
    }

    public OnGeometryChange(p_entity: SceneEntity): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        let boundingBox = p_entity.HasComponentOfType(BoundingBoxComponent) ? p_entity.GetComponentOfType(BoundingBoxComponent) : undefined;
        if (boundingBox) {
            boundingBox.ComputeBoundingBox(p_entity.Transform.GetObject());
            let entityWorldPos = new Vector3();
            p_entity.Transform.GetObject().getWorldPosition(entityWorldPos);
            if (entityWorldPos.distanceTo(boundingBox.Center) > 0.0001) {
                Seller3DUtils.SetPivotAtObjectBBCenter(boundingBox.Center, p_entity!.Transform.GetObject());
                boundingBox.ComputeBoundingBox(p_entity.Transform.GetObject());
            }
            this.m_engine.Modules.EventManager.Publish(BoundingBoxChangedEvent, new BoundingBoxChangedEvent(p_entity));
        }
    }
}