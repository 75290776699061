import apiClient from '../../api-client';
import {
    CREATE_UPDATE_CATALOG,
    DELETE_CATALOG,
    FIND_ALL_PRODUCTS_IN_CATALOG_SECTION,
    FIND_CATALOGS_BY_PRODUCT_ID,
    GET_ALL_PRODUCTS_CATALOG_SUBSECTION,
    GET_ALL_PRODUCTS_CATALOG_SUBSECTION_TREE,
    GET_CATALOG_BY_ID,
    GET_CATALOG_BY_REF,
    GET_CATALOGS,
    GET_CATALOGS_BY_USER,
    LINK_PRODUCT_TO_CATALOG,
    SEARCH_PRODUCTS_IN_CATALOG,
    TREE_CATALOG,
    UNLINK_PRODUCT_TO_CATALOG,
} from '../../enpoint/catalog/catalog.endpoint';

export type CreateForm = {
    id?: number;
    version?: number;
    reference?: string;
    name: String;
    createdAt?: Date;
    updatedAt?: Date;
};

const catalogServices = {
    getAllCatalog: async () => apiClient(GET_CATALOGS, undefined, undefined, 'GET'),
    getAllCatalogByUser: async () => apiClient(GET_CATALOGS_BY_USER, undefined, undefined, 'GET'),
    getCatalogById: async (catalogId: number) => apiClient(GET_CATALOG_BY_ID(catalogId), undefined, undefined, 'GET'),
    getCatalogsByProductId: async (productId: number) => apiClient(FIND_CATALOGS_BY_PRODUCT_ID(productId), undefined, undefined, 'GET'),
    getCatalogByRef: async (catalogRef: string) => apiClient(GET_CATALOG_BY_REF(catalogRef), undefined, undefined, 'GET'),
    createAndUpdateCatalog: async (createForm: CreateForm) => apiClient(CREATE_UPDATE_CATALOG, createForm, undefined, 'POST'),
    deleteCatalog: async (catalogId: number) => apiClient(DELETE_CATALOG(catalogId), undefined, undefined, 'DELETE'),
    treeCatalog: async (catalogId: number) => apiClient(TREE_CATALOG(catalogId), undefined, undefined, 'GET'),
    linkPresetToSubSection: async (catalogId: number, productId: number) => apiClient(LINK_PRODUCT_TO_CATALOG(catalogId, productId), undefined, undefined, 'POST'),
    unLinkPresetToSubSection: async (catalogId: number, productId: number) => apiClient(UNLINK_PRODUCT_TO_CATALOG(catalogId, productId), undefined, undefined, 'DELETE'),

    getAllProductsInCatalogSubSection: async (catalogId: number, sectionReference: string, subSectionReference: string) =>
        apiClient(GET_ALL_PRODUCTS_CATALOG_SUBSECTION(catalogId, sectionReference, subSectionReference), undefined, undefined, 'GET'),
    getAllProductsInCatalogSubSectionTree: async (catalogId: number, sectionReference: string, subSectionReference: string) =>
        apiClient(GET_ALL_PRODUCTS_CATALOG_SUBSECTION_TREE(catalogId, sectionReference, subSectionReference), undefined, undefined, 'GET'),
    getAllProductsInCatalogSection: async (sectionId: number) => apiClient(FIND_ALL_PRODUCTS_IN_CATALOG_SECTION(sectionId), undefined, undefined, 'GET'),

    searchProductsInCatalog: async (query: string, catalogId: number) => apiClient(SEARCH_PRODUCTS_IN_CATALOG(catalogId, query), undefined, undefined, 'get'),
};
export default catalogServices;
