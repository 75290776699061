import React, { useEffect } from "react";
import ProductStudioApp from "../../Librairies/Studios/ProductStudio/ProductStudioApp";
import { useDispatch } from "react-redux";
import EntitySelectedTSEvent from "../../Librairies/Studios/Application3D/GameLogic/Notifier/TSEvents/EntitySelectedTSEvent";
import ConnectorClickedTSEvent from "../../Librairies/Studios/Application3D/GameLogic/Notifier/TSEvents/ConnectorClickedTSEvent";
import {setSelectedConnector, setSelectedEntity} from "../../Redux/Reducers/product-studio/ProductStudioReducer";

export type ProductStudioListnerProps = {
    app: ProductStudioApp;
};

const ProductStudioListner = (props: ProductStudioListnerProps) => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        function handleEntitySelected(ev: CustomEvent<EntitySelectedTSEvent>) {
           
            if(props.app.EngineService.Scene.HasEntityWithID(ev.detail.EntityID)){
                let entity = props.app.EngineService.Scene.GetEntityByID(ev.detail.EntityID);
                dispatch(setSelectedEntity({ entity: entity, isSelected: ev.detail.IsSelected }));
            }
        }
        window.addEventListener('application3d-onEntitySelected', handleEntitySelected);

        return () => {
            window.removeEventListener('application3d-onEntitySelected', handleEntitySelected);
        };
    }, []);

    useEffect(() => {
        function handleConnectorSelected(ev: CustomEvent<ConnectorClickedTSEvent>) {
            if(props.app.EngineService.Scene.HasEntityWithID(ev.detail.EntityID)){
                console.log(' ------ PRODUCT application3d-onConnectorClicked ');
                dispatch(setSelectedConnector({ refOfConnector: ev.detail.RefOfConnector, isSelected: ev.detail.IsSelected }));
            }
        }

        window.addEventListener('application3d-onConnectorClicked', handleConnectorSelected);

        return () => {
            window.removeEventListener('application3d-onConnectorClicked', handleConnectorSelected);
        };
    }, []);

    return (
        <></>
    );
    
};
export default ProductStudioListner;