import React from 'react';
import Stats from 'three/examples/jsm/libs/stats.module';

type PerfStatsProps = {
    top?: number;
    left?: number;
};

export default class PerfStatsTool extends React.Component<PerfStatsProps> {
    private readonly m_positionX: number | undefined;

    private readonly m_positionY: number | undefined;

    private readonly m_renderStats: Stats;

    constructor(props: PerfStatsProps) {
        super(props);
        this.m_positionY = props.top ? props.top : 30;
        this.m_positionX = props.left ? props.left : 30;
        this.m_renderStats = new Stats();

        (this.m_renderStats as any).domElement.style.position = 'absolute';
        (this.m_renderStats as any).domElement.style.left = this.m_positionX + 'px';
        (this.m_renderStats as any).domElement.style.top = this.m_positionY + 'px';
    }

    componentDidMount() {
        document.body.appendChild((this.m_renderStats as any).domElement);
        console.log('Component Added');
    }

    componentWillUnmount() {
        document.body.removeChild((this.m_renderStats as any).domElement);
    }

    public Update() {
        this.m_renderStats.update();
    }

    render() {
        return (
            <>
                <div></div>
            </>
        );
    }
}
