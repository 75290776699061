import { DisableComponent } from '@lutithree/build/Modules/Core/Entity/DisableComponent';
import {ObjectAction} from "../../../Domain/Objects/ObjectAction";

export default class ActionComponent extends DisableComponent {
    private m_name: string;

    private m_actions: ObjectAction[];

    public constructor(p_name: string, p_actions: ObjectAction[]) {
        super();
        if (!p_name) throw new Error('NullReferenceException : p_name is null or undefined or empty');
        if (p_actions == null) throw new Error('NullReferenceException : p_actions is null or undefined');
        this.m_name = p_name;
        this.m_actions = p_actions;
    }

    public get Name(): string {
        return this.m_name;
    }

    public get Actions(): ReadonlyArray<ObjectAction> {
        return this.m_actions;
    }

    public HasAction(p_action: ObjectAction): boolean {
        return this.m_actions.includes(p_action);
    }
}
