import AState from "../../../../FSM/AState";

export default class ConfiguringBehaviours extends AState {
    public Enter(): void {
        console.log('ConfiguringBehaviours Enter!');
    }

    public Exit(): void {
        console.log('ConfiguringBehaviours Exit!');
    }
}
