import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { BackSide, ColorRepresentation, EquirectangularReflectionMapping, Mesh, MeshBasicMaterial, MeshStandardMaterial, PMREMGenerator, SphereGeometry, WebGLRenderer } from "three";
import { MeshFilterComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent";
import { DataTextureLoader } from "@lutithree/build/Modules/WebGL/Resources/Load/DataTextureLoader";
import { MeshRendererComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshRendererComponent";
import { IAsyncEntityDecorator } from "../../../../Domain/IAsyncEntityDecorator";

export class BackgroundEnvmapDecorator implements IAsyncEntityDecorator<SceneEntity> {

    private static m_pmremGenerator: PMREMGenerator;

    private m_envmapContent: string | ColorRepresentation;

    public constructor(p_envmapContent: string | ColorRepresentation, p_viewport: WebGLRenderer) {
        if (!p_envmapContent) throw new Error('NullReferenceException : p_orbitControls is null or undefined');
        if (p_viewport == null) throw new Error('NullReferenceException : p_viewport is null or undefined ');

        this.m_envmapContent = p_envmapContent;

        if (BackgroundEnvmapDecorator.m_pmremGenerator) BackgroundEnvmapDecorator.m_pmremGenerator.dispose();
        BackgroundEnvmapDecorator.m_pmremGenerator = new PMREMGenerator(p_viewport);
        BackgroundEnvmapDecorator.m_pmremGenerator.compileEquirectangularShader();
    }

    public DecorateAsync(p_entity: SceneEntity): Promise<void> {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        let geometry = new SphereGeometry(30, 16, 16);
        let sphere = new Mesh(geometry);
        sphere.renderOrder = -50;
        let meshFilter = p_entity.AddComponentOfType(MeshFilterComponent, sphere);

        return new Promise<void>((resolve) => {
            if( typeof this.m_envmapContent === 'string' || this.m_envmapContent instanceof String){

                new DataTextureLoader(this.m_envmapContent as string).LoadAsync().then((texture) => {
                    texture.mapping = EquirectangularReflectionMapping;
                    texture = BackgroundEnvmapDecorator.m_pmremGenerator.fromEquirectangular(texture).texture;
                    let material = new MeshStandardMaterial({
                        color: 0xffffff,
                        side: BackSide,
                        depthWrite: false,
                        envMap: texture,
                        metalness: 1.0,
                        roughness: 0.4,
                    });

                    let meshRenderer = p_entity.AddComponentOfType(MeshRendererComponent, false, false, material);
                    meshRenderer.AddMeshFilter(meshFilter);
                    resolve();
                });
            }
            else {
                let material = new MeshBasicMaterial({
                    color: this.m_envmapContent,
                    side: BackSide,
                    depthWrite: false,
                });

                let meshRenderer = p_entity.AddComponentOfType(MeshRendererComponent, false, false, material);
                meshRenderer.AddMeshFilter(meshFilter);
                resolve();
            }
        });
    }
}