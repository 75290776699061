import { combineReducers } from 'redux';

import { getAllUnreadNewsSlice, getNewsSlice, getSearchNewsSlice, markNewsAsReadSlice } from './reducers/news/news.slice';
import newsCategoryReducer from './reducers/news/news-cateogory-list.slice';
import { authSlice, createPasswordSlice, forgotPasswordSlice } from './reducers/auth/auth.slice';
import { plannerSlice } from './reducers/planner/planner.slice';
import { uploadSpeedTestSlice } from './reducers/speed-test/speed-test-reducer';
import SelectedPresetReducer from './reducers/planner/selected-preset-reducer';
import { getConfigurateurSlice, getDefaultConfigurationSlice, getReferentialSetByConfigurationSlice, getReferentialSetSlice } from './reducers/configurateur/configurateur.slice';
import { getCurrentUserSlice } from './reducers/user/user-reducer';
import modalReducer from './reducers/modal/modal.slice';
import updateUserSlice from './reducers/update-user/update-user.slice';
import resetPasswordSlice from './reducers/reset-password/reset-password';
import {
    getConfigurateurV2Slice,
    getDefaultConfigurationV2Slice,
    getReferentialSetByConfigurationV2Slice,
    getReferentialSetV2Slice,
    getSuggestionConfigurationV2Slice,
} from './reducers/configurateur-v2/configurateur-v2.slice';
import {
    createProjectsSlice,
    deleteUserProjectsSlice,
    getProjectsSlice,
    getUserProjectByCodeSlice,
    getUserProjectsSlice,
    importProjectsSlice,
    updateProjectNameSlice,
    updateProjectRoomSlice,
    updateProjectSlice,
} from './reducers/projects/project.slice';

import { addRoomSlice, deleteRoomSlice, duplicateRoomSlice, editRoomDescSlice, getRoomModelSlice, getRoomNameSlice, getRoomSlice, updateRoomNameSlice } from './reducers/rooms/rooms.slice';

import { panelSlice } from './reducers/panel/panel.slice';
import { getFinishAndColorsByTagSlice } from './reducers/tags/tags.slice';
import { addScreenShotSlice, deleteScreenShotFromRoomSlice, updateRoomVignetteSlice } from './reducers/rooms/screenshot.slice';
import { getAllGroupsSlice, getGroupSlice } from './reducers/mapping-zoning/group-mapping.reducer';
import { getMappingMzSlice } from './reducers/mapping-zoning/mappingMz.reducer';
import { getMappingConstraintSlice } from './reducers/mapping-zoning/mapping-constraint.reducers';
import { updateZoning2Slice } from './reducers/mapping-zoning/zoning.reducer';
import { getAllSubSectionsSlice, getSubSectionByIdSlice } from './reducers/catalog/sub-section.slice';
import { createSectionSlice, deleteSectionSlice, getAllSectionsByCatalogIdSlice, getAllSectionsSlice, getSectionByIdSlice, updateSectionsSlice } from './reducers/catalog/section.slice';
import { create3dCellValueSlice, get3dCellValueSlice, get3dCellValueTreeSlice } from './reducers/product/3DCellValues.reducers';
import { getProductDefaultPresetSlice, getProductsByStatutSlice } from './reducers/product/product.reducers';
import { createProductRulesSlice, deleteProductRulesSlice, executeRulesSlice, getProductRulesSlice, SwitchConfRulesTypeSlice } from './reducers/rules/rules.reducers';
import {
    createPresetSlice,
    deletePresetSlice,
    duplicatePresetSlice,
    getAllpresetSlice,
    getPresetByProductRefSlice,
    renamePresetSlice,
    updatePresetThumbnailUrlSlice,
} from './reducers/product/preset/preset.reducers';
import {
    createAndUpdateCatalogSlice,
    deleteCatalogSlice,
    getAllProductsInCatalogSectionSlice,
    getAllProductsInCatalogSubSectionSlice,
    getAllProductsInCatalogSubSectionTreeSlice,
    getCatalogByIdSlice,
    getCatalogByRefSlice,
    getCatalogsByProductIdSlice,
    getCatalogsByUserSlice,
    getCatalogsSlice,
    getTreeCatalogSlice,
    linkProductToCatalogSlice,
    unlinkProductToCatalogSlice,
} from './reducers/catalog/catalog.slice';
import { findCatalogByAdminSlice, selectCatalogSlice } from './reducers/visibility/visibility.slice';
import {
    addMultipleAtomsToConfiguredProductSlice,
    createConfiguredProductSlice,
    deleteConfiguredProductSlice,
    getConfiguredProductTreeByCoreProductSlice,
    getConfiguredProductTreeListSlice,
    getConfiguredProductTreeSlice,
    uploadThumbnailUrlToConfiguredProductSlice,
} from './reducers/products/configured-product/configured-product.reducers';
import { FindBasketByRoomSlice, UpdateBasketSlice } from './reducers/basket/basket.slice';
import { contextualMenuSlice } from '../../application-3d/application3D-seller/Redux/Reducers/ContextualMenuReducer';
import SellerApp3DModeSlice from '../../application-3d/application3D-seller/Redux/Reducers/SellerApp3DModeReducer';
import roomStudioSlice from '../../application-3d/application3D-seller/Redux/Reducers/room-studio/RoomStudioReducer';
import roomContentSlice from '../../application-3d/application3D-seller/Redux/Reducers/room-studio/RoomContentReducer';
import entitiesSlice from '../../application-3d/application3D-seller/Redux/Reducers/room-studio/EntitiesReducer';
import productStudioSlice from '../../application-3d/application3D-common/Redux/Reducers/product-studio/ProductStudioReducer';
import { basketConfigurationSlice } from './reducers/basket-configuration/basket-configuration.slice';
import store from "../store";

const rootReducer = combineReducers({
    currentUser: getCurrentUserSlice.reducer,
    news: getNewsSlice.reducer,
    unreadNews: getAllUnreadNewsSlice.reducer,
    markNewsAsRead: markNewsAsReadSlice.reducer,
    search: getSearchNewsSlice.reducer,
    categoryList: newsCategoryReducer,
    auth: authSlice.reducer,
    planner: plannerSlice.reducer,
    forgotPassword: forgotPasswordSlice.reducer,
    createPassword: createPasswordSlice.reducer,
    partsPath: SelectedPresetReducer,
    configurateurCollection: getConfigurateurSlice.reducer,
    configurateurV2Collection: getConfigurateurV2Slice.reducer,

    configurateurReferential: getReferentialSetSlice.reducer,
    configurateurV2Referential: getReferentialSetV2Slice.reducer,

    speedTest: uploadSpeedTestSlice.reducer,

    configurateurDefault: getDefaultConfigurationSlice.reducer,
    configurateurV2Default: getDefaultConfigurationV2Slice.reducer,

    suggestionConfiguration: getSuggestionConfigurationV2Slice.reducer,

    referentialSetByConfiguration: getReferentialSetByConfigurationSlice.reducer,
    referentialSetByConfigurationV2: getReferentialSetByConfigurationV2Slice.reducer,

    modals: modalReducer,
    updateUser: updateUserSlice,
    resetUserPassword: resetPasswordSlice,

    getProjects: getProjectsSlice.reducer,
    getUserProjects: getUserProjectsSlice.reducer,
    getUserProjectByCode: getUserProjectByCodeSlice.reducer,

    createProject: createProjectsSlice.reducer,
    importProject: importProjectsSlice.reducer,
    updateProject: updateProjectSlice.reducer,
    updateProjectName: updateProjectNameSlice.reducer,
    deleteUserProject: deleteUserProjectsSlice.reducer,

    addRoom: addRoomSlice.reducer,
    getRoom: getRoomSlice.reducer,
    getRoomName: getRoomNameSlice.reducer,
    deleteRoom: deleteRoomSlice.reducer,
    duplicateRoom: duplicateRoomSlice.reducer,
    updateRoomName: updateRoomNameSlice.reducer,
    updateProjectRoom: updateProjectRoomSlice.reducer,
    editRoomDesc: editRoomDescSlice.reducer,
    getRoomModel: getRoomModelSlice.reducer,
    getFinishAndColorsByTags: getFinishAndColorsByTagSlice.reducer,

    sellerApp3DModes: SellerApp3DModeSlice.reducer,
    roomStudio: roomStudioSlice.reducer,
    roomContent: roomContentSlice.reducer,
    entities: entitiesSlice.reducer,
    productStudio: productStudioSlice.reducer,

    updateZoning2: updateZoning2Slice.reducer,

    panel: panelSlice.reducer,
    contextualMenu: contextualMenuSlice.reducer,
    screenshot: addScreenShotSlice.reducer,
    updateRoomVignette: updateRoomVignetteSlice.reducer,
    deleteScreenShot: deleteScreenShotFromRoomSlice.reducer,

    getGroup: getGroupSlice.reducer,
    getAllGroups: getAllGroupsSlice.reducer,

    getMappingMz: getMappingMzSlice.reducer,
    getMappingConstraint: getMappingConstraintSlice.reducer,

    getAllSections: getAllSectionsSlice.reducer,
    getSectionsByCatalogId: getAllSectionsByCatalogIdSlice.reducer,
    getSection: getSectionByIdSlice.reducer,
    createSection: createSectionSlice.reducer,
    updateSection: updateSectionsSlice.reducer,
    deleteSection: deleteSectionSlice.reducer,

    getAllSubSections: getAllSubSectionsSlice.reducer,
    getSubSection: getSubSectionByIdSlice.reducer,
    getAllProductsCatalogSubSection: getAllProductsInCatalogSubSectionSlice.reducer,
    getAllProductsInCatalogSubSectionTree: getAllProductsInCatalogSubSectionTreeSlice.reducer,
    getAllProductsInCatalogSectionTree: getAllProductsInCatalogSectionSlice.reducer,

    get3dCellValue: get3dCellValueSlice.reducer,
    getCellValueTree: get3dCellValueTreeSlice.reducer,
    create3dCellValue: create3dCellValueSlice.reducer,
    getDefaultConfiguration: getDefaultConfigurationSlice.reducer,

    getProductsByStatut: getProductsByStatutSlice.reducer,
    getProductDefaultPreset: getProductDefaultPresetSlice.reducer,

    getRules: getProductRulesSlice.reducer,
    createRule: createProductRulesSlice.reducer,
    deleteRules: deleteProductRulesSlice.reducer,
    switchRuleType: SwitchConfRulesTypeSlice.reducer,
    executeRules: executeRulesSlice.reducer,

    duplicatePreset: duplicatePresetSlice.reducer,
    getPresetByProductRef: getPresetByProductRefSlice.reducer,
    createPreset: createPresetSlice.reducer,
    renamePreset: renamePresetSlice.reducer,
    getAllPreset: getAllpresetSlice.reducer,
    deletePreset: deletePresetSlice.reducer,
    updatePresetThumbnailUrl: updatePresetThumbnailUrlSlice.reducer,

    createAndUpdateCatalog: createAndUpdateCatalogSlice.reducer,
    getCatalogs: getCatalogsSlice.reducer,
    getCatalogsByUser: getCatalogsByUserSlice.reducer,
    getCatalogById: getCatalogByIdSlice.reducer,
    getCatalogByRef: getCatalogByRefSlice.reducer,
    deleteCatalog: deleteCatalogSlice.reducer,
    getTreeCatalog: getTreeCatalogSlice.reducer,
    linkProductToCatalog: linkProductToCatalogSlice.reducer,
    unlinkProductToCatalog: unlinkProductToCatalogSlice.reducer,
    getCatalogsByProductId: getCatalogsByProductIdSlice.reducer,

    findCatalogByAdmin: findCatalogByAdminSlice.reducer,
    selectedCatalog: selectCatalogSlice.reducer,

    findBasketByRoom: FindBasketByRoomSlice.reducer,
    updateBasket: UpdateBasketSlice.reducer,

    getConfiguredProductTreeList: getConfiguredProductTreeListSlice.reducer,
    getConfiguredProductTreeByCoreProduct: getConfiguredProductTreeByCoreProductSlice.reducer,
    getConfiguredProductTree: getConfiguredProductTreeSlice.reducer,
    createConfiguredProduct: createConfiguredProductSlice.reducer,
    addMultipleAtomsToConfiguredProduct: addMultipleAtomsToConfiguredProductSlice.reducer,
    uploadThumbnailUrlToConfiguredProduct: uploadThumbnailUrlToConfiguredProductSlice.reducer,
    deleteConfiguredProduct: deleteConfiguredProductSlice.reducer,

    basketConfiguration: basketConfigurationSlice.reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default rootReducer;
