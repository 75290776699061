import React from 'react';
import { Provider } from 'react-redux';

import RouteurV6 from './components/router/routeurV6';
import store from "./services/store";

//add test

function App() {
    return (
        <Provider store={store}>
            <RouteurV6 />
        </Provider>
    );
}

export default App;
