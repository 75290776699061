import { SceneEntity } from "@lutithree/build/Modules/WebGL/Scene/SceneEntity";
import { Guid } from "guid-typescript";
import { IEntityDecorator } from "../../../../Domain/IEntityDecorator";
import { DimensionsDisplayerComponent } from "../Components/DimensionDisplayerComponent";

export class DimensionDisplayerDecorator implements IEntityDecorator<SceneEntity> {

    private readonly m_targetGuid: Guid;

    public constructor(p_target: SceneEntity) {
        if (p_target == null) throw new Error('NullReferenceException : p_target is null or undefined');

        this.m_targetGuid = p_target.Id;
    }

    public Decorate(p_entity: SceneEntity): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');

        p_entity.Transform.GetObject().add(p_entity.AddComponentOfType(DimensionsDisplayerComponent, this.m_targetGuid).GetObject());
    }
}