import { AComponentHandler } from '@lutithree/build/Modules/WebGL/Scene/ComponentHandlers/AComponentHandler';
import { IComponentHandler } from '@lutithree/build/Modules/Core/Entity/IComponentHandler';
import { MeshFilterComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent";
import { HookComponent } from "../Components/HookComponent";
import EntityHooksChangeEvent from "../Events/EntityHooksChangeEvent";

export class MeshFilterEnabler extends AComponentHandler implements IComponentHandler<MeshFilterComponent> {
    
    public Handle(p_meshFilterComponent: MeshFilterComponent): void {
        
        let entity = this.m_scene.GetEntityByID(p_meshFilterComponent.EntityID);
        let hookComponents = entity.GetComponentsOfType(HookComponent);
        let filteredHookComponents = hookComponents.filter(x => x.Ref === p_meshFilterComponent?.Model.uuid);

        if(filteredHookComponents.length > 0) {
            filteredHookComponents.forEach((hookComponent)=>{
                if(hookComponent.IsEnabled !== p_meshFilterComponent.IsEnabled) {
                    hookComponent.Enable(p_meshFilterComponent.IsEnabled);
                    this.m_eventManager.Publish(EntityHooksChangeEvent, new EntityHooksChangeEvent(entity));
                }
            });
        }
    }
}
