import * as React from 'react';
import { Box, CardMedia, styled, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';


type CardActionColorProps = {
    colorName: string;
    thumbnailUrl: string;
    index: number;
    focus: number;
};

function CardActionColor({ colorName, thumbnailUrl, index, focus }: CardActionColorProps) {
    return (
        <BoxColorAssociate>
            <Box
                sx={ { position: 'relative' } }
            >
                <CardMedia
                    component='img'
                    sx={ (theme: { palette: { primary: { main: string } } }) => ({
                        border: `${ focus === index ? `solid 3px ${ theme.palette.primary.main }` : `1px solid #CCCCCC` };`,
                        boxSizing: 'border-box',
                        height: 84,
                        width: 84,
                        borderRadius: '3px',
                        marginBottom: '9px',
                    }) }
                    alt='color'
                    src={ thumbnailUrl } />
                { focus === index ?
                    <Box sx={ {
                        position: 'absolute',
                        top: '-3px',
                        right: '-3px',
                        borderRadius: 20,
                        background: (theme) => theme.palette.primary.main,
                        height: '23px',
                        padding: '1px',
                    } }>
                        <DoneIcon
                            sx={ {
                                color: 'white',
                                height: 20,
                            } }
                        />
                    </Box> : null }
            </Box>
            <Typography
                variant='subtitle1'
                color='primary'
                sx={ { fontSize: 16, lineHeight: 1.2, wordBreak: 'break-word', letterSpacing: 1.3 } }
            >
                { colorName }
            </Typography>
        </BoxColorAssociate>
    );
}

const BoxColorAssociate = styled(Box)(() => ({
    padding: 5,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    height: '100%',
    textTransform: 'capitalize',
}));

export default CardActionColor;