import {IHandler} from "@lutithree/build/Modules/Core/Event/IHandler";
import ResourceLoadingFailEvent from "../Events/ResourceLoadingFailEvent";
import TSBasicNotifierService from "../../../Notifier/TSBasicNotifierService";
import {Engine} from "@lutithree/build/Engine";
import {AHandler} from "@lutithree/build/Handlers/AHandler";


export class LoadingFailHandler extends AHandler implements IHandler<ResourceLoadingFailEvent> {
    
    private readonly m_notifier: TSBasicNotifierService;
    
    public constructor(p_engine: Engine, p_notifier: TSBasicNotifierService){
        super(p_engine);
        if (p_notifier == null) throw new Error('NullReferenceException : p_notifier is null or undefined');
        
        this.m_notifier = p_notifier;
    }
    
    public Handle(p_event: ResourceLoadingFailEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');

        this.m_notifier.NotifyResourceLoadingFailed(p_event.ResourceErrorData);
    }
}