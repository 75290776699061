import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Engine } from '@lutithree/build/Engine';
import IRoomService from '../../Domain/Room/IRoomService';
import RoomLoader from './RoomLoader';
import InstancesBuilder from './InstancesBuilder';
import { Vector3 } from "three";
import AService from '../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/AService';
import IObjectLoader
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/IObjectLoader";
import BasicObject
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import Instance3D
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/Instance3D";
import IObjectDataAccess
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/IObjectDataAccess";
import {addInstances} from "../../../../../Redux/Reducers/room-studio/RoomContentReducer";
import DataPrepService from "./DataPrepService";
import SelectionService
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/Selection/SelectionService";
import ObjectComponent
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";
import ObjectAddedToRoomEvent from "../Objects/Events/ObjectAddedToRoomEvent";
import ShapeData
    from "../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/Assets/ShapeData";
import StructureInstanciatedEvent from "../Objects/Events/StructureInstanciatedEvent";

export class RoomService extends AService implements IRoomService {
    
    private m_roomLoader: RoomLoader;

    private m_instancesBuilder: InstancesBuilder;

    private readonly m_dataPrep: DataPrepService;

    private readonly m_selection: SelectionService;

    private readonly  m_dataAccess: IObjectDataAccess ;

    private m_rootEntities: Map<string, SceneEntity>;

    private m_getCenterOfView: ()=>Vector3;
    
    
    public constructor(p_engine: Engine, p_dataAccess: IObjectDataAccess, p_objectLoader: IObjectLoader, p_selection: SelectionService, p_rootEntities: Map<string, SceneEntity>, p_getCenterOfView: ()=>Vector3) {
        super(p_engine);
        if (p_objectLoader == null) throw new Error('NullReferenceException : p_objectLoader is null or undefined');
        if (p_getCenterOfView == null) throw new Error('NullReferenceException : p_getCenterOfView is null or undefined');
        if (p_rootEntities == null) throw new Error('NullReferenceException : p_rootEntities is null or undefined');

        this.m_instancesBuilder = new InstancesBuilder(p_engine, p_rootEntities, p_objectLoader, p_getCenterOfView);
        this.m_roomLoader = new RoomLoader(p_engine, this.m_instancesBuilder);
        this.m_dataAccess = p_dataAccess;
        this.m_dataPrep = new DataPrepService(p_engine.Modules.Scene);
        this.m_selection = p_selection;
        this.m_rootEntities = p_rootEntities;
        this.m_getCenterOfView = p_getCenterOfView;
    }

    public SetOnInstanciateCallback(p_callBack: (p_title: string, p_url: string, p_progress: number) => void): void {
        this.m_roomLoader.SetOnInstanciateCallback(p_callBack);
    }
    
    public RemoveObject(p_entity: SceneEntity): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        this.m_engine.Modules.Scene.RemoveEntity(p_entity);
        this.m_engine.Modules.LoopStrategy.RequestRender(true);
    }

    public InstanciateObjectsAsync(p_basicObjects: BasicObject[]): Promise<void> {
        return new Promise<void>((resolve,reject)=>{

            // todo 
            //  1 filtrer pour instensier uniquement les p_basicObjects roots 
            //  2 implémenter dans object service le chargeent du nouveau format d'objet 
            //  3 appeller depuis le room service la deuxième méthode si on a de l'assembly 
            //  4 propager dans le product studio et tester dans backoffice 
            //  5 contraindre au nouveau format la création des obj dans bo + maj des room obj factory v2 des 2 cotés
            //  6 supprimer composition de basicobject et renommer assembly

         
            p_basicObjects = p_basicObjects.filter(object => object.IsRootObject === true);
            if (p_basicObjects.length > 0) {
                let instancesToAdd = this.m_dataPrep.PrepareRoomObjectsDatas(this.m_dataAccess.Instances3D, p_basicObjects);

                // Instanciation d'objets unique
                if (p_basicObjects.length === 1) {
                    let instance = this.m_dataAccess.GetInstance(p_basicObjects[0].RefOfInstance);
                    if(!instance) instance = instancesToAdd.find(i => i.Ref === p_basicObjects[0].RefOfInstance);

                    let instanciationResult = this.InstanciateObjectAsync(p_basicObjects[0], instance);
                    instanciationResult.then((result) => {
                        if (p_basicObjects[0].Informations.Type !== 'Ground' && p_basicObjects[0].Informations.Type !== 'Wall')
                            this.m_selection.UpdateSelectionStatus(result.entity, false);
                        resolve();
                    });
                } 
                // Instanciation de plusieurs objets en même temps
                else {
                    instancesToAdd = instancesToAdd.concat(this.m_dataAccess.Instances3D);
                    let promise = this.InstanciateRoomAsync(p_basicObjects, instancesToAdd);
                    promise.then(()=>{resolve();});
                }
            }
        });
    }

    private async InstanciateRoomAsync(p_basicObjects: BasicObject[], p_instances: Instance3D[]): Promise<void> {
        return this.m_roomLoader.LaunchInstanciationSequence(p_basicObjects, p_instances);
    }

    private async InstanciateObjectAsync(p_basicObject: BasicObject, p_instance: Instance3D | undefined): Promise<{ entity: SceneEntity; instance: Instance3D }> {
        return this.m_instancesBuilder.InstanciateRoomObject(p_basicObject, p_instance);
    }
}
