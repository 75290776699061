import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../services/redux/root-reducers';
import { createPassword, CreatePasswordState } from '../../services/redux/reducers/auth/auth.slice';
import { setToasterError, setToasterVisible } from '../../services/redux/reducers/toaster-reducer/toaster.slice';
import CreatePaswordForm from '../../components/CreatePasswordForm/CreatePasswordForm';

const CreatePassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const dispatch = useDispatch();
    const history = useNavigate();
    const createPasswordResponse = useSelector<RootState, CreatePasswordState>((state) => state.createPassword);
    // @ts-ignore
    const { resetPasswordToken } = useParams();
    const [passworderrorMessage, setPasswordErrorMessage] = useState<string>('');
    const [confirmPassworderrorMessage, setConfirmPasswordErrorMessage] = useState<string>('');

    const onSubmit = () => {
        if (!resetPasswordToken) {
            return;
        }
        let errorList: string[] = [];
        if (password.length === 0 || confirmPassword.length === 0 || confirmPassword !== password) {
            if (password.length === 0) {
                setPasswordError(true);
                setPasswordErrorMessage('Le mot de passe doit être renseigné');
            }
            if (confirmPassword.length === 0) {
                setConfirmPasswordError(true);
                setConfirmPasswordErrorMessage('La confirmation du mot de passe doit être renseignée');
            } else {
                setPasswordError(true);
                setConfirmPasswordError(true);
                setConfirmPasswordErrorMessage('Les mots de passes ne correspondent pas');
            }
        } else {
            errorList = [];
            setPasswordError(false);
            setConfirmPasswordError(false);
            dispatch(createPassword({ password, resetPasswordToken }));
        }
        setErrors(errorList);
    };

    useEffect(() => {
        const { payload } = createPasswordResponse;

        if (payload.content === null && payload.errors.length === 0) {
        } else if (payload.content === null && payload.errors.length > 0) {
            setConfirmPasswordErrorMessage(payload.errors[0].detail);
            dispatch(setToasterError({ message: payload.errors[0].message, title: payload.errors[0].code }));
        } else {
            dispatch(
                setToasterVisible({
                    message: `${payload.content?.firstname}, Votre mot de passe a été modifié avec succès`,
                    title: 'Mot de passe créé',
                })
            );
            history('/');
        }
    }, [createPasswordResponse]);

    return (
        <div>
            <div />
            <div>
                <CreatePaswordForm
                    confirmPassworderrorMessage={confirmPassworderrorMessage}
                    setConfirmPasswordErrorMessage={setConfirmPasswordErrorMessage}
                    passworderrorMessage={passworderrorMessage}
                    setPasswordErrorMessage={setPasswordErrorMessage}
                    errorList={errors}
                    passwordError={passwordError}
                    setPasswordError={setPasswordError}
                    confirmPasswordError={confirmPasswordError}
                    setConfirmPasswordError={setConfirmPasswordError}
                    onSubmit={() => {
                        onSubmit();
                    }}
                    password={password}
                    setPassword={(value) => {
                        setPassword(value);
                    }}
                    setConfirmPassword={(value: string) => {
                        setConfirmPassword(value);
                    }}
                    confirmPassword={confirmPassword}
                />
            </div>
        </div>
    );
};

export default CreatePassword;
