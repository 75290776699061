import React, { useEffect, useState } from 'react';
import DialogLayout from '../../../ui-components/Dialog/DialogLayout';
import DialogAction from '../../../ui-components/Dialog/DialogAction';
import DialogContent from '@mui/material/DialogContent';
import { Box, Button, Divider, styled } from '@mui/material';
import TextFieldUi from '../../../ui-components/TextFieldUi';
import { useDispatch, useSelector } from 'react-redux';
import {
    createProject,
    CreateProjectsState,
    importProject,
    ImportProjectsState,
    resetCreateProjects,
} from '../../../services/redux/reducers/projects/project.slice';
import { RootState } from '../../../services/redux/root-reducers';
import { getCurrentUserInfo } from '../../../services/redux/reducers/user/user-reducer';
import { useNavigate } from 'react-router-dom';


type CreateProjectProps = {
    onClose: () => void;
    title: string;
    open: boolean;
};

const CreateProject = ({ open, onClose, title }: CreateProjectProps) => {
    const { payload } = useSelector<RootState, CreateProjectsState>((state) => state.createProject);
    const imported = useSelector<RootState, ImportProjectsState>((state) => state.importProject);
    const user = localStorage.getItem('user');
    const userId = user && JSON.parse(user).id;
    const [projectName, setProjectName] = useState('');
    const [isProjectErr, setIsProjectErr] = useState(false);
    const [projectErrMsg, setProjectErrMsg] = useState('');
    const [importedCode, setImportedCode] = useState('');
    const [isCodeErr, setIsCodeErr] = useState(false);
    const [codeErrMsg, setCodeErrMsg] = useState('');
    const [importProjectReq, setImportProjectReq] = useState(false);
    const [projectCode, setProjectCode] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setProjectName(e.target.value);
        if (e.target.value.length === 24) {
            setIsProjectErr(true);
            setProjectErrMsg('Le nombre de caractères maximum est atteint.');
        } else {
            setIsProjectErr(false);
            setProjectErrMsg('');
        }
    };

    const handleChangeFindExistingCode: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setImportedCode(e.target.value);
        if (e.target.value.length === 24) {
            setIsCodeErr(true);
            setCodeErrMsg('Le nombre de caractères maximum est atteint.');
        } else {
            setIsCodeErr(false);
            setCodeErrMsg('');
        }
    };

    const handleSubmit = () => {
        if (importedCode.length === 0 && projectName.length === 0) {
            setIsCodeErr(true);
            setCodeErrMsg('Veuillez entrez un code valide');
            setIsProjectErr(true);
            setProjectErrMsg('Veuillez entrez un nom de projet valide');
        } else {
            if (importedCode.length > 0 && projectName.length > 0) {
                setIsCodeErr(true);
                setCodeErrMsg('choisissez parmi la creation ou l\'import');
                setIsProjectErr(true);
                setProjectErrMsg('choisissez parmi la creation ou l\'import');
            } else {
                if (importedCode.length > 0) {
                    dispatch(importProject({ userId, code: importedCode }));
                } else {
                    dispatch(createProject({ projectName: projectName }));
                }
            }
        }
    };

    useEffect(() => {
        dispatch(resetCreateProjects());
        dispatch(getCurrentUserInfo());
    }, []);


    useEffect(() => {
        if (payload.content !== undefined) {
            setProjectCode(payload.content.code);
            onClose();
        }
    }, [payload]);


    useEffect(() => {
        if (payload.content !== undefined) {
            navigate('/projects');
        }
    }, [projectCode]);


    useEffect(() => {
        if (imported.payload.content !== undefined) {
            if (imported.payload.errors.length !== 0) {
                if (imported.payload.errors[0].code === 'P0001') {
                    setIsCodeErr(true);
                    setCodeErrMsg('Ce code n\'est pas valide');
                }
                if (imported.payload.errors[0].code === 'P0004') {
                    setIsCodeErr(true);
                    setCodeErrMsg('Vous ne pouvez pas importer un projet qui vous appartient ');
                }
            } else {
                navigate(`/project/${ imported.payload.content.id }/${ imported.payload.content.code }`);
            }
        }
    }, [imported]);


    useEffect(() => {
        if (importedCode.length > 0) {
            setImportProjectReq(true);
        } else {
            setImportProjectReq(false);
        }
    }, [importedCode]);

    return (
        <DialogLayout title={ title } onClose={ onClose } open={ open }>
            <DialogContent>
                <TextFieldUi
                    labelContent='Créer un nouveau projet'
                    placeholder='Nom du projet'
                    name='create-project'
                    id='new-project'
                    variant='outlined'
                    error={ isProjectErr }
                    helperText={ projectErrMsg }
                    onChange={ handleChange }
                    fullWidth
                    value={ projectName }
                    inputProps={ { maxLength: 24 } }
                    maxLength={ 24 }
                    showLength
                />
            </DialogContent>
            <DialogContent>
                <Box sx={ { pt: 1, pb: 1 } }>
                    <Divider>OU</Divider>
                </Box>
            </DialogContent>
            <DialogContent>
                <TextFieldUi
                    labelContent="Retrouver un projet existant à partir d'un code"
                    placeholder='Code du projet'
                    name='create-project-code'
                    id='code-project'
                    variant='outlined'
                    onChange={ handleChangeFindExistingCode }
                    fullWidth
                    error={ isCodeErr }
                    helperText={ codeErrMsg }
                    value={ importedCode }
                    inputProps={ { maxLength: 24 } }
                    maxLength={ 24 }
                    showLength
                />
            </DialogContent>
            <DialogAction>
                <CustomBtn variant='outlined' onClick={ onClose } size='large'>
                    annuler
                </CustomBtn>
                <CustomBtn color='secondary' variant='contained' onClick={ handleSubmit } size='large' disableElevation>
                    Ajouter
                </CustomBtn>
            </DialogAction>
        </DialogLayout>
    );
};

const CustomBtn = styled(Button)(() => ({
    width: 200,
    height: 50,
}));

export default CreateProject;
