import React, { useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { News } from '../../../domain/domain';
import axios from 'axios';
import fileDownload from 'js-file-download';
import Tag from '../NewsChip/NewsChip';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import dateFormatter from '../../../utils/date-formatter';
import { numToMonth } from '../../../pages/rooms/monthArray';
import { styled } from "@mui/material";
import CardMedia from '@mui/material/CardMedia';


interface PreviewProps {
    data: News;
    unreadNewsList: News[];
    readNews: (id: number) => void;
    onClose: () => void;
    open: boolean;
}

const NewsPreview = (props: PreviewProps) => {
    const { open, onClose, data, unreadNewsList, readNews } = props;
    const [isReadLabelButton, setIsReadLabelButton] = useState<string>('');
    const [isRead, setIsRead] = useState<boolean>(false);
    const [openPreview, setOpenPreview] = useState<boolean>(false);
    const dateFormat = dateFormatter.dateTimeStampToDateTime(data.createdAt);
    const dateFormatSplit = dateFormat.split('/');
    const monthDate = dateFormatSplit[1];
    const monthDateString = numToMonth(monthDate);
    const spliceDateFormat = dateFormatSplit.splice(1, 1, monthDateString as string);
    const dueDate = dateFormatSplit.join().replaceAll(',', ' ');
    const [imgUrl, setImgUrl] = useState('');
    const extension = imgUrl.split('.').pop();

    const handleOpenImagePreview = (element: string) => {
        setImgUrl(element);
        setOpenPreview(true);
    };

    const handleClick = () => {
        if (!isRead) {
            readNews(data.id);
        } else {
            onClose();
        }
    };

    const downloadDocument = (doc: string) => {
        const filenameToDownload = doc.replace(/^.*[\\/]/, '');
        let fileExtension: string[] | string = doc.split('.');
        fileExtension = fileExtension[fileExtension.length - 1];
        axios.get(`${ doc }`, {
            responseType: 'blob',
        }).then((res) => {
            fileDownload(res.data, `${ filenameToDownload }.${ fileExtension }`);
        }).catch((error) => {
            console.error(error);
        });
    };

    const handleCloseImagePreview = () => {
        setOpenPreview(false);
        setImgUrl('');
    };

    useEffect(() => {
        const foundNewsUnread = unreadNewsList.find((el) => {
            return el.id === data.id;
        });
        if (!foundNewsUnread) {
            setIsRead(true);
            setIsReadLabelButton('Fermer');
        } else {
            setIsReadLabelButton('J\'ai lu la news');
        }
    }, []);

    return (
        <Dialog
            fullWidth
            maxWidth='xl'
            open={ open }
            onClose={ onClose }
        >
            <IconButton
                aria-label='close'
                onClick={ onClose }
                sx={ {
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                } }
            >
                <CloseIcon />
            </IconButton>

            <DialogContent>
                <Tag newsCategoryId={ data.newsCategoryId } />
                <Typography sx={ { mt: 2, mb: 1 } } variant='h1' color='primary'>{ data.title }</Typography>
                <Typography variant='h4' color='primary'>{ dueDate }</Typography>
                <Box sx={ { mt: 3, mb: 3 } }>
                    <Typography
                        sx={ { fontSize: 18 } }
                        variant='body2'
                        color='primary'
                        dangerouslySetInnerHTML={ { __html: data.content } } />
                </Box>
                <Box
                    sx={ { mb: 2 } }
                >
                    <Divider />
                    <Typography
                        sx={ {
                            mt: 1.2,
                            mb: 1,
                            fontSize: 14,
                            letterSpacing: '1.2px',
                            textTransform: 'uppercase',
                            fontWeight: 'normal',
                        } }
                        color='grey'
                    >
                        PIÈCES JOINTES
                    </Typography>
                </Box>

                <Grid container spacing={ 1 } columnSpacing={ { xs: 1, sm: 2, md: 3 } }>
                    { data.documentUrls.map((el, index) => {
                        const url = el.substring(70);
                        const removeExt = url.split('.').slice(0, -1).join('.');
                        const limitCharLength = removeExt.substring(0, 19);
                        const docExtension = el.split(".").pop();

                        return (
                            <Grid item key={ index } xs={ 12 } md={ 6 } lg={ 4 }>
                                <Card sx={ {
                                    p: 0.7,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                } } variant='outlined'>
                                    <Box
                                        display='flex'
                                        alignItems='center'
                                    >
                                        <PermMediaIcon
                                            sx={ {
                                                color: (theme) => theme.palette.grey[700],
                                                fontSize: 34, marginRight: '12px', ml: 1
                                            } } />
                                        <Box>
                                            <Typography color='primary'>{ limitCharLength }</Typography>
                                            <Typography
                                                sx={ { textTransform: 'uppercase' } } color='grey'
                                                variant='body2'>{ docExtension }</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={ {
                                        '& > :not(style)': { mr: 1 },
                                    } }>
                                        <StyledIconButton
                                            onClick={ () => downloadDocument(el) }
                                        >
                                            <DownloadIcon />
                                        </StyledIconButton>
                                        <StyledIconButton
                                            onClick={ () => handleOpenImagePreview(el) }
                                        >
                                            <VisibilityIcon />
                                        </StyledIconButton>
                                    </Box>
                                </Card>
                            </Grid>
                        );
                    }) }
                </Grid>
            </DialogContent>

            <DialogContent
                sx={ {
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    display: 'flex',
                    background: !isRead ? '#f0f4f7' : 'white',
                    overflow: 'hidden'
                } }
            >
                { isRead ? (
                    <Box />) : (
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        <WarningIcon sx={ { fontSize: '27px', mr: 1 } } color='error' />
                        <Typography color='error'>
                            Vous devez confirmer la lecture de cette news
                        </Typography>
                    </Box>
                ) }
                <Button
                    sx={ {
                        padding: '12px 17px',
                        ':first-letter ': {
                            textTransform: 'capitalize',
                        },
                    } }
                    variant='contained'
                    color='primary'
                    disableElevation
                    onClick={ handleClick }
                >
                    { isReadLabelButton }
                </Button>
            </DialogContent>

            { openPreview && (
                <>
                    <StyledDialog
                        open={ openPreview }
                        onClose={ handleCloseImagePreview }
                    >
                        { (extension === 'jpg' || extension === 'png') &&
                            <>
                                <CardMedia
                                    sx={ { overflow: 'hidden' } }
                                    component="img"
                                    image={ imgUrl }
                                    alt="media associated to news" />
                                <IconButton
                                    aria-label='close'
                                    onClick={ handleCloseImagePreview }
                                    sx={ {
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    } }
                                >
                                    <CloseIcon />
                                </IconButton>
                            </> }
                        {
                            extension === 'pdf' &&
                            <embed style={ { height: 600, width: 800 } } src={ imgUrl } />
                        }
                    </StyledDialog>
                </>
            ) }
        </Dialog>
    );
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    border: `solid 1.8px ${ theme.palette.grey[200] }`,
    borderRadius: 3,
}));

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': { width: 'auto', maxWidth: 800 },
}));

export default NewsPreview;
