import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { TranslatableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/TranslatableComponent';
import { OrbitControlComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Controls/OrbitControlComponent';
import { YControlableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/YControlableComponent';
import { Object3D, Vector3 } from 'three';
import { RotControlableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/RotControlableComponent';
import { SnappableComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SnappableComponent';
import { AutoReorientationComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/AutoReorientationComponent';
import { BoundingBoxComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/BoundingBoxComponent';
import { SupportComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/SupportComponent';
import { Engine } from '@lutithree/build/Engine';
import { RenderMode } from "@lutithree/build/Modules/WebGL/Rendering/RenderingStrategies/RenderMode";
import BoundingBoxChangedEvent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/RelativePositioning/Events/BoundingBoxChangedEvent";
import IControlsCallbacks
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Features3D/IControlsCallbacks";
import {
    IViewModeController
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Cameras/IViewModeController";
import {
    INavigationController
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Features3D/INavigationController";
import EntityStopMovingEvent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Events/EntityStopMovingEvent";
import {
    ViewMode
} from "../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Cameras/ViewMode";

export default class ControlsCallbacks implements IControlsCallbacks {
    private m_engine: Engine;

    private m_pointOfView: IViewModeController;

    private readonly m_draggingChangeCallback: (value: boolean, entity: SceneEntity) => void;

    private readonly m_draggingChangeRotCallback: (value: boolean, entity: SceneEntity) => void;

    private readonly m_draggingChangeTransCallback: (value: boolean, entity: SceneEntity) => void;

    private readonly m_rotationChangeCallback: (entity: SceneEntity) => void;

    private readonly m_translationChangeCallback: (entity: SceneEntity) => void;

    public constructor(p_engine: Engine, p_pointOfView: IViewModeController, p_navigationController: INavigationController) {
        if (p_engine == null) throw new Error('NullReferenceException : p_engine is null or undefined');
        if (p_pointOfView == null) throw new Error('NullReferenceException : p_pointOfView is null or undefined');

        this.m_engine = p_engine;
        this.m_pointOfView = p_pointOfView;

        this.m_draggingChangeCallback = (value: boolean, entity: SceneEntity) => {
            this.m_engine.Modules.Rendering.OverrideRenderMode(value ? RenderMode.Default : null);
            if (!value) this.m_engine.Modules.LoopStrategy.RequestRender(false);
            
            p_navigationController.EnableNavigation(!value);
            this.m_engine.Modules.Scene.GetComponents(OrbitControlComponent, { entity: false, component: false }).forEach((orbit) => {
                orbit.Enable(!value);
            });
            this.m_engine.Modules.EventManager.Publish(BoundingBoxChangedEvent, new BoundingBoxChangedEvent(entity));
            if(!value)
                this.m_engine.Modules.Systems.SelectionSystem.GetSelectedEntities().forEach((obj)=>{
                    if(obj.HasComponentOfType(TranslatableComponent))
                        obj.GetComponentOfType(TranslatableComponent).Enable(true);
                });
        };

        this.m_draggingChangeRotCallback = (value: boolean, entity: SceneEntity) => {
            if (!value) {
                this.m_engine.Modules.EventManager.Publish(EntityStopMovingEvent, new EntityStopMovingEvent(entity));
            }
            this.m_draggingChangeCallback(value, entity);
            if (entity.HasComponentOfType(YControlableComponent, false)) {
                let viewMode: ViewMode | undefined = this.m_pointOfView.CurrentViewMode;
                let axisTranslatable = entity.GetComponentOfType(YControlableComponent);
                let enable = axisTranslatable.Axis.equals(Object3D.DEFAULT_UP) && viewMode !== ViewMode.ThirdPerson ? false : true;
                axisTranslatable.Enable(!value && enable);
            }
        };

        this.m_draggingChangeTransCallback = (value: boolean, entity: SceneEntity) => {
            if (!value) {
                this.m_engine.Modules.EventManager.Publish(EntityStopMovingEvent, new EntityStopMovingEvent(entity));
            }
            this.m_draggingChangeCallback(value, entity);
            if (entity.HasComponentOfType(RotControlableComponent, false)) {
                let snappable = entity.HasComponentOfType(SnappableComponent)?entity.GetComponentOfType(SnappableComponent) : undefined;
                let reorientatable = entity.HasComponentOfType(SnappableComponent)?entity.GetComponentOfType(SnappableComponent) : undefined;
                if(!(reorientatable && snappable && snappable.Snapped.length > 0)){
                    let rotatableComponent = entity.GetComponentOfType(RotControlableComponent);
                    rotatableComponent.Enable(!value);
                }
            }
        };

        this.m_rotationChangeCallback = (entity: SceneEntity) => {
            this.m_engine.Modules.LoopStrategy.RequestRender(true);
            if (entity.HasComponentOfType(SnappableComponent)) {
                let axis = new Vector3(0, 0, 1);
                let snapped = entity.GetComponentOfType(SnappableComponent).Snapped;
                if (snapped.length > 0) {
                    if (entity.HasComponentOfType(AutoReorientationComponent)) return;
                    axis = snapped[0].Plane.normal.clone().negate();
                }
                this.m_engine.Modules.Systems.SnapSystem.SnapOnAxis(entity, axis, axis.clone().cross(new Vector3(0, 1, 0)), 45, 10);
            }
            this.m_engine.Modules.EventManager.Publish(BoundingBoxChangedEvent, new BoundingBoxChangedEvent(entity));
        };

        this.m_translationChangeCallback = (entity: SceneEntity) => {
            this.m_engine.Modules.LoopStrategy.RequestRender(true);
            if (entity.HasComponentOfType(BoundingBoxComponent)) {
                let OBB = entity.GetComponentOfType(BoundingBoxComponent);
                let vector = new Vector3().addVectors(OBB.Center, OBB.GetUpOriented(true));
                if (vector.y < 0) {
                    entity.Transform.AddToPosition(new Vector3(0, Math.abs(vector.y), 0));
                }
            }
            if (entity.HasComponentOfType(SnappableComponent)) 
                this.m_engine.Modules.Systems.SnapSystem.SnapToGround(entity, this.m_engine.Modules.Scene.GetComponents(SupportComponent), 0.3);
            this.m_engine.Modules.EventManager.Publish(BoundingBoxChangedEvent, new BoundingBoxChangedEvent(entity));
        };
    }

    public get DraggingChangeCallback(): (value: boolean, entity: SceneEntity) => void {
        return this.m_draggingChangeCallback;
    }

    public get DraggingChangeRotCallback(): (value: boolean, entity: SceneEntity) => void {
        return this.m_draggingChangeRotCallback;
    }

    public get DraggingChangeTransCallback(): (value: boolean, entity: SceneEntity) => void {
        return this.m_draggingChangeTransCallback;
    }

    public get RotationChangeCallback(): (entity: SceneEntity) => void {
        return this.m_rotationChangeCallback;
    }

    public get TranslationChangeCallback(): (entity: SceneEntity) => void {
        return this.m_translationChangeCallback;
    }
}
