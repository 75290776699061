import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import { Vector3 } from 'three';
import { MeshLinksComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshLinksComponent';
import { MeshRendererComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshRendererComponent';
import { MeshFilterComponent } from '@lutithree/build/Modules/WebGL/Scene/Components/Mesh/MeshFilterComponent';
import { MeshUtils } from '@lutithree/build/Modules/WebGL/Utils/MeshUtils';
import { RaycastableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Behaviors/RaycastableComponent";
import {
    IObjectDecorator
} from "../../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/IObjectDecorator";
import BasicObject
    from "../../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/BasicObject";
import Instance3D
    from "../../../../../../../../application3D-common/Librairies/Studios/Application3D/Domain/Objects/AssetAssembly/Instance3D";

export class CeilingDecorator implements IObjectDecorator {

    public DecorateRootObject(p_entity: SceneEntity, p_object: BasicObject, p_instance: Instance3D | undefined): void {
    }

    public DecorateObject(p_entity: SceneEntity, p_object: BasicObject, p_instance: Instance3D | undefined): void {
    }

    public DecoratePart(p_entity: SceneEntity, p_parentObject: BasicObject, p_parentEntity:SceneEntity, p_refOfPart: string): void {
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        if (p_parentObject == null) throw new Error('NullReferenceException : p_parentObject is null or undefined');
        if (p_parentEntity == null) throw new Error('NullReferenceException : p_parentEntity is null or undefined');
        if (!p_refOfPart) throw new Error('NullReferenceException : p_refOfPart is null or undefined');

        if((p_parentObject.Composition.Type === 'Asset3D') || p_parentObject.Assets.length>0){
            p_entity.AddComponentOfType(RaycastableComponent);
        
            let wallForward: Vector3 = new Vector3();
            p_parentEntity.Transform.UpdateMatrixWorldInChildren();
            p_parentEntity.Transform.GetObject().getWorldDirection(wallForward);

            let onGeomertyRescaled = () => this.OnGeometryRescaled(p_entity);

            let meshLinkComponent = p_entity.AddComponentOfType(MeshLinksComponent, wallForward.clone().negate());
            meshLinkComponent.AddOnGeometryScaledCallback('rescaleUVFeature', onGeomertyRescaled);

            if (p_entity.HasComponentOfType(MeshRendererComponent)) {
                let meshRenderer = p_entity.GetComponentOfType(MeshRendererComponent);
                meshRenderer.CastShadows = false;
            }
        }
    }

    private OnGeometryRescaled(p_partEntity: SceneEntity): void {
        if (p_partEntity.HasComponentOfType(MeshFilterComponent)) {
            let meshFilter = p_partEntity.GetComponentOfType(MeshFilterComponent);
            meshFilter.GetMeshes().forEach((mesh) => {
                MeshUtils.RefreshUV_local(mesh);
            });
        }
    }
}
