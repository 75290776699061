import MdfApiResponse from '../../../../domain/common/generic';
import { PartItemTree, ProductRuleResponse, Rule, RuleSetOnProduct, RuleType } from '../../../../domain/domain';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import rulesService from '../../../../api/service/rules/rules.service';

export type ProductRulesState = {
    isLoading: false;
    payload: MdfApiResponse<ProductRuleResponse>;
};
const initialProductRulesState: ProductRulesState = {
    isLoading: false,
    payload: {
        content: {
            ruleSet: {
                id: 0,
                version: 0,
                createdAt: new Date(),
                updatedAt: new Date(),
                productRef: '',
                rules: { rules: [] },
            },
            parts: [],
            model3DCellValues: [],
            valueAtomNameByAtomValueRef: {},
        },
        errors: [],
        warnings: [],
    },
};

export type SwitchRulesState = {
    isLoading: false;
    payload: MdfApiResponse<RuleSetOnProduct>;
};

const initialSwitchRulesRulesState: SwitchRulesState = {
    isLoading: false,
    payload: {
        errors: [],
        warnings: [],
        content: {
            id: 0,
            rules: { rules: [] },
            productRef: '',
            version: 0,
            createdAt: new Date(),
            updatedAt: new Date(),
        },
    },
};

//<editor-fold desc="get-Rules">
export const getProductRules = createAsyncThunk('get/Rules', async (productRef: string) => {
    const res = await rulesService.getRules(productRef);
    return res;
});

export const getProductRulesSlice = createSlice({
    name: 'getProductRules',
    initialState: initialProductRulesState,
    reducers: {
        resetGetRulesPart: (state) => {
            state = initialProductRulesState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProductRules.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getProductRules.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(getProductRules.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetGetRulesPart } = getProductRulesSlice.actions;
//</editor-fold>

//<editor-fold desc="get-Rules">
export const switchConfRulesType = createAsyncThunk('switch/RulesType', async (data: { productRef: string; newRuleType: RuleType }) => {
    const res = await rulesService.switchConfRuleType(data.productRef, data.newRuleType);
    return res;
});

export const SwitchConfRulesTypeSlice = createSlice({
    name: 'switchConfRulesType',
    initialState: initialSwitchRulesRulesState,
    reducers: {
        resetSwitchConfRulesType: (state) => {
            state = initialSwitchRulesRulesState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(switchConfRulesType.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(switchConfRulesType.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(switchConfRulesType.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetSwitchConfRulesType } = SwitchConfRulesTypeSlice.actions;
//</editor-fold>

//<editor-fold desc="create-Rules">
export const createProductRules = createAsyncThunk('create/Rules', async (data: { productRef: string; rule: Rule }) => {
    const res = await rulesService.createRule(data.productRef, data.rule);
    return res;
});

export const createProductRulesSlice = createSlice({
    name: 'createProductRules',
    initialState: initialProductRulesState,
    reducers: {
        resetCreateRules: (state) => {
            state = initialProductRulesState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createProductRules.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createProductRules.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(createProductRules.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetCreateRules } = createProductRulesSlice.actions;
//</editor-fold>

//<editor-fold desc="delete-Rules">
export const deleteProductRules = createAsyncThunk('delete/Rules', async (data: { productRef: string; ruleRef: string }) => {
    const res = await rulesService.deleteRule(data.productRef, data.ruleRef);
    return res;
});

export const deleteProductRulesSlice = createSlice({
    name: 'deleteProductRules',
    initialState: initialProductRulesState,
    reducers: {
        resetDeleteProductRules: (state) => {
            state = initialProductRulesState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteProductRules.pending, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteProductRules.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(deleteProductRules.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetDeleteProductRules } = deleteProductRulesSlice.actions;
//</editor-fold>

//<editor-fold desc="execute-Rules">
export type ExecuteRuleState = {
    isLoading: boolean;
    payload: MdfApiResponse<PartItemTree[]>;
};
const initialExecuteRuleState: ExecuteRuleState = {
    isLoading: false,
    payload: {
        content: [],
        errors: [],
        warnings: [],
    },
};

export const executeRules = createAsyncThunk('post/executeRules', async (data: { productReference: string; configuration: string[] }) => {
    const res = await rulesService.executeRules(data.productReference, data.configuration);
    return res;
});

export const executeRulesSlice = createSlice({
    name: 'executeRulesSlice',
    initialState: initialExecuteRuleState,
    reducers: {
        resetExecuteRules: (state) => {
            state = initialExecuteRuleState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(executeRules.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(executeRules.fulfilled, (state, { payload }) => {
            state = { payload, isLoading: false };
            return state;
        });
        builder.addCase(executeRules.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export const { resetExecuteRules } = executeRulesSlice.actions;
//</editor-fold>