import { IHandler } from "@lutithree/build/Modules/Core/Event/IHandler";
import { EntityRaycastedEvent } from "@lutithree/build/Modules/WebGL/Scene/Events/EntityRaycastedEvent";
import ARoomStudioHandler from "../../../ARoomStudioHandler";
import {Engine} from "@lutithree/build/Engine";
import RoomStudioServices from "../../RoomStudioServices";
import ObjectSystem
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Composition/ObjectSystem";
import PartComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/PartComponent";
import InfoComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/InfoComponent";



export default class ObjectRaycastedHandler extends ARoomStudioHandler implements IHandler<EntityRaycastedEvent> {
    
    private m_objectSystem: ObjectSystem;

    public constructor(p_engine: Engine, p_services: RoomStudioServices) {
        super(p_engine, p_services);
        this.m_objectSystem = new ObjectSystem(p_engine.Modules.Scene);
    }
    
    public Handle(p_event: EntityRaycastedEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined or empty in EntityRaycastedEvent Handle');
        
        let entity = p_event.Entity;
        
        if (entity.HasComponentOfType(PartComponent)) {
            let partComponent = entity.GetComponentOfType(PartComponent);
            let parentEntity = this.m_objectSystem.GetObjectEntity(partComponent.ParentObjectRef);
            let infoComponent = parentEntity?.HasComponentOfType(InfoComponent)? parentEntity?.GetComponentOfType(InfoComponent):undefined;
            if(infoComponent && (infoComponent.Info.Type === "Ground" || infoComponent.Info.Type === "Ceiling")) {
                this.m_services.Notifier.NotifyNeutralObjectRaycasted();
            }
        }
        
    }
}
