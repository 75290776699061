import AState from "../../../../FSM/AState";

export default class ScreenshotBehaviours extends AState {
    public Enter(): void {
        console.log('ScreenshotBehaviours Enter!');
    }

    public Exit(): void {
        console.log('ScreenshotBehaviours Exit!');
    }
}
