import * as React from 'react';
import { useEffect, useState } from 'react';
import { News } from '../../../domain/domain';
import { Paper, Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import NewsChip from '../NewsChip';


type DisplayProps = {
    isRead: (newsId: number) => boolean;
    setPreview: (preview: News | undefined) => void;
    data: News;
};

const NewsCard = ({ isRead, setPreview, data }: DisplayProps) => {
    const [isCritical, setIsCritical] = useState<boolean>(false);

    useEffect(() => {
        if (data.criticality === 'CRITIQUE') {
            setIsCritical(true);
        } else {
            setIsCritical(false);
        }
    });

    return (
        <Paper elevation={ 3 }
               sx={ {
                   minHeight: 120,
                   cursor: 'pointer',
                   padding: '12px 16px',
                   borderLeft: (theme) => isCritical ? `9px solid ${ theme.palette.error.main }` : '',
                   background: isRead(data.id) ? '#F2F4F5' : 'white',
               } }
               onClick={ () => {
                   setPreview(data);
               } }>
            <Box sx={ (theme) => ({
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                [theme.breakpoints.down('md')]: {
                    display: 'block',
                },
            }) }>
                <Box sx={ (theme) => ({
                    maxWidth: '75%',
                    [theme.breakpoints.down('md')]: {
                        maxWidth: '100%',
                    },
                }) }>
                    <Box sx={ { mb: 1 } }>
                        <NewsChip newsCategoryId={ data.newsCategoryId } />
                    </Box>
                    <Typography sx={ (theme) => ({
                        ':first-letter ': {
                            textTransform: 'capitalize',
                        },
                        pl: 1.5,
                        fontSize: 22,
                        fontWeight: isRead(data.id) ? 400 : 500,
                        color: isRead(data.id) ? theme.palette.grey[700] : theme.palette.primary.main,
                    }) }
                                variant='h4'
                    >
                        { data.title }
                    </Typography>
                    <Typography
                        variant='body2'
                        sx={ {
                            pl: 1.5,
                            maxHeight: 83,
                            overflow: 'hidden',
                            color: '#889AAB',
                        } } dangerouslySetInnerHTML={ { __html: data.content } }
                    />
                </Box>

                <Box
                    sx={ { minWidth: 193 } }
                    display='flex' justifyContent='flex-end'
                >
                    { isRead(data.id)
                        ? <>
                            <Typography sx={ {
                                mr: 0.8,
                                color: (theme) => theme.palette.grey[700],
                            } }
                            >News lue</Typography>
                            <CheckIcon sx={ {
                                color: (theme) => theme.palette.grey[700],
                            } } />
                        </>
                        : <>
                            <Typography sx={ { mr: 1 } } color='error'>Confirmez la lecture</Typography>
                            <WarningIcon color='error' />
                        </>
                    }
                </Box>
            </Box>
        </Paper>
    );
};

export default NewsCard;
