import { AHandler } from '@lutithree/build/Handlers/AHandler';
import { Engine } from '@lutithree/build/Engine';
import RoomStudioServices from "./GameLogic/RoomStudioServices";

export default abstract class ARoomStudioHandler extends AHandler {
    protected m_services: RoomStudioServices;
    
    public constructor(p_engine: Engine, p_services: RoomStudioServices) {
        if (p_services == null) throw new Error('NullReferenceException : p_services is null or undefined');
        super(p_engine);
        
        this.m_services = p_services;
    }
}
