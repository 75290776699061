import { DisableComponent } from "@lutithree/build/Modules/Core/Entity/DisableComponent";
import { IDisableComponent } from "@lutithree/build/Modules/Core/Entity/IDisableComponent";
import { Object3D } from "three";

export class ConnectorsComponent extends DisableComponent implements IDisableComponent {

    private m_refOfModel: string;
    
    private m_locationByRole: Map<string, Object3D>;

    private m_availabilityByRole: Map<string, boolean>;
    
    private m_onLocationsUpdate? : ()=>void;

    public constructor(p_ref: string, p_locationByRole: Map<string,Object3D>) {
        super(true);
        if (p_locationByRole == null) throw new Error('NullReferenceException : p_locationByRole is null or undefined');
        if (!p_ref) throw new Error('NullReferenceException : p_ref is null or undefined or empty');

        this.m_refOfModel = p_ref;
        this.m_locationByRole = p_locationByRole;
        this.m_availabilityByRole = new Map<string, boolean>();
    }

    public get RefOfModel(): string {
        return this.m_refOfModel;
    }

    public set RefOfModel(p_ref:string) {
        if (!p_ref) throw new Error('NullReferenceException : p_ref is null or undefined or empty');
        this.m_refOfModel = p_ref;
    }
    
    public GetConnectorLocation(p_role: string): Object3D|undefined {
        if (!p_role) throw new Error('NullReferenceException : p_role is null or undefined or empty');
        return this.m_locationByRole.get(p_role);
    }
    
    public SetAvailability(p_role: string, p_isAvailable: boolean): void{
        if (!p_role) throw new Error('NullReferenceException : p_role is null or undefined or empty');
        if (p_isAvailable == null) throw new Error('NullReferenceException : p_isAvailable is null or undefined ');
        
        this.m_availabilityByRole.set(p_role,p_isAvailable);
    }
    
    public get LocationsByRole(): Map<string,  Object3D> {
        return this.m_locationByRole;
    }

    public get AvailabilityByRole(): Map<string,  boolean> {
        return this.m_availabilityByRole;
    }


    public set LocationsByRole(p_locationByRole: Map<string,  Object3D>) {
        if (p_locationByRole == null) throw new Error('NullReferenceException : p_locationByRole is null or undefined');

        this.m_locationByRole = p_locationByRole;
        if(this.m_onLocationsUpdate) this.m_onLocationsUpdate();
    }

    public SetOnLocationsUpdate(p_onLocationsUpdate? : ()=>void): void {
        if (p_onLocationsUpdate === null) throw new Error('NullReferenceException : p_onLocationsUpdate is null');

        this.m_onLocationsUpdate = p_onLocationsUpdate;
    }
}