const GET_ALL_SECTION = `v1/sections/`;
const GET_ALL_SECTION_BY_CATALOG_ID = (catalogId: number) => `v1/sections/catalog/${ catalogId }`;
const GET_SECTION_BY_ID = (sectionId: number) => `v1/sections/${ sectionId }`;
const CREATE_SECTION = (catalogId: number) => `v1/sections/catalog/${ catalogId }`;
const UPDATE_SECTION = (sectionId: number) => `v1/sections/${ sectionId }`;
const DELETE_SECTION = (sectionId: number) => `v1/sections/${ sectionId }`;


export {
    GET_ALL_SECTION,
    GET_ALL_SECTION_BY_CATALOG_ID,
    GET_SECTION_BY_ID,
    CREATE_SECTION,
    UPDATE_SECTION,
    DELETE_SECTION,
};
