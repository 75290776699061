import { IHandler } from '@lutithree/build/Modules/Core/Event/IHandler';
import ObjectScaledEvent from '../Events/ObjectScaledEvent';
import PartScaledEvent from "../Events/PartScaledEvent";
import { TransformControlComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/Controls/TransformControlComponent";
import LSTransformControls from "@lutithree/build/Librairies/LSCustomControls/LSTransformControls";
import ARoomStudioHandler from "../../../ARoomStudioHandler";
import EntityScaledEvent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Features3D/TransformControls/Events/EntityScaledEvent";
import ObjectComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/ObjectComponent";
import InfoComponent
    from "../../../../../../../application3D-common/Librairies/Studios/Application3D/GameLogic/Objects/Components/InfoComponent";

export class ObjectScaledHandler extends ARoomStudioHandler implements IHandler<EntityScaledEvent> {
    public Handle(p_event: EntityScaledEvent): void {
        if (p_event == null) throw new Error('NullReferenceException : p_event is null or undefined');
       
        let infoComponent = p_event.Entity.HasComponentOfType(InfoComponent)? p_event.Entity.GetComponentOfType(InfoComponent):undefined;
        let objectComponent = p_event.Entity.HasComponentOfType(ObjectComponent) ? p_event.Entity.GetComponentOfType(ObjectComponent) : undefined;
        if(infoComponent && objectComponent) {
            if(infoComponent.Info.Type === 'Opening') {
                this.m_services.ObjectBehaviours.Openings.OnRescale(p_event.Entity, objectComponent);
            }
    
            this.m_engine.Modules.EventManager.Publish(ObjectScaledEvent, new ObjectScaledEvent(p_event.Entity, objectComponent));
            
            let isEntityTheScalableTarget = !(p_event.ScaledTargets.length > 0 && p_event.ScaledTargets[0].Id !== p_event.Entity.Id);
            if(!isEntityTheScalableTarget){
                objectComponent.PartIds.forEach((entityId, refOfPart)=>{
                    if(entityId === p_event.ScaledTargets[0].Id) {
                        this.m_engine.Modules.EventManager.Publish(
                            PartScaledEvent, 
                            new PartScaledEvent(p_event.Entity, objectComponent!, p_event.ScaledTargets[0], refOfPart));
                    }
                });
            }
        }
        
        this.m_engine.Modules.Scene.GetComponents(TransformControlComponent).forEach((obj) => {
            if (obj.GetObject() instanceof LSTransformControls)
                (obj.GetObject() as LSTransformControls).updateGyzmosSize();
        });
    }
}
