import React, { PropsWithChildren } from 'react';
import DialogActions from "@mui/material/DialogActions";


type DialogLayoutProps = PropsWithChildren<{}>;

const DialogAction = ({ children }: DialogLayoutProps) => {
    return (
        <DialogActions
            sx={ { display: 'flex', justifyContent: 'space-between' } }
        >
            { children }
        </DialogActions>
    );
};

export default DialogAction;