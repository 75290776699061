import { Guid } from 'guid-typescript';
import { AComponent } from '@lutithree/build/Modules/Core/Entity/AComponent';
import { IComponent } from '@lutithree/build/Modules/Core/Entity/IComponent';
import { SceneEntity } from '@lutithree/build/Modules/WebGL/Scene/SceneEntity';
import {Vector3} from "three";

export default class ObjectComponent extends AComponent implements IComponent {
    protected m_partEntities: Map<string, Guid>;

    protected m_ref: string;
    
    public constructor(p_ref: string) {
        super();
        if (!p_ref) throw new Error('NullReferenceException : p_ref is null or undefined or empty');
        
        this.m_partEntities = new Map<string, Guid>();
        this.m_ref = p_ref;
    }

    public get PartIds(): ReadonlyMap<string, Guid> {
        return this.m_partEntities;
    }

    public get Ref(): string {
        return this.m_ref;
    }

    public AddPartEntity(p_refOfPart: string, p_entity: SceneEntity) {
        if (!p_refOfPart) throw new Error('NullReferenceException : p_refOfPart is null or undefined or empty');
        if (p_entity == null) throw new Error('NullReferenceException : p_entity is null or undefined');
        this.m_partEntities.set(p_refOfPart, p_entity.Id);
    }

    public RemovePart(p_refOfPart: string) {
        if (!p_refOfPart) throw new Error('NullReferenceException : p_refOfPart is null or undefined or empty');
        this.m_partEntities.delete(p_refOfPart);
    }

    public GetEntityIdOfPart(p_refOfPart: string): Guid | undefined {
        if (!p_refOfPart) throw new Error('NullReferenceException : p_refOfPart is null or undefined or empty');
        if (this.m_partEntities.has(p_refOfPart)) {
            return this.m_partEntities.get(p_refOfPart)!;
        }
        return undefined;
    }
}
