import * as React from 'react';
import { Box, Paper, styled, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TouchIt from '../../../../asset/help-modal-touch-it.svg';
import RollUpTable from '../../../../asset/help-modal-rollup-table.svg';
import setTable from '../../../../asset/help-modal-setable.svg';
import playerTablet from '../../../../asset/player-tablet.svg';
import DoNotTouch from '../../../../asset/help-dialog-pas-touche.svg';


type ClosingElementProps = {
    onClose?: () => void;
};

function ClosingElement({ onClose }: ClosingElementProps) {
    return (
        <>
            { onClose ? (
                <>
                    <IconButton
                        aria-label='close'
                        onClick={ onClose }
                        sx={ {
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        } }
                    >
                        <CloseIcon />
                    </IconButton>
                </>
            ) : null }
        </>
    );
}

type DialogHelpMode = {
    onClose: () => void;
    open: boolean;
    showingMode: string;
};

const DialogHelpMode = ({ onClose, open, showingMode }: DialogHelpMode) => {

    if (!open) {
        return null;
    }
    return (
        <Box sx={ {
            position: 'fixed', right: '1%', bottom: '2%',
        } }>
            <CustomPaper elevation={ 3 }>
                <ClosingElement onClose={ onClose } />

                { showingMode === 'selected' && (
                    <>
                        <BoxInCoupleWithImg>
                            <img style={ { marginRight: 12 } } src={ setTable } alt='aide logo mesure' />
                            <Typography sx={ { fontSize: 14 } } color='primary' variant='body2'>Sélectionnez plusieurs
                                objets de
                                votre pièce</Typography>
                        </BoxInCoupleWithImg>

                        <Box sx={ { mt: 3 } }>
                            <BoxInCoupleWithImg>
                                <img style={ { marginRight: 12 } } src={ RollUpTable } alt='aide logo mesure' />
                                <Typography sx={ { fontSize: 14 } } color='primary' variant='body2'>Déplacez ou
                                    réorientez
                                    l'ensemble des objets</Typography>
                            </BoxInCoupleWithImg>
                        </Box>
                    </>
                ) }
                { showingMode === 'measure' && (
                    <Box sx={ { mt: 1 } }>
                        <BoxInCoupleWithImg>
                            <img style={ { marginRight: 12 } } src={ TouchIt } alt='deux pointsdifférents' />
                            <Typography sx={ { fontSize: 14 } } color='primary' variant='body2'
                            >
                                Sélectionnez deux points différents pour prendre une mesure</Typography>
                        </BoxInCoupleWithImg>
                    </Box>
                ) }

                { showingMode === 'tablet' && (
                    <>
                        <BoxInCoupleWithImg>
                            <img style={ { marginRight: 12 } } src={ playerTablet } alt='visualiser votre pièce' />
                            <Typography sx={ { fontSize: 14 } } color='primary' variant='body2'>Déplacez votre écran
                                pour visualiser votre pièce</Typography>
                        </BoxInCoupleWithImg>

                        <Box sx={ { mt: 3 } }>
                            <BoxInCoupleWithImg>
                                <img style={ { marginRight: 12 } } src={ DoNotTouch }
                                     alt='Déplacez vous dans  la pièce' />
                                <Typography sx={ { fontSize: 14 } } color='primary' variant='body2'>Déplacez vous dans
                                    la pièce en appuyant deux fois rapidement sur l'écran</Typography>
                            </BoxInCoupleWithImg>
                        </Box>
                    </>
                ) }
            </CustomPaper>
        </Box>
    );
};

const CustomPaper = styled(Paper)(() => ({
    position: 'relative',
    width: 300,
    padding: '25px',
}));

const BoxInCoupleWithImg = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}));

export default DialogHelpMode;