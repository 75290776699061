import {Vector3} from "three";

export default class DimensionsInfo {
    private m_fromLength : Vector3;
    
    private m_toLength : Vector3;
    
    private m_fromDepth : Vector3;
    
    private m_toDepth : Vector3;
    
    private m_fromHeight : Vector3;
    
    private m_toHeight : Vector3;
    
    public constructor(p_lengthInfo: {p_from: Vector3, p_to: Vector3},
                       p_depthInfo: {p_from: Vector3, p_to: Vector3},
                       p_heightInfo: {p_from: Vector3, p_to: Vector3}) {
        this.m_fromLength = p_lengthInfo.p_from;
        this.m_toLength = p_lengthInfo.p_to;
        this.m_fromDepth = p_depthInfo.p_from;
        this.m_toDepth = p_depthInfo.p_to;
        this.m_fromHeight = p_heightInfo.p_from;
        this.m_toHeight = p_heightInfo.p_to;
    }
    
    public get Length(): {p_from: Vector3, p_to: Vector3} {
        return {p_from: this.m_fromLength, p_to: this.m_toLength};
    }
    
    public get Depth(): {p_from: Vector3, p_to: Vector3} {
        return {p_from: this.m_fromDepth, p_to: this.m_toDepth};
    }
    
    public get Height(): {p_from: Vector3, p_to: Vector3} {
        return {p_from: this.m_fromHeight, p_to: this.m_toHeight};
    }
}