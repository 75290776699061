import { DisableComponent } from "@lutithree/build/Modules/Core/Entity/DisableComponent";
import { IDisposableComponent } from "@lutithree/build/Modules/WebGL/Scene/Components/IDisposableComponent";
import { Group, Object3D, Quaternion, Vector3 } from "three";

export class TemporaryNodeComponent extends DisableComponent implements IDisposableComponent {
    
    private m_lastParents: Map<Object3D, Object3D>;
    
    private m_objectGroup: Group;
    
    public constructor(){
        super();
        
        this.m_lastParents = new Map<Object3D, Object3D>();
        this.m_objectGroup = new Group();
    }

    public GetChildNumber(): number {
        return this.m_lastParents.size;
    }

    public HasChild(p_object:Object3D): boolean {
        return this.m_lastParents.has(p_object);
    }
    
    public AddChild(p_object:Object3D|Object3D[]): void {
        if (p_object == null) throw new Error('NullReferenceException : p_object is null or undefined');

        if(Array.isArray(p_object)){
            p_object.forEach((object)=>{
                if(object.parent) {
                    this.m_lastParents.set(object, object.parent);
                    this.m_objectGroup.attach(object);
                }
            });
        }
        else{
            if(p_object.parent) {
                this.m_lastParents.set(p_object, p_object.parent);
                this.m_objectGroup.attach(p_object);
            }
        }
    }
    
    public RemoveChild(p_object:Object3D): void{
        if (p_object == null) throw new Error('NullReferenceException : p_object is null or undefined');
        
        let parent = this.m_lastParents.get(p_object);
        if(parent) parent.attach(p_object);
        this.m_lastParents.delete(p_object);
    }

    public AttachChildren2Parents(): void {
        this.m_lastParents.forEach((parent,object)=>{
            parent.attach(object);
        });
    }

    public AttachChildren2Node(): void {
        this.m_lastParents.forEach((parent,object)=>{
            this.m_objectGroup.attach(object);
        });
    }
    
    public ClearNode(): void {
        this.AttachChildren2Parents();
        this.m_lastParents.clear();
    }

    public GetObject() {
        return this.m_objectGroup;
    }
    
    public GetDisposable(): (Object3D)[] {
        this.ClearNode();
        
        return [this.m_objectGroup];
    }
}