import React, { useEffect, useState } from "react";
import dateFormatter from "../../../utils/date-formatter";
import { useDispatch } from "react-redux";
import { updateProjectName } from "../../../services/redux/reducers/projects/project.slice";
import { numToMonth } from "../../../pages/rooms/monthArray";
import "./room-car-container.scss";
import { Box, styled, TextField as MuiTextField, Typography } from "@mui/material";
import ButtonEdit from "../../ButtonMui/ButtonEdit/ButtonEdit";
import SaveCancelButton from "../../../business/ButtonAction/SaveCancelButton";


type RoomCardContProps = {
    children: React.ReactNode;
    title: string | undefined;
    updatedDate: Date | undefined;
    code: string | undefined;
    projectId: number | undefined;
};

const RoomCardContainer = ({ children, title, updatedDate, code, projectId }: RoomCardContProps) => {
    const dispatch = useDispatch();

    const dateFormat = dateFormatter.dateTimeStampToDateTime(updatedDate);
    const [updateInput, setUpdateInput] = useState(false);
    const [roomName, setRoomName] = useState<string>("");
    const [initialRoomName, setInitialRoomName] = useState("");
    const split = dateFormat.split("/");
    const monthNum = split[1];
    const monthString = numToMonth(monthNum);
    const splice = split.splice(1, 1, monthString as string);
    const maxLengthInputRoomCardTitle = 24;

    useEffect(() => {
        if (title) {
            setRoomName(title);
            setInitialRoomName(title);
        }
    }, [title]);

    const handleEditTitle = () => {
        setUpdateInput(!updateInput);
    };

    const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRoomName(event.target.value);
    };

    const handleNothingChangeTitle = () => {
        setRoomName(initialRoomName);
        handleEditTitle();
    };

    const onSubmitRoomTitle = () => {
        if (roomName === initialRoomName || roomName === "" || roomName === null) {
            handleEditTitle();
        } else {
            dispatch(
                updateProjectName({
                    projectId: projectId,
                    projectName: roomName
                })
            );
            setInitialRoomName(roomName);
            handleEditTitle();
        }
    };

    return (
        <Box>
            <Box
                sx={ {
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between"
                } }>
                { updateInput ? (
                    <>
                        <BoxTextField>
                            <Box>
                                <StyledInput variant='outlined'
                                             onChange={ (e: React.ChangeEvent<HTMLInputElement>) => handleChangeTextField(e) }
                                             name='edit-room-name'
                                             value={ roomName }
                                             inputProps={ { maxLength: maxLengthInputRoomCardTitle } }
                                />
                                <TypoLength
                                    variant='overline' display='block' gutterBottom>
                                    { roomName.length }/{ maxLengthInputRoomCardTitle }
                                </TypoLength>
                            </Box>
                            <BoxResponsive>
                                <SaveCancelButton
                                    disabled={ roomName.length === 0 }
                                    onSubmit={ onSubmitRoomTitle }
                                    onClickCancel={ handleNothingChangeTitle }
                                />
                            </BoxResponsive>
                        </BoxTextField>
                    </>
                ) : (
                    <>
                        <BoxTextField>
                            <BoxWidthHead>
                                <Typography
                                    variant='h3'
                                    color='primary'>{ title }</Typography>
                            </BoxWidthHead>
                            <BoxResponsive>
                                <ButtonEdit onClick={ handleEditTitle } />
                            </BoxResponsive>
                        </BoxTextField></>) }
                <Box sx={ {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                } }>
                    <Typography
                        sx={ {
                            fontSize: 14,
                            letterSpacing: "1.2px",
                            fontWeight: 500,
                            color: (theme) => theme.palette.grey[500],
                            textTransform: "uppercase"
                        } }
                    >code projet</Typography>
                    <Typography
                        sx={ {
                            fontSize: 26,
                            letterSpacing: 3,
                            fontWeight: 500,
                            color: (theme) => theme.palette.grey[500]
                        } }
                    >{ code }</Typography>
                </Box>
            </Box>

            <Typography variant='h4' color='primary'>{ split.join().replaceAll(",", " ") }</Typography>
            <Typography sx={ { mt: 4 } } color='primary' variant='h5'>Mes pièces</Typography>
            <Box sx={ { mt: 2 } }>
                { children }
            </Box>
        </Box>
    );
};

const BoxTextField = styled(Box)(({ theme }) => ({
    display: "flex",
    [theme.breakpoints.down("md")]: {
        display: "block",
        marginRight: 10
    }
}));

const BoxWidthHead = styled(Box)(({ theme }) => ({
    width: 300,
    [theme.breakpoints.down("md")]: {
        width: "auto"
    }
}));

const BoxResponsive = styled(Box)(({ theme }) => ({
    marginLeft: 10,
    [theme.breakpoints.down("md")]: {
        marginLeft: 0,
        marginBottom: 10
    }
}));

const TypoLength = styled(Typography)({
    color: "#dbdbdb",
    fontSize: "0.90rem",
    display: "flex",
    justifyContent: "flex-end"
});

const StyledInput = styled(MuiTextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
        fontSize: 22,
        "& input": {
            padding: "7.5px 11px"
        }
    },
    [theme.breakpoints.down("md")]: {
        width: "100%"
    }
}));


export default RoomCardContainer;
